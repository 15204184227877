export const types = {
  GET_CUSTOM_CURRENCIES: 'currencies/GET_CUSTOM_CURRENCIES',
  GET_CUSTOM_CURRENCIES_SUCCESS: 'currencies/GET_CUSTOM_CURRENCIES_SUCCESS',
  GET_CUSTOM_CURRENCIES_FAILED: 'currencies/GET_CUSTOM_CURRENCIES_FAILED',

  ADD_CUSTOM_CURRENCY: 'currencies/ADD_CUSTOM_CURRENCY',
  ADD_CUSTOM_CURRENCY_SUCCESS: 'currencies/ADD_CUSTOM_CURRENCY_SUCCESS',
  ADD_CUSTOM_CURRENCY_FAILED: 'currencies/ADD_CUSTOM_CURRENCY_FAILED',

  UPDATE_CUSTOM_CURRENCY: 'currencies/UPDATE_CUSTOM_CURRENCY',
  UPDATE_CUSTOM_CURRENCY_SUCCESS: 'currencies/UPDATE_CUSTOM_CURRENCY_SUCCESS',
  UPDATE_CUSTOM_CURRENCY_FAILED: 'currencies/UPDATE_CUSTOM_CURRENCY_FAILED',

  DELETE_CUSTOM_CURRENCY: 'currencies/DELETE_CUSTOM_CURRENCY',
  DELETE_CUSTOM_CURRENCY_SUCCESS: 'currencies/DELETE_CUSTOM_CURRENCY_SUCCESS',
  DELETE_CUSTOM_CURRENCY_FAILED: 'currencies/DELETE_CUSTOM_CURRENCY_FAILED',

  GET_CURRENCIES: 'currencies/GET_CURRENCIES',
  GET_CURRENCIES_SUCCESS: 'currencies/GET_CURRENCIES_SUCCESS',
  GET_CURRENCIES_FAILED: 'currencies/GET_CURRENCIES_FAILED',
};

export const CURRENCY_MESSAGES = {
  CREATE_CURRENCY_SUCCESS: 'Currency created successfully',
  CREATE_CURRENCY_FAILED: 'Failed to create currency',
  UPDATE_CURRENCY_SUCCESS: 'Currency updated successfully',
  UPDATE_CURRENCY_FAILED: 'Failed to update curency',
  DELETE_CURRENCY_SUCCESS: 'Currency deleted successfully',
  DELETE_CURRENCY_FAILED: 'Failed to delete currency',
};
