import { takeLatest, call, put } from 'redux-saga/effects';

import API from 'utils/api';
import { SAGA_MESSAGES } from 'redux/sagaMessages';

import { sagaErrorHandler } from 'utils/common';
import { types } from './types';

function* getAudits({ payload }) {
  try {
    const {
      data: { data_for_filters, data },
      headers,
    } = yield call(API.getAuditData, {
      page: payload.pagination.current,
      size: payload.pagination.pageSize,
      sort: payload.sort,
      filters: payload.filters,
    });

    yield put({
      type: types.GET_AUDITS_SUCCESS,
      payload: { data: data, headers, data_for_filters },
    });
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.GET_AUDITS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_AUDITS, getAudits);
}
