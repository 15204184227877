import { actionFactory } from 'redux/factories';
import { types } from './types';

export const getSummaryEmployees = actionFactory(types.GET_SUMMARY_EMPLOYEES);
export const getSummaryEmployee = actionFactory(types.GET_SUMMARY_EMPLOYEE);
export const getSummaryEmployeeSalaries = actionFactory(
  types.GET_SUMMARY_EMPLOYEE_SALARIES,
);
export const getSummaryEmployeeComments = actionFactory(
  types.GET_SUMMARY_EMPLOYEE_COMMENTS,
);
export const getSummaryEmployeeExtraPayments = actionFactory(
  types.GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS,
);
export const getSummarySocialRequests = actionFactory(
  types.GET_SUMMARY_SOCIAL_REQUESTS,
);
export const getSummarySocialRequestsInfo = actionFactory(
  types.GET_SUMMARY_SOCIAL_REQUESTS_INFO,
);
export const createSummarySocialRequest = actionFactory(
  types.CREATE_SUMMARY_SOCIAL_REQUEST,
);
export const updateSummarySocialRequest = actionFactory(
  types.UPDATE_SUMMARY_SOCIAL_REQUEST,
);
export const deleteSummarySocialRequest = actionFactory(
  types.DELETE_SUMMARY_SOCIAL_REQUEST,
);
export const getSummaryProfiles = actionFactory(types.GET_SUMMARY_PROFILES);
