exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".lead-summary-c3721{padding:0;margin:0;list-style:none}.lead-summary_vertical-43b61 .lead-summary-item-6af1d{display:block}.lead-summary-item-6af1d{list-style:none;padding:5px 10px}.lead-summary_horizontal-10d44 .lead-summary-item-6af1d{display:inline-block}.lead-summary_horizontal-10d44 .lead-summary-item-6af1d:first-child{padding-left:0}.infoIcon-b1563{margin:5px}.itemDescription-1c564{display:flex}.itemMoney-d090b{display:flex;align-items:center}.listItem-1f6dc{display:flex;align-items:center;position:relative}.details-f2e83{padding:0;margin:0;list-style:none;font-size:16px;color:#595959}.details-f2e83>li{margin-bottom:6px;line-height:24px}.details-f2e83 .infoIcon-b1563{margin-right:6px}.details-f2e83 .itemDescription-1c564{display:flex}.details-f2e83 .itemMoney-d090b{display:flex;align-items:center}", ""]);

// Exports
exports.locals = {
	"lead-summary": "lead-summary-c3721",
	"lead-summary_vertical": "lead-summary_vertical-43b61",
	"lead-summary-item": "lead-summary-item-6af1d",
	"lead-summary_horizontal": "lead-summary_horizontal-10d44",
	"infoIcon": "infoIcon-b1563",
	"itemDescription": "itemDescription-1c564",
	"itemMoney": "itemMoney-d090b",
	"listItem": "listItem-1f6dc",
	"details": "details-f2e83"
};