import React, {
  Fragment,
  useMemo,
  useState,
  createRef,
  useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Switch, Typography, Modal as AntdModal } from 'antd';

import PageHeader from 'components/Common/PageHeader';
import JiraProgress from 'components/Common/JiraProgress';
import { Button, Loader, Table } from 'components/Common';
import { ClientForm } from 'components/ClientsCommon';
import Modal from 'components/Common/Modal';
import {
  JIRA_FIREBASE_PATH,
  OK_STATUS,
  SORT_DIRECTIONS,
} from 'utils/constants';
import { hasRights } from 'utils/permissions';
import { getRowKey } from 'utils/helpers';
import { getFiltersWithOutNull } from 'utils/common';
import { useFirebaseProgress, usePrevState, useRoleContext } from 'utils/hooks';
import { clientsSelector, usersSelector } from 'redux/selectors';
import {
  loadClients,
  createClient,
  updateClient,
  deleteClient,
  restoreClient,
} from 'redux/Clients/actions';
import { loadUsers } from 'redux/Users/actions';
import { updateJiraProjects } from 'redux/Jira/actions';

import { CLIENT_PERMISSIONS } from 'containers/ClientPage/constants';
import { getColumns, getCurrentClientData } from './utils';
import { CLIENTS_PERMISSIONS } from './constants';
import styles from './styles.scss';

const { Text } = Typography;

let unsubscribe;

const ClientsPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filtersInfo, setFiltersInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const [currentClientId, setCurrentClientId] = useState(null);
  const [currentClient, setCurrentClient] = useState({});
  const [sortInfo, setSortInfo] = useState({
    order: SORT_DIRECTIONS.ascend,
    columnKey: 'title',
  });
  const [onlyActive, setOnlyActive] = useState(true);

  const prevProgress = usePrevState(progress);

  const { progress, getFirebaseData } = useFirebaseProgress();

  const dispatch = useDispatch();

  const role = useRoleContext();

  const {
    isClientsLoading,
    clients,
    totalClients,
    clientFilters,
  } = useSelector(clientsSelector);

  const { users } = useSelector(usersSelector);

  // @ts-ignore
  const [form] = Form.useForm();
  const searchInput = createRef();

  const clientsRequestParams = {
    page: pagination.current,
    size: pagination.pageSize,
    sort: `${sortInfo.columnKey}:${sortInfo.order}`,
    filters: { ...filtersInfo, only_active: onlyActive },
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
    setSortInfo({});
    setFiltersInfo({});
    dispatch(
      loadClients({
        ...clientsRequestParams,
        filters: { only_active: onlyActive },
      }),
    );
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const onTableChange = (pagination, filters, sorter) => {
    const filtersWithoutNull = getFiltersWithOutNull(filters);
    setFiltersInfo(filtersWithoutNull);
    setPagination(pagination);
    const sortOrder = sorter.order || SORT_DIRECTIONS.ascend;
    setSortInfo({ ...sorter, order: sortOrder });
    dispatch(
      loadClients({
        ...clientsRequestParams,
        page: pagination.current,
        size: pagination.pageSize,
        filters: { ...filtersWithoutNull, only_active: onlyActive },
        sort: `${sorter.columnKey}:${sortOrder}`,
      }),
    );
  };

  useEffect(() => {
    unsubscribe && unsubscribe();
    unsubscribe = getFirebaseData({
      collection: JIRA_FIREBASE_PATH.collection,
      doc: JIRA_FIREBASE_PATH.doc,
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (
      progress &&
      progress.status === OK_STATUS &&
      prevProgress &&
      prevProgress.status !== OK_STATUS
    ) {
      dispatch(loadClients({ ...clientsRequestParams }));
    }
  }, [progress]);

  const onUpdateJiraProjects = () => {
    dispatch(updateJiraProjects());
  };

  const onCreateClient = values => {
    if (values) {
      dispatch(
        createClient({
          values,
          ...clientsRequestParams,
        }),
      );
    }

    onHideModal();
  };

  const onUpdateClient = values => {
    if (values) {
      dispatch(
        updateClient({
          values,
          clientId: currentClientId,
          ...clientsRequestParams,
        }),
      );
    }

    onHideModal();
  };

  const onShowModal = () => setIsModalVisible(true);
  const onHideModal = () => {
    setIsModalVisible(false);
    setCurrentClientId(null);
    setCurrentClient({});
  };

  const onDeleteClient = ({ clientId }) => {
    AntdModal.confirm({
      title: 'Delete client',
      content: 'Are you sure you want to delete this client?',
      onOk: () => {
        dispatch(
          deleteClient({
            clientId,
            ...clientsRequestParams,
          }),
        );
        AntdModal.destroyAll();
      },
    });
  };

  const onRestoreClient = ({ clientId }) => {
    AntdModal.confirm({
      title: 'Restore client',
      content: 'Are you sure you want to restore this client?',
      onOk: () => {
        dispatch(
          restoreClient({
            clientId,
            ...clientsRequestParams,
          }),
        );
        AntdModal.destroyAll();
      },
    });
  };

  const onEditClient = ({ id }) => {
    setCurrentClientId(id);
    setIsModalVisible(true);
  };

  useEffect(() => {
    dispatch(loadClients({ ...clientsRequestParams }));
  }, [onlyActive]);

  const columns = useMemo(
    () =>
      getColumns({
        filtersInfo,
        handleSearch,
        handleReset,
        searchInput,
        role,
        users,
        onDeleteClient,
        onEditClient,
        sortInfo,
        clientFilters,
        onRestoreClient,
      }),
    [filtersInfo, searchText, users, sortInfo, clientFilters],
  );

  useEffect(() => {
    dispatch(loadUsers());
  }, []);

  useEffect(() => {
    if (currentClientId) {
      setCurrentClient(getCurrentClientData({ currentClientId, clients }));
    }
  }, [currentClientId]);

  useEffect(() => {
    setPagination(prev => ({ ...prev, total: Number(totalClients) }));
  }, [totalClients]);

  return (
    <Fragment>
      <PageHeader
        title="Clients"
        extra={
          <>
            <JiraProgress progress={progress} />
            {hasRights(role, CLIENT_PERMISSIONS.updateProjects) && (
              <Button
                onClick={onUpdateJiraProjects}
                type="primary"
                loading={progress && progress.status !== OK_STATUS}
              >
                Update projects
              </Button>
            )}
            <Text>Only active: </Text>
            <Switch checked={onlyActive} onChange={setOnlyActive} />
            {hasRights(role, CLIENTS_PERMISSIONS.addClient) && (
              <Button type="primary" onClick={onShowModal}>
                Add client
              </Button>
            )}
          </>
        }
      />
      <Modal
        open={isModalVisible}
        onCancel={onHideModal}
        title={currentClientId ? 'Edit client' : 'Create client'}
        footer={
          <>
            <Button onClick={onHideModal}>Close</Button>
            <Button
              type="primary"
              form="clientForm"
              key="submit"
              htmlType="submit"
            >
              {currentClientId ? 'Save' : 'Add'}
            </Button>
          </>
        }
      >
        <ClientForm
          form={form}
          onFinish={currentClientId ? onUpdateClient : onCreateClient}
          fieldValues={currentClient}
        />
      </Modal>
      <Loader loading={isClientsLoading}>
        <Table
          // @ts-ignore
          columns={columns}
          bordered
          onChange={onTableChange}
          rowKey={getRowKey}
          dataSource={clients}
          pagination={pagination}
          scroll={{ x: 1500 }}
          className={styles.clientsTable}
        />
      </Loader>
    </Fragment>
  );
};

export default ClientsPage;
