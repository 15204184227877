import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  deals: [],
  isDealsLoading: false,
  rmDealAllocations: [],
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_RM_DEAL_ALLOCATIONS:
    case types.GET_DEALS:
    case types.UPDATE_DEAL:
      return state.set('isDealsLoading', true);
    case types.CLEAR_RM_DEAL_ALLOCATIONS:
      return state.set('rmDealAllocations', []);

    case types.GET_DEALS_SUCCESS:
    case types.UPDATE_DEAL_SUCCESS:
      return state.set('isDealsLoading', false).payload('deals', payload);
    case types.GET_RM_DEAL_ALLOCATIONS_SUCCESS:
      return state
        .set('isDealsLoading', false)
        .set('rmDealAllocations', payload);

    case types.GET_DEALS_FAILED:
    case types.UPDATE_DEAL_FAILED:
      return state.set('isDealsLoading', false);
    case types.GET_RM_DEAL_ALLOCATIONS_FAILED:
      return state.set('isDealsLoading', false);

    default:
      return state;
  }
}
