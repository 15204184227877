import React from 'react';
import { MailOutlined } from '@ant-design/icons';
import { LEAD_TABS } from 'containers/LeadPage/constants';

export function filterEventsByType(events = [], type) {
  if (type === LEAD_TABS.all) {
    return events.filter(event => {
      if (event.auditable_type === 'Lead' && event.action === 'update') {
        return false;
      }

      return true;
    });
  }

  if (type === LEAD_TABS.notes) {
    return events.filter(event => event.auditable_type === 'LeadNote');
  }

  return events.filter(event => event.auditable_type === type);
}

export const getTimeLineColor = action => {
  switch (action) {
    case 'destroy':
      return 'red';
    case 'update':
      return 'blue';
    default:
      return 'green';
  }
};

export const getTimeLineIcon = ({ event }) => {
  const { auditable_type } = event;

  switch (auditable_type) {
    case 'Lead':
      return <MailOutlined />;
    default:
      return '';
  }
};
