import { takeLatest, call, put, select } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';
import { REDUX_DATA_HELPERS } from 'utils/helpers';

import { currenciesSelector } from 'redux/selectors';
import { sagaErrorHandler } from 'utils/common';
import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { CURRENCY_MESSAGES, types } from './types';

function* getCustomCurrencies() {
  try {
    const { data } = yield call(API.loadCurrencies);
    yield put({ type: types.GET_CUSTOM_CURRENCIES_SUCCESS, payload: data });
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.GET_CUSTOM_CURRENCIES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* addCustomCurrency({ payload }) {
  try {
    const { currencies } = yield select(currenciesSelector);
    const { data } = yield call(API.addCurrency, payload);

    yield put({
      type: types.ADD_CUSTOM_CURRENCY_SUCCESS,
      payload: [...currencies, data],
    });
    message.success(CURRENCY_MESSAGES.CREATE_CURRENCY_SUCCESS);
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.ADD_CUSTOM_CURRENCY_FAILED,
      CURRENCY_MESSAGES.CREATE_CURRENCY_FAILED,
    );
  }
}

function* updateCustomCurrency({ payload }) {
  try {
    const { currencies } = yield select(currenciesSelector);
    const { data } = yield call(API.updateCurrency, payload);

    const updatedCurrencies = REDUX_DATA_HELPERS.updateData({
      data: currencies,
      editedItem: data,
      id: payload.id,
    });

    yield put({
      type: types.UPDATE_CUSTOM_CURRENCY_SUCCESS,
      payload: updatedCurrencies,
    });
    message.success(CURRENCY_MESSAGES.UPDATE_CURRENCY_SUCCESS);
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.UPDATE_CUSTOM_CURRENCY_FAILED,
      CURRENCY_MESSAGES.UPDATE_CURRENCY_FAILED,
    );
  }
}
function* deleteCustomCurrency({ payload }) {
  try {
    const { currencies } = yield select(currenciesSelector);
    yield call(API.deleteCurrency, payload);

    const updatedCurrencies = REDUX_DATA_HELPERS.removeItem({
      data: currencies,
      id: payload,
    });

    yield put({
      type: types.DELETE_CUSTOM_CURRENCY_SUCCESS,
      payload: updatedCurrencies,
    });
    message.success(CURRENCY_MESSAGES.DELETE_CURRENCY_SUCCESS);
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.DELETE_CUSTOM_CURRENCY_FAILED,
      CURRENCY_MESSAGES.DELETE_CURRENCY_FAILED,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_CUSTOM_CURRENCIES, getCustomCurrencies);
  yield takeLatest(types.ADD_CUSTOM_CURRENCY, addCustomCurrency);
  yield takeLatest(types.UPDATE_CUSTOM_CURRENCY, updateCustomCurrency);
  yield takeLatest(types.DELETE_CUSTOM_CURRENCY, deleteCustomCurrency);
}
