/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, InputNumber, Select } from 'antd';
import dayjs from 'dayjs';

import DatePicker from 'components/Common/DatePicker';
import { DATE_FORMAT } from 'utils/timeConstants';

import {
  SHIFT_KEY_CODE,
  ENTER_KEY_CODE,
  WORKLOGS_EDITABLE_FIELDS,
  ISSUE_TYPES,
  WORKLOGS_SELECT_FIELDS,
} from '../constants';
import styles from '../styles.scss';

const { Option } = Select;

const InvoiceWorklogEditableCell = ({
  onClick,
  inputType,
  value,
  record,
  editableCell,
  onCellBlur,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const [isPressShift, setIsPressShift] = useState(false);

  const onChangeInputValue = event => {
    setInputValue(
      WORKLOGS_SELECT_FIELDS.includes(inputType) ? event : event.target.value,
    );
  };

  const onChangeDate = date => {
    setInputValue(dayjs(date).format(DATE_FORMAT));
  };

  const onCellClick = () => {
    onClick({ record, inputType });
  };

  const onBlur = () => onCellBlur(inputValue);

  const onKeyDown = event => {
    if (
      !isPressShift &&
      event.keyCode.toString() === SHIFT_KEY_CODE &&
      inputType === WORKLOGS_EDITABLE_FIELDS.comment
    ) {
      setIsPressShift(true);
    }

    if (event.keyCode.toString() === ENTER_KEY_CODE && !isPressShift) {
      onCellBlur(inputValue);
    }
  };

  const onKeyUp = event => {
    if (
      isPressShift &&
      event.keyCode.toString() === SHIFT_KEY_CODE &&
      inputType === WORKLOGS_EDITABLE_FIELDS.comment
    ) {
      setIsPressShift(false);
    }
  };

  switch (true) {
    case editableCell &&
      editableCell.worklog_id === record.worklog_id &&
      WORKLOGS_EDITABLE_FIELDS.spent_hours === editableCell.inputType &&
      WORKLOGS_EDITABLE_FIELDS.spent_hours === inputType:
      return (
        <InputNumber
          size="small"
          min={0}
          value={inputValue}
          onChange={onChangeInputValue}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          autoFocus
        />
      );

    case editableCell &&
      editableCell.worklog_id === record.worklog_id &&
      WORKLOGS_EDITABLE_FIELDS.issue_type === editableCell.inputType &&
      WORKLOGS_EDITABLE_FIELDS.issue_type === inputType:
      return (
        <Select
          size="small"
          value={inputValue}
          onChange={onChangeInputValue}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          autoFocus
        >
          {ISSUE_TYPES.map(type => (
            <Option value={type}>{type}</Option>
          ))}
        </Select>
      );

    case editableCell &&
      editableCell.worklog_id === record.worklog_id &&
      WORKLOGS_EDITABLE_FIELDS.date === editableCell.inputType &&
      WORKLOGS_EDITABLE_FIELDS.date === inputType:
      const dateValue = dayjs(inputValue);

      return (
        <DatePicker
          size="small"
          value={dateValue}
          onChange={onChangeDate}
          onKeyDown={onKeyDown}
          allowClear={false}
          onBlur={onBlur}
          autoFocus
        />
      );

    case editableCell &&
      editableCell.worklog_id === record.worklog_id &&
      WORKLOGS_EDITABLE_FIELDS[inputType] === editableCell.inputType &&
      WORKLOGS_EDITABLE_FIELDS[inputType] === inputType:
      return (
        <Input.TextArea
          size="small"
          value={inputValue}
          onChange={onChangeInputValue}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          autoSize
          onBlur={onBlur}
          autoFocus
        />
      );

    default:
      return (
        <div
          onClick={onCellClick}
          role="cell"
          className={styles.worklogsEditableCell}
        >
          {value}
        </div>
      );
  }
};

InvoiceWorklogEditableCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  editableCell: PropTypes.object,
  onCellBlur: PropTypes.func.isRequired,
};

export default InvoiceWorklogEditableCell;
