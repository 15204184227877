import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Tabs } from 'antd';
import { debounce, get, groupBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { searchJiraUsers } from 'redux/Jira/actions';
import { getCalendar } from 'redux/Calendar/actions';
import { Loader, Table } from 'components/Common';
import { LOCAL_STORAGE_ITEMS } from 'utils/localStorage';
import { getFiltersWithOutNull, mapArrayToEntities } from 'utils/common';

import {
  calendarSelector,
  clientsSelector,
  jiraSelector,
} from 'redux/selectors';
import { useIndexedDBStore } from 'use-indexeddb';
import { DB_STORES } from 'config/indexedDB';
import {
  addEmptyWorklogArrays,
  formatNewWorklog,
  getDeletedWorklogs,
  getModifiedColumns,
  getProjectColumns,
  getSummary,
  getSummaryColumns,
  getSummaryDataSource,
  getWorkerFilters,
} from './utils';
import { getInvoiceStartEndDates } from '../ClientInvoiceSteps/utils';
import ClientInvoiceWorklogsModal from './ClientInvoiceWorklogsModal';
import ClientInvoiceDraftAlert from './ClientInvoiceDraftAlert';

import styles from './styles.scss';

const ClientInvoiceWorklogsStep = ({
  invoiceDate,
  invoiceData,
  invoiceProjects,
  addWorklogForm,
  invoiceAllocations,
  worklogsGroupedByProject,
  jiraRolesGroupedById,
  projectGroupedById,
  draftSavedAt,

  setIsAddWorklog,
  setWorklogsGroupedByProject,
  setIsInvoiceValuesTouched,
  setDraftSavedAt,

  isFullScreen,
  isAddWorklogs,
}) => {
  const [currentProjectId, setCurrentProjectId] = useState('');
  const [editableCell, setEditableCell] = useState(null);
  const [columnsSettings, setColumnsSettings] = useState([]);
  const [worklogFilters, setWorklogFilters] = useState({});

  const dispatch = useDispatch();

  const { getByID, update, deleteByID } = useIndexedDBStore(DB_STORES.worklogs);

  const { isClientsLoading, isAllocationsStepLoading } = useSelector(
    clientsSelector,
  );

  const { calendar } = useSelector(calendarSelector);

  const {
    jiraRoles,
    searchedJiraUsers,
    isJiraLoading,
    jiraProjects,
  } = useSelector(jiraSelector);

  useEffect(() => {
    if (!calendar.length) {
      const { date_from, date_to } = getInvoiceStartEndDates(invoiceDate);
      dispatch(getCalendar({ from: date_from, to: date_to }));
    }
  }, []);

  const setWorklogsTouched = () => {
    setIsInvoiceValuesTouched(prev => ({
      ...prev,
      worklogs: true,
    }));
  };

  const setWorklogs = async () => {
    if (!invoiceData) return;

    const worklogsDraft = await getByID(invoiceData.id);

    if (worklogsDraft) {
      setWorklogsGroupedByProject(worklogsDraft.worklogs);
      setDraftSavedAt(worklogsDraft.savedAt);
      setWorklogsTouched();

      return;
    }

    const originalWorklogs = get(invoiceData, 'original_worklogs', []);
    const worklogs = get(invoiceData, 'worklogs');
    const worklogsGroupedById = groupBy(worklogs, 'worklog_id');
    const deletedWorklogs = getDeletedWorklogs({
      worklogsGroupedById,
      originalWorklogs,
    });

    const worklogsGroupedByProject = worklogs
      ? groupBy([...worklogs, ...deletedWorklogs], 'project_id')
      : groupBy(originalWorklogs, 'project_id');

    // Add keys for projects without worklogs into "worklogsGroupedByProject"
    addEmptyWorklogArrays({
      worklogs: worklogsGroupedByProject,
      projects: invoiceProjects,
    });

    setWorklogsGroupedByProject(worklogsGroupedByProject);
  };

  useEffect(() => {
    setWorklogs();
  }, [invoiceData]);

  const originialWorklogsGroupById = useMemo(
    () =>
      mapArrayToEntities(
        get(invoiceData, 'original_worklogs', []),
        {},
        'worklog_id',
      ).entities,
    [invoiceData],
  );

  const onTableChange = (pagination, filters) => {
    const filtersWithoutNull = getFiltersWithOutNull(filters);

    setWorklogFilters({
      [currentProjectId]: filtersWithoutNull,
    });
  };

  const workersGroupedByProject = useMemo(
    () => getWorkerFilters(worklogsGroupedByProject),
    [worklogsGroupedByProject],
  );

  const removeWorklogsDraft = async () => {
    await deleteByID(invoiceData.id);
    setWorklogs();
    setDraftSavedAt(null);
    setIsInvoiceValuesTouched(prev => ({
      ...prev,
      worklogs: false,
    }));
  };

  const setWorklogsDraft = worklogs => {
    update({
      worklogs,
      invoiceId: invoiceData.id,
      savedAt: new Date(),
    });
    setDraftSavedAt(new Date());
  };

  const onDeleteRestoreWorklog = ({ record, isDelete }) => {
    setWorklogsGroupedByProject(prev => {
      const updatedWorklogs = {
        ...prev,
        [record.project_id]: prev[record.project_id].map(worklog =>
          worklog.worklog_id === record.worklog_id
            ? { ...worklog, deleted: isDelete }
            : worklog,
        ),
      };
      setWorklogsDraft(updatedWorklogs);

      return updatedWorklogs;
    });

    setWorklogsTouched();
  };

  // Включает режим редактрования поля ворклога.
  const onCellClick = ({ record, inputType }) => {
    setEditableCell({ ...record, inputType });
  };

  // Сохраняет измененное поле ворклога.
  const onCellBlur = value => {
    let editedValues = {
      [editableCell.inputType]: value,
      edited: true,
    };

    if (editableCell.inputType === 'spent_hours') {
      editedValues.spent_seconds = value * 3600;
    }

    if (editableCell.inputType === 'worker_name') {
      editedValues.worker_name =
        searchedJiraUsers.find(jiraUser => jiraUser.key === value)
          ?.displayName || '';
      editedValues.worker = value;
    }

    const updatedProjectWorklogs = worklogsGroupedByProject[
      currentProjectId
    ].map(worklog =>
      worklog.worklog_id === editableCell.worklog_id
        ? { ...worklog, ...editedValues }
        : worklog,
    );

    setWorklogsGroupedByProject(prev => {
      const updatedWorklogs = {
        ...prev,
        [currentProjectId]: updatedProjectWorklogs,
      };
      setWorklogsDraft(updatedWorklogs);

      return updatedWorklogs;
    });
    setEditableCell(null);
    setWorklogsTouched();
  };

  const onSearchJiraUsers = debounce(name => {
    if (name) dispatch(searchJiraUsers({ name }));
  }, 300);

  const summaryDataSource = useMemo(
    () =>
      getSummaryDataSource({
        worklogsGroupedByProject,
        invoiceAllocations,
        invoiceProjects,
        calendar,
        projectGroupedById,
        jiraRoles,
        isAllocationsStepLoading,
      }),
    //// Refactor dependencies
    [
      worklogsGroupedByProject,
      invoiceAllocations,
      invoiceProjects,
      calendar,
      projectGroupedById,
      jiraRoles,
      isAllocationsStepLoading,
    ],
  );

  useEffect(() => {
    const tableSettings = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_ITEMS.invoiceOverviewProjectSettings),
    );

    const modifiedColumns = getModifiedColumns({
      tableSettings,
      columns: projectColumns,
    });

    setColumnsSettings(modifiedColumns);
  }, []);

  const summaryColumns = useMemo(() => getSummaryColumns(), []);

  const getRecordKey = record => record.worklog_id;

  const onModalClose = () => {
    setIsAddWorklog(false);
    addWorklogForm.resetFields();
  };

  const onAddWorklog = values => {
    const formattedWorklog = formatNewWorklog({
      values,
      currentProjectId,
      invoiceAllocations,
      searchedJiraUsers,
      jiraProjects,
      jiraRolesGroupedById,
    });

    const project_id = values.project_id || currentProjectId;

    const updatedProjectWorklogs = [
      ...worklogsGroupedByProject[project_id],
      formattedWorklog,
    ].sort((a, b) => (a.date > b.date ? 1 : -1));

    setWorklogsGroupedByProject(prev => {
      const updatedWorklogs = {
        ...prev,
        [project_id]: updatedProjectWorklogs,
      };
      setWorklogsDraft(updatedWorklogs);

      return updatedWorklogs;
    });
    setWorklogsTouched();
    onModalClose();
  };

  const onDuplicateWorklog = record => {
    const { project_id } = record;
    const updatedProjectWorklogs =
      worklogsGroupedByProject[project_id]?.reduce((acc, curr) => {
        if (curr.worklog_id === record.worklog_id) {
          return [
            ...acc,
            curr,
            {
              ...curr,
              worklog_id: uuidv4(),
              isNew: true,
              edited: false,
            },
          ];
        }

        return [...acc, curr];
      }, []) || [];

    setWorklogsGroupedByProject(prev => {
      const updatedWorklogs = {
        ...prev,
        [project_id]: updatedProjectWorklogs,
      };
      setWorklogsDraft(updatedWorklogs);

      return updatedWorklogs;
    });
    setWorklogsTouched();
  };

  const openWorklogModal = () => {
    setIsAddWorklog(true);
  };

  const getProjectSummary = () =>
    getSummary({
      data: worklogsGroupedByProject,
      project: currentProjectId,
      worklogFilters: worklogFilters,
      columnsSettings,
    });

  const projectColumns = useMemo(
    () =>
      getProjectColumns({
        currentProjectId,
        onCellClick,
        onCellBlur,
        editableCell,
        setEditableCell,
        workersGroupedByProject,
        onDeleteRestoreWorklog,
        onSearchJiraUsers,
        searchedJiraUsers,
        isJiraLoading,
        onDuplicateWorklog,
      }),
    [
      currentProjectId,
      editableCell,
      workersGroupedByProject,
      searchedJiraUsers,
      isJiraLoading,
    ],
  );

  const tableScrollHeight = `${window.innerHeight / 22}vh`;

  const getRowClassName = ({ edited, deleted, worklog_id }) => {
    const isNew = !originialWorklogsGroupById[worklog_id];

    switch (true) {
      case edited:
        return styles.editedRow;
      case deleted:
        return styles.deletedRow;
      case isNew:
        return styles.newRow;
      default:
        return '';
    }
  };

  const projectTabs =
    (invoiceProjects &&
      invoiceProjects.map(project => ({
        label: projectGroupedById[project]?.key,
        key: project,
        children: (
          <>
            <Loader loading={isClientsLoading || isAllocationsStepLoading}>
              <Table
                dataSource={worklogsGroupedByProject[currentProjectId]}
                // @ts-ignore
                columns={projectColumns}
                size="small"
                pagination={false}
                scroll={{
                  y: isFullScreen ? tableScrollHeight : '450px',
                }}
                rowKey={getRecordKey}
                storageKey={LOCAL_STORAGE_ITEMS.invoiceOverviewProjectSettings}
                isPageWithId
                loading={isClientsLoading}
                rowClassName={getRowClassName}
                onChange={onTableChange}
                summary={getProjectSummary}
                setColumnsSettings={setColumnsSettings}
              />
            </Loader>
            <Button
              type="dashed"
              onClick={openWorklogModal}
              size="small"
              className={styles.addWorklogBtn}
            >
              Add row
            </Button>
          </>
        ),
      }))) ||
    [];

  const summaryTab = {
    label: 'Summary',
    key: '',
    children: (
      <Loader loading={isClientsLoading || isAllocationsStepLoading}>
        <Table
          rowKey={record => record.key}
          size="small"
          pagination={false}
          expandable={{
            defaultExpandAllRows: true,
            defaultExpandedRowKeys: invoiceProjects,
          }}
          columns={summaryColumns}
          dataSource={summaryDataSource}
          scroll={{
            y: isFullScreen ? tableScrollHeight : '450px',
          }}
          storageKey={LOCAL_STORAGE_ITEMS.invoiceOverviewSettings}
          loading={isClientsLoading}
        />
      </Loader>
    ),
  };

  return (
    <>
      <ClientInvoiceWorklogsModal
        isVisible={isAddWorklogs}
        form={addWorklogForm}
        onClose={onModalClose}
        onAdd={onAddWorklog}
        onSearchJiraUsers={onSearchJiraUsers}
        isJiraLoading={isJiraLoading}
        searchedJiraUsers={searchedJiraUsers}
        invoiceAllocations={invoiceAllocations}
        currentProjectId={currentProjectId}
        jiraRolesGroupedById={jiraRolesGroupedById}
        invoiceDate={invoiceDate}
      />
      <Tabs
        defaultActiveKey=""
        style={{
          marginBottom: isFullScreen ? 0 : '30px',
          maxWidth: 'calc(100% - 315px)',
        }}
        onChange={setCurrentProjectId}
        tabBarExtraContent={{
          right: draftSavedAt && (
            <ClientInvoiceDraftAlert
              draftSavedAt={draftSavedAt}
              removeWorklogsDraft={removeWorklogsDraft}
            />
          ),
        }}
        items={[...projectTabs, summaryTab]}
      />
    </>
  );
};

ClientInvoiceWorklogsStep.propTypes = {
  invoiceDate: PropTypes.object.isRequired,
  invoiceData: PropTypes.object,
  invoiceProjects: PropTypes.array.isRequired,
  addWorklogForm: PropTypes.object.isRequired,
  invoiceAllocations: PropTypes.object.isRequired,
  worklogsGroupedByProject: PropTypes.object.isRequired,
  jiraRolesGroupedById: PropTypes.object.isRequired,
  projectGroupedById: PropTypes.object.isRequired,
  draftSavedAt: PropTypes.object,

  setIsAddWorklog: PropTypes.func.isRequired,
  setWorklogsGroupedByProject: PropTypes.func.isRequired,
  setIsInvoiceValuesTouched: PropTypes.func.isRequired,
  setDraftSavedAt: PropTypes.func.isRequired,

  isFullScreen: PropTypes.bool.isRequired,
  isAddWorklogs: PropTypes.bool.isRequired,
};

export default ClientInvoiceWorklogsStep;
