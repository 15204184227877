import React, {
  useState,
  useEffect,
  Fragment,
  useMemo,
  createRef,
} from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import PageHeader from 'components/Common/PageHeader';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, Table } from 'components/Common';
import { OrganizationModal } from 'components/OrganizationsCommon';
import { organizationsSelector } from 'redux/selectors';
import {
  getOrganizationsSearch,
  updateOrganization,
  createOrganization,
  deleteOrganization,
  getOrganizationAccounts,
  createOrganizationAccount,
  updateOrganizationAccount,
  deleteOrganizationAccount,
  clearOrganizationAccounts,
} from 'redux/Organizations/actions';

import { getRowKey } from 'utils/helpers';
import { useRoleContext } from 'utils/hooks';
import {
  getTableColumns,
  getFiltersWithOutNull,
  formatSearchFilters,
} from './utils';
import styles from './styles.scss';

const OrganizationsList = ({
  rowSelection,
  isOrganizationsListInModal,
  clientId,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [filtersInfo, setFiltersInfo] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const [sortInfo, setSortInfo] = useState({
    order: 'descend',
    columnKey: 'id',
  });
  const [accountsData, setAccountsData] = useState([]);

  const dispatch = useDispatch();

  const role = useRoleContext();

  const {
    isOrganizationsLoading,
    organizations,
    organizationAccounts,
    totalOrganizations,
  } = useSelector(organizationsSelector);

  const [form] = Form.useForm();

  const searchInput = createRef();

  const toggleModal = value => {
    setIsVisible(value);
  };

  useEffect(() => {
    if (isOrganizationsListInModal && clientId) {
      dispatch(
        getOrganizationsSearch({
          sort: `${sortInfo.columnKey}:${sortInfo.order}`,
          filters: {
            client_id: clientId,
          },
          size: 1000,
        }),
      );

      return;
    }

    dispatch(
      getOrganizationsSearch({
        sort: `${sortInfo.columnKey}:${sortInfo.order}`,
        page: pagination.current,
        size: pagination.pageSize,
      }),
    );
  }, []);

  const onCreate = values => {
    if (values) {
      dispatch(
        createOrganization({
          values,
          sort: `${sortInfo.columnKey}:${sortInfo.order}`,
          accounts: accountsData,
        }),
      );
    }

    onHideModal();
  };

  const onEdit = values => {
    if (currentOrganization) {
      dispatch(
        updateOrganization({
          organizationId: currentOrganization.id,
          data: values,
          sort: `${sortInfo.columnKey}:${sortInfo.order}`,
        }),
      );
    }

    onHideModal();
  };

  const onTableChange = (pagination, filters, sorter) => {
    const filtersWithOutNull = getFiltersWithOutNull(filters);
    const formattedFilters = formatSearchFilters(filtersWithOutNull);
    setSortInfo(sorter);
    setFiltersInfo(filtersWithOutNull);
    setPagination(pagination);

    dispatch(
      getOrganizationsSearch({
        sort: `${sorter.columnKey}:${sorter.order || ''}`,
        filters: formattedFilters,
      }),
    );
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
    setFiltersInfo({});
    dispatch(
      getOrganizationsSearch({
        sort: `${sortInfo.columnKey}:${sortInfo.order || ''}`,
      }),
    );
  };

  const onShowModal = record => {
    setCurrentOrganization(record);
    dispatch(getOrganizationAccounts({ organizationId: record.id }));
    form.setFieldsValue(record);
    toggleModal(true);
  };

  const onDeleteOrganization = params => {
    Modal.confirm({
      title: 'Delete organization',
      content: 'Are you sure you want to delete organization?',
      onOk: () => {
        dispatch(deleteOrganization(params));
        Modal.destroyAll();
      },
      cancelText: 'Cancel',
    });
  };

  const tableColumns = useMemo(
    () =>
      getTableColumns({
        onDeleteOrganization,
        handleSearch,
        handleReset,
        sortInfo,
        role,
        onShowModal,
        searchInput,
        filtersInfo,
      }),
    [sortInfo, organizations, searchText, searchInput, filtersInfo],
  );

  const onHideModal = () => {
    setIsVisible(false);
    setCurrentOrganization(null);
    form.resetFields();
    setAccountsData([]);
    dispatch(clearOrganizationAccounts());
  };

  useEffect(() => {
    setPagination(prev => ({ ...prev, total: Number(totalOrganizations) }));
  }, [totalOrganizations]);

  const onCreateAccount = params => dispatch(createOrganizationAccount(params));

  const onUpdateAccount = params => dispatch(updateOrganizationAccount(params));

  const onDeleteAccount = params => dispatch(deleteOrganizationAccount(params));

  return (
    <Fragment>
      <OrganizationModal
        currentOrganization={currentOrganization}
        isVisible={isVisible}
        onHideModal={onHideModal}
        isLoading={isOrganizationsLoading}
        onEdit={onEdit}
        onCreate={onCreate}
        role={role}
        form={form}
        accounts={organizationAccounts}
        deleteAccount={onDeleteAccount}
        createAccount={onCreateAccount}
        editAccount={onUpdateAccount}
        accountsData={accountsData}
        setAccountsData={setAccountsData}
      />
      <PageHeader
        title="Organizations"
        extra={
          <Button
            type="primary"
            onClick={toggleModal}
            className={classNames({
              [styles.buttonMargin]: isOrganizationsListInModal,
            })}
          >
            Add organization
          </Button>
        }
      />
      <Table
        dataSource={organizations}
        rowKey={getRowKey}
        columns={tableColumns}
        loading={isOrganizationsLoading}
        size="small"
        bordered
        onChange={onTableChange}
        pagination={!isOrganizationsListInModal && pagination}
        rowSelection={rowSelection}
        scroll={{ y: isOrganizationsListInModal ? 400 : false }}
        storageKey="clientsOrganizations"
        isPageWithId={isOrganizationsListInModal}
      />
    </Fragment>
  );
};

OrganizationsList.defaultProps = {
  rowSelection: null,
  isOrganizationsListInModal: false,
  clientId: null,
};

OrganizationsList.propTypes = {
  isOrganizationsListInModal: PropTypes.bool,
  rowSelection: PropTypes.object,
  clientId: PropTypes.string,
};

export default OrganizationsList;
