import React from 'react';
import FilterDropdown from 'components/Common/FilterDropdown';
import SearchTableIcon from 'components/Icons/SearchIcon';

const getColumnSearchProps = ({ dataIndex, handleReset }) => ({
  filterDropdown: props => (
    <FilterDropdown
      {...props}
      dataIndex={dataIndex}
      handleReset={handleReset}
    />
  ),
  filterIcon: filtered => <SearchTableIcon isFiltered={filtered} />,
});

export default getColumnSearchProps;
