exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".detailsContainer-e9426{width:340px;height:100%;display:flex;flex-direction:column;align-items:center;position:relative}.detailsContainer-e9426 .wrapper-a0cdd{width:100%;display:flex;align-items:center;flex-direction:column;margin-bottom:50px}.detailsContainer-e9426 .details-63a8b{padding:24px;border-radius:12px;background-color:#fff;box-shadow:0 4px 20px 0 rgba(112,112,128,.1);width:100%}.detailsContainer-e9426 .divider-ae0ea{margin:20px 0}", ""]);

// Exports
exports.locals = {
	"detailsContainer": "detailsContainer-e9426",
	"wrapper": "wrapper-a0cdd",
	"details": "details-63a8b",
	"divider": "divider-ae0ea"
};