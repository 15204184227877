import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  formatNumberWithCommas,
  nFormatter,
  getMonthNameWithYear,
} from 'utils/helpers';

import styles from './styles.scss';

const DashboardEmployeeRevenueChart = ({ monthData }) => {
  const yTickFormatter = value => nFormatter(value);

  return (
    <div className={styles.dasbboardEmployeeRevenueChart}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={monthData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="month"
            xAxisId={1}
            tickFormatter={getMonthNameWithYear}
          />
          <XAxis dataKey="month" xAxisId={2} hide />
          <YAxis tickFormatter={yTickFormatter} />
          <Tooltip formatter={formatNumberWithCommas} />
          <Legend verticalAlign="top" align="left" height={50} />
          <Bar
            dataKey="revenue"
            name="Revenue"
            fill="#F5C243"
            xAxisId={1}
            fillOpacity={0.85}
          />
          <Bar
            dataKey="cost"
            name="Cost"
            fill="#D9D9D9"
            xAxisId={2}
            fillOpacity={0.85}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

DashboardEmployeeRevenueChart.propTypes = {
  monthData: PropTypes.array.isRequired,
};

export default DashboardEmployeeRevenueChart;
