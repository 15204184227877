import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  audits: [],
  auditTypes: [],
  auditFilters: {},
  totalAudits: 1,
  isAuditsLoading: false,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_AUDITS:
    case types.GET_AUDITS_TYPES:
      return state.set('isAuditsLoading', true);

    case types.GET_AUDITS_SUCCESS:
      return state
        .set('isAuditsLoading', false)
        .set('audits', payload.data)
        .set('totalAudits', payload.headers['x-total-count'])
        .set('auditFilters', payload.data_for_filters);
    case types.GET_AUDITS_TYPES_SUCCESS:
      return state.set('isAuditsLoading', false).set('auditTypes', payload);

    case types.GET_AUDITS_FAILED:
    case types.GET_AUDITS_TYPES_FAILED:
      return state.set('isAuditsLoading', false);

    default:
      return state;
  }
}
