import { nFormatter, formatNumberWithCommas } from 'utils/helpers';

export const getMonth = ({ monthsData, index }) =>
  monthsData?.[monthsData.length - index];

export const getRevenue = month =>
  month?.revenue_sum && nFormatter(month?.revenue_sum);

export const getGPM = value =>
  value?.g_p_m && `${formatNumberWithCommas(value?.g_p_m)}%`;

export const getProject = project => project && project[0];
