import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

// @ts-ignore
import styles from './styles.scss';
import { formatMoney } from '../utils';

const MoneyCell = ({ value, style = {}, project, index }) => (
  <div style={style} className={styles.moneyCellWrapper}>
    <div
      className={styles.moneyCell}
      style={{ color: index === 2 && (value >= 0 ? '#237804' : '#a8071a') }}
    >
      {Number(value) === 0 &&
      !(get(project, 'cost') === get(project, 'revenue'))
        ? ''
        : `${formatMoney(value)}`}
    </div>
  </div>
);

MoneyCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: PropTypes.object,
  project: PropTypes.object,
};

export default MoneyCell;
