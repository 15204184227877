import React from 'react';
import PropTypes from 'prop-types';
import { Tag as AntdTag } from 'antd';
import classNames from 'classnames';

import styles from './styles.scss';

const Tag = ({ children, color, className }) => (
  <AntdTag color={color} className={classNames([styles.tag, className])}>
    {children}
  </AntdTag>
);

Tag.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Tag;
