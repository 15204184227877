/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import get from 'lodash/get';

import styles from './styles.scss';

const BuddyDisplay = ({ userBuddyInfo, onShowBuddySelect }) => (
  <>
    {userBuddyInfo.map((buddy, index) => (
      <div
        onClick={onShowBuddySelect}
        className={styles.userInfo}
        key={`${buddy.id}_${index}`}
      >
        <Avatar
          className={styles.avatar}
          shape="square"
          size={24}
          src={get(
            buddy,
            ['jira_user', 'avatarUrls', '32x32'],
            'https://www.gravatar.com/avatar/e5ffa8aecb9a36a76676407d8bfd1d36?d=mm&s=32',
          )}
        />
        {buddy.name_en || buddy.name_ru || ''}
      </div>
    ))}
  </>
);

BuddyDisplay.propTypes = {
  userBuddyInfo: PropTypes.array.isRequired,
  onShowBuddySelect: PropTypes.func.isRequired,
};

export default BuddyDisplay;
