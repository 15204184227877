import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const DetailsContainer = ({ extra, name, children }) => {
  return (
    <div className={styles.detailsContainer}>
      <div className={styles.detailsHeader}>
        <span className={styles.detailsTitle}>{name}</span>
        {extra}
      </div>
      <div className={styles.detailsContent}>{children}</div>
    </div>
  );
};

DetailsContainer.propTypes = {
  name: PropTypes.string,
  extra: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default DetailsContainer;
