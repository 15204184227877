import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import CustomLegend from 'components/Common/Recharts/Legend';
import {
  formatNumberWithCommas,
  nFormatter,
  getMonthNameWithYearShorten,
} from 'utils/helpers';
import { RECHARTS_AXIS_STYLES } from 'utils/constants';

import TooltipContent from 'components/Common/Recharts/TooltipContent';
import styles from './styles.scss';

const ProfitAndLossChart = ({ monthData }) => {
  const yTickFormatter = value => nFormatter(value);

  const formattedData = useMemo(
    () =>
      monthData?.map(item => ({
        cost_adjustment: +item.cost_adjustment,
        revenue_sum: +item.revenue_sum,
        month: item.month,
      })) || [],
    [monthData],
  );

  return (
    <div className={styles.profitAndLossChart}>
      <ResponsiveContainer width="100%" height={480}>
        <BarChart
          data={formattedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={46}
        >
          <XAxis
            dataKey="month"
            xAxisId={1}
            tickFormatter={getMonthNameWithYearShorten}
            {...RECHARTS_AXIS_STYLES}
          />
          <XAxis dataKey="month" xAxisId={2} hide />
          <YAxis tickFormatter={yTickFormatter} {...RECHARTS_AXIS_STYLES} />
          <Tooltip
            formatter={formatNumberWithCommas}
            content={TooltipContent}
          />
          <Legend content={CustomLegend} />
          <Bar
            dataKey="revenue_sum"
            name="Revenue"
            fill="#4F46E5"
            xAxisId={1}
            fillOpacity={0.85}
            radius={[8, 8, 0, 0]}
          />
          <Bar
            dataKey="cost_adjustment"
            name="Cost"
            fill="#83CBFF"
            xAxisId={2}
            fillOpacity={0.85}
            radius={[8, 8, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

ProfitAndLossChart.propTypes = {
  monthData: PropTypes.array,
};

export default ProfitAndLossChart;
