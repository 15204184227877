export const findProject = ({ project, filteredDeals, dealId = null }) => {
  const reversedFilteredDeals = [...filteredDeals].reverse();

  if (dealId) {
    return reversedFilteredDeals.find(
      item => item.id === Number(dealId) && !item.deleted_at,
    );
  }

  return reversedFilteredDeals.find(
    item => item.project_id === Number(project) && !item.deleted_at,
  );
};
