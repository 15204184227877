import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Typography } from 'antd';
import { LINKS } from 'config/routing';
import { formatNumberWithCommas } from 'utils/helpers';
import { TableSearchProps } from 'components/TableSearchCommon';
import { DASHBOARD_SALARY_INDEX } from './constants';

const { Text } = Typography;

const renderEmployee = (value, { id }) => (
  <Link to={LINKS.profile(id)}>{value}</Link>
);

const getSortOrder = (sortInfo, condition) =>
  sortInfo.field === condition && sortInfo.order;

const filteredValue = (filterInfo, valueType) =>
  (filterInfo && filterInfo[valueType]) || null;

const getOrganizationFilters = organizations =>
  organizations?.map(org => ({
    text: org.name,
    value: org.id,
  })) || [];

const getFilters = filters =>
  (filters &&
    Object.entries(filters).map(([id, filter]) => ({
      text: filter,
      value: +id,
    }))) ||
  [];

export const getColumns = ({ filters, filterInfo, sortInfo, searchInput }) => [
  {
    title: DASHBOARD_SALARY_INDEX.full_name_title,
    dataIndex: DASHBOARD_SALARY_INDEX.full_name,
    key: DASHBOARD_SALARY_INDEX.full_name,
    sorter: true,
    sortOrder: getSortOrder(sortInfo, DASHBOARD_SALARY_INDEX.full_name),
    filteredValue: filteredValue(filterInfo, DASHBOARD_SALARY_INDEX.full_name),
    ...TableSearchProps({
      dataIndex: DASHBOARD_SALARY_INDEX.full_name,
      searchInput,
    }),
    render: renderEmployee,
  },
  {
    title: DASHBOARD_SALARY_INDEX.role_title,
    dataIndex: DASHBOARD_SALARY_INDEX.role,
    key: DASHBOARD_SALARY_INDEX.role,
    filters: getFilters(filters.roles),
    filteredValue: filteredValue(filterInfo, DASHBOARD_SALARY_INDEX.role),
    sorter: true,
    sortOrder: getSortOrder(sortInfo, DASHBOARD_SALARY_INDEX.role),
  },
  {
    title: DASHBOARD_SALARY_INDEX.rm_title,
    dataIndex: DASHBOARD_SALARY_INDEX.rm,
    key: DASHBOARD_SALARY_INDEX.rm,
    filters: getFilters(filters.rms),
    filteredValue: filteredValue(filterInfo, DASHBOARD_SALARY_INDEX.rm),
    sorter: true,
    sortOrder: getSortOrder(sortInfo, DASHBOARD_SALARY_INDEX.rm),
  },
  {
    title: DASHBOARD_SALARY_INDEX.salary_title,
    dataIndex: DASHBOARD_SALARY_INDEX.salary,
    key: DASHBOARD_SALARY_INDEX.salary,
    sorter: true,
    sortOrder: getSortOrder(sortInfo, DASHBOARD_SALARY_INDEX.salary),
    render: formatNumberWithCommas,
  },
  {
    title: DASHBOARD_SALARY_INDEX.extra_payments_title,
    dataIndex: DASHBOARD_SALARY_INDEX.extra_payments,
    key: DASHBOARD_SALARY_INDEX.extra_payments,
    sorter: true,
    sortOrder: getSortOrder(sortInfo, DASHBOARD_SALARY_INDEX.extra_payments),
    render: formatNumberWithCommas,
  },
  {
    title: DASHBOARD_SALARY_INDEX.total_title,
    dataIndex: DASHBOARD_SALARY_INDEX.total,
    key: DASHBOARD_SALARY_INDEX.total,
    sorter: true,
    sortOrder: getSortOrder(sortInfo, DASHBOARD_SALARY_INDEX.total),
    render: formatNumberWithCommas,
  },
  {
    title: DASHBOARD_SALARY_INDEX.organization_title,
    dataIndex: DASHBOARD_SALARY_INDEX.organization,
    key: DASHBOARD_SALARY_INDEX.organization,
    filters: getOrganizationFilters(filters.organizations),
    filteredValue: filteredValue(
      filterInfo,
      DASHBOARD_SALARY_INDEX.organization,
    ),
    sorter: true,
    sortOrder: getSortOrder(sortInfo, DASHBOARD_SALARY_INDEX.organization),
  },
];

export const getSummary = summary => {
  if (!summary) return;

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}>
        <Text strong>Total</Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1} />
      <Table.Summary.Cell index={2} />
      <Table.Summary.Cell index={3}>
        <Text strong> {formatNumberWithCommas(summary.sum_salary)}</Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3}>
        <Text strong>{formatNumberWithCommas(summary.extra_payments)}</Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={4}>
        <Text strong>{formatNumberWithCommas(summary.total)}</Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};
