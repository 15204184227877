/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTable, useBlockLayout } from 'react-table';
import { Affix } from 'antd';

import { DATE_FORMAT } from 'utils/timeConstants';
import { EMPLOYEE_COLUMN_KEY } from 'containers/ResourceManagementPage/constants';
import { TableRow } from '../index';

const Table = ({
  columns,
  data,
  onClickCell,
  onCloseOpenTree,
  indexRows,
  deletePlanById,
  deletePlanByType,
  plans,
  calendar,
  startDate,
  endDate,
  projects,
  canUpdatePlans,
  canUpdateDeals,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
  );
  const numberDaysOfMonth = columns.length - 1;
  const styleForTimeFields = {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px',
    paddingLeft: '0px',
    paddingRight: '0px',
    width: `calc((100% - 270px)/${numberDaysOfMonth})`,
  };
  const styleForUserFields = {
    width: '270px',
  };
  const currentDate = dayjs().format(DATE_FORMAT);

  return (
    <div className="table" {...getTableProps()}>
      <Affix>
        <div>
          {headerGroups.map(headerGroup => (
            <div className="tr" key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map(column => {
                const conditionCurDate = dayjs(currentDate).isSame(
                  column.valueColum,
                );

                return (
                  <div
                    key={column.id}
                    className="th"
                    style={
                      column.Header === EMPLOYEE_COLUMN_KEY
                        ? styleForUserFields
                        : {
                            ...styleForTimeFields,
                            border: conditionCurDate && '3px solid #1890ff',
                          }
                    }
                  >
                    <span style={{ width: '20px' }}>
                      {column.render('Header')}
                    </span>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </Affix>

      <div {...getTableBodyProps()}>
        {rows.map(item => (
          <TableRow
            key={item.index}
            rows={rows}
            indexRows={indexRows}
            prepareRow={prepareRow}
            index={item.index}
            onClickCell={onClickCell}
            onCloseOpenTree={onCloseOpenTree}
            deletePlanById={deletePlanById}
            deletePlanByType={deletePlanByType}
            plans={plans}
            numberDaysOfMonth={numberDaysOfMonth}
            calendar={calendar}
            startDate={startDate}
            endDate={endDate}
            projects={projects}
            canUpdatePlans={canUpdatePlans}
            canUpdateDeals={canUpdateDeals}
          />
        ))}
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  deletePlanById: PropTypes.func.isRequired,
  onClickCell: PropTypes.func.isRequired,
  onCloseOpenTree: PropTypes.func.isRequired,
  isOpenTree: PropTypes.bool,
  indexRows: PropTypes.array,
  plans: PropTypes.array,
  calendar: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  deletePlanByType: PropTypes.func.isRequired,
  projects: PropTypes.array,
  canUpdatePlans: PropTypes.bool,
  canUpdateDeals: PropTypes.bool,
};

export default Table;
