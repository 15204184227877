import React, { useState, useMemo } from 'react';
import { Select, Button } from 'antd';
import { get } from 'lodash';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const { Option } = Select;

const OwnerCommon = ({ users, selectOwner, ownerId, isDetails = false }) => {
  const [isShowSelector, setIsShowSelector] = useState(false);
  const [leadOwner, setLeadOwner] = useState('');

  const onClickOwner = () => setIsShowSelector(true);

  const onSelectOwner = owner => {
    setLeadOwner(owner);
  };

  const owner = useMemo(() => users.find(user => user.id === ownerId), [
    users,
    ownerId,
  ]);

  const ownerInfo = get(owner, 'name')
    ? get(owner, 'name')
    : get(owner, 'email');

  const onApproveSelect = () => {
    selectOwner(leadOwner);
    setIsShowSelector(false);
  };

  const onCancelSelect = () => {
    setLeadOwner('');
    setIsShowSelector(false);
  };

  return (
    <>
      {isShowSelector ? (
        <div style={{ position: 'relative' }}>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select owner"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={onSelectOwner}
            size="small"
          >
            {users.map(user => (
              <Option key={user.id} value={user.id}>
                {user.name || user.email}
              </Option>
            ))}
          </Select>
          <div
            className={
              isDetails
                ? styles.selectDetailsButtonContainer
                : styles.selectButtonContainer
            }
          >
            <Button
              icon={<CheckOutlined />}
              size="small"
              disabled={!leadOwner}
              onClick={onApproveSelect}
            />
            <Button
              icon={<CloseOutlined />}
              size="small"
              style={{ marginLeft: '2px' }}
              onClick={onCancelSelect}
            />
          </div>
        </div>
      ) : (
        <span onClick={onClickOwner} style={{ cursor: 'pointer' }}>
          {ownerInfo ? ownerInfo : 'No owner'}
        </span>
      )}
    </>
  );
};

OwnerCommon.propTypes = {
  users: PropTypes.array,
  selectOwner: PropTypes.func,
  ownerId: PropTypes.string,
  isDetails: PropTypes.bool,
};

export default OwnerCommon;
