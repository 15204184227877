import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isInvoiceTypesLoading: false,
  invoiceTypes: [],
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_INVOICE_TYPES:
      return state.set('isInvoiceTypesLoading', true);

    case types.GET_INVOICE_TYPES_SUCCESS:
      return state
        .set('isInvoiceTypesLoading', false)
        .set('invoiceTypes', payload.data);
    case types.CREATE_INVOICE_TYPE_SUCCESS:
    case types.UPDATE_INVOICE_TYPE_SUCCESS:
    case types.DELETE_INVOICE_TYPE_SUCCESS:
      return state.set('invoiceTypes', payload);

    case types.GET_INVOICE_TYPES_FAILED:
    case types.CREATE_INVOICE_TYPE_FAILED:
    case types.UPDATE_INVOICE_TYPE_FAILED:
    case types.DELETE_INVOICE_TYPE_FAILED:
      return state.set('isInvoiceTypesLoading', false);
    default:
      return state;
  }
}
