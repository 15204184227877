import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Avatar } from 'antd';

import styles from './styles.scss';

export const getInfoAboutAuthor = ({ email, employees, name }) => {
  if (!email) {
    return <span className={styles.author}>Deleted user</span>;
  }

  const author = employees.find(employee => get(employee, 'email') === email);

  if (author) {
    return (
      <>
        <Avatar
          src={get(author, ['jira_user', 'avatarUrls', '16x16'])}
          size={16}
          style={{ marginRight: 8 }}
        />
        <span className={styles.author}>{name}</span>
      </>
    );
  }

  return <span className={styles.author}>{name}</span>;
};

getInfoAboutAuthor.propTypes = {
  email: PropTypes.string,
  employees: PropTypes.array,
  name: PropTypes.string,
};
