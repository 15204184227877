export const types = {
  GET_CONTRACTS: 'contracts/GET_CONTRACTS',
  GET_CONTRACTS_SUCCESS: 'contracts/GET_CONTRACTS_SUCCESS',
  GET_CONTRACTS_FAILED: 'contracts/GET_CONTRACTS_FAILED',
  CREATE_CONTRACT: 'contracts/CREATE_CONTRACT',
  CREATE_CONTRACT_SUCCESS: 'contracts/CREATE_CONTRACT_SUCCESS',
  CREATE_CONTRACT_FAILED: 'contracts/CREATE_CONTRACT_FAILED',
  DELETE_CONTRACT: 'contracts/DELETE_CONTRACT',
  DELETE_CONTRACT_SUCCESS: 'contracts/DELETE_CONTRACT_SUCCESS',
  DELETE_CONTRACT_FAILED: 'contracts/DELETE_CONTRACT_FAILED',
  UPDATE_CONTRACT: 'contracts/UPDATE_CONTRACT',
  UPDATE_CONTRACT_SUCCESS: 'contracts/UPDATE_CONTRACT_SUCCESS',
  UPDATE_CONTRACT_FAILED: 'contracts/UPDATE_CONTRACT_FAILED',
};
