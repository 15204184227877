import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { Link } from 'react-router-dom';
import { getType } from './utils';
import styles from './styles.scss';

Notification.propTypes = {
  notification: PropTypes.object,
};

function Notification({ notification }) {
  const { title, subtitle, issues = [] } = notification;
  const type = useMemo(() => getType(issues), [issues]);

  return (
    <Alert
      showIcon
      type={type || 'info'}
      style={{ marginBottom: '5px' }}
      message={title}
      description={
        <div>
          <p>{subtitle}</p>
          <ul className={styles['issues-list']}>
            {issues.map(({ title, path }, index) => (
              //eslint-disable-next-line
              <li key={`${path}-${index}`}>
                {path && <Link to={`/${path}`}>{title}</Link>}
              </li>
            ))}
          </ul>
        </div>
      }
    />
  );
}

export default Notification;
