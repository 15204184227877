import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import { v4 } from 'uuid';
import {
  DeleteOutlined,
  EditOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';

import { ORGANIZATIONS_PERMISSIONS } from 'containers/Organizations/constants';
import TableButton from 'components/Common/TableButton';
import { hasRights } from 'utils/permissions';

import styles from './styles.scss';

export const getColumns = ({
  isEditing,
  onSave,
  onCancelEditOrCreate,
  onEditRow,
  editingKey,
  isAddAccountToggle,
  onDeleteAccount,
  role,
}) => {
  const canEdit = hasRights(role, ORGANIZATIONS_PERMISSIONS.updateAccount);
  const canDelete = hasRights(role, ORGANIZATIONS_PERMISSIONS.deleteAccount);

  return [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      editable: true,
    },
    {
      title: 'Bank name',
      dataIndex: 'bank_name',
      editable: true,
      key: 'bank_name',
    },
    {
      title: 'Bank address',
      dataIndex: 'bank_address',
      editable: true,
      key: 'bank_address',
    },
    {
      title: 'Billing address',
      dataIndex: 'billing_address',
      editable: true,
      key: 'billing_address',
    },
    {
      title: 'Account number',
      dataIndex: 'account_number',
      editable: true,
      key: 'account_number',
    },
    {
      title: 'Currency code',
      dataIndex: 'currency_code',
      editable: true,
      key: 'currency_code',
    },
    {
      title: 'Swift',
      dataIndex: 'swift',
      editable: true,
      key: 'swift',
    },
    {
      title: (
        <div className={styles.actionHeader}>
          <span>Actions</span>
          {hasRights(role, ORGANIZATIONS_PERMISSIONS.addAccount) && (
            <Button size="small" onClick={isAddAccountToggle}>
              Add account
            </Button>
          )}
        </div>
      ),
      dataIndex: 'actions',
      width: 120,
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);

        return editable ? (
          <div>
            <TableButton
              ghost
              type="primary"
              className={styles.tableButton}
              onClick={() => onSave(record)}
              icon={<CheckOutlined />}
            />
            <TableButton
              ghost
              danger
              icon={<CloseOutlined />}
              onClick={onCancelEditOrCreate}
            />
          </div>
        ) : (
          <>
            <TableButton
              icon={<EditOutlined />}
              onClick={() => onEditRow(record)}
              type="primary"
              className={styles.tableButton}
              ghost
              disabled={editingKey !== '' || !canEdit}
            />
            <TableButton
              className={styles.button}
              icon={<DeleteOutlined />}
              danger
              ghost
              disabled={!canDelete}
              onClick={() => onDeleteAccount(record)}
            />
          </>
        );
      },
    },
  ];
};

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `This field is required`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.string,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.string,
  record: PropTypes.object,
  index: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const formatNewAccount = account => ({
  ...account,
  id: v4(),
});
