import React, { useMemo, useEffect, useState } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';

import DatePicker from 'components/Common/DatePicker';
import DealOverviewForm from 'components/ClientCommon/ClientModal/DealOverviewForm';
import { findProject } from 'components/ClientCommon/ClientModal/ClientSmartProject/utils';
import { Loader } from 'components/Common';
import { getColumns, formatOverviewData } from './utils';
import styles from './styles.scss';

const ClientOverview = ({
  jiraRoles,
  currencies,
  project,
  filteredDeals,
  allocations,
  allocationsData,
  deletedAllocations,
  dealValues,
  dealStartsAt,
  dealId = null,
  isLoading,
}) => {
  const [formattedOverviewData, setFormattedOverviewData] = useState([]);

  const previousDeal = useMemo(
    () => findProject({ filteredDeals: filteredDeals, project, dealId }),
    [filteredDeals, project, dealId],
  );

  const columns = getColumns({
    jiraRoles: groupBy(jiraRoles, 'id'),
    currencies,
  });

  useEffect(() => {
    setFormattedOverviewData(
      formatOverviewData({ deletedAllocations, allocationsData, allocations }),
    );
  }, [deletedAllocations, allocationsData, allocations]);

  return (
    <Loader loading={isLoading}>
      <div>
        <div className={styles.dealInfoContainer}>
          <div className={styles.dealFormContainer}>
            <div>
              <div>Starts at</div>
              <DatePicker
                disabled
                value={dealStartsAt}
                style={{
                  width: '100%',
                  marginTop: '7px',
                  marginBottom: '15px',
                }}
              />
            </div>
            <DealOverviewForm
              dealFormValues={dealValues}
              previousDeal={previousDeal}
            />
          </div>
          <div className={styles.allocationContainer}>
            <Table
              columns={columns}
              pagination={false}
              bordered={false}
              dataSource={formattedOverviewData}
              size="small"
            />
          </div>
        </div>
      </div>
    </Loader>
  );
};

ClientOverview.propTypes = {
  project: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filteredDeals: PropTypes.array,
  dealId: PropTypes.number,
  currencies: PropTypes.array,
  allocations: PropTypes.object,
  allocationsData: PropTypes.array,
  deletedAllocations: PropTypes.array,
  dealValues: PropTypes.object,
  dealStartsAt: PropTypes.object,
  jiraRoles: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default ClientOverview;
