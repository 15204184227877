import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { getUpOrDownIcon } from '../../utils';
import styles from './styles.scss';

const ProfitAndLossTotalItem = ({
  title,
  tooltipContent,
  penultimateMonthValue,
  lastMonthValue,
  penultimateMonthFormattedValue,
  lastMonthFormattedValue,
  penultimateMonth,
  lastMonth,
}) => (
  <div className={styles.profitAndLossTotalItem}>
    <div className={styles.title}>
      <span className={styles.titleText}>{title}</span>
      <Tooltip title={tooltipContent}>
        <InfoCircleOutlined className={styles.tooltip} />
      </Tooltip>
    </div>
    <div className={styles.content}>
      <div className={styles.stat}>
        <div className={styles.icon}>
          {getUpOrDownIcon(penultimateMonthValue, lastMonthValue)}
        </div>
        <div className={styles.value}>{penultimateMonthFormattedValue}</div>
        <div className={styles.month}>{penultimateMonth}</div>
      </div>
      <div className={styles.stat}>
        <div className={styles.icon}>
          {getUpOrDownIcon(penultimateMonthValue, lastMonthValue)}
        </div>
        <div className={styles.value}>{lastMonthFormattedValue}</div>
        <div className={styles.month}>{lastMonth}</div>
      </div>
    </div>
  </div>
);

ProfitAndLossTotalItem.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipContent: PropTypes.string.isRequired,
  penultimateMonthValue: PropTypes.number,
  lastMonthValue: PropTypes.number,
  penultimateMonthFormattedValue: PropTypes.string,
  lastMonthFormattedValue: PropTypes.string,
  penultimateMonth: PropTypes.string,
  lastMonth: PropTypes.string,
};

export default ProfitAndLossTotalItem;
