import { actionFactory } from 'redux/factories';
import { types } from './types';

export const getLeads = actionFactory(types.GET_LEADS);
export const getLeadData = actionFactory(types.GET_LEAD_DATA);
export const getLeadActivities = actionFactory(types.GET_LEAD_ACTIVITIES);
export const getLeadAttachments = actionFactory(types.GET_LEAD_ATTACHMENTS);
export const updateLeadActivities = actionFactory(types.UPDATE_LEAD_ACTIVITIES);
export const selectLeadOwner = actionFactory(types.SELECT_LEAD_OWNER);
export const saveLeadNote = actionFactory(types.SAVE_LEAD_NOTE);
export const getLeadAudit = actionFactory(types.GET_LEAD_AUDIT);
export const updateLeadNote = actionFactory(types.UPDATE_LEAD_NOTE);
export const selectLeadStatus = actionFactory(types.UPDATE_LEAD_STATUS);
export const getLeadGeo = actionFactory(types.GET_LEAD_GEO);
export const getLeadNotes = actionFactory(types.GET_LEAD_NOTES);
export const updateLead = actionFactory(types.UPDATE_LEAD);
