import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Tag from 'components/Common/Tag';
import { OK_STATUS } from 'utils/constants';
import {
  formatProgressDuration,
  formatProgressStatus,
  getTagColorByProgressStatus,
} from 'containers/ProjectProfits/utils';

import { DATE_MONTH_FORMAT } from 'utils/timeConstants';
import styles from './styles.scss';

const JiraProgress = ({ progress }) => {
  if (!progress) return null;

  return (
    <div className={styles.jiraProgress}>
      {progress.status === OK_STATUS ? (
        `Last updated: ${dayjs(progress.updated_at.seconds * 1000).format(
          DATE_MONTH_FORMAT,
        )}`
      ) : (
        <div>
          <Tag color={getTagColorByProgressStatus(progress.status)}>
            {formatProgressStatus(progress)}
          </Tag>
          {formatProgressDuration(progress.duration)}
        </div>
      )}
    </div>
  );
};

JiraProgress.propTypes = {
  progress: PropTypes.object,
};

export default JiraProgress;
