import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { STATUS_TAG_COLOR } from 'containers/LeadPage/constants';
import Tag from 'components/Common/Tag';

import styles from './styles.scss';

const LeadsPageHeader = ({ leadData: { status, name }, ownerName }) => (
  <div className={styles.leadHeader}>
    <Avatar size={120} icon={<UserOutlined />} className={styles.avatar} />
    <span className={styles.title}>{name}</span>
    <span className={styles.ownerName}>{ownerName}</span>
    <Tag className={styles.tag} color={STATUS_TAG_COLOR[status]}>
      {status}
    </Tag>
  </div>
);

LeadsPageHeader.propTypes = {
  leadData: PropTypes.object,
  ownerName: PropTypes.string,
};

export default LeadsPageHeader;
