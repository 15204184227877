import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row } from 'antd';
import Input from 'components/Common/Input';
import TextArea from 'components/Common/TextArea';

const { Item } = Form;

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 20 },
};

const ClientSummaryForm = ({ fieldValues, onUpdateClient, form }) => (
  <Form
    form={form}
    onFinish={onUpdateClient}
    {...layout}
    fields={[
      { name: 'title', value: fieldValues.title },
      { name: 'location', value: fieldValues.location },
      { name: 'description', value: fieldValues.description },
    ]}
  >
    <Item
      label="Title"
      key="title"
      name="title"
      rules={[
        {
          required: true,
          message: 'This field is required.',
        },
      ]}
    >
      <Input />
    </Item>
    <Item
      label="Location"
      key="location"
      name="location"
      rules={[
        {
          required: true,
          message: 'This field is required.',
        },
      ]}
    >
      <Input />
    </Item>
    <Item label="Description" key="description" name="description">
      <TextArea rows={4} />
    </Item>
  </Form>
);

ClientSummaryForm.propTypes = {
  fieldValues: PropTypes.object,
  onUpdateClient: PropTypes.func,
  form: PropTypes.object,
};

export default ClientSummaryForm;
