import { takeLatest, call, put } from 'redux-saga/effects';
import { SAGA_MESSAGES } from 'redux/sagaMessages';

import API from 'utils/api';
import { sagaErrorHandler } from 'utils/common';

import { types } from './types';

function* getDashboardEffectiveness({ payload }) {
  try {
    const { data } = yield call(API.getDashboardEffectiveness, payload);
    yield put({
      type: types.GET_DASHBOARD_EFFECTIVENESS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_DASHBOARD_EFFECTIVENESS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getDashboardEmployees({ payload }) {
  try {
    const { data } = yield call(API.getDashboardEmployees, payload);
    yield put({ type: types.GET_DASHBOARD_EMPLOYEES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_DASHBOARD_EMPLOYEES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getDashboardEmployeeData({ payload }) {
  try {
    const { data } = yield call(API.getDashboardEmployeeData, payload);
    yield put({
      type: types.GET_DASHBOARD_EMPLOYEE_DATA_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_DASHBOARD_EMPLOYEE_DATA_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getDashboardOverview({ payload }) {
  try {
    const { data } = yield call(API.getDashboardOverview, payload);
    yield put({ type: types.GET_DASHBOARD_OVERVIEW_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_DASHBOARD_OVERVIEW_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getDashboardSalaryChart({ payload }) {
  try {
    const { data } = yield call(API.getDashboardSalaryChart, payload);
    yield put({
      type: types.GET_DASHBOARD_SALARY_CHART_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_DASHBOARD_SALARY_CHART_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getDashboardSalaryTable({ payload }) {
  try {
    const { data } = yield call(API.getDashboardSalaryTable, payload);
    yield put({
      type: types.GET_DASHBOARD_SALARY_TABLE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_DASHBOARD_SALARY_TABLE_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getDashboardSubcontractorsTable({ payload }) {
  try {
    const { data } = yield call(API.getDashboardSubcontractorsTable, payload);
    yield put({
      type: types.GET_DASHBOARD_SUBCONTRACTORS_TABLE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_DASHBOARD_SUBCONTRACTORS_TABLE_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getDashboardSubcontractors({ payload }) {
  try {
    const { data: overallData } = yield call(
      API.getDashboardSubcontractorsOverall,
      payload,
    );
    const { data: tableData } = yield call(
      API.getDashboardSubcontractorsTable,
      payload,
    );

    yield put({
      type: types.GET_DASHBOARD_SUBCONTRACTORS_SUCCESS,
      payload: { overallData, tableData },
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_DASHBOARD_SUBCONTRACTORS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(
    types.GET_DASHBOARD_EFFECTIVENESS,
    getDashboardEffectiveness,
  );
  yield takeLatest(types.GET_DASHBOARD_EMPLOYEES, getDashboardEmployees);
  yield takeLatest(types.GET_DASHBOARD_EMPLOYEE_DATA, getDashboardEmployeeData);
  yield takeLatest(types.GET_DASHBOARD_OVERVIEW, getDashboardOverview);
  yield takeLatest(types.GET_DASHBOARD_SALARY_CHART, getDashboardSalaryChart);
  yield takeLatest(types.GET_DASHBOARD_SALARY_TABLE, getDashboardSalaryTable);
  yield takeLatest(
    types.GET_DASHBOARD_SUBCONTRACTORS_TABLE,
    getDashboardSubcontractorsTable,
  );
  yield takeLatest(
    types.GET_DASHBOARD_SUBCONTRACTORS,
    getDashboardSubcontractors,
  );
}
