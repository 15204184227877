/* eslint-disable indent */
import React, { useState, useEffect, useMemo } from 'react';
import { Table, Form } from 'antd';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';

import { CREATION_ROW, BUTTON_ROW } from './constants';
import {
  components,
  getColumns,
  getDataWithoutEmptyFields,
  getSummary,
} from './utils';
import styles from './styles.scss';

const ClientAllocationTable = ({
  dataSource,
  jiraRoles,
  currencies,
  allocationsData,
  setAllocationsData,
  isFinishEditDeal,
  setDeletedAllocations,
  setIsDisableNextButton,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [jiraRolesObj, setJiraRolesObj] = useState(null);
  const [isAddAllocation, setIsAddAllocation] = useState(false);

  const isEditing = record => record.key === editingKey;

  const onEditRow = record => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const onCancelEditOrCreate = () => {
    if (isAddAllocation) {
      setIsAddAllocation(false);
      setAllocationsData(prev => [
        ...prev.filter(item => item.key !== CREATION_ROW.key),
        BUTTON_ROW,
      ]);
    }

    setEditingKey('');
  };

  const onSave = async record => {
    const isValidate = await form.validateFields();

    if (isValidate) {
      const values = form.getFieldsValue();
      const dataWithoutEmptyFields = allocationsData.filter(
        item => item.jira_role_id,
      );

      if (isAddAllocation) {
        setAllocationsData([
          ...dataWithoutEmptyFields,
          { ...values, key: allocationsData.length, is_new: true },
          BUTTON_ROW,
        ]);
      } else {
        const updatedData = dataWithoutEmptyFields.map(item => {
          if (item.key === editingKey)
            return item.is_new
              ? {
                  ...values,
                  key: editingKey,
                  is_new: true,
                  old_values: record,
                  id: item.id,
                }
              : {
                  ...values,
                  key: editingKey,
                  edited: true,
                  old_values: record,
                  id: item.id,
                };

          return item;
        });

        setAllocationsData([...updatedData, BUTTON_ROW]);
      }

      setIsAddAllocation(false);
      setEditingKey('');
    }
  };

  const handleAdd = () => {
    if (isAddAllocation) {
      setEditingKey(CREATION_ROW.key);
      setAllocationsData(prev => [
        ...prev.filter(item => item.jira_role_id),
        CREATION_ROW,
      ]);
    } else {
      setEditingKey('');
    }

    form.resetFields();
  };

  const onDeleteAllocation = record => {
    if (record) {
      setAllocationsData(
        allocationsData.filter(item => item.key !== record.key),
      );

      if (record.id) {
        setDeletedAllocations(prev => [...prev, { ...record, deleted: true }]);
      }
    }
  };

  const isAddAllocationToggle = () => setIsAddAllocation(prev => !prev);

  const columns = useMemo(
    () =>
      getColumns({
        isEditing,
        onSave,
        onCancelEditOrCreate,
        onEditRow,
        editingKey,
        isAddAllocationToggle,
        jiraRoles: jiraRolesObj,
        currencies,
        onDeleteAllocation,
      }),
    [dataSource, editingKey, allocationsData, jiraRolesObj],
  );

  const getInputType = columnKey => {
    switch (columnKey) {
      case 'position':
        return 'selectPosition';
      case 'condition':
        return 'selectCondition';
      case 'rate':
        return 'rate';
      case 'allocation':
        return 'allocation';
      default:
        return 'text';
    }
  };

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: getInputType(col.key),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        jiraRoles,
        currencies,
        jiraRolesObj,
        form,
        allocationsData,
      }),
    };
  });

  useEffect(() => {
    handleAdd();
  }, [isAddAllocation]);

  useEffect(() => {
    setJiraRolesObj(groupBy(jiraRoles, 'id'));
  }, [jiraRoles]);

  useEffect(() => {
    const dataWithoutEmptyFields = getDataWithoutEmptyFields(allocationsData);
    setAllocationsData([...dataWithoutEmptyFields, BUTTON_ROW]);
  }, []);

  useEffect(() => {
    setIsDisableNextButton(isAddAllocation || !!editingKey);
  }, [isAddAllocation, editingKey]);

  useEffect(() => {
    if (dataSource && !isFinishEditDeal) {
      const dataSourceWithKey = dataSource.reduce((acc, cur) => {
        if (cur.jira_role_id && !cur.deleted_at) {
          return [
            ...acc,
            {
              ...cur,
              key: `${cur.id}_${cur.jira_role_id}`,
            },
          ];
        }

        return acc;
      }, []);
      setAllocationsData([...dataSourceWithKey, BUTTON_ROW]);
    }
  }, [dataSource]);

  const getRowKey = record => record.id || record.key;

  return (
    <div className={styles.allocationContainer}>
      <Form form={form} component={false}>
        <Table
          components={components}
          dataSource={allocationsData}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          size="small"
          rowKey={getRowKey}
          summary={getSummary}
        />
      </Form>
    </div>
  );
};

ClientAllocationTable.propTypes = {
  dataSource: PropTypes.array,
  jiraRoles: PropTypes.array,
  currencies: PropTypes.array,
  allocationsData: PropTypes.array,
  setAllocationsData: PropTypes.func,
  isFinishEditDeal: PropTypes.bool,
  setDeletedAllocations: PropTypes.func,
  setIsDisableNextButton: PropTypes.func,
};

export default ClientAllocationTable;
