import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Steps, Button, Typography, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { formatDealValues, getSteps } from 'containers/ClientPage/utils';
import { createClientDeal, updateClientDeal } from 'redux/Clients/actions';

import {
  clientsSelector,
  currenciesSelector,
  jiraSelector,
} from 'redux/selectors';
import styles from './styles.scss';

const { Step } = Steps;

const { Text } = Typography;

const DealSteps = ({
  status,
  isDisableNextButton,
  setIsDisableNextButton,
  onHideClientModal,
  filteredDeals,
  allocationsGroupByDeal,
  setDealValues,
  dealValues,
  allocationsData,
  setAllocationsData,
  editingDealId,
}) => {
  const [currentDealStep, setCurrentDealStep] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [project, setProject] = useState('');
  const [isFinishEditDeal, setIsFinishEditDeal] = useState(false);
  const [deletedAllocations, setDeletedAllocations] = useState([]);
  const [dealStartsAt, setDealStartsAt] = useState(null);

  const [dealForm] = Form.useForm();

  const dispatch = useDispatch();

  const { jiraProjects, jiraRoles } = useSelector(jiraSelector);

  const { currencies } = useSelector(currenciesSelector);

  const {
    client: { id },
    isClientsLoading,
  } = useSelector(clientsSelector);

  useEffect(() => {
    if (editingDealId && isFirstRender) {
      setCurrentDealStep(1);
      setIsFirstRender(false);
    }
  }, [editingDealId, isFirstRender]);

  useEffect(() => {
    if (editingDealId && dealValues) {
      setProject(dealValues.project_id);
      setDealStartsAt(dayjs(dealValues.starts_at));
    }
  }, [editingDealId]);

  const onFinish = () => {
    onHideClientModal();
    setIsFirstRender(true);
    setCurrentDealStep(0);
    setProject('');
    setIsFinishEditDeal(false);
    setDeletedAllocations([]);
    setDealStartsAt(null);
    dealForm.resetFields();
    setDealValues(null);
  };

  const onChangeDealStartsAt = value => {
    setDealStartsAt(value);
  };

  const dealSteps = getSteps({
    projects: jiraProjects,
    form: dealForm,
    jiraRoles,
    setProject,
    filteredDeals,
    project,
    allocations: allocationsGroupByDeal,
    currencies,
    allocationsData,
    setAllocationsData,
    isFinishEditDeal,
    setDeletedAllocations,
    deletedAllocations,
    dealValues,
    dealStartsAt,
    onChangeDealStartsAt,
    setIsDisableNextButton,
    isLoading: isClientsLoading,
  });

  const onSaveDeal = ({ dealValues }) => {
    dispatch(
      createClientDeal({
        dealValues: formatDealValues({
          dealValues,
          id,
          dealStartsAt,
          isCreate: true,
          project,
        }),
        allocationsData,
        clientId: id,
      }),
    );
  };

  const onUpdateDeal = ({ values, dealId, allocationsData }) => {
    dispatch(
      updateClientDeal({
        values: formatDealValues({
          dealValues: values,
          id,
          dealStartsAt,
          project,
        }),
        dealId,
        clientId: id,
        allocationsData,
        isUpdate: true,
      }),
    );
  };

  const next = () => {
    setCurrentDealStep(prev => prev + 1);
  };

  const prev = () => {
    setCurrentDealStep(prev => prev - 1);

    if (currentDealStep === 1) {
      setIsFinishEditDeal(false);
    }
  };

  useEffect(() => {
    if (status >= 200 && status < 300) {
      next();
      onFinish();
    }
  }, [status]);

  const onDealEditCompleted = async () => {
    const isValidate = await dealForm.validateFields();

    if (isValidate) {
      const values = dealForm.getFieldsValue();
      setIsFinishEditDeal(true);
      setDealValues(values);
      next();
    }
  };

  const onProjectChosen = () => {
    dealForm.resetFields();
    setAllocationsData([]);
    setIsDisableNextButton(false);
    next();
  };

  const onSave = () => {
    editingDealId
      ? onUpdateDeal({
          dealId: editingDealId,
          values: dealValues,
          allocationsData,
        })
      : onSaveDeal({ dealValues });
  };

  return (
    <>
      <Steps
        current={currentDealStep}
        className={styles.clientDealSteps}
        size="small"
      >
        {dealSteps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      {!project && currentDealStep === 1 && (
        <Text type="danger">
          Project was not selected, please return on the first step and choose
          the project
        </Text>
      )}
      <div className="steps-content">{dealSteps[currentDealStep].content}</div>
      <div className={styles.stepActions}>
        {currentDealStep > 0 && currentDealStep !== 3 && (
          <Button
            className={styles.prevButton}
            onClick={prev}
            disabled={!!editingDealId && currentDealStep === 1}
          >
            Previous
          </Button>
        )}
        {currentDealStep === 0 && (
          <Button type="primary" onClick={onProjectChosen} disabled={!project}>
            Next
          </Button>
        )}
        {currentDealStep === 1 && (
          <Button
            type="primary"
            onClick={onDealEditCompleted}
            disabled={!dealStartsAt || isDisableNextButton || !project}
          >
            Next
          </Button>
        )}
        {currentDealStep === 2 && (
          <Button type="primary" onClick={onSave} loading={isClientsLoading}>
            Confirm & Save
          </Button>
        )}
      </div>
    </>
  );
};

DealSteps.propTypes = {
  status: PropTypes.number,
  onHideClientModal: PropTypes.func.isRequired,
  setIsDisableNextButton: PropTypes.func.isRequired,
  isDisableNextButton: PropTypes.bool.isRequired,
  filteredDeals: PropTypes.array.isRequired,
  allocationsGroupByDeal: PropTypes.object.isRequired,
  setDealValues: PropTypes.func.isRequired,
  dealValues: PropTypes.object,
  allocationsData: PropTypes.array.isRequired,
  setAllocationsData: PropTypes.func.isRequired,
  editingDealId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DealSteps;
