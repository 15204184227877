exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dashboardAERTotalProjectsItem-d6cd9 .item-c9ceb{display:flex;justify-content:space-between;align-items:center;margin-bottom:6px;padding:10px 6px;border:1px solid #d9d9d9;text-align:center}.dashboardAERTotalProjectsItem-d6cd9 .item-c9ceb div{width:30%}.dashboardAERTotalProjectsItem-d6cd9 .item-c9ceb .icon-768b1{width:10%}.dashboardAERTotalProjectsItem-d6cd9 .item-c9ceb .firstPeriod-ef1e0,.dashboardAERTotalProjectsItem-d6cd9 .item-c9ceb .secondPeriod-3cfe6{width:30%}.dashboardAERTotalProjectsItem-d6cd9 .item-c9ceb .title-a021e{width:25%;text-align:left;font-weight:500}.dashboardAERTotalProjectsItem-d6cd9 .label-c4e15{display:inline-block;margin-bottom:4px;font-size:14px;opacity:.8}", ""]);

// Exports
exports.locals = {
	"dashboardAERTotalProjectsItem": "dashboardAERTotalProjectsItem-d6cd9",
	"item": "item-c9ceb",
	"icon": "icon-768b1",
	"firstPeriod": "firstPeriod-ef1e0",
	"secondPeriod": "secondPeriod-3cfe6",
	"title": "title-a021e",
	"label": "label-c4e15"
};