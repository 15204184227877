import React from 'react';
import { isArray, groupBy, get } from 'lodash';

import ClientSmartProject from 'components/ClientCommon/ClientModal/ClientSmartProject';
import ClientOverview from 'components/ClientCommon/ClientModal/ClientOverview';
import { DEALS_INFO } from './constants';

export const replaceIdWithName = ({
  dataSource,
  projects,
  users,
  clientTitle,
}) => {
  if (isArray(projects)) {
    const projectsObj = groupBy(projects, 'id');

    return dataSource.map(item => {
      if (item.title === 'project_id') {
        return {
          ...item,
          title: 'Project',
          old_value:
            get(projectsObj, item.old_value) &&
            get(projectsObj, item.old_value)[0].name,
          new_value:
            get(projectsObj, item.new_value) &&
            get(projectsObj, item.new_value)[0].name,
        };
      }

      if (Object.keys(DEALS_INFO).includes(item.title)) {
        return {
          ...item,
          title: DEALS_INFO[item.title],
        };
      }

      if (item.title === 'owner_id') {
        return {
          ...item,
          title: 'Owner',
          old_value: get(
            users,
            [item.old_value, 'name'],
            get(users, [item.old_value, 'email']),
          ),
          new_value: get(
            users,
            [item.new_value, 'name'],
            get(users, [item.new_value, 'email']),
          ),
        };
      }

      if (item.title === 'client_id') {
        return {
          ...item,
          title: 'Client',
          new_value: clientTitle,
        };
      }

      return item;
    });
  }

  return dataSource;
};

export const getFilteredClientAudit = ({
  clientAuditWithGroupDealAllocations,
  dealId,
}) =>
  clientAuditWithGroupDealAllocations.filter(
    item => item.auditable_type === 'DealAllocation' && item.deal_id === dealId,
  );

export const getSteps = ({
  organizations,
  dealFormValues,
  form,
  jiraRoles,
  filteredDeals,
  allocations,
  currencies,
  allocationsData,
  setAllocationsData,
  dealStartsAt,
  onChangeDealStartsAt,
  project,
  deletedAllocations,
  dealValues,
  setDeletedAllocations,
  dealId,
  isFinishEditDeal,
  setIsDisableNextButton,
  onChangeFormValue,
}) => [
  {
    title: 'Deal',
    content: (
      <ClientSmartProject
        organizations={organizations}
        dealFormValues={dealFormValues}
        form={form}
        jiraRoles={jiraRoles}
        filteredDeals={filteredDeals}
        allocations={allocations}
        currencies={currencies}
        allocationsData={allocationsData}
        setAllocationsData={setAllocationsData}
        dealStartsAt={dealStartsAt}
        onChangeDealStartsAt={onChangeDealStartsAt}
        setDeletedAllocations={setDeletedAllocations}
        isFinishEditDeal={isFinishEditDeal}
        setIsDisableNextButton={setIsDisableNextButton}
        onChangeFormValue={onChangeFormValue}
      />
    ),
  },
  {
    title: 'Overview',
    content: (
      <ClientOverview
        currencies={currencies}
        jiraRoles={jiraRoles}
        filteredDeals={filteredDeals}
        project={project}
        allocations={allocations}
        allocationsData={allocationsData}
        deletedAllocations={deletedAllocations}
        dealValues={dealValues}
        dealStartsAt={dealStartsAt}
        dealId={dealId}
      />
    ),
  },
];
