import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import dayjs from 'dayjs';

import TimelineItem from 'components/Common/TimelineItem';
import { FULL_DATE_FORMAT } from 'utils/timeConstants';
import { mapArrayToEntities } from 'utils/common';

import styles from 'containers/LeadPage/styles.scss';
import { filterEventsByType, getTimeLineColor } from './utils';
import LeadTimelineItem from './LeadTimelineItem';

const LeadTimeline = ({
  leadData,
  leadAudit,
  tab,
  updateLeadNote,
  users,
  leadAttachments,
  role,
  notesObject,
}) => {
  const createdAt = dayjs(leadData.created_at).format(FULL_DATE_FORMAT);

  const filteredAudit = useMemo(() => filterEventsByType(leadAudit, tab), [
    tab,
    leadAudit,
  ]);

  const usersObject = useMemo(() => mapArrayToEntities(users).entities, [
    users,
  ]);

  return (
    <Timeline className={styles.leadsTimeline}>
      {filteredAudit.map(({ id, ...event }) => (
        <TimelineItem color={getTimeLineColor(event.action)}>
          <LeadTimelineItem
            event={event}
            updateLeadNote={updateLeadNote}
            leadAttachments={leadAttachments}
            usersObject={usersObject}
            role={role}
            notesObject={notesObject}
          />
        </TimelineItem>
      ))}
      <TimelineItem>
        Lead created<p>{createdAt}</p>
      </TimelineItem>
    </Timeline>
  );
};

LeadTimeline.propTypes = {
  leadData: PropTypes.object,
  leadAudit: PropTypes.array,
  leadAttachments: PropTypes.array,
  users: PropTypes.array,
  tab: PropTypes.string,
  role: PropTypes.string,
  updateLeadNote: PropTypes.func,
  onShowModal: PropTypes.func,
  notesObject: PropTypes.object,
};

export default LeadTimeline;
