import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Input from 'components/Common/Input';
import TextArea from 'components/Common/TextArea';

const { Item } = Form;

const ClientForm = ({ form, onFinish, fieldValues = {} }) => (
  <Form
    form={form}
    layout="vertical"
    id="clientForm"
    onFinish={onFinish}
    fields={[
      { name: 'title', value: get(fieldValues, 'title', '') },
      { name: 'location', value: get(fieldValues, 'location', '') },
      { name: 'description', value: get(fieldValues, 'description', '') },
    ]}
  >
    <Item
      label="Title"
      key="title"
      name="title"
      rules={[
        {
          required: true,
          message: 'This field is  required.',
        },
      ]}
    >
      <Input />
    </Item>
    <Item
      label="Location"
      key="location"
      name="location"
      rules={[
        {
          required: true,
          message: 'This field is  required.',
        },
      ]}
    >
      <Input />
    </Item>
    <Item
      label="Description"
      key="description"
      name="description"
      rules={[
        {
          message: 'This field is  required.',
        },
      ]}
    >
      <TextArea rows={4} />
    </Item>
  </Form>
);

ClientForm.propTypes = {
  form: PropTypes.object,
  onFinish: PropTypes.func,
  fieldValues: PropTypes.object,
};

export default ClientForm;
