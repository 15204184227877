exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".salaries-8c840{min-width:290px;padding:24px;border-right:1px solid #d9d9d9}.salaries-8c840 .title-d5d5c{margin-bottom:12px;font-size:20px;font-weight:600;line-height:28px;color:#262626;margin-bottom:30px}.salaries-8c840 .salary-6742e{display:inline-block;width:120px;font-weight:600}.salaries-8c840 .field-1445e{width:fit-content}.salaries-8c840 .actions-ec110{margin-bottom:15px}.salaries-8c840 .actionButton-6dc41{margin-left:5px;margin-bottom:5px}.salaries-8c840 .timeline-82add li:last-child{padding-bottom:0px}.salaries-8c840 .date-62077{color:#595959}", ""]);

// Exports
exports.locals = {
	"salaries": "salaries-8c840",
	"title": "title-d5d5c",
	"salary": "salary-6742e",
	"field": "field-1445e",
	"actions": "actions-ec110",
	"actionButton": "actionButton-6dc41",
	"timeline": "timeline-82add",
	"date": "date-62077"
};