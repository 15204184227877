exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".lead-summary-d5d64{padding:0;margin:0;list-style:none}.lead-summary_vertical-fd2a0 .lead-summary-item-9bb06{display:block}.lead-summary-item-9bb06{list-style:none;padding:5px 10px}.lead-summary_horizontal-e06bc .lead-summary-item-9bb06{display:inline-block}.lead-summary_horizontal-e06bc .lead-summary-item-9bb06:first-child{padding-left:0}.infoIcon-badac{margin:5px}.ownerIcon-846b6{margin:3px 5px 5px 5px}.itemDescription-a9849{display:flex}.itemMoney-313c3{display:flex;align-items:center}.selectButtonContainer-bf56b{position:absolute;right:0px;bottom:-28px;padding:2px;background:#fff}.selectDetailsButtonContainer-6ec14{position:absolute;right:-54px;bottom:-2px;padding:2px;background:#fff}", ""]);

// Exports
exports.locals = {
	"lead-summary": "lead-summary-d5d64",
	"lead-summary_vertical": "lead-summary_vertical-fd2a0",
	"lead-summary-item": "lead-summary-item-9bb06",
	"lead-summary_horizontal": "lead-summary_horizontal-e06bc",
	"infoIcon": "infoIcon-badac",
	"ownerIcon": "ownerIcon-846b6",
	"itemDescription": "itemDescription-a9849",
	"itemMoney": "itemMoney-313c3",
	"selectButtonContainer": "selectButtonContainer-bf56b",
	"selectDetailsButtonContainer": "selectDetailsButtonContainer-6ec14"
};