import React from 'react';
import dayjs from 'dayjs';
import { Dropdown } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  RollbackOutlined,
} from '@ant-design/icons';

import { DATE_MONTH_FORMAT } from 'utils/timeConstants';

import { USERS_TABLE_COMMON } from './constants';

const getSortOrder = (sortInfo, condition) =>
  sortInfo.field === condition && sortInfo.order;

export function getTableColumns({
  onDelete,
  sortInfo,
  onRestore,
  onEdit,
  roles,
}) {
  return [
    {
      title: USERS_TABLE_COMMON.id_title,
      sorter: true,
      sortOrder: getSortOrder(sortInfo, USERS_TABLE_COMMON.id),
      dataIndex: USERS_TABLE_COMMON.id,
      key: USERS_TABLE_COMMON.id,
    },
    {
      title: USERS_TABLE_COMMON.name_title,
      sorter: true,
      sortOrder: getSortOrder(sortInfo, USERS_TABLE_COMMON.name),
      dataIndex: USERS_TABLE_COMMON.name,
      key: USERS_TABLE_COMMON.name,
    },
    {
      title: USERS_TABLE_COMMON.email_title,
      sorter: true,
      sortOrder: getSortOrder(sortInfo, USERS_TABLE_COMMON.email),
      dataIndex: USERS_TABLE_COMMON.email,
      key: USERS_TABLE_COMMON.email,
    },
    {
      title: USERS_TABLE_COMMON.created_at_title,
      sorter: true,
      sortOrder: getSortOrder(sortInfo, USERS_TABLE_COMMON.created_at),
      dataIndex: USERS_TABLE_COMMON.created_at,
      key: USERS_TABLE_COMMON.created_at,
      render: date => date && dayjs(date).format(DATE_MONTH_FORMAT),
    },
    {
      title: USERS_TABLE_COMMON.updated_at_title,
      sorter: true,
      sortOrder: getSortOrder(sortInfo, USERS_TABLE_COMMON.updated_at),
      dataIndex: USERS_TABLE_COMMON.updated_at,
      key: USERS_TABLE_COMMON.updated_at,
      render: date => date && dayjs(date).format(DATE_MONTH_FORMAT),
    },
    {
      title: USERS_TABLE_COMMON.role_id_title,
      dataIndex: USERS_TABLE_COMMON.role_id,
      key: USERS_TABLE_COMMON.role_id,
      render: roleId => roles[roleId],
    },
    {
      title: ' ',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div>
          <Dropdown
            menu={{
              items: [
                {
                  label: 'Edit',
                  key: 'Edit',
                  onClick: () => onEdit(record),
                  icon: <EditOutlined />,
                },
                {
                  label: record.deleted_at ? 'Restore' : 'Remove',
                  key: 'Remove',
                  onClick: () =>
                    record.deleted_at
                      ? onRestore(record.id)
                      : onDelete(record.id),
                  icon: record.deleted_at ? (
                    <RollbackOutlined />
                  ) : (
                    <DeleteOutlined />
                  ),
                },
              ],
            }}
          >
            <EllipsisOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];
}
