import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Spin } from 'antd';
import { Line } from '@ant-design/charts';

import { getDashboardChartConfig } from 'containers/DashboardEffectivenessPage/utils';
import { getRowKey } from 'utils/helpers';
import DashboardEmployeeRevenueChart from './DasbboardEmployeeRevenueChart';
import {
  getDataSource,
  getColumns,
  getEmployeeComData,
  getEmployeeRevenueData,
} from './utils';

import styles from './styles.scss';

const DashboardEmployeeExpandableRow = ({
  employeeData,
  isLoading,
  period,
}) => {
  const dataSource = useMemo(() => getDataSource(employeeData.projects), [
    employeeData,
  ]);

  const columns = useMemo(() => getColumns(), [employeeData]);

  const employeeComData = useMemo(
    () => getEmployeeComData(employeeData.monthly_data, period),
    [employeeData],
  );

  const employeeComDataConfig = useMemo(
    () =>
      getDashboardChartConfig({
        data: employeeComData,
        seriesField: 'type',
      }),
    [employeeComData],
  );

  const employeeRevenueData = useMemo(
    () => getEmployeeRevenueData(employeeData.monthly_data, period),
    [employeeData],
  );

  if (isLoading) return <Spin size="default" className={styles.loader} />;

  return (
    <div className={styles.dashboardEmployeeExpandableRow}>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        className={styles.projectsTable}
        scroll={{ y: 250 }}
        rowKey={getRowKey}
      />
      <Line
        {...employeeComDataConfig}
        className={styles.comChart}
        height={250}
      />
      <DashboardEmployeeRevenueChart monthData={employeeRevenueData} />
    </div>
  );
};

DashboardEmployeeExpandableRow.propTypes = {
  employeeData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  period: PropTypes.array.isRequired,
};

export default DashboardEmployeeExpandableRow;
