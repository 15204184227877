import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import {
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';
import {
  getMonthNameWithYear,
  nFormatter,
  formatNumberWithCommas,
} from 'utils/helpers';

const { Title } = Typography;

const UtilizationTotalHours = ({ data }) => {
  const chartFormatter = value => nFormatter(value);

  return (
    <div>
      <Title level={5}>Total hours</Title>
      <ResponsiveContainer width="100%" height={415}>
        <ComposedChart
          data={data}
          margin={{
            top: 0,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" tickFormatter={getMonthNameWithYear} />
          <YAxis tickFormatter={chartFormatter} />
          <Tooltip formatter={formatNumberWithCommas} />
          <Legend verticalAlign="top" align="left" height={45} />
          <Bar
            dataKey="logged_delivery"
            name="Delivery"
            fill="#448EF7"
            stackId="a"
          />
          <Bar
            dataKey="logged_not_delivery"
            name="Support"
            fill="#D9D9D9"
            stackId="a"
          />
          <Line
            dataKey="average_logged_hours"
            name="Avg. hours"
            dot={false}
            strokeDasharray="5 5"
            strokeWidth={2}
            stroke="#F5C243"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

UtilizationTotalHours.propTypes = {
  data: PropTypes.array.isRequired,
};

export default UtilizationTotalHours;
