import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { CLIENT_PERMISSIONS } from 'containers/ClientPage/constants';
import ClientInfo from 'components/ClientCommon/ClientSummary';
import ClientSummaryForm from 'components/ClientCommon/ClientForms/ClientSummaryForm';
import Modal from 'components/Common/Modal';
import { Button } from 'components/Common';
import { hasRights } from 'utils/permissions';

import DetailsContainer from '../../../Common/DetailsContainer';

const ClientSummary = ({ client = {}, updateClient, id, role }) => {
  const [isVisible, toggleVisible] = useState(false);

  const [form] = Form.useForm();

  const onHideModal = () => toggleVisible(false);
  const onShowModal = () => toggleVisible(true);

  const onUpdateClient = values => {
    if (values) {
      updateClient({ clientId: id, values });
    }

    onHideModal();
  };

  return (
    <React.Fragment>
      <Modal
        title="Edit client"
        open={isVisible}
        footer={
          <>
            <Button onClick={onHideModal}>Close</Button>
            <Button type="primary" onClick={form.submit}>
              Save
            </Button>
          </>
        }
        onCancel={onHideModal}
        destroyOnClose
      >
        <ClientSummaryForm
          fieldValues={client}
          onUpdateClient={onUpdateClient}
          form={form}
        />
      </Modal>
      <DetailsContainer
        name="General information"
        extra={
          hasRights(role, CLIENT_PERMISSIONS.updateGeneralInfo) && (
            <Button
              onClick={onShowModal}
              size="small"
              icon={<EditOutlined />}
              type="link"
            />
          )
        }
      >
        <ClientInfo client={client} type="vertical" showDescription />
      </DetailsContainer>
    </React.Fragment>
  );
};

ClientSummary.propTypes = {
  client: PropTypes.object,
  updateClient: PropTypes.func,
  id: PropTypes.string,
  role: PropTypes.string,
};

export default ClientSummary;
