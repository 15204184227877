import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Tabs } from 'antd';

import LeadNote from './LeadNote';

const LeadModal = ({ showModal, onSaveNote, onHideLeadModal }) => {
  const tabItems = [
    {
      label: 'Notes',
      key: 'ClientNote',
      children: <LeadNote onFinish={onSaveNote} />,
    },
  ];

  return (
    <Modal
      footer={null}
      width="1200px"
      destroyOnClose
      open={showModal}
      onCancel={onHideLeadModal}
    >
      <Tabs defaultActiveKey="Notes" items={tabItems} />
    </Modal>
  );
};

LeadModal.propTypes = {
  showModal: PropTypes.bool,
  onSaveNote: PropTypes.func,
  onHideLeadModal: PropTypes.func,
};

export default LeadModal;
