import React from 'react';
import PropTypes from 'prop-types';
import { SyncOutlined } from '@ant-design/icons';
import { Tree } from 'antd';

import { transformActivitiesData } from 'components/LeadCommon/LeadActivities/utils';
import { LeadActivities } from 'components/LeadCommon/';
import DetailsContainer from 'components/Common/DetailsContainer';

import { INFO_KEYS, LEAD_INFO_OBJECT } from './constants';
import styles from './styles.scss';

const AnalyticsCommon = ({
  onUpdateLeadActivities,
  leadData,
  leadActivities,
  leadGeo,
}) => (
  <DetailsContainer
    name="Analytics"
    extra={<SyncOutlined onClick={onUpdateLeadActivities} />}
  >
    <ul className={styles.leadReportList}>
      {INFO_KEYS.map(item => (
        <li key={item}>
          <h4 className={styles.itemTitle}>{LEAD_INFO_OBJECT[item].title} </h4>
          <p>{leadData[item]}</p>
        </li>
      ))}
      <li>
        <h4 className={styles.itemTitle}>Activities</h4>
        <div>
          <LeadActivities leadActivities={leadActivities} />
        </div>
      </li>
      {leadGeo && (
        <li>
          <h4 className={styles.itemTitle}>GEO</h4>
          <div>
            <Tree
              showLine={{ showLeafIcon: false }}
              treeData={transformActivitiesData([leadGeo], 'ip_info')}
            />
          </div>
        </li>
      )}
    </ul>
  </DetailsContainer>
);

AnalyticsCommon.propTypes = {
  onUpdateLeadActivities: PropTypes.func,
  leadData: PropTypes.array,
  leadActivities: PropTypes.array,
  leadGeo: PropTypes.object,
};

export default AnalyticsCommon;
