import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';
import { REDUX_DATA_HELPERS } from 'utils/helpers';

import { incomingInvoicesSelector } from 'redux/selectors';
import { sagaErrorHandler } from 'utils/common';
import { types, INCOMING_INVOICE_MESSAGES } from './types';

function* getIncomingInvoices({ payload }) {
  try {
    const { data } = yield call(API.getIncomingInvoices, payload);

    yield put({ type: types.GET_INCOMING_INVOICES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_INCOMING_INVOICES_FAILED,
      INCOMING_INVOICE_MESSAGES.GET_INCOMING_INVOICES_FAILED,
    );
  }
}

function* createIncomingInvoice({ payload }) {
  try {
    const { incomingInvoices } = yield select(incomingInvoicesSelector);
    const { data } = yield call(API.createIncomingInvoice, payload);

    if (payload.attachments) {
      const { data: attachments } = yield call(
        uploadIncomingInvoiceAttachment,
        {
          id: data.id,
          attachments: payload.attachments,
        },
      );
      data.attachments = attachments;
    }

    yield put({
      type: types.CREATE_INCOMING_INVOICE_SUCCESS,
      payload: [...incomingInvoices, data],
    });
    message.success(INCOMING_INVOICE_MESSAGES.CREATE_INCOMING_INVOICE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_INCOMING_INVOICE_FAILED,
      INCOMING_INVOICE_MESSAGES.CREATE_INCOMING_INVOICE_FAILED,
    );
  }
}

function* updateIncomingInvoice({ payload }) {
  try {
    const { incomingInvoices } = yield select(incomingInvoicesSelector);
    const { data } = yield call(API.updateIncomingInvoice, payload);

    if (payload.attachments) {
      const { data: attachments } = yield call(
        uploadIncomingInvoiceAttachment,
        {
          id: data.id,
          attachments: payload.attachments,
        },
      );

      data.attachments = attachments;
    }

    const updatedInvoices = REDUX_DATA_HELPERS.updateData({
      data: incomingInvoices,
      editedItem: data,
      id: payload.id,
    });

    yield put({
      type: types.UPDATE_INCOMING_INVOICE_SUCCESS,
      payload: updatedInvoices,
    });
    message.success(INCOMING_INVOICE_MESSAGES.UPDATE_INCOMING_INVOICE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_INCOMING_INVOICE_FAILED,
      INCOMING_INVOICE_MESSAGES.UPDATE_INCOMING_INVOICE_FAILED,
    );
  }
}

function* deleteIncomingInvoice({ payload }) {
  try {
    const { incomingInvoices } = yield select(incomingInvoicesSelector);
    yield call(API.deleteIncomingInvoice, payload);

    const updatedInvoices = REDUX_DATA_HELPERS.removeItem({
      data: incomingInvoices,
      id: payload,
    });

    yield put({
      type: types.DELETE_INCOMING_INVOICE_SUCCESS,
      payload: updatedInvoices,
    });
    message.success(INCOMING_INVOICE_MESSAGES.DELETE_INCOMING_INVOICE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_INCOMING_INVOICE_FAILED,
      INCOMING_INVOICE_MESSAGES.DELETE_INCOMING_INVOICE_FAILED,
    );
  }
}

function* approveIncomingInvoiceByFinancier({ payload }) {
  try {
    const { incomingInvoices } = yield select(incomingInvoicesSelector);
    const { data } = yield call(API.approveIncomingInvoiceByFinancier, payload);
    const updatedInvoices = REDUX_DATA_HELPERS.updateData({
      data: incomingInvoices,
      editedItem: data,
      id: payload,
    });
    message.success(
      INCOMING_INVOICE_MESSAGES.APPROVE_INCOMING_INVOICE_FINANCIER_SUCCESS,
    );

    yield put({
      type: types.APPROVE_INCOMING_INVOICE_FINANCIER_SUCCESS,
      payload: updatedInvoices,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.APPROVE_INCOMING_INVOICE_FINANCIER_FAILED,
      INCOMING_INVOICE_MESSAGES.APPROVE_INCOMING_INVOICE_FINANCIER_FAILED,
    );
  }
}
function* declineIncomingInvoiceByFinancier({ payload }) {
  try {
    const { incomingInvoices } = yield select(incomingInvoicesSelector);
    const { data } = yield call(API.declineIncomingInvoiceByFinancier, payload);
    const updatedInvoices = REDUX_DATA_HELPERS.updateData({
      data: incomingInvoices,
      editedItem: data,
      id: payload,
    });
    message.success(
      INCOMING_INVOICE_MESSAGES.DECLINE_INCOMING_INVOICE_FINANCIER_SUCCESS,
    );
    yield put({
      type: types.DECLINE_INCOMING_INVOICE_FINANCIER_SUCCESS,
      payload: updatedInvoices,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DECLINE_INCOMING_INVOICE_FINANCIER_FAILED,
      INCOMING_INVOICE_MESSAGES.DECLINE_INCOMING_INVOICE_FINANCIER_FAILED,
    );
  }
}
function* approveIncomingInvoiceByApprover({ payload }) {
  try {
    const { incomingInvoices } = yield select(incomingInvoicesSelector);
    const { data } = yield call(API.approveIncomingInvoiceByApprover, payload);
    const updatedInvoices = REDUX_DATA_HELPERS.updateData({
      data: incomingInvoices,
      editedItem: data,
      id: payload,
    });
    message.success(
      INCOMING_INVOICE_MESSAGES.APPROVE_INCOMING_INVOICE_APPROVER_SUCCESS,
    );
    yield put({
      type: types.APPROVE_INCOMING_INVOICE_APPROVER_SUCCESS,
      payload: updatedInvoices,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.APPROVE_INCOMING_INVOICE_APPROVER_FAILED,
      INCOMING_INVOICE_MESSAGES.APPROVE_INCOMING_INVOICE_APPROVER_FAILED,
    );
  }
}
function* declineIncomingInvoiceByApprover({ payload }) {
  try {
    const { incomingInvoices } = yield select(incomingInvoicesSelector);
    const { data } = yield call(API.declineIncomingInvoiceByApprover, payload);
    const updatedInvoices = REDUX_DATA_HELPERS.updateData({
      data: incomingInvoices,
      editedItem: data,
      id: payload,
    });
    message.success(
      INCOMING_INVOICE_MESSAGES.DECLINE_INCOMING_INVOICE_APPROVER_SUCCESS,
    );
    yield put({
      type: types.DECLINE_INCOMING_INVOICE_APPROVER_SUCCESS,
      payload: updatedInvoices,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DECLINE_INCOMING_INVOICE_APPROVER_FAILED,
      INCOMING_INVOICE_MESSAGES.DECLINE_INCOMING_INVOICE_APPROVER_FAILED,
    );
  }
}

function* uploadIncomingInvoiceAttachment({ attachments, id }) {
  try {
    const attachmentsParams = attachments.map(attachment => ({
      filename: attachment.name,
    }));

    const { data } = yield call(API.uploadIncomingInvoiceAttachmentUrl, {
      attachments: attachmentsParams,
    });

    yield all(
      data.map((url, index) =>
        call(API.uploadAttachmentToAWS, url.signed_url, attachments[index]),
      ),
    );

    const formattedAttachments = attachments.map((attachment, index) => ({
      file_name: attachment.name,
      url: data[index].signed_url,
    }));

    const uploadedAttachments = yield call(API.saveIncomingInvoiceAttachments, {
      id,
      attachments: formattedAttachments,
    });

    return uploadedAttachments;
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.SAVE_INCOMING_INVOICE_ATTACHMENTS_FAILED,
      INCOMING_INVOICE_MESSAGES.SAVE_INCOMING_INVOICE_ATTACHMENTS_FAILED,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_INCOMING_INVOICES, getIncomingInvoices);
  yield takeLatest(types.CREATE_INCOMING_INVOICE, createIncomingInvoice);
  yield takeLatest(types.UPDATE_INCOMING_INVOICE, updateIncomingInvoice);
  yield takeLatest(types.DELETE_INCOMING_INVOICE, deleteIncomingInvoice);
  yield takeLatest(
    types.APPROVE_INCOMING_INVOICE_FINANCIER,
    approveIncomingInvoiceByFinancier,
  );
  yield takeLatest(
    types.DECLINE_INCOMING_INVOICE_FINANCIER,
    declineIncomingInvoiceByFinancier,
  );
  yield takeLatest(
    types.APPROVE_INCOMING_INVOICE_APPROVER,
    approveIncomingInvoiceByApprover,
  );
  yield takeLatest(
    types.DECLINE_INCOMING_INVOICE_APPROVER,
    declineIncomingInvoiceByApprover,
  );
}
