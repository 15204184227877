import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import {
  DATE_FORMAT,
  DATE_MONTH_FORMAT,
  INVALID_DATE,
} from 'utils/timeConstants';
import DatePicker from 'components/Common/DatePicker';
import RangePicker from 'components/Common/RangePicker';

import styles from './styles.scss';

const UserContract = ({
  contractStartsAt,
  contractEndsAt,
  isContractTermless,
}) => {
  const isContractHasInvalidStartsDate =
    dayjs(contractStartsAt).format(DATE_FORMAT) === INVALID_DATE;
  const isContractHasInvalidEndDate =
    dayjs(contractEndsAt).format(DATE_FORMAT) === INVALID_DATE;

  const pickerStartPlaceHolder = isContractHasInvalidEndDate
    ? 'N/A'
    : 'Start date';

  const pickerEndPlaceHolder = isContractHasInvalidEndDate ? 'N/A' : 'End date';

  const endDateValue = isContractHasInvalidEndDate
    ? null
    : dayjs(contractEndsAt, DATE_FORMAT);

  const startDateValue = isContractHasInvalidStartsDate
    ? null
    : dayjs(contractStartsAt, DATE_FORMAT);

  return (
    <div className={styles.contractContainer}>
      <h3 className={styles.title}>
        Contract {isContractTermless && '(open-ended)'}
      </h3>
      {!isContractTermless ? (
        <RangePicker
          picker="date"
          format={DATE_MONTH_FORMAT}
          value={[startDateValue, endDateValue]}
          disabled
          placeholder={[pickerStartPlaceHolder, pickerEndPlaceHolder]}
        />
      ) : (
        <DatePicker
          format={DATE_MONTH_FORMAT}
          value={startDateValue}
          placeholder={pickerStartPlaceHolder}
          disabled
        />
      )}
    </div>
  );
};

UserContract.propTypes = {
  contractStartsAt: PropTypes.string,
  contractEndsAt: PropTypes.string,
  isContractTermless: PropTypes.bool,
};

export default UserContract;
