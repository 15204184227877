import React from 'react';
import PropTypes from 'prop-types';
import { Select as AtndSelect, Avatar } from 'antd';
import Select from 'components/Common/Select';

import styles from './styles.scss';

const { Option } = AtndSelect;

const UserSelect = ({
  data = [],
  width = '100%',
  disabled = false,
  mode = '',
  value = '',
  onChange = () => {},
  isReturnAccountId = false,
}) => {
  const filterOption = (input, { props: { children } }) => {
    const lowerCaseInput = input.toLowerCase();

    return (
      children[1]?.toLowerCase().indexOf(lowerCaseInput) >= 0 ||
      children[3]?.props.children[1].toLowerCase().indexOf(lowerCaseInput) >= 0
    );
  };

  return (
    <Select
      getPopupContainer={e => e.parentElement || e}
      showSearch
      disabled={disabled}
      style={{ width }}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={filterOption}
      value={value}
      mode={mode}
    >
      {data.map(({ jira_user, id, name_en, name_ru }, index) => {
        const value = isReturnAccountId ? id : jira_user.id;

        return (
          //eslint-disable-next-line
          <Option key={`${value}_${index}`} value={value}>
            <Avatar
              className={styles.avatar}
              shape="square"
              size={20}
              src={jira_user && jira_user.avatarUrls['32x32']}
            />
            {`${name_en || name_ru} `}
            <span className={styles.label}>({jira_user && jira_user.id})</span>
          </Option>
        );
      })}
    </Select>
  );
};

UserSelect.propTypes = {
  data: PropTypes.array,
  width: PropTypes.string,
  mode: PropTypes.string,
  isReturnAccountId: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
};

export default UserSelect;
