import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  socialRequests: [],
  socialRequestsInfo: null,
  socialRequestsFilters: [],
  socialRequestsTotal: 0,
  socialRequestsTypes: [],
  socialRequestsStatuses: [],
  isSocialRequestsLoading: false,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_SOCIAL_REQUESTS:
    case types.GET_SOCIAL_REQUESTS_INFO:
    case types.GET_SOCIAL_REQUEST_STATUSES:
    case types.GET_SOCIAL_REQUEST_TYPES:
    case types.CREATE_SOCIAL_REQUEST:
    case types.UPDATE_SOCIAL_REQUEST:
    case types.DELETE_SOCIAL_REQUEST:
    case types.APPROVE_SOCIAL_REQUEST:
    case types.DECLINE_SOCIAL_REQUEST:
      return state.set('isSocialRequestsLoading', true);

    case types.GET_SOCIAL_REQUESTS_SUCCESS:
      return state
        .set('socialRequests', payload.data)
        .set('socialRequestsFilters', payload.data_for_filters)
        .set('socialRequestsTotal', payload.total_data)
        .set('isSocialRequestsLoading', false);
    case types.GET_SOCIAL_REQUESTS_INFO_SUCCESS:
      return state
        .set('socialRequestsInfo', payload)
        .set('isSocialRequestsLoading', false);
    case types.GET_SOCIAL_REQUEST_STATUSES_SUCCESS:
      return state
        .set('socialRequestsStatuses', payload)
        .set('isSocialRequestsLoading', false);
    case types.GET_SOCIAL_REQUEST_TYPES_SUCCESS:
      return state
        .set('socialRequestsTypes', payload)
        .set('isSocialRequestsLoading', false);
    case types.CREATE_SOCIAL_REQUEST_SUCCESS:
    case types.UPDATE_SOCIAL_REQUEST_SUCCESS:
    case types.DELETE_SOCIAL_REQUEST_SUCCESS:
    case types.DECLINE_SOCIAL_REQUEST_SUCCESS:
    case types.APPROVE_SOCIAL_REQUEST_SUCCESS:
      return state
        .set('socialRequests', payload)
        .set('isSocialRequestsLoading', false);

    case types.GET_SOCIAL_REQUESTS_FAILED:
    case types.GET_SOCIAL_REQUESTS_INFO_FAILED:
    case types.GET_SOCIAL_REQUEST_STATUSES_FAILED:
    case types.GET_SOCIAL_REQUEST_TYPES_FAILED:
    case types.CREATE_SOCIAL_REQUEST_FAILED:
    case types.UPDATE_SOCIAL_REQUEST_FAILED:
    case types.DELETE_SOCIAL_REQUEST_FAILED:
    case types.APPROVE_SOCIAL_REQUEST_FAILED:
    case types.DECLINE_SOCIAL_REQUEST_FAILED:
      return state.set('isSocialRequestsLoading', false);

    default:
      return state;
  }
}
