import React from 'react';
import { Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { CELL_COLORS } from 'components/ClientCommon/ClientTimeline/TimelineItems/CommonItem/constants';
import { getConditionPreview } from '../ClientAllocationTable/utils';

const { Text } = Typography;

export const getColumns = ({ jiraRoles, currencies }) => [
  {
    title: 'Role',
    dataIndex: 'jira_role_id',
    key: 'position',
    width: '28%',
    align: 'left',
    render: (value, record) => ({
      props: {
        style: {
          backgroundColor:
            (record.deleted && CELL_COLORS.red) ||
            (record.is_new && CELL_COLORS.green) ||
            '',
        },
      },
      children: jiraRoles[value] && (
        <Text delete={record.deleted} style={{ fontSize: '0.85rem' }}>
          {jiraRoles[value][0].name}
        </Text>
      ),
    }),
  },
  {
    title: 'Rate',
    dataIndex: 'rate',
    align: 'center',
    key: 'rate',
    width: '28%',
    render: (value, record) => {
      const currentCurrency = currencies.find(
        item => item.name === record.currency_code,
      );

      const oldCurrency =
        record.old_values &&
        currencies.find(item => item.name === record.old_values.currency_code);

      const isRateOrCurrencyUpdated =
        record.edited &&
        record.id &&
        (value !== record.old_values.rate ||
          record.currency_code !== record.old_values.currency_code);

      return {
        props: {
          style: {
            backgroundColor:
              (record.deleted && CELL_COLORS.red) ||
              (record.is_new && CELL_COLORS.green) ||
              '',
          },
        },
        children: value && (
          <>
            {isRateOrCurrencyUpdated && (
              <>
                <Text
                  strong
                  delete={record.deleted}
                  style={{ fontSize: '0.85rem' }}
                >
                  {record.old_values.rate} (
                  {oldCurrency ? oldCurrency.name : ''})
                </Text>{' '}
                <ArrowRightOutlined style={{ fontSize: '14px' }} />{' '}
              </>
            )}
            <Text
              strong
              delete={record.deleted}
              style={{ fontSize: '0.85rem' }}
            >
              {value} ({currentCurrency ? currentCurrency.name : ''})
            </Text>{' '}
            <Text delete={record.deleted} style={{ fontSize: '0.85rem' }}>
              per hour
            </Text>
          </>
        ),
      };
    },
  },
  {
    title: 'Allocation',
    dataIndex: 'hours_per_week',
    key: 'hours_per_week',
    width: '28%',
    align: 'center',
    render: (value, record) => ({
      props: {
        style: {
          backgroundColor:
            (record.deleted && CELL_COLORS.red) ||
            (record.is_new && CELL_COLORS.green) ||
            '',
        },
      },
      children: value && (
        <>
          {record.edited &&
            record.id &&
            value !== record.old_values.hours_per_week && (
              <>
                <Text
                  strong
                  delete={record.deleted}
                  style={{ fontSize: '0.85rem' }}
                >
                  {record.old_values.hours_per_week} h/week
                </Text>{' '}
                <ArrowRightOutlined style={{ fontSize: '14px' }} />{' '}
              </>
            )}
          <Text strong delete={record.deleted} style={{ fontSize: '0.85rem' }}>
            {value}
          </Text>
          <Text delete={record.deleted} style={{ fontSize: '0.85rem' }}>
            {' '}
            h/week
          </Text>
        </>
      ),
    }),
  },
  {
    title: 'Condition',
    dataIndex: 'condition',
    key: 'condition',
    width: '10%',
    align: 'center',
    render: (value, record) => ({
      props: {
        style: {
          backgroundColor:
            (record.deleted && CELL_COLORS.red) ||
            (record.is_new && CELL_COLORS.green) ||
            '',
        },
      },
      children: value && (
        <>
          {record.edited && record.id && value !== record.old_values.condition && (
            <>
              <Text
                strong
                delete={record.deleted}
                style={{ fontSize: '0.85rem' }}
              >
                {getConditionPreview(record.old_values.condition)}
              </Text>{' '}
              <ArrowRightOutlined style={{ fontSize: '14px' }} />{' '}
            </>
          )}
          <Text delete={record.deleted} style={{ fontSize: '0.85rem' }}>
            {getConditionPreview(value)}
          </Text>
        </>
      ),
    }),
  },
];

export const formatOverviewData = ({ deletedAllocations, allocationsData }) => {
  const allocationsDataWithoutNull = allocationsData.filter(
    item => item.jira_role_id,
  );

  return [...allocationsDataWithoutNull, ...deletedAllocations];
};
