import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const DashboardAERTotalProjectsItem = ({ title, project, aer, com }) => (
  <div className={styles.dashboardAERTotalProjectsItem}>
    <span className={styles.label}>{title}</span>
    <div className={styles.item}>
      <div className={styles.title}>{project}</div>
      <div className={styles.icon} />
      <div className={styles.firstPeriod}>{aer}</div>
      <div className={styles.secondPeriod}>{com}</div>
    </div>
  </div>
);

DashboardAERTotalProjectsItem.propTypes = {
  title: PropTypes.string.isRequired,
  project: PropTypes.string,
  aer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  com: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DashboardAERTotalProjectsItem;
