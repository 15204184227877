import React from 'react';
import PropTypes from 'prop-types';
import { Line } from '@ant-design/charts';

import { Typography } from 'antd';

const { Title } = Typography;

const DashboardUtilizationChart = ({ config }) => (
  <div>
    <Title level={5}>Utilization</Title>
    <Line {...config} />
  </div>
);

DashboardUtilizationChart.propTypes = {
  config: PropTypes.object.isRequired,
};

export default DashboardUtilizationChart;
