import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { message, Modal } from 'antd';

import { getModifiedFilters } from 'containers/ClientsPage/utils';
import { FILTER_FIELDS, FILTER_STRING } from 'containers/ClientsPage/constants';
import { formatAllocationsData } from 'containers/ClientPage/utils';
import {
  ACCESS_LOCKED_CODE,
  EXPAND_AUDITS,
  EXPAND_INVOICE,
  INVOICE_UPDATE_MESSAGES,
} from 'containers/ClientPage/constants';

import API from 'utils/api';
import {
  mapArrayToEntities,
  notifyError,
  sagaErrorHandler,
} from 'utils/common';
import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { clientsSelector } from 'redux/selectors';
import { REDUX_DATA_HELPERS } from 'utils/helpers';

import { types } from './types';

function* loadClients({ payload }) {
  try {
    const modifiedFilters = getModifiedFilters({
      FILTER_STRING,
      filters: payload.filters,
    });

    const { data, headers } = yield call(API.loadClients, {
      params: { ...payload, filters: modifiedFilters },
    });
    yield put({ type: types.LOAD_CLIENTS_SUCCESS, payload: { data, headers } });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.LOAD_CLIENTS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* createClient({ payload: { values, page, size, sort, filters } }) {
  try {
    yield call(API.createClient, values);
    yield call(loadClients, { payload: { page, size, sort, filters } });
    yield put({ type: types.CREATE_CLIENT_SUCCESS });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_CLIENT_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* deleteClient({ payload: { clientId, ...params } }) {
  try {
    yield call(API.deleteClient, { clientId });

    yield call(loadClients, { payload: params });

    yield put({ type: types.DELETE_CLIENT_SUCCESS });
    message.success(SAGA_MESSAGES.DELETE_CLIENT_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_CLIENT_FAILED,
      SAGA_MESSAGES.DELETE_CLIENT_FAILED,
    );
  }
}

function* restoreClient({ payload: { clientId, ...params } }) {
  try {
    yield call(API.restoreClient, { clientId });

    yield call(loadClients, { payload: params });

    yield put({ type: types.RESTORE_CLIENT_SUCCESS });
    message.success(SAGA_MESSAGES.RESTORE_CLIENT_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.RESTORE_CLIENT_FAILED,
      SAGA_MESSAGES.RESTORE_CLIENT_FAILED,
    );
  }
}

function* getClientsFilters() {
  try {
    const response = yield all(
      FILTER_FIELDS.map(field => API.getClientsFilter({ field })),
    );
    const data = response.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.config.params.field]: curr.data.filter(item => item),
      }),
      {},
    );
    yield put({
      type: types.GET_CLIENTS_FILTERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_CLIENTS_FILTERS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getClient({ payload: id }) {
  try {
    const [
      { data: client },
      { data: clientProjects },
      { data: clientOrganizations },
      { data: clientNotes },
    ] = yield all([
      call(API.getClient, id),
      call(API.getClientProjects, id),
      call(API.getClientOrganizations, id),
      call(API.getClientNotes, { id, params: { expand: ['audits'] } }),
    ]);

    const clientNotesObject = mapArrayToEntities(clientNotes).entities;

    yield put({
      type: types.GET_CLIENT_SUCCESS,
      payload: {
        client,
        clientProjects,
        clientOrganizations,
        clientNotes,
        clientNotesObject,
      },
    });
  } catch (error) {
    yield put({
      type: types.GET_CLIENT_FAILED,
      payload: error.response.status,
    });
  }
}

function* getClientProjects({ payload: { id } }) {
  try {
    const { data } = yield call(API.getClientProjects, id);

    yield put({ type: types.GET_CLIENT_PROJECTS_SUCCESS, payload: data });
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.GET_CLIENT_PROJECTS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* saveNote({ payload: { clientId, text } }) {
  try {
    const { data } = yield call(API.saveNote, {
      text,
      clientId,
      expand: [EXPAND_AUDITS],
    });

    const { clientNotes } = yield select(clientsSelector);

    const updatedNotes = [...clientNotes, data];

    const clientNotesObject = mapArrayToEntities(updatedNotes).entities;

    yield put({
      type: types.SAVE_CLIENT_NOTE_SUCCESS,
      payload: { clientNotesObject, clientNotes: updatedNotes },
    });

    message.success(SAGA_MESSAGES.ADD_NOTE_SUCCESS);
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.SAVE_CLIENT_NOTE_FAILED,
      SAGA_MESSAGES.ADD_NOTE_FAILED,
    );
  }
}

function* updateNote({ payload: { clientId, noteId, text } }) {
  try {
    const { data } = yield call(API.updateNote, {
      clientId,
      noteId,
      text,
      expand: [EXPAND_AUDITS],
    });

    const { clientNotes } = yield select(clientsSelector);

    const updatedNotes = REDUX_DATA_HELPERS.updateData({
      data: clientNotes,
      editedItem: data,
      id: noteId,
    });

    const clientNotesObject = mapArrayToEntities(updatedNotes).entities;

    yield put({
      type: types.UPDATE_CLIENT_NOTE_SUCCESS,
      payload: { clientNotesObject, clientNotes: updatedNotes },
    });

    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.UPDATE_CLIENT_NOTE_FAILED,
      SAGA_MESSAGES.UPDATE_NOTE_FAILED,
    );
  }
}

function* deleteNote({ payload: { clientId, noteId } }) {
  try {
    yield call(API.deleteNote, { clientId, noteId });

    const { clientNotes } = yield select(clientsSelector);

    const updatedNotes = REDUX_DATA_HELPERS.removeItem({
      data: clientNotes,
      id: noteId,
    });

    const clientNotesObject = mapArrayToEntities(updatedNotes).entities;

    yield put({
      type: types.DELETE_CLIENT_NOTE_SUCCESS,
      payload: { clientNotesObject, clientNotes: updatedNotes },
    });

    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.DELETE_CLIENT_NOTE_FAILED,
      SAGA_MESSAGES.DELETE_NOTE_FAILED,
    );
  }
}

function* updateClient({ payload: { clientId, values } }) {
  try {
    const { data } = yield call(API.updateClient, { clientId, values });

    const { clients } = yield select(clientsSelector);

    const updatedClients = REDUX_DATA_HELPERS.updateData({
      id: clientId,
      editedItem: data,
      data: clients,
    });

    yield put({
      type: types.UPDATE_CLIENT_SUCCESS,
      payload: { client: data, clients: updatedClients },
    });
    message.success(SAGA_MESSAGES.UPDATE_CLIENT_SUCCESS);
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.UPDATE_CLIENT_FAILED,
      SAGA_MESSAGES.UPDATE_CLIENT_FAILED,
    );
  }
}

function* getClientOrganizations({ payload: { clientId } }) {
  try {
    const { data } = yield call(API.getClientOrganizations, clientId);
    yield put({
      type: types.GET_CLIENT_ORGANIZATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield call(sagaErrorHandler, error, types.GET_CLIENT_ORGANIZATIONS_FAILED);
  }
}

function* addClientOrganization({ payload: { organizationId, clientId } }) {
  try {
    yield call(API.addClientOrganization, {
      clientId,
      organizationId,
    });

    yield call(getClientOrganizations, { payload: { clientId } });

    message.success(SAGA_MESSAGES.ADD_ORGANIZATION_SUCCESS);
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.UPDATE_CLIENT_FAILED,
      SAGA_MESSAGES.ADD_ORGANIZATION_FAILED,
    );
  }
}

function* getClientAudit({ payload: { clientId } }) {
  try {
    const { data } = yield call(API.getClientAudit, { clientId });
    yield put({
      type: types.GET_CLIENT_AUDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.GET_CLIENT_AUDIT_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getClientAllocations({ payload: { clientId } }) {
  try {
    const { data } = yield call(API.getClientAllocations, { clientId });
    yield put({
      type: types.GET_CLIENT_ALLOCATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.GET_CLIENT_ALLOCATIONS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getClientInvoices({ payload: { clientId } }) {
  try {
    const { data } = yield call(API.getClientInvoices, {
      clientId,
      params: { expand: ['attachments', 'audits'] },
    });

    yield put({
      type: types.GET_CLIENT_INVOICES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.GET_CLIENT_INVOICES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* updateClientContract({ payload: { values, id, clientId } }) {
  try {
    yield call(API.updateContract, { values, id });
    message.success(SAGA_MESSAGES.UPDATE_CONTRACT_SUCCESS);

    yield call(getClientContracts, { payload: { id: clientId } });

    yield put({
      type: types.UPDATE_CLIENT_CONTRACT_SUCCESS,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_CLIENT_CONTRACT_FAILED,
      SAGA_MESSAGES.UPDATE_CONTRACT_FAILED,
    );
  }
}

function* getClientContracts({ payload: { id } }) {
  try {
    const { data } = yield call(API.getClientContracts, id);
    yield put({
      type: types.GET_CLIENT_CONTRACTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_CLIENT_CONTRACTS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* updateDealAllocations({
  payload: { dealId, allocations, clientId },
}) {
  try {
    yield call(API.updateDealAllocations, {
      dealId,
      allocations,
    });

    yield call(getClientAllocations, { payload: { clientId } });

    message.success(SAGA_MESSAGES.UPDATE_ALLOCATIONS_SUCCESS);
  } catch (error) {
    notifyError(error);
  }
}

function* getClientDeals({ payload: { id } }) {
  try {
    const { data } = yield call(API.getClientDeals, {
      id,
      params: { expand: ['audits'] },
    });
    yield put({ type: types.GET_CLIENT_DEALS_SUCCESS, payload: data });
  } catch (error) {
    message.error(error);
    yield put({ type: types.GET_CLIENT_DEALS_FAILED, payload: { error } });
  }
}

function* createClientDeal({
  payload: { dealValues, allocationsData, clientId },
}) {
  try {
    const { status, data } = yield call(API.createDeal, {
      values: { ...dealValues },
      expand: [EXPAND_AUDITS],
    });

    const { clientDeals } = yield select(clientsSelector);

    yield call(updateDealAllocations, {
      payload: {
        allocations: formatAllocationsData(allocationsData, data.id),
        dealId: data.id,
        clientId,
      },
    });

    yield call(getClientProjects, { payload: { id: dealValues.client_id } });

    yield put({
      type: types.CREATE_CLIENT_DEAL_SUCCESS,
      payload: { data: [...clientDeals, data], status },
    });

    message.success(SAGA_MESSAGES.CREATE_DEAL_SUCCESS);
  } catch (error) {
    message.error(SAGA_MESSAGES.CREATE_DEAL_FAILED);
    yield put({
      type: types.CREATE_CLIENT_DEAL_FAILED,
      payload: error.response.status,
    });
  }
}

function* updateClientDeal({
  payload: {
    dealId,
    values,
    allocationsData,
    isUpdate,
    isEndDeal = false,
    clientId,
  },
}) {
  try {
    const { data, status } = yield call(API.updateDeal, {
      dealId,
      values,
      expand: [EXPAND_AUDITS],
    });

    const { clientDeals } = yield select(clientsSelector);

    if (!isEndDeal) {
      yield call(updateDealAllocations, {
        payload: {
          allocations: formatAllocationsData(allocationsData, dealId, isUpdate),
          dealId,
          clientId,
        },
      });
    }

    const updatedDeals = REDUX_DATA_HELPERS.updateData({
      data: clientDeals,
      id: dealId,
      editedItem: data,
    });

    yield put({
      type: types.UPDATE_CLIENT_DEAL_SUCCESS,
      payload: { deals: updatedDeals, status },
    });

    message.success(SAGA_MESSAGES.UPDATE_DEAL_SUCCESS);
  } catch (error) {
    message.error(SAGA_MESSAGES.UPDATE_DEAL_FAILED);
    yield put({
      type: types.UPDATE_CLIENT_DEAL_FAILED,
      payload: error.response.status,
    });
  }
}

function* deleteClientDeal({ payload: { deal_id } }) {
  try {
    const { data } = yield call(API.deleteDeal, {
      deal_id,
      expand: [EXPAND_AUDITS],
    });

    const { clientDeals } = yield select(clientsSelector);

    const updatedDeals = REDUX_DATA_HELPERS.updateData({
      data: clientDeals,
      id: deal_id,
      editedItem: data,
    });

    yield put({
      type: types.DELETE_CLIENT_DEAL_SUCCESS,
      payload: updatedDeals,
    });

    message.success(SAGA_MESSAGES.DELETE_DEAL_SUCCESS);
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.DELETE_CLIENT_DEAL_FAILED,
      SAGA_MESSAGES.DELETE_DEAL_FAILED,
    );
  }
}

function* deleteClientInvoice({ payload: { clientId, invoiceId } }) {
  try {
    const {
      data: { client_totals_paid, client_totals_pending },
    } = yield call(API.deleteClieintInvoice, {
      clientId,
      invoiceId,
    });

    const { clientInvoices } = yield select(clientsSelector);

    yield put({
      type: types.DELETE_CLIENT_INVOICE_SUCCESS,
      payload: {
        client_totals_paid,
        client_totals_pending,
      },
    });
    const updatedInvoices = REDUX_DATA_HELPERS.removeItem({
      data: clientInvoices,
      id: invoiceId,
    });

    yield put({
      type: types.GET_CLIENT_INVOICES_SUCCESS,
      payload: updatedInvoices,
    });
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.DELETE_CLIENT_INVOICE_FAILED,
      SAGA_MESSAGES.DELETE_INVOICE_FAILED,
    );
  }
}

function* getAllocationsStepData({
  payload: { project_ids, date_from, date_to, client_id },
}) {
  try {
    const { data: dealAllocationsRes } = yield call(API.getDealAllocations, {
      project_ids,
      period: date_from,
      client_id,
      expand: ['deal_allocations'],
    });

    const summaryWorklogsRes = yield all(
      project_ids.map(projectId =>
        API.getJiraWorklogsSummary({ projectId, date_from, date_to }),
      ),
    );

    const worklogsRes = yield all(
      project_ids.map(projectId =>
        API.getJiraWorklogs({ projectId, date_from, date_to }),
      ),
    );

    const projectActorsRes = yield all(
      project_ids.map(projectId => API.getJiraProjectActors({ projectId })),
    );

    const summaryWorklogs = {};
    const projectActors = {};
    const worklogs = {};

    summaryWorklogsRes.forEach(item => {
      summaryWorklogs[item.config.params.project_id] = item.data;
    });

    projectActorsRes.forEach(item => {
      projectActors[item.config.params.project_id] = item.data;
    });

    worklogsRes.forEach(item => {
      worklogs[item.config.params.project_id] = item.data;
    });

    const dealAllocations = {};

    dealAllocationsRes.forEach(deal => {
      dealAllocations[deal.project_id] = dealAllocations[deal.project_id]
        ? [...dealAllocations[deal.project_id], deal]
        : [deal];
    });

    yield put({
      type: types.GET_ALLOCATIONS_STEP_DATA_SUCCESS,
      payload: {
        dealAllocations,
        summaryWorklogs,
        projectActors,
        worklogs,
      },
    });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_ALLOCATIONS_STEP_DATA_FAILED);
  }
}

function* addClientInvoice({ payload }) {
  try {
    const { client_id } = payload;
    const { data, status } = yield call(API.addClientInvoce, {
      client_id,
      payload: { ...payload, expand: EXPAND_INVOICE },
    });

    const { clientInvoices } = yield select(clientsSelector);

    yield put({
      type: types.GET_CLIENT_INVOICES_SUCCESS,
      payload: [...clientInvoices, data],
    });

    yield put({
      type: types.ADD_CLIENT_INVOICE_SUCCESS,
      payload: { data: data, status },
    });
    message.success(SAGA_MESSAGES.CREATE_INVOICE_SUCCESS);
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.ADD_CLIENT_INVOICE_FAILED,
      SAGA_MESSAGES.CREATE_INVOICE_FAILED,
    );
  }
}

function* updateClientInvoice({
  payload: {
    invoice_id,
    client_id,
    messageText = 'Invoice was updated successfully',
    isClearResponseAfter,
    setIsInvoiceValuesTouched,
    updatedField,
    ...params
  },
}) {
  try {
    const { data, status } = yield call(API.updateClientInvoice, {
      invoice_id,
      params: { ...params, expand: EXPAND_INVOICE },
    });

    const { clientInvoices } = yield select(clientsSelector);

    yield put({
      type: types.UPDATE_CLIENT_INVOICE_SUCCESS,
      payload: { data: data, status },
    });

    if (setIsInvoiceValuesTouched && updatedField) {
      setIsInvoiceValuesTouched(prev => ({
        ...prev,
        [updatedField]: false,
      }));
    }

    if (isClearResponseAfter) {
      yield put({ type: types.CLEAR_RESPONSE });
    }

    const updatedInvoices = REDUX_DATA_HELPERS.updateData({
      data: clientInvoices,
      editedItem: data,
      id: invoice_id,
    });

    yield put({
      type: types.GET_CLIENT_INVOICES_SUCCESS,
      payload: updatedInvoices,
    });

    message.success(messageText);
  } catch (error) {
    if (error.response.status === ACCESS_LOCKED_CODE) {
      Modal.error({
        title: INVOICE_UPDATE_MESSAGES.ACCESS_LOCKED_TITLE,
        content: INVOICE_UPDATE_MESSAGES.ACCESS_LOCKED_CONTENT,
      });
    }

    yield put({
      type: types.UPDATE_CLIENT_INVOICE_FAILED,
      payload: error,
    });
  }
}

function* getInvoice({ payload: { invoiceId, clientId } }) {
  try {
    const { data } = yield call(API.getInvoice, {
      invoiceId,
      clientId,
      expand: EXPAND_INVOICE,
    });

    yield put({ type: types.GET_CLIENT_INVOICE_SUCCESS, payload: data });
  } catch (error) {
    yield call(
      sagaErrorHandler,
      error,
      types.GET_CLIENT_INVOICE_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.LOAD_CLIENTS, loadClients);
  yield takeLatest(types.CREATE_CLIENT, createClient);
  yield takeLatest(types.DELETE_CLIENT, deleteClient);
  yield takeLatest(types.RESTORE_CLIENT, restoreClient);
  yield takeLatest(types.UPDATE_CLIENT, updateClient);
  yield takeLatest(types.GET_CLIENTS_FILTERS, getClientsFilters);
  yield takeLatest(types.GET_CLIENT, getClient);
  yield takeLatest(types.SAVE_CLIENT_NOTE, saveNote);
  yield takeLatest(types.UPDATE_CLIENT_NOTE, updateNote);
  yield takeLatest(types.DELETE_CLIENT_NOTE, deleteNote);
  yield takeLatest(types.GET_CLIENT_ORGANIZATIONS, getClientOrganizations);
  yield takeLatest(types.ADD_CLIENT_ORGANIZATION, addClientOrganization);
  yield takeLatest(types.GET_CLIENT_AUDIT, getClientAudit);
  yield takeLatest(types.GET_CLIENT_ALLOCATIONS, getClientAllocations);

  yield takeLatest(types.GET_CLIENT_INVOICES, getClientInvoices);
  yield takeLatest(types.GET_CLIENT_INVOICE, getInvoice);
  yield takeLatest(types.ADD_CLIENT_INVOICE, addClientInvoice);
  yield takeLatest(types.UPDATE_CLIENT_INVOICE, updateClientInvoice);
  yield takeLatest(types.DELETE_CLIENT_INVOICE, deleteClientInvoice);

  yield takeLatest(types.GET_CLIENT_CONTRACTS, getClientContracts);
  yield takeLatest(types.UPDATE_CLIENT_CONTRACT, updateClientContract);

  yield takeLatest(types.GET_CLIENT_DEALS, getClientDeals);
  yield takeLatest(types.CREATE_CLIENT_DEAL, createClientDeal);
  yield takeLatest(types.UPDATE_CLIENT_DEAL, updateClientDeal);
  yield takeLatest(types.DELETE_CLIENT_DEAL, deleteClientDeal);
  yield takeLatest(types.GET_ALLOCATIONS_STEP_DATA, getAllocationsStepData);
}
