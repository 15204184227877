exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".profitAndLossTotalItem-7e761{margin-bottom:12px;padding:16px 20px;border:1px solid #d9d9d9;border-radius:8px}.profitAndLossTotalItem-7e761 .title-1eed6{display:flex;align-items:center;margin-bottom:8px;line-height:24px}.profitAndLossTotalItem-7e761 .title-1eed6 .titleText-77e05{margin-right:6px;font-weight:600}.profitAndLossTotalItem-7e761 .title-1eed6 .tooltip-1bcaf{width:16px;height:16px}.profitAndLossTotalItem-7e761 .content-42033 .stat-b7664{display:flex;align-items:center;margin-bottom:6px;color:#595959}.profitAndLossTotalItem-7e761 .content-42033 .stat-b7664 .value-1dd64{margin:0 12px;padding:0 12px;border-right:1px solid #d9d9d9;border-left:1px solid #d9d9d9}", ""]);

// Exports
exports.locals = {
	"profitAndLossTotalItem": "profitAndLossTotalItem-7e761",
	"title": "title-1eed6",
	"titleText": "titleText-77e05",
	"tooltip": "tooltip-1bcaf",
	"content": "content-42033",
	"stat": "stat-b7664",
	"value": "value-1dd64"
};