import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio, Upload, Typography, Alert } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import classNames from 'classnames';

import {
  REQUESTS_COMMON,
  REQUEST_TYPES,
} from 'containers/SocialRequestsPage/constants';
import { PROFILES_PERMISSIONS } from 'containers/ProfilesPage/constants';
import Modal from 'components/Common/Modal';
import Input from 'components/Common/Input';
import TextArea from 'components/Common/TextArea';
import DatePicker from 'components/Common/DatePicker';
import { DATE_FORMAT } from 'utils/timeConstants';
import { hasRights } from 'utils/permissions';

import { formatBalanceValue } from '../utils';
import styles from './styles.scss';

const { Item } = Form;
const { Text } = Typography;
const { Dragger } = Upload;

const RequestsModal = ({
  isVisible,
  onHide,
  onAddSocialRequest,
  form,
  requestsInfo,
  role,
  isWard,
  isOwnProfile,
}) => {
  const [requestType, setRequestType] = useState(REQUEST_TYPES.sick_day.key);

  const onValuesChange = ({ request_type }) => {
    if (request_type) {
      setRequestType(request_type);
    }
  };

  const amountValidator = (_, value) => {
    if (value > balance) {
      return Promise.reject();
    }

    return Promise.resolve();
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const attachmentFakeRequest = options => {
    setTimeout(() => {
      options.onSuccess('ok');
    }, 0);
  };

  const isSickDayType = requestType === REQUEST_TYPES.sick_day.key;

  const balance = Number(requestsInfo[requestType]);

  const onFinish = values => {
    const socialRequest = {
      ...values,
      amount: isSickDayType ? 1 : values.amount,
    };

    if (socialRequest.sick_day_date) {
      socialRequest.sick_day_date = dayjs(socialRequest.sick_day_date).format(
        DATE_FORMAT,
      );
    }

    onAddSocialRequest(socialRequest);
  };

  return (
    <Modal
      open={isVisible}
      title="Request"
      onOk={form.submit}
      onCancel={onHide}
      okText="Send a request"
      okButtonProps={{
        disabled: !balance,
      }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        layout="vertical"
        initialValues={{
          [REQUESTS_COMMON.request_type]: REQUEST_TYPES.sick_day.key,
        }}
      >
        <Item name={REQUESTS_COMMON.request_type} label="Select type">
          <Radio.Group buttonStyle="solid" className={styles.typeRadioGroup}>
            <Radio.Button
              value={REQUEST_TYPES.sick_day.key}
              className={styles.typeRadioButton}
              disabled={
                !isOwnProfile &&
                !isWard &&
                !hasRights(role, PROFILES_PERMISSIONS.sickdayRequest)
              }
            >
              {REQUEST_TYPES.sick_day.title}
            </Radio.Button>
            <Radio.Button
              value={REQUEST_TYPES.health.key}
              className={styles.typeRadioButton}
              disabled={
                !isOwnProfile &&
                !hasRights(role, PROFILES_PERMISSIONS.healthRequest)
              }
            >
              {REQUEST_TYPES.health.title}
            </Radio.Button>
            <Radio.Button
              value={REQUEST_TYPES.education.key}
              className={styles.typeRadioButton}
              disabled={
                !isOwnProfile &&
                !isWard &&
                !hasRights(role, PROFILES_PERMISSIONS.educationRequest)
              }
            >
              {REQUEST_TYPES.education.title}
            </Radio.Button>
            <Radio.Button
              value={REQUEST_TYPES.sport.key}
              className={styles.typeRadioButton}
              disabled={
                !isOwnProfile &&
                !hasRights(role, PROFILES_PERMISSIONS.sportRequest)
              }
            >
              {REQUEST_TYPES.sport.title}
            </Radio.Button>
          </Radio.Group>
        </Item>
        <Alert
          type={!balance ? 'error' : 'success'}
          message={`Your balance: ${formatBalanceValue({
            type: requestType,
            value: balance,
          })}`}
          className={styles.balance}
        />
        {!isSickDayType && (
          <Item
            name={REQUESTS_COMMON.amount}
            label="Amount"
            required
            rules={[
              {
                validator: amountValidator,
                message: 'The amount must be less than or equal to the balance',
              },
              {
                pattern: /^(-)?(\d)*(\.)?([0-9]{1,2})?$/,
                message: 'No more than two decimal places',
                required: true,
              },
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Input
              type="number"
              addonAfter={!isSickDayType && <span>USD</span>}
              className={classNames({ [styles.inputAddon]: !isSickDayType })}
            />
          </Item>
        )}
        {isSickDayType && (
          <Item
            name={REQUESTS_COMMON.sick_day_date}
            label="Sick day date"
            required
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <DatePicker format={DATE_FORMAT} />
          </Item>
        )}
        {!isSickDayType && (
          <Text type="secondary" className={styles.amountDetails}>
            Please, convert the amount into USD yourself at the exchange rate on
            the date of sending the request of the National Bank of your country
            (Belarus - NBRB, Georgia - NBG, etc.)
          </Text>
        )}

        {!isSickDayType && (
          <Item label={REQUESTS_COMMON.attachments_title}>
            <Item
              name={REQUESTS_COMMON.attachments}
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Dragger customRequest={attachmentFakeRequest} multiple>
                <p>
                  <UploadOutlined />
                </p>
                <p>Click or drag file to this area to upload</p>
              </Dragger>
            </Item>
          </Item>
        )}
        <Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
          required
        >
          <TextArea />
        </Item>
      </Form>
    </Modal>
  );
};

RequestsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onAddSocialRequest: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  requestsInfo: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  isWard: PropTypes.bool.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
};

export default RequestsModal;
