import { EXTRA_PAYMENTS_CURRENCY_CODES } from './constants';

export const getString = ({ code, value }) => {
  switch (code) {
    case EXTRA_PAYMENTS_CURRENCY_CODES.BYR:
      return `${value} р.`;
    case EXTRA_PAYMENTS_CURRENCY_CODES.USD:
      return `$${value}`;
    default:
      return value;
  }
};
