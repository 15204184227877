exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".title-63467{color:#fff}.noAccessContainer-78742{height:auto}.noAccessIcon-5dda8{margin:auto;display:flex;align-items:center;justify-content:center;padding:20px;background-color:#ffbf02;border-radius:50%;width:150px;height:150px}.content-08b7b{background:#f8f8f8;box-sizing:border-box;padding:24px 40px;margin:auto;width:100%}", ""]);

// Exports
exports.locals = {
	"title": "title-63467",
	"noAccessContainer": "noAccessContainer-78742",
	"noAccessIcon": "noAccessIcon-5dda8",
	"content": "content-08b7b"
};