import { ROLE_TYPES } from 'utils/permissions';

export const CALENDAR_EXPAND = ['holidays'];

export const HOLIDAY_TYPES = {
  public: {
    key: 'public',
    title: 'Public holiday',
    color: '#FECACA',
    selectable: true,
    order: 1,
  },
  company: {
    key: 'company',
    title: 'Company holiday',
    color: '#FEF08A',
    selectable: true,
    order: 2,
  },
  professional: {
    key: 'professional',
    title: 'Professional holiday',
    color: '#BBF7D0',
    selectable: true,
    order: 3,
  },
  anniversary: {
    key: 'anniversary',
    title: 'Anniversary',
    color: '#83CBFF',
    selectable: false,
    order: 4,
  },
  birthday: {
    key: 'birthday',
    title: 'Birthday',
    color: '#DDD6FE',
    selectable: false,
    order: 5,
  },
};

export const HOLIDAY_WORK_TYPES = {
  working_day: { key: 'working_day', title: 'Working day' },
  day_off: { key: 'day_off', title: 'Day off' },
};

export const HOLIDAY_REPEAT_TYPES = {
  no_repeat: { key: null, title: "Don't repeat" },
  daily: { key: 'daily', title: 'Daily' },
  weekly: { key: 'weekly', title: 'Weekly' },
  once_in_two_weeks: { key: 'once_in_two_weeks', title: 'Once in 2 weeks' },
  monthly: { key: 'monthly', title: 'Monthly' },
  yearly: { key: 'yearly', title: 'Yearly' },
};

export const HOLIDAY_UPDATE_TYPES = {
  current: { key: 'current', title: 'This holiday' },
  following: { key: 'following', title: 'This and all following holidays' },
  all: { key: 'all', title: 'All holidays in this series' },
};

export const HOLIDAY_COMMON = {
  holiday_date: 'holiday_date',
  holiday_type: 'holiday_type',
  is_all_day: 'is_all_day',
  title: 'title',
  description: 'description',
  holiday_time: 'holiday_time',
  repeat_type: 'repeat_type',
  repeat_until_date: 'repeat_until_date',
  work_type: 'work_type',
  update_type: 'update_type',
  delete_type: 'delete_type',
};

export const HOLIDAYS_IMMUTABLE = [
  HOLIDAY_TYPES.anniversary.key,
  HOLIDAY_TYPES.birthday.key,
];

export const CALENDAR_PERMISSIONS = {
  updateHoliday: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.hr,
    ROLE_TYPES.office_manger,
  ],
};
