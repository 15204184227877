import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Typography } from 'antd';

import TotalCell from 'containers/ProjectProfits/TotalCell';
import MoneyCell from 'containers/ProjectProfits/MoneyCell';
import { formatMoney } from 'containers/ProjectProfits/utils';
import { TEAM_ID } from 'utils/constants';
import { SUBCONTRACTS_TABLE_INDEX } from './constants';

import styles from './styles.scss';

const { Text } = Typography;

const filteredValue = (valueType, filterInfo) =>
  (filterInfo && filterInfo[valueType]) || null;

const getFilters = filters =>
  (filters &&
    Object.entries(filters).map(([value, text]) => ({ value, text }))) ||
  [];

const getEmployerFilters = filters =>
  (filters && filters.map(({ id, name }) => ({ value: id, text: name }))) || [];

const getSortOrder = (sortInfo, condition) =>
  sortInfo.field === condition && sortInfo.order;

export const getColumns = ({ filterInfo, sortInfo, filters }) => [
  {
    title: 'ID',
    key: SUBCONTRACTS_TABLE_INDEX.project_key,
    dataIndex: SUBCONTRACTS_TABLE_INDEX.project_key,
    width: 120,
  },
  {
    title: 'Project',
    key: SUBCONTRACTS_TABLE_INDEX.project_id,
    dataIndex: SUBCONTRACTS_TABLE_INDEX.project_name,
    width: 280,
    sorter: true,
    sortOrder: getSortOrder(sortInfo, SUBCONTRACTS_TABLE_INDEX.project_name),
    filters: getFilters(filters.projects) || [],
    filteredValue: filteredValue(
      SUBCONTRACTS_TABLE_INDEX.project_id,
      filterInfo,
    ),
  },
  {
    title: 'Employer',
    key: SUBCONTRACTS_TABLE_INDEX.employer,
    dataIndex: SUBCONTRACTS_TABLE_INDEX.employer,
    sorter: true,
    sortOrder: getSortOrder(sortInfo, SUBCONTRACTS_TABLE_INDEX.employer),
    filters: getEmployerFilters(filters.organizations) || [],
    filteredValue: filteredValue(SUBCONTRACTS_TABLE_INDEX.employer, filterInfo),
  },
  {
    title: 'Full name',
    key: SUBCONTRACTS_TABLE_INDEX.employee_id,
    dataIndex: SUBCONTRACTS_TABLE_INDEX.employee_name,
    sorter: true,
    sortOrder: getSortOrder(sortInfo, SUBCONTRACTS_TABLE_INDEX.employee_name),
    filters: getFilters(filters.employees) || [],
    filteredValue: filteredValue(
      SUBCONTRACTS_TABLE_INDEX.employee_id,
      filterInfo,
    ),
    render: (value, record) => (
      <Link to={`/profiles/${record.employee_id}`}>{value}</Link>
    ),
  },
  {
    title: 'Spent hours',
    key: SUBCONTRACTS_TABLE_INDEX.spent_hours,
    dataIndex: SUBCONTRACTS_TABLE_INDEX.spent_hours,
    align: 'right',
    sorter: true,
    sortOrder: getSortOrder(sortInfo, SUBCONTRACTS_TABLE_INDEX.spent_hours),
    render: value => Number(value).toFixed(2),
  },
  {
    title: 'Cost, USD',
    key: SUBCONTRACTS_TABLE_INDEX.cost,
    dataIndex: SUBCONTRACTS_TABLE_INDEX.cost,
    align: 'right',
    sorter: true,
    sortOrder: getSortOrder(sortInfo, SUBCONTRACTS_TABLE_INDEX.cost),
    render: value => formatMoney(value),
  },
];

export const getData = ({ employees, costRates }) =>
  costRates.reduce((acc, cur) => {
    const currentEmployee = employees.find(
      employee =>
        employee.id === cur.employee_id &&
        employee.team_id === TEAM_ID.SUBCONTRACTORS,
    );

    if (currentEmployee) {
      return [
        ...acc,
        { ...cur, organization_id: currentEmployee.organization_id },
      ];
    }

    return acc;
  }, []);

const getShownColumns = columns => {
  const isColumnsShown = {};

  columns.forEach(({ dataIndex, isShowColumn }) => {
    isColumnsShown[dataIndex] = isShowColumn;
  });

  return isColumnsShown;
};

export const getSummary = (pageData, columns, columnsSettings) => {
  let totalSpentHours = 0;
  let totalCost = 0;
  const employees = new Set();
  const employers = new Set();

  const {
    project_key,
    project_name,
    employer,
    employee_name,
    spent_hours,
    cost,
  } = getShownColumns(columnsSettings);

  const employerColumns = columns.find(
    column => column.key === SUBCONTRACTS_TABLE_INDEX.employer,
  );

  const nameColumn = columns.find(
    column => column.key === SUBCONTRACTS_TABLE_INDEX.employee_id,
  );

  // @ts-ignore
  pageData.forEach(({ spent_hours, cost, employee_id, employer }) => {
    totalSpentHours += +spent_hours;
    totalCost += +cost;
    employees.add(employee_id);
    employers.add(employer);
  });

  return (
    <>
      <Table.Summary.Row>
        {project_key && <Table.Summary.Cell />}
        {project_name && (
          <Table.Summary.Cell index={0}>
            <TotalCell>TOTAL</TotalCell>
          </Table.Summary.Cell>
        )}
        {employer && (
          <Table.Summary.Cell index={1}>
            <Text strong>{employerColumns && employees.size}</Text>
          </Table.Summary.Cell>
        )}
        {employee_name && (
          <Table.Summary.Cell index={2}>
            <Text strong>{nameColumn && employers.size}</Text>
          </Table.Summary.Cell>
        )}
        {spent_hours && (
          <Table.Summary.Cell index={3}>
            <TotalCell>
              <div className={styles.totalCell}>
                {Number(totalSpentHours).toFixed(2)}
              </div>
            </TotalCell>
          </Table.Summary.Cell>
        )}
        {cost && (
          <Table.Summary.Cell index={4}>
            <TotalCell>
              <MoneyCell value={totalCost} />
            </TotalCell>
          </Table.Summary.Cell>
        )}
      </Table.Summary.Row>
    </>
  );
};
