import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const CustomLegend = props => {
  const { payload } = props;

  return (
    <ul className={styles.legend}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} className={styles.legendItem}>
          <span
            style={{ backgroundColor: entry.color }}
            className={styles.legendIcon}
          />
          <span>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

CustomLegend.propTypes = {
  payload: PropTypes.array.isRequired,
};

export default CustomLegend;
