exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dealInfoContainer-98256{display:flex;justify-content:space-between}.dealPeriodContainer-35ca0{margin-bottom:10px}.dealAlertContainer-ad3fb{display:flex;justify-content:center;margin:20px;align-items:center}.dealFormContainer-761a8{width:28%}", ""]);

// Exports
exports.locals = {
	"dealInfoContainer": "dealInfoContainer-98256",
	"dealPeriodContainer": "dealPeriodContainer-35ca0",
	"dealAlertContainer": "dealAlertContainer-ad3fb",
	"dealFormContainer": "dealFormContainer-761a8"
};