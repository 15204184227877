import { ROLE_TYPES } from 'utils/permissions';

export const TECHNOLOGIES_PERMISSIONS = {
  addPermission: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.hr,
    ROLE_TYPES.office_manger,
  ],
  updatePermission: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.hr,
    ROLE_TYPES.office_manger,
  ],
  deletePermission: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.hr,
    ROLE_TYPES.office_manger,
  ],
};
