import { EMPLOYEES_TABLE_COMMON } from 'containers/EmployeesProfiles/constants';
import { SORT_DIRECTIONS } from 'utils/constants';

export const loadEmployeeParams = {
  page: 1,
  size: 1000,
  sort: `${EMPLOYEES_TABLE_COMMON.full_name}:${SORT_DIRECTIONS.ascend}`,
  filters: {},
};

export const actionsTagColor = {
  create: {
    color: 'green',
  },
  update: {
    color: 'geekblue',
  },
  destroy: {
    color: 'red',
  },
};

export const ACTION_TYPES = [
  { text: 'update', value: 'update' },
  { text: 'create', value: 'create' },
  { text: 'destroy', value: 'destroy' },
];

export const DEFAULT_SORTER = 'created_at:descend';
