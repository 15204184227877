import React, { useState, useMemo } from 'react';
import { EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { hasRights } from 'utils/permissions';
import { PROFILES_PERMISSIONS } from 'containers/ProfilesPage/constants';
import { uniqBy } from 'lodash';
import BuddyHeader from './BuddyHeader';
import BuddyDisplay from './BuddyDisplay';
import BuddySelect from './BuddySelect';

import styles from './styles.scss';

const Buddy = ({
  summaryEmployees,
  currentBuddyIds,
  isSaveBuddyVisible,
  onChangeBuddy,
  onHideBuddySelect,
  onUpdateBuddy,
  role,
  isWard,
  isSummary,
  buddies,
}) => {
  const [showBuddySelect, setShowBuddySelect] = useState(false);

  const canUpdateBuddy =
    hasRights(role, PROFILES_PERMISSIONS.updateBuddy) || isWard;

  const onShowBuddySelect = () =>
    !isSummary && canUpdateBuddy && setShowBuddySelect(true);

  const onHide = () => {
    onHideBuddySelect();
    setShowBuddySelect(false);
  };

  const updatedEmployees = useMemo(
    () => uniqBy([...summaryEmployees, ...buddies], 'id'),
    [summaryEmployees],
  );

  const userBuddyInfo = useMemo(
    () => updatedEmployees.filter(item => currentBuddyIds.includes(item.id)),
    [updatedEmployees, currentBuddyIds],
  );

  const onUpdateBuddyHandler = () => {
    onUpdateBuddy();
    setShowBuddySelect(false);
  };

  const isDisplayEdit = !showBuddySelect && canUpdateBuddy && !isSummary;

  return (
    <div className={styles.userBuddy}>
      <BuddyHeader
        showBuddySelect={showBuddySelect}
        isSaveBuddyVisible={isSaveBuddyVisible}
        onHide={onHide}
        onUpdateBuddy={onUpdateBuddyHandler}
      />
      {isDisplayEdit && !isSummary && (
        <EditOutlined className={styles.editIcon} onClick={onShowBuddySelect} />
      )}
      {!showBuddySelect && userBuddyInfo && (
        <BuddyDisplay
          userBuddyInfo={userBuddyInfo}
          onShowBuddySelect={onShowBuddySelect}
        />
      )}
      {showBuddySelect && (
        <BuddySelect
          currentBuddyIds={currentBuddyIds}
          onChangeBuddy={onChangeBuddy}
          employees={updatedEmployees}
        />
      )}
    </div>
  );
};

Buddy.propTypes = {
  summaryEmployees: PropTypes.array,
  currentBuddyIds: PropTypes.array,
  isSaveBuddyVisible: PropTypes.bool,
  onChangeBuddy: PropTypes.func,
  onHideBuddySelect: PropTypes.func,
  onUpdateBuddy: PropTypes.func,
  role: PropTypes.string,
  isSummary: PropTypes.bool,
  isWard: PropTypes.bool,
  buddies: PropTypes.array,
};

Buddy.defaultProps = {
  summaryEmployees: [],
  isSaveBuddyVisible: false,
};

export default Buddy;
