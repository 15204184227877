import { takeLatest, call, put, take, select } from 'redux-saga/effects';
import { notification } from 'antd';
import dayjs from 'dayjs';
import firebase from 'firebase';
import { eventChannel } from 'redux-saga';

import { showNotification } from 'containers/AuthLayout/Header/utils';

import { get } from 'lodash';

import API from 'utils/api';

import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';
import { types, changeType } from './types';

function* syncNotifications() {
  const ref = firebase.firestore().collection('notifications');
  const channel = eventChannel(emit => ref.onSnapshot(emit));
  const startNotificationsTime = dayjs().add(10, 'seconds');
  const auth = yield select(state => state.getIn(['auth']).toJS());
  const userId = get(auth, 'user.id', 0);

  try {
    //TODO MAX CALL STACK ERROR
    while (true) {
      const data = yield take(channel);
      data.docChanges().forEach(change => {
        const notificationMessage = {
          docId: change.doc.id,
          ...change.doc.data(),
        };

        const curTime = dayjs();

        if (
          (change.type === changeType.ADDED ||
            change.type === changeType.MODIFIED) &&
          curTime.isAfter(startNotificationsTime) &&
          (notificationMessage.user_id === 0 ||
            notificationMessage.user_id === userId)
        ) {
          showNotification(notificationMessage);
        }
      });
      yield put({
        type: types.SYNC_NOTIFICATIONS_SUCCESS,
        payload: { data, userId },
      });
    }
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.SYNC_NOTIFICATIONS_FAILED,
      SAGA_MESSAGES.LOAD_NOTIFICATIONS_FAILED,
    );
  }
}

function* refreshNotifications() {
  try {
    yield call(API.refreshNotifications);
    yield put({ type: types.REFRESH_SYNC_NOTIFICATIONS_SUCCESS });
    notification['success']({ message: SAGA_MESSAGES.UPDATING_NOTIFICATIONS });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.REFRESH_NOTIFICATIONS_FAILED,
      SAGA_MESSAGES.LOAD_NOTIFICATIONS_FAILED,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.SYNC_NOTIFICATIONS, syncNotifications);
  yield takeLatest(types.REFRESH_SYNC_NOTIFICATIONS, refreshNotifications);
}
