import { actionFactory } from 'redux/factories';
import { types } from './types';

export const loadEmployees = actionFactory(types.LOAD_EMPLOYEES);
export const getEmployeesWithJira = actionFactory(
  types.GET_EMPLOYEES_WITH_JIRA,
);
export const getFullEmployees = actionFactory(types.GET_FULL_EMPLOYEES);
export const uploadEmployees = actionFactory(types.UPLOAD_EMPLOYEES);
export const getEmployee = actionFactory(types.GET_EMPLOYEE);
export const getEmployeeProfileData = actionFactory(
  types.GET_EMPLOYEE_PROFILE_DATA,
);
export const getEmployeeRm = actionFactory(types.GET_EMPLOYEE_RM);
export const getEmployeePaysheets = actionFactory(types.GET_EMPLOYEE_PAYSHEETS);
export const updateEmployee = actionFactory(types.UPDATE_EMPLOYEE);
export const updateEmployeeRm = actionFactory(types.UPDATE_EMPLOYEE_RM);
export const createEmployee = actionFactory(types.CREATE_EMPLOYEE);
export const getEmployeeSalaries = actionFactory(types.GET_EMPLOYEE_SALARIES);
export const updateEmployeeSalaries = actionFactory(
  types.UPDATE_EMPLOYEE_SALARIES,
);
export const deleteEmployeeSalaries = actionFactory(
  types.DELETE_EMPLOYEE_SALARIES,
);
export const getEmployeeExtraPayments = actionFactory(
  types.GET_EMPLOYEE_EXTRA_PAYMENTS,
);
export const addEmployeeSalaries = actionFactory(types.ADD_EMPLOYEE_SALARIES);

export const addEmployeeExtraPayments = actionFactory(
  types.ADD_EMPLOYEE_EXTRA_PAYMENTS,
);
export const getEmployeeTechnologies = actionFactory(
  types.GET_EMPLOYEE_TECHNOLOGIES,
);
export const updateEmployeeTechnologies = actionFactory(
  types.UPDATE_EMPLOYEE_TECHNOLOGIES,
);
export const addAndUpdateTechnology = actionFactory(
  types.ADD_AND_UPDATE_TECHNOLOGIES,
);
export const getEmployeeComments = actionFactory(types.GET_EMPLOYEE_COMMENTS);
export const addEmployeeComment = actionFactory(types.ADD_EMPLOYEE_COMMENT);
export const updateEmployeeComment = actionFactory(
  types.UPDATE_EMPLOYEE_COMMENT,
);
export const deleteEmployeeComment = actionFactory(
  types.DELETE_EMPLOYEE_COMMENT,
);
export const getEmployeeAttachments = actionFactory(
  types.GET_EMPLOYEE_ATTACHMENTS,
);
export const addEmployeeAttachment = actionFactory(
  types.ADD_EMPLOYEE_ATTACHMENT,
);
export const deleteEmployeeAttachment = actionFactory(
  types.DELETE_EMPLOYEE_ATTACHMENT,
);
export const updateBuddies = actionFactory(types.UPDATE_BUDDIES);
export const cancelAttachmentUpload = actionFactory(
  types.CANCEL_ATTACHMENT_UPLOAD,
);
export const updateEmployeeExtraPayment = actionFactory(
  types.UPDATE_EMPLOYEE_EXTRA_PAYMENTS,
);
export const deleteEmployeeExtraPayment = actionFactory(
  types.DELETE_EMPLOYEE_EXTRA_PAYMENTS,
);
export const approveEmployeeExtraPayment = actionFactory(
  types.APPROVE_EMPLOYEE_EXTRA_PAYMENTS,
);
export const declineEmployeeExtraPayment = actionFactory(
  types.DECLINE_EMPLOYEE_EXTRA_PAYMENTS,
);
export const getIbanCurrency = actionFactory(types.GET_IBAN_CURRENCY);
export const setIbanSelectedCurrency = actionFactory(
  types.SET_IBAN_SELECTED_CURRENCY,
);
export const clearEmployeeStore = actionFactory(types.CLEAR_EMPLOYEE_STORE);
export const updateResourceManager = actionFactory(
  types.UPDATE_RESOURCE_MANAGER,
);
