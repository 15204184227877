import { RM_PLAN_TYPES } from 'containers/ResourceManagementPage/constants';
import dayjs from 'dayjs';

const calculateCellWidth = (condition, numberDaysOfMonth) =>
  condition ? '270px' : `calc((100% - 270px)/${numberDaysOfMonth})`;

const projectIcon =
  'https://jira.yellow.team/secure/projectavatar?pid=10505&avatarId=10011';

const calculateTotalTime = ({ row, calendar }) => {
  const numberWorkingDays = calendar.filter(
    item =>
      dayjs(item.date).isAfter(dayjs().subtract(1, 'day')) && !item.is_day_off,
  ).length;

  let totalTime = 0;
  for (const key in row.values) {
    const element = row.values[key];
    const day = calendar.find(elem => elem.date === key);
    const condition = day ? !day.is_day_off : true;

    if (dayjs(key).isAfter(dayjs().subtract(1, 'day')) && condition) {
      const seconds = calculateSecondsWithoutBench(element);
      totalTime += seconds / 3600;
    }
  }
  const time = totalTime
    ? numberWorkingDays * 8 - totalTime
    : numberWorkingDays * 8;

  const minutes = dayjs.duration(time, 'hours').minutes();

  return time % 1
    ? `${Math.trunc(time)} h ${minutes} m`
    : `${Math.trunc(time)} h`;
};

const timeView = (type, seconds) => {
  switch (type) {
    case RM_PLAN_TYPES.BENCH:
      return 'B';
    case RM_PLAN_TYPES.PROJECT:
      return seconds / 3600;
    case RM_PLAN_TYPES.SICKLEAVE:
      return 'S';
    case RM_PLAN_TYPES.VACATION:
      return 'V';
    default:
      return seconds / 3600;
  }
};

const calculateSecondsWithoutBench = value =>
  Array.isArray(value) &&
  value.reduce((accumulator, currentValue) => {
    if (currentValue.planItem.type !== RM_PLAN_TYPES.BENCH) {
      return accumulator
        ? accumulator + currentValue.secondsPerDay
        : currentValue.secondsPerDay;
    }

    return accumulator;
  }, 0);

const calculateCellSeconds = value =>
  Array.isArray(value) &&
  value.reduce((accumulator, currentValue) => {
    if (currentValue.planItem.type === RM_PLAN_TYPES.PROJECT) {
      return accumulator
        ? accumulator + currentValue.secondsPerDay
        : currentValue.secondsPerDay;
    }

    return accumulator;
  }, 0);

const colorBadge = type => {
  switch (type) {
    case RM_PLAN_TYPES.BENCH:
      return 'gold';
    case RM_PLAN_TYPES.VACATION:
      return 'green';
    case RM_PLAN_TYPES.SICKLEAVE:
      return 'purple';
    default:
      return 'blue';
  }
};

const findProjectsNames = (cell, projects) =>
  Array.isArray(cell.value) &&
  cell.value.map(item => {
    const hours = Math.trunc(item.secondsPerDay / 3600);
    const minutes = dayjs.duration(item.secondsPerDay, 'seconds').minutes();
    const time = minutes ? `${hours} h ${minutes} м` : `${hours} h`;

    if (
      item.planItem.type === RM_PLAN_TYPES.BENCH ||
      item.planItem.type === RM_PLAN_TYPES.SICKLEAVE ||
      item.planItem.type === RM_PLAN_TYPES.VACATION
    ) {
      const type =
        item.planItem.type === RM_PLAN_TYPES.SICKLEAVE
          ? 'Sick leave'
          : item.planItem.type;

      return { name: type, time };
    }

    const project = projects.find(project => +project.id === +item.planItem.id);

    return { name: project && project.name, time };
  });

const getCell = (value, condition) =>
  value.find(item => item.planItem.type === condition);

export {
  projectIcon,
  calculateTotalTime,
  calculateCellWidth,
  timeView,
  calculateCellSeconds,
  colorBadge,
  findProjectsNames,
  getCell,
};
