exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dashboardEmployeeOverview-8f729 .dashboardHeader-552f0{display:flex;align-items:center}.dashboardEmployeeOverview-8f729 .rangePicker-84686{width:250px}.dashboardEmployeeOverview-8f729 .withoutSubcontractors-12a54{display:flex;align-items:center;margin-right:20px}.dashboardEmployeeOverview-8f729 .withoutSubcontractors-12a54 span{display:inline-block;margin-right:5px}.dashboardEmployeeOverview-8f729 .totalDelivery-8ba88{display:inline-block;margin-right:20px}.dashboardEmployeeOverview-8f729 .redCell-251b5{background-color:rgba(222,96,96,.25)}.dashboardEmployeeOverview-8f729 .greenCell-5a3b1{background-color:rgba(144,222,96,.25)}", ""]);

// Exports
exports.locals = {
	"dashboardEmployeeOverview": "dashboardEmployeeOverview-8f729",
	"dashboardHeader": "dashboardHeader-552f0",
	"rangePicker": "rangePicker-84686",
	"withoutSubcontractors": "withoutSubcontractors-12a54",
	"totalDelivery": "totalDelivery-8ba88",
	"redCell": "redCell-251b5",
	"greenCell": "greenCell-5a3b1"
};