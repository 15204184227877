export const types = {
  GET_ROLES: 'roles/GET_ROLES',
  GET_ROLES_SUCCESS: 'roles/GET_ROLES_SUCCESS',
  GET_ROLES_FAILED: 'roles/GET_ROLES_FAILED',

  CREATE_ROLE: 'roles/CREATE_ROLE',
  CREATE_ROLE_SUCCESS: 'roles/CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILED: 'roles/CREATE_ROLE_FAILED',

  UPDATE_ROLE: 'roles/UPDATE_ROLE',
  UPDATE_ROLE_SUCCESS: 'roles/UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAILED: 'roles/UPDATE_ROLE_FAILED',

  DELETE_ROLE: 'roles/DELETE_ROLE',
  DELETE_ROLE_SUCCESS: 'roles/DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILED: 'roles/DELETE_ROLE_FAILED',

  GET_PERMISSIONS: 'roles/GET_PERMISSIONS',
  GET_PERMISSIONS_SUCCESS: 'roles/GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_FAILED: 'roles/GET_PERMISSIONS_FAILED',

  GET_ROLE_TYPES: 'roles/GET_ROLE_TYPES',
  GET_ROLE_TYPES_SUCCESS: 'roles/GET_ROLE_TYPES_SUCCESS',
  GET_ROLE_TYPES_FAILED: 'roles/GET_ROLE_TYPES_FAILED',
};
