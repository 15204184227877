import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Badge, Row, Radio, Checkbox } from 'antd';
import {
  without,
  isEqual,
  concat,
  uniqWith,
  orderBy,
  xorWith,
  get,
} from 'lodash';
import dayjs from 'dayjs';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import PageLoader from 'components/Common/PageLoader';
import JiraProgress from 'components/Common/JiraProgress';
import PageHeader from 'components/Common/PageHeader';
import { Loader } from 'components/Common';
import {
  Table,
  PlanTimeForm,
  FilterForm,
} from 'components/ResourceManagementCommon';
import RangePicker from 'components/Common/RangePicker';
import { JIRA_FIREBASE_PATH, OK_STATUS, TEAM_ID } from 'utils/constants';
import { DATE_FORMAT, DATE_MONTH_FORMAT } from 'utils/timeConstants';
import {
  getData,
  updatePlanById,
  deletePlanById,
  createPlan,
  setTableData,
  createPlanByType,
  updatePlanByType,
  deletePlanByType,
  getRmEmployees,
  getRmProjects,
} from 'redux/ResourceManagement/actions';
import { clearRmDealAllocations } from 'redux/Deals/actions';
import { getPositions } from 'redux/EmployeePositions/actions';
import { getJiraProjects, updateJiraProjects } from 'redux/Jira/actions';
import { getTeams } from 'redux/Teams/actions';
import { getCalendar } from 'redux/Calendar/actions';
import { getTechnologies } from 'redux/Technologies/actions';
import {
  authSelector,
  calendarSelector,
  dealsSelector,
  employeePositionsSelector,
  jiraSelector,
  resourceManagementSelector,
  teamsSelector,
  technologiesSelector,
} from 'redux/selectors';
import { useFirebaseProgress, usePrevState, useRoleContext } from 'utils/hooks';
import { hasRights } from 'utils/permissions';

import TableStyles from './tableStyles';
import {
  usersColumn,
  formatedDataForModal,
  columnObj,
  formatedDataForCreate,
  findManagers,
  calculateData,
  calculateTotalTime,
  defaultDataForModal,
  groupBy,
  findPlans,
  filterData,
  getRoundValue,
  getFilterValuesOnCheckbox,
  getFilterValuesOnSearch,
  clearFilters,
  getFilteredEmployees,
} from './utils';
import {
  LOCAL_STORAGE_KEYS,
  RM_MODES,
  RM_PERMISSIONS,
  RM_PLAN_TYPES,
} from './constants';
import styles from './styles.scss';

let unsubscribe;

const ResourceManagementPage = () => {
  const role = useRoleContext();
  const canUseFilters = hasRights(role, RM_PERMISSIONS.filters);
  const defaultMode = canUseFilters ? RM_MODES.BY_RM : RM_MODES.ALL;
  const defaultTeams = canUseFilters ? [TEAM_ID.YELLOW] : [];
  const defaultDelivery = !!canUseFilters;
  const defaultHidePms = !!canUseFilters;

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [indexRows, setIndexRows] = useState([]);
  const [selectedManagerIndex, setSelectedManagerIndex] = useState([]);
  const [selectedUserIndex, setSelectedUserIndex] = useState([]);
  const [selectedTechnologiesIndex, setSelectedTechnologiesIndex] = useState(
    [],
  );
  const [selectedPositionsIndex, setSelectedPositionsIndex] = useState([]);
  const [selectedTeamsIndex, setSelectedTeamsIndex] = useState(defaultTeams);
  const [startDate, setStartDate] = useState(dayjs().format(DATE_FORMAT));
  const [endDate, setEndDate] = useState(
    dayjs()
      .startOf('week')
      .add(28, 'day')
      .format(DATE_FORMAT),
  );
  const [modalData, setModalData] = useState({});
  const [defaultRadioValue, setDefaultRadioValue] = useState(defaultMode);
  const [curPlanId, setCurPlanId] = useState(null);
  const [curPlanType, setCurPlanType] = useState(null);
  const [deliveryCheckbox, setDeliveryCheckbox] = useState(defaultDelivery);
  const [hidePms, setHidePms] = useState(defaultHidePms);
  const [isOpenTree, setIsOpenTree] = useState(false);
  const [isUpdatePlan, setIsUpdatePlan] = useState(false);

  const { progress, getFirebaseData } = useFirebaseProgress();

  const prevProgress = usePrevState(progress);

  const dispatch = useDispatch();

  const { user, isAuthLoading } = useSelector(authSelector);
  const { plans, isRmLoading, data, rmEmployees, projects } = useSelector(
    resourceManagementSelector,
  );
  const { calendar } = useSelector(calendarSelector);
  const { rmDealAllocations } = useSelector(dealsSelector);
  const { technologies, isTechnologiesLoading } = useSelector(
    technologiesSelector,
  );
  const { positions, isPositionsLoading } = useSelector(
    employeePositionsSelector,
  );
  const { teams, isTeamsLoading } = useSelector(teamsSelector);

  const isPageLoading =
    isAuthLoading ||
    isTechnologiesLoading ||
    isPositionsLoading ||
    isTeamsLoading;

  const summaryEmployeesWithJira = useMemo(
    () => rmEmployees.filter(employee => employee.jira_id),
    [rmEmployees],
  );

  const canUpdatePlans = hasRights(role, RM_PERMISSIONS.updatePlans);

  useEffect(() => {
    unsubscribe && unsubscribe();
    unsubscribe = getFirebaseData({
      collection: JIRA_FIREBASE_PATH.collection,
      doc: JIRA_FIREBASE_PATH.doc,
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (
      progress &&
      progress.status === OK_STATUS &&
      prevProgress &&
      prevProgress.status !== OK_STATUS
    ) {
      dispatch(getJiraProjects());
    }
  }, [progress]);

  const onUpdateJiraProjects = () => {
    dispatch(updateJiraProjects());
  };

  const setFilters = ({
    selectedManagerIndex,
    selectedUserIndex,
    selectedTechnologiesIndex,
    selectedPositionsIndex,
    selectedTeamsIndex,
  }) => {
    setSelectedManagerIndex(selectedManagerIndex || []);
    setSelectedUserIndex(selectedUserIndex || []);
    setSelectedTechnologiesIndex(selectedTechnologiesIndex || []);
    setSelectedPositionsIndex(selectedPositionsIndex || []);
    setSelectedTeamsIndex(selectedTeamsIndex || []);
  };

  const onClearFilter = () => {
    const {
      selectedManagerIndex,
      selectedUserIndex,
      selectedTechnologiesIndex,
      selectedPositionsIndex,
      selectedTeamsIndex,
    } = clearFilters();
    setFilters({
      selectedManagerIndex,
      selectedUserIndex,
      selectedTechnologiesIndex,
      selectedPositionsIndex,
      selectedTeamsIndex,
    });
  };

  const onSearchByType = type => value => {
    const filters = getFilterValuesOnSearch({
      selectedManagerIndex,
      selectedUserIndex,
      selectedTechnologiesIndex,
      selectedPositionsIndex,
      selectedTeamsIndex,
      type,
      value,
    });

    setFilters({
      selectedManagerIndex: filters.selectedManagerIndex,
      selectedUserIndex: filters.selectedUserIndex,
      selectedTechnologiesIndex: filters.selectedTechnologiesIndex,
      selectedPositionsIndex: filters.selectedPositionsIndex,
      selectedTeamsIndex: filters.selectedTeamsIndex,
    });
  };

  const onChangeCheckbox = (value, type) => event => {
    event.stopPropagation();

    const filters = getFilterValuesOnCheckbox({
      selectedManagerIndex,
      selectedUserIndex,
      selectedTechnologiesIndex,
      selectedPositionsIndex,
      selectedTeamsIndex,
      type,
      value,
    });

    setFilters({
      selectedManagerIndex: filters.selectedManagerIndex,
      selectedUserIndex: filters.selectedUserIndex,
      selectedTechnologiesIndex: filters.selectedTechnologiesIndex,
      selectedPositionsIndex: filters.selectedPositionsIndex,
      selectedTeamsIndex: filters.selectedTeamsIndex,
    });
  };

  const calculateSubDataForTable = (filterData, columnDataObject) =>
    filterData.map(filData => {
      const subProps = calculateData(
        columnDataObject,
        projects,
        filData,
        startDate,
      );

      const {
        name_en,
        name_ru,
        id = '',
        jira_id = '',
        jira_user = {},
        employee_position,
        email,
        team_id,
        resource_manager = {},
        technologies = [],
      } = filData;

      return {
        name: name_en || name_ru || '',
        id: id,
        manager: false,
        jiraId: jira_id,
        avatarUrl: get(jira_user, ['avatarUrls']),
        jiraName: get(jira_user, ['id']),
        technologies: technologies,
        employee_position: employee_position,
        isCurUser: email === get(user, 'email'),
        team_id: team_id,
        resource_manager: get(resource_manager, ['jira_id']),
        ...subProps,
      };
    });

  const calculateDataForTableByRM = () => {
    const managers = findManagers(summaryEmployeesWithJira);
    const currentUser = summaryEmployeesWithJira.find(
      item => item.email === user.email,
    );
    const currentManager = currentUser
      ? managers.find(item => get(item, 'email') === get(currentUser, 'email'))
      : [];
    const filterManagers = currentUser
      ? uniqWith([currentManager, ...managers], isEqual)
      : managers;

    const columnDataObject = groupBy('key')(plans);

    const data = filterManagers.reduce((acc, item) => {
      const props = calculateData(columnDataObject, projects, item, startDate);

      const filteredEmployees = getFilteredEmployees({
        employees: summaryEmployeesWithJira,
        onlyDelivery: deliveryCheckbox,
        hidePms,
      });

      const filteredData = filteredEmployees.filter(
        employee =>
          get(employee, 'resource_manager') &&
          item &&
          get(employee, ['resource_manager', 'id']) === get(item, 'id') &&
          get(employee, 'id') !== get(item, 'id') &&
          !get(employee, 'is_skip_resource_manager'),
      );
      const subData = calculateSubDataForTable(filteredData, columnDataObject);

      if (
        subData.length ||
        (get(item, 'employee_position') &&
          get(item, ['employee_position', 'is_delivery']))
      ) {
        return [
          ...acc,
          [
            {
              name: get(item, 'name_en') || get(item, 'name_ru'),
              id: get(item, 'id'),
              manager: true,
              resource_manager: get(item, ['resource_manager', 'jira_id']),
              jiraId: get(item, 'jira_id'),
              avatarUrl: get(item, ['jira_user', 'avatarUrls']),
              jiraName: get(item, ['jira_user', 'id']),
              technologies: get(item, 'technologies'),
              employee_position: get(item, 'employee_position'),
              isCurUser: get(item, 'email') === get(user, 'email'),
              team_id: get(item, 'team_id'),
              ...props,
            },
            ...subData,
          ],
        ];
      }

      return acc;
    }, []);

    return data;
  };

  const calculateDataForTableByAll = () => {
    const columnDataObject = groupBy('key')(plans);
    const filteredEmployees = getFilteredEmployees({
      employees: summaryEmployeesWithJira,
      onlyDelivery: deliveryCheckbox,
      hidePms,
    });
    const data = filteredEmployees.map(item => {
      const props = calculateData(columnDataObject, projects, item, startDate);

      return {
        name: get(item, 'name_en') || get(item, 'name_ru'),
        id: get(item, 'id'),
        manager: false,
        resource_manager: get(item, ['resource_manager', 'jira_id']),
        jiraId: get(item, 'jira_id'),
        avatarUrl: get(item, ['jira_user', 'avatarUrls']),
        jiraName: get(item, ['jira_user', 'id']),
        technologies: get(item, 'technologies'),
        employee_position: get(item, 'employee_position'),
        isCurUser: get(item, 'email') === get(user, 'email'),
        team_id: get(item, 'team_id'),
        ...props,
      };
    });

    return data;
  };

  useEffect(() => {
    if (canUseFilters) {
      const radio = localStorage.getItem(LOCAL_STORAGE_KEYS.rmRadio);
      const delivery = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.rmDelivery),
      );
      const hidePms = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.rmHidePms),
      );
      const filters = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.rmFilters),
      );

      if (delivery) {
        setDeliveryCheckbox(delivery);
      }

      if (hidePms) {
        setHidePms(hidePms);
      }

      if (radio) {
        setDefaultRadioValue(radio);
      }

      if (filters) {
        /// selectedTeamsIndex is always "1" by default
        setFilters({ ...filters, selectedTeamsIndex: [1] });
      } else {
        setFilters({ ...clearFilters(), selectedTeamsIndex: [1] });
      }
    }

    dispatch(
      getData({
        start: startDate,
        end: endDate,
      }),
    );
    dispatch(getPositions());
    dispatch(getRmProjects());
    dispatch(getTeams());
    dispatch(getCalendar({ from: startDate, to: endDate }));
    dispatch(getTechnologies());
    dispatch(getRmEmployees({ from: startDate, to: endDate }));
  }, []);

  useEffect(() => {
    if (isRmLoading) return;

    const tableDataByRM = calculateDataForTableByRM();
    const tableDataByAll = orderBy(
      calculateDataForTableByAll(),
      ['name'],
      ['asc'],
    );
    const tableData =
      defaultRadioValue === RM_MODES.BY_RM
        ? [...tableDataByRM]
        : [...tableDataByAll];

    if (xorWith(tableData, data, isEqual).length) {
      dispatch(setTableData(tableData));
    }
  }, [
    plans,
    defaultRadioValue,
    summaryEmployeesWithJira,
    deliveryCheckbox,
    hidePms,
  ]);

  const canUpdateDeals = hasRights(role, RM_PERMISSIONS.updateProjectPlan);

  const onClickCell = event => {
    if (!canUpdatePlans) return;

    const projectKey = event.target.getAttribute('projectid');
    const row = event.target.getAttribute('row');
    const column = event.target.getAttribute('column');
    const curPlanId = event.target.getAttribute('planid');
    const curPlanType = event.target.getAttribute('plantype');

    if (curPlanType === RM_PLAN_TYPES.PROJECT && !canUpdateDeals) return;

    if (column !== 'name') {
      const columnDataObject = groupBy('key')(plans);
      const formatedPlan =
        columnDataObject[row] &&
        columnDataObject[row].find(item => {
          const timeCondition = dayjs(column).isBetween(
            dayjs(item.start).subtract(1, 'day'),
            dayjs(item.end).add(1, 'day'),
          );
          const typeCondition =
            item.planItem.type === RM_PLAN_TYPES.PROJECT
              ? Number(get(item, ['planItem', 'id'])) === Number(projectKey)
              : get(item, ['planItem', 'type']) === projectKey;

          return timeCondition && typeCondition;
        });

      const defaultEmployees = summaryEmployeesWithJira.find(
        item => get(item, 'jira_id') === row,
      );
      const defaultProjects =
        projectKey && projects.find(item => get(item, 'id') === projectKey);

      const project =
        formatedPlan &&
        projects.find(
          item =>
            Number(get(item, 'id')) ===
            Number(get(formatedPlan, ['planItem', 'id'])),
        );
      const defaultProps = {
        defaultEmployees,
        defaultProjects,
      };

      const planType = isNaN(+projectKey)
        ? projectKey
        : canUpdateDeals
        ? RM_PLAN_TYPES.DEALS
        : RM_PLAN_TYPES.BENCH;
      const defaultModalData = defaultDataForModal(
        defaultProps,
        column,
        planType,
      );
      const modalData = formatedDataForModal(formatedPlan, project);

      setIsUpdatePlan(!!modalData);
      setIsVisibleModal(true);
      setModalData(modalData || defaultModalData || {});
      setCurPlanId(curPlanId);
      setCurPlanType(curPlanType);
    }
  };

  const onCloseOpenTree = index => {
    const indexRowsNew =
      indexRows.find(item => item === index) === 0 ||
      indexRows.find(item => item === index)
        ? without(indexRows, index)
        : [...indexRows, index];
    setIsOpenTree(prev => !prev);
    setIndexRows(indexRowsNew);
  };

  const hideModal = () => {
    setModalData({});
    setIsVisibleModal(false);
    dispatch(clearRmDealAllocations());
    setIsUpdatePlan(false);
  };

  const onSubmit = values => {
    if (values) {
      const curUser = summaryEmployeesWithJira.find(
        item => get(item, ['jira_user', 'id']) === get(values, 'name'),
      );

      const selectedProject = projects.find(
        item => get(item, 'id') === get(values, 'project'),
      );
      const params = formatedDataForCreate(
        { ...values, project: selectedProject && get(selectedProject, 'id') },
        curUser,
        calendar,
      );

      const plan = findPlans(plans, params, curPlanId, curPlanType);

      if (plan && plan.id) {
        if (
          values.planType === RM_PLAN_TYPES.PROJECT ||
          values.planType === RM_PLAN_TYPES.DEALS
        ) {
          dispatch(updatePlanById({ plans, updatingPlanId: plan.id, params }));
        } else {
          dispatch(
            updatePlanByType({ plans, updatingPlanId: plan.id, params }),
          );
        }
      } else {
        if (
          values.planType === RM_PLAN_TYPES.PROJECT ||
          values.planType === RM_PLAN_TYPES.DEALS
        ) {
          dispatch(createPlan({ params }));
        } else {
          dispatch(createPlanByType({ path: curUser.id, params }));
        }
      }

      hideModal();
    }
  };

  const increaseDate = () => {
    const start = dayjs(startDate, DATE_FORMAT)
      .add(1, 'months')
      .date(1)
      .format(DATE_FORMAT);
    const end = dayjs(startDate, DATE_FORMAT)
      .add(2, 'months')
      .date(0)
      .format(DATE_FORMAT);

    setStartDate(start);
    setEndDate(end);

    dispatch(
      getData({
        start,
        end,
      }),
    );
    dispatch(getCalendar({ from: start, to: end }));
    dispatch(getRmEmployees({ from: start, to: end }));
  };

  const decreaseDate = () => {
    const start = dayjs(endDate, DATE_FORMAT)
      .subtract(1, 'months')
      .date(1)
      .format(DATE_FORMAT);
    const end = dayjs(endDate, DATE_FORMAT)
      .date(0)
      .format(DATE_FORMAT);

    setStartDate(start);
    setEndDate(end);

    dispatch(
      getData({
        start,
        end,
      }),
    );
    dispatch(getCalendar({ from: start, to: end }));
    dispatch(getRmEmployees({ from: start, to: end }));
  };

  const onChangeDate = (value, formatedVal) => {
    const daysNumbers = dayjs(formatedVal[1]).diff(
      dayjs(formatedVal[0]),
      'days',
    );
    const start = formatedVal[0];
    const end =
      daysNumbers > 30
        ? dayjs(formatedVal[0])
            .add(30, 'days')
            .format(DATE_FORMAT)
        : formatedVal[1];

    setStartDate(start);
    setEndDate(end);

    dispatch(
      getData({
        start,
        end,
      }),
    );
    dispatch(getCalendar({ from: start, to: end }));
    dispatch(getRmEmployees({ from: start, to: end }));
  };

  const onChangeRadio = event => {
    setDefaultRadioValue(event.target.value);
    localStorage.setItem(LOCAL_STORAGE_KEYS.rmRadio, event.target.value);
  };

  const onChangeDeliveryCheckbox = event => {
    setDeliveryCheckbox(event.target.checked);
    localStorage.setItem(LOCAL_STORAGE_KEYS.rmDelivery, event.target.checked);
  };

  const onChangeHidePms = event => {
    setHidePms(event.target.checked);
    localStorage.setItem(LOCAL_STORAGE_KEYS.rmHidePms, event.target.checked);
  };

  const dateColumns = calendar.map(item => {
    const title = dayjs(item.date, DATE_FORMAT).format('D dd');
    const accessor = dayjs(item.date, DATE_FORMAT).format(DATE_FORMAT);

    return columnObj(title, accessor, item.is_day_off);
  });

  const tableData = concat(...data);
  const columns = [...usersColumn, ...dateColumns];

  const filteredByTechnology =
    selectedTechnologiesIndex.length &&
    tableData.filter(item =>
      item.technologies.find(
        el => selectedTechnologiesIndex.indexOf(el.title) !== -1,
      ),
    );

  const filteredByPosition =
    selectedPositionsIndex.length &&
    tableData.filter(
      item =>
        item.employee_position &&
        selectedPositionsIndex.indexOf(item.employee_position.title) !== -1,
    );

  const filteredByTeams =
    selectedTeamsIndex.length &&
    tableData.filter(
      item =>
        get(item, 'team_id') &&
        selectedTeamsIndex.indexOf(get(item, 'team_id')) !== -1,
    );

  const updatedData =
    !selectedManagerIndex.length &&
    !selectedUserIndex.length &&
    !filteredByTechnology &&
    !filteredByTeams &&
    !filteredByPosition
      ? tableData
      : filterData(tableData, {
          selectedTeamsIndex,
          selectedPositionsIndex,
          selectedTechnologiesIndex,
          selectedUserIndex,
          selectedManagerIndex,
          jiraUsers: summaryEmployeesWithJira,
        });

  const {
    remainingDays,
    secondsByBench,
    secondsByVacation,
    secondsBySickleave,
  } = calculateTotalTime({
    plans,
    endDate,
    updatedData,
    calendar,
    startDate,
    selectedManagerIndex,
    selectedUserIndex,
    selectedTechnologiesIndex,
    selectedPositionsIndex,
    selectedTeamsIndex,
    employees: summaryEmployeesWithJira,
    data: tableData,
  });

  const onDeletePlanById = id => dispatch(deletePlanById(id));

  const onDeletePlanByType = type => dispatch(deletePlanByType(type));

  if (isPageLoading) return <PageLoader />;

  return (
    <Loader loading={isRmLoading}>
      <PageHeader
        title="Resource management"
        className={styles.header}
        extraClassName={styles.extra}
        extra={
          hasRights(role, RM_PERMISSIONS.overall) && (
            <Row className={styles.timeField}>
              <span>Total hours available {remainingDays}</span>
              <Badge
                className={styles.nameTech}
                color="gold"
                text={<span>Bench {getRoundValue(secondsByBench)} h</span>}
              />
              <Badge
                className={styles.nameTech}
                color="green"
                text={
                  <span>Vacation {getRoundValue(secondsByVacation)} h</span>
                }
              />
              <Badge
                className={styles.nameTech}
                color="purple"
                text={
                  <span>Sick leave {getRoundValue(secondsBySickleave)} h</span>
                }
              />
              <div className={styles.progress}>
                <JiraProgress progress={progress} />
                <Button
                  className={styles.updateButton}
                  type="primary"
                  onClick={onUpdateJiraProjects}
                  loading={progress && progress.status !== OK_STATUS}
                >
                  Update projects
                </Button>
              </div>
            </Row>
          )
        }
      />
      <Modal
        className={styles.modal}
        title="Plan Time"
        open={isVisibleModal}
        onCancel={hideModal}
        width={600}
        destroyOnClose
        maskClosable
        footer={[
          <Button onClick={hideModal}>Cancel</Button>,
          <Button form="planTime" key="submit" htmlType="submit" type="primary">
            Save
          </Button>,
        ]}
      >
        <PlanTimeForm
          fieldValues={modalData}
          projects={projects}
          jiraUsers={summaryEmployeesWithJira}
          onFinish={onSubmit}
          dealAllocations={rmDealAllocations}
          role={role}
          isUpdatePlan={isUpdatePlan}
        />
      </Modal>

      <div className={styles.headerTable}>
        <div className={styles.dataPicker}>
          {hasRights(role, RM_PERMISSIONS.filters) && (
            <>
              <Radio.Group
                defaultValue={defaultRadioValue}
                style={{ marginRight: '20px' }}
                onChange={onChangeRadio}
              >
                <Radio.Button value={RM_MODES.BY_RM}>By RM</Radio.Button>
                <Radio.Button value={RM_MODES.ALL}>All</Radio.Button>
              </Radio.Group>
              <div className={styles.checkboxes}>
                <Checkbox
                  onChange={onChangeDeliveryCheckbox}
                  checked={deliveryCheckbox}
                  className={styles.deliveryCheckbox}
                >
                  Show only Delivery
                </Checkbox>
                <Checkbox
                  onChange={onChangeHidePms}
                  checked={hidePms}
                  className={styles.pmCheckbox}
                >
                  Hide PMs
                </Checkbox>
              </div>
            </>
          )}
          <Button className={styles.arrow} onClick={decreaseDate}>
            <LeftOutlined />
          </Button>
          <RangePicker
            allowClear={false}
            value={[dayjs(startDate, DATE_FORMAT), dayjs(endDate, DATE_FORMAT)]}
            format={DATE_MONTH_FORMAT}
            onChange={onChangeDate}
            className={styles.rangePicker}
          />
          <Button className={styles.arrow} onClick={increaseDate}>
            <RightOutlined />
          </Button>
        </div>
        {hasRights(role, RM_PERMISSIONS.filters) && (
          <FilterForm
            managers={findManagers(summaryEmployeesWithJira)}
            jiraUsers={summaryEmployeesWithJira}
            technologies={technologies}
            selectedManagerIndex={selectedManagerIndex}
            selectedUserIndex={selectedUserIndex}
            selectedTechnologiesIndex={selectedTechnologiesIndex}
            selectedTeamsIndex={selectedTeamsIndex}
            selectedPositionsIndex={selectedPositionsIndex}
            onChangeCheckbox={onChangeCheckbox}
            onSearchByType={onSearchByType}
            onClearFilter={onClearFilter}
            employeePositions={positions}
            teams={teams}
          />
        )}
      </div>
      <TableStyles>
        <Table
          columns={columns}
          data={updatedData || []}
          onClickCell={onClickCell}
          onCloseOpenTree={onCloseOpenTree}
          deletePlanById={onDeletePlanById}
          deletePlanByType={onDeletePlanByType}
          indexRows={indexRows}
          plans={plans}
          calendar={calendar}
          startDate={startDate}
          endDate={endDate}
          projects={projects}
          canUpdatePlans={canUpdatePlans}
          canUpdateDeals={canUpdateDeals}
        />
      </TableStyles>
    </Loader>
  );
};

export default ResourceManagementPage;
