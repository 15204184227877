import React from 'react';

import TableActions from 'components/Common/TableActions';

import { INVOICE_TYPES_COMMON } from './constants';

export const getColumns = ({ onEdit, onDeleteType }) => [
  {
    title: INVOICE_TYPES_COMMON.id_title,
    dataIndex: INVOICE_TYPES_COMMON.id,
  },
  {
    title: INVOICE_TYPES_COMMON.name_title,
    dataIndex: INVOICE_TYPES_COMMON.name,
  },
  {
    title: ' ',
    dataIndex: INVOICE_TYPES_COMMON.actions,
    width: 50,
    align: 'center',
    render: (_, record) => (
      <TableActions
        onEdit={onEdit(record)}
        onDelete={onDeleteType(record.id)}
      />
    ),
  },
];
