import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import MoneyCell from '../MoneyCell';
import TotalCell from '../TotalCell';

const TotalMoneyCell = ({ value }) => (
  <Table.Summary.Cell>
    <TotalCell>
      <MoneyCell value={value} />
    </TotalCell>
  </Table.Summary.Cell>
);

TotalMoneyCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TotalMoneyCell;
