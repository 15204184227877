import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';
import { logout } from 'redux/Auth/actions';
import { get } from 'lodash';
import queryString from 'query-string';
import { store } from '../app';

const serverData = window.SERVER_DATA;
const apiURL = serverData && serverData.API_URL;
const newApiURL = serverData && serverData.API_V2_URL;

export const apiUrl =
  process.env.API_URL ||
  apiURL ||
  'https://yellow-erp-backend-dev.herokuapp.com/api/v1/';

export const apiUrlV2 =
  process.env.API_V2_URL ||
  newApiURL ||
  'https://yellow-erp-backend-dev.herokuapp.com/api/v2/';

const apiClient = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

const apiClientV2 = axios.create({
  baseURL: apiUrlV2,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

const handleApiToken = client =>
  client.interceptors.request.use(
    config => {
      const newConfig = { ...config };
      const accessToken = Cookies.get('accessToken');

      if (!newConfig.headers.authorization && accessToken) {
        newConfig.headers.Authorization = `Bearer ${accessToken}`;
      }

      return newConfig;
    },
    error => Promise.reject(error),
  );

const handleApiAuth = client =>
  client.interceptors.response.use(
    response => response,
    async error => {
      if (!error.response) {
        return Promise.reject(error);
      }

      if (get(error, 'response.status', null) === 401) {
        store.dispatch(logout());
      }

      return Promise.reject(error);
    },
  );

handleApiToken(apiClient);
handleApiToken(apiClientV2);
handleApiAuth(apiClient);
handleApiAuth(apiClientV2);

const API = {
  // NOTIFICATIONS
  notifications: () => apiClientV2.get('/notifications'),
  refreshNotifications: () => apiClientV2.put('/notifications'),
  // AUTH
  login: (username, password) =>
    apiClientV2.post('/auth/login', { username, password }),
  getProfile: () => apiClientV2.get('/auth/user'),

  // USERS
  loadUsers: ({ params }) => apiClientV2.get('/users', { params }),
  getUser: id => apiClientV2.get(`/users/${id}`),
  deleteUser: id => apiClientV2.delete(`/users/${id}`),
  createUser: data =>
    apiClientV2.post(
      `/users`,
      qs.stringify(data, { arrayFormat: 'brackets' }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    ),
  loadPermissions: () => apiClientV2.get('/users/user_permissions'),
  editUser: (id, data) =>
    apiClientV2.put(
      `/users/${id}`,
      qs.stringify(data, { arrayFormat: 'brackets' }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    ),
  restoreUser: id => apiClientV2.put(`/users/${id}/restore`),

  // POSITION
  getEmployeePosition: () => apiClientV2.get('/employee-positions'),

  //CURRENCIES
  getCurrencies: () => apiClient.get(`/currencies`),

  // JIRA
  getJiraUsers: name =>
    apiClientV2.get('/jira/users/search', {
      params: {
        name,
      },
    }),
  getJiraUser: jiraKey => apiClientV2.get(`/jira/users/${jiraKey}`),
  updateJiraKey: (id, jiraKey) =>
    apiClientV2.put(`/employees/${id}/jira_user`, {
      employee_id: id,
      jira_key: jiraKey,
    }),
  deleteJiraKey: id => apiClientV2.delete(`/employees/${id}/jira_user`),
  getJiraProjects: params => apiClientV2.get('/jira/projects', params),
  getJiraRoles: () => apiClientV2.get('/jira/roles'),
  getJiraWorklogsSummary: ({ projectId, date_from, date_to }) =>
    apiClientV2.get(`/jira/projects/${projectId}/worklogs/summary`, {
      params: {
        date_from,
        date_to,
        project_id: projectId,
      },
    }),
  getJiraWorklogs: ({ projectId, date_from, date_to }) =>
    apiClientV2.get(`/jira/projects/${projectId}/worklogs`, {
      params: {
        date_from,
        date_to,
        project_id: projectId,
      },
    }),
  getJiraProjectActors: ({ projectId }) =>
    apiClientV2.get(`jira/projects/${projectId}/actors`, {
      params: {
        project_id: projectId,
      },
    }),
  updateJiraProjects: () => apiClientV2.get('/jira/project_synchronize'),

  // CALENDAR
  getDateByPeriod: (dateFrom, dateTo) =>
    apiClientV2.get('/calendar/search', {
      params: {
        date_from: dateFrom,
        date_to: dateTo,
      },
    }),
  // RESOURCE-PLANNING
  getPlansByType: (startDate, endDate) =>
    apiClientV2.get('/resource-plannings/allocation', {
      params: {
        date_from: startDate,
        date_to: endDate,
      },
    }),
  createPlanByType: data =>
    apiClientV2.post(`/resource-plannings/allocation`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  updatePlanByType: (id, data) =>
    apiClientV2.put(`/resource-plannings/allocation/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  deletePlanByType: id =>
    apiClientV2.delete(`/resource-plannings/allocation/${id}`),
  // RESOURCE-MANAGMENT
  getPlanById: (allocationId, startDate, endDate, expand) =>
    apiClientV2.get(`/tempo-planning/allocation/${allocationId}`, {
      params: {
        allocationId,
        expand,
        startDate,
        endDate,
      },
    }),
  getPlans: (startDate, endDate) =>
    apiClientV2.get(`/tempo-planning/allocation`, {
      params: {
        startDate,
        endDate,
      },
    }),
  createPlan: values => apiClientV2.post('/tempo-planning/allocation', values),
  updatePlanById: (allocationId, values) =>
    apiClientV2.put(`/tempo-planning/allocation/${allocationId}`, values),
  deletePlanById: allocationId =>
    apiClientV2.delete(`/tempo-planning/allocation/${allocationId}`),
  getModalData: ({ date, projectId }) =>
    apiClientV2.get(`/deals/allocations/${projectId}/${date}`),
  getResourceManagementEmployees: params =>
    apiClientV2.get('/resource_management/search', { params }),
  getResourceManagementProjects: () =>
    apiClientV2.get('/resource_management/projects'),

  // ORGANIZATIONS
  deleteOrganizationAccount: ({ organizationId, accountId }) =>
    apiClientV2.delete(
      `/organizations/${organizationId}/accounts/${accountId}`,
    ),
  editOrganizationAccount: ({ organizationId, accountId, data }) =>
    apiClientV2.put(
      `/organizations/${organizationId}/accounts/${accountId}`,
      qs.stringify(data),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    ),
  getOrganizationAccounts: organizationId =>
    apiClientV2.get(`/organizations/${organizationId}/accounts`),
  createOrganizationAccount: ({ organizationId, data }) =>
    apiClientV2.post(
      `/organizations/${organizationId}/accounts`,
      qs.stringify(data),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    ),
  deleteOrganization: organizationId =>
    apiClientV2.delete(`/organizations/${organizationId}`),
  getOrganizationById: organizationId =>
    apiClientV2.get(`/organizations/${organizationId}`),
  editOrganization: ({ organizationId, data }) =>
    apiClientV2.put(`/organizations/${organizationId}`, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  getOrganizationsSearch: params =>
    apiClientV2.get(`/organizations/search`, { params }),
  getOrganizations: () => apiClientV2.get(`/organizations`),
  createOrganization: data =>
    apiClientV2.post(`/organizations`, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),

  // CALENDAR
  getCalendar: params => apiClientV2.get('/holidays', { params }),
  createHoliday: params => apiClientV2.post('/holidays', params),
  updateHoliday: params => apiClientV2.put(`/holidays/${params.id}`, params),
  deleteHoliday: ({ id, ...params }) =>
    apiClientV2.delete(`/holidays/${id}`, { data: params }),

  // EMPLOYEES
  loadEmployees: params =>
    apiClientV2.get('/employees', {
      params: {
        ...params,
      },
    }),
  loadEmployeesV1: ({ filters, sort, ...params }) =>
    apiClient.get('/employees/search', {
      params: {
        ...params,
        filters,
        sort,
      },
    }),
  getEmployeesWithJira: params =>
    apiClientV2.get('/employees/with_jira_user', {
      params,
    }),
  uploadEmployees: file => {
    const formData = new FormData();
    formData.append('file', file);

    return apiClientV2.post('/employees/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getEmployee: ({ employeeId, expand }) =>
    apiClientV2.get(`/employees/${employeeId}`, {
      params: {
        expand,
      },
    }),
  updateEmployee: (id, data) => apiClientV2.put(`/employees/${id}`, data),
  createEmployee: data =>
    apiClientV2.post('/employees', qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  getEmployeeSalaries: id => apiClientV2.get(`/employees/${id}/salaries`),
  addEmployeeSalaries: (id, data) =>
    apiClientV2.post(`/employees/${id}/salaries`, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  getEmployeeExtraPayments: id =>
    apiClientV2.get(`/employees/${id}/extra-payments`),
  getEmployeePaysheets: id => apiClientV2.get(`/employees/${id}/paysheets`),
  addEmployeeExtraPayments: (id, data) =>
    apiClientV2.post(`/employees/${id}/extra-payments`, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  updateEmployeeExtraPayment: ({ id, data, employeeId }) =>
    apiClientV2.put(`/employees/${employeeId}/extra-payments/${id}`, data),
  getEmployeeAttachments: id => apiClientV2.get(`/employees/${id}/attachments`),
  deleteEmployeeAttachment: ({ employee_id, file_name, url }) =>
    apiClientV2.delete(`/employees/${employee_id}/attachments`, {
      params: {
        file_name,
        url,
      },
    }),
  uploadEmployeeAttachmentUrl: (id, fileName) =>
    apiClientV2.post(`/employees/${id}/attachments/upload-url`, {
      file_name: fileName,
      employee_id: id,
    }),
  addEmployeeAttachment: (id, data) =>
    apiClientV2.post(`/employees/${id}/attachments`, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  updateEmployeeBuddies: ({ buddy_ids, employee_id }) =>
    apiClientV2.put(`/employees/${employee_id}/buddies`, { buddy_ids }),
  updateEmployeeIbans: ({ id, ibans }) =>
    apiClientV2.put(`/employees/${id}/ibans`, { ibans }),
  getIbanCurrency: iban_number =>
    apiClientV2.get(`/ibans/currency_type`, { params: { iban_number } }),

  // SUMMARY-EMPLOYEES
  getSummaryEmployees: params =>
    apiClientV2.get(`/summary_employees/search`, {
      params,
    }),
  getSummaryEmployee: ({ params, summaryEmployeeId }) =>
    apiClientV2.get(`/summary_employees/${summaryEmployeeId}`, {
      params,
    }),
  getSummaryEmployeeSalaries: summaryEmployeeId =>
    apiClientV2.get(`/summary_employees/${summaryEmployeeId}/salaries`),
  getSummaryEmployeeComments: summaryEmployeeId =>
    apiClientV2.get(`/summary_employees/${summaryEmployeeId}/comments`),
  getSummaryEmployeeExtraPayments: summaryEmployeeId =>
    apiClientV2.get(`/summary_employees/${summaryEmployeeId}/extra-payments`),

  // RESOURCE-MANAGER
  getResourceManager: id =>
    apiClientV2.get(`/employees/${id}/resource-manager`),
  updateResourceManager: ({ id, resource_manager_id, expand }) =>
    apiClientV2.put(`/employees/${id}/resource_manager`, {
      resource_manager_id,
      expand,
    }),

  // EMPLOYEE-POSITION
  updateEmployeePosition: (id, employeePositionId) =>
    apiClientV2.put(`/employees/${id}/employee-position`, {
      employee_id: id,
      employee_position_id: employeePositionId,
    }),

  // AVERAGE-SALARIES
  getAverageSalaries: year =>
    apiClientV2.get('/average-salaries/search', {
      params: {
        year,
      },
    }),
  addAverageSalary: data =>
    apiClientV2.post('/average-salaries', qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  updateAverageSalary: ({ period, ...data }) =>
    apiClientV2.put(`/average-salaries/${period}`, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  getAverageSalaryForPeriod: period =>
    apiClientV2.get(`/average-salaries/${period}`),

  // EXTRA_PAYMENTS
  loadExtraPayments: params =>
    apiClientV2.get('/extra-payments', {
      params,
    }),
  deleteExtraPayments: id => apiClientV2.delete(`/extra-payments/${id}`),
  updateExtraPayments: (id, data) =>
    apiClientV2.put(`/extra-payments/${id}`, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  approveExtraPayment: id => apiClientV2.put(`/extra-payments/${id}/approve`),
  declineExtraPayment: id => apiClientV2.put(`/extra-payments/${id}/decline`),
  addExtraPayment: ({ params }) =>
    apiClientV2.post('/extra-payments', { ...params }),

  // SALARIES
  deleteSalaries: ({ id, employeeId }) =>
    apiClientV2.delete(`/employees/${employeeId}/salaries/${id}`),
  getSalaries: id => apiClientV2.get(`/salaries/${id}`),
  updateSalaries: (id, data) =>
    apiClientV2.put(`/salaries/${id}`, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),

  // EXCHANGE_RATES
  getExchangeRateForDate: date => apiClientV2.get(`/exchange-rates/${date}`),
  getExchangeRateForMonth: period =>
    apiClientV2.get('/exchange-rates/search', {
      params: {
        period,
      },
    }),
  editExchangeRateForMonth: (date, payload) =>
    apiClientV2.put(`/exchange-rates/${date}`, qs.stringify(payload), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  createExchangeRate: payload =>
    apiClientV2.post('/exchange-rates', {
      ...payload,
    }),
  // TECHNOLOGIES
  getTechnologies: () => apiClientV2.get('/technologies'),
  editTechnology: (id, data) =>
    apiClientV2.put(`/technologies/${id}`, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  deleteTechnology: id => apiClientV2.delete(`/technologies/${id}`),
  addTechnology: data =>
    apiClientV2.post('/technologies', qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  getTechnologyEmployees: id =>
    apiClientV2.get(`/technologies/${id}/summary_employees`),
  getEmployeeTechnologies: id =>
    apiClientV2.get(`/employees/${id}/technologies`),
  updateEmployeeTechnologies: (id, technologyIds) =>
    apiClientV2.post(`/employees/${id}/technologies`, technologyIds),

  // POSITIONS
  getPositions: () => apiClientV2.get('/employee-positions'),
  editPosition: (id, data) =>
    apiClientV2.put(`/employee-positions/${id}`, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  deletePosition: id => apiClientV2.delete(`/employee-positions/${id}`),
  addPosition: data =>
    apiClientV2.post('/employee-positions', qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),

  // INTEGRATIONS
  getExports: (period, token) => {
    const link = document.createElement('a');
    const url = `${apiUrl}/integrations/export?${queryString.stringify({
      period,
      access_token: token,
    })}`;

    link.href = url;
    link.setAttribute('target', '_blank');
    link.click();
  },
  // PAYSHEETS
  loadPaySheets: ({ period, ...params }) =>
    apiClientV2.get(`/paysheets/${period}`, {
      params,
    }),
  updatePaysheetValues: (period, data) =>
    apiClientV2.put(`/paysheets/${period}`, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  loadExhangeRateForDate: date =>
    apiClientV2.get(`/exchange-rates/${date}`).catch(() => null),
  loadAverageSalaryForPeriod: period =>
    apiClientV2.get(`/average-salaries/${period}`).catch(() => null),

  // PROJECT-PROFITS
  getProjectProfits: payload =>
    apiClientV2.get(`/project-profits`, { params: { ...payload } }),
  calculateProjectProfits: period =>
    apiClientV2.put(`/project-profits/calculate`, {
      period,
    }),
  updateProjectProfits: ({ period, projectId, formData }) =>
    apiClientV2.put(`/project-profits/revenue_adjustment`, {
      period,
      project_id: projectId,
      ...formData,
    }),
  getProjectProfitsByYear: ({ year, ...params }) =>
    apiClientV2.get(`project-profits/years/${year}`, {
      params,
    }),
  deleteRevenueProjectProfits: ({ period, projectId }) =>
    apiClientV2.put(`/project-profits/nullify_revenue`, {
      period,
      project_id: projectId,
    }),
  getManagerProfiles: ({ project_profit_id, jira_id, ...params }) =>
    apiClientV2.get(`/project-profits/${project_profit_id}/employees`, {
      params: {
        jira_id,
        ...params,
      },
    }),

  // Dashboard
  getDashboardOverview: payload =>
    apiClientV2.get('/dashboard/overview', { params: payload }),
  getDashboardEffectiveness: payload =>
    apiClientV2.get('/dashboard/effectiveness', { params: payload }),
  getDashboardEmployees: payload =>
    apiClientV2.get('/dashboard/employees', { params: payload }),
  getDashboardEmployeeData: ({ params, employeeId }) =>
    apiClientV2.get(`/dashboard/employees/${employeeId}`, { params }),
  getDashboardSalaryChart: payload =>
    apiClientV2.get('/dashboard/salary_fund/chart_data', { params: payload }),
  getDashboardSalaryTable: payload =>
    apiClientV2.get('/dashboard/salary_fund/employees', { params: payload }),
  getDashboardSubcontractorsTable: payload =>
    apiClientV2.get('/dashboard/subcontractors/table', { params: payload }),
  getDashboardSubcontractorsOverall: payload =>
    apiClientV2.get('/dashboard/subcontractors/overall', { params: payload }),
  //COST-RATES
  getCostRates: ({ period, project_key }) =>
    apiClient.get(`cost-rates/${period}/${project_key}`),
  getAllCostRates: period => apiClient.get(`cost-rates/${period}`),
  //TEAMS
  getEmployeeTeams: () => apiClientV2.get(`/teams`),
  //COMMENTS
  getEmployeeComments: id => apiClientV2.get(`/employees/${id}/comments`),
  addEmployeeComment: ({ currentId, currentComment }) =>
    apiClientV2.post(`/employees/${currentId}/comments`, {
      text: currentComment,
    }),
  deleteEmployeeComment: ({ comment_id, employee_id }) =>
    apiClientV2.delete(`/employees/${employee_id}/comments/${comment_id}`),
  updateEmployeeComment: ({ employee_id, comment_id, text }) =>
    apiClientV2.put(`/employees/${employee_id}/comments/${comment_id}`, {
      text,
    }),

  //AUDIT
  getAuditData: ({ sort, ...params }) =>
    apiClientV2.get(`/audits/search`, {
      params: {
        ...params,
        sort: sort,
      },
    }),

  // CLIENTS
  getClient: id => apiClientV2.get(`/clients/${id}`),
  getClientProjects: id => apiClientV2.get(`/clients/${id}/projects`),
  getClientOrganizations: id => apiClientV2.get(`/clients/${id}/organizations`),
  loadClients: ({ params }) =>
    apiClientV2.get(`/clients/search`, {
      params: {
        ...params,
      },
    }),
  getClientNotes: ({ id, params }) =>
    apiClientV2.get(`clients/${id}/notes`, { params }),
  getClientDeals: ({ id, params }) =>
    apiClientV2.get(`clients/${id}/deals`, { params }),
  createClient: data =>
    apiClientV2.post(`/clients`, qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  updateClient: ({ clientId, values }) =>
    apiClientV2.put(`/clients/${clientId}`, qs.stringify(values), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  getDeals: () =>
    apiClientV2.get(`/deals/search`, {
      params: {
        page: 1,
        size: 10000,
        expand: 'deleted',
      },
    }),
  getClientsFilter: ({ field }) =>
    apiClient.get(`/clients/search/filters/${field}`, { params: { field } }),
  deleteClient: ({ clientId }) => apiClientV2.delete(`/clients/${clientId}/`),
  restoreClient: ({ clientId }) =>
    apiClientV2.put(`/clients/${clientId}/restore`),
  saveNote: ({ clientId, text, ...params }) =>
    apiClientV2.post(`/clients/${clientId}/notes`, { text, ...params }),
  updateNote: ({ clientId, noteId, text, ...params }) =>
    apiClientV2.put(`/clients/${clientId}/notes/${noteId}`, {
      text,
      ...params,
    }),
  deleteNote: ({ clientId, noteId, ...params }) =>
    apiClientV2.delete(`/clients/${clientId}/notes/${noteId}`, { params }),
  addClientOrganization: ({ clientId, organizationId }) =>
    apiClientV2.put(
      `/clients/${clientId}/organizations`,
      qs.stringify(organizationId),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          organization_ids: organizationId,
        },
      },
    ),
  getClientAudit: ({ clientId }) =>
    apiClientV2.get(`/clients/${clientId}/audits`),
  getClientAllocations: ({ clientId }) =>
    apiClientV2.get(`/clients/${clientId}/allocations`, {
      params: {
        expand: ['audits'],
      },
    }),
  addClientInvoce: ({ client_id, payload }) =>
    apiClientV2.post(`/clients/${client_id}/invoices`, JSON.stringify(payload)),
  getClientContracts: id => apiClientV2.get(`/clients/${id}/contracts`),

  //INVOICES
  updateClientInvoice: ({ invoice_id, params }) =>
    apiClientV2.put(
      `/invoices/${invoice_id}`,
      { ...params },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ),

  getInvoiceTemplates: () => apiClientV2.get(`/invoices/templates`),
  deleteClieintInvoice: ({ clientId, invoiceId, ...params }) =>
    apiClientV2.delete(`/invoices/${invoiceId}`, {
      params: { client_id: clientId, ...params },
    }),
  getInvoiceStatuses: () => apiClientV2.get(`/invoices/statuses`),
  getInvoiceAudit: id => apiClientV2.get(`/invoices/${id}/audits`),

  //CLIENT CONTRACTS
  getClientsContracts: ({ ...params }) =>
    apiClientV2.get(`/contracts/search`, {
      params: {
        ...params,
      },
    }),
  createContract: values =>
    apiClientV2.post(
      `/contracts`,
      { ...values },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ),
  deleteContract: id => apiClientV2.delete(`/contracts/${id}`),
  updateContract: ({ id, values }) =>
    apiClientV2.put(
      `/contracts/${id}`,
      { ...values },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ),

  //INVOICES
  getInvoice: ({ invoiceId, clientId, expand }) =>
    apiClientV2.get(`/invoices/${invoiceId}`, {
      params: { client_id: clientId, expand },
    }),
  getInvoices: ({ ...params }) =>
    apiClientV2.get(`/invoices/search`, {
      params: {
        ...params,
      },
    }),
  getClientInvoices: ({ clientId, params }) =>
    apiClientV2.get(`/clients/${clientId}/invoices`, { params }),
  approveInvoice: id => apiClientV2.put(`/invoices/${id}/approve`),
  declineInvoice: id => apiClientV2.put(`/invoices/${id}/decline`),

  //DEALS
  updateDealAllocations: ({ dealId, allocations, ...params }) =>
    apiClientV2.put(
      `/deals/${dealId}/allocations`,
      JSON.stringify(allocations),
      {
        headers: {
          'Content-Type': 'application/json',
        },
        params,
      },
    ),
  updateDeal: ({ values, dealId, ...params }) =>
    apiClientV2.put(`/deals/${dealId}`, qs.stringify(values), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params,
    }),
  createDeal: ({ values, ...params }) =>
    apiClientV2.post(`/deals`, qs.stringify(values), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params,
    }),

  deleteDeal: ({ deal_id, ...params }) =>
    apiClientV2.delete(`/deals/${deal_id}`, { params }),
  getDealAllocations: params =>
    apiClientV2.get(`/deals/allocations`, {
      params,
    }),
  //LEADS
  getLeads: ({ params }) =>
    apiClientV2.get(`/leads/search`, {
      params: {
        ...params,
      },
    }),
  getLeadAudit: ({ id }) => apiClientV2.get(`/leads/${id}/audits`),
  getLeadGeo: ({ id }) => apiClientV2.get(`/leads/${id}/geo`),
  getLeadData: ({ id }) => apiClientV2.get(`/leads/${id}`),
  getLeadNotes: ({ id }) => apiClientV2.get(`/leads/${id}/notes`),
  getLeadActivities: ({ id }) => apiClientV2.get(`/leads/${id}/activities`),
  getLeadAttachments: ({ id }) => apiClientV2.get(`/leads/${id}/attachments`),
  updateLeadActivities: ({ id }) => apiClientV2.put(`/leads/${id}/activities`),
  updateLeadOwner: ({ owner_id, id }) =>
    apiClientV2.put(`/leads/${id}/owner`, { user_id: owner_id }),
  updateLead: ({ status, id, score }) =>
    apiClientV2.put(`/leads/${id}`, { status, score }),
  saveLeadNote: ({ id, text }) =>
    apiClientV2.post(`/leads/${id}/notes`, { text }),
  updateLeadNote: ({ id, noteId, text }) =>
    apiClientV2.put(`/leads/${id}/notes/${noteId}`, { text }),

  // Subcontractors
  getSubcontractors: ({ period, filters, sort }) =>
    apiClientV2.get(`/cost-rates/subcontractors/${period}`, {
      params: { filters, sort },
    }),

  // Incoming invoices
  getIncomingInvoices: ({ period, ...params }) =>
    apiClientV2.get(`/incoming-invoices/${period}`, { params }),
  getIncomingInvoiceAttachments: id =>
    apiClientV2.get(`/incoming-invoices/${id}/attachments`),
  saveIncomingInvoiceAttachments: ({ id, attachments }) =>
    apiClientV2.post(`/incoming-invoices/${id}/attachments`, { attachments }),
  uploadIncomingInvoiceAttachmentUrl: params =>
    apiClientV2.post(`/incoming-invoices/attachments/upload-url`, params),
  createIncomingInvoice: params =>
    apiClientV2.post(`/incoming-invoices`, { ...params }),
  updateIncomingInvoice: ({ id, ...params }) =>
    apiClientV2.put(`/incoming-invoices/${id}`, { ...params }),
  deleteIncomingInvoice: id => apiClientV2.delete(`/incoming-invoices/${id}`),
  approveIncomingInvoiceByFinancier: id =>
    apiClientV2.put(`/incoming-invoices/${id}/financier/approve`),
  declineIncomingInvoiceByFinancier: id =>
    apiClientV2.put(`/incoming-invoices/${id}/financier/decline`),
  approveIncomingInvoiceByApprover: id =>
    apiClientV2.put(`/incoming-invoices/${id}/approver/approve`),
  declineIncomingInvoiceByApprover: id =>
    apiClientV2.put(`/incoming-invoices/${id}/approver/decline`),

  //Invoice types
  getInvoiceTypes: () => apiClientV2.get(`/invoice-types/search`),
  createInvoiceType: name => apiClientV2.post(`/invoice-types`, { name }),
  updateInvoiceType: ({ name, id }) =>
    apiClientV2.put(`/invoice-types/${id}`, { name }),
  deleteInvoiceType: id => apiClientV2.delete(`/invoice-types/${id}`),

  //Banks
  getBanks: () => apiClientV2.get(`/banks`),
  addBank: name => apiClientV2.post(`/banks`, { name }),
  updateBank: ({ name, id }) => apiClientV2.put(`/banks/${id}`, { name }),
  deleteBank: id => apiClientV2.delete(`/banks/${id}`),

  //Currencies
  loadCurrencies: () => apiClientV2.get(`/currencies`),
  addCurrency: name => apiClientV2.post(`/currencies`, { name }),
  updateCurrency: ({ name, id }) =>
    apiClientV2.put(`/currencies/${id}`, { name }),
  deleteCurrency: id => apiClientV2.delete(`/currencies/${id}`),

  //Roles
  getRoles: params => apiClientV2.get('/roles', { params }),
  createRole: role => apiClientV2.post('/roles', role),
  updateRole: ({ role, id }) => apiClientV2.put(`/roles/${id}`, role),
  deleteRole: id => apiClientV2.delete(`/roles/${id}`),
  getPermissions: () => apiClientV2.get('/roles/permissions'),
  getRoleTypes: () => apiClientV2.get('/roles/role_types'),

  //Social requests
  getSocialRequests: params =>
    apiClientV2.get('/social_requests/search', { params }),
  getSocialRequestsInfo: filters =>
    apiClientV2.get('/social_requests/social_request_info', {
      params: filters,
    }),
  getSocialRequestTypes: () => apiClientV2.get('/social_requests/types'),
  getSocialRequestStatuses: () => apiClientV2.get('/social_requests/statuses'),
  createSocialRequest: params => apiClientV2.post('/social_requests', params),
  updateSocialRequest: ({ id, ...params }) =>
    apiClientV2.put(`/social_requests/${id}`, params),
  deleteSocialRequest: ({ id }) => apiClientV2.post(`/social_requests/${id}`),
  approveSocialRequest: ({ id, employee_id, ...params }) =>
    apiClientV2.put(`/social_requests/${id}/approve`, {
      employee_id,
      ...params,
    }),
  declineSocialRequest: ({ id, ...params }) =>
    apiClientV2.put(`/social_requests/${id}/decline`, { ...params }),
  uploadSocialRequestAttachment: ({ id, payload }) =>
    apiClientV2.post(`/social_requests/${id}/attachments`, payload),
  uploadSocialRequestAttachmentUrl: ({ id, file_name }) =>
    apiClientV2.post(`/social_requests/${id}/attachments/upload-url`, {
      file_name,
    }),

  getSummarySocialRequests: ({ summary_employee_id, ...params }) =>
    apiClientV2.get(
      `/summary_employees/${summary_employee_id}/social_requests`,
      { params },
    ),
  createSummarySocialRequest: ({ summary_employee_id, ...params }) =>
    apiClientV2.post(
      `/summary_employees/${summary_employee_id}/social_requests`,
      params,
    ),
  updateSummarySocialRequest: ({
    summary_employee_id,
    social_request_id,
    ...params
  }) =>
    apiClientV2.put(
      `/summary_employees/${summary_employee_id}/social_requests/${social_request_id}`,
      params,
    ),
  deleteSummarySocialRequest: ({ summary_employee_id, social_request_id }) =>
    apiClientV2.delete(
      `/summary_employees/${summary_employee_id}/social_requests/${social_request_id}`,
    ),
  getSummarySocialRequestsInfo: ({ summary_employee_id }) =>
    apiClientV2.get(
      `/summary_employees/${summary_employee_id}/social_request_info`,
    ),
  getSummaryEmployeeProfiles: ({ employee_id }) =>
    apiClientV2.get(`/summary_employees/by_employee_id/${employee_id}`),

  //AWS
  uploadAttachmentToAWS: (signed_url, file) =>
    axios.put(signed_url, file, {
      headers: {
        'Content-Type': file.type,
      },
    }),
};

export default API;
