import { takeLatest, call, put } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';

import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';
import { types } from './types';

function* getInvoices({ payload }) {
  try {
    const { data, headers } = yield call(API.getInvoices, {
      ...payload,
      expand: ['attachments'],
    });
    yield put({
      type: types.GET_INVOICES_SUCCESS,
      payload: { data, headers },
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_INVOICES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* approveInvoice({ payload: { id, filters, page, size, sort } }) {
  try {
    yield call(API.approveInvoice, id);
    yield call(getInvoices, { payload: { filters, page, size, sort } });
    message.success(SAGA_MESSAGES.APPROVE_INVOICE);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.APPROVE_INVOICE_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* declineInvoice({ payload: { id, filters, page, size, sort } }) {
  try {
    yield call(API.declineInvoice, id);
    yield call(getInvoices, { payload: { filters, page, size, sort } });
    message.success(SAGA_MESSAGES.DECLINE_INVOICE);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DECLINE_INVOICE_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* updateInvoice({
  payload: { id, filters, values, page, size, sort },
}) {
  try {
    yield call(API.updateClientInvoice, { invoice_id: id, params: values });
    yield call(getInvoices, { payload: { filters, page, size, sort } });
    yield put({ type: types.UPDATE_INVOICE_SUCCESS });
    message.success(SAGA_MESSAGES.UPDATE_INVOICE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_INVOICE_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getInvoiceStatuses() {
  try {
    const { data } = yield call(API.getInvoiceStatuses);

    yield put({ type: types.GET_INVOICE_STATUSES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_INVOICE_STATUSES_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* deleteClientInvoice({ payload: { clientId, invoiceId } }) {
  try {
    yield call(API.deleteClieintInvoice, { clientId, invoiceId });
    yield put({ type: types.DELETE_INVOICE_SUCCESS });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_INVOICE_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getInvoiceTemplates() {
  try {
    const { data } = yield call(API.getInvoiceTemplates);

    yield put({
      type: types.GET_INVOICE_TEMPLATES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_INVOICE_TEMPLATES_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getInvoiceAudit({ payload: id }) {
  try {
    const { data } = yield call(API.getInvoiceAudit, id);
    yield put({
      type: types.GET_INVOICE_AUDIT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_INVOICE_AUDIT_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_INVOICES, getInvoices);
  yield takeLatest(types.APPROVE_INVOICE, approveInvoice);
  yield takeLatest(types.DECLINE_INVOICE, declineInvoice);
  yield takeLatest(types.UPDATE_INVOICE, updateInvoice);
  yield takeLatest(types.GET_INVOICE_STATUSES, getInvoiceStatuses);
  yield takeLatest(types.DELETE_INVOICE, deleteClientInvoice);
  yield takeLatest(types.GET_INVOICE_TEMPLATES, getInvoiceTemplates);
  yield takeLatest(types.GET_INVOICE_AUDIT, getInvoiceAudit);
}
