import React from 'react';
import PropTypes from 'prop-types';
import currencyFormatter from 'currency-formatter';
import dayjs from 'dayjs';

import Timeline from 'components/Common/Timeline';
import TimelineItem from 'components/Common/TimelineItem';
import { DATE_MONTH_FORMAT } from 'utils/timeConstants';
import { CURRENCY_CODES } from 'utils/constants';

import styles from './styles.scss';

const Salaries = ({ salaries }) => (
  <div className={styles.salaries}>
    <h3 className={styles.title}>Salary</h3>
    <Timeline className={styles.timeline} highlightFirst>
      {salaries &&
        salaries.map(
          (
            {
              id,
              fixed_salary_byn,
              valid_from,
              fixed_salary_usd,
              hourly_rate_usd,
            },
            index,
          ) => (
            <TimelineItem key={id || `${index}_${valid_from}`}>
              <div className={styles.field}>
                {fixed_salary_byn !== null && (
                  <span className={styles.salary}>
                    {currencyFormatter.format(fixed_salary_byn, {
                      code: CURRENCY_CODES.BYR,
                    })}
                  </span>
                )}
                {fixed_salary_usd !== null && (
                  <span className={styles.salary}>
                    {currencyFormatter.format(fixed_salary_usd, {
                      code: CURRENCY_CODES.USD,
                    })}
                  </span>
                )}
                {hourly_rate_usd !== null && (
                  <span className={styles.salary}>
                    {currencyFormatter.format(hourly_rate_usd, {
                      code: CURRENCY_CODES.USD,
                    })}
                    /h{' '}
                    {`(${currencyFormatter.format(168 * hourly_rate_usd, {
                      code: CURRENCY_CODES.USD,
                    })})`}
                  </span>
                )}
                <span className={styles.date}>
                  {dayjs(valid_from).format(DATE_MONTH_FORMAT)}
                </span>
              </div>
            </TimelineItem>
          ),
        )}
    </Timeline>
  </div>
);

Salaries.propTypes = {
  salaries: PropTypes.array,
};

export default Salaries;
