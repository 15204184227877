import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography } from 'antd';
import { getColumns, getPeriodFilters } from './utils';

import styles from './styles.scss';

const { Title } = Typography;

const DashboardSubcontractorsTable = ({
  tableData,
  filters,
  filterInfo,
  onTableChange,
}) => {
  const periodFilters = useMemo(() => getPeriodFilters(tableData), [tableData]);

  const columns = useMemo(
    () => getColumns({ periodFilters, filters, filterInfo }),
    [tableData, filters, filterInfo],
  );

  const getRowKey = record => {
    const { name, project, period } = record;

    return `${name}_${project}_${period}`;
  };

  return (
    <div className={styles.dashboardSubcontractorsTable}>
      <Title level={5}>Employees</Title>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{ defaultPageSize: 20 }}
        onChange={onTableChange}
        rowKey={getRowKey}
      />
    </div>
  );
};

DashboardSubcontractorsTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  filterInfo: PropTypes.object.isRequired,
  onTableChange: PropTypes.func.isRequired,
};

export default DashboardSubcontractorsTable;
