exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".summaryPage-48c7e{margin:0 auto}.summaryPage-48c7e .content-e7bea{display:flex;justify-content:space-between;gap:24px}.summaryPage-48c7e .breadcrumbs-e5ed3{margin-bottom:24px;font-size:14px;line-height:22px}.summaryPage-48c7e .tabs-a07d4{background-color:#fff;box-shadow:0 0 3px #ccc;border-radius:4px;width:calc(100% - 364px);background:rgba(0,0,0,0);box-shadow:none}.summaryPage-48c7e .summaryTabs-b12d3{position:relative;display:flex;align-self:stretch;margin-bottom:24px;overflow:hidden;border-radius:12px;white-space:nowrap;transform:translate(0);background-color:#fff}.summaryPage-48c7e .summaryTabs-b12d3 .tabBar-0111a{position:relative;display:flex;overflow-y:scroll;transition:transform .3s;-ms-overflow-style:none;scrollbar-width:none}.summaryPage-48c7e .summaryTabs-b12d3 ::-webkit-scrollbar{display:none}.summaryPage-48c7e .summaryTabs-b12d3 .tab-bb7c7{padding:12px 24px;font-size:16px;line-height:24px;cursor:pointer;border-radius:12px}.summaryPage-48c7e .summaryTabs-b12d3 .tab-bb7c7:hover{color:#1677ff}.summaryPage-48c7e .summaryTabs-b12d3 .tabActive-075fb{color:#fff;background-color:#1677ff}.summaryPage-48c7e .summaryTabs-b12d3 .tabActive-075fb:hover{color:#fff}.summaryPage-48c7e .summaryTabs-b12d3 .inactiveProfile-1bd3e{color:#979797}.summaryPage-48c7e .profileMain-0f338{width:100%}", ""]);

// Exports
exports.locals = {
	"summaryPage": "summaryPage-48c7e",
	"content": "content-e7bea",
	"breadcrumbs": "breadcrumbs-e5ed3",
	"tabs": "tabs-a07d4",
	"summaryTabs": "summaryTabs-b12d3",
	"tabBar": "tabBar-0111a",
	"tab": "tab-bb7c7",
	"tabActive": "tabActive-075fb",
	"inactiveProfile": "inactiveProfile-1bd3e",
	"profileMain": "profileMain-0f338"
};