exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".detailsForm-bffaf{width:450px;overflow-y:scroll}.detailsForm-bffaf .detailsInput-79dc4{width:100%}.detailsForm-bffaf .ant-form-item-label{padding:6px 0 0 0;line-height:1.2}.detailsForm-bffaf .ant-form-item-with-help{margin-bottom:24px}.detailsForm-bffaf .ant-form-item{margin-bottom:0}.detailsForm-bffaf .customerDetails-d5de7{resize:none}.detailsForm-bffaf .formItem-48eee{margin-bottom:12px}", ""]);

// Exports
exports.locals = {
	"detailsForm": "detailsForm-bffaf",
	"detailsInput": "detailsInput-79dc4",
	"customerDetails": "customerDetails-d5de7",
	"formItem": "formItem-48eee"
};