export const types = {
  GET_TECHNOLOGIES: 'technologies/GET_TECHNOLOGIES',
  GET_TECHNOLOGIES_SUCCESS: 'technologies/GET_TECHNOLOGIES_SUCCESS',
  GET_TECHNOLOGIES_FAILED: 'technologies/GET_TECHNOLOGIES_FAILED',

  UPDATE_TECHNOLOGY: 'technologies/UPDATE_TECHNOLOGY',
  UPDATE_TECHNOLOGY_SUCCESS: 'technologies/UPDATE_TECHNOLOGY_SUCCESS',
  UPDATE_TECHNOLOGY_FAILED: 'technologies/UPDATE_TECHNOLOGY_FAILED',

  DELETE_TECHNOLOGY: 'technologies/DELETE_TECHNOLOGY',
  DELETE_TECHNOLOGY_SUCCESS: 'technologies/DELETE_TECHNOLOGY',
  DELETE_TECHNOLOGY_FAILED: 'technologies/DELETE_TECHNOLOGY_FAILED',

  ADD_TECHNOLOGY: 'technologies/ADD_TECHNOLOGY',
  ADD_TECHNOLOGY_SUCCESS: 'technologies/ADD_TECHNOLOGY_SUCCESS',
  ADD_TECHNOLOGY_FAILED: 'technologies/ADD_TECHNOLOGY_FAILED',

  DELETE_TECHNOLOGY_ERRORS: 'technologies/DELETE_TECHNOLOGY_ERRORS',
};
