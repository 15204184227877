import { takeLatest, call, put } from 'redux-saga/effects';

import { getModifiedFilters } from 'containers/LeadsPage/utils';

import API from 'utils/api';

import { FILTER_STRING } from 'containers/LeadsPage/constants';
import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';
import { message } from 'antd';
import { types } from './types';

function* getLeads({ payload: { page, size, sort, filters } }) {
  try {
    const modifiedFilters = getModifiedFilters({
      filters,
      FILTER_STRING,
    });
    const {
      data: { data, data_for_filters, total_data },
    } = yield call(API.getLeads, {
      params: { page, size, sort, filters: modifiedFilters },
    });
    yield put({
      type: types.GET_LEADS_DATA_SUCCESS,
      payload: { data, data_for_filters, total_data },
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_LEADS_DATA_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getLeadData({ payload: { id } }) {
  try {
    const { data } = yield call(API.getLeadData, { id });

    yield put({
      type: types.GET_LEAD_DATA_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_LEAD_DATA_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getLeadActivities({ payload: { id } }) {
  try {
    const { data } = yield call(API.getLeadActivities, { id });

    yield put({
      type: types.GET_LEAD_ACTIVITIES_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_LEAD_ACTIVITIES_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getLeadAttachments({ payload: { id } }) {
  try {
    const { data } = yield call(API.getLeadAttachments, { id });

    yield put({
      type: types.GET_LEAD_ATTACHMENTS_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_LEAD_ATTACHMENTS_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* udpdateLeadActivities({ payload: { id } }) {
  try {
    yield call(API.updateLeadActivities, { id });
    yield call(getLeadData, { payload: { id } });
    yield put({
      type: types.UPDATE_LEAD_ACTIVITIES_SUCCESS,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_LEAD_ACTIVITIES_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getLeadNotes({ payload: { id } }) {
  try {
    const { data } = yield call(API.getLeadNotes, { id });

    yield put({ type: types.GET_LEAD_NOTES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_LEAD_NOTES_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* saveLeadNote({ payload: { id, text, leadId } }) {
  try {
    yield call(API.saveLeadNote, { id, text });
    yield call(getLeadAudit, { payload: { id: leadId } });
    yield put({
      type: types.SAVE_LEAD_NOTE_SUCCESS,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.SAVE_LEAD_NOTE_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* updateLeadNote({ payload: { id, text, noteId } }) {
  try {
    yield call(API.updateLeadNote, { id, text, noteId });
    yield call(getLeadAudit, { payload: { id } });
    yield put({
      type: types.UPDATE_LEAD_NOTE_SUCCESS,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_LEAD_NOTE_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getLeadAudit({ payload: { id } }) {
  try {
    const { data } = yield call(API.getLeadAudit, { id });
    yield put({
      type: types.GET_LEAD_AUDIT_SUCCESS,
      payload: [...data].reverse(),
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_LEAD_AUDIT_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getLeadGeo({ payload: { id } }) {
  try {
    const { data } = yield call(API.getLeadGeo, { id });
    yield put({
      type: types.GET_LEAD_GEO_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_LEAD_GEO_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* updateLead({ payload: { owner_id, score, status, id } }) {
  try {
    if (status || score) {
      yield call(API.updateLead, { id, status, score });
    }

    if (owner_id) {
      yield call(API.updateLeadOwner, { id, owner_id });
    }

    yield call(getLeadData, { payload: { id } });

    yield put({ type: types.UPDATE_LEAD_SUCCESS });

    message.success(SAGA_MESSAGES.UPDATE_LEAD_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_LEAD_FAILED,
      SAGA_MESSAGES.UPDATE_LEAD_FAILED,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_LEADS, getLeads);
  yield takeLatest(types.GET_LEAD_DATA, getLeadData);
  yield takeLatest(types.GET_LEAD_ACTIVITIES, getLeadActivities);
  yield takeLatest(types.GET_LEAD_ATTACHMENTS, getLeadAttachments);
  yield takeLatest(types.UPDATE_LEAD_ACTIVITIES, udpdateLeadActivities);
  yield takeLatest(types.SAVE_LEAD_NOTE, saveLeadNote);
  yield takeLatest(types.GET_LEAD_AUDIT, getLeadAudit);
  yield takeLatest(types.UPDATE_LEAD_NOTE, updateLeadNote);
  yield takeLatest(types.GET_LEAD_GEO, getLeadGeo);
  yield takeLatest(types.GET_LEAD_NOTES, getLeadNotes);
  yield takeLatest(types.UPDATE_LEAD, updateLead);
}
