import { ROLE_TYPES } from 'utils/permissions';

export const types = {
  GET_CLIENT: 'client/GET_CLIENT',
  GET_CLIENT_SUCCESS: 'client/GET_CLIENT_SUCCESS',
  GET_CLIENT_FAILED: 'client/GET_CLIENT_FAILED',
  GET_PROJECTS: 'client/GET_PROJECTS',
  GET_PROJECTS_SUCCESS: 'client/GET_PROJECTS_SUCCESS',
  GET_PROJECTS_FAILED: 'client/GET_PROJECTS_FAILED',
  GET_ORGANIZATIONS: 'client/GET_ORGANIZATIONS',
  GET_ORGANIZATIONS_SUCCESS: 'client/GET_ORGANIZATIONS_SUCCESS',
  GET_ORGANIZATIONS_FAILED: 'client/GET_ORGANIZATIONS_FAILED',
  UPDATE_CLIENT_INFO: 'client/UPDATE_CLIENT_INFO',
  UPDATE_CLIENT_INFO_SUCCESS: 'client/UPDATE_CLIENT_INFO_SUCCESS',
  UPDATE_CLIENT_INFO_FAILED: 'client/UPDATE_CLIENT_INFO_FAILED',
  SAVE_DEAL: 'client/SAVE_DEAL',
  SAVE_DEAL_SUCCESS: 'client/SAVE_DEAL_SUCCESS',
  SAVE_DEAL_FAILED: 'client/SAVE_DEAL_FAILED',
  GET_DEALS: 'client/GET_DEALS',
  GET_DEALS_SUCCESS: 'client/GET_DEALS_SUCCESS',
  GET_DEALS_FAILED: 'client/GET_DEALS_FAILED',
  UPDATE_DEAL: 'client/UPDATE_DEAL',
  UPDATE_DEAL_SUCCESS: 'client/UPDATE_DEAL_SUCCESS',
  UPDATE_DEAL_FAILED: 'client/UPDATE_DEAL_FAILED',
  DELETE_DEAL: 'client/DELETE_DEAL',
  DELETE_DEAL_FAILED: 'client/DELETE_DEAL_FAILED',
  DELETE_DEAL_SUCEES: 'client/DELETE_DEAL_SUCEES',
  SAVE_NOTE: 'client/SAVE_NOTE',
  SAVE_NOTE_FAILED: 'client/SAVE_NOTE_FAILED',
  SAVE_NOTE_SUCCESS: 'client/SAVE_NOTE_SUCCESS',
  UPDATE_NOTE: 'client/UPDATE_NOTE',
  UPDATE_NOTE_FAILED: 'client/UPDATE_NOTE_FAILED',
  UPDATE_NOTE_SUCCESS: 'client/UPDATE_NOTE_SUCCESS',
  DELETE_NOTE: 'client/DELETE_NOTE',
  DELETE_NOTE_SUCCESS: 'client/DELETE_NOTE_SUCCESS',
  DELETE_NOTE_FAILED: 'client/DELETE_NOTE_FAILED',
  UPDATE_DEAL_ALLOCATIONS: 'client/UPDATE_DEAL_ALLOCATIONS',
  UPDATE_DEAL_ALLOCATIONS_SUCCESS: 'client/UPDATE_DEAL_ALLOCATIONS_SUCCESS',
  UPDATE_DEAL_ALLOCATIONS_FAILED: 'client/UPDATE_DEAL_ALLOCATIONS_FAILED',
  ADD_CLIENT_ORGANIZATION: 'client/ADD_CLIENT_ORGANIZATION',
  ADD_CLIENT_ORGANIZATION_SUCCESS: 'client/ADD_CLIENT_ORGANIZATION_SUCCESS',
  ADD_CLIENT_ORGANIZATION_FAILED: 'client/ADD_CLIENT_ORGANIZATION_FAILED',
  GET_CLIENT_AUDIT: 'client/GET_CLIENT_AUDIT',
  GET_CLIENT_AUDIT_SUCCESS: 'client/GET_CLIENT_AUDIT_SUCCESS',
  GET_CLIENT_AUDIT_FAILED: 'client/GET_CLIENT_AUDIT_FAILED',
  GET_CLIENT_ALLOCATIONS: 'client/GET_CLIENT_ALLOCATIONS',
  GET_CLIENT_ALLOCATIONS_FAILED: 'client/GET_CLIENT_ALLOCATIONS_FAILED',
  GET_CLIENT_ALLOCATIONS_SUCCESS: 'client/GET_CLIENT_ALLOCATIONS_SUCCESS',
  GET_CLIENT_ORGANIZATIONS: 'client/GET_CLIENT_ORGANIZATIONS',
  GET_CLIENT_ORGANIZATIONS_FAILED: 'client/GET_CLIENT_ORGANIZATIONS_FAILED',
  GET_CLIENT_ORGANIZATIONS_SUCCESS: 'client/GET_CLIENT_ORGANIZATIONS_SUCCESS',
  ADD_CLIENT_INVOICE: 'client/ADD_CLIENT_INVOICE',
  ADD_CLIENT_INVOICE_SUCCESS: 'client/ADD_CLIENT_INVOICE_SUCCESS',
  ADD_CLIENT_INVOICE_FAILED: 'client/ADD_CLIENT_INVOICE_FAILED',
  CLEAR_RESPONSE_STATUS: 'client/CLEAR_RESPONSE_STATUS',
  UPDATE_INVOICE: 'client/UPDATE_INVOICE',
  UPDATE_INVOICE_SUCCESS: 'client/UPDATE_INVOICE_SUCCESS',
  UPDATE_INVOICE_FAILED: 'client/UPDATE_INVOICE_FAILED',
  GET_ORGANIZATION_ACCOUNTS: 'client/GET_ORGANIZATION_ACCOUNTS',
  GET_ORGANIZATION_ACCOUNTS_SUCCESS: 'client/GET_ORGANIZATION_ACCOUNTS_SUCCESS',
  GET_ORGANIZATION_ACCOUNTS_FAILED: 'client/GET_ORGANIZATION_ACCOUNTS_FAILED',
  GET_CLIENT_INVOICES: 'client/GET_CLIENT_INVOICES',
  GET_CLIENT_INVOICES_SUCCESS: 'client/GET_CLIENT_INVOICES_SUCCESS',
  GET_CLIENT_INVOICES_FAILED: 'client/GET_CLIENT_INVOICES_FAILED',
  GET_INVOICE_CALENDAR: 'client/GET_INVOICE_CALENDAR',
  GET_INVOICE_CALENDAR_SUCCESS: 'client/GET_INVOICE_CALENDAR_SUCCESS',
  GET_INVOICE_CALENDAR_FAILED: 'client/GET_INVOICE_CALENDAR_FAILED',
  DELETE_CLIENT_INVOICE: 'client/DELETE_CLIENT_INVOICE',
  DELETE_CLIENT_INVOICE_FAILED: 'client/DELETE_CLIENT_INVOICE_FAILED',
  DELETE_CLIENT_INVOICE_SUCCESS: 'client/DELETE_CLIENT_INVOICE_SUCEES',
  GET_CLIENT_CONTRACTS: 'client/GET_CLIENTS_CONTRACTS',
  GET_CLIENT_CONTRACTS_SUCCESS: 'client/GET_CLIENTS_CONTRACTS_SUCCESS',
  GET_CLIENT_CONTRACTS_FAILED: 'client/GET_CLIENTS_CONTRACTS_FAILED',
  UPDATE_CONTRACT: 'client/UPDATE_CONTRACT',
  UPDATE_CONTRACT_SUCCESS: 'client/UPDATE_CONTRACT_SUCCESS',
  UPDATE_CONTRACT_FAILED: 'client/UPDATE_CONTRACT_FAILED',
  GET_INVOICE_TEMPLATES: 'client/GET_INVOICE_TEMPLATES',
  GET_INVOICE_TEMPLATES_SUCCESS: 'client/GET_INVOICE_TEMPLATES_SUCCESS',
  GET_INVOICE_TEMPLATES_FAILED: 'client/GET_INVOICE_TEMPLATES_FAILED',
  GET_PROJECT_WORKLOG: 'client/GET_PROJECT_WORKLOG',
  GET_PROJECT_WORKLOG_SUCCESS: 'client/GET_PROJECT_WORKLOG_SUCCESS',
  GET_PROJECT_WORKLOG_FAILED: 'client/GET_PROJECT_WORKLOG_FAILED',
  GET_CLIENT_PROJECTS: 'client/GET_CLIENT_PROJECTS',
  GET_CLIENT_PROJECTS_SUCCESS: 'client/GET_CLIENT_PROJECTS_SUCCESS',
  GET_CLIENT_PROJECTS_FAILED: 'client/GET_CLIENT_PROJECTS_FAILED',
  GET_INVOICE: 'client/GET_INVOICE',
  GET_INVOICE_SUCCESS: 'client/GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILED: 'client/GET_INVOICE_FAILED',

  GET_ALLOCATIONS_STEP_DATA: 'client/GET_ALLOCATIONS_STEP_DATA',
  GET_ALLOCATIONS_STEP_DATA_SUCCESS: 'client/GET_ALLOCATIONS_STEP_DATA_SUCCESS',
  GET_ALLOCATIONS_STEP_DATA_FAILED: 'client/GET_ALLOCATIONS_STEP_DATA_FAILED',

  SET_INVOICE_DATA: 'client/SET_INVOICE_DATA',
};

export const INVOICE_UPDATE_ACIONS = {
  overview: 'overview',
  details: 'details',
  items: 'items',
  allocations: 'allocations',
};

export const DEFAULT_EDIT_MODAL_STATE_TOUCHED = {
  worklogs: false,
  details: false,
  items: false,
  allocations: false,
};

export const LOAD_INVOICES_PARAMS = { page: 1, size: 10000 };

export const INVOICES_MODAL_TAB = {
  information: 'Information',
  workflow: 'Workflow',
};

export const INVOICE_UPDATE_MESSAGES = {
  WORKLOGS: 'Worklogs confirmed successfully',
  DETAILS: 'Details confirmed successfully',
  FILE: 'File generated successfully',
  ALLOCATIONS: 'Allocations confirmed successfully',
  ACCESS_LOCKED_TITLE: 'You probably have invoice files opened.',
  ACCESS_LOCKED_CONTENT: 'Close them to edit current invoice.',
};

export const ACCESS_LOCKED_CODE = 423;

export const CLIENT_PERMISSIONS = {
  generalInfo: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
    ROLE_TYPES.dm,
  ],
  updateGeneralInfo: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
    ROLE_TYPES.dm,
  ],
  clientOrganizations: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
  ],
  clientContracts: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
  ],
  createDeal: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.pm,
    ROLE_TYPES.bdm,
    ROLE_TYPES.dm,
  ],
  updateDeal: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.pm,
    ROLE_TYPES.bdm,
    ROLE_TYPES.dm,
  ],
  deleteDeal: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  createInvoice: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
    ROLE_TYPES.dm,
  ],
  deleteInvoice: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  statusAllocations: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
    ROLE_TYPES.dm,
    ROLE_TYPES.pm,
  ],
  statusWorklogs: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
    ROLE_TYPES.dm,
  ],
  statusWorklogsReady: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
    ROLE_TYPES.dm,
  ],
  statusClientRejected: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
    ROLE_TYPES.dm,
  ],
  statusInvoiceReady: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  statusApprovedDeclined: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  statusSent: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  statusPaid: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  confirmWorklogs: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
    ROLE_TYPES.dm,
  ],
  confirmAllocations: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
    ROLE_TYPES.dm,
  ],
  confirmDetails: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  confirmItems: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  projects: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.dm,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
  ],
  updateProjects: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.dm,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
  ],
};

export const EXPAND_AUDITS = 'audits';

export const EXPAND_INVOICE = [
  EXPAND_AUDITS,
  'client_totals',
  'allocations',
  'worklogs',
  'original_worklogs',
  'items',
  'original_items',
  'contract',
  'customer_account',
  'contractor',
  'customer',
  'attachments',
];
