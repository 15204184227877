import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import { CURRENCIES_COMMON } from 'containers/CurrenciesPage/constants';
import Modal from 'components/Common/Modal';
import Input from 'components/Common/Input';

const { Item } = Form;

const CurrenciesModal = ({
  isVisible,
  currentCurrency,
  toggleModal,
  onUpdateCurrency,
  onCreateCurrency,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentCurrency) {
      form.setFieldsValue(currentCurrency);
    }

    return () => form.resetFields();
  }, [currentCurrency]);

  const okText = currentCurrency ? 'Update' : 'Add';

  const titleText = currentCurrency ? 'Update currency' : 'Create currency';

  const onSubmit = () => {
    const name = form.getFieldValue(CURRENCIES_COMMON.name);

    if (currentCurrency) {
      onUpdateCurrency({ name, id: currentCurrency.id });
      form.resetFields();

      return;
    }

    onCreateCurrency(name);
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title={titleText}
      okText={okText}
      onCancel={toggleModal(false)}
      onOk={form.submit}
    >
      <Form form={form} onFinish={onSubmit}>
        <Item
          name={CURRENCIES_COMMON.name}
          label={CURRENCIES_COMMON.name_title}
          rules={[
            {
              required: true,
              message: 'Please enter currency name',
            },
          ]}
        >
          <Input />
        </Item>
      </Form>
    </Modal>
  );
};

CurrenciesModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  currentCurrency: PropTypes.object,
  toggleModal: PropTypes.func.isRequired,
  onUpdateCurrency: PropTypes.func.isRequired,
  onCreateCurrency: PropTypes.func.isRequired,
};

export default CurrenciesModal;
