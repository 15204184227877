import React, { createRef, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import dayjs from 'dayjs';

import PageHeader from 'components/Common/PageHeader';
import SocialRequestsApproveModal from 'components/SocialRequestsCommon/SocialRequestsApproveModal';
import SocialRequestsTotal from 'components/SocialRequestsCommon/SocialRequestsTotal';
import RangePicker from 'components/Common/RangePicker';
import SocialRequestAttachmentModal from 'components/SocialRequestsCommon/SocialRequestAttachmentModal';
import { Loader, Table } from 'components/Common';
import {
  approveSocialRequest,
  declineSocialRequest,
  getSocialRequests,
  getSocialRequestsInfo,
} from 'redux/SocialRequests/actions';
import { SORT_DIRECTIONS } from 'utils/constants';
import { getFiltersWithOutNull } from 'utils/common';
import { getFirstDayOfMonth, getLastDayOfMonth } from 'utils/helpers';
import {
  BEGIN_MONTH_FORMAT,
  DATE_FORMAT,
  MONTH_FORMAT,
} from 'utils/timeConstants';
import { authSelector, socialRequestsSelector } from 'redux/selectors';
import { useRoleContext } from 'utils/hooks';
import { hasRights } from 'utils/permissions';

import { getColumns } from './utils';
import { REQUESTS_COMMON, REQUESTS_PERMISSIONS } from './constants';
import styles from './styles.scss';

const SocialRequestsPage = () => {
  const [period, setPeriod] = useState([dayjs(new Date()), dayjs(new Date())]);
  const [sortInfo, setSortInfo] = useState({
    field: REQUESTS_COMMON.sent_at,
    order: SORT_DIRECTIONS.descend,
  });
  const [filterInfo, setFilterInfo] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const [approveProfile, setApproveProfile] = useState(null);
  const [approveModalData, setApproveModalData] = useState(null);
  const [doc, setDoc] = useState(null);
  const [searchText, setSearchText] = useState(null);

  const searchInput = createRef();

  const role = useRoleContext();

  const REQ_PARAMS = {
    filters: {
      ...filterInfo,
      from: dayjs(period[0]).format(BEGIN_MONTH_FORMAT),
      to: dayjs(period[1])
        .clone()
        .endOf('M')
        .format(DATE_FORMAT),
    },
    sort: `${sortInfo.field}:${sortInfo.order}`,
    page: pagination.current,
    size: pagination.pageSize,
    expand: ['with_employees'],
  };

  const {
    socialRequests,
    socialRequestsFilters,
    socialRequestsInfo,
    isSocialRequestsLoading,
    socialRequestsTotal,
  } = useSelector(socialRequestsSelector);

  const { user } = useSelector(authSelector);

  const dispatch = useDispatch();

  const onTableChange = (pagination, filters, sort) => {
    const filterWithoutNull = getFiltersWithOutNull(filters);
    setFilterInfo({ ...filterWithoutNull });

    if (REQUESTS_COMMON.full_name in filterWithoutNull) {
      filterWithoutNull.full_name = filterWithoutNull.full_name[0];
    }

    setSortInfo(sort);
    setPagination(pagination);

    dispatch(
      getSocialRequests({
        ...REQ_PARAMS,
        filters: {
          ...filterWithoutNull,
          from: dayjs(period[0]).format(BEGIN_MONTH_FORMAT),
          to: dayjs(period[1])
            .clone()
            .endOf('M')
            .format(DATE_FORMAT),
        },
        sort: `${sort.field}:${sort.order}`,
        page: pagination.current,
        size: pagination.pageSize,
      }),
    );
  };

  const onPeriodChange = period => {
    setPeriod([getFirstDayOfMonth(period[0]), getLastDayOfMonth(period[1])]);
  };

  const onOpenApproveModal = record => {
    if (record.summary_employee?.employee_ids.length === 1) {
      setApproveProfile(record.summary_employee.employee_ids[0]);
    }

    setApproveModalData(record);
  };

  const onHideApproveModal = () => {
    setApproveModalData(null);
    setApproveProfile(null);
  };

  const onApprove = () => {
    dispatch(
      approveSocialRequest({
        id: approveModalData.id,
        employee_id: approveProfile,
        from: period[0].format(BEGIN_MONTH_FORMAT),
        to: period[1].format(DATE_FORMAT),
        role,
      }),
    );
    onHideApproveModal();
  };

  const onDecline = ({ id }) => {
    Modal.confirm({
      title: 'Decline social request',
      content: 'Are you sure you want to delcine this social request?',
      onOk: () => {
        dispatch(
          declineSocialRequest({
            id,
            from: period[0].format(BEGIN_MONTH_FORMAT),
            to: period[1].format(DATE_FORMAT),
            role,
          }),
        );
        Modal.destroyAll();
      },
    });
  };

  const onPreview = url => () => {
    setDoc([{ uri: url }]);
  };

  const onClosePreviewModal = () => {
    setDoc(null);
  };

  const onDownloadAttachment = doc => () => {
    doc.length && window.open(doc[0].uri);
  };

  const columns = useMemo(
    () =>
      getColumns({
        sortInfo,
        filterInfo,
        onApprove: onOpenApproveModal,
        onDecline,
        onPreview,
        searchInput,
        role,
        user,
        filters: socialRequestsFilters,
      }),
    [sortInfo, filterInfo, searchText, role, user, socialRequestsFilters],
  );

  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      total: Number(socialRequestsTotal),
    }));
  }, [socialRequestsTotal]);

  useEffect(() => {
    hasRights(role, REQUESTS_PERMISSIONS.balance) &&
      dispatch(
        getSocialRequestsInfo({
          from: dayjs(period[0]).format(BEGIN_MONTH_FORMAT),
          to: dayjs(period[1])
            .clone()
            .endOf('M')
            .format(DATE_FORMAT),
        }),
      );
    dispatch(getSocialRequests(REQ_PARAMS));
  }, [period]);

  return (
    <div className={styles.socialRequests}>
      <SocialRequestsApproveModal
        data={approveModalData}
        onHide={onHideApproveModal}
        approveProfile={approveProfile}
        setApproveProfile={setApproveProfile}
        onApprove={onApprove}
      />
      <SocialRequestAttachmentModal
        doc={doc}
        isVisible={!!doc}
        onClose={onClosePreviewModal}
        onDownloadAttachment={onDownloadAttachment}
      />
      <PageHeader
        title="Social benefits requests"
        extra={
          <div className={styles.pageExtra}>
            {hasRights(role, REQUESTS_PERMISSIONS.balance) && (
              <SocialRequestsTotal socialRequestsInfo={socialRequestsInfo} />
            )}
            <RangePicker
              onChange={onPeriodChange}
              value={period}
              picker="month"
              format={MONTH_FORMAT}
            />
          </div>
        }
      />
      <Loader loading={isSocialRequestsLoading}>
        <Table
          dataSource={socialRequests}
          columns={columns}
          pagination={pagination}
          onChange={onTableChange}
        />
      </Loader>
    </div>
  );
};

export default SocialRequestsPage;
