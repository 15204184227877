import dayjs from 'dayjs';
import {
  uniqWith,
  isEqual,
  uniq,
  difference,
  isBoolean,
  get,
  without,
} from 'lodash';
import { DATE_FORMAT } from 'utils/timeConstants';
import {
  EMPLOYEE_COLUMN_KEY,
  LOCAL_STORAGE_KEYS,
  PLAN_TYPES_WITH_PROJECT,
  MANAGER_POSITIONS,
  RM_PLAN_TYPES,
} from './constants';

const timeFormat = 'HH:mm';
const workingHours = 8;

const usersColumn = [
  {
    Header: EMPLOYEE_COLUMN_KEY,
    valueColum: 'name',
    accessor: 'name',
    isDayOff: false,
  },
];

const filterEmployees = employees =>
  employees.filter(
    item =>
      item.jira_user &&
      ((item.fired_at &&
        dayjs(item.fired_at).isAfter(dayjs().subtract(1, 'day'))) ||
        !item.fired_at),
  );

const groupBy = key => array =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj.assignee[key]]: (objectsByKeyValue[obj.assignee[key]] || []).concat(
        obj,
      ),
    }),
    {},
  );

const columnObj = (title, accessor, isDayOff) => ({
  Header: title,
  valueColum: accessor,
  accessor,
  isDayOff,
});

const convertTimeToSeconds = plannedTime => {
  const numbers = plannedTime.replace(/\s/g, '').match(/\d+/g);

  if (numbers.length === 1) {
    return plannedTime[numbers[0].length] === 'm'
      ? +numbers[0] * 60
      : +numbers[0] * 3600;
  } else {
    return plannedTime[numbers[0].length] === 'm'
      ? +numbers[0] * 60 + +numbers[1] * 60
      : +numbers[0] * 3600 + +numbers[1] * 60;
  }
};

const formatedDataForCreate = (values, listNamesUser, calendar) => {
  const plannedTime = convertTimeToSeconds(values.plannedTime);
  const numberDays = values.rangeDate
    ? calendar
        .slice(
          calendar.findIndex(
            item =>
              item.date === dayjs(values.rangeDate[0]).format(DATE_FORMAT),
          ),
          calendar.findIndex(
            item =>
              item.date === dayjs(values.rangeDate[1]).format(DATE_FORMAT),
          ) + 1,
        )
        .filter(item => !item.is_day_off || values.includeNonWorkingDays).length
    : 1;

  const perDayTime =
    values.dividedTime === 'Per day' ? plannedTime : plannedTime / numberDays;
  const projectTypeParams = {
    assignee: {
      key: listNamesUser.jira_user.id,
      userKey: listNamesUser.jira_user.key,
      type: 'USER',
    },
    planItem: {
      id: values.project,
      type: RM_PLAN_TYPES.PROJECT,
    },
    scope: {
      id: values.project,
      type: 'none',
    },
    secondsPerDay: perDayTime || 3600,
    includeNonWorkingDays: values.includeNonWorkingDays,
    start: values.rangeDate
      ? dayjs(values.rangeDate[0]).format(DATE_FORMAT)
      : dayjs(values.data).format(DATE_FORMAT),
    end: values.rangeDate
      ? dayjs(values.rangeDate[1]).format(DATE_FORMAT)
      : dayjs(values.data).format(DATE_FORMAT),
    description: values.description,
    recurrence: {
      endDate: dayjs(values.endData).format(DATE_FORMAT),
      rule: 'NEVER',
    },
    planning_type: values.planType,
  };
  const otherTypeParams = {
    assignee: {
      key: listNamesUser.jira_user.id,
      userKey: listNamesUser.jira_user.key,
      type: 'USER',
    },
    planItem: {
      type: values.planType,
    },
    secondsPerDay: perDayTime || 3600,
    includeNonWorkingDays: values.includeNonWorkingDays,
    start: values.rangeDate
      ? dayjs(values.rangeDate[0]).format(DATE_FORMAT)
      : dayjs(values.data).format(DATE_FORMAT),
    end: values.rangeDate
      ? dayjs(values.rangeDate[1]).format(DATE_FORMAT)
      : dayjs(values.data).format(DATE_FORMAT),
    description: values.description,
    recurrence: {
      endDate: dayjs(values.endData).format(DATE_FORMAT),
      rule: 'NEVER',
    },
  };

  if (values.planType === RM_PLAN_TYPES.DEALS) {
    projectTypeParams.dealAllocationId = values.dealAllocationId;
  }

  return values.project ? projectTypeParams : otherTypeParams;
};

const defaultDataForModal = (
  { defaultEmployees, defaultProjects },
  column,
  planType,
) => ({
  name: defaultEmployees && defaultEmployees.jira_user.name,
  project: defaultProjects && defaultProjects.id,
  data: dayjs(column, DATE_FORMAT),
  rangeDate: [dayjs(column, DATE_FORMAT), dayjs(column, DATE_FORMAT)],
  endData: dayjs(column, DATE_FORMAT),
  dividedTime: 'Per day',
  planType: planType,
});

const formatedDataForModal = (pl, project) => {
  const hours = pl && dayjs.duration(pl.secondsPerDay, 'seconds').hours();
  const minutes = pl && dayjs.duration(pl.secondsPerDay, 'seconds').minutes();
  const plannedTime = `${hours}h ${minutes}m`;
  const data = pl && {
    name: pl.assignee.key,
    project: project && project.id,
    description: pl.description,
    period: true,
    data: dayjs(pl.start, DATE_FORMAT),
    rangeDate: [dayjs(pl.start, DATE_FORMAT), dayjs(pl.start, DATE_FORMAT)],
    endData: dayjs(pl.end, DATE_FORMAT),
    plannedTime,
    reviewer: pl.planApproval && pl.planApproval.reviewer.displayName,
    dividedTime: 'Per day',
    planType: PLAN_TYPES_WITH_PROJECT.includes(pl.planItem.type)
      ? RM_PLAN_TYPES.DEALS
      : pl.planItem.type,
    dealAllocationId: pl.dealAllocationId,
  };

  return data;
};

const findManagers = employees => {
  const managersId = uniqWith(
    employees
      .filter(item => item.resource_manager)
      .map(item => item.resource_manager.id),
    isEqual,
  );

  return employees.filter(item => managersId.find(el => el === item.id));
};

const calculateData = (columnDataObject, projects, item, startDate) => {
  let pr = {};
  const projectsId =
    columnDataObject[get(item, 'jira_id')] &&
    columnDataObject[get(item, 'jira_id')].map(el =>
      el.planItem.type === RM_PLAN_TYPES.PROJECT
        ? el.planItem.id
        : el.planItem.type,
    );
  (columnDataObject[get(item, 'jira_id')] || []).forEach(el => {
    if (el.start === el.end) {
      if (pr.hasOwnProperty(el.start)) {
        pr[el.start] = [...pr[el.start], el];
      } else {
        pr[el.start] = [el];
      }
    } else {
      const numberDays = dayjs(el.end).diff(el.start, 'days') + 1;
      let periodDate = el.start;
      for (let i = 0; i < numberDays; i++) {
        if (pr.hasOwnProperty(periodDate)) {
          pr[periodDate] = [...pr[periodDate], el];
        } else {
          pr[periodDate] = [el];
        }

        periodDate = dayjs(periodDate)
          .add(1, 'day')
          .format(DATE_FORMAT);
      }
    }
  });
  let props = {
    ...pr,
    projects: [],
  };

  uniq(projectsId).map(project => {
    const userProjects = projects.find(el => +el.id === +project);

    if (columnDataObject[item.jira_id]) {
      props.projects.push({
        key: project,
        name: userProjects ? userProjects.name : project,
      });
      columnDataObject[item.jira_id].forEach(element => {
        const date = !dayjs(element.start).isBefore(dayjs(startDate))
          ? element.start
          : startDate;

        if (
          (element.planItem.type === RM_PLAN_TYPES.PROJECT &&
            element.planItem.id ===
              props.projects[props.projects.length - 1].key) ||
          (element.planItem.type !== RM_PLAN_TYPES.PROJECT &&
            element.planItem.type ===
              props.projects[props.projects.length - 1].key)
        ) {
          props.projects[props.projects.length - 1][date] = element;
        }
      });
    }
  });

  return props;
};

const isBetweenDate = (date, start, end) =>
  dayjs(date).isBetween(
    dayjs(start).subtract(1, 'day'),
    dayjs(end).add(1, 'day'),
  );

const isEqualArg = (arg1, arg2) => arg1 === arg2;
const isNotEqualArg = (arg1, arg2) => arg1 !== arg2;

const calculateTotalTime = ({
  plans,
  endDate,
  startDate,
  updatedData,
  calendar,
  selectedManagerIndex,
  selectedUserIndex,
  selectedTechnologiesIndex,
  selectedPositionsIndex,
  selectedTeamsIndex,
  employees,
  data,
}) => {
  const numberEmployees = uniq(updatedData.map(item => item.id)).length;
  const workingDays = calendar.filter(
    item =>
      isBetweenDate(item.date, availableStartDate, endDate) && !item.is_day_off,
  );

  const jiraUsers = employees.reduce((accumulator, currentValue) => {
    if (
      currentValue.jira_user &&
      data.find(elem => elem.id === currentValue.id)
    ) {
      return accumulator
        ? [...accumulator, currentValue.jira_user.id]
        : [currentValue.jira_user.id];
    }

    return accumulator;
  }, 0);

  const availableStartDate =
    dayjs().month() === dayjs(startDate).month()
      ? dayjs().format(DATE_FORMAT)
      : startDate;

  const remainingPlans =
    plans.length &&
    plans.filter(item => {
      if (
        jiraUsers &&
        jiraUsers.indexOf(item.assignee?.key) !== -1 &&
        !dayjs(item.end).isBefore(startDate)
      ) {
        if (selectedManagerIndex.length) {
          const filterData = employees.reduce((acc, el) => {
            const managerKey =
              el.resource_manager && el.resource_manager.jira_id;
            const manager = employees.find(
              employee => employees && employee.jira_id === managerKey,
            );

            if (
              manager &&
              manager.jira_user &&
              selectedManagerIndex.indexOf(manager.jira_user.name) !== -1
            ) {
              return acc
                ? [...acc, el.jira_user && el.jira_user.name]
                : [el.jira_user.name];
            }

            return acc;
          }, []);

          return filterData.indexOf(item.assignee.key) !== -1;
        }

        if (selectedUserIndex.length) {
          return selectedUserIndex.indexOf(item.assignee.key) !== -1;
        }

        if (selectedTechnologiesIndex.length) {
          const filterData = employees.reduce((acc, el) => {
            const techNames = el.technologies.map(tech => tech.title);

            if (
              techNames.length !==
              difference(techNames, selectedTechnologiesIndex).length
            ) {
              return acc
                ? [...acc, el.jira_user && el.jira_user.name]
                : [el.jira_user.name];
            }

            return acc;
          }, []);

          return filterData.indexOf(item.assignee.key) !== -1;
        }

        if (selectedPositionsIndex.length) {
          const filterData = employees.reduce((acc, el) => {
            if (
              el.employee_position &&
              selectedPositionsIndex.indexOf(el.employee_position.title) !== -1
            ) {
              return acc
                ? [...acc, el.jira_user && el.jira_user.name]
                : [el.jira_user.name];
            }

            return acc;
          }, []);

          return filterData.indexOf(item.assignee.key) !== -1;
        }

        if (selectedTeamsIndex.length) {
          const filterData = employees.reduce((acc, el) => {
            if (el.team_id && selectedTeamsIndex.indexOf(el.team_id) !== -1) {
              return acc
                ? [...acc, el.jira_user && el.jira_user.name]
                : [el.jira_user.name];
            }

            return acc;
          }, []);

          return filterData.indexOf(item.assignee.key) !== -1;
        }

        return true;
      }

      return false;
    });
  const remainingPlanTime = secondsByType(
    remainingPlans,
    workingDays,
    'BENCH',
    isNotEqualArg,
  );
  const time =
    workingDays.length * workingHours * numberEmployees -
    remainingPlanTime / 3600;
  const minutes = dayjs.duration(time, 'hours').minutes();
  const secondsByBench = secondsByBenchType(remainingPlans) / 3600;
  const secondsByVacation =
    secondsByType(
      remainingPlans,
      workingDays,
      RM_PLAN_TYPES.VACATION,
      isEqualArg,
    ) / 3600;
  const secondsBySickleave =
    secondsByType(
      remainingPlans,
      workingDays,
      RM_PLAN_TYPES.SICKLEAVE,
      isEqualArg,
    ) / 3600;

  return {
    remainingDays: `${Math.trunc(time)} h ${minutes} m`,
    secondsByBench,
    secondsByVacation,
    secondsBySickleave,
  };
};

const secondsByType = (remainingPlans, workingDays, type, comparisonFun) =>
  remainingPlans &&
  remainingPlans.length &&
  remainingPlans.reduce((accumulator, currentValue) => {
    if (comparisonFun(currentValue.planItem.type, type)) {
      const numberDaysOfPlan = workingDays.filter(item =>
        isBetweenDate(item.date, currentValue.start, currentValue.end),
      ).length;

      return accumulator
        ? currentValue.secondsPerDay * numberDaysOfPlan + accumulator
        : currentValue.secondsPerDay * numberDaysOfPlan;
    }

    return accumulator;
  }, 0);

const secondsByBenchType = remainingPlans => {
  let dates = {};
  remainingPlans &&
    remainingPlans.length &&
    remainingPlans.map(item => {
      let currentDate = dayjs(item.start);
      do {
        if (item.planItem.type === RM_PLAN_TYPES.BENCH) {
          if (dates.hasOwnProperty(currentDate.format(DATE_FORMAT))) {
            dates[currentDate.format(DATE_FORMAT)] += item.secondsPerDay;
          } else {
            dates[currentDate.format(DATE_FORMAT)] = item.secondsPerDay;
          }
        }

        currentDate = dayjs(currentDate).add(1, 'day');
      } while (currentDate.isBefore(dayjs(item.end).add(1, 'day')));
    });

  let seconds = 0;
  for (const key in dates) {
    if (dates[key] > 0) seconds += dates[key];
  }

  return seconds;
};

const filterByManager = (selectedManagerIndex, jiraUsers, data) =>
  data.filter(item => {
    const selectedManagerKey = selectedManagerIndex.map(elem => {
      const jiraUser = jiraUsers.find(user => user.jira_user.id === elem);

      return jiraUser.jira_user.key;
    });

    return selectedManagerKey.indexOf(item.resource_manager) !== -1;
  });

const findPlans = (plans, params, curPlanId, curPlanType) =>
  plans.find(item => {
    if (curPlanId) {
      return +item.id === +curPlanId && item.planItem.type === curPlanType;
    }

    const typeCondition =
      params.assignee.key === item.assignee.key &&
      (item.planItem.type === RM_PLAN_TYPES.PROJECT
        ? item.planItem.id === +params.planItem.id
        : item.planItem.type === params.planItem.type);

    return (
      (dayjs(params.start).isBetween(
        dayjs(item.start).subtract(1, 'day'),
        dayjs(item.end).add(1, 'day'),
      ) ||
        dayjs(params.end).isBetween(
          dayjs(item.start).subtract(1, 'day'),
          dayjs(item.end).add(1, 'day'),
        ) ||
        dayjs(item.start).isBetween(
          dayjs(params.start).subtract(1, 'day'),
          dayjs(params.end).add(1, 'day'),
        )) &&
      typeCondition
    );
  });

const filterData = (
  data,
  {
    selectedTeamsIndex,
    selectedPositionsIndex,
    selectedTechnologiesIndex,
    selectedUserIndex,
    selectedManagerIndex,
    jiraUsers,
  },
) => {
  const newData = data.filter(item => {
    let isReturnItem = true;

    if (
      Boolean(selectedTeamsIndex.length) &&
      isBoolean(!!item.team_id) &&
      selectedTeamsIndex.indexOf(item.team_id) === -1
    )
      isReturnItem = false;

    if (
      selectedTechnologiesIndex.length &&
      isReturnItem &&
      item.technologies.filter(
        item => selectedTechnologiesIndex.indexOf(item.title) !== -1,
      ).length !== selectedTechnologiesIndex.length
    ) {
      isReturnItem = false;
    }

    if (
      selectedPositionsIndex.length &&
      isReturnItem &&
      selectedPositionsIndex.indexOf(item.employee_position.title) === -1
    )
      isReturnItem = false;

    if (
      selectedUserIndex.length &&
      selectedUserIndex.indexOf(item.jiraName) === -1
    )
      isReturnItem = false;

    return isReturnItem;
  });

  if (selectedManagerIndex.length) {
    return filterByManager(selectedManagerIndex, jiraUsers, newData);
  }

  return newData;
};
const getRoundValue = value => (value % 1 === 0 ? value : value.toFixed(2));

const getFilterField = (field, type, state, value) =>
  field === type ? without(state, value) : uniq([...state]);

const getFilterFieldOnSearch = (field, type, state, value) =>
  field === type ? uniq([...state, value]) : uniq([...state]);

const getFilterValuesOnCheckbox = ({
  selectedManagerIndex,
  selectedUserIndex,
  selectedTechnologiesIndex,
  selectedPositionsIndex,
  selectedTeamsIndex,
  value,
  type,
}) => {
  const values = {
    selectedUserIndex: getFilterField('user', type, selectedUserIndex, value),
    selectedManagerIndex: getFilterField(
      'manager',
      type,
      selectedManagerIndex,
      value,
    ),
    selectedTechnologiesIndex: getFilterField(
      'technology',
      type,
      selectedTechnologiesIndex,
      value,
    ),
    selectedPositionsIndex: getFilterField(
      'position',
      type,
      selectedPositionsIndex,
      value,
    ),
    selectedTeamsIndex: getFilterField('team', type, selectedTeamsIndex, value),
  };

  localStorage.setItem(LOCAL_STORAGE_KEYS.rmFilters, JSON.stringify(values));

  return values;
};

const getFilterValuesOnSearch = ({
  selectedTechnologiesIndex,
  selectedManagerIndex,
  selectedUserIndex,
  selectedPositionsIndex,
  selectedTeamsIndex,
  value,
  type,
}) => {
  const values = {
    selectedTechnologiesIndex: getFilterFieldOnSearch(
      'technology',
      type,
      selectedTechnologiesIndex,
      value,
    ),
    selectedManagerIndex: getFilterFieldOnSearch(
      'manager',
      type,
      selectedManagerIndex,
      value,
    ),
    selectedUserIndex: getFilterFieldOnSearch(
      'user',
      type,
      selectedUserIndex,
      value,
    ),
    selectedPositionsIndex: getFilterFieldOnSearch(
      'position',
      type,
      selectedPositionsIndex,
      value,
    ),
    selectedTeamsIndex: getFilterFieldOnSearch(
      'team',
      type,
      selectedTeamsIndex,
      value,
    ),
  };

  localStorage.setItem(LOCAL_STORAGE_KEYS.rmFilters, JSON.stringify(values));

  return values;
};

const clearFilters = () => {
  const filters = {
    selectedManagerIndex: [],
    selectedUserIndex: [],
    selectedTechnologiesIndex: [],
    selectedPositionsIndex: [],
    selectedTeamsIndex: [],
  };

  localStorage.setItem(LOCAL_STORAGE_KEYS.rmFilters, JSON.stringify(filters));

  return filters;
};

const getFilteredEmployees = ({ employees, onlyDelivery, hidePms }) => {
  let filteredEmployees = employees;

  if (onlyDelivery) {
    filteredEmployees = filteredEmployees.filter(
      employee =>
        employee.employee_position && employee.employee_position.is_delivery,
    );
  }

  if (hidePms) {
    filteredEmployees = filteredEmployees.filter(
      employee =>
        !(
          employee.employee_position &&
          MANAGER_POSITIONS.includes(
            employee.employee_position.title.toLowerCase(),
          )
        ),
    );
  }

  return filteredEmployees;
};

export {
  usersColumn,
  DATE_FORMAT,
  columnObj,
  timeFormat,
  formatedDataForCreate,
  formatedDataForModal,
  findManagers,
  calculateData,
  workingHours,
  calculateTotalTime,
  defaultDataForModal,
  groupBy,
  filterByManager,
  filterEmployees,
  findPlans,
  filterData,
  getRoundValue,
  getFilterValuesOnCheckbox,
  getFilterValuesOnSearch,
  clearFilters,
  getFilteredEmployees,
};
