import { ROLE_TYPES } from 'utils/permissions';

export const EMPLOYEES_PAGE_SIZE = 1000;

export const EMPLOYEES_TABLE_COMMON = {
  id_title: 'ID',
  id: 'id',
  internal_id_title: '1C ID',
  internal_id: 'internal_id',
  name_title: 'Full name',
  name: 'name',
  full_name: 'full_name',
  organization_title: 'Organization',
  organization_id: 'organization_id',
  contract_title: 'Contract ends',
  contract_ends_at: 'contract_ends_at',
  fired_at_title: 'Fired',
  fired_at: 'fired_at',
  updated_at_title: 'Updated',
  updated_at: 'updated_at',
  team_id_title: 'Team',
  team_id: 'team_id',
  location_title: 'Location',
  location: 'location',
  status_title: 'Status',
  status: 'status',
  summary_employee: 'summary_employee',
};

export const EMPLOYEES_EXPAND_PARAMETERS = {
  technologies: 'technologies',
  resource_manager: 'resource_manager',
  employee_position: 'employee_position',
};

export const EMPLOYEE_PROFILES_PERMISSIONS = {
  addProfile: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.office_manger,
  ],
  updateProfile: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.office_manger,
  ],
  importProfiles: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
};
