/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Upload, Form } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { EditOutlined } from '@ant-design/icons';

import ClientContractsPage from 'containers/ClientContractsPage/ClientContractsPage';
import { ClientContractsModal } from 'components/ClientCommon';
import { DATE_FORMAT } from 'utils/timeConstants';

import DetailsContainer from '../../../Common/DetailsContainer';
import { START_DATE, END_DATE, DATED } from './constants';
import ClientContractsItem from './ClientContractsItem';
import styles from './styles.scss';

const ClientContracts = ({
  isVisible,
  setIsVisible,
  contracts,
  organizationDataObject,
  organizationsData,
  updateContract,
  id,
}) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isEditRecord, setIsEditRecord] = useState(false);
  const [editRecordId, setEditRecordId] = useState('');

  const [form] = Form.useForm();

  const onShowModal = () => {
    setIsVisible(true);
  };

  const onHideModal = () => {
    setIsVisible(false);
  };

  const onAddContract = async () => {
    const isValidate = await form.validateFields();

    if (isValidate) {
      const values = form.getFieldsValue();
      const modifiedValues = Object.keys(values).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: dayjs.isDayjs(values[curr])
            ? dayjs(values[curr]).format(DATE_FORMAT)
            : values[curr],
        }),
        {},
      );

      const valuesWithoutNull = Object.keys(modifiedValues).reduce(
        (acc, cur) =>
          modifiedValues[cur] ? { ...acc, [cur]: modifiedValues[cur] } : acc,
        {},
      );

      updateContract({
        values: valuesWithoutNull,
        id: editRecordId,
        clientId: id,
      });

      onHideClientContractModal();
    }
  };

  const onEditRecord = record => {
    setIsEditRecord(true);
    setIsVisibleModal(true);
    const formValues = Object.keys(record).reduce((acc, curr) => {
      if (curr === END_DATE || curr === START_DATE || curr === DATED) {
        return record[curr]
          ? { ...acc, [curr]: dayjs(record[curr]) }
          : { ...acc, [curr]: record[curr] };
      }

      return { ...acc, [curr]: record[curr] };
    }, {});
    setEditRecordId(record.id);
    form.setFieldsValue(formValues);
  };

  const onHideClientContractModal = () => {
    setIsVisibleModal(false);
    setIsEditRecord(false);
    form.resetFields();
    setEditRecordId('');
  };

  return (
    <>
      <Modal open={isVisible} width="100%" onCancel={onHideModal} footer={null}>
        <ClientContractsPage isClientPage />
      </Modal>
      <DetailsContainer
        name="Contracts"
        extra={
          <Button
            size="small"
            onClick={onShowModal}
            type="link"
            icon={<EditOutlined />}
          />
        }
      >
        <ClientContractsModal
          isVisibleModal={isVisibleModal}
          onHideModal={onHideClientContractModal}
          organizationsData={organizationsData}
          form={form}
          onFinish={onAddContract}
          isEditRecord={isEditRecord}
        />
        {isEmpty(contracts) ? (
          <div className={styles.contractsModalContainer}>
            <Button type="dashed" onClick={onShowModal}>
              Add contract
            </Button>
          </div>
        ) : (
          contracts.map(contract => (
            <div className={styles.clientContractItem} key={contract.id}>
              <ClientContractsItem label="Number:">
                <span
                  className={styles.clientContractNumber}
                  onClick={() => onEditRecord(contract)}
                  role="button"
                >
                  {contract.number}
                </span>
              </ClientContractsItem>
              <ClientContractsItem label="Dated:">
                {contract.dated}
              </ClientContractsItem>
              <ClientContractsItem label="Starts at:">
                {contract.start_date}
              </ClientContractsItem>
              {contract.end_date && (
                <ClientContractsItem label="Ends at:">
                  {contract.end_date}
                </ClientContractsItem>
              )}
              <ClientContractsItem label="Contractor:">
                {organizationDataObject[contract.contractor_id] &&
                  organizationDataObject[contract.contractor_id][0]
                    .friendly_name}
              </ClientContractsItem>
              <ClientContractsItem label="Customer:">
                {organizationDataObject[contract.customer_id] &&
                  organizationDataObject[contract.customer_id][0].friendly_name}
              </ClientContractsItem>
              {contract.attachments && (
                <div>
                  <Upload
                    showUploadList={{
                      showDownloadIcon: false,
                      showRemoveIcon: false,
                    }}
                    fileList={contract.attachments.map(attachment => ({
                      name: attachment.title,
                      status: 'done',
                      url: attachment.url,
                      uid: `fileTable__${attachment.title}`,
                    }))}
                  />
                </div>
              )}
            </div>
          ))
        )}
      </DetailsContainer>
    </>
  );
};

ClientContracts.propTypes = {
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  contracts: PropTypes.array,
  organizationsData: PropTypes.array,
  organizationDataObject: PropTypes.object,
  updateContract: PropTypes.func,
  id: PropTypes.string,
};

export default ClientContracts;
