/* eslint-disable indent */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, uniqBy, groupBy } from 'lodash';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { Form } from 'antd';
import { useSelector } from 'react-redux';

import { Loader, Table } from 'components/Common/';
import { mapArrayToEntities } from 'utils/common';
import { LOCAL_STORAGE_ITEMS } from 'utils/localStorage';
import { currenciesSelector } from 'redux/selectors';
import { getRowKey } from 'utils/helpers';
import { DATE_FORMAT } from 'utils/timeConstants';

import { CREATION_ROW, BUTTON_ROW } from './constants';
import { components, getColumns, getDeletedInvoiceItems } from './utils';

const ClientInvoiceItem = ({
  invoiceData = {},
  isUpdateInvoice,
  invoiceItems = [],
  setInvoiceItems,
  isFullScreen,
  setIsInvoiceValuesTouched,
  isShowDealDescription = false,
}) => {
  const [editingKey, setEditingKey] = useState('');
  const [isAddInvoiceItem, setIsAddInvoiceItem] = useState(false);
  const [groupTitleSelectValue, setGroupTitleSelectValue] = useState('');

  const [periods, setPeriods] = useState({
    period_from: dayjs(),
    period_to: dayjs(),
  });
  const [uniqGroupTitle, setUniqGroupTitle] = useState([]);

  const [form] = Form.useForm();

  const { currencies } = useSelector(currenciesSelector);

  useEffect(() => {
    const originalItems = get(invoiceData, 'original_items', []);
    const items = get(invoiceData, 'items');

    const itemsGroupById = groupBy(items, 'id');

    const deletedInvoiceItems = getDeletedInvoiceItems({
      itemsGroupById,
      originalItems,
    });

    setInvoiceItems(items ? [...items, ...deletedInvoiceItems] : originalItems);
    setUniqGroupTitle(uniqBy(items ? items : originalItems, 'group_title'));
  }, [invoiceData]);

  const setIsItemsTouched = () => {
    setIsInvoiceValuesTouched(prev => ({
      ...prev,
      items: true,
    }));
  };

  const isEditing = record => record.id === editingKey;

  const onEditRow = record => {
    if (record) {
      form.setFieldsValue({
        ...record,
      });
      setPeriods({
        period_from: record.period_from,
        period_to: record.period_to,
      });
      setEditingKey(record.id);
    }
  };

  const onCancelEditOrCreate = () => {
    if (isAddInvoiceItem) {
      setIsAddInvoiceItem(false);
    }

    setInvoiceItems(prev => prev.filter(item => item.id !== 'new_allocation'));
    setEditingKey('');
  };

  const onSave = async record => {
    const isValidate = await form.validateFields();

    if (isValidate) {
      const values = form.getFieldsValue();
      setIsItemsTouched();
      const { unit_price, quantity, discount } = values;
      const amount =
        discount === 0
          ? unit_price * quantity
          : quantity * unit_price - quantity * unit_price * (discount / 100);

      if (isAddInvoiceItem) {
        const updatedData = invoiceItems.map(item => {
          const periodFrom = dayjs(periods.period_from).format(DATE_FORMAT);
          const periodTo = dayjs(periods.period_to).format(DATE_FORMAT);
          const quantity_seconds = quantity * 3600;

          const id = uuidv4();

          if (item.id === editingKey)
            return {
              ...values,
              key: id,
              id: id,
              is_new: true,
              original_quantity: null,
              expected_quantity: null,
              amount,
              period_from: periodFrom,
              period_to: periodTo,
              quantity_seconds,
            };

          return item;
        });

        setInvoiceItems(updatedData);
      } else {
        const updatedData = invoiceItems.map(item => {
          const periodFrom = dayjs(periods.period_from).format(DATE_FORMAT);
          const periodTo = dayjs(periods.period_to).format(DATE_FORMAT);
          const quantity_seconds = quantity * 3600;

          if (item.id === editingKey)
            return item.is_new
              ? {
                  ...item,
                  ...values,
                  is_new: true,
                  old_values: record,
                  original_quantity: null,
                  expected_quantity: null,
                  amount,
                  period_from: periodFrom,
                  period_to: periodTo,
                  quantity_seconds,
                }
              : {
                  ...item,
                  ...values,
                  edited: true,
                  old_values: record,
                  amount,
                  period_from: periodFrom,
                  period_to: periodTo,
                  quantity_seconds,
                };

          return item;
        });

        setInvoiceItems(updatedData);
      }

      setIsAddInvoiceItem(false);
      setEditingKey('');
      setGroupTitleSelectValue('');
    }
  };

  const handleAdd = () => {
    if (isAddInvoiceItem) {
      const period_from = invoiceData
        ? dayjs(invoiceData.period_from)
        : dayjs();
      const period_to = invoiceData ? dayjs(invoiceData.period_to) : dayjs();
      setInvoiceItems(prev => [
        ...prev,
        {
          ...CREATION_ROW,
          period_from,
          period_to,
          discount: 0,
          tax_rate: 0,
        },
      ]);
      setEditingKey(CREATION_ROW.key);
    } else {
      setEditingKey('');
    }

    form.resetFields();
  };

  const onDeleteInvoice = record => {
    if (record) {
      setIsItemsTouched();
      setInvoiceItems(prev =>
        prev.map(item =>
          record.id === item.id ? { ...item, deleted: true } : item,
        ),
      );
    }
  };

  const onRestoreInvoice = record => {
    if (record) {
      // @ts-ignore
      setIsItemsTouched();
      setInvoiceItems(prev =>
        prev.map(item =>
          record.id === item.id ? { ...item, deleted: false } : item,
        ),
      );
    }
  };

  const onChangePeriods = ({ dataIndex, value }) => {
    if (value) {
      setPeriods(prev => ({ ...prev, [dataIndex]: value }));
    }
  };

  const originalItemsGroupById = useMemo(
    () =>
      mapArrayToEntities(get(invoiceData, 'original_items') || [], {}, 'id')
        .entities,
    [invoiceData],
  );

  const columns = getColumns({
    isEditing,
    onSave,
    onCancelEditOrCreate,
    onEditRow,
    editingKey,
    onDeleteInvoice,
    setIsAddInvoiceItem,
    originalItemsGroupById,
    onRestoreInvoice,
  });

  const mergedColumns = Array.isArray(columns)
    ? columns.map(col => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: record => ({
            record,
            inputType: col.key,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            editingKey,
            currencies,
            uniqGroupTitle,
            form,
            onChangePeriods,
            setGroupTitleSelectValue,
            groupTitleSelectValue,
            isAddInvoiceItem,
          }),
        };
      })
    : [];

  useEffect(() => {
    handleAdd();
  }, [isAddInvoiceItem]);

  const tableScrollHeight = '60vh';

  return (
    <Form form={form} component={false}>
      <Loader loading={isUpdateInvoice}>
        <Table
          components={components}
          dataSource={[...invoiceItems, BUTTON_ROW]}
          columns={mergedColumns}
          size="small"
          pagination={false}
          scroll={{
            y: isFullScreen ? tableScrollHeight : '450px',
            x: '1400px',
          }}
          rowKey={getRowKey}
          style={{
            marginBottom: isFullScreen ? 0 : '30px',
            maxWidth: isShowDealDescription ? '82%' : '100%',
          }}
          loading={isUpdateInvoice}
          isPageWithId
          storageKey={LOCAL_STORAGE_ITEMS.invoiceItemsSettings}
        />
      </Loader>
    </Form>
  );
};

ClientInvoiceItem.propTypes = {
  invoiceData: PropTypes.object,
  isUpdateInvoice: PropTypes.bool.isRequired,
  setInvoiceItems: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  invoiceItems: PropTypes.array.isRequired,
  setIsInvoiceValuesTouched: PropTypes.func.isRequired,
  isShowDealDescription: PropTypes.bool.isRequired,
};

export default ClientInvoiceItem;
