import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { Select, Modal, Form, Calendar } from 'antd';
import PageHeader from 'components/Common/PageHeader';
import { useDispatch, useSelector } from 'react-redux';

import { AverageSalaryForm } from 'components/AverageSalaryCommon';
import { Loader } from 'components/Common';
// import Calendar from 'components/Common/Calendar';
import { averageSalariesSelector } from 'redux/selectors';
import {
  addAverageSalary,
  getAverageSalariesByYear,
  updateAverageSalary,
} from 'redux/AverageSalaries/actions';
import { BEGIN_MONTH_FORMAT } from 'utils/timeConstants';
import { CURRENCY_CODES } from 'utils/constants';

import { currencyFormatter } from 'utils/helpers';
import styles from './styles.scss';

const { useForm } = Form;

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 16 },
    lg: { span: 16 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 8 },
    lg: { span: 8 },
  },
};

const AverageSalariesPage = () => {
  const year = dayjs().get('year');

  const [currentYear, setCurrentYear] = useState(year);
  const [editedData, setEditedData] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const [form] = useForm();

  const dispatch = useDispatch();

  const { averageSalaries, isAverageSalariesLoading } = useSelector(
    averageSalariesSelector,
  );

  useEffect(() => {
    dispatch(getAverageSalariesByYear(currentYear));
  }, [currentYear]);

  const onSelectCell = value => {
    const selectedMonth = value.get('month');

    setEditedData({ selectedMonth });
    setIsVisible(true);
    form.setFieldsValue({
      period: dayjs()
        .set('year', currentYear)
        .set('month', selectedMonth)
        .format(BEGIN_MONTH_FORMAT),
      value: get(averageSalaries, [currentYear, selectedMonth], null),
    });
  };

  const getPeriod = () =>
    editedData &&
    dayjs()
      .set('year', currentYear)
      .set('month', editedData?.selectedMonth)
      .format(BEGIN_MONTH_FORMAT);

  const hideModal = () => {
    setEditedData(null);
    setIsVisible(false);
    form.resetFields();
  };

  const onSubmit = values => {
    if (
      get(
        averageSalaries,
        [currentYear, editedData && editedData.selectedMonth],
        null,
      )
    ) {
      dispatch(updateAverageSalary(values));
    } else {
      dispatch(addAverageSalary(values));
    }

    setIsVisible(false);
    setEditedData(null);
    form.resetFields();
  };

  const onYearChange = newYear => {
    setCurrentYear(newYear);
  };

  const monthCellRender = value => {
    const sum = get(averageSalaries, [currentYear, value.month()], null);

    return (
      <div className={styles.cell}>
        {sum && (
          <span className={styles.amount}>
            {currencyFormatter(sum, CURRENCY_CODES.BYR)}
          </span>
        )}
      </div>
    );
  };

  return (
    <Loader loading={isAverageSalariesLoading}>
      <div className={styles.container}>
        <PageHeader title="Nominal wage" />
        <Modal
          className={styles.modal}
          title={`Average salary level on ${getPeriod()}`}
          open={isVisible}
          onCancel={hideModal}
          onOk={form.submit}
          okText="Save"
          cancelText="Cancel"
          destroyOnClose
          maskClosable
        >
          <AverageSalaryForm form={form} onSubmit={onSubmit} />
        </Modal>
        <Calendar
          className={styles.calendar}
          mode="year"
          headerRender={() => {
            const options = [];
            for (let i = year - 10; i < year + 2; i += 1) {
              options.push(
                <Select.Option key={i} value={i}>
                  {i}
                </Select.Option>,
              );
            }

            return (
              <Form {...formItemLayout}>
                <Form.Item label="Year" style={{ padding: 10 }}>
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    onChange={onYearChange}
                    value={String(currentYear)}
                  >
                    {options}
                  </Select>
                </Form.Item>
              </Form>
            );
          }}
          monthCellRender={monthCellRender}
          onSelect={onSelectCell}
        />
      </div>
    </Loader>
  );
};

AverageSalariesPage.propTypes = {
  salaries: PropTypes.object,
  addAverageSalary: PropTypes.func.isRequired,
  updateAverageSalary: PropTypes.func.isRequired,
  getAverageSalariesByYear: PropTypes.func.isRequired,
};

export default AverageSalariesPage;
