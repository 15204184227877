/* eslint-disable react/prop-types*/

import React from 'react';
import { get, isArray } from 'lodash';
import { Typography, Table } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import {
  CELL_COLORS,
  GREEN_COLOR,
} from 'components/ClientCommon/ClientTimeline/TimelineItems/CommonItem/constants';
import { getConditionPreview } from 'components/ClientCommon/ClientModal/ClientAllocationTable/utils';
import { formatNumberWithCommas } from 'utils/helpers';
import { FILTER_TYPES, AUDIT_ACTIONS } from '../../../constants';

import styles from './styles.scss';

const { Text } = Typography;

const getOldAllocations = ({
  oldAllocationsIndex,
  currentAllocationIndex,
  allocationsGroupsById,
  idOfPreviousDeal,
}) =>
  oldAllocationsIndex === currentAllocationIndex
    ? allocationsGroupsById[idOfPreviousDeal][oldAllocationsIndex]
    : allocationsGroupsById[idOfPreviousDeal][currentAllocationIndex];

export const getColumns = ({
  jiraRoles,
  filteredClientAudit,
  deal,
  groupedDealsByProject,
  allocationsGroupsById,
}) => {
  const indexOfDeal =
    deal &&
    groupedDealsByProject[deal.project_id] &&
    groupedDealsByProject[deal.project_id].findIndex(
      item => item.id === deal.id,
    );
  const dealsInArray =
    deal && groupedDealsByProject[deal.project_id]
      ? groupedDealsByProject[deal.project_id].length
      : 0;

  const indexOfPreviousDeal =
    indexOfDeal > 0 && indexOfDeal < dealsInArray ? indexOfDeal - 1 : -1;

  const idOfPreviousDeal =
    indexOfPreviousDeal >= 0 &&
    groupedDealsByProject[deal.project_id][indexOfPreviousDeal].id;

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '8%',
    },
    {
      title: 'Role',
      dataIndex: 'jira_role_id',
      key: 'position',
      width: '25%',
      align: 'left',
      render: (value, record, currentAllocationIndex) => {
        if (record.action && record.action === 'destroy') {
          return {
            props: {
              style: { backgroundColor: CELL_COLORS.red },
            },
            children: (
              <Text delete className={styles.tableItem}>
                {isArray(jiraRoles[value]) && jiraRoles[value][0].name}
              </Text>
            ),
          };
        }

        if (idOfPreviousDeal) {
          const isOldAllocations =
            allocationsGroupsById[idOfPreviousDeal] &&
            allocationsGroupsById[idOfPreviousDeal]
              .slice(currentAllocationIndex)
              .some(item => item.jira_role_id === record.jira_role_id);

          if (isOldAllocations) {
            return (
              <Text className={styles.tableItem}>
                {isArray(jiraRoles[value]) && jiraRoles[value][0].name}
              </Text>
            );
          } else {
            return {
              props: {
                style: { backgroundColor: CELL_COLORS.green },
              },
              children: (
                <Text className={styles.tableItem}>
                  {isArray(jiraRoles[value]) && jiraRoles[value][0].name}
                </Text>
              ),
            };
          }
        }

        const currentAllocation = get(
          filteredClientAudit,
          'dealAllocations',
          [],
        ).find(
          allocation =>
            allocation.auditable_id === record.id &&
            allocation.action === 'create',
        );

        if (currentAllocation && idOfPreviousDeal) {
          return {
            props: {
              style: { backgroundColor: CELL_COLORS.green },
            },
            children: (
              <Text className={styles.tableItem}>
                {isArray(jiraRoles[value]) && jiraRoles[value][0].name}
              </Text>
            ),
          };
        }

        return (
          <Text className={styles.tableItem}>
            {isArray(jiraRoles[value]) && jiraRoles[value][0].name}
          </Text>
        );
      },
    },
    {
      title: 'Hours a week',
      dataIndex: 'hours_per_week',
      key: 'hours_per_week',
      width: '15%',
      render: (value, record, currentAllocationIndex) => {
        if (record.action && record.action === 'destroy') {
          return {
            props: {
              style: { backgroundColor: CELL_COLORS.red },
            },
            children: (
              <span className={styles.tableItem}>
                <Text delete>{value}</Text>
              </span>
            ),
          };
        }

        const updatedAllocation = get(
          filteredClientAudit,
          'dealAllocations',
          [],
        ).find(
          allocation =>
            allocation.auditable_id === record.id &&
            allocation.action === 'update',
        );

        if (updatedAllocation) {
          const { audited_changes } = updatedAllocation;

          if (Object.keys(audited_changes).includes('hours_per_week')) {
            return {
              props: {
                style: { backgroundColor: CELL_COLORS.yellow },
              },
              children: (
                <span className={styles.tableItem}>
                  <Text>{audited_changes.hours_per_week[0]}</Text>
                  <ArrowRightOutlined />{' '}
                  <Text>{audited_changes.hours_per_week[1]}</Text>
                </span>
              ),
            };
          }
        }

        if (idOfPreviousDeal) {
          const oldAllocationsIndex =
            allocationsGroupsById[idOfPreviousDeal] &&
            allocationsGroupsById[idOfPreviousDeal].findIndex(
              item => item.jira_role_id === record.jira_role_id,
            );
          const oldAllocations =
            allocationsGroupsById[idOfPreviousDeal] &&
            getOldAllocations({
              oldAllocationsIndex,
              currentAllocationIndex,
              allocationsGroupsById,
              idOfPreviousDeal,
            });

          if (oldAllocations) {
            return {
              props: {
                style: {
                  backgroundColor:
                    oldAllocations.hours_per_week !== value &&
                    CELL_COLORS.yellow,
                },
              },
              children: (
                <>
                  {oldAllocations.hours_per_week !== value && (
                    <span className={styles.tableItem}>
                      <Text>{oldAllocations.hours_per_week}</Text>
                      <ArrowRightOutlined />
                    </span>
                  )}
                  <span className={styles.tableItem}>
                    <Text>{value}</Text>
                  </span>
                </>
              ),
            };
          } else {
            return {
              props: {
                style: { backgroundColor: CELL_COLORS.green },
              },
              children: (
                <span className={styles.tableItem}>
                  <Text>{value}</Text>
                </span>
              ),
            };
          }
        }

        const currentAllocation = get(
          filteredClientAudit,
          'dealAllocations',
          [],
        ).find(
          allocation =>
            allocation.auditable_id === record.id &&
            allocation.action === 'create',
        );

        if (currentAllocation && idOfPreviousDeal) {
          return {
            props: {
              style: { backgroundColor: CELL_COLORS.green },
            },
            children: (
              <span className={styles.tableItem}>
                <Text>{value}</Text>
              </span>
            ),
          };
        }

        return (
          <span className={styles.tableItem}>
            <Text>{value}</Text>
          </span>
        );
      },
    },
    {
      title: 'Currency',
      dataIndex: 'currency_code',
      key: 'currency_code',
      width: '15%',
      render: (value, record, currentAllocationIndex) => {
        if (record.action && record.action === 'destroy') {
          return {
            props: {
              style: { backgroundColor: CELL_COLORS.red },
            },
            children: (
              <Text delete className={styles.tableItem}>
                {record.currency_code}
              </Text>
            ),
          };
        }

        const updatedAllocation = get(
          filteredClientAudit,
          'dealAllocations',
          [],
        ).find(
          allocation =>
            allocation.auditable_id === record.id &&
            allocation.action === 'update',
        );

        if (updatedAllocation) {
          const { audited_changes } = updatedAllocation;

          if (Object.keys(audited_changes).includes('currency_code')) {
            return {
              props: {
                style: { backgroundColor: CELL_COLORS.yellow },
              },
              children: (
                <span className={styles.tableItem}>
                  <Text>{audited_changes.currency_code[0]}</Text>{' '}
                  <ArrowRightOutlined />{' '}
                  <Text> {audited_changes.currency_code[1]} </Text>
                </span>
              ),
            };
          }
        }

        if (idOfPreviousDeal) {
          const oldAllocationsIndex =
            allocationsGroupsById[idOfPreviousDeal] &&
            allocationsGroupsById[idOfPreviousDeal].findIndex(
              item => item.jira_role_id === record.jira_role_id,
            );
          const oldAllocations =
            allocationsGroupsById[idOfPreviousDeal] &&
            getOldAllocations({
              oldAllocationsIndex,
              currentAllocationIndex,
              allocationsGroupsById,
              idOfPreviousDeal,
            });

          if (oldAllocations) {
            return {
              props: {
                style: {
                  backgroundColor:
                    oldAllocations.currency_code !== value &&
                    CELL_COLORS.yellow,
                },
              },
              children: (
                <>
                  {oldAllocations.currency_code !== value && (
                    <span className={styles.tableItem}>
                      <Text>{oldAllocations.currency_code}</Text>{' '}
                      <ArrowRightOutlined />
                    </span>
                  )}
                  <span className={styles.tableItem}>
                    <Text>{record.currency_code}</Text>
                  </span>
                </>
              ),
            };
          } else {
            return {
              props: {
                style: { backgroundColor: CELL_COLORS.green },
              },
              children: (
                <span className={styles.tableItem}>
                  <Text>{record.currency_code}</Text>
                </span>
              ),
            };
          }
        }

        const currentAllocation = get(
          filteredClientAudit,
          'dealAllocations',
          [],
        ).find(
          allocation =>
            allocation.auditable_id === record.id &&
            allocation.action === 'create',
        );

        if (currentAllocation && idOfPreviousDeal) {
          return {
            props: {
              style: { backgroundColor: CELL_COLORS.green },
            },
            children: (
              <Text className={styles.tableItem}>{record.currency_code}</Text>
            ),
          };
        }

        return <span className={styles.tableItem}>{value}</span>;
      },
    },
    {
      title: 'Hourly rate',
      dataIndex: 'rate',
      key: 'rate',
      width: '20%',
      render: (value, record, currentAllocationIndex) => {
        if (record.action && record.action === 'destroy') {
          return {
            props: {
              style: { backgroundColor: CELL_COLORS.red },
            },
            children: (
              <span className={styles.tableItem}>
                <Text delete>{value}</Text>
              </span>
            ),
          };
        }

        const updatedAllocation = get(
          filteredClientAudit,
          'dealAllocations',
          [],
        ).find(
          allocation =>
            allocation.auditable_id === record.id &&
            allocation.action === 'update',
        );

        if (updatedAllocation) {
          const { audited_changes } = updatedAllocation;

          if (Object.keys(audited_changes).includes('rate')) {
            return {
              props: {
                style: { backgroundColor: CELL_COLORS.yellow },
              },
              children: (
                <span className={styles.tableItem}>
                  <Text>{audited_changes.rate[0]}</Text> <ArrowRightOutlined />{' '}
                  <Text>{audited_changes.rate[1]} </Text>
                </span>
              ),
            };
          }
        }

        if (idOfPreviousDeal) {
          const oldAllocationsIndex =
            allocationsGroupsById[idOfPreviousDeal] &&
            allocationsGroupsById[idOfPreviousDeal].findIndex(
              item => item.jira_role_id === record.jira_role_id,
            );
          const oldAllocations =
            allocationsGroupsById[idOfPreviousDeal] &&
            getOldAllocations({
              oldAllocationsIndex,
              currentAllocationIndex,
              allocationsGroupsById,
              idOfPreviousDeal,
            });

          if (oldAllocations) {
            return {
              props: {
                style: {
                  backgroundColor:
                    oldAllocations.rate !== value && CELL_COLORS.yellow,
                },
              },
              children: (
                <>
                  {oldAllocations.rate !== value && (
                    <span className={styles.tableItem}>
                      <Text> {oldAllocations.rate}</Text> <ArrowRightOutlined />
                    </span>
                  )}
                  <span className={styles.tableItem}>
                    <Text>{value}</Text>
                  </span>
                </>
              ),
            };
          } else {
            return {
              props: {
                style: { backgroundColor: CELL_COLORS.green },
              },
              children: (
                <span className={styles.tableItem}>
                  <Text>{value}</Text>
                </span>
              ),
            };
          }
        }

        const currentAllocation = get(
          filteredClientAudit,
          'dealAllocations',
          [],
        ).find(
          allocation =>
            allocation.auditable_id === record.id &&
            allocation.action === 'create',
        );

        if (currentAllocation && idOfPreviousDeal) {
          return {
            props: {
              style: { backgroundColor: CELL_COLORS.green },
            },
            children: (
              <span className={styles.tableItem}>
                <Text>{value}</Text>
              </span>
            ),
          };
        }

        return (
          <span className={styles.tableItem}>
            <Text>{value}</Text>
          </span>
        );
      },
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
      key: 'condition',
      width: '10%',
      render: (value, record, currentAllocationIndex) => {
        if (record.action && record.action === 'destroy') {
          return {
            props: {
              style: { backgroundColor: CELL_COLORS.red },
            },
            children: (
              <span className={styles.tableItem}>
                <Text delete>{getConditionPreview(value)}</Text>
              </span>
            ),
          };
        }

        const updatedAllocation = get(
          filteredClientAudit,
          'dealAllocations',
          [],
        ).find(
          allocation =>
            allocation.auditable_id === record.id &&
            allocation.action === 'update',
        );

        if (updatedAllocation) {
          const { audited_changes } = updatedAllocation;

          if (Object.keys(audited_changes).includes('condition')) {
            return {
              props: {
                style: { backgroundColor: CELL_COLORS.yellow },
              },
              children: (
                <span className={styles.tableItem}>
                  <Text>
                    {getConditionPreview(audited_changes.condition[0])}
                  </Text>{' '}
                  <ArrowRightOutlined />{' '}
                  <Text>
                    {getConditionPreview(audited_changes.condition[1])}
                  </Text>
                </span>
              ),
            };
          }
        }

        if (idOfPreviousDeal) {
          const oldAllocationsIndex =
            allocationsGroupsById[idOfPreviousDeal] &&
            allocationsGroupsById[idOfPreviousDeal].findIndex(
              item => item.jira_role_id === record.jira_role_id,
            );
          const oldAllocations =
            allocationsGroupsById[idOfPreviousDeal] &&
            getOldAllocations({
              oldAllocationsIndex,
              currentAllocationIndex,
              allocationsGroupsById,
              idOfPreviousDeal,
            });

          if (oldAllocations) {
            return {
              props: {
                style: {
                  backgroundColor:
                    oldAllocations.condition !== value && CELL_COLORS.yellow,
                },
              },
              children: (
                <>
                  {oldAllocations.condition !== value && (
                    <span className={styles.tableItem}>
                      <Text>
                        {getConditionPreview(oldAllocations.condition)}
                      </Text>{' '}
                      <ArrowRightOutlined />
                    </span>
                  )}
                  <span className={styles.tableItem}>
                    <Text>{getConditionPreview(value)}</Text>
                  </span>
                </>
              ),
            };
          } else {
            return {
              props: {
                style: { backgroundColor: CELL_COLORS.green },
              },
              children: (
                <span className={styles.tableItem}>
                  <Text>{getConditionPreview(value)}</Text>
                </span>
              ),
            };
          }
        }

        const currentAllocation = get(
          filteredClientAudit,
          'dealAllocations',
          [],
        ).find(
          allocation =>
            allocation.auditable_id === record.id &&
            allocation.action === 'create',
        );

        if (currentAllocation && idOfPreviousDeal) {
          return {
            props: {
              style: { backgroundColor: CELL_COLORS.green },
            },
            children: (
              <span className={styles.tableItem}>
                <Text>{getConditionPreview(value)}</Text>
              </span>
            ),
          };
        }

        return (
          <span className={styles.tableItem}>
            <Text>{getConditionPreview(value)}</Text>
          </span>
        );
      },
    },
  ];
};

const getTotalHoursPerWeek = data =>
  data.reduce(
    (acc, curr) =>
      curr.action !== 'destroy' ? acc + curr.hours_per_week : acc,
    0,
  );

export const getSummary = ({ filteredAllocations, deal, infoChanges }) => {
  let totalHours = getTotalHoursPerWeek(filteredAllocations);

  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3}>
          <span className={styles.summaryItem}>{totalHours}</span>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={3} index={4}>
          <span className={styles.summaryItem}>
            {deal.fix_price && (
              <span
                style={{ color: 'fix_price' in infoChanges ? GREEN_COLOR : '' }}
              >
                Fixed price{' '}
                <span
                  style={{
                    color: 'fix_price' in infoChanges ? GREEN_COLOR : '',
                  }}
                >
                  {deal.currency_code} {formatNumberWithCommas(deal.fix_price)}
                </span>
              </span>
            )}
          </span>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};

export const getCurrentProjectTitle = ({ jiraProjects, projectId }) => {
  const isJiraProjectsLoaded = isArray(get(jiraProjects, projectId));

  return isJiraProjectsLoaded ? get(jiraProjects, projectId)[0].name : '';
};

export const getFilteredAllocations = ({ allocations, dealAllocations }) => {
  const destroyedDealAllocations =
    dealAllocations?.reduce((acc, curr) => {
      if (curr.action === 'destroy') {
        return [
          ...acc,
          { ...curr.audited_changes, id: curr.auditable_id, action: 'destroy' },
        ];
      }

      return acc;
    }, []) || [];

  return [
    ...allocations.filter(item => !item.deleted_at),
    ...destroyedDealAllocations,
  ];
};

export const getLeftPosition = ({
  type,
  isPreviousDealExisted = false,
  isPreviousDealHasEndDate,
  action = '',
}) => {
  switch (type) {
    case FILTER_TYPES.deal.type:
      return isPreviousDealExisted && !isPreviousDealHasEndDate
        ? '-150px'
        : '-70px';

    case FILTER_TYPES.client.type:
      return '-130px';

    case FILTER_TYPES.clientOrganization.type:
      return '-130px';

    case FILTER_TYPES.clientnote.type:
      return action === AUDIT_ACTIONS.update ? '-140px' : '-130px';

    default:
      return '-75px';
  }
};

export const getMaxWidth = type => {
  switch (type) {
    case FILTER_TYPES.clientOrganization.type:
      return '100px';
    default:
      return 'initial';
  }
};

export const getTagParams = ({
  deal,
  isPreviousDealHasEndDate,
  previousDeal,
  isPreviousDealExisted,
  actions,
  action,
}) => {
  switch (true) {
    case !!deal?.deleted_at:
      return { title: 'Deleted', color: 'red' };

    case isPreviousDealHasEndDate &&
      dayjs(previousDeal.ends_at)
        .add(1, 'days')
        .isBefore(deal.starts_at):
      return { title: 'Deal created', color: 'green' };

    case !isPreviousDealHasEndDate:
      return isPreviousDealExisted
        ? { title: 'Deal extended', color: 'blue' }
        : actions[action];

    case isPreviousDealExisted &&
      isPreviousDealHasEndDate &&
      dayjs(previousDeal.ends_at).isSame(deal.starts_at):
      return { title: 'Deal extended', color: 'blue' };

    default:
      return null;
  }
};
