export const types = {
  GET_CALENDAR: 'calendar/GET_CALENDAR',
  GET_CALENDAR_SUCCESS: 'calendar/GET_CALENDAR_SUCCESS',
  GET_CALENDAR_FAILED: 'calendar/GET_CALENDAR_FAILED',

  CREATE_HOLIDAY: 'calendar/CREATE_HOLIDAY',
  CREATE_HOLIDAY_SUCCESS: 'calendar/CREATE_HOLIDAY_SUCCESS',
  CREATE_HOLIDAY_FAILED: 'calendar/CREATE_HOLIDAY_FAILED',

  UPDATE_HOLIDAY: 'calendar/UPDATE_HOLIDAY',
  UPDATE_HOLIDAY_SUCCESS: 'calendar/UPDATE_HOLIDAY_SUCCESS',
  UPDATE_HOLIDAY_FAILED: 'calendar/UPDATE_HOLIDAY_FAILED',

  DELETE_HOLIDAY: 'calendar/DELETE_HOLIDAY',
  DELETE_HOLIDAY_SUCCESS: 'calendar/DELETE_HOLIDAY_SUCCESS',
  DELETE_HOLIDAY_FAILED: 'calendar/DELETE_HOLIDAY_FAILED',
};
