import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from 'components/Common';
import { hasRights } from 'utils/permissions';
import { PROFILES_PERMISSIONS } from 'containers/ProfilesPage/constants';
import Salaries from './Salaries';
import Comments from './Comments';

import styles from './styles.scss';

const PmDashboard = ({
  salaries,
  role,
  isWard,
  comments,
  onAddComment,
  onCommentDelete,
  onSaveEditedComment,
  authUser,
  employees,
  isLoading,
  currentEmployeeId,
  isSummary,
  isOwnProfile,
}) => {
  const canSeeSalaries =
    hasRights(role, PROFILES_PERMISSIONS.salaries) || isWard || isOwnProfile;

  const canSeeComments =
    hasRights(role, PROFILES_PERMISSIONS.comments) || isWard;

  return (
    <div className={styles.pmDashboard}>
      {canSeeSalaries && <Salaries salaries={salaries} />}
      {canSeeComments && (
        <Loader loading={isLoading}>
          <Comments
            role={role}
            comments={comments}
            isWard={isWard}
            onAddComment={onAddComment}
            onCommentDelete={onCommentDelete}
            onSaveEditedComment={onSaveEditedComment}
            authUser={authUser}
            employees={employees}
            currentEmployeeId={currentEmployeeId}
            isSummary={isSummary}
          />
        </Loader>
      )}
    </div>
  );
};

PmDashboard.propTypes = {
  salaries: PropTypes.array,
  role: PropTypes.string,
  authUser: PropTypes.object,
  comments: PropTypes.array,
  employees: PropTypes.array,
  onAddComment: PropTypes.func,
  onCommentDelete: PropTypes.func,
  onSaveEditedComment: PropTypes.func,
  isLoading: PropTypes.bool,
  currentEmployeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSummary: PropTypes.bool,
  isWard: PropTypes.bool,
  isOwnProfile: PropTypes.bool,
};

export default PmDashboard;
