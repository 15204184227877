import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Table } from 'antd';
import { trim } from 'lodash';
import { getRowKey } from 'utils/helpers';
import ExtraPaymentsForm from '../ExtraPaymentsForm';

import styles from './styles.scss';

const ExtraPaymentsModal = ({
  isVisible,
  onClose,
  isShowExtraPaymentForm,
  onShowExtraPaymentsForm,
  paymentsFieldValues,
  columns,
  dataSource,
  onAddExtraPayment,
  getManagerProfilesHandler,
  isManagerProfilesLoading,
  managerProfiles,
}) => {
  const [form] = Form.useForm();

  const closeHandler = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={600}
      title={`Extra payments (${trim(paymentsFieldValues.projectName)})`}
      open={isVisible}
      onCancel={closeHandler}
      okText="Save"
      cancelText="Cancel"
      destroyOnClose
      maskClosable
      footer={
        isShowExtraPaymentForm
          ? [
              <Button onClick={closeHandler}>Close</Button>,
              <Button
                form="projectProfitsForm"
                key="submit"
                htmlType="submit"
                type="primary"
              >
                Send
              </Button>,
            ]
          : [
              <div className={styles.modalFooter}>
                <Button onClick={onShowExtraPaymentsForm} type="primary">
                  Request
                </Button>
                <Button onClick={closeHandler}>Close</Button>
              </div>,
            ]
      }
    >
      {isShowExtraPaymentForm ? (
        <ExtraPaymentsForm
          fieldValues={paymentsFieldValues}
          onFinish={onAddExtraPayment}
          getManagerProfiles={getManagerProfilesHandler}
          isManagerProfilesLoading={isManagerProfilesLoading}
          managerProfiles={managerProfiles}
          form={form}
        />
      ) : (
        <Table
          rowKey={getRowKey}
          pagination={false}
          bordered
          columns={columns}
          dataSource={dataSource}
          size="small"
        />
      )}
    </Modal>
  );
};

ExtraPaymentsModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  isShowExtraPaymentForm: PropTypes.bool,
  onShowExtraPaymentsForm: PropTypes.func,
  paymentsFieldValues: PropTypes.object,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  onAddExtraPayment: PropTypes.func,
  getManagerProfilesHandler: PropTypes.func,
  isManagerProfilesLoading: PropTypes.bool,
  managerProfiles: PropTypes.array,
};

export default ExtraPaymentsModal;
