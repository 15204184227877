import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Tabs } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { Loader } from 'components/Common';
import ProfileDetails from 'components/ProfilesCommon/ProfileDetails';
import ProfileMain from 'components/ProfilesCommon/ProfileMain';
import {
  authSelector,
  employeesSelector,
  jiraSelector,
  organizationsSelector,
  summaryEmployeesSelector,
  teamsSelector,
  usersSelector,
} from 'redux/selectors';
import {
  getSummaryEmployee,
  getSummaryEmployeeComments,
  getSummaryEmployeeExtraPayments,
  getSummaryEmployees,
  getSummaryEmployeeSalaries,
} from 'redux/SummaryEmployees/actions';
import { getTeams } from 'redux/Teams/actions';
import { getOrganizations } from 'redux/Organizations/actions';
import { getEmployeeProfileData } from 'redux/Employees/actions';
import { loadUsers } from 'redux/Users/actions';
import { getJiraProjects } from 'redux/Jira/actions';
import { SORT_DIRECTIONS } from 'utils/constants';
import { ROUTING } from 'config/routing';

import classNames from 'classnames';
import PageLoader from 'components/Common/PageLoader';
import { useRoleContext } from 'utils/hooks';
import { hasRights } from 'utils/permissions';
import { CLIENT_PERMISSIONS } from 'containers/ClientPage/constants';
import { SUMMARY_EXPAND_PARAMS, SUMMARY_TAB } from './constants';
import {
  getInactiveProfiles,
  getProfilesInfo,
  updateProfilesInfo,
} from './utils';

import styles from './styles.scss';

const SummaryPage = () => {
  const [activeProfileId, setActiveProfileId] = useState(SUMMARY_TAB);
  const [profilesInfo, setProfilesInfo] = useState([]);

  const {
    summaryEmployee,
    summaryEmployees,
    summaryEmployeeSalaries,
    summaryEmployeeComments,
    summaryEmployeeExtraPayments,
    isSummaryEmployeesLoading,
  } = useSelector(summaryEmployeesSelector);
  const { user, isAuthLoading } = useSelector(authSelector);
  const { users, isUsersLoading } = useSelector(usersSelector);
  const { teams, isTeamsLoading } = useSelector(teamsSelector);
  const { jiraProjects, isJiraLoading } = useSelector(jiraSelector);
  const { organizationsFull, isOrganizationsLoading } = useSelector(
    organizationsSelector,
  );
  const {
    employeeComments,
    employeeExtraPayments,
    employeeSalaries,
    isEmployeesLoading,
    isEmployeeProfileDataLoading,
  } = useSelector(employeesSelector);

  const role = useRoleContext();

  const { id } = useParams();

  const dispatch = useDispatch();

  const isPageLoading =
    isEmployeesLoading ||
    isOrganizationsLoading ||
    isJiraLoading ||
    isAuthLoading ||
    isTeamsLoading ||
    isUsersLoading ||
    isSummaryEmployeesLoading;

  useEffect(() => {
    dispatch(
      getSummaryEmployee({
        summaryId: id,
        params: {
          expand: SUMMARY_EXPAND_PARAMS,
        },
      }),
    );
    dispatch(getSummaryEmployees({ size: 9999 }));
    dispatch(getTeams());
    dispatch(loadUsers());
    dispatch(getOrganizations());
    hasRights(role, CLIENT_PERMISSIONS.projects) &&
      dispatch(getJiraProjects({ sort: `name:${SORT_DIRECTIONS.ascend}` }));
    dispatch(getSummaryEmployeeSalaries(id));
    dispatch(getSummaryEmployeeComments(id));
    dispatch(getSummaryEmployeeExtraPayments(id));
  }, [id]);

  useEffect(() => {
    if (activeProfileId !== SUMMARY_TAB) {
      const profile = profilesInfo.find(prof => prof.tabKey == activeProfileId);

      if (!profile.isDataLoaded) {
        dispatch(getEmployeeProfileData(activeProfileId));
      }
    }
  }, [activeProfileId]);

  useEffect(() => {
    if (isSummaryEmployeesLoading || isOrganizationsLoading) return;

    setProfilesInfo(
      getProfilesInfo({
        summaryProfile: summaryEmployee,
        summarySalaries: summaryEmployeeSalaries,
        summaryComments: summaryEmployeeComments,
        summaryExtraPayments: summaryEmployeeExtraPayments,
      }),
    );
  }, [
    summaryEmployee,
    summaryEmployeeSalaries,
    summaryEmployeeComments,
    summaryEmployeeExtraPayments,
    organizationsFull,
  ]);

  const inactiveProfiles = useMemo(() => getInactiveProfiles(profilesInfo), [
    profilesInfo,
  ]);

  useEffect(() => {
    if (isEmployeeProfileDataLoading || activeProfileId === SUMMARY_TAB) return;

    setProfilesInfo(
      updateProfilesInfo({
        profilesInfo,
        comments: employeeComments,
        extraPayments: employeeExtraPayments,
        salaries: employeeSalaries,
        activeProfileId,
      }),
    );
  }, [employeeComments, employeeExtraPayments, employeeSalaries]);

  const onChangeTab = tab => {
    setActiveProfileId(tab);
  };

  const contractInfo = useMemo(() => {
    const currentProfile = profilesInfo.find(
      profile => profile.tabKey == activeProfileId,
    );

    if (!currentProfile) return;

    return {
      contract_starts_at: currentProfile.contract_starts_at,
      contract_ends_at: currentProfile.contract_ends_at,
      is_contract_termless: currentProfile.is_contract_termless,
    };
  }, [profilesInfo, activeProfileId]);

  const isWard = user.summary_ids.includes(+id);
  const isOwnProfile = user.email === summaryEmployee?.email;

  const tabItems = profilesInfo.map(profile => ({
    label: profile.tabTitle,
    key: profile.tabKey,
    children: (
      <Loader loading={isEmployeeProfileDataLoading}>
        <ProfileMain
          profile={profile}
          id={id}
          role={role}
          salaries={profile.salaries}
          comments={profile.comments}
          authUser={user}
          employees={summaryEmployees}
          extraPayments={profile.extraPayments}
          users={users}
          currentEmployeeId={profile.id}
          isLoading={isEmployeeProfileDataLoading}
          projects={jiraProjects}
          attachments={profile.attachments}
          isAttachmentLoading={false}
          isSummary
          isSummaryTab={profile.tabKey === SUMMARY_TAB}
          className={styles.profileMain}
          isWard={isWard}
          isOwnProfile={isOwnProfile}
        />
      </Loader>
    ),
  }));

  const renderTabBar = (props, def) => (
    <div className={styles.summaryTabs}>
      <div className={styles.tabBar}>
        {props.panes.map(pane => (
          <div
            key={pane.props.tabKey}
            onClick={e => props.onTabClick(pane.props.tabKey, e)}
            className={classNames({
              [styles.tab]: true,
              [styles.tabActive]: props.activeKey === pane.props.tabKey,
              [styles.inactiveProfile]: inactiveProfiles[pane.props.tabKey],
            })}
          >
            {pane.props.tab}
          </div>
        ))}
      </div>
    </div>
  );

  if (isPageLoading || !summaryEmployee) return <PageLoader />;

  const breadcrumbItems = [
    {
      title: 'Yellow',
      key: '0',
    },
    {
      title: <Link to={ROUTING.EMPLOYEES_SUMMARY.path}>Summary employees</Link>,
      key: '1',
    },
    {
      title:
        get(summaryEmployee, 'full_name', get(summaryEmployee, 'name_en')) ||
        'User name',
      key: '2',
    },
  ];

  return (
    <div className={styles.summaryPage}>
      <Breadcrumb className={styles.breadcrumbs} items={breadcrumbItems} />
      <div className={styles.content}>
        <Loader loading={isEmployeeProfileDataLoading}>
          <ProfileDetails
            profile={summaryEmployee}
            summaryEmployees={summaryEmployees}
            teams={teams}
            organizations={organizationsFull}
            role={role}
            contractInfo={contractInfo}
            isSummaryTab={activeProfileId === SUMMARY_TAB}
            isSummary
            isWard={isWard}
            employeeTechnologies={summaryEmployee.technologies}
          />
        </Loader>
        <Tabs
          type="card"
          className={styles.tabs}
          activeKey={activeProfileId}
          onChange={onChangeTab}
          items={tabItems}
          renderTabBar={renderTabBar}
        />
      </div>
    </div>
  );
};

export default SummaryPage;
