import dayjs from 'dayjs';
import {
  nFormatter,
  formatNumberWithCommas,
  getMonthNameWithYearShorten,
} from 'utils/helpers';
import { DATE_FORMAT } from 'utils/timeConstants';

export const getDataSource = ({
  clients_last_period = [],
  clients_total = [],
}) =>
  clients_total
    .map(({ client_name, revenue_sum }) => {
      const clientLastPeriod = clients_last_period.find(
        item => item.client_name === client_name,
      );

      return {
        clientName: client_name,
        revenueTotal: revenue_sum,
        revenueLastPeriod: clientLastPeriod?.revenue_sum || null,
      };
    })
    .slice(0, 5)
    .sort((a, b) => b.revenueLastPeriod - a.revenueLastPeriod);

export const getClientConcentrationColumns = period => [
  {
    title: 'Client',
    dataIndex: 'clientName',
    key: 'clientName',
    width: '33%',
  },
  {
    title: getMonthNameWithYearShorten(period[1]),
    dataIndex: 'revenueLastPeriod',
    key: 'revenueLastPeriod',
    width: '33%',
    render: value => formatNumberWithCommas(value),
  },
  {
    title: `${getMonthNameWithYearShorten(
      period[0],
    )} - ${getMonthNameWithYearShorten(period[1])}`,
    dataIndex: 'revenueTotal',
    key: 'revenueTotal',
    width: '33%',
    render: value => formatNumberWithCommas(value),
  },
];

export const getClientConfig = clientData => ({
  appendPadding: 10,
  data: clientData || [],
  angleField: 'revenue_sum',
  colorField: 'client_name',
  radius: 1,
  innerRadius: 0.6,
  label: false,
  tooltip: {
    formatter: datum => ({
      name: datum.client_name,
      value: nFormatter(datum.revenue_sum),
    }),
  },
  statistic: {
    content: {
      customHtml: (container, view, datum, data) =>
        `${formatNumberWithCommas(
          data.reduce((acc, cur) => acc + cur.revenue_sum, 0),
        )}`,
      style: {
        fontSize: '20px',
      },
    },
  },
  interactions: [
    {
      type: 'element-active',
    },
  ],
  legend: {
    position: 'bottom',
    layout: 'horizontal',
    maxRow: 7,
    flipPage: false,
  },
});

export const getFormattedDate = data => dayjs(data).format(DATE_FORMAT);

export const clientDataFilter = data =>
  data
    ?.filter(({ revenue_sum }) => revenue_sum)
    .sort((a, b) => b.revenue_sum - a.revenue_sum) || [];

export const getClientsColors = (data, colors) =>
  data.reduce(
    (acc, curr, i) => ({ ...acc, [curr.client_name]: colors[i] }),
    {},
  );
