exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".clientHeader-34139{padding:24px;border-radius:12px;background-color:#fff;box-shadow:0 4px 20px 0 rgba(112,112,128,.1);display:flex;flex-direction:column;align-items:center;justify-content:center;margin-bottom:24px}.clientHeader-34139 .avatar-8402a{position:relative;margin-bottom:20px;padding:2px;border:2px solid #1677ff}.clientHeader-34139 .avatar-8402a:before{content:\" \";position:absolute;top:0;bottom:0;left:0;right:0;border-radius:50%;border:2px solid #fff}.clientHeader-34139 .summary-7b572{width:100%;text-align:center}.clientHeader-34139 .summaryWithTotal-336c8{margin-bottom:20px;padding-bottom:20px;border-bottom:1px solid #d9d9d9}.clientHeader-34139 .info-9a133{display:flex;align-items:center}.clientHeader-34139 .ownerName-63707,.clientHeader-34139 .location-681ce{display:block;margin-bottom:6px;font-size:16px;line-height:24px;color:#595959}.clientHeader-34139 .location-681ce{margin-bottom:12px}.clientHeader-34139 .title-21207{display:block;margin-bottom:6px;font-size:24px;line-height:32px;font-weight:600}.clientHeader-34139 .tag-b8e56{margin:0;height:100%}", ""]);

// Exports
exports.locals = {
	"clientHeader": "clientHeader-34139",
	"avatar": "avatar-8402a",
	"summary": "summary-7b572",
	"summaryWithTotal": "summaryWithTotal-336c8",
	"info": "info-9a133",
	"ownerName": "ownerName-63707",
	"location": "location-681ce",
	"title": "title-21207",
	"tag": "tag-b8e56"
};