export const types = {
  SET_CURRENT_USER: 'users/SET_CURRENT_USER',

  GET_USERS: 'users/GET_USERS',
  GET_USERS_SUCCESS: 'users/GET_USERS_SUCCESS',
  GET_USERS_FAILED: 'users/GET_USERS_FAILED',

  CREATE_USER: 'users/CREATE_USER',
  CREATE_USER_SUCCESS: 'users/CREATE_USER_SUCCESS',
  CREATE_USER_FAILED: 'users/CREATE_USER_FAILED',

  UPDATE_USER: 'users/UPDATE_USER',
  UPDATE_USER_SUCCESS: 'users/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILED: 'users/UPDATE_USER_FAILED',

  DELETE_USER: 'users/DELETE_USER',
  DELETE_USER_SUCCESS: 'users/DELETE_USER_SUCCESS',
  DELETE_USER_FAILED: 'users/DELETE_USER_FAILED',

  RESTORE_USER: 'users/RESTORE_USER',
  RESTORE_USER_SUCCESS: 'users/RESTORE_USER_SUCCESS',
  RESTORE_USER_FAILED: 'users/RESTORE_USER_FAILED',

  GET_USER_PERMISSIONS: 'users/GET_USER_PERMISSIONS',
  GET_USER_PERMISSIONS_SUCCESS: 'users/GET_USER_PERMISSIONS_SUCCESS',
  GET_USER_PERMISSIONS_FAILED: 'users/GET_USER_PERMISSIONS_FAILED',
};
