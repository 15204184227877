import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isBanksLoading: false,
  banks: [],
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_BANKS:
      return state.set('isBanksLoading', true);

    case types.GET_BANKS_SUCCESS:
      return state.set('isBanksLoading', false).set('banks', payload);

    case types.ADD_BANK_SUCCESS:
    case types.UPDATE_BANK_SUCCESS:
    case types.DELETE_BANK_SUCCESS:
      return state.set('banks', payload);

    default:
      return state;
  }
}
