exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".lead-header-3f86c{box-sizing:border-box;padding:15px;flex-flow:row nowrap;border-bottom:1px solid #ccc;margin-bottom:15px}.lead-summary-1b1bd{flex:1 1 100%;box-sizing:border-box;padding:10px 20px 0}.lead-avatar-c355e,.lead-extra-233b8{flex:0 0 auto;margin-top:10px}.lead-extra-233b8{width:70%}.lead-info-4681e{display:flex;align-items:center;position:relative}.lead-paid-4c434,.lead-pending-25763{display:flex;align-items:center}.title-cb1f2{font-size:24px;font-weight:600}.selectButtonContainer-963cb{position:absolute;right:39px;bottom:-22px;padding:2px;background:#fff}.leadHeader-fc121{padding:24px;border-radius:12px;background-color:#fff;box-shadow:0 4px 20px 0 rgba(112,112,128,.1);display:flex;flex-direction:column;align-items:center;justify-content:center;margin-bottom:24px}.leadHeader-fc121 .avatar-80dc8{position:relative;display:flex;justify-content:center;align-items:center;margin-bottom:20px;padding:2px;border:2px solid #1677ff}.leadHeader-fc121 .avatar-80dc8:before{content:\" \";position:absolute;top:0;bottom:0;left:0;right:0;border-radius:50%;border:2px solid #fff}.leadHeader-fc121 .ownerName-95f66,.leadHeader-fc121 .email-2bdd5{display:block;margin-bottom:6px;font-size:16px;line-height:24px;color:#595959}.leadHeader-fc121 .ownerName-95f66{margin-bottom:12px}.leadHeader-fc121 .title-cb1f2{display:block;margin-bottom:6px;font-size:24px;line-height:32px;font-weight:600}.leadHeader-fc121 .tag-8e5ee{margin:0;height:100%}", ""]);

// Exports
exports.locals = {
	"lead-header": "lead-header-3f86c",
	"lead-summary": "lead-summary-1b1bd",
	"lead-avatar": "lead-avatar-c355e",
	"lead-extra": "lead-extra-233b8",
	"lead-info": "lead-info-4681e",
	"lead-paid": "lead-paid-4c434",
	"lead-pending": "lead-pending-25763",
	"title": "title-cb1f2",
	"selectButtonContainer": "selectButtonContainer-963cb",
	"leadHeader": "leadHeader-fc121",
	"avatar": "avatar-80dc8",
	"ownerName": "ownerName-95f66",
	"email": "email-2bdd5",
	"tag": "tag-8e5ee"
};