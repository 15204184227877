import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { Column } from '@ant-design/charts';
import { getHoursData, getHoursConfig } from './utils';

import styles from './styles.scss';

const { Title } = Typography;

const DashboardSubcontractorsHours = ({ monthData, period }) => {
  const hoursData = useMemo(() => getHoursData(monthData, period), [
    monthData,
    period,
  ]);

  const hoursConfig = useMemo(() => getHoursConfig(hoursData), [hoursData]);

  return (
    <div className={styles.dashboardSubcontractorsOverall}>
      <Title level={5}>Hours</Title>
      <Column {...hoursConfig} />
    </div>
  );
};

DashboardSubcontractorsHours.propTypes = {
  monthData: PropTypes.object.isRequired,
  period: PropTypes.array.isRequired,
};

export default DashboardSubcontractorsHours;
