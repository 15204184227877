import currencyFormatter from 'currency-formatter';

export function getTableColumns() {
  return [
    {
      title: 'Month',
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: 'Amount BYN',
      dataIndex: 'paid_salary_byn',
      key: 'paid_salary_byn',
      render: value =>
        currencyFormatter.format(value, {
          code: 'BYR',
        }),
    },
    {
      title: 'Amount USD',
      dataIndex: 'paid_salary_usd',
      key: 'paid_salary_usd',
      render: value =>
        currencyFormatter.format(value, {
          code: 'USD',
        }),
    },
  ];
}
