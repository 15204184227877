import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isUserLoading: true,
  isLogged: true,
  user: null,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.LOGIN:
    case types.GET_USER:
      return state
        .set('isLogged', false)
        .set('isUserLoading', true)
        .set('user', null);
    case types.LOGOUT:
      return state
        .set('isLogged', false)
        .set('user', null)
        .set('isUserLoading', false);

    case types.LOGIN_SUCCESS:
    case types.GET_USER_SUCCESS:
      return state
        .set('isLogged', true)
        .set('isUserLoading', false)
        .set('user', payload);

    case types.LOGIN_FAILED:
    case types.GET_USER_FAILED:
      return state.set('isLogged', false).set('isUserLoading', false);

    default:
      return state;
  }
}
