import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import styles from './styles.scss';

const DashboardSalaryTable = ({
  columns,
  tableData,
  summary,
  onTableChange,
  pagination,
}) => (
  <div className={styles.dashboardSalaryTable}>
    <Table
      dataSource={tableData}
      pagination={{ ...pagination, showSizeChanger: true }}
      columns={columns}
      summary={summary}
      onChange={onTableChange}
      rowKey={record => record.employee_id}
    />
  </div>
);

DashboardSalaryTable.propTypes = {
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  summary: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  onTableChange: PropTypes.func.isRequired,
};

export default DashboardSalaryTable;
