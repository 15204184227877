import React, { useState, useEffect, useMemo } from 'react';
import { Modal as AntdModal, Row, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import PageHeader from 'components/Common/PageHeader';
import { UserForm } from 'components/UsersCommon';
import Modal from 'components/Common/Modal';
import TableWithSettings from 'components/Common/TableWithSettings';
import { Button } from 'components/Common';
import { SORT_DIRECTIONS } from 'utils/constants';
import { getRowKey, groupBy } from 'utils/helpers';
import { rolesSelector, usersSelector } from 'redux/selectors';
import { getRoles } from 'redux/Roles/actions';
import {
  updateUser,
  createUser,
  deleteUser,
  loadUsers,
  restoreUser,
} from 'redux/Users/actions';

import { getTableColumns } from './utils';
import { USERS_EXPAND } from './constants';

import styles from './styles.scss';

const { confirm } = AntdModal;

const UsersList = () => {
  const { users, isUsersLoading, totalUsers } = useSelector(usersSelector);
  const { roles } = useSelector(rolesSelector);

  const [currentUser, setCurrentUser] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    order: SORT_DIRECTIONS.ascend,
    field: 'id',
  });
  const [paginationInfo, setPaginationInfo] = useState({
    pageSize: 25,
    current: 1,
    total: totalUsers,
  });

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const defaultRequest = {
    sort: `${sortInfo.field}:${sortInfo.order}`,
    size: paginationInfo.pageSize,
    page: paginationInfo.current,
    expand: USERS_EXPAND,
  };

  useEffect(() => {
    dispatch(loadUsers(defaultRequest));
    dispatch(getRoles());
  }, []);

  useEffect(() => {
    setPaginationInfo(prev => ({
      ...prev,
      total: totalUsers,
    }));
  }, [totalUsers]);

  const rolesGroupedById = useMemo(
    () => groupBy({ data: roles, by: 'id', field: 'name' }),
    [roles],
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    setCurrentUser(null);
    form.resetFields();
  };

  const onSubmitForm = values => {
    if (currentUser) {
      dispatch(
        updateUser({
          id: currentUser.id,
          data: { ...values, expand: USERS_EXPAND },
        }),
      );
      form.resetFields();
      setCurrentUser(null);
      hideModal();

      return;
    }

    dispatch(createUser({ data: { ...values } }));
    hideModal();
  };

  const onDeleteUser = id => {
    confirm({
      title: 'Remove user',
      content: 'Are you sure you want to remove this user?',
      onOk() {
        dispatch(deleteUser({ id, ...defaultRequest }));
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSortInfo(sorter);
    setPaginationInfo(pagination);
    dispatch(
      loadUsers({
        sort: `${sorter.field}:${sorter.order}`,
        size: pagination.pageSize,
        page: pagination.current,
        expand: ['permissions'],
      }),
    );
  };

  const onRestoreUser = id => {
    dispatch(restoreUser({ id, ...defaultRequest }));
  };

  const getRowClassName = record => record.deleted_at && styles.deletedUserRow;

  const onEdit = record => {
    setCurrentUser(record);
    showModal();
  };

  const tableColumns = getTableColumns({
    onDelete: onDeleteUser,
    onRestore: onRestoreUser,
    onEdit,
    sortInfo,
    roles: rolesGroupedById,
  });

  return (
    <>
      <Modal
        title={currentUser ? 'Edit user' : 'Add user'}
        open={isModalVisible}
        onCancel={hideModal}
        onOk={form.submit}
        okText={currentUser ? 'Save' : 'Add'}
        cancelText="Cancel"
        maskClosable
        confirmLoading={isUsersLoading}
        destroyOnClose
        width={600}
      >
        <UserForm
          form={form}
          currentUser={currentUser}
          roles={roles}
          onSubmit={onSubmitForm}
        />
      </Modal>
      <PageHeader
        title="Users"
        extra={
          <Row type="flex">
            <Button
              onClick={showModal}
              type="primary"
              style={{ marginLeft: 16 }}
            >
              Add user
            </Button>
          </Row>
        }
      />
      <TableWithSettings
        pagination={paginationInfo}
        dataSource={users}
        rowKey={getRowKey}
        columns={tableColumns}
        loading={isUsersLoading}
        onChange={handleTableChange}
        rowClassName={getRowClassName}
      />
    </>
  );
};

export default UsersList;
