import { Tag } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { TableSearchProps } from 'components/TableSearchCommon';
import { LINKS } from 'config/routing';
import { getFilteredValue, getSortOrder } from 'utils/helpers';
import { TEAMS } from 'utils/constants';

import { SUMMARY_STATUSE_COLORS, SUMMARY_TABLE_COMMON } from './constants';
import styles from './styles.scss';

const renderCellAsLink = (value, record) => (
  <Link to={LINKS.summaryEmployee(record.id)}>{value}</Link>
);

const renderStatus = value => (
  <Tag color={SUMMARY_STATUSE_COLORS[value]}>{value}</Tag>
);

const getFilters = filters =>
  (filters &&
    Object.entries(filters).map(([value, text]) => ({
      text,
      value: value.trim() ? value : 0,
    }))) ||
  [];

const getStatusFilters = filters =>
  filters?.map(filter => ({
    text: filter,
    value: filter,
  })) || [];

export const getColumns = ({ filters, sortInfo, filterInfo, searchInput }) => [
  {
    title: SUMMARY_TABLE_COMMON.name_en_title,
    dataIndex: SUMMARY_TABLE_COMMON.name_en,
    filteredValue: getFilteredValue({
      field: SUMMARY_TABLE_COMMON.name_en,
      filterInfo,
    }),
    ...TableSearchProps({
      dataIndex: SUMMARY_TABLE_COMMON.name_en,
      searchInput,
    }),
    sorter: true,
    sortOrder: getSortOrder({
      field: SUMMARY_TABLE_COMMON.name_en,
      fieldType: 'field',
      sortInfo,
    }),
    render: renderCellAsLink,
  },
  {
    title: SUMMARY_TABLE_COMMON.position_title,
    dataIndex: SUMMARY_TABLE_COMMON.position,
    sorter: true,
    sortOrder: getSortOrder({
      field: SUMMARY_TABLE_COMMON.position,
      fieldType: 'field',
      sortInfo,
    }),
    filteredValue: getFilteredValue({
      field: SUMMARY_TABLE_COMMON.position,
      filterInfo,
    }),
    filters: getFilters(filters.employee_positions),
    render: (value, record) => value && renderCellAsLink(value.title, record),
  },
  {
    title: SUMMARY_TABLE_COMMON.location_title,
    dataIndex: SUMMARY_TABLE_COMMON.location,
    sorter: true,
    sortOrder: getSortOrder({
      field: SUMMARY_TABLE_COMMON.location,
      fieldType: 'field',
      sortInfo,
    }),
    render: renderCellAsLink,
  },
  {
    title: SUMMARY_TABLE_COMMON.rm_title,
    dataIndex: SUMMARY_TABLE_COMMON.rm,
    sorter: true,
    sortOrder: getSortOrder({
      field: SUMMARY_TABLE_COMMON.rm,
      fieldType: 'field',
      sortInfo,
    }),
    filteredValue: getFilteredValue({
      field: SUMMARY_TABLE_COMMON.rm,
      filterInfo,
    }),
    filters: getFilters(filters.resource_managers),
    render: (value, record) => value && renderCellAsLink(value.name_en, record),
  },
  {
    title: SUMMARY_TABLE_COMMON.buddy_title,
    dataIndex: SUMMARY_TABLE_COMMON.buddy,
    render: (value, record) => {
      const block = (
        <div className={styles.buddyCell}>
          {value?.map(buddy => (
            <span key={buddy.id}>{buddy.name_en || buddy.name_ru}</span>
          ))}
        </div>
      );

      return renderCellAsLink(block, record);
    },
  },
  {
    title: SUMMARY_TABLE_COMMON.email_title,
    dataIndex: SUMMARY_TABLE_COMMON.email,
    sorter: true,
    sortOrder: getSortOrder({
      field: SUMMARY_TABLE_COMMON.email,
      fieldType: 'field',
      sortInfo,
    }),
    render: renderCellAsLink,
  },
  {
    title: SUMMARY_TABLE_COMMON.team_title,
    dataIndex: SUMMARY_TABLE_COMMON.team,
    sorter: true,
    sortOrder: getSortOrder({
      field: SUMMARY_TABLE_COMMON.team,
      fieldType: 'field',
      sortInfo,
    }),
    filteredValue: getFilteredValue({
      field: SUMMARY_TABLE_COMMON.team,
      filterInfo,
    }),
    filters: getFilters(TEAMS),
    render: (value, record) => renderCellAsLink(TEAMS[value], record),
  },
  {
    title: SUMMARY_TABLE_COMMON.status_title,
    dataIndex: SUMMARY_TABLE_COMMON.status,
    align: 'center',
    filteredValue: getFilteredValue({
      field: SUMMARY_TABLE_COMMON.status,
      filterInfo,
    }),
    filters: getStatusFilters(filters.statuses),
    sorter: true,
    sortOrder: getSortOrder({
      field: SUMMARY_TABLE_COMMON.status,
      fieldType: 'field',
      sortInfo,
    }),
    render: renderStatus,
  },
];
