import React from 'react';
import { Result } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Button } from 'components/Common';

import styles from './styles.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    Sentry.captureException(error);

    return { hasError: true };
  }

  backHome = () => {
    this.setState({
      hasError: false,
    });
  };

  componentDidMount() {
    const { history } = this.props;

    history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Button type="primary" onClick={this.backHome}>
              <Link to="/resource-management" className={styles.backButton}>
                Back Home
              </Link>
            </Button>
          }
        />
      );
    } else {
      return this.props.children;
    }
  }
}

export default withRouter(ErrorBoundary);
