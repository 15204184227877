export const FILTER_TYPES = {
  deal: { type: 'Deal', title: 'Deals' },
  clientnote: { type: 'ClientNote', title: 'Notes' },
  invoice: { type: 'Invoice', title: 'Invoices' },
  all: { type: 'All', title: 'All' },
  dealAllocations: { type: 'DealAllocation', title: 'DealAllocation' },
  client: { type: 'Client', title: 'Client' },
  clientOrganization: {
    type: 'ClientOrganization',
    title: 'ClientOrganization',
  },
};

export const AUDIT_ACTIONS = {
  create: 'create',
  update: 'update',
  destroy: 'destroy',
};

export const TIMELINE_FILTERS = [
  FILTER_TYPES.all.type,
  FILTER_TYPES.deal.type,
  FILTER_TYPES.invoice.type,
  FILTER_TYPES.clientnote.type,
];
