/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import { getPopupContainerHandler } from 'utils/helpers';

import styles from '../styles.scss';
import { WORKLOGS_EDITABLE_FIELDS } from '../constants';

const { Option } = Select;

const InvoiceWorklogWorkerCell = ({
  onClick,
  inputType,
  value,
  record,
  editableCell,
  setEditableCell,
  onCellBlur,
  onSearchJiraUsers,
  searchedJiraUsers,
  isJiraLoading,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const onChangeWorker = worker => {
    setInputValue(worker);
  };

  const onCellClick = () => {
    onClick({ record, inputType });
  };

  const onBlur = () => {
    if (inputValue === value) {
      setEditableCell(null);

      return;
    }

    onCellBlur(inputValue);
  };

  if (
    editableCell &&
    editableCell.worklog_id === record.worklog_id &&
    WORKLOGS_EDITABLE_FIELDS.worker === editableCell.inputType &&
    WORKLOGS_EDITABLE_FIELDS.worker === inputType
  ) {
    return (
      <Select
        style={{ width: '100%' }}
        onSearch={onSearchJiraUsers}
        showSearch
        loading={isJiraLoading}
        filterOption={false}
        getPopupContainer={getPopupContainerHandler}
        value={inputValue}
        onChange={onChangeWorker}
        onBlur={onBlur}
        autoFocus
      >
        {searchedJiraUsers.map(jiraUser => (
          <Option value={jiraUser.key} key={jiraUser.key}>
            {jiraUser.displayName}
          </Option>
        ))}
      </Select>
    );
  }

  return (
    <div
      onClick={onCellClick}
      role="cell"
      className={styles.worklogsEditableCell}
    >
      {value}
    </div>
  );
};

InvoiceWorklogWorkerCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  editableCell: PropTypes.object,
  setEditableCell: PropTypes.func,
  onCellBlur: PropTypes.func.isRequired,
  onSearchJiraUsers: PropTypes.func,
  searchedJiraUsers: PropTypes.array,
  isJiraLoading: PropTypes.bool,
};

export default InvoiceWorklogWorkerCell;
