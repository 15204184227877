/* eslint-disable indent */
import React from 'react';
import { Tooltip, Upload } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { get } from 'lodash';

import {
  GREEN_STATUSES,
  RED_STATUSES,
  GRAY_STATUSES,
  YELLOW_STATUSES,
} from 'components/ClientCommon/ClientTimeline/TimelineItems/InvoiceItem/constants';
import { TableSearchProps } from 'components/TableSearchCommon/';
import AvatarWithName from 'components/AvatarWithName';
import { getInitials } from 'utils/getInitials';
import { hasRights } from 'utils/permissions';
import { ICONS_COLORS } from 'utils/constants';
import { DATE_FORMAT, DATE_MONTH_FORMAT, D_M_Y } from 'utils/timeConstants';
import TableButton from 'components/Common/TableButton';
import Tag from 'components/Common/Tag';
import {
  CONFIRM_BLOCKING_STATUSES,
  DECLINE_BLOCKING_STATUSES,
  INVOICES_INDEX,
  INVOICES_PERMISSIONS,
} from './constants';
import styles from './styles.scss';

const getStatusTag = status => (
  <Tag
    color={
      (RED_STATUSES.includes(status) && 'red') ||
      (GRAY_STATUSES.includes(status) && 'default') ||
      (GREEN_STATUSES.includes(status) && 'green') ||
      (YELLOW_STATUSES.includes(status) && 'gold')
    }
  >
    {status.replace(/_/g, ' ')}
  </Tag>
);

const getFilters = filters =>
  filters?.map(({ name, id }) => ({ text: name, value: id })) || [];

const getUserAndEmployee = ({ employees, groupUsersById, value }) => {
  const currentUser = groupUsersById[value];

  const currentEmployee =
    employees.find(
      item =>
        get(item, ['summary_employee', 'jira_user', 'displayName']) ===
          currentUser?.name ||
        get(item, ['summary_employee', 'email']) === currentUser?.email,
    ) || [];

  const userName = currentUser?.name || currentUser?.email || '-';

  return { userName, currentEmployee, currentUser };
};

const filteredValue = (valueType, filterInfo) =>
  (filterInfo && filterInfo[valueType]) || null;

const formatNumberWithCommas = value =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const getSortOrder = (sortInfo, condition) =>
  sortInfo.columnKey === condition && sortInfo.order;

export const getColumns = ({
  groupUsersById,
  groupClientsById,
  filtersInfo,
  role,
  onApproveInvoice,
  onDeclineInvoice,
  organizationdsGroupById,
  onVisibleEditModal,
  invoiceStatuses,
  sortInfo,
  searchInput,
  employees,
  filters,
}) => [
  {
    title: INVOICES_INDEX.client_id_title,
    dataIndex: INVOICES_INDEX.client_id,
    key: INVOICES_INDEX.client_title,
    sorter: true,
    filterSearch: true,
    filteredValue: filteredValue(INVOICES_INDEX.client_id, filtersInfo),
    sortOrder: getSortOrder(sortInfo, INVOICES_INDEX.client_title),
    filters: getFilters(filters.clients),
    render: value => (
      <Link to={`/clients/${value}`}>{groupClientsById[value]?.title}</Link>
    ),
  },
  {
    title: INVOICES_INDEX.number_title,
    dataIndex: INVOICES_INDEX.number,
    key: INVOICES_INDEX.number,
    sorter: true,
    sortOrder: getSortOrder(sortInfo, INVOICES_INDEX.number),
    filteredValue: filteredValue(INVOICES_INDEX.number, filtersInfo),
    ...TableSearchProps({
      dataIndex: INVOICES_INDEX.number,
      searchInput,
    }),
  },
  {
    title: INVOICES_INDEX.contractor_title,
    dataIndex: INVOICES_INDEX.contractor_id,
    key: INVOICES_INDEX.contractor_name,
    filterSearch: true,
    sorter: true,
    width: 80,
    filters: getFilters(filters.contractors),
    filteredValue: filteredValue(INVOICES_INDEX.contractor_id, filtersInfo),
    sortOrder: getSortOrder(sortInfo, INVOICES_INDEX.contractor_name),
    render: value => {
      const currentOrganization = organizationdsGroupById[value];

      <Tooltip
        getPopupContainer={e => e.parentElement || e}
        title={dayjs(value).format(D_M_Y)}
      >
        {currentOrganization ? (
          <span>{currentOrganization.friendly_name}</span>
        ) : (
          value
        )}
      </Tooltip>;
    },
  },
  {
    title: INVOICES_INDEX.author_title,
    dataIndex: INVOICES_INDEX.owner_id,
    key: INVOICES_INDEX.author_name,
    sorter: true,

    filters: getFilters(filters.authors),
    filterSearch: true,
    filteredValue: filteredValue(INVOICES_INDEX.owner_id, filtersInfo),
    sortOrder: getSortOrder(sortInfo, INVOICES_INDEX.author_name),
    render: (value, record) => {
      const { userName, currentEmployee, currentUser } = getUserAndEmployee({
        groupUsersById,
        value,
        employees,
      });

      return currentUser ? (
        <AvatarWithName
          payload={getInitials(userName)}
          avatarUrl={
            userName !== '-' &&
            get(
              currentEmployee,
              ['jira_user', 'avatarUrls', '32x32'],
              'https://www.gravatar.com/avatar/e5ffa8aecb9a36a76676407d8bfd1d36?d=mm&s=32',
            )
          }
          title={userName}
        />
      ) : (
        record.owner_name
      );
    },
  },
  {
    title: INVOICES_INDEX.reviewer_title,
    dataIndex: INVOICES_INDEX.reviewer_id,
    key: INVOICES_INDEX.reviewer_name,
    sorter: true,
    filterSearch: true,
    sortOrder: getSortOrder(sortInfo, INVOICES_INDEX.reviewer_name),
    filters: getFilters(filters.reviewers),
    filteredValue: filteredValue(INVOICES_INDEX.reviewer_id, filtersInfo),
    render: (value, record) => {
      const currentUser = groupUsersById[value];

      const currentEmployee =
        employees.find(
          item =>
            get(item, ['summary_employee', 'jira_user', 'displayName']) ===
              currentUser?.name ||
            get(item, ['summary_employee', 'email']) === currentUser?.email,
        ) || [];

      const userName = currentUser?.name || currentUser?.email || '-';

      return currentUser ? (
        <AvatarWithName
          payload={getInitials(userName)}
          avatarUrl={
            userName !== '-' &&
            get(
              currentEmployee,
              ['jira_user', 'avatarUrls', '32x32'],
              'https://www.gravatar.com/avatar/e5ffa8aecb9a36a76676407d8bfd1d36?d=mm&s=32',
            )
          }
          title={userName}
        />
      ) : (
        record.reviewer_name
      );
    },
  },
  {
    title: INVOICES_INDEX.amount_title,
    dataIndex: INVOICES_INDEX.amount,
    key: INVOICES_INDEX.amount,
    sorter: true,
    sortOrder: getSortOrder(sortInfo, INVOICES_INDEX.amount),
    render: (value, record) => (
      <span>
        {record.currency_code}{' '}
        {formatNumberWithCommas(Number(value).toFixed(2))}
      </span>
    ),
  },
  {
    title: INVOICES_INDEX.attachments_title,
    dataIndex: INVOICES_INDEX.attachments,
    key: INVOICES_INDEX.attachments,
    width: 130,
    render: attachments => {
      const fileList = attachments
        ? attachments.map(attachment => ({
            name: attachment.title,
            status: 'done',
            url: attachment.url || '',
            uid: `fileTable__${attachment.title}`,
          }))
        : [];

      return (
        <div style={{ width: '120px' }}>
          <Upload
            showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
            fileList={fileList}
            className={styles.attachments}
          />
        </div>
      );
    },
  },
  {
    title: INVOICES_INDEX.status_title,
    dataIndex: INVOICES_INDEX.status,
    key: INVOICES_INDEX.status,
    filterSearch: true,
    sorter: true,
    sortOrder: getSortOrder(sortInfo, INVOICES_INDEX.status),
    filters: invoiceStatuses.map(item => ({
      text: item.replace(/_/g, ' '),
      value: item,
    })),
    filteredValue: filteredValue(INVOICES_INDEX.status, filtersInfo),
    render: value => getStatusTag(value),
  },
  {
    title: INVOICES_INDEX.sent_title,
    dataIndex: INVOICES_INDEX.sent_at,
    key: INVOICES_INDEX.sent_at,
    render: value => (value ? dayjs(value).format(D_M_Y) : '-'),
    isShowColumn: false,
  },
  {
    title: INVOICES_INDEX.issue_title,
    dataIndex: INVOICES_INDEX.issue_date,
    key: INVOICES_INDEX.issue_date,
    sorter: true,

    sortOrder: getSortOrder(sortInfo, INVOICES_INDEX.issue_date),
    render: value => (
      <Tooltip
        getPopupContainer={e => e.parentElement || e}
        title={dayjs(value).format(DATE_MONTH_FORMAT)}
      >
        <div className={styles.dateCell}>
          <span className={styles.lessFontSize}>
            {dayjs(value).format(DATE_MONTH_FORMAT)}
          </span>
        </div>
      </Tooltip>
    ),
  },
  {
    title: INVOICES_INDEX.reviewed_title,
    dataIndex: INVOICES_INDEX.reviewed_at,
    key: INVOICES_INDEX.reviewed_at,
    sorter: true,
    sortOrder: getSortOrder(sortInfo, INVOICES_INDEX.reviewed_at),
    render: value => (
      <span className={styles.lessFontSize}>
        {value ? dayjs(value).format(DATE_MONTH_FORMAT) : '-'}
      </span>
    ),
  },
  {
    title: INVOICES_INDEX.created_title,
    dataIndex: INVOICES_INDEX.created_at,
    key: INVOICES_INDEX.created_at,
    isShowColumn: false,
    sorter: true,

    sortOrder: getSortOrder(sortInfo, INVOICES_INDEX.created_at),
    render: value => (
      <span className={styles.lessFontSize}>
        {value ? dayjs(value).format(DATE_MONTH_FORMAT) : '-'}
      </span>
    ),
  },
  {
    title: INVOICES_INDEX.updated_title,
    dataIndex: INVOICES_INDEX.updated_at,
    key: INVOICES_INDEX.updated_at,
    sorter: true,
    // width: 100,
    sortOrder: getSortOrder(sortInfo, INVOICES_INDEX.updated_at),
    render: value => (
      <Tooltip
        getPopupContainer={e => e.parentElement || e}
        title={dayjs(value).format(DATE_MONTH_FORMAT)}
      >
        <div className={styles.dateCell}>
          <span className={styles.lessFontSize}>
            {value ? dayjs(value).format(DATE_MONTH_FORMAT) : '-'}
          </span>
        </div>
      </Tooltip>
    ),
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    hidden: !hasRights(role, INVOICES_PERMISSIONS.declineApprove),
    render: (value, record) => (
      <div className={styles.actionButtons}>
        <TableButton
          type="primary"
          disabled={CONFIRM_BLOCKING_STATUSES.includes(record.status)}
          className={
            CONFIRM_BLOCKING_STATUSES.includes(record.status)
              ? styles.invoiceButtonDisabled
              : styles.invoiceButtonActive
          }
          onClick={() => onApproveInvoice({ id: record.id })}
          icon={<CheckOutlined />}
        />
        <TableButton
          className={
            DECLINE_BLOCKING_STATUSES.includes(record.status)
              ? styles.declineButtonDisabled
              : styles.declineButtonActive
          }
          disabled={DECLINE_BLOCKING_STATUSES.includes(record.status)}
          danger
          onClick={() => onDeclineInvoice({ id: record.id })}
          icon={<CloseOutlined />}
        />
      </div>
    ),
  },
  {
    title: '',
    dataIndex: 'more',
    key: 'more',
    align: 'center',
    render: (_, record) => (
      <TableButton
        icon={<EllipsisOutlined />}
        style={{
          borderColor: ICONS_COLORS.ellipseOutlined.border,
          color: ICONS_COLORS.ellipseOutlined.color,
        }}
        onClick={() => onVisibleEditModal(record)}
      />
    ),
  },
];

export const getStartAndEndOfMonth = month => {
  const dateFrom = dayjs(month)
    .clone()
    .startOf('month')
    .format(DATE_FORMAT);
  const dateTo = getEndOfMonth(month);

  return [dayjs(dateFrom), dayjs(dateTo)];
};

export const getEndOfMonth = month =>
  dayjs(month)
    .clone()
    .endOf('month')
    .format(DATE_FORMAT);
