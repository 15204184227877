/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { DownOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import styles from './styles.scss';

const DashboardDropdown = ({
  overlay,
  values,
  setValues,
  title,
  visible,
  setVisible,
}) => {
  const onVisibleChange = isVisible => {
    setVisible(isVisible);

    if (!isVisible) {
      setValues(prev => ({
        ...prev,
        selected: [],
      }));

      return;
    }

    setValues(prev => ({
      ...prev,
      selected: prev.applied,
    }));
  };

  const filtersCounter =
    !!values.applied.length && `(${values.applied.length})`;

  return (
    <Dropdown
      dropdownRender={overlay}
      trigger={['click']}
      open={visible}
      onOpenChange={onVisibleChange}
    >
      <div className={styles.dashboardDropdown}>
        <div className={styles.content}>
          <span
            className={classNames({
              [styles.appliedFilter]: !!values.applied.length,
            })}
          >
            {title} {filtersCounter}
          </span>
          <DownOutlined className={styles.icon} />
        </div>
      </div>
    </Dropdown>
  );
};

DashboardDropdown.propTypes = {
  overlay: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default DashboardDropdown;
