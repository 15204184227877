exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".leadsReport-4e0c6{position:relative;display:flex;flex-direction:column;justify-content:center;align-items:center;width:17%;height:100%;margin-left:50px}.syncIconContainer-c818d{position:absolute;right:25px;top:25px}.leadReportList-1e11f{width:100%;padding:0;list-style:none}.leadsContainer-15ad5{display:flex;justify-content:space-around}.leadsContent-5e1f6{background-color:#fff;box-shadow:0 0 3px #ccc;border-radius:4px;margin-left:25px}.leadsContentHeader-755e8{display:flex}.itemTitle-54351{display:flex;align-items:center}.leadsCard-d1bac{width:25%;padding:15px;border:1px solid rgba(0,0,0,.05)}.leadsCardTitle-9f26d{display:flex;align-items:center;font-weight:600}.leadsCardValue-488b0{margin-bottom:8px;font-weight:600;font-size:18px}.leadsCardDescription-7f2e1{color:rgba(0,0,0,.5);font-size:.88rem}.leadsTimeline-9427a{margin-left:25%;margin-top:15px}", ""]);

// Exports
exports.locals = {
	"leadsReport": "leadsReport-4e0c6",
	"syncIconContainer": "syncIconContainer-c818d",
	"leadReportList": "leadReportList-1e11f",
	"leadsContainer": "leadsContainer-15ad5",
	"leadsContent": "leadsContent-5e1f6",
	"leadsContentHeader": "leadsContentHeader-755e8",
	"itemTitle": "itemTitle-54351",
	"leadsCard": "leadsCard-d1bac",
	"leadsCardTitle": "leadsCardTitle-9f26d",
	"leadsCardValue": "leadsCardValue-488b0",
	"leadsCardDescription": "leadsCardDescription-7f2e1",
	"leadsTimeline": "leadsTimeline-9427a"
};