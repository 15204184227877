export const getSalaryData = data =>
  data &&
  Object.entries(data).map(([month, monthData]) => ({
    ...monthData,
    month,
  }));

export const getTooltipWidth = roles => {
  if (!roles) return 250;

  const length = roles.length;

  switch (true) {
    case length <= 6:
      return 250;
    case length <= 12:
      return 500;
    case length <= 18:
      return 750;
    case length <= 24:
      return 1000;
    default:
      return 250;
  }
};
