import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { get } from 'lodash';
import { ArrowRightOutlined } from '@ant-design/icons';

import styles from './styles.scss';

const { Text } = Typography;

const LeadOwner = ({ event, usersObject }) => {
  const { audited_changes } = event;
  const isPreviosOwnerExisted = Boolean(audited_changes.owner_id[0]);
  const [previousOwnerId, currentOwnerId] = audited_changes.owner_id;

  const previousOwner = usersObject[previousOwnerId];
  const currentOwner = usersObject[currentOwnerId];

  const currentOwnerName =
    get(currentOwner, 'name') || get(currentOwner, 'email');

  const previousOwnerName =
    get(previousOwner, 'name') || get(previousOwner, 'email');

  return (
    <div>
      <Text strong>Lead owner updated</Text>
      {isPreviosOwnerExisted ? (
        <div className={styles.previousOwner}>
          <span>{previousOwnerName}</span>{' '}
          <ArrowRightOutlined style={{ margin: '0px 5px' }} />{' '}
          <span>{currentOwnerName}</span>
        </div>
      ) : (
        <div>{currentOwnerName}</div>
      )}
    </div>
  );
};

LeadOwner.propTypes = {
  event: PropTypes.object,
  usersObject: PropTypes.object,
};

export default LeadOwner;
