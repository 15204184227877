import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import PropTypes from 'prop-types';

// @ts-ignore
import styles from './styles.scss';
import { formatMoney } from '../utils';
import { EDITABLE_ITEMS_STYLE } from './constants';

const EditableCell = ({ initialValue, onShowModal, project }) => {
  const [isHover, setHover] = useState(false);

  const onChangeHover = value => e => {
    e.preventDefault();
    e.stopPropagation();
    setHover(value);
  };

  return project.adjustment ? (
    <Popover content={project.adjustment_comment}>
      <div
        onMouseEnter={onChangeHover(true)}
        onMouseLeave={onChangeHover(false)}
        className={styles.editableCellWrapper}
      >
        {' '}
        {!project.isChildren && (
          <div className={styles.editableCellEdit}>
            <div style={EDITABLE_ITEMS_STYLE}>
              <span
                style={{ color: initialValue >= 0 ? '#237804' : '#a8071a' }}
              >
                {!project.adjustment ? '' : formatMoney(initialValue)}
              </span>
            </div>
            {isHover && (
              <Button
                style={EDITABLE_ITEMS_STYLE}
                type="link"
                onClick={onShowModal}
              >
                Edit
              </Button>
            )}
          </div>
        )}
      </div>
    </Popover>
  ) : (
    <div
      onMouseEnter={onChangeHover(true)}
      onMouseLeave={onChangeHover(false)}
      className={styles.editableCellWrapper}
    >
      {' '}
      {!project.isChildren && (
        <div className={styles.editableCellEdit}>
          <div style={EDITABLE_ITEMS_STYLE}>
            <span style={{ color: initialValue >= 0 ? '#237804' : '#a8071a' }}>
              {!project.adjustment ? '' : formatMoney(initialValue)}
            </span>
          </div>
          {isHover && (
            <Button
              style={EDITABLE_ITEMS_STYLE}
              type="link"
              onClick={onShowModal}
            >
              Edit
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

EditableCell.propTypes = {
  initialValue: PropTypes.number.isRequired,
  onShowModal: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

export default EditableCell;
