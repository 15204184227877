import React from 'react';
import PropTypes from 'prop-types';

// @ts-ignore
import { Tooltip } from 'antd';
import styles from './styles.scss';
import { formatMoney } from '../utils';

const MoneyCell = ({ value, style = {} }) => (
  <div style={style} className={styles.moneyCellWrapper}>
    <Tooltip arrow={false} title={formatMoney(value)}>
      <div className={styles.moneyCell}>{formatMoney(value)}</div>
    </Tooltip>
  </div>
);
MoneyCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
};

export default MoneyCell;
