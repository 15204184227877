import React from 'react';
import { Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import DatePicker from 'components/Common/DatePicker';
import SearchTableIcon from 'components/Icons/SearchIcon';

import styles from './styles.scss';

const getColumnSearchProps = ({
  dataIndex,
  searchInput,
  handleSearch,
  handleReset,
  onChangePeriod,
}) => {
  const onHandleSearch = (selectedKeys, confirm, dataIndex) =>
    handleSearch(selectedKeys, confirm, dataIndex);

  const onHandleReset = (clearFilters, setSelectedKeys) =>
    handleReset(clearFilters, setSelectedKeys);

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className={styles.dropDownPeriodSearch}>
        <DatePicker
          picker="month"
          ref={searchInput}
          onChange={onChangePeriod}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => onHandleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => onHandleReset(clearFilters, setSelectedKeys)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchTableIcon isFiltered={filtered} />,
  };
};

export default getColumnSearchProps;
