exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".modal-e24d7>div>button>span{display:flex;justify-content:center;align-items:center}.container-65f96{width:100%;max-width:1440px;margin:auto}.container-65f96 .calendar-439b1{background-color:#fff;box-shadow:0 0 3px #ccc;border-radius:4px}.amount-81cd6{font-size:24px;font-weight:600}.cell-21bff{text-align:center}.title-21be4{font-size:21px}", ""]);

// Exports
exports.locals = {
	"modal": "modal-e24d7",
	"container": "container-65f96",
	"calendar": "calendar-439b1",
	"amount": "amount-81cd6",
	"cell": "cell-21bff",
	"title": "title-21be4"
};