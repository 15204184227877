export const DASHBOARD_SUBCONTRACTORS_COMMON = {
  period_title: 'Period',
  period: 'period',
  project_title: 'Project',
  project: 'project',
  employer_title: 'Employer',
  employer: 'employer',
  name_title: 'Full name',
  name: 'name',
  paid_rate_title: 'Paid rate',
  paid_rate: 'paid_rate',
  invoiced_rate_title: 'Invoiced rate',
  invoiced_rate: 'invoiced_rate',
  margin_title: 'Margin, %',
  margin: 'margin',
};

export const MARGIN_NEGATIVE_VALUE = 30;
