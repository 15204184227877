export function hasRights(role, roleTypes) {
  if (!Array.isArray(roleTypes) || role === ROLE_TYPES.admin) return true;

  return roleTypes.includes(role);
}

export const ROLE_TYPES = {
  admin: 'admin',
  ceo: 'ceo',
  finance_specialist: 'finance_specialist',
  hr_lead: 'hr_lead',
  dm: 'dm',
  bdm: 'bdm',
  pm: 'pm',
  office_manger: 'office_manager',
  hr: 'hr',
  head_of_marketing: 'head_of_marketing',
  user: 'user',
};
