import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'antd';
import classNames from 'classnames';

import { getAllocationsStepData } from 'redux/Clients/actions';
import { Loader } from 'components/Common';
import { getObjectDeepCopy } from 'utils/helpers';

import { clientsSelector, jiraSelector } from 'redux/selectors';
import { getCalendar } from 'redux/Calendar/actions';

import { getInvoiceStartEndDates } from '../ClientInvoiceSteps/utils';
import {
  getColumns,
  getDataSource,
  getDataSourceIndexes,
  getEditDataSource,
} from './utils';

import styles from './styles.scss';

const ClientInvoiceAllocationsStep = ({
  clientId,
  invoiceDate,
  invoiceProjects,
  loggedAllocations,
  invoiceAllocations,
  isFullScreen,
  setInvoiceAllocations,
  setLoggedAllocations,
  setIsInvoiceValuesTouched,
  isInvoiceEdit,
  dataSource,
  dataSourceMap,
  setDataSource,
  setDataSourceMap,
}) => {
  const [tableHeight, setTableHeight] = useState(window.innerHeight / 15);

  const dispatch = useDispatch();
  const {
    dealAllocations,
    summaryWorklogs,
    isAllocationsStepLoading,
    projectActors,
    isClientsLoading,
    isAllocationsStepDataLoaded,
    clientProjects,
  } = useSelector(clientsSelector);

  const { jiraRoles } = useSelector(jiraSelector);

  useEffect(() => {
    if (!isAllocationsStepDataLoaded && !isInvoiceEdit) {
      const { date_from, date_to } = getInvoiceStartEndDates(invoiceDate);
      dispatch(
        getAllocationsStepData({
          client_id: clientId,
          date_from,
          date_to,
          project_ids: invoiceProjects,
        }),
      );
      dispatch(getCalendar({ from: date_from, to: date_to }));
    }
  }, []);

  useEffect(() => {
    if (!isInvoiceEdit && !loggedAllocations.length) {
      // Формирует массив для таблицы allocations в режиме создания инвойса
      const {
        dataSource,
        dataSourceMap,
        invoiceAllocations,
        loggedAllocations,
      } = getDataSource({
        summaryWorklogs,
        dealAllocations,
        projects: clientProjects,
        invoiceProjects,
        invoiceDate,
        isAllocationsStepLoading,
        projectActors,
      });

      setDataSource(dataSource);
      setDataSourceMap(dataSourceMap);
      setInvoiceAllocations(invoiceAllocations);
      setLoggedAllocations(loggedAllocations);
    }
  }, [isAllocationsStepLoading, invoiceDate]);

  useEffect(() => {
    if (isInvoiceEdit) {
      // Формирует массив для таблицы allocations в режиме редактирования инвойса
      const { dataSource, dataSourceMap } = getEditDataSource({
        summaryWorklogs,
        projects: clientProjects,
        invoiceProjects,
        invoiceDate,
        isAllocationsStepLoading,
        dealAllocations,
        loggedAllocations,
        invoiceAllocations,
      });
      setDataSource(dataSource);
      setDataSourceMap(dataSourceMap);
    }
  }, [isAllocationsStepLoading, isInvoiceEdit, loggedAllocations]);

  useEffect(() => {
    const setTableHeightState = () => {
      setTableHeight(window.innerHeight / 15);
    };

    window.addEventListener('resize', setTableHeightState);

    return () => window.removeEventListener('resize', setTableHeightState);
  }, []);

  const onSelectAllocation = allocation => {
    const prevDataSource = [...dataSource];

    // Вычисляет индексы в массиве dataSource с помощью source map для оптимизации
    const { projectIndex, workerIndex, dealIndex } = getDataSourceIndexes({
      dataSourceMap,
      projectId: allocation.project_id,
      worker: allocation.worker,
      dealId: allocation.deal_id,
    });

    const prevAllocationId =
      prevDataSource[projectIndex].children[workerIndex].children[dealIndex]
        .selectedAllocation;

    prevDataSource[projectIndex].children[workerIndex].children[
      dealIndex
    ].selectedAllocation = allocation.id;

    const updatedAllocations = loggedAllocations.filter(
      all => !(all.id === prevAllocationId && all.worker === allocation.worker),
    );

    setIsInvoiceValuesTouched(prev => ({ ...prev, allocations: true }));
    setDataSource(prevDataSource);
    setLoggedAllocations([...updatedAllocations, allocation]);
  };

  const onChangeCheckbox = ({ projectId, worker, dealId }) => {
    const prevDataSource = getObjectDeepCopy(dataSource);

    // Вычисляет индексы в массиве dataSource с помощью source map для оптимизации
    const { projectIndex, workerIndex, dealIndex } = getDataSourceIndexes({
      dataSourceMap,
      projectId,
      worker,
      dealId,
    });

    const removingAllocationId =
      prevDataSource[projectIndex].children[workerIndex].children[dealIndex]
        .selectedAllocation;

    const filteredAllocations = loggedAllocations.filter(
      allocation =>
        !(
          allocation.id === removingAllocationId && worker === allocation.worker
        ),
    );

    setLoggedAllocations(filteredAllocations);

    prevDataSource[projectIndex].children[workerIndex].children[
      dealIndex
    ].selectedAllocation = '';

    setIsInvoiceValuesTouched(prev => ({ ...prev, allocations: true }));
    setDataSource(prevDataSource);
  };

  const columns = useMemo(
    () =>
      getColumns({
        roles: jiraRoles,
        onSelectAllocation,
        onChangeCheckbox,
      }),
    [dataSource, jiraRoles],
  );

  return (
    <div
      className={classNames(
        isFullScreen
          ? styles.secondStepIsFullScreen
          : styles.secondStepIsNotFullScreen,
        styles.secondStepIsShowDealDescription,
      )}
    >
      <Loader
        loading={
          isAllocationsStepLoading || isClientsLoading || !dataSource.length
        }
      >
        <Table
          columns={columns}
          size="small"
          dataSource={dataSource}
          pagination={false}
          expandable={{
            defaultExpandAllRows: true,
          }}
          rowKey={record => record.firstKey}
          className={styles.allocationsTable}
          scroll={{ y: isFullScreen ? `${tableHeight}vh` : '450px' }}
        />
      </Loader>
    </div>
  );
};

ClientInvoiceAllocationsStep.propTypes = {
  clientId: PropTypes.number.isRequired,
  invoiceDate: PropTypes.object.isRequired,
  invoiceProjects: PropTypes.array.isRequired,
  loggedAllocations: PropTypes.array.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  setInvoiceAllocations: PropTypes.func.isRequired,
  setLoggedAllocations: PropTypes.func.isRequired,
  setIsInvoiceValuesTouched: PropTypes.func.isRequired,
  isInvoiceEdit: PropTypes.bool.isRequired,
  dataSource: PropTypes.array.isRequired,
  dataSourceMap: PropTypes.object.isRequired,
  setDataSource: PropTypes.func.isRequired,
  setDataSourceMap: PropTypes.func.isRequired,
  invoiceAllocations: PropTypes.array.isRequired,
};

export default ClientInvoiceAllocationsStep;
