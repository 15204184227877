import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Checkbox } from 'antd';

import { Button } from 'components/Common';
import styles from './styles.scss';

const DashboardDropdownOverlay = ({
  values,
  valuesList,
  onValueSelect,
  onApply,
  onReset,
}) => (
  <div className={styles.dashboardOverlay}>
    <Menu className={styles.menu}>
      {valuesList.map(type => (
        <Menu.Item
          onClick={() => onValueSelect(type)}
          key={type}
          className={styles.menuItem}
        >
          <Checkbox
            checked={values.selected.includes(type)}
            className={styles.checkbox}
          />
          {type}
        </Menu.Item>
      ))}
      <Menu.Divider />
    </Menu>
    <div className={styles.footer}>
      <Button onClick={onReset} type="link" className={styles.resetButton}>
        Reset
      </Button>
      <Button type="primary" onClick={onApply} className={styles.applyButton}>
        Apply
      </Button>
    </div>
  </div>
);

DashboardDropdownOverlay.propTypes = {
  values: PropTypes.object.isRequired,
  valuesList: PropTypes.array.isRequired,
  onValueSelect: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default DashboardDropdownOverlay;
