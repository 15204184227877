import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.scss';

const PageHeader = ({ title, extra, ...props }) => (
  <div className={classNames([styles.pageHeader, props.className])}>
    <div className={styles.leftContent}>
      {title && <span className={styles.title}>{title}</span>}
    </div>
    <div className={classNames([styles.rightContent, props.extraClassName])}>
      {extra}
    </div>
  </div>
);

PageHeader.propTypes = {
  title: PropTypes.string,
  extra: PropTypes.object,
  className: PropTypes.string,
  extraClassName: PropTypes.string,
};

export default PageHeader;
