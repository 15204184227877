import React from 'react';
import PropTypes from 'prop-types';

// @ts-ignore
import styles from './styles.scss';

const TotalCell = ({ children }) => (
  <div className={styles.totalCellWrapper}>{children}</div>
);
TotalCell.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TotalCell;
