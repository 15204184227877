import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Row } from 'antd';

const { Item } = Form;
const { TextArea } = Input;

const ClientNoteForm = ({ onFinish }) => {
  return (
    <Form onFinish={onFinish}>
      <Item key="text" name="text">
        <TextArea rows={6} placeholder="Please input notes..." />
      </Item>
      <Row type="flex" align="middle" justify="end">
        <Button type="primary" htmlType="submit">
          Add
        </Button>
      </Row>
    </Form>
  );
};

ClientNoteForm.propTypes = {
  onFinish: PropTypes.func,
};

export default ClientNoteForm;
