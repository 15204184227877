/* eslint-disable prettier/prettier */
import React, {useState} from 'react';
import {useDispatch} from 'react-redux'
import { Divider, Row, Col, Alert } from 'antd';
import PropTypes from 'prop-types';

import { SalaryList } from 'components/SalaryListCommon';
import SalaryModal from 'components/EmployeeCommon/SalaryModal';
import { Button } from 'components/Common';
import { addEmployeeSalaries, updateEmployeeSalaries } from 'redux/Employees/actions'

import {
  formatSalary,
} from './utils';
import styles from './styles.scss';

const UserSalary = ({
  warnings,
  salaries,
  onSalariesDelete,
  id,
}) => {
  const dispatch = useDispatch()
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [currentSalary, setCurrentSalary] = useState(null)

  const hideModal = () => {
    setIsVisibleModal(false)
  };

  const showModal = () => {
    setIsVisibleModal(true)
  }

  const onEditSalary = data => {
    setCurrentSalary(data)
    showModal()
  };

  const onUpdateSalary = values => {
    dispatch(updateEmployeeSalaries({
      salaryId: currentSalary.id,
      ...formatSalary(values),
    }))
    hideModal()
  };

  const onAddSalary = values => {
    dispatch(addEmployeeSalaries({
      employee_id: id,
      ...formatSalary(values),
    }))
    hideModal()
  };

  const onSalarySubmmit = values => {
    currentSalary ? onUpdateSalary(values) : onAddSalary(values)
  }

  return (
    <React.Fragment>
      <SalaryModal 
        isVisible={isVisibleModal}
        onSubmit={onSalarySubmmit}
        hideModal={hideModal}
        currentSalary={currentSalary}
        isExternal={false}
      />
      <Row gutter={16}>
          <Col md={{ span: 10 }}>
            <Divider>Salary dynamics</Divider>
            {warnings?.commonWarnings && warnings.commonWarnings.length > 0 && (
              <Alert
                className={styles.salaryAlert}
                type="error"
                showIcon
                message={warnings.commonWarnings.map(warn => (
                  <p key={warn}>{warn}</p>
                ))}
              />
            )}
              <div className={styles.actions}>
                <Button onClick={showModal}>Add</Button>
              </div>
            <SalaryList
              salaries={salaries}
              onEditSalary={onEditSalary}
              onSalariesDelete={onSalariesDelete}
            />
          </Col>
      </Row>
    </React.Fragment>
  );
  
}

UserSalary.propTypes = {
  warnings: PropTypes.object.isRequired,
  salaries: PropTypes.array.isRequired,
  onSalariesDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default UserSalary;
