import React, {
  Fragment,
  useEffect,
  useMemo,
  useState,
  createRef,
} from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import FilterDropdown from 'components/Common/FilterDropdown';
import { Loader, Table } from 'components/Common';
import PageHeader from 'components/Common/PageHeader';
import RangePicker from 'components/Common/RangePicker';
import { leadsSelector, usersSelector } from 'redux/selectors';
import { getLeads } from 'redux/Leads/actions';
import { loadUsers } from 'redux/Users/actions';
import { DATE_FORMAT, DATE_MONTH_FORMAT } from 'utils/timeConstants';
import { getRowKey } from 'utils/helpers';

import SearchTableIcon from 'components/Icons/SearchIcon';
import { getColumns } from './utils';
import styles from './styles.scss';

const startOfMonth = dayjs()
  .clone()
  .startOf('month');

const LeadsPage = () => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
  const [period, setPeriod] = useState([startOfMonth, dayjs()]);
  const [sortInfo, setSortInfo] = useState({
    order: 'descend',
    columnKey: 'id',
  });
  const [filtersInfo, setFiltersInfo] = useState({ owner: [] });
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();

  const { leads, totalLeads, leadFilters } = useSelector(leadsSelector);

  const { users } = useSelector(usersSelector);

  const searchInput = createRef();

  useEffect(() => {
    dispatch(loadUsers());
  }, []);

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: props => (
      <FilterDropdown
        {...props}
        dataIndex={dataIndex}
        handleReset={handleReset}
      />
    ),
    filterIcon: filtered => <SearchTableIcon isFiltered={filtered} />,
    onFilterDropdownOpenChange: open => {
      if (open && dataIndex !== 'created_at') {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  const columns = useMemo(
    () => getColumns({ sortInfo, users, leadFilters, getColumnSearchProps }),
    [leads, users, leadFilters],
  );

  const onTableChange = (pagination, filters, sorter) => {
    setFiltersInfo(filters);
    setPagination(prev => ({ ...prev, ...pagination }));
    setSortInfo(sorter);
  };

  useEffect(() => {
    const filtersWithoutNull = Object.keys(filtersInfo).reduce(
      (acc, item) =>
        filtersInfo[item] ? { ...acc, [item]: filtersInfo[item] } : acc,
      {},
    );

    dispatch(
      getLeads({
        sort: `${sortInfo.columnKey}:${sortInfo.order || ''}`,
        page: pagination.current,
        size: pagination.pageSize,
        filters: {
          ...filtersWithoutNull,
          period_from: period && dayjs(period[0]).format(DATE_FORMAT),
          period_to: period && dayjs(period[1]).format(DATE_FORMAT),
        },
      }),
    );
  }, [pagination, sortInfo, filtersInfo, period]);

  useEffect(() => {
    setPagination(prev => ({ ...prev, total: Number(totalLeads) }));
  }, [totalLeads]);

  const onChangePeriod = value => setPeriod(value);

  return (
    <Fragment>
      <PageHeader
        title="Leads"
        extra={
          <>
            Period{' '}
            <RangePicker
              defaultValue={period}
              onChange={onChangePeriod}
              format={DATE_MONTH_FORMAT}
            />
          </>
        }
      />
      <Loader loading={false}>
        <Table
          columns={columns}
          bordered
          dataSource={leads}
          onChange={onTableChange}
          rowKey={getRowKey}
          className={styles.leadsTable}
          pagination={pagination}
          size="small"
        />
      </Loader>
    </Fragment>
  );
};

export default LeadsPage;
