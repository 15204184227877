import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import PageHeader from 'components/Common/PageHeader';
import { Button, Loader } from 'components/Common';
import CurrenciesModal from 'components/CurrenciesCommon/CurrenciesModal';
import Table from 'components/Common/Table';

import {
  getCurrencies,
  addCustomCurrency,
  updateCustomCurrency,
  deleteCustomCurrency,
} from 'redux/Currencies/actions';
import { currenciesSelector } from 'redux/selectors';
import { getRowKey } from 'utils/helpers';

import { getColumns } from './utils';

const CurrenciesPage = () => {
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch = useDispatch();

  const { currencies, isCurrenciesLoading } = useSelector(currenciesSelector);

  useEffect(() => {
    dispatch(getCurrencies());
  }, []);

  const toggleModal = isVisible => () => {
    setIsModalVisible(isVisible);

    if (!isVisible) {
      setCurrentCurrency(null);
    }
  };

  const onEdit = currency => () => {
    setCurrentCurrency(currency);
    toggleModal(true)();
  };

  const onUpdateCurrency = currency => {
    dispatch(updateCustomCurrency(currency));
    toggleModal(false)();
  };

  const onCreateCurrency = currency => {
    dispatch(addCustomCurrency(currency));
    toggleModal(false)();
  };

  const onDeleteCurrency = id => () => {
    Modal.confirm({
      title: 'Delete curreny',
      content: 'Are you sure you want to delete currency?',
      onOk: () => {
        dispatch(deleteCustomCurrency(id));
        Modal.destroyAll();
      },
    });
  };

  const columns = useMemo(() => getColumns({ onEdit, onDeleteCurrency }), [
    currencies,
  ]);

  return (
    <div>
      <PageHeader
        title="Currencies"
        extra={
          <Button type="primary" onClick={toggleModal(true)}>
            Add currency
          </Button>
        }
      />
      <CurrenciesModal
        currentCurrency={currentCurrency}
        isVisible={isModalVisible}
        onCreateCurrency={onCreateCurrency}
        onUpdateCurrency={onUpdateCurrency}
        toggleModal={toggleModal}
      />
      <Loader loading={isCurrenciesLoading}>
        <Table
          columns={columns}
          dataSource={currencies}
          pagination={false}
          rowKey={getRowKey}
        />
      </Loader>
    </div>
  );
};

export default CurrenciesPage;
