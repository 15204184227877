import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import {
  getMonthNameWithYear,
  formatNumberWithCommas,
  numberSorter,
} from 'utils/helpers';

import TableButton from 'components/Common/TableButton';
import TableActions from 'components/Common/TableActions';
import FilterDropdown from 'components/Common/FilterDropdown';
import SearchTableIcon from 'components/Icons/SearchIcon';
import { INCOMING_INVOICES_TABLE_COMMON, APPROVE_FILTERS } from './constants';

import styles from './styles.scss';

const renderOrganization = ({ value, organizations }) =>
  organizations?.find(org => org.id === value)?.friendly_name;

const renderEmployee = ({ value, employees }) =>
  employees?.find(empl => empl.id === value)?.summary_employee.name_en;

const renderBank = ({ value, banks }) =>
  banks?.find(bank => bank.id === value)?.name;

const renderCurrency = ({ value, currencies }) =>
  currencies?.find(curr => curr.id === value)?.name;

const renderUser = ({ value, users }) =>
  users?.find(user => user.id === value)?.name;

const getFilters = filters =>
  filters?.map(({ id, name }) => ({ text: name, value: id }));

const getStatusFilters = filters =>
  filters?.map(status => ({ value: status, text: status }));

const getProjectFilters = filters =>
  filters?.map(({ name }) => ({ value: name, text: name })) || [];

const filteredValue = (valueType, filterInfo) =>
  (filterInfo && filterInfo[valueType]) || null;

export const attachmentsFormatter = attachments =>
  attachments?.map(({ url, file_name }) => ({
    name: file_name,
    status: 'done',
    url: url || '',
  })) || [];

const getColumnSearchProps = ({ dataIndex, searchInput, handleReset }) => ({
  filterDropdown: props => (
    <FilterDropdown
      {...props}
      dataIndex={dataIndex}
      handleReset={handleReset}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : '',
  filterIcon: filtered => (
    <SearchTableIcon className={filtered ? styles.searchIcon : null} />
  ),
  onFilterDropdownOpenChange: open => {
    if (open) {
      setTimeout(() => searchInput.current.select(), 100);
    }
  },
});

export const getColumns = ({
  employees,
  organizations,
  banks,
  currencies,
  users,
  filters,
  onInvoiceEdit,
  filterInfo,
  approveByFinancier,
  declineByFinancier,
  approveByApprover,
  declineByApprover,
  onDeleteIncomingInvoice,
  searchInput,
  handleReset,
}) => [
  {
    title: INCOMING_INVOICES_TABLE_COMMON.invoice_number_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.invoice_number,
    ...getColumnSearchProps({
      dataIndex: INCOMING_INVOICES_TABLE_COMMON.invoice_number,
      searchInput,
      handleReset,
    }),
    filteredValue: filteredValue(
      INCOMING_INVOICES_TABLE_COMMON.invoice_number,
      filterInfo,
    ),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.period_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.period,
    render: value => getMonthNameWithYear(value),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.organization_id_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.organization_id,
    key: INCOMING_INVOICES_TABLE_COMMON.company_ids,
    filters: getFilters(filters.organizations),
    filteredValue: filteredValue(
      INCOMING_INVOICES_TABLE_COMMON.company_ids,
      filterInfo,
    ),
    render: value => renderOrganization({ value, organizations }),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.project_name_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.project_name,
    key: INCOMING_INVOICES_TABLE_COMMON.project_name,
    filters: getProjectFilters(filters.projects),
    filteredValue: filteredValue(
      INCOMING_INVOICES_TABLE_COMMON.project_name,
      filterInfo,
    ),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.employee_id_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.employee_id,
    key: INCOMING_INVOICES_TABLE_COMMON.employee_ids,
    filters: getFilters(filters.employees),
    filteredValue: filteredValue(
      INCOMING_INVOICES_TABLE_COMMON.employee_ids,
      filterInfo,
    ),
    render: value => renderEmployee({ value, employees }),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.bank_id_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.bank_id,
    key: INCOMING_INVOICES_TABLE_COMMON.bank_ids,
    filters: getFilters(filters.banks),
    filteredValue: filteredValue(
      INCOMING_INVOICES_TABLE_COMMON.bank_ids,
      filterInfo,
    ),
    render: value => renderBank({ value, banks }),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.attachments_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.attachments,
    width: 150,
    render: value => (
      <div className={styles.attachments}>
        <Upload
          showUploadList={{
            showDownloadIcon: false,
            showRemoveIcon: false,
          }}
          fileList={attachmentsFormatter(value)}
        />
      </div>
    ),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.cost_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.cost,
    sorter: numberSorter(INCOMING_INVOICES_TABLE_COMMON.cost),
    render: value => formatNumberWithCommas(value),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.currency_id_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.currency_id,
    key: INCOMING_INVOICES_TABLE_COMMON.currency_ids,
    filters: getFilters(filters.currencies),
    filteredValue: filteredValue(
      INCOMING_INVOICES_TABLE_COMMON.currency_ids,
      filterInfo,
    ),
    render: value => renderCurrency({ value, currencies }),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.comments_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.comments,
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.author_id_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.author_id,
    render: value => renderUser({ value, users }),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.approver_id_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.approver_id,
    key: INCOMING_INVOICES_TABLE_COMMON.approver_ids,
    filters: getFilters(filters.approvers),
    filteredValue: filteredValue(
      INCOMING_INVOICES_TABLE_COMMON.approver_ids,
      filterInfo,
    ),
    render: value => renderUser({ value, users }),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.approver_id_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.is_approver_approve,
    key: INCOMING_INVOICES_TABLE_COMMON.approver_approve,
    filters: APPROVE_FILTERS,
    filteredValue: filteredValue(
      INCOMING_INVOICES_TABLE_COMMON.approver_approve,
      filterInfo,
    ),
    render: (value, { id, approver_id }) => (
      <div className={styles.approveButtons}>
        <TableButton
          icon={<CheckOutlined />}
          ghost
          type="primary"
          className={styles.approveButton}
          disabled={value || !approver_id}
          onClick={() => approveByApprover(id)}
        />
        <TableButton
          icon={<CloseOutlined />}
          ghost
          danger
          disabled={!value || !approver_id}
          onClick={() => declineByApprover(id)}
        />
      </div>
    ),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.financier,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.is_financier_approve,
    filters: APPROVE_FILTERS,
    filteredValue: filteredValue(
      INCOMING_INVOICES_TABLE_COMMON.is_financier_approve,
      filterInfo,
    ),
    render: (value, { id }) => (
      <div className={styles.approveButtons}>
        <TableButton
          icon={<CheckOutlined />}
          ghost
          type="primary"
          className={styles.approveButton}
          disabled={value}
          onClick={() => approveByFinancier(id)}
        />
        <TableButton
          icon={<CloseOutlined />}
          ghost
          danger
          disabled={!value}
          onClick={() => declineByFinancier(id)}
        />
      </div>
    ),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.status_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.status,
    filters: getStatusFilters(filters.statuses),
    filteredValue: filteredValue(
      INCOMING_INVOICES_TABLE_COMMON.status,
      filterInfo,
    ),
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.invoice_type_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.invoice_type,
    key: INCOMING_INVOICES_TABLE_COMMON.invoice_type_ids,
    filters: getFilters(filters.types),
    filteredValue: filteredValue(
      INCOMING_INVOICES_TABLE_COMMON.invoice_type_ids,
      filterInfo,
    ),
    render: value => value?.name,
  },
  {
    title: INCOMING_INVOICES_TABLE_COMMON.actions_title,
    dataIndex: INCOMING_INVOICES_TABLE_COMMON.actions,
    align: 'center',
    width: 50,
    render: (value, record) => (
      <TableActions
        onEdit={onInvoiceEdit(record)}
        onDelete={onDeleteIncomingInvoice(record.id)}
      />
    ),
  },
];
