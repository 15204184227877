import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  summaryEmployees: [],
  summaryEmployeesFilters: {},
  summaryEmployeesTotal: 0,
  summaryEmployee: null,
  summaryEmployeeSalaries: [],
  summaryEmployeeComments: [],
  summaryEmployeeExtraPayments: [],
  summaryEmployeeSocialRequests: [],
  summaryEmployeeSocialRequestsFilters: null,
  summaryEmployeeSocialRequestsTotal: 0,
  summaryEmployeeSocialRequestsInfo: null,
  summaryEmployeeProfiles: [],
  isSummaryEmployeesLoading: false,
  isSummarySocialRequestsLoading: false,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_SUMMARY_EMPLOYEES:
    case types.GET_SUMMARY_EMPLOYEE:
    case types.GET_SUMMARY_EMPLOYEE_SALARIES:
    case types.GET_SUMMARY_EMPLOYEE_COMMENTS:
    case types.GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS:
      return state.set('isSummaryEmployeesLoading', true);
    case types.GET_SUMMARY_SOCIAL_REQUESTS:
    case types.GET_SUMMARY_SOCIAL_REQUESTS_INFO:
    case types.CREATE_SUMMARY_SOCIAL_REQUEST:
    case types.UPDATE_SUMMARY_SOCIAL_REQUEST:
    case types.DELETE_SUMMARY_SOCIAL_REQUEST:
      return state.set('isSummarySocialRequestsLoading', true);

    case types.GET_SUMMARY_EMPLOYEES_SUCCESS:
      return state
        .set('isSummaryEmployeesLoading', false)
        .set('summaryEmployees', payload.data)
        .set('summaryEmployeesFilters', payload.data_for_filters)
        .set('summaryEmployeesTotal', payload.total_data);
    case types.GET_SUMMARY_EMPLOYEE_SUCCESS:
      return state
        .set('isSummaryEmployeesLoading', false)
        .set('summaryEmployee', payload);
    case types.GET_SUMMARY_EMPLOYEE_SALARIES_SUCCESS:
      return state
        .set('isSummaryEmployeesLoading', false)
        .set('summaryEmployeeSalaries', payload);
    case types.GET_SUMMARY_EMPLOYEE_COMMENTS_SUCCESS:
      return state
        .set('isSummaryEmployeesLoading', false)
        .set('summaryEmployeeComments', payload);
    case types.GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
      return state
        .set('isSummaryEmployeesLoading', false)
        .set('summaryEmployeeExtraPayments', payload);
    case types.CREATE_SUMMARY_SOCIAL_REQUEST_SUCCESS:
    case types.UPDATE_SUMMARY_SOCIAL_REQUEST_SUCCESS:
    case types.DELETE_SUMMARY_SOCIAL_REQUEST_SUCCESS:
      return state
        .set('summaryEmployeeSocialRequests', payload)
        .set('isSummarySocialRequestsLoading', false);
    case types.GET_SUMMARY_SOCIAL_REQUESTS_SUCCESS:
      return state
        .set('summaryEmployeeSocialRequests', payload.data)
        .set('isSummarySocialRequestsLoading', false);
    case types.GET_SUMMARY_SOCIAL_REQUESTS_INFO_SUCCESS:
      return state
        .set('summaryEmployeeSocialRequestsInfo', payload)
        .set('isSummarySocialRequestsLoading', false);
    case types.GET_SUMMARY_PROFILES_SUCCESS:
      return state.set('summaryEmployeeProfiles', payload);

    case types.GET_SUMMARY_EMPLOYEES_FAILED:
    case types.GET_SUMMARY_EMPLOYEE_FAILED:
    case types.GET_SUMMARY_EMPLOYEE_SALARIES_FAILED:
    case types.GET_SUMMARY_EMPLOYEE_COMMENTS_FAILED:
    case types.GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
      return state.set('isSummaryEmployeesLoading', false);
    case types.GET_SUMMARY_SOCIAL_REQUESTS_FAILED:
    case types.GET_SUMMARY_SOCIAL_REQUESTS_INFO_FAILED:
    case types.CREATE_SUMMARY_SOCIAL_REQUEST_FAILED:
    case types.UPDATE_SUMMARY_SOCIAL_REQUEST_FAILED:
    case types.DELETE_SUMMARY_SOCIAL_REQUEST_FAILED:
      return state.set('isSummarySocialRequestsLoading', false);
    default:
      return state;
  }
}
