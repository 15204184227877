import React from 'react';
import { Table as AntdTable } from 'antd';

import styles from './styles.scss';

const Table = ({ ...props }) => (
  <AntdTable {...props} className={styles.table} onHea bordered />
);

export default Table;
