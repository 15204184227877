exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".requestsInfo-2845a{display:flex;align-items:flex-end;gap:30px;margin-bottom:24px}.requestsInfo-2845a .balance-ec16d{width:40%}.requestsInfo-2845a .balance-ec16d .balanceTitle-ca034{display:inline-block;margin-bottom:12px;font-weight:700}.requestsInfo-2845a .balance-ec16d .balanceItems-2951c{display:grid;grid-template-columns:repeat(2, 1fr);column-gap:40px}.requestsInfo-2845a .description-19ca2{width:60%;font-size:14px}.balanceItem-73e57{display:flex;justify-content:space-between;align-items:center}.balanceItem-73e57 .value-a6cfc{font-weight:500}.balanceItem-73e57 .valueZero-60a31{color:tomato}", ""]);

// Exports
exports.locals = {
	"requestsInfo": "requestsInfo-2845a",
	"balance": "balance-ec16d",
	"balanceTitle": "balanceTitle-ca034",
	"balanceItems": "balanceItems-2951c",
	"description": "description-19ca2",
	"balanceItem": "balanceItem-73e57",
	"value": "value-a6cfc",
	"valueZero": "valueZero-60a31"
};