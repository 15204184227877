exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".comments-9b1b1{width:100%;padding:24px}.comments-9b1b1 .title-6d65f{margin-bottom:12px;font-size:20px;font-weight:600;line-height:28px;color:#262626;margin-bottom:20px}.comments-9b1b1 .comment-580c1{position:relative}.comments-9b1b1 .comment-580c1 .dropdown-e14e6{position:absolute;right:0;top:0}.comments-9b1b1 .comment-580c1 .buttons-e5bac{position:absolute;right:35px;top:-10px}.comments-9b1b1 .commentEditor-f5eb0{margin-bottom:15px;border:1px solid #d9d9d9;border-radius:8px;cursor:text}", ""]);

// Exports
exports.locals = {
	"comments": "comments-9b1b1",
	"title": "title-6d65f",
	"comment": "comment-580c1",
	"dropdown": "dropdown-e14e6",
	"buttons": "buttons-e5bac",
	"commentEditor": "commentEditor-f5eb0"
};