import React from 'react';
import PropTypes from 'prop-types';

import TechnologiesSelect from 'containers/TechnologiesPage/TechnologiesSelect/TechnologiesSelect';

import styles from './styles.scss';

const UserTechnologies = ({ currentEmpoyeeId }) => (
  <div className={styles.userSelectorContainer}>
    <TechnologiesSelect currentEmpoyeeId={currentEmpoyeeId} />
  </div>
);

UserTechnologies.propTypes = {
  currentEmpoyeeId: PropTypes.string,
};

export default UserTechnologies;
