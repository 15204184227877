exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dealInfoContainer-63e5c{display:flex;justify-content:space-between}.overviewPeriodContainer-a8df3{display:flex;justify-content:center;margin:15px;align-items:center}.dealFormContainer-e5875{width:28%}.allocationContainer-495f9{width:67%;margin-top:30px}.overviewAllocation-c5921{font-size:.88rem}", ""]);

// Exports
exports.locals = {
	"dealInfoContainer": "dealInfoContainer-63e5c",
	"overviewPeriodContainer": "overviewPeriodContainer-a8df3",
	"dealFormContainer": "dealFormContainer-e5875",
	"allocationContainer": "allocationContainer-495f9",
	"overviewAllocation": "overviewAllocation-c5921"
};