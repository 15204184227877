exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".typeRadioGroup-6dec2{width:100%}.typeRadioButton-2e5a5{width:25%;text-align:center}.amountDetails-be60a{display:inline-block;margin:0 0 24px;font-size:14px}.inputAddon-f7427{padding:0}.inputAddon-f7427 input{padding:8px 12px}.balance-bed3e{font-size:14px;margin-bottom:12px}", ""]);

// Exports
exports.locals = {
	"typeRadioGroup": "typeRadioGroup-6dec2",
	"typeRadioButton": "typeRadioButton-2e5a5",
	"amountDetails": "amountDetails-be60a",
	"inputAddon": "inputAddon-f7427",
	"balance": "balance-bed3e"
};