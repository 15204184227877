import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AuthLayout from 'containers/AuthLayout/AuthLayout';
import { authSelector } from 'redux/selectors';
import { LOCAL_STORAGE_ITEMS } from 'utils/localStorage';
import { Y_M_D_H_M } from 'utils/timeConstants';

import Loader from '../Common/Loader';

const AuthRoute = ({ component: Component, scopes, ...args }) => {
  const { isUserLoading, isLogged } = useSelector(authSelector);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_ITEMS.lastPage, args.computedMatch.url);
    localStorage.setItem(
      LOCAL_STORAGE_ITEMS.lastPageTime,
      dayjs(new Date()).format(Y_M_D_H_M),
    );
  }, [args.path]);

  return (
    <Loader loading={isUserLoading} global>
      <Route
        {...args}
        render={props => {
          if (!isLogged) {
            return <Redirect to="/login" />;
          }

          return (
            <AuthLayout {...{ scopes }}>
              <Component {...props} />
            </AuthLayout>
          );
        }}
      />
    </Loader>
  );
};

AuthRoute.propTypes = {
  component: PropTypes.any,
  identify: PropTypes.array,
  scopes: PropTypes.func,
};

export default AuthRoute;
