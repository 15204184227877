import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Steps } from 'antd';

import {
  HOLIDAY_COMMON,
  HOLIDAY_UPDATE_TYPES,
} from 'containers/CalendarPage/constants';
import Modal from 'components/Common/Modal';
import Select, { Option } from 'components/Common/Select';
import { Button } from 'components/Common';

import HolidayForm from '../HolidayForm';
import styles from './styles.scss';

const { Item } = Form;

const HolidayRepeatModal = ({
  holidayDate,
  holidayData,
  form,
  onSubmit,
  onCancel,
  isHolidayRepeatModal,
  step,
  setStep,
}) => {
  const [isAllDay, setIsAllDay] = useState(true);
  const [isRepeat, setIsRepeat] = useState(false);
  const [updateType, setUpdateType] = useState(null);

  useEffect(() => {
    if (holidayData) {
      setIsAllDay(holidayData[HOLIDAY_COMMON.is_all_day]);
      setIsRepeat(!!holidayData[HOLIDAY_COMMON.repeat_type]);
      setUpdateType(holidayData[HOLIDAY_COMMON.update_type]);
    }
  }, [holidayData]);

  const onCancelHandler = () => {
    setIsAllDay(true);
    setIsRepeat(false);
    onCancel();
  };

  const onValuesChange = updatedValues => {
    if (HOLIDAY_COMMON.is_all_day in updatedValues) {
      setIsAllDay(updatedValues[HOLIDAY_COMMON.is_all_day]);
    }

    if (HOLIDAY_COMMON.repeat_type in updatedValues) {
      setIsRepeat(!!updatedValues[HOLIDAY_COMMON.repeat_type]);
    }
  };

  const next = () => {
    setStep(1);
  };

  const onSubmitHandler = values => {
    onSubmit({
      ...values,
      [HOLIDAY_COMMON.update_type]: updateType,
    });
  };

  const steps = [
    {
      title: 'Select holidays',
      content: (
        <Item
          label="Select holidays to edit "
          required
          rules={[{ required: true, message: 'Select type' }]}
        >
          <Select value={updateType} onChange={setUpdateType}>
            {Object.values(HOLIDAY_UPDATE_TYPES).map(({ key, title }) => (
              <Option value={key} key={key}>
                {title}
              </Option>
            ))}
          </Select>
        </Item>
      ),
    },
    {
      title: 'Editing',
      content: (
        <HolidayForm
          form={form}
          isAllDay={isAllDay}
          isRepeat={isRepeat}
          onValuesChange={onValuesChange}
          onSubmit={onSubmitHandler}
        />
      ),
    },
  ];

  return (
    <Modal
      onOk={form.submit}
      onCancel={onCancelHandler}
      open={!!holidayDate && isHolidayRepeatModal}
      title="Edit holiday"
      okText="Save"
      footer={
        <>
          <Button onClick={onCancelHandler}>Cancel</Button>
          {step === 0 ? (
            <Button onClick={next} type="primary" disabled={!updateType}>
              Next
            </Button>
          ) : (
            <Button onClick={form.submit} type="primary">
              Save
            </Button>
          )}
        </>
      }
    >
      <Steps
        items={steps}
        current={step}
        size="small"
        className={styles.steps}
      />
      {steps[step].content}
    </Modal>
  );
};

HolidayRepeatModal.propTypes = {
  holidayDate: PropTypes.object,
  holidayData: PropTypes.object,
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isHolidayRepeatModal: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default HolidayRepeatModal;
