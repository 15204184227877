import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isJiraLoading: false,
  jiraRoles: [],
  jiraProjects: [],
  searchedJiraUsers: [],
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_JIRA_PROJECTS:
    case types.GET_JIRA_ROLES:
    case types.SEARCH_JIRA_USERS:
      return state.set('isJiraLoading', true);

    case types.GET_JIRA_PROJECTS_SUCCESS:
      return state.set('jiraProjects', payload).set('isJiraLoading', false);
    case types.GET_JIRA_ROLES_SUCCESS:
      return state.set('jiraRoles', payload).set('isJiraLoading', false);
    case types.SEARCH_JIRA_USERS_SUCCESS:
      return state
        .set('searchedJiraUsers', payload)
        .set('isJiraLoading', false);
    case types.GET_JIRA_PROJECTS_FAILED:
    case types.GET_JIRA_ROLES_FAILED:
    case types.SEARCH_JIRA_USERS_FAILED:
      return state.set('isJiraLoading', false);

    default:
      return state;
  }
}
