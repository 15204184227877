/* eslint-disable indent */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Tabs, Modal as AntdModal, Avatar, Form } from 'antd';
import { debounce } from 'lodash';
import dayjs from 'dayjs';

import {
  formatWarningsKeys,
  getStatusTag,
} from 'containers/EmployeesProfiles/utils';
import { EXTRA_PAYMENTS_MODAL } from 'containers/ExtraPayments/constants';
import PageHeader from 'components/Common/PageHeader';
import PageLoader from 'components/Common/PageLoader';
import { PaymentForm } from 'components/PaymentsCommon';
import { Button, Loader } from 'components/Common';
import Modal from 'components/Common/Modal';
import { TEAM_ID } from 'utils/constants';
import { DATE_FORMAT } from 'utils/timeConstants';
import {
  getEmployee,
  getFullEmployees,
  updateEmployee,
  getEmployeeSalaries,
  getEmployeeExtraPayments,
  getEmployeePaysheets,
  addEmployeeExtraPayments,
  updateEmployeeExtraPayment,
  deleteEmployeeExtraPayment,
  deleteEmployeeSalaries,
  clearEmployeeStore,
  getEmployeeTechnologies,
} from 'redux/Employees/actions';
import { searchJiraUsers, getJiraProjects } from 'redux/Jira/actions';
import { getTeams } from 'redux/Teams/actions';
import { getPositions } from 'redux/EmployeePositions/actions';
import { getOrganizations } from 'redux/Organizations/actions';
import { loadUsers } from 'redux/Users/actions';
import { getCurrencies } from 'redux/Currencies/actions';
import {
  currenciesSelector,
  employeePositionsSelector,
  employeesSelector,
  jiraSelector,
  organizationsSelector,
  summaryEmployeesSelector,
  teamsSelector,
  usersSelector,
} from 'redux/selectors';
import { getSummaryEmployees } from 'redux/SummaryEmployees/actions';
import { LINKS } from 'config/routing';

import UserProfile from '../UserProfile';
import UserPayments from '../UserPayments';
import UserTechnologies from '../UserTechnologies';
import UserPaysheets from '../UserPaysheets';
import UserSalary from '../UserSalary';

import { formatExtraPayments } from '../UserPayments/utils';

import styles from './styles.scss';

const UserPage = () => {
  const dispatch = useDispatch();
  const { id, tab } = useParams();
  const history = useHistory();

  const [isEPModalVisible, setIsEPModalVisible] = useState(false);
  const [isExternal, setIsExternal] = useState(false);
  const [isEditable, setIsEditable] = useState('');

  const [form] = Form.useForm();

  const {
    isEmployeesLoading,
    profile,
    employeeSalaries,
    employeeExtraPayments,
    employeePaysheets,
    ibanCurrencies,
    employeesFull,
  } = useSelector(employeesSelector);

  const { searchedJiraUsers, jiraProjects } = useSelector(jiraSelector);

  const { users } = useSelector(usersSelector);

  const { teams } = useSelector(teamsSelector);

  const { positions } = useSelector(employeePositionsSelector);

  const { organizationsFull } = useSelector(organizationsSelector);

  const { currencies } = useSelector(currenciesSelector);

  const { summaryEmployees } = useSelector(summaryEmployeesSelector);

  useEffect(() => {
    dispatch(clearEmployeeStore());
    dispatch(loadUsers());
    dispatch(getOrganizations());
    dispatch(getTeams());
    dispatch(getPositions());
    dispatch(getFullEmployees());
    dispatch(getSummaryEmployees({ size: 9999 }));

    dispatch(getEmployeeSalaries(id));

    dispatch(getEmployeeExtraPayments(id));

    dispatch(getEmployeePaysheets(id));

    dispatch(getEmployeeTechnologies(id));

    dispatch(getCurrencies());
    dispatch(getEmployee(id));
  }, [id]);

  useEffect(() => {
    if (profile) {
      setIsExternal(profile.team_id === TEAM_ID.SUBCONTRACTORS);
      setIsEditable(profile.jira_id);
    }
  }, [profile]);

  const onChangeView = key => {
    history.push(`/employees/${id}/${key}`);
  };

  const hideModal = () => {
    setIsEPModalVisible(false);
  };

  const showModal = () => setIsEPModalVisible(true);

  const onAddPayment = values => {
    if (values) {
      dispatch(
        addEmployeeExtraPayments({
          employee_id: id,
          ...formatExtraPayments(values),
        }),
      );
    }

    hideModal();
  };

  const onExtraPaymentsDelete = paymentId => {
    AntdModal.confirm({
      title: EXTRA_PAYMENTS_MODAL.deleteTitle,
      content: EXTRA_PAYMENTS_MODAL.delete,
      onOk: () => {
        dispatch(
          deleteEmployeeExtraPayment({
            id: paymentId,
            employeeId: id,
          }),
        );
        AntdModal.destroyAll();
      },
      onCancel: AntdModal.destroyAll,
    });
  };

  const onSalariesDelete = salaryId => {
    AntdModal.confirm({
      title: 'Remove salary increase',
      content: 'Are you sure you want to remove this salary increase?',
      onOk: () => {
        dispatch(
          deleteEmployeeSalaries({
            id: salaryId,
            employeeId: id,
          }),
        );
        AntdModal.destroyAll();
      },
      onCancel: AntdModal.destroyAll,
    });
  };

  const onUserUpdate = values => {
    const resource_manager_id = values.resource_manager;
    const buddy_ids = values.buddies;
    const birthday = values.birthday
      ? dayjs(values.birthday).format(DATE_FORMAT)
      : null;
    const ibans = values.ibans.map(iban => {
      if (iban.currency_type) return iban;

      const formattedIban = { ...iban };
      const ibanObject = ibanCurrencies[iban.iban_number];

      if (ibanObject.currency_type && ibanObject.isEditable) {
        formattedIban.currency_type_id = ibanObject.currency_type;
      }

      return formattedIban;
    });

    const contract_starts_at = values.contract_starts_at
      ? values.contract_starts_at.format(DATE_FORMAT)
      : null;
    const contract_ends_at = values.contract_ends_at
      ? values.contract_ends_at.format(DATE_FORMAT)
      : null;

    dispatch(
      updateEmployee({
        ...values,
        id,
        resource_manager_id,
        buddy_ids,
        ibans,
        birthday,
        contract_starts_at,
        contract_ends_at,
      }),
    );
  };

  const onSearch = debounce(val => {
    dispatch(
      searchJiraUsers({
        name: val,
      }),
    );
  }, 350);

  const warnings = formatWarningsKeys(profile?.warnings || {});

  if (!profile) return <PageLoader />;

  const tabItems = [
    {
      label: 'Profile',
      key: 'info',
      children: (
        <div className={styles.tab}>
          <UserProfile
            form={form}
            summaryEmployees={summaryEmployees}
            onUpdate={onUserUpdate}
            jiraUsers={searchedJiraUsers}
            employeePositions={positions}
            teams={teams}
            organizations={organizationsFull}
            employee={profile}
            onJiraSearch={onSearch}
            isExternal={isExternal}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            setIsExternal={setIsExternal}
            ibanCurrencies={ibanCurrencies}
            currencies={currencies}
          />
        </div>
      ),
    },
    {
      label: 'Extra payments',
      key: 'extra-payments',
      children: (
        <UserPayments
          currentEmployeeId={id}
          onDelete={onExtraPaymentsDelete}
          onUpdateExtraPayments={updateEmployeeExtraPayment}
          extraPayments={employeeExtraPayments}
          users={users}
          getJiraProjects={getJiraProjects}
          projects={jiraProjects}
          employees={employeesFull}
        />
      ),
    },
    {
      label: 'Payments',
      key: 'payments',
      children: (
        <div className={styles.tab}>
          <UserPaysheets paysheets={employeePaysheets} />
        </div>
      ),
    },
    {
      label: 'Technologies',
      key: 'technologies',
      children: (
        <div className={styles.tab}>
          <UserTechnologies currentEmpoyeeId={id} />
        </div>
      ),
    },
    {
      label: 'Salary',
      key: 'salary',
      children: (
        <div className={styles.tab}>
          <UserSalary
            warnings={warnings}
            salaries={employeeSalaries}
            onSalariesDelete={onSalariesDelete}
            id={id}
          />
        </div>
      ),
    },
  ];

  return (
    <Loader loading={isEmployeesLoading}>
      <div className={styles.container}>
        <Modal
          className={styles.modal}
          title="Extra payments"
          open={isEPModalVisible}
          onCancel={hideModal}
          cancelText="Отмена"
          destroyOnClose
          maskClosable
          footer={[
            <Button onClick={hideModal}>Cancel</Button>,
            <Button
              form="paymentForm"
              key="submit"
              htmlType="submit"
              type="primary"
            >
              Add
            </Button>,
          ]}
        >
          <PaymentForm onSubmit={onAddPayment} />
        </Modal>
        <PageHeader
          title={
            <div className={styles.header}>
              <Avatar
                size={40}
                src={profile.jira_user && profile.jira_user.avatarUrls['32x32']}
              />
              <Link to={LINKS.profile(id)} className={styles.name}>
                {profile.name_ru}
              </Link>
              {getStatusTag(profile.status, isExternal)}
            </div>
          }
          subTitle={profile.name_en && `(${profile.name_en})`}
          tags={getStatusTag(profile.status, isExternal)}
          extra={
            <div>
              {(!tab || tab === 'info') && (
                <Button onClick={form.submit} type="primary">
                  Save
                </Button>
              )}
              {tab === 'extra-payments' && (
                <Button type="primary" onClick={showModal}>
                  Request extra payment
                </Button>
              )}
            </div>
          }
        />
        <Tabs
          defaultActiveKey="info"
          activeKey={tab}
          animated={false}
          className={styles.tabs}
          tabPosition="left"
          onChange={onChangeView}
          items={tabItems}
        />
      </div>
    </Loader>
  );
};

export default UserPage;
