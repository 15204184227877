import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, Menu, Checkbox, Tag } from 'antd';
import Icon from '@ant-design/icons';
import { orderBy, get, pick } from 'lodash';

import { TEAM_ID } from 'utils/constants';

import { UserSelect, TechnologySelect, TeamSelect } from '../index';
import { YELLOW_TEAM_NAME } from './constants';
import styles from './styles.scss';
import FilterContent from './FilterContent';

const FilterForm = ({
  managers,
  jiraUsers,
  selectedManagerIndex,
  selectedUserIndex,
  selectedTechnologiesIndex,
  selectedPositionsIndex,
  selectedTeamsIndex,
  onClearFilter,
  technologies,
  onChangeCheckbox,
  onSearchByType,
  employeePositions,
  teams,
}) => {
  const [typeContent, setTypeContent] = useState('main');

  const onSelectContent = type => () => {
    setTypeContent(type);
  };

  const positions = orderBy(employeePositions, ['title']);
  const text = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {!(typeContent === 'main') && (
        <span className={styles.back} onClick={onSelectContent('main')}>
          <Icon type="left" />
          Back
        </span>
      )}
      <span className={styles.titleBold}>resource filters</span>
    </div>
  );
  const mainContent = (
    <div className={styles.filterContent}>
      <Menu className={styles.menu} mode="inline">
        <Menu.Item
          className={styles.item}
          key="1"
          onClick={onSelectContent('manager')}
        >
          <span>Managers</span>
        </Menu.Item>
        <Menu.Item
          className={styles.item}
          key="2"
          onClick={onSelectContent('user')}
        >
          <span>Employees</span>
        </Menu.Item>
        <Menu.Item
          className={styles.item}
          key="3"
          onClick={onSelectContent('technology')}
        >
          <span>Technologies</span>
        </Menu.Item>
        <Menu.Item
          className={styles.item}
          key="4"
          onClick={onSelectContent('position')}
        >
          <span>Position</span>
        </Menu.Item>
        <Menu.Item
          className={styles.item}
          key="5"
          onClick={onSelectContent('team')}
        >
          <span>Team</span>
        </Menu.Item>
      </Menu>
    </div>
  );
  const managerContent = (
    <div className={styles.filterContent}>
      <UserSelect
        width="300px"
        onChange={onSearchByType('manager')}
        data={managers}
        value=""
      />
      <span className={styles.titleGray}>Search and select to filter by.</span>
      {selectedManagerIndex.length !== 0 && (
        <span className={styles.titleBold}>selected</span>
      )}
      <div className={styles.listCheckbox}>
        {selectedManagerIndex.map(item => {
          const manager = managers.find(
            elem => elem.jira_user && elem.jira_user.id === item,
          );

          if (!manager) return null;

          return (
            <Checkbox
              key={item}
              checked
              onChange={onChangeCheckbox(
                manager.jira_user && manager.jira_user.id,
                'manager',
              )}
            >
              {manager.name_en} ({manager.jira_user && manager.jira_user.id})
            </Checkbox>
          );
        })}
      </div>
    </div>
  );
  const userContent = (
    <div style={{ width: '300px' }}>
      <UserSelect
        width="300px"
        onChange={onSearchByType('user')}
        data={jiraUsers}
        value=""
      />
      <span className={styles.titleGray}>Search and select to filter by.</span>
      {selectedUserIndex.length !== 0 && (
        <span className={styles.titleBold}>selected</span>
      )}
      <div className={styles.listCheckbox}>
        {selectedUserIndex.map(item => {
          const employee = jiraUsers.find(
            elem => elem.jira_user && elem.jira_user.id === item,
          );

          if (!employee) return null;

          return (
            <Checkbox
              key={item}
              checked
              onChange={onChangeCheckbox(
                employee.jira_user && employee.jira_user.id,
                'user',
              )}
            >
              {employee.name_en} ({employee.jira_user && employee.jira_user.id})
            </Checkbox>
          );
        })}
      </div>
    </div>
  );

  const technologiesContent = (
    <FilterContent
      data={technologies}
      field="technology"
      onSearchByType={onSearchByType}
      onChangeCheckbox={onChangeCheckbox}
      selectedIndex={selectedTechnologiesIndex}
    />
  );

  const positionContent = (
    <FilterContent
      data={positions}
      field="position"
      onSearchByType={onSearchByType}
      onChangeCheckbox={onChangeCheckbox}
      selectedIndex={selectedPositionsIndex}
    />
  );

  const teamContent = (
    <div className={styles.filterContent}>
      <TeamSelect
        width="300px"
        onChange={onSearchByType('team')}
        data={teams}
        value=""
      />
      <span className={styles.titleGray}>Search and select to filter by.</span>
      {selectedTeamsIndex.length !== 0 && (
        <span className={styles.titleBold}>selected</span>
      )}
      <div className={styles.listCheckbox}>
        {teams.length &&
          selectedTeamsIndex.map(item => {
            const team = pick(teams.find(elem => elem.id === item), [
              'title',
              'id',
            ]);

            if (!team) return null;

            return (
              <Checkbox
                key={item}
                checked
                onChange={onChangeCheckbox(
                  get(team, 'id', TEAM_ID.YELLOW),
                  'team',
                )}
              >
                {get(team, 'title', YELLOW_TEAM_NAME)}
              </Checkbox>
            );
          })}
      </div>
    </div>
  );
  const isUserView = typeContent === 'user' && userContent;
  const isManagerView = typeContent === 'manager' && managerContent;
  const isTechnologiesView =
    typeContent === 'technology' && technologiesContent;
  const isPositionContent = typeContent === 'position' && positionContent;
  const isTeamContent = typeContent === 'team' && teamContent;
  const content =
    isUserView ||
    isManagerView ||
    isTechnologiesView ||
    isPositionContent ||
    isTeamContent ||
    mainContent;
  const conditionTagView =
    selectedManagerIndex.length ||
    selectedUserIndex.length ||
    selectedTechnologiesIndex.length ||
    selectedPositionsIndex.length ||
    selectedTeamsIndex ||
    null;

  return (
    <div className={styles.filterBox}>
      <Popover
        placement="bottom"
        title={text}
        content={content}
        trigger="click"
      >
        <Button className={styles.filterField}>
          <div>
            {!conditionTagView && (
              <span>Use filters to find specific resources</span>
            )}
            {conditionTagView && (
              <Fragment>
                <span className={styles.filterFieldTitle}>Filter by</span>
                {selectedUserIndex.map(item => (
                  <Tag
                    color="geekblue"
                    key={item}
                    closable
                    onClose={onChangeCheckbox(item, 'user')}
                  >
                    <span style={{ fontWeight: 'bold' }}>User</span> {item}
                  </Tag>
                ))}
                {selectedManagerIndex.map(item => (
                  <Tag
                    color="magenta"
                    key={item}
                    closable
                    onClose={onChangeCheckbox(item, 'manager')}
                  >
                    <span style={{ fontWeight: 'bold' }}>Manager</span> {item}
                  </Tag>
                ))}
                {selectedTechnologiesIndex.map(item => (
                  <Tag
                    color="green"
                    key={item}
                    closable
                    onClose={onChangeCheckbox(item, 'technology')}
                  >
                    <span style={{ fontWeight: 'bold' }}>Technology</span>{' '}
                    {item}
                  </Tag>
                ))}
                {selectedPositionsIndex.map(item => (
                  <Tag
                    color="gold"
                    key={item}
                    closable
                    onClose={onChangeCheckbox(item, 'position')}
                  >
                    <span style={{ fontWeight: 'bold' }}>Position</span> {item}
                  </Tag>
                ))}
                {teams.length &&
                  selectedTeamsIndex.map(item => (
                    <Tag
                      color="gold"
                      key={item}
                      closable
                      onClose={onChangeCheckbox(item, 'team')}
                    >
                      <span style={{ fontWeight: 'bold' }}>Team</span>{' '}
                      {get(
                        teams.find(elem => elem.id === item),
                        'title',
                        YELLOW_TEAM_NAME,
                      )}
                    </Tag>
                  ))}
              </Fragment>
            )}
          </div>
        </Button>
      </Popover>
      {conditionTagView && (
        <Button style={{ marginRight: '20px' }} onClick={onClearFilter}>
          Clear all
        </Button>
      )}
    </div>
  );
};

FilterForm.propTypes = {
  managers: PropTypes.array,
  jiraUsers: PropTypes.array,
  technologies: PropTypes.array,
  selectedManagerIndex: PropTypes.array,
  selectedUserIndex: PropTypes.array,
  selectedTechnologiesIndex: PropTypes.array,
  selectedPositionsIndex: PropTypes.array,
  employeePositions: PropTypes.array,
  onClearFilter: PropTypes.func.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
  onSearchByType: PropTypes.func.isRequired,
  selectedTeamsIndex: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
};

export default FilterForm;
