import React from 'react';
import PropTypes from 'prop-types';
import { Form, Alert } from 'antd';

import { UserSelect } from 'components/ResourceManagementCommon';
import Modal from 'components/Common/Modal';
import Select, { Option } from 'components/Common/Select';
import Input from 'components/Common/Input';
import { getPopupContainerHandler } from 'utils/helpers';

import { LOCATION_PATTERN } from '../EmployeeEditForm/constants';

import styles from './styles.scss';

const { Item } = Form;

const EmployeeAddForm = ({
  teams,
  positions,
  organizations,
  summaryEmployees,
  isVisible,
  hideModal,
  onSubmit,
  isLoading,
}) => {
  const [form] = Form.useForm();

  const onFilterOption = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const filteredEmployees = summaryEmployees.filter(
    employee => employee.jira_id,
  );

  return (
    <Modal
      title="Add employee"
      open={isVisible}
      onCancel={hideModal}
      onOk={form.submit}
      okText="Add"
      cancelText="Cancel"
      maskClosable
      confirmLoading={isLoading}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Item label="Team" required name="team_id">
          <Select
            getPopupContainer={getPopupContainerHandler}
            showSearch
            optionFilterProp="children"
            filterOption={onFilterOption}
          >
            {teams.map(item => (
              <Option key={item.id} value={item.id}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          label="1C ID"
          name="internal_id"
          rules={[
            {
              required: true,
              message: 'Field is required',
            },
          ]}
        >
          <Input />
        </Item>
        <Alert
          message="prefix EXT {N} for subcontractors. UA{N} for employees from Ukraine"
          type="info"
          showIcon
          className={styles.alert}
        />
        <Item
          label="Full name (rus)"
          name="name_ru"
          rules={[
            {
              required: true,
              message: 'Field is required',
            },
          ]}
        >
          <Input />
        </Item>
        <Item
          label="Position"
          name="employee_position_id"
          rules={[
            {
              required: true,
              message: 'Field is required',
            },
          ]}
        >
          <Select
            getPopupContainer={e => e.parentElement || e}
            showSearch
            optionFilterProp="children"
            filterOption={onFilterOption}
          >
            {positions.map(item => (
              <Option key={item.id} value={item.id}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          label="Organization"
          name="organization_id"
          rules={[
            {
              required: true,
              message: 'Field is required',
            },
          ]}
        >
          <Select
            getPopupContainer={getPopupContainerHandler}
            showSearch
            optionFilterProp="children"
            filterOption={onFilterOption}
          >
            {organizations.map(item => (
              <Option value={item.id} key={item.id}>
                {item.friendly_name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          label="Resource manager"
          name="resource_manager_id"
          rules={[
            {
              required: true,
              message: 'Field is required',
            },
          ]}
        >
          <UserSelect data={filteredEmployees} isReturnAccountId />
        </Item>
        <Item
          label="Location"
          name="location"
          rules={[
            {
              required: true,
              pattern: LOCATION_PATTERN,
              message: 'Wrong location format',
            },
          ]}
        >
          <Input placeholder="Format: Minsk, Belarus" />
        </Item>
      </Form>
    </Modal>
  );
};

EmployeeAddForm.propTypes = {
  teams: PropTypes.array.isRequired,
  positions: PropTypes.array.isRequired,
  organizations: PropTypes.array.isRequired,
  summaryEmployees: PropTypes.array.isRequired,
  isVisible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default EmployeeAddForm;
