import { tooltipPercentFormatter } from 'containers/DashboardSubcontractors/utils';
import { getMonthNameWithYear, getArrayOfMonth } from 'utils/helpers';

export const getGpmData = (monthData, period) => {
  const arrayOfMonth = getArrayOfMonth(period);

  return (
    monthData &&
    arrayOfMonth.map(month => {
      const monthItemData = Object.entries(monthData).find(
        item => item[0] === month,
      );

      return {
        month,
        type: 'GPM',
        value: monthItemData ? +monthItemData[1].gpm : 0,
      };
    })
  );
};

export const getGpmConfig = data => ({
  data,
  xField: 'month',
  yField: 'value',
  seriesField: 'type',
  xAxis: {
    type: 'time',
    label: {
      formatter: getMonthNameWithYear,
    },
  },
  yAxis: {
    label: {
      formatter: value => `${value}%`,
    },
  },
  tooltip: {
    formatter: tooltipPercentFormatter,
  },
  legend: {
    maxRow: 3,
    flipPage: false,
  },
});
