/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { Popover, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import AmountCell from 'components/ExtraPaymentsCommon/AmountCell';
import AvatarWithName from 'components/AvatarWithName';
import Tag from 'components/Common/Tag';
import TableActions from 'components/Common/TableActions';
import TableButton from 'components/Common/TableButton';
import { getInitials } from 'utils/getInitials';
import { hasRights } from 'utils/permissions';
import {
  DATE_MONTH_FORMAT,
  EXTRA_PAYMENT_FORMAT,
  Y_M_D_H_M_ZONE,
} from 'utils/timeConstants';

import {
  DEFAULT_AVATAR,
  EP_PERMISSIONS,
  EXTRA_PAYMENTS_TABLE_CONTENT,
  EXTRA_PAYMENT_STATUS,
  STATUS_INFO,
} from './constants';
import styles from './styles.scss';

export const getStatusTag = status => (
  <Tag color={get(STATUS_INFO, [status, 'color'], '')}>
    {get(STATUS_INFO, [status, 'title'], 'undefined')}
  </Tag>
);

const getAvatarAuthor = ({ name, icon }) => (
  <AvatarWithName
    payload={getInitials(name) || name}
    avatarUrl={icon || DEFAULT_AVATAR}
    title={name}
  />
);

const getFilters = filters =>
  filters &&
  Object.entries(filters)
    .map(([id, name]) => ({ value: id, text: name }))
    .sort((a, b) => a.text.localeCompare(b.text));

const filteredValue = (valueType, filterInfo) =>
  (filterInfo && filterInfo[valueType]) || null;

const getPopupContainer = e => e.parentElement || e;

const getSortOrder = (sortInfo, condition) =>
  sortInfo.field === condition && sortInfo.order;

export const getColumns = ({
  onDelete,
  onShowModal,
  onApproveExtraPayment,
  onDeclineExtraPayment,
  filtersInfo,
  filtersData,
  sortInfo,
  role,
  user,
}) => {
  const canUpdate = hasRights(role, EP_PERMISSIONS.updatePayment);
  const canUpdateOwn = hasRights(role, EP_PERMISSIONS.updateOwnPayment);
  const canDelete = hasRights(role, EP_PERMISSIONS.deletePayment);

  return [
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.id_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.id,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.id,
      align: 'center',
      width: 70,
      sorter: true,
      sortOrder: getSortOrder(sortInfo, EXTRA_PAYMENTS_TABLE_CONTENT.id),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.internal_id_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.employee_internal_id,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.employee_internal_id,
      align: 'center',
      width: 120,
      sorter: true,
      sortOrder: getSortOrder(
        sortInfo,
        EXTRA_PAYMENTS_TABLE_CONTENT.employee_internal_id,
      ),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.employee_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.employee_full_name,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.employee_id,
      filters: getFilters(filtersData.employees),
      filteredValue: filteredValue('employee_id', filtersInfo),
      filterSearch: true,
      render: (value, { employee_id }) => (
        <Link to={`/profiles/${employee_id}`}>{value}</Link>
      ),
      align: 'center',
      width: 150,
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.employee_profile_name_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.employee_profile_name,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.employee_profile_name,
      align: 'center',
      width: 150,
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.period_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.valid_from,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.valid_from,
      render: value => value && dayjs(value).format(EXTRA_PAYMENT_FORMAT),
      width: 115,
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.amount_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.amount_usd,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.amount_usd,
      sorter: true,
      sortOrder: getSortOrder(
        sortInfo,
        EXTRA_PAYMENTS_TABLE_CONTENT.amount_usd,
      ),
      width: 150,
      render: (value, { amount_byn, amount_usd }) => (
        <>
          {amount_byn && <AmountCell amount={amount_byn} code="BYR" />}
          {amount_usd && <AmountCell amount={amount_usd} code="USD" />}
          {'\t'}
        </>
      ),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.description_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.title,
      width: 160,
      render: (value, record) => (
        <Popover
          placement="bottom"
          title="Description"
          content={
            <div className={styles.popoverDescription}>
              {get(record, 'description') || 'Description is missing'}
            </div>
          }
          trigger="hover"
        >
          <div className={styles.field}>{value}</div>
        </Popover>
      ),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.project_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.project_id,
      isShowColumn: false,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.project_name,
      filters: getFilters(filtersData.projects),
      filterSearch: true,
      filteredValue: filteredValue(
        EXTRA_PAYMENTS_TABLE_CONTENT.project_id,
        filtersInfo,
      ),
      width: 150,
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.status_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.status,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.status,
      render: value => getStatusTag(value),
      filters: Object.keys(STATUS_INFO).map(value => ({
        text: STATUS_INFO[value].title,
        value: value.toLowerCase(),
      })),
      width: 120,
      filteredValue: filteredValue(
        EXTRA_PAYMENTS_TABLE_CONTENT.status,
        filtersInfo,
      ),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.author_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.author,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.author_id,
      render: value =>
        value && getAvatarAuthor({ name: value.name, icon: value.icon }),
      filters: getFilters(filtersData.authors),
      filterSearch: true,
      filteredValue: filteredValue(
        EXTRA_PAYMENTS_TABLE_CONTENT.author_id,
        filtersInfo,
      ),
      width: 110,
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.created_at_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.created_at,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.created_at,
      isShowColumn: false,
      width: 60,
      render: value => (
        <Tooltip
          getPopupContainer={e => e.parentElement || e}
          title={value && dayjs(value).format(Y_M_D_H_M_ZONE)}
        >
          {value && dayjs(value).format(DATE_MONTH_FORMAT)}
        </Tooltip>
      ),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.reviewed_at_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.reviewed_at,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.reviewed_at,
      isShowColumn: false,
      width: 60,
      render: value => {
        const rowValue = value ? dayjs(value).format(DATE_MONTH_FORMAT) : '';

        return (
          <Tooltip
            getPopupContainer={getPopupContainer}
            title={value && dayjs(value).format(Y_M_D_H_M_ZONE)}
          >
            {rowValue}
          </Tooltip>
        );
      },
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.reviewer_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.reviewer,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.reviewer_id,
      render: value =>
        value && getAvatarAuthor({ name: value.name, icon: value.icon }),
      filters: getFilters(filtersData.reviewers),
      filterSearch: true,
      filteredValue: filteredValue(
        EXTRA_PAYMENTS_TABLE_CONTENT.reviewer_id,
        filtersInfo,
      ),
      width: 120,
    },
    {
      title: ' ',
      key: 'actions',
      dataIndex: 'actions',
      width: 100,
      align: 'center',
      hidden: !hasRights(role, EP_PERMISSIONS.approveDeclinePayment),
      render: (value, record) => (
        <>
          <TableButton
            type="primary"
            disabled={record.status === EXTRA_PAYMENT_STATUS.APPROVED}
            onClick={() => onApproveExtraPayment({ id: record.id })}
            icon={<CheckOutlined />}
            className={styles.approveButton}
          />
          <TableButton
            disabled={record.status === EXTRA_PAYMENT_STATUS.DECLINED}
            ghost
            danger
            onClick={() => onDeclineExtraPayment({ id: record.id })}
            icon={<CloseOutlined />}
          />
        </>
      ),
    },
    {
      title: ' ',
      key: 'more',
      dataIndex: 'more',
      width: 50,
      align: 'center',
      render: (value, record) => (
        <TableActions
          onEdit={() => onShowModal(record)}
          onDelete={() => onDelete(record.id)}
          canEdit={
            (canUpdate || (canUpdateOwn && record.author_id === user.id)) &&
            !record.is_social_request
          }
          canDelete={canDelete && !record.is_social_request}
        />
      ),
    },
  ];
};

export const getFiltersWithOutNull = filtersInfo =>
  Object.keys(filtersInfo).reduce(
    (acc, item) =>
      filtersInfo[item] ? { ...acc, [item]: filtersInfo[item] } : acc,
    {},
  );
