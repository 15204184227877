import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isPositionsLoading: false,
  positions: [],
  users: [],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_POSITIONS:
    case types.ADD_POSITION:
    case types.EDIT_POSITION:
    case types.DELETE_POSITION: {
      return state.set('isPositionsLoading', true).set('users', []);
    }

    case types.GET_POSITIONS_SUCCESS: {
      return state
        .set('positions', action.payload)
        .set('isPositionsLoading', false);
    }
    case types.ADD_POSITION_SUCCESS: {
      return state
        .set('positions', [...state.get('positions'), action.payload])
        .set('isPositionsLoading', false);
    }

    case types.ADD_POSITION_FAILED:
    case types.GET_POSITIONS_FAILED:
    case types.EDIT_POSITION_FAILED:
    case types.DELETE_POSITION_FAILED: {
      return state.set('isPositionsLoading', false).set('users', []);
    }
    case types.DELETE_POSITION_ERRORS: {
      return state.set('users', []);
    }

    default:
      return state;
  }
}
