export const types = {
  GET_EXTRA_PAYMENTS: 'extra-payments/GET_EXTRA_PAYMENTS',
  GET_EXTRA_PAYMENTS_SUCCESS: 'extra-payments/GET_EXTRA_PAYMENTS_SUCCESS',
  GET_EXTRA_PAYMENTS_FAILED: 'extra-payments/GET_EXTRA_PAYMENTS_FAILED',

  DELETE_EXTRA_PAYMENT: 'extra-payments/DELETE_EXTRA_PAYMENT',
  DELETE_EXTRA_PAYMENT_SUCCESS: 'extra-payments/DELETE_EXTRA_PAYMENT_SUCCESS',
  DELETE_EXTRA_PAYMENT_FAILED: 'extra-payments/DELETE_EXTRA_PAYMENT_FAILED',

  UPDATE_EXTRA_PAYMENT: 'extra-payments/UPDATE_EXTRA_PAYMENTS',
  UPDATE_EXTRA_PAYMENT_SUCCESS: 'extra-payments/UPDATE_EXTRA_PAYMENT_SUCCESS',
  UPDATE_EXTRA_PAYMENT_FAILED: 'extra-payments/UPDATE_EXTRA_PAYMENT_FAILED',

  APPROVE_EXTRA_PAYMENT: 'extra-payments/APPROVE_EXTRA_PAYMENT',
  APPROVE_EXTRA_PAYMENT_SUCCESS: 'extra-payments/APPROVE_EXTRA_PAYMENT_SUCCESS',
  APPROVE_EXTRA_PAYMENT_FAILED: 'extra-payments/APPROVE_EXTRA_PAYMENT_FAILED',

  DECLINE_EXTRA_PAYMENT: 'extra-payments/DECLINE_EXTRA_PAYMENT',
  DECLINE_EXTRA_PAYMENT_SUCCESS: 'extra-payments/DECLINE_EXTRA_PAYMENT_SUCCESS',
  DECLINE_EXTRA_PAYMENT_FAILED: 'extra-payments/DECLINE_EXTRA_PAYMENT_FAILED',
};
