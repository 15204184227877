/* eslint-disable indent */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { trim } from 'lodash';
import { Button, Tag, Typography, Modal, Table as AntdTable, Form } from 'antd';

import { TIME_FORMAT } from 'containers/ClientsPage/constants';
import { ProjectChart } from 'components/ProjectProfitsCommon';
import RevenueModal from 'components/ProjectProfitsCommon/RevenueModal';
import { Loader } from 'components/Common';
import DatePicker from 'components/Common/DatePicker';
import ExtraPaymentsModal from 'components/ProjectProfitsCommon/ExtraPaymentsModal';
import { getFiltersWithOutNull } from 'utils/common';
import { OK_STATUS, SORT_DIRECTIONS } from 'utils/constants';
import {
  DATE_FORMAT,
  DATE_MONTH_FORMAT,
  MONTH_FORMAT,
} from 'utils/timeConstants';
import { getRowKey } from 'utils/helpers';
import { usePrevState, useRoleContext } from 'utils/hooks';
import { hasRights } from 'utils/permissions';
import { projectProfitsSelector } from 'redux/selectors';
import {
  addProjectExtraPayment,
  calculateProjectProfits,
  deleteRevenueFromProject,
  getInfoAboutProjectProfitsByYear,
  getManagerProfiles,
  getProjectProfits,
  updateProjectProfits,
} from 'redux/ProjectProfits/actions';

import AdjustmentModal from './AdjustmentModal';
import Table from './LowRowTable';
import {
  defaultMonth,
  firebasePath,
  DEFAULT_FILTERS,
  PROJECT_PROFITS_INDEX,
  PP_PERMISSIONS,
} from './constants';
import {
  formatProgressDuration,
  formatProgressStatus,
  getColumns,
  getProjectProfitsMonth,
  getSummary,
  getTagColorByProgressStatus,
  setProjectProfitsMonth,
  getYear,
  getInfoAboutCurrentProject,
  getModalColumns,
  getExtraPaymentsColumns,
  getLastTwelveMonths,
  compareLastCalculate,
  getDataSource,
} from './utils';

import firebase from '../../firebase';

import styles from './styles.scss';

let unsubscribe = null;

async function getFirebaseData(month, setCalculateProgress) {
  const db = firebase.firestore();

  const query = dayjs(month)
    .startOf('month')
    .format(DATE_FORMAT);

  unsubscribe = db
    .collection(firebasePath)
    .doc(query)
    .onSnapshot(doc => {
      setCalculateProgress(doc.data());
    });
}

const ProjectProfits = () => {
  const dispatch = useDispatch();
  const {
    isProjectProfitsLoading,
    projectProfits,
    projectProfitsFilters,
    totals,
    infoAboutProjectProfitsByYear,
    managerProfiles,
    isManagerProfilesLoading,
  } = useSelector(projectProfitsSelector);

  const [month, setMonth] = useState(
    dayjs(getProjectProfitsMonth(), DATE_FORMAT),
  );

  const [titleCurrentProject, setTitleCurrentProject] = useState(
    'Current project',
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [infoAboutCurrentProject, setInfoAboutCurrentProject] = useState([]);
  const [filterInfo, setFilterInfo] = useState(DEFAULT_FILTERS);
  const [sortOrder, setSortOrder] = useState({
    field: PROJECT_PROFITS_INDEX.project_name,
    order: SORT_DIRECTIONS.ascend,
  });
  const [isVisiblePaymentsModal, setIsVisiblePaymentsModal] = useState(false);
  const [isShowExtraPaymentForm, setIsShowExtraPaymentForm] = useState(false);
  const [paymentsFieldValues, setPaymentsFieldValues] = useState({});
  const [extraPaymentsColumns, setExtraPaymentsColumns] = useState([]);
  const [projectExtraPaymentData, setProjectExtraPaymentData] = useState([]);
  const [calculateProgress, setCalculateProgress] = useState(null);
  const [isAdjustmentModalVisible, setIsAdjustmentModalVisible] = useState(
    false,
  );
  const [adjustmentData, setAdjustmentData] = useState(null);
  const [isCalculateLoading, setIsCalculateLoading] = useState(false);
  const [columnsSettings, setColumnsSettings] = useState([]);
  const [revenueModalOpen, setRevenueModalOpen] = useState(null);

  const [revenueForm] = Form.useForm();

  const prevCalculateProgress = usePrevState(calculateProgress);

  const role = useRoleContext();

  const onChangeMonth = newMonth => {
    setMonth(newMonth || defaultMonth);
    setProjectProfitsMonth(newMonth || defaultMonth);
  };

  const setIsCalculateLoadingHanlder = () => {
    setIsCalculateLoading(compareLastCalculate());
  };

  const onCalculate = useCallback(() => {
    localStorage.setItem(
      'last-calculate',
      dayjs(new Date()).format(TIME_FORMAT),
    );
    setIsCalculateLoadingHanlder();
    dispatch(
      calculateProjectProfits(
        dayjs(month)
          .startOf('month')
          .format(DATE_FORMAT),
      ),
    );
  }, [month, calculateProjectProfits, DATE_FORMAT]);

  useEffect(() => {
    setIsCalculateLoadingHanlder();
    const checkCalculateLoading = setInterval(() => {
      setIsCalculateLoadingHanlder();
    }, 30000);

    return () => clearInterval(checkCalculateLoading);
  }, []);

  const onSaveRevenue = ({ revenue, project_id, period }) => {
    dispatch(
      updateProjectProfits({
        period: period,
        projectId: project_id,
        formData: {
          revenue: revenue === '' ? 0 : revenue,
        },
        ...getRequestParams(),
      }),
    );
    setRevenueModalOpen(false);
    revenueForm.resetFields();
  };

  const onClearRevenue = project => {
    dispatch(
      deleteRevenueFromProject({
        period: project.period,
        projectId: project.project_id,
        ...getRequestParams(),
      }),
    );
  };

  const onSaveAdjustment = ({
    adjustment_comment,
    adjustment,
    project_id,
    period,
  }) => {
    const isAdjusmentEmpty = trim(adjustment);
    dispatch(
      updateProjectProfits({
        period: period,
        projectId: project_id,
        formData: {
          adjustment: isAdjusmentEmpty ? adjustment : null,
          adjustment_comment: isAdjusmentEmpty ? adjustment_comment : '',
        },
        ...getRequestParams(),
      }),
    );
    setIsAdjustmentModalVisible(false);
  };

  const onAddExtraPayment = values => {
    dispatch(
      addProjectExtraPayment({
        employee_id: values.employeeId,
        extra_payment: {
          description: `Sent from Projects: bonus for ${values.projectName}`,
          title: `${values.percentage}% of ${values.source}`,
          valid_from: dayjs(values.period).format('YYYY-MM-01'),
          amount_usd: values.amount,
          project_id: paymentsFieldValues.projectId,
          project_period: dayjs(month).format('YYYY-MM-01'),
        },
        ...getRequestParams(),
      }),
    );

    handleClosePaymentsModal();
  };

  const getManagerProfilesHandler = ({ project_profit_id, jira_id }) => {
    dispatch(getManagerProfiles({ project_profit_id, jira_id }));
  };

  const year = useMemo(() => getYear(month), [month]);

  const lastTwelveMonths = useMemo(
    () => getLastTwelveMonths(dayjs(month).format(DATE_FORMAT)),
    [month],
  );

  const modalColumns = useMemo(
    () => getModalColumns({ arrayOfMonth: lastTwelveMonths }),
    [lastTwelveMonths],
  );

  const onShowModal = ({ record, value }) => {
    setIsModalVisible(true);
    setTitleCurrentProject(value);
    dispatch(
      getInfoAboutProjectProfitsByYear({ year, project_id: record.project_id }),
    );
  };

  const onShowAdjustmentModal = ({ record }) => {
    setAdjustmentData(record);
    setIsAdjustmentModalVisible(true);
  };

  const onHideAdjustmentModal = () => setIsAdjustmentModalVisible(false);

  const onShowPaymentsModal = ({
    record: { project_name, revenue = '', profit = '', project_id, id },
    employee_id,
    name,
    field,
    extraPayments,
    jiraId,
  }) => {
    setIsVisiblePaymentsModal(true);
    setPaymentsFieldValues({
      projectName: project_name,
      revenue: revenue,
      profit: profit,
      projectId: project_id,
      employeeId: employee_id,
      name,
      period: month,
      percentage: field === PROJECT_PROFITS_INDEX.project_manager ? 10 : 5,
      field: field,
      jiraId: jiraId,
      id,
    });

    setProjectExtraPaymentData(extraPayments);
    setExtraPaymentsColumns(
      getExtraPaymentsColumns({ name, employeeId: employee_id }),
    );
  };

  const onShowExtraPaymentsForm = () => setIsShowExtraPaymentForm(true);

  const onEditRevenue = (revenue, record) => {
    setRevenueModalOpen(true);
    revenueForm.setFieldsValue({
      revenue,
      project_id: record.project_id,
      period: record.period,
    });
  };

  const onRevenueModalClose = () => {
    revenueForm.resetFields();
    setRevenueModalOpen(false);
  };

  const canRequestPayments = hasRights(role, PP_PERMISSIONS.requestPayment);
  const canOpenProjectModal = hasRights(role, PP_PERMISSIONS.projectModal);

  const columns = useMemo(
    () =>
      getColumns({
        filterInfo,
        onShowModal,
        onShowPaymentsModal,
        onShowAdjustmentModal,
        sortOrder,
        filtersData: projectProfitsFilters,
        onClearRevenue,
        canRequestPayments,
        canOpenProjectModal,
        onEditRevenue,
      }),
    [projectProfits, filterInfo, sortOrder, projectProfitsFilters],
  );

  const getRequestParams = (filters = filterInfo, sort = sortOrder) => ({
    period: dayjs(month)
      .startOf('month')
      .format(DATE_FORMAT),
    sort: `${sort.field}:${sort.order}`,
    filters,
  });

  const onTableChange = (pagination, filters, sorter) => {
    const filtersWithoutNull = getFiltersWithOutNull(filters);
    setSortOrder({
      field: sorter.field,
      order: sorter.order,
    });
    setFilterInfo(filtersWithoutNull);
    dispatch(getProjectProfits(getRequestParams(filtersWithoutNull, sorter)));
  };

  useEffect(() => {
    dispatch(getProjectProfits(getRequestParams()));

    setFilterInfo(DEFAULT_FILTERS);

    unsubscribe && unsubscribe();
    getFirebaseData(month, setCalculateProgress);

    return () => unsubscribe();
  }, [month]);

  useEffect(() => {
    setInfoAboutCurrentProject(
      getInfoAboutCurrentProject({
        infoAboutProjectProfitsByYear,
        arrayOfMonth: lastTwelveMonths,
      }),
    );
  }, [infoAboutProjectProfitsByYear.length && infoAboutProjectProfitsByYear]);

  useEffect(() => {
    if (
      calculateProgress &&
      calculateProgress.status === OK_STATUS &&
      prevCalculateProgress &&
      prevCalculateProgress.status !== OK_STATUS
    ) {
      dispatch(getProjectProfits(getRequestParams()));
    }
  }, [calculateProgress]);

  const handleClose = () => setIsModalVisible(false);
  const handleClosePaymentsModal = () => {
    setIsShowExtraPaymentForm(false);
    setIsVisiblePaymentsModal(false);
  };

  useEffect(() => {
    const settings = JSON.parse(
      localStorage.getItem(`${window.location.pathname}-`),
    );

    const modifiedColumns = settings
      ? columns.map(column => ({
          ...column,
          isShowColumn: settings[column.dataIndex].isShowColumn,
          width: settings[column.dataIndex].width,
        }))
      : columns.map(column => ({
          ...column,
          isShowColumn: column.isShowColumn !== false,
        }));

    setColumnsSettings(modifiedColumns);
  }, []);

  const onSetColumnsSettings = data => {
    setColumnsSettings(data);
  };

  const dataSource = useMemo(() => getDataSource(projectProfits), [
    projectProfits,
    projectProfitsFilters,
  ]);

  const getSummaryHandler = () => getSummary(totals, columnsSettings);

  return (
    <div className={styles.projectProfitsWrapper}>
      <div className={styles.projectProfitsContainer}>
        <div className={styles.projectProfits}>
          <div>
            <Typography.Title level={3}>
              Report: Project Profits
            </Typography.Title>
          </div>
          <div className={styles.projectProfitsControls}>
            {calculateProgress && (
              <>
                <div className={styles.controlsItem}>
                  {calculateProgress.status === OK_STATUS
                    ? `Last updated: ${dayjs(
                        calculateProgress.updated_at.seconds * 1000,
                      ).format(DATE_MONTH_FORMAT)}`
                    : formatProgressDuration(calculateProgress.duration)}
                </div>
                <div className={styles.controlsItem}>
                  <Tag
                    color={getTagColorByProgressStatus(
                      calculateProgress.status,
                    )}
                  >
                    {formatProgressStatus(calculateProgress)}
                  </Tag>
                </div>
              </>
            )}
            <div className={styles.controlsItem}>
              <Loader loading={isProjectProfitsLoading}>
                <DatePicker
                  picker="month"
                  value={month}
                  onChange={onChangeMonth}
                  format={MONTH_FORMAT}
                />
              </Loader>
            </div>
            <div className={styles.controlsItem}>
              <Button
                type="primary"
                onClick={onCalculate}
                loading={
                  calculateProgress &&
                  (calculateProgress.progress < 100 && isCalculateLoading)
                    ? true
                    : false
                }
              >
                Calculate
              </Button>
            </div>
          </div>
        </div>
        <RevenueModal
          open={revenueModalOpen}
          onSave={onSaveRevenue}
          form={revenueForm}
          onClose={onRevenueModalClose}
        />
        <AdjustmentModal
          isVisible={isAdjustmentModalVisible}
          onFinish={onSaveAdjustment}
          onHideModal={onHideAdjustmentModal}
          adjustmentData={adjustmentData}
        />
        <div className={styles.projectProfitsTable}>
          <Loader loading={isProjectProfitsLoading}>
            <Table
              columns={columns}
              dataSource={dataSource}
              bordered
              pagination={false}
              scroll={{ x: 1360, y: 'calc(100vh - 230px)' }}
              size="small"
              onChange={onTableChange}
              summary={getSummaryHandler}
              setColumnsSettings={onSetColumnsSettings}
              rowKey={getRowKey}
            />
            <ExtraPaymentsModal
              isVisible={isVisiblePaymentsModal}
              onClose={handleClosePaymentsModal}
              isShowExtraPaymentForm={isShowExtraPaymentForm}
              onShowExtraPaymentsForm={onShowExtraPaymentsForm}
              paymentsFieldValues={paymentsFieldValues}
              columns={extraPaymentsColumns}
              dataSource={projectExtraPaymentData}
              onAddExtraPayment={onAddExtraPayment}
              getManagerProfilesHandler={getManagerProfilesHandler}
              isManagerProfilesLoading={isManagerProfilesLoading}
              managerProfiles={managerProfiles}
            />
            <Modal
              title={titleCurrentProject}
              open={isModalVisible}
              width="1600"
              onCancel={handleClose}
              footer={[
                <Button onClick={handleClose} type="primary">
                  Close
                </Button>,
              ]}
            >
              <AntdTable
                pagination={false}
                columns={modalColumns}
                dataSource={infoAboutCurrentProject}
                bordered
                scroll={{ x: 1360 }}
                style={{ fontSize: '0.8rem' }}
                size="small"
              />
              <ProjectChart infoAboutCurrentProject={infoAboutCurrentProject} />
            </Modal>
          </Loader>
        </div>
      </div>
    </div>
  );
};

export default ProjectProfits;
