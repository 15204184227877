import { takeLatest, call, put } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';

import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';
import { types } from './types';

function* getExtraPayments({ payload }) {
  try {
    const response = yield call(API.loadExtraPayments, {
      ...payload,
    });
    yield put({ type: types.GET_EXTRA_PAYMENTS_SUCCESS, payload: response });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_EXTRA_PAYMENTS_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* deleteExtraPayments({ payload: { id, filters } }) {
  try {
    yield call(API.deleteExtraPayments, id);
    yield call(getExtraPayments, { payload: { filters } });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_EXTRA_PAYMENT_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}
function* editExtraPayment({
  payload: { currentExtraPaymentId, filters, ...payload },
}) {
  try {
    yield call(API.updateExtraPayments, currentExtraPaymentId, payload);
    yield call(getExtraPayments, { payload: { filters } });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_EXTRA_PAYMENT_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* approveExtraPayment({ payload: { id, filters } }) {
  try {
    yield call(API.approveExtraPayment, id);
    yield call(getExtraPayments, { payload: { filters } });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.APPROVE_EXTRA_PAYMENT_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* declineExtraPayment({ payload: { id, filters } }) {
  try {
    yield call(API.declineExtraPayment, id);
    yield call(getExtraPayments, { payload: { filters } });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DECLINE_EXTRA_PAYMENT_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_EXTRA_PAYMENTS, getExtraPayments);
  yield takeLatest(types.DELETE_EXTRA_PAYMENT, deleteExtraPayments);
  yield takeLatest(types.UPDATE_EXTRA_PAYMENT, editExtraPayment);
  yield takeLatest(types.APPROVE_EXTRA_PAYMENT, approveExtraPayment);
  yield takeLatest(types.DECLINE_EXTRA_PAYMENT, declineExtraPayment);
}
