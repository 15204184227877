exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".button-a2c22{padding:0}.text_expanded-8ed09{max-height:none}.text_expanded-8ed09:after{content:\"\";display:none}.noteInfo-e4305{display:flex;justify-content:space-between;align-items:center}.noteMenu-dad3f{cursor:pointer}.noteContainer-56872{display:flex;flex-direction:column}.noteContainer-56872 p{width:80%}.noteContainer-56872 .content-65a29{padding:12px 20px;border:1px solid #d9d9d9;border-radius:8px}.noteContainer-56872 .created-cae18{font-size:14px;color:#8c8c8c}.tools-215d5 svg{cursor:pointer;margin-left:10px;width:20px;height:20px}.noteButtons-2d3b0{margin-top:5px}.saveButton-4cb68{margin-left:5px}", ""]);

// Exports
exports.locals = {
	"button": "button-a2c22",
	"text_expanded": "text_expanded-8ed09",
	"noteInfo": "noteInfo-e4305",
	"noteMenu": "noteMenu-dad3f",
	"noteContainer": "noteContainer-56872",
	"content": "content-65a29",
	"created": "created-cae18",
	"tools": "tools-215d5",
	"noteButtons": "noteButtons-2d3b0",
	"saveButton": "saveButton-4cb68"
};