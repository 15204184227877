/* eslint-disable indent */
import React from 'react';
import { Upload } from 'antd';
import dayjs from 'dayjs';

import { hasRights } from 'utils/permissions';
import { DATE_MONTH_FORMAT } from 'utils/timeConstants';
import TableActions from 'components/Common/TableActions';
import { CONTRACTS_PERMISSIONS, CONTRACTS_TABLE_COMMON } from './constants';

const filteredValue = (valueType, filterInfo) =>
  (filterInfo && filterInfo[valueType]) || null;

const getFilters = filters =>
  filters?.map(({ id, name }) => ({ text: name, value: id })) || [];

const getSortOrder = (condition, sortInfo) =>
  sortInfo.field === condition && sortInfo.order;

export const getColumns = ({
  filtersInfo,
  sortInfo,
  role,
  deleteContract,
  onEditRecord,
  filtersData,
}) => [
  {
    title: CONTRACTS_TABLE_COMMON.id_title,
    dataIndex: CONTRACTS_TABLE_COMMON.id,
    key: CONTRACTS_TABLE_COMMON.id,
    sorter: true,
    sortOrder: getSortOrder(CONTRACTS_TABLE_COMMON.id, sortInfo),
  },
  {
    title: CONTRACTS_TABLE_COMMON.contractor,
    dataIndex: CONTRACTS_TABLE_COMMON.contractor_title,
    key: CONTRACTS_TABLE_COMMON.contractor_id,
    sorter: true,
    sortOrder: getSortOrder(CONTRACTS_TABLE_COMMON.contractor_title, sortInfo),
    filters: getFilters(filtersData.contractors),
    filteredValue: filteredValue(
      CONTRACTS_TABLE_COMMON.contractor_id,
      filtersInfo,
    ),
  },
  {
    title: CONTRACTS_TABLE_COMMON.customer,
    dataIndex: CONTRACTS_TABLE_COMMON.customer_title,
    key: CONTRACTS_TABLE_COMMON.customer_id,
    sorter: true,
    sortOrder: getSortOrder(CONTRACTS_TABLE_COMMON.customer_title, sortInfo),
    filters: getFilters(filtersData.customers),
    filteredValue: filteredValue(
      CONTRACTS_TABLE_COMMON.customer_id,
      filtersInfo,
    ),
  },
  {
    title: CONTRACTS_TABLE_COMMON.owner,
    dataIndex: CONTRACTS_TABLE_COMMON.owner_name,
    key: CONTRACTS_TABLE_COMMON.owner_id,
    sorter: true,
    sortOrder: getSortOrder(CONTRACTS_TABLE_COMMON.owner_name, sortInfo),
    filters: getFilters(filtersData.owners),
    filteredValue: filteredValue(CONTRACTS_TABLE_COMMON.owner_id, filtersInfo),
  },
  {
    title: CONTRACTS_TABLE_COMMON.attachments_title,
    dataIndex: CONTRACTS_TABLE_COMMON.attachments,
    key: CONTRACTS_TABLE_COMMON.attachments,
    render: attachments => {
      const fileList = attachments
        ? attachments.map(attachment => ({
            name: attachment.title,
            status: 'done',
            url: attachment.url,
            uid: `fileTable__${attachment.title}`,
          }))
        : [];

      return (
        <Upload
          showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
          fileList={fileList}
        />
      );
    },
  },
  {
    title: CONTRACTS_TABLE_COMMON.dated_title,
    dataIndex: CONTRACTS_TABLE_COMMON.dated,
    key: CONTRACTS_TABLE_COMMON.dated,
    sorter: true,
    sortOrder: getSortOrder(CONTRACTS_TABLE_COMMON.dated, sortInfo),
    render: value => dayjs(value).format(DATE_MONTH_FORMAT),
  },
  {
    title: CONTRACTS_TABLE_COMMON.number_title,
    dataIndex: CONTRACTS_TABLE_COMMON.number,
    key: CONTRACTS_TABLE_COMMON.number,
    sorter: true,
    sortOrder: getSortOrder(CONTRACTS_TABLE_COMMON.number, sortInfo),
  },
  {
    title: CONTRACTS_TABLE_COMMON.start_date_title,
    dataIndex: CONTRACTS_TABLE_COMMON.start_date,
    key: CONTRACTS_TABLE_COMMON.start_date,
    sorter: true,
    sortOrder: getSortOrder(CONTRACTS_TABLE_COMMON.start_date, sortInfo),
    render: value => dayjs(value).format(DATE_MONTH_FORMAT),
  },
  {
    title: CONTRACTS_TABLE_COMMON.end_date_title,
    dataIndex: CONTRACTS_TABLE_COMMON.end_date,
    key: CONTRACTS_TABLE_COMMON.end_date,
    sorter: true,
    sortOrder: getSortOrder(CONTRACTS_TABLE_COMMON.end_date, sortInfo),
    render: value => (value ? dayjs(value).format(DATE_MONTH_FORMAT) : '-'),
  },
  {
    title: CONTRACTS_TABLE_COMMON.created_at_title,
    dataIndex: CONTRACTS_TABLE_COMMON.created_at,
    key: CONTRACTS_TABLE_COMMON.created_at,
    sorter: true,
    sortOrder: getSortOrder(CONTRACTS_TABLE_COMMON.created_at, sortInfo),
    render: value => dayjs(value).format(DATE_MONTH_FORMAT),
  },
  {
    title: CONTRACTS_TABLE_COMMON.updated_at_title,
    dataIndex: CONTRACTS_TABLE_COMMON.updated_at,
    key: CONTRACTS_TABLE_COMMON.updated_at,
    sorter: true,
    sortOrder: getSortOrder(CONTRACTS_TABLE_COMMON.updated_at, sortInfo),
    render: value => dayjs(value).format(DATE_MONTH_FORMAT),
  },
  {
    title: ' ',
    dataIndex: 'action',
    width: 50,
    align: 'center',
    key: 'action',
    render: (_, record) => (
      <TableActions
        onEdit={() => onEditRecord(record)}
        onDelete={() => deleteContract(record)}
        canDelete={hasRights(role, CONTRACTS_PERMISSIONS.deleteContract)}
        canEdit
      />
    ),
  },
];
