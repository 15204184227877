import React from 'react';
import TableActions from 'components/Common/TableActions';

export const getColumns = ({ onDelete, showEditModal }) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Position',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Delivery',
    dataIndex: 'is_delivery',
    key: 'is_delivery',
    render: value => (value ? 'Yes' : 'No'),
  },
  {
    title: ' ',
    dataIndex: 'action',
    key: 'action',
    width: 50,
    align: 'center',
    render: (_, record) => (
      <TableActions
        onDelete={() => onDelete(record.id)}
        onEdit={() => showEditModal(record)}
      />
    ),
  },
];
