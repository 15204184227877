import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isCostRatesLoading: false,
  subcontractors: [],
  subcontractorsFilters: {},
  updatedDate: '',
});

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_SUBCONTRACTORS:
      return state.set('isCostRatesLoading', true);
    case types.GET_SUBCONTRACTORS_SUCCESS:
      return state
        .set('isCostRatesLoading', false)
        .set('subcontractors', payload.data)
        .set('subcontractorsFilters', payload.data_for_filters)
        .set('updatedDate', payload.updated_date);
    case types.GET_SUBCONTRACTORS_FAILED:
      return state.set('isCostRatesLoading', false);
    default:
      return state;
  }
}
