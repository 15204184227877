export const SUMMARY_TABLE_COMMON = {
  name_ru: 'name_ru',
  name_ru_title: 'Full name (rus)',
  name_en: 'name_en',
  name_en_title: 'Full name (eng)',
  position: 'employee_position',
  position_title: 'Position',
  location: 'location',
  location_title: 'Location',
  rm: 'resource_manager',
  rm_title: 'Resource manager',
  buddy: 'buddies',
  buddy_title: 'Buddy',
  email: 'email',
  email_title: 'Email',
  status: 'status',
  status_title: 'Status',
  team_title: 'Team',
  team: 'team_id',
};

export const SUMMARY_STATUSE_COLORS = {
  WORKS: 'green',
  FIRED: 'red',
};

export const SUMMARY_WORKS = 'WORKS';
export const SUMMARY_FIRED = 'FIRED';
