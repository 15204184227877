/* eslint-disable indent */
export const getFileList = data =>
  data
    ? data.map((item, index) => ({
        name: item.title,
        url: item.url ? item.url : '',
        status: 'done',
        uid: `${index}__${item.title}`,
      }))
    : [];
