import dayjs from 'dayjs';

export const fieldSet = new Set([
  'iban',
  'phone',
  'birthday',
  'contract_starts_at',
  'contract_ends_at',
]);

export const setEmployeeFieldsValue = ({ employee, form }) => {
  employee &&
    form.setFieldsValue({
      ...employee,
      contract_starts_at:
        employee.contract_starts_at && dayjs(employee.contract_starts_at),
      contract_ends_at:
        employee.contract_ends_at && dayjs(employee.contract_ends_at),
      fired_at: employee.fired_at && dayjs(employee.fired_at),
      birthday: employee.birthday && dayjs(employee.birthday),
      resource_manager: employee.resource_manager?.id,
      buddies: employee.buddies?.map(buddy => buddy.id),
      jira_id: employee.jira_user && employee.jira_user.id,
      email: employee?.jira_user
        ? employee?.jira_user?.emailAddress
        : employee?.email,
    });
};
