export const types = {
  SET_TABLE_DATA: 'resource-management/SET_TABLE_DATA',

  CREATE_PLAN: 'resource-management/CREATE_PLAN',
  CREATE_PLAN_SUCCESS: 'resource-management/CREATE_PLAN_SUCCESS',
  CREATE_PLAN_FAILED: 'resource-management/CREATE_PLAN_FAILED',

  UPDATE_PLAN_BY_ID: 'resource-management/UPDATE_PLAN_BY_ID',
  UPDATE_PLAN_BY_ID_SUCCESS: 'resource-management/UPDATE_PLAN_BY_ID_SUCCESS',
  UPDATE_PLAN_BY_ID_FAILED: 'resource-management/UPDATE_PLAN_BY_ID_FAILED',

  DELETE_PLAN_BY_ID: 'resource-management/DELETE_PLAN_BY_ID',
  DELETE_PLAN_BY_ID_SUCCESS: 'resource-management/DELETE_PLAN_BY_ID_SUCCESS',
  DELETE_PLAN_BY_ID_FAILED: 'resource-management/DELETE_PLAN_BY_ID_FAILED',

  GET_DATA: 'resource-management/GET_DATA',
  GET_DATA_SUCCESS: 'resource-management/GET_DATA_SUCCESS',
  GET_DATA_FAILED: 'resource-management/GET_DATA_FAILED',

  CREATE_PLAN_BY_TYPE: 'resource-management/CREATE_PLAN_BY_TYPE',
  CREATE_PLAN_BY_TYPE_SUCCESS:
    'resource-management/CREATE_PLAN_BY_TYPE_SUCCESS',
  CREATE_PLAN_BY_TYPE_FAILED: 'resource-management/CREATE_PLAN_BY_TYPE_FAILED',

  UPDATE_PLAN_BY_TYPE: 'resource-management/UPDATE_PLAN_BY_TYPE',
  UPDATE_PLAN_BY_TYPE_SUCCESS:
    'resource-management/UPDATE_PLAN_BY_TYPE_SUCCESS',
  UPDATE_PLAN_BY_TYPE_FAILED: 'resource-management/UPDATE_PLAN_BY_TYPE_FAILED',

  DELETE_PLAN_BY_TYPE: 'resource-management/DELETE_PLAN_BY_TYPE',
  DELETE_PLAN_BY_TYPE_SUCCESS:
    'resource-management/DELETE_PLAN_BY_TYPE_SUCCESS',
  DELETE_PLAN_BY_TYPE_FAILED: 'resource-management/DELETE_PLAN_BY_TYPE_FAILED',

  GET_RM_EMPLOYEES: 'resource-management/GET_RM_EMPLOYEES',
  GET_RM_EMPLOYEES_SUCCESS: 'resource-management/GET_RM_EMPLOYEES_SUCCESS',
  GET_RM_EMPLOYEES_FAILED: 'resource-management/GET_RM_EMPLOYEES_FAILED',

  GET_RM_PROJECTS: 'resource-management/GET_RM_PROJECTS',
  GET_RM_PROJECTS_SUCCESS: 'resource-management/GET_RM_PROJECTS_SUCCESS',
  GET_RM_PROJECTS_FAILED: 'resource-management/GET_RM_PROJECTS_FAILED',
};
