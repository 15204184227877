import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import DatePicker from 'components/Common/DatePicker';
import Select, { Option } from 'components/Common/Select';
import TextArea from 'components/Common/TextArea';

import { getPopupContainerHandler } from 'utils/helpers';
import styles from './styles.scss';

const { Item } = Form;

const ClientInvoiceDetailsForm = ({
  detailsForm,
  invoiceTemplates,
  currencies,
  contracts,
  organizations,
  contractorAccounts,
  customerAccounts,
  isAccountsLoading,
  onFormValuesChange,
  formHeight,
}) => (
  <Form
    form={detailsForm}
    onValuesChange={onFormValuesChange}
    layout="vertical"
    className={styles.detailsForm}
    style={{ height: `${formHeight}vh` }}
  >
    <Item
      label="Invoice template"
      name="invoice_template_id"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
      className={styles.formItem}
    >
      <Select
        className={styles.detailsInput}
        placeholder="Templates"
        getPopupContainer={getPopupContainerHandler}
      >
        {invoiceTemplates?.map(template => (
          <Option value={template.id} key={template.id}>
            {template.title}
          </Option>
        ))}
      </Select>
    </Item>
    <Item
      label="Select currency"
      name="currency_code"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
      className={styles.formItem}
    >
      <Select
        className={styles.detailsInput}
        placeholder="Currencies"
        getPopupContainer={getPopupContainerHandler}
      >
        {currencies?.map(item => (
          <Option value={item.name} key={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    </Item>
    <Item noStyle>
      <Item
        label="Issue date"
        name="issue_date"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        className={styles.formItem}
      >
        <DatePicker />
      </Item>
      <Item label="Due date" name="due_date" className={styles.formItem}>
        <DatePicker />
      </Item>
    </Item>
    <Item
      label="Select contract"
      name="contract_id"
      className={styles.formItem}
    >
      <Select
        className={styles.detailsInput}
        placeholder="Contracts"
        getPopupContainer={getPopupContainerHandler}
      >
        {contracts?.map(contract => (
          <Option key={contract.id} value={contract.id}>
            {contract.number}
          </Option>
        ))}
      </Select>
    </Item>
    <Item
      label="Select contractor"
      name="contractor_id"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
      className={styles.formItem}
    >
      <Select
        className={styles.detailsInput}
        placeholder="Contractors"
        showSearch
        filterOption={(input, option) =>
          `${option.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        getPopupContainer={getPopupContainerHandler}
      >
        {organizations?.map(organization => (
          <Option key={organization.id} value={organization.id}>
            {organization.friendly_name}
          </Option>
        ))}
      </Select>
    </Item>
    <Item
      label="Select contractor account"
      name="contractor_account_id"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
      className={styles.formItem}
    >
      <Select
        className={styles.detailsInput}
        placeholder="Contractors accounts"
        loading={isAccountsLoading}
        getPopupContainer={getPopupContainerHandler}
      >
        {contractorAccounts?.map(organization => (
          <Option key={organization.id} value={organization.id}>
            {organization.title} {organization.account_number}{' '}
            {organization.currency_code}
          </Option>
        ))}
      </Select>
    </Item>
    <Item
      label="Select customer"
      name="customer_id"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
      className={styles.formItem}
    >
      <Select
        className={styles.detailsInput}
        placeholder="Customers"
        showSearch
        filterOption={(input, option) =>
          `${option.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        getPopupContainer={getPopupContainerHandler}
      >
        {organizations?.map(organization => (
          <Option key={organization.id} value={organization.id}>
            {organization.friendly_name}
          </Option>
        ))}
      </Select>
    </Item>
    <Item
      label="Select customer account"
      name="customer_account_id"
      className={styles.formItem}
    >
      <Select
        className={styles.detailsInput}
        placeholder="Customers accounts"
        loading={isAccountsLoading}
        getPopupContainer={getPopupContainerHandler}
      >
        {customerAccounts?.map(organization => (
          <Option key={organization.id} value={organization.id}>
            {organization.title}
          </Option>
        ))}
      </Select>
    </Item>
    <Item
      label="Customer details"
      name="customer_details"
      className={styles.formItem}
    >
      <TextArea
        autoSize={false}
        rows={2}
        placeholder="Customer details"
        className={styles.customerDetails}
      />
    </Item>
  </Form>
);

ClientInvoiceDetailsForm.propTypes = {
  detailsForm: PropTypes.object.isRequired,
  invoiceTemplates: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired,
  organizations: PropTypes.array.isRequired,
  contractorAccounts: PropTypes.array.isRequired,
  customerAccounts: PropTypes.array.isRequired,
  isAccountsLoading: PropTypes.bool.isRequired,
  onFormValuesChange: PropTypes.func.isRequired,
  formHeight: PropTypes.number.isRequired,
};

export default ClientInvoiceDetailsForm;
