export const types = {
  GET_LEADS: 'leads/GET_LEADS',
  GET_LEADS_DATA_SUCCESS: 'leads/GET_LEADS_SUCCESS',
  GET_LEADS_DATA_FAILED: 'leads/GET_LEADS_FAILED',
  GET_LEADS_FILTERS: 'lead/GET_LEADS_FILTERS',
  GET_LEADS_FILTERS_FAILED: 'lead/GET_LEADS_FILTERS_FAILED',
  GET_LEADS_FILTERS_SUCCESS: 'lead/GET_LEADS_FILTERS_SUCCESS',
};

export const EMPLOYEE_STATUS_OK = 'OK';

export const TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const FILTER_FIELDS = [
  'ga_source',
  'ga_medium',
  'channel',
  'status',
  'country_name',
];

export const FILTER_STRING = ['name', 'email', 'city'];
