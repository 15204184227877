import React, { useEffect, useState } from 'react';
import { Modal as AntdModal, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Loader, Table } from 'components/Common';
import PageHeader from 'components/Common/PageHeader';
import { PositionsForm } from 'components/PositionsCommon';
import Modal from 'components/Common/Modal';
import { getRowKey } from 'utils/helpers';
import { employeePositionsSelector } from 'redux/selectors';
import {
  getPositions,
  addPosition,
  deletePosition,
  editPosition,
  deletePositionErrors,
} from 'redux/EmployeePositions/actions';

import { getColumns } from './utils';
import styles from './styles.scss';

const { useForm } = Form;

const PositionsPage = () => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const [form] = useForm();

  const dispatch = useDispatch();

  const { positions, isPositionsLoading, users } = useSelector(
    employeePositionsSelector,
  );

  useEffect(() => {
    dispatch(getPositions());
  }, []);

  const onDelete = id => {
    AntdModal.confirm({
      title: 'Remove position',
      content: 'Are you sure you want to remove this position?',
      onOk: () => {
        dispatch(
          deletePosition({
            id,
          }),
        );
        AntdModal.destroyAll();
      },
      onCancel: AntdModal.destroyAll,
    });
  };

  const hideModal = () => {
    setIsVisibleModal(false);
    setCurrentId(null);
    form.resetFields();
  };

  const showEditModal = record => {
    setIsVisibleModal(true);
    setCurrentId(record.id);
    form.setFieldsValue(record);
  };

  const showAddModal = () => {
    setIsVisibleModal(true);
  };

  const onSubmitEditData = values => {
    dispatch(
      editPosition({
        id: currentId,
        title: values.title,
        is_delivery: values.is_delivery,
      }),
    );

    hideModal();
    setCurrentId(null);
  };

  const onSubmitAddData = values => {
    dispatch(
      addPosition({
        title: values.title,
        is_delivery: values.is_delivery,
      }),
    );

    hideModal();
  };

  const onSubmit = values => {
    if (currentId) {
      onSubmitEditData(values);

      return;
    }

    onSubmitAddData(values);
  };

  const columns = getColumns({ onDelete, showEditModal });

  const onErrorOk = () => dispatch(deletePositionErrors());

  return (
    <Loader loading={isPositionsLoading}>
      <PageHeader
        title="Positions"
        extra={
          <Button
            onClick={showAddModal}
            type="primary"
            style={{ marginLeft: 16 }}
          >
            Add position
          </Button>
        }
      />

      <Modal
        title="Error!"
        open={users && users.length > 0}
        onOk={onErrorOk}
        onCancel={onErrorOk}
        cancelButtonProps={{ style: { display: 'none' } }}
        keyboard
      >
        <div>
          <h3>
            Эта должность не может быть удалена, т.к. используется у следующих
            пользователей:
          </h3>
        </div>
      </Modal>

      <Modal
        className={styles.modal}
        title={currentId ? 'Edit position' : 'Add position'}
        open={isVisibleModal}
        onCancel={hideModal}
        onOk={form.submit}
        okText={currentId ? 'Save' : 'Add'}
        cancelText="Cancel"
        confirmLoading={isPositionsLoading}
      >
        <PositionsForm form={form} onSubmit={onSubmit} />
      </Modal>
      <Table
        pagination={false}
        columns={columns}
        dataSource={positions}
        className={styles.positionsTable}
        rowKey={getRowKey}
      />
    </Loader>
  );
};

export default PositionsPage;
