import { takeLatest, call, put } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';
import { SAGA_MESSAGES } from 'redux/sagaMessages';

import { sagaErrorHandler } from 'utils/common';
import { types } from './types';

function* getTechnologies() {
  try {
    const { data } = yield call(API.getTechnologies);
    yield put({
      type: types.GET_TECHNOLOGIES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_TECHNOLOGIES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* updateTechnology({ payload: { id, ...payload } }) {
  try {
    yield call(API.editTechnology, id, payload);
    yield call(getTechnologies);
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_TECHNOLOGY_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* deleteTechnology({ payload: { id } }) {
  try {
    yield call(API.deleteTechnology, id);
    yield call(getTechnologies);
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    try {
      const { data: employees } = yield call(API.getTechnologyEmployees, id);
      yield put({
        type: types.DELETE_TECHNOLOGY_FAILED,
        payload: employees,
      });
    } catch {
      yield call(
        sagaErrorHandler,
        err,
        types.DELETE_TECHNOLOGY_FAILED,
        SAGA_MESSAGES.ERROR,
      );
    }
  }
}

function* addTechnology({ payload }) {
  try {
    const { data } = yield call(API.addTechnology, payload);
    yield put({
      type: types.ADD_TECHNOLOGY_SUCCESS,
      payload: data,
    });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.ADD_TECHNOLOGY_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_TECHNOLOGIES, getTechnologies);
  yield takeLatest(types.UPDATE_TECHNOLOGY, updateTechnology);
  yield takeLatest(types.DELETE_TECHNOLOGY, deleteTechnology);
  yield takeLatest(types.ADD_TECHNOLOGY, addTechnology);
}
