import React from 'react';
import dayjs from 'dayjs';

import { BEGIN_MONTH_FORMAT, DATE_FORMAT } from 'utils/timeConstants';
import { getLastDayOfMonth } from 'utils/helpers';

import ClientInvoiceAllocationsStep from '../ClientInvoiceAllocationsStep';
import ClientInvoiceDetails from '../ClientInvoiceDetails';
import ClientInvoiceFilesStep from '../ClientInvoiceFilesStep';
import ClientInvoiceFirstStep from '../ClientInvoiceFirstStep';
import ClientInvoiceItem from '../ClientInvoiceItem';
import ClientInvoiceWorklogsStep from '../ClientInvoiceWorklogsStep';

export const getDealsForPeriod = ({ deals, period, invoiceProjects }) => {
  const lastMonthDay = getLastDayOfMonth(period);

  return deals.filter(
    deal =>
      !dayjs(deal.starts_at).isAfter(lastMonthDay) &&
      (!deal.ends_at || !dayjs(deal.ends_at).isBefore(lastMonthDay)) &&
      invoiceProjects?.includes(String(deal.project_id)),
  );
};

export const formatAllocations = allocations =>
  allocations.map(
    ({ id, deal_id, period_from, period_to, project_id, worker }) => ({
      allocation_id: id,
      deal_id,
      period_from,
      period_to,
      project_id,
      worker,
    }),
  );

export const getInvoiceStartEndDates = date => ({
  date_from: dayjs(date).format(BEGIN_MONTH_FORMAT),
  date_to: dayjs(date)
    .endOf('M')
    .format(DATE_FORMAT),
});

export const getProjectsFromWorklogsAndAllocations = (
  worklogs,
  allocations,
) => {
  const invoiceProjects = new Set();
  worklogs.forEach(worklog => {
    invoiceProjects.add(worklog.project_id);
  });
  allocations.forEach(allocation => {
    invoiceProjects.add(allocation.project_id);
  });

  return Array.from(invoiceProjects);
};

export const formatLoggedAllocations = ({
  invoiceAllocations,
  loggedAllocations,
}) => {
  const formattedAllocations = [];

  loggedAllocations.forEach(allocation => {
    const loggedAllocation = invoiceAllocations[allocation.project_id]?.find(
      item => item.id === allocation.allocation_id,
    );

    if (loggedAllocation) {
      formattedAllocations.push({ ...loggedAllocation, ...allocation });
    }
  });

  return formattedAllocations;
};

export const getInvoiceSteps = ({
  invoiceDate,
  invoiceProjects,
  projects,
  isClientsLoading,
  isFullScreenClientModal,
  isAddWorklogs,
  clientId,
  loggedAllocations,
  invoiceData,
  addWorklogForm,
  setInvoiceAllocations,
  setLoggedAllocations,
  setIsInvoiceValuesTouched,
  setIsAddWorklog,
  invoiceAllocations,
  worklogsGroupedByProject,
  setWorklogsGroupedByProject,
  detailsForm,
  invoiceItems,
  setInvoiceItems,
  isCreateCustomInvoice,
  customInvoiceHandler,
  setInvoiceDate,
  setInvoiceProjects,
  isInvoiceEdit,
  jiraRolesGroupedById,
  projectGroupedById,
  allocationsDataSource,
  allocationsDataSourceMap,
  setAllocationsDataSource,
  setAllocationsDataSourceMap,
  draftSavedAt,
  setDraftSavedAt,
}) => {
  let steps = [
    {
      title: 'Projects',
      content: (
        <ClientInvoiceFirstStep
          projects={projects}
          isFullScreen={isFullScreenClientModal}
          isCreateCustomInvoice={isCreateCustomInvoice}
          isInvoiceLoading={isClientsLoading}
          customInvoiceHandler={customInvoiceHandler}
          setInvoiceDate={setInvoiceDate}
          setInvoiceProjects={setInvoiceProjects}
          invoiceDate={invoiceDate}
          invoiceProjects={invoiceProjects}
        />
      ),
    },
    {
      title: 'Allocations',
      content: (
        <ClientInvoiceAllocationsStep
          clientId={clientId}
          invoiceDate={invoiceDate}
          invoiceProjects={invoiceProjects}
          loggedAllocations={loggedAllocations}
          setInvoiceAllocations={setInvoiceAllocations}
          setLoggedAllocations={setLoggedAllocations}
          setIsInvoiceValuesTouched={setIsInvoiceValuesTouched}
          isInvoiceEdit={isInvoiceEdit}
          isFullScreen={isFullScreenClientModal}
          dataSource={allocationsDataSource}
          dataSourceMap={allocationsDataSourceMap}
          setDataSource={setAllocationsDataSource}
          setDataSourceMap={setAllocationsDataSourceMap}
          invoiceAllocations={invoiceAllocations}
        />
      ),
    },
    {
      title: 'Worklogs',
      content: (
        <ClientInvoiceWorklogsStep
          invoiceDate={invoiceDate}
          invoiceData={invoiceData}
          invoiceProjects={invoiceProjects}
          addWorklogForm={addWorklogForm}
          isAddWorklogs={isAddWorklogs}
          setIsAddWorklog={setIsAddWorklog}
          invoiceAllocations={invoiceAllocations}
          worklogsGroupedByProject={worklogsGroupedByProject}
          setWorklogsGroupedByProject={setWorklogsGroupedByProject}
          setIsInvoiceValuesTouched={setIsInvoiceValuesTouched}
          jiraRolesGroupedById={jiraRolesGroupedById}
          projectGroupedById={projectGroupedById}
          isFullScreen={isFullScreenClientModal}
          draftSavedAt={draftSavedAt}
          setDraftSavedAt={setDraftSavedAt}
        />
      ),
    },
    {
      title: 'Details',
      content: (
        <ClientInvoiceDetails
          isFullScreen={isFullScreenClientModal}
          detailsForm={detailsForm}
          setIsInvoiceValuesTouched={setIsInvoiceValuesTouched}
        />
      ),
    },
    {
      title: 'Invoice',
      content: (
        <ClientInvoiceItem
          invoiceData={invoiceData}
          invoiceItems={invoiceItems}
          setInvoiceItems={setInvoiceItems}
          isFullScreen={isFullScreenClientModal}
          setIsInvoiceValuesTouched={setIsInvoiceValuesTouched}
          isShowDealDescription={false}
          isUpdateInvoice={isClientsLoading}
        />
      ),
    },
    {
      title: 'Files',
      content: <ClientInvoiceFilesStep invoiceData={invoiceData} />,
    },
  ];

  return steps;
};

export const checkWorklogs = groupedWorklogs =>
  Object.keys(groupedWorklogs).every(key => groupedWorklogs[key].length === 0);
