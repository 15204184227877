import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Typography,
  Button,
  Popconfirm,
  Select,
  Table,
  InputNumber,
} from 'antd';
import {
  CheckOutlined,
  DeleteOutlined,
  CloseOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { get, isArray } from 'lodash';

import TotalCell from 'containers/ProjectProfits/TotalCell';
import TableButton from 'components/Common/TableButton';
import { formatNumberWithCommas } from 'utils/helpers';

import { CONDITION_OPTIONS } from './constants';
import styles from './styles.scss';

const { Text } = Typography;

const filterJiraRoles = (input, option) =>
  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const getConditionPreview = condition => {
  switch (condition) {
    case '<=':
      return 'Max';
    case '>=':
      return 'Min';
    case '=':
      return 'Allocation';
    case '<>':
      return 'Logs';
    default:
      return condition;
  }
};

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  jiraRoles,
  currencies,
  jiraRolesObj,
  form,
  allocationsData,
  ...restProps
}) => {
  const checkFields = (_, value) => {
    const values = form.getFieldsValue();
    const isAllocationWithSameRoleAndRate = allocationsData.some(
      elem =>
        Number(elem.rate) === Number(values.rate) &&
        elem.jira_role_id === values.jira_role_id &&
        elem.id !== record.id,
    );

    if (isAllocationWithSameRoleAndRate) {
      return Promise.reject(
        new Error('Allocations already have role with the same rate.'),
      );
    }

    if (inputType === 'selectPosition' && !values.jira_role_id) {
      return Promise.reject(new Error('Field can not be empty'));
    }

    if (inputType === 'selectCondition' && !values.condition) {
      return Promise.reject(new Error('Field can not be empty'));
    }

    if (inputType === 'rate' && values.rate < 0) {
      return Promise.reject(new Error('Value can not be negative'));
    }

    if (
      inputType === 'rate' &&
      !values.rate.toString().match(/^(-)?(\d)*(\.)?([0-9]{1,2})?$/)
    ) {
      return Promise.reject(new Error('No more than two decimal places'));
    }

    if (
      !['selectPosition', 'selectCondition', 'rate'].includes(inputType) &&
      +value < 0
    ) {
      return Promise.reject(new Error('Value must be greater than zero'));
    }

    return Promise.resolve();
  };

  const renderInputNode = () => {
    switch (inputType) {
      case 'selectPosition':
        return (
          <Select showSearch filterOption={filterJiraRoles} size="small">
            {jiraRoles.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
            {record.jira_role_id && (
              <Select.Option value={record.jira_role_id}>
                {jiraRolesObj &&
                  isArray(jiraRolesObj[record.jira_role_id]) &&
                  jiraRolesObj[record.jira_role_id][0]['name']}
              </Select.Option>
            )}
          </Select>
        );
      case 'selectCondition':
        return (
          <Select
            size="small"
            optionLabelProp="label"
            dropdownMatchSelectWidth={false}
            dropdownAlign={{
              offset: [-400, 4],
            }}
          >
            {CONDITION_OPTIONS.map(item => (
              <Select.Option
                key={item.value}
                value={item.value}
                label={item.preview}
              >
                {item.title}
              </Select.Option>
            ))}
          </Select>
        );
      default:
        return (
          <InputNumber
            className={styles.inputNumber}
            size="small"
            // formatter={formatNumberWithCommas}
            addonAfter={
              title === 'Rate' ? (
                <Form.Item
                  name="currency_code"
                  noStyle
                  rules={[{ required: true, message: 'Select currency' }]}
                >
                  <Select
                    showSearch
                    filterOption={filterJiraRoles}
                    style={{ width: '70px' }}
                    size="small"
                  >
                    {currencies.map(item => (
                      <Select.Option key={item.id} value={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ' h/week'
              )
            }
          />
        );
    }
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              validator: checkFields,
            },
          ]}
        >
          {renderInputNode()}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.string,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.string,
  record: PropTypes.object,
  index: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  jiraRoles: PropTypes.array,
  currencies: PropTypes.array,
  jiraRolesObj: PropTypes.object,
  form: PropTypes.object,
  allocationsData: PropTypes.object,
};

export const getColumns = ({
  isEditing,
  editingKey,
  onEditRow,
  onSave,
  onCancelEditOrCreate,
  isAddAllocationToggle,
  jiraRoles,
  currencies,
  onDeleteAllocation,
}) => [
  {
    title: 'Role',
    dataIndex: 'jira_role_id',
    key: 'position',
    width: '25%',
    align: 'left',
    editable: true,

    render: (value, record) => {
      if (get(record, 'isAddButton')) {
        return (
          <Button type="dashed" onClick={isAddAllocationToggle} size="small">
            Add row
          </Button>
        );
      }

      if (jiraRoles) return jiraRoles[value] && jiraRoles[value][0].name;

      return value;
    },
  },
  {
    title: 'Rate',
    editable: true,
    dataIndex: 'rate',
    align: 'center',
    key: 'rate',
    width: '25%',
    render: (value, record) => {
      const currentCurrency = currencies.find(
        item => item.name === record.currency_code,
      );

      return (
        value && (
          <>
            <Text strong>
              {currentCurrency ? currentCurrency.name : ''}
              {` ${formatNumberWithCommas(value)}`}
            </Text>{' '}
            per hour
          </>
        )
      );
    },
  },
  {
    title: 'Allocation',
    editable: true,
    dataIndex: 'hours_per_week',
    key: 'hours_per_week',
    width: '24%',
    align: 'center',
    render: value =>
      value && (
        <>
          <Text strong>{value}</Text> h/week
        </>
      ),
  },
  {
    title: 'Condition',
    dataIndex: 'condition',
    key: 'condition',
    width: '10%',
    align: 'center',
    editable: true,
    render: value =>
      value && (
        <>
          <Text strong>{getConditionPreview(value)}</Text>
        </>
      ),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: '10%',
    align: 'center',
    render: (_, record) => {
      if (get(record, 'isAddButton')) return null;

      const editable = isEditing(record);

      return editable ? (
        <div>
          <TableButton
            ghost
            type="primary"
            style={{
              marginRight: 5,
              color: '#08c',
            }}
            icon={<CheckOutlined />}
            onClick={() => onSave(record)}
            size="small"
          />
          <Popconfirm title="Sure to cancel?" onConfirm={onCancelEditOrCreate}>
            <TableButton
              ghost
              style={{ marginRight: 5, color: '#f00' }}
              danger
              icon={<CloseOutlined />}
              size="small"
            />
          </Popconfirm>
        </div>
      ) : (
        <>
          <TableButton
            icon={<EditOutlined />}
            onClick={() => onEditRow(record)}
            type="primary"
            ghost
            disabled={editingKey !== ''}
            size="small"
          />
          <Popconfirm
            placement="topRight"
            title="Are you sure you want to remove this role?"
            onConfirm={() => onDeleteAllocation(record)}
          >
            <TableButton
              className={styles.button}
              icon={<DeleteOutlined />}
              danger
              ghost
              size="small"
            />
          </Popconfirm>
        </>
      );
    },
  },
];

export const components = {
  body: {
    cell: EditableCell,
  },
};

export const getDataWithoutEmptyFields = allocationsData =>
  allocationsData
    ? allocationsData.filter(item => item.jira_role_id && !item.deleted_at)
    : [];

export const getSummary = data => {
  let totalHours = 0;

  // @ts-ignore
  data.forEach(({ hours_per_week }) => {
    totalHours += +hours_per_week;
  });

  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell>
          <TotalCell>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              TOTAL
            </div>
          </TotalCell>
        </Table.Summary.Cell>
        <Table.Summary.Cell />
        <Table.Summary.Cell>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Text strong>{Number(totalHours).toFixed(2)} </Text>
            <span style={{ marginLeft: '5px' }}>h/week</span>
          </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell />
      </Table.Summary.Row>
    </>
  );
};
