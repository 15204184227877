import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  roles: [],
  permissions: [],
  roleTypes: [],
  isRolesLoading: false,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_ROLES:
    case types.CREATE_ROLE:
    case types.UPDATE_ROLE:
    case types.DELETE_ROLE:
    case types.GET_PERMISSIONS:
    case types.GET_ROLE_TYPES:
      return state.set('isRolesLoading', true);

    case types.GET_ROLES_SUCCESS:
    case types.CREATE_ROLE_SUCCESS:
    case types.UPDATE_ROLE_SUCCESS:
    case types.DELETE_ROLE_SUCCESS:
      return state.set('isRolesLoading', false).set('roles', payload);
    case types.GET_PERMISSIONS_SUCCESS:
      return state.set('isRolesLoading', false).set('permissions', payload);
    case types.GET_ROLE_TYPES_SUCCESS:
      return state.set('isRolesLoading', false).set('roleTypes', payload);

    case types.GET_ROLES_FAILED:
    case types.CREATE_ROLE_FAILED:
    case types.UPDATE_ROLE_FAILED:
    case types.DELETE_ROLE_FAILED:
    case types.GET_PERMISSIONS_FAILED:
    case types.GET_ROLE_TYPES_FAILED:
      return state.set('isRolesLoading', false);

    default:
      return state;
  }
}
