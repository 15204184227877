import React from 'react';
import PropTypes from 'prop-types';
import { getUpOrDownIcon } from 'components/DashboardCommon/ProfitAndLoss/utils';
import { getMonthNameWithYear } from 'utils/helpers';
import { getMonth, getAer, getCom } from './utils';
import DashboardAERTotalHeader from './DashboardAERTotalHeader';
import DashboardAERTotalItem from './DashboardAERTotalItem';
import DashboardAERTotalProjects from './DashboardAERTotalProjects';
import { AER, COM } from './constants';

import styles from './styles.scss';

const DashboardAERTotal = ({
  dashboardData: { months_com, highest_aer, lowest_aer },
}) => {
  const lastMonth = getMonth({ months_com, index: 1 });
  const penultimateMonth = getMonth({ months_com, index: 2 });

  const lastMonthName = getMonthNameWithYear(lastMonth?.month);
  const penultimateMonthName = getMonthNameWithYear(penultimateMonth?.month);

  const lastMonthAer = getAer(lastMonth);
  const penultimateMonthAer = getAer(penultimateMonth);

  const lastMonthCom = getCom(lastMonth);
  const penultimateMonthCom = getCom(penultimateMonth);

  const highestAer = getAer(highest_aer);
  const lowestAer = getAer(lowest_aer);

  const highestCom = getCom(highest_aer);
  const lowestCom = getCom(highest_aer);

  const aerIcon = getUpOrDownIcon(penultimateMonth?.aer, lastMonth?.aer);
  const comIcon = getUpOrDownIcon(penultimateMonth?.com, lastMonth?.com);

  return (
    <div className={styles.dashboardTotal}>
      <DashboardAERTotalHeader
        penultimateMonth={penultimateMonthName}
        lastMonth={lastMonthName}
      />
      <DashboardAERTotalItem
        title={AER}
        icon={aerIcon}
        penultimateMonth={penultimateMonthAer}
        lastMonth={lastMonthAer}
      />
      <DashboardAERTotalItem
        title={COM}
        icon={comIcon}
        penultimateMonth={penultimateMonthCom}
        lastMonth={lastMonthCom}
      />
      <DashboardAERTotalProjects
        highestAer={highestAer}
        lowestAer={lowestAer}
        highestCom={highestCom}
        lowestCom={lowestCom}
        highestAerProject={highest_aer?.project_name}
        lowestAerProject={lowest_aer?.project_name}
      />
    </div>
  );
};

DashboardAERTotal.propTypes = {
  dashboardData: PropTypes.shape({
    months_com: PropTypes.array,
    highest_aer: PropTypes.object,
    lowest_aer: PropTypes.object,
  }),
};

export default DashboardAERTotal;
