export const EVENT_TYPES = {
  dealallocation: 'Deal allocation',
  clientnote: 'Note',
  deal: 'Deal',
  invoice: 'invoice',
  client: 'Client',
  clientorganization: 'Organization',
};

export const DEALS_INFO = {
  title: 'Title',
  starts_at: 'Starts at',
  ends_at: 'Ends at',
  status: 'Status',
  description: 'Description',
  fix_price: 'Fixed price',
  deleted_at: 'Deleted at',
};

export const EVENT_ACTIONS = {
  create: { title: 'Deal', color: 'green' },
  added: { title: 'Added', color: 'green' },
  update: { title: 'Updated', color: 'blue' },
  destroy: { title: 'Deleted', color: 'red' },
};

export const ORGANIZATIONS_ACTIONS = {
  create: { title: 'Added', color: 'green' },
  update: { title: 'Updated', color: 'blue' },
  destroy: { title: 'Deleted', color: 'red' },
};

export const MODAL_TITLE = {
  Deal: 'Edit Deal',
};

export const CELL_COLORS = {
  green: '#f6ffed',
  yellow: '#fff7e6',
  red: '#fff1f0',
  gray: '',
};

export const DELETED_COLOR = '#a8071a';

export const GREEN_COLOR = '#3f6600';
