exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".table-009f3{background-color:#fff;box-shadow:0 0 3px #ccc;border-radius:4px}.table__row-2bf4d{cursor:pointer;font-size:14px}.table__column-a3150{background:rgba(22,22,22,.0862745098)}.table__column_action-c185a{text-align:center}.table-009f3 ul>li>a{display:flex !important;align-items:center;justify-content:center}", ""]);

// Exports
exports.locals = {
	"table": "table-009f3",
	"table__row": "table__row-2bf4d",
	"table__column": "table__column-a3150",
	"table__column_action": "table__column_action-c185a"
};