import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal as AntdModal, Form } from 'antd';
import { Link } from 'react-router-dom';

import PageHeader from 'components/Common/PageHeader';
import { Button, Loader, Table } from 'components/Common';
import { TechnologiesForm } from 'components/TechnologiesCommon';
import Modal from 'components/Common/Modal';
import {
  getTechnologies,
  deleteTechnology,
  editTechnology,
  addTechnology,
  deleteTechnologyErrors,
} from 'redux/Technologies/actions';
import { technologiesSelector } from 'redux/selectors';

import { useRoleContext } from 'utils/hooks';
import { hasRights } from 'utils/permissions';
import { LINKS } from 'config/routing';
import { getColumns } from './utils';
import styles from './styles.scss';
import { TECHNOLOGIES_PERMISSIONS } from './constants';

const { useForm } = Form;

const TechnologiesPage = () => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const [form] = useForm();

  const dispatch = useDispatch();

  const role = useRoleContext();

  const { technologies, users, isTechnologiesLoading } = useSelector(
    technologiesSelector,
  );

  useEffect(() => {
    dispatch(getTechnologies());
  }, []);

  const onDelete = id => {
    AntdModal.confirm({
      title: 'Remove technology',
      content: 'Are you sure you want to remove this technology?',
      onOk: () => {
        dispatch(
          deleteTechnology({
            id,
          }),
        );
        AntdModal.destroyAll();
      },
    });
  };

  const hideModal = () => {
    setIsVisibleModal(false);
    form.resetFields();
  };

  const showEditModal = technology => {
    setIsVisibleModal(true);
    setCurrentId(technology.id);
    form.setFieldsValue(technology);
  };

  const columns = getColumns({ onDelete, showEditModal, role });

  const showAddModal = () => {
    setIsVisibleModal(true);
  };

  const onSubmitEditData = values => {
    dispatch(editTechnology(values));

    hideModal();
  };

  const onSubmitAddData = values => {
    dispatch(addTechnology(values));

    hideModal();
  };

  const onSubmit = values => {
    if (currentId) {
      onSubmitEditData({ ...values, id: currentId });

      return;
    }

    onSubmitAddData(values);
  };

  const onErrorOk = () => dispatch(deleteTechnologyErrors());

  return (
    <Loader loading={isTechnologiesLoading}>
      <PageHeader
        title="Technologies"
        extra={
          hasRights(role, TECHNOLOGIES_PERMISSIONS.addPermission) && (
            <Button onClick={showAddModal} type="primary">
              Add technology
            </Button>
          )
        }
      />
      {users && users.length > 0 ? (
        <Modal
          title="Error"
          open
          onOk={onErrorOk}
          onCancel={onErrorOk}
          cancelButtonProps={{ style: { display: 'none' } }}
          keyboard
        >
          <div>
            <h3>
              This technology cannot be removed because it used by the following
              users:
            </h3>
            <ul>
              {users.map(employee => (
                <Link to={LINKS.summaryEmployee(employee.id)}>
                  <li>
                    {employee.name_ru} (id: {employee.id})
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </Modal>
      ) : null}
      <Modal
        className={styles.modal}
        title={currentId ? 'Edit technology' : 'Add technology'}
        open={isVisibleModal}
        onCancel={hideModal}
        onOk={form.submit}
        okText={currentId ? 'Save' : 'add'}
        cancelText="Cancel"
        confirmLoading={isTechnologiesLoading}
      >
        <TechnologiesForm form={form} onSubmit={onSubmit} />
      </Modal>
      <Table
        pagination={false}
        columns={columns}
        dataSource={technologies}
        className={styles.technologiesTable}
      />
    </Loader>
  );
};

export default TechnologiesPage;
