/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';

import Timeline from 'components/Common/Timeline';
import { Loader } from 'components/Common/';
import Item from 'components/Common/TimelineItem';

import TimelineTabs from './TimelineTabs';
import {
  filterEventsByType,
  getGroupDealAllocations,
  getTimeLineIcon,
} from './utils';
import { FILTER_TYPES } from './constants';
import TimelineItem from './TimelineItems';
import styles from './styles.scss';

const ClientTimeline = ({
  users,
  updateNote,
  jiraRoles,
  showClientModal,
  clientAudit,
  deals,
  allocations,
  organizationsData,
  filteredDeals,
  allocationsGroupById,
  jiraProjects,
  onEndDeal,
  onDeleteDeal,
  invoicesGroupById,
  onDeleteInvoice,
  onVisibleInvoiceModal,
  clientNotesObject,
  clientProjects,
  isTimelineDataLoading,
  isLoading,
  isInvoiceEditModalVisible,
  isUpdateInvoice,
  deleteNote,
  onEditInvoice,
  onEditDeal,
  role,
  onUpdateJiraProjects,
  progress,
}) => {
  const [filter, setFilter] = useState(FILTER_TYPES.deal.type);
  const [projectFilter, setProjectFilter] = useState([]);
  const [groupedDealsByProject, setGroupedDealsByProject] = useState({});
  const clientAuditWithGroupDealAllocations = useMemo(
    () => getGroupDealAllocations({ clientAudit, deals, allocationsGroupById }),
    [clientAudit, deals, allocationsGroupById],
  );

  const filteredEvents = useMemo(
    () =>
      filterEventsByType(
        clientAuditWithGroupDealAllocations,
        filter,
        invoicesGroupById,
        deals,
        projectFilter,
        clientNotesObject,
      ),
    [
      filter,
      clientAuditWithGroupDealAllocations,
      invoicesGroupById,
      deals,
      projectFilter,
      clientNotesObject,
    ],
  );

  const onChangeFilter = filter => setFilter(filter);

  useEffect(() => {
    const removedDeleteDeals = filteredDeals.filter(item => !item.deleted_at);
    setGroupedDealsByProject(groupBy(removedDeleteDeals, 'project_id'));
  }, [filteredDeals]);

  const onShowClientModal = () => showClientModal(filter);

  const onChangeProjectFilter = ({ projects = null, isReset = false }) => {
    if (isReset) {
      setProjectFilter([]);
    } else {
      setProjectFilter(projects);
    }
  };

  return (
    <div className={styles.clientTimeLine}>
      <TimelineTabs
        changeFilter={onChangeFilter}
        filter={filter}
        onChangeProjectFilter={onChangeProjectFilter}
        clientProjects={clientProjects}
        onShowClientModal={onShowClientModal}
        progress={progress}
        onUpdateJiraProjects={onUpdateJiraProjects}
        role={role}
      />
      <Loader
        isDynamicPosition
        loading={
          isTimelineDataLoading ||
          isLoading ||
          (isUpdateInvoice && !isInvoiceEditModalVisible)
        }
      >
        <Timeline className={styles.timeline}>
          {filteredEvents.map(({ id, ...event }) => {
            const { icon, isActive = false } = getTimeLineIcon({
              event,
              deals,
              groupedDealsByProject,
            });

            return (
              <Item key={id} className={styles.item}>
                <TimelineItem
                  event={event}
                  users={users}
                  updateNote={updateNote}
                  jiraRoles={jiraRoles}
                  deals={deals}
                  allocations={allocations}
                  organizationsData={organizationsData}
                  jiraProjects={jiraProjects}
                  onEndDeal={onEndDeal}
                  onDeleteDeal={onDeleteDeal}
                  clientAudit={clientAudit}
                  clientAuditWithGroupDealAllocations={
                    clientAuditWithGroupDealAllocations
                  }
                  groupedDealsByProject={groupedDealsByProject}
                  isActive={isActive}
                  invoicesGroupById={invoicesGroupById}
                  onDeleteInvoice={onDeleteInvoice}
                  onVisibleInvoiceModal={onVisibleInvoiceModal}
                  clientNotesObject={clientNotesObject}
                  deleteNote={deleteNote}
                  onEditInvoice={onEditInvoice}
                  onEditDeal={onEditDeal}
                  role={role}
                />
              </Item>
            );
          })}
        </Timeline>
      </Loader>
    </div>
  );
};

ClientTimeline.propTypes = {
  invoicesGroupById: PropTypes.object,
  progress: PropTypes.object,
  onDeleteInvoice: PropTypes.func,
  onVisibleInvoiceModal: PropTypes.func,
  updateNote: PropTypes.func,
  showClientModal: PropTypes.func,
  onEndDeal: PropTypes.func,
  onDeleteDeal: PropTypes.func,
  deleteNote: PropTypes.func,
  isTimelineDataLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  isInvoiceEditModalVisible: PropTypes.bool,
  onEditDeal: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  onUpdateJiraProjects: PropTypes.func.isRequired,
};

export default ClientTimeline;
