import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import { DEFAULT_AVATAR } from 'utils/constants';

import { LINKS } from 'config/routing';
import styles from './styles.scss';

const EmployeeCell = ({
  record: { avatarUrl, employee_id, employee_name, employee_jira_key },
}) => (
  <span>
    <Avatar
      size={32}
      src={avatarUrl || DEFAULT_AVATAR}
      className={styles.employeeCellAvatar}
      shape="square"
    />
    <Link to={LINKS.profile(employee_id)}>
      {employee_name || employee_jira_key}
    </Link>
  </span>
);

EmployeeCell.propTypes = {
  record: PropTypes.shape({
    avatarUrl: PropTypes.string,
    employee_id: PropTypes.number,
    employee_name: PropTypes.string,
    employee_jira_key: PropTypes.string,
  }),
};

export default EmployeeCell;
