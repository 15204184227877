import React from 'react';
import PropTypes from 'prop-types';
import { Transfer, Table } from 'antd';

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({ direction, filteredItems, disabled: listDisabled }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;

      return (
        <Table
          pagination={false}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? 'none' : null }}
        />
      );
    }}
  </Transfer>
);

TableTransfer.propTypes = {
  leftColumns: PropTypes.array,
  rightColumns: PropTypes.array,
};

export default TableTransfer;
