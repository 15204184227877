exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".table-77bbd>div>div>div>div>span,.table-77bbd>div>div>div>div>a{top:22px}.table-77bbd>div{min-height:250px}.table-77bbd>div>button{height:50px}.userSelectorContainer-d92fe{margin:20px 20px 20px 0px}.removeIcon-35a3d{display:flex;align-items:center;height:100%}.selectOption-e06ec{display:flex;justify-content:space-between;align-items:center}", ""]);

// Exports
exports.locals = {
	"table": "table-77bbd",
	"userSelectorContainer": "userSelectorContainer-d92fe",
	"removeIcon": "removeIcon-35a3d",
	"selectOption": "selectOption-e06ec"
};