import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import classNames from 'classnames';

import styles from './styles.scss';

const TextArea = ({ ...props }) => (
  <Input.TextArea
    {...props}
    className={classNames([styles.textArea, props.className])}
  />
);

TextArea.propTypes = {
  className: PropTypes.string,
};

export default TextArea;
