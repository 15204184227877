exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".leadsReport-14de4{padding:24px;border-radius:12px;background-color:#fff;box-shadow:0 4px 20px 0 rgba(112,112,128,.1);position:relative;display:flex;flex-direction:column;justify-content:center;align-items:center;width:17%;height:100%}.syncIconContainer-a64d4{position:absolute;right:25px;top:25px}.leadReportList-5443b{width:100%;margin-top:20px;list-style:none}.leadsContainer-c648c{display:flex;justify-content:space-around}.leadsContent-cbb99{padding:24px;border-radius:12px;background-color:#fff;box-shadow:0 4px 20px 0 rgba(112,112,128,.1);padding:0}.leadsContentHeader-33b89{display:flex}.leadsCard-fbcb1{border:1px solid rgba(0,0,0,.05);padding:15px;width:25%}.leadsCardTitle-155fd{font-weight:600;display:flex;align-items:center}.leadsCardValue-b2335{font-weight:600;font-size:18px;margin-bottom:8px}.leadsCardDescription-3a4b6{color:rgba(0,0,0,.5);font-size:.88rem}.leadsTimeline-c2eff{padding:24px;margin-top:15px}.leadRefreshIcon-5c7bc{font-size:24px;cursor:pointer;transition:.6s ease}.leadRefreshIcon-5c7bc:hover{transform:rotate(180deg)}", ""]);

// Exports
exports.locals = {
	"leadsReport": "leadsReport-14de4",
	"syncIconContainer": "syncIconContainer-a64d4",
	"leadReportList": "leadReportList-5443b",
	"leadsContainer": "leadsContainer-c648c",
	"leadsContent": "leadsContent-cbb99",
	"leadsContentHeader": "leadsContentHeader-33b89",
	"leadsCard": "leadsCard-fbcb1",
	"leadsCardTitle": "leadsCardTitle-155fd",
	"leadsCardValue": "leadsCardValue-b2335",
	"leadsCardDescription": "leadsCardDescription-3a4b6",
	"leadsTimeline": "leadsTimeline-c2eff",
	"leadRefreshIcon": "leadRefreshIcon-5c7bc"
};