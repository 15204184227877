import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';

import styles from './styles.scss';

const TableActions = ({ onEdit, onDelete, canEdit, canDelete }) => (
  <Dropdown
    menu={{
      items: [
        {
          label: 'Edit',
          key: 'Edit',
          onClick: onEdit,
          icon: <EditOutlined />,
          disabled: !canEdit,
        },
        {
          label: 'Remove',
          key: 'Remove',
          onClick: onDelete,
          icon: <DeleteOutlined />,
          disabled: !canDelete,
        },
      ],
    }}
  >
    <EllipsisOutlined className={styles.actionIcon} />
  </Dropdown>
);

TableActions.defaultProps = {
  canEdit: true,
  canDelete: true,
};

TableActions.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
};

export default TableActions;
