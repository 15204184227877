import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Dropdown } from 'antd';
import dayjs from 'dayjs';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';

import { FULL_DATE_FORMAT } from 'utils/timeConstants';

import InfoItem from '../CommonItem/InfoItem';
import { ORGANIZATIONS_ACTIONS } from '../CommonItem/constants';
import styles from './styles.scss';

const NoteItem = ({ event, updateNote, clientNotesObject, deleteNote }) => {
  const { auditable_id } = event;
  const [isNoteClicked, setIsNoteClicked] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isInputValueChanged, setIsInputValueChanged] = useState(false);

  const onNoteClicked = () => setIsNoteClicked(true);
  const onCancelClicked = () => {
    setIsNoteClicked(false);
    setIsInputValueChanged(false);

    const text = clientNotesObject[auditable_id].text;
    setInputValue(text);
  };

  const onUpdateNote = () => {
    setIsNoteClicked(false);
    updateNote({ text: inputValue, noteId: auditable_id });
    setIsInputValueChanged(false);
  };

  const onDeleteNote = () => {
    setIsNoteClicked(false);
    deleteNote({ noteId: auditable_id });
    setIsInputValueChanged(false);
  };

  const onChangeNote = ({ target: { value } }) => {
    setInputValue(value);
    setIsInputValueChanged(true);
  };

  useEffect(() => {
    const text = clientNotesObject[auditable_id]?.text;
    setInputValue(text);
  }, [clientNotesObject]);

  const dropdownItem = [
    {
      label: 'Edit',
      key: 'Edit',
      onClick: () => onNoteClicked(),
      icon: <EditOutlined />,
    },
    {
      label: 'Remove',
      key: 'Remove',
      onClick: () => onDeleteNote(),
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <div>
      <div className={styles.noteContainer}>
        <div className={styles.noteInfo}>
          <InfoItem
            type={event.auditable_type}
            action={event.action}
            actions={ORGANIZATIONS_ACTIONS}
          />
          <Dropdown menu={{ items: dropdownItem }}>
            <EllipsisOutlined className={styles.noteMenu} />
          </Dropdown>
        </div>
        <div className={styles.content}>
          {isNoteClicked ? (
            <>
              <Input.TextArea
                value={inputValue}
                rows={3}
                onChange={onChangeNote}
              />
              <div className={styles.noteButtons}>
                <Button size="small" onClick={onCancelClicked}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  type="primary"
                  className={styles.saveButton}
                  onClick={onUpdateNote}
                  disabled={!isInputValueChanged}
                >
                  Save
                </Button>
              </div>
            </>
          ) : (
            <p>{clientNotesObject[auditable_id]?.text}</p>
          )}
          <span className={styles.created}>{`Created by: ${
            clientNotesObject[auditable_id]?.owner_name
          } ${dayjs(event.created_at).format(FULL_DATE_FORMAT)}`}</span>
        </div>
      </div>
    </div>
  );
};

NoteItem.propTypes = {
  event: PropTypes.object,
  clientNotesObject: PropTypes.object,
  updateNote: PropTypes.func,
  deleteNote: PropTypes.func,
};

export default NoteItem;
