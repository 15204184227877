import dayjs from 'dayjs';
import {
  formatNumberWithCommas,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getMonthFormat,
} from 'utils/helpers';
import { DASHBOARD_EMPLOYEES_INDEX } from './constants';

import styles from './styles.scss';

export const getCurrentMonthRange = date => [
  getFirstDayOfMonth(date),
  getLastDayOfMonth(date),
];

export const getYearRange = date => {
  const lastMonthDay = getMonthFormat(date);

  const firstMonth = getMonthFormat(dayjs(lastMonthDay).subtract(12, 'M'));

  return [firstMonth, lastMonthDay];
};

export const getEmployeeDataPeriod = period => {
  if (dayjs(period[0]).isSame(period[1], 'month')) {
    const [from, to] = getYearRange(period[1]);

    return {
      from,
      to,
    };
  }

  return { from: getMonthFormat(period[0]), to: getMonthFormat(period[1]) };
};

const getComClassName = com => {
  if (com < 80) return styles.redCell;

  if (com > 100) return styles.greenCell;

  return null;
};

const getSortOrder = (condition, { field, order }) =>
  field === condition && order;

const filteredValue = (valueType, filterInfo) =>
  (filterInfo && filterInfo[valueType]) || null;

const orderByTextField = (first, second) => {
  let firstLowerCase = first.text.toLowerCase();
  let secondLowerCase = second.text.toLowerCase();

  if (firstLowerCase < secondLowerCase) return -1;

  if (firstLowerCase > secondLowerCase) return 1;

  return 0;
};
const getEmployeeFilters = filters =>
  filters &&
  Object.entries(filters)
    .map(([name, id]) => ({ text: name, value: id }))
    .sort(orderByTextField);

const getRoleFilters = filters =>
  filters &&
  Object.entries(filters)
    .map(([id, name]) => ({ text: name, value: id }))
    .sort(orderByTextField);

export const getColumns = ({
  sortInfo,
  filterInfo,
  averageAer,
  filterData,
}) => [
  {
    title: DASHBOARD_EMPLOYEES_INDEX.employee_title,
    key: DASHBOARD_EMPLOYEES_INDEX.employee_id,
    dataIndex: DASHBOARD_EMPLOYEES_INDEX.employee_name,
    sorter: true,
    sortOrder: getSortOrder(DASHBOARD_EMPLOYEES_INDEX.employee_name, sortInfo),
    filters: getEmployeeFilters(filterData.employees),
    filteredValue: filteredValue(
      DASHBOARD_EMPLOYEES_INDEX.employee_id,
      filterInfo,
    ),
  },
  {
    title: DASHBOARD_EMPLOYEES_INDEX.role_title,
    key: DASHBOARD_EMPLOYEES_INDEX.role_id,
    dataIndex: DASHBOARD_EMPLOYEES_INDEX.role,
    sorter: true,
    sortOrder: getSortOrder(DASHBOARD_EMPLOYEES_INDEX.role, sortInfo),
    filters: getRoleFilters(filterData.roles),
    filteredValue: filteredValue(DASHBOARD_EMPLOYEES_INDEX.role_id, filterInfo),
  },
  {
    title: DASHBOARD_EMPLOYEES_INDEX.rm_title,
    key: DASHBOARD_EMPLOYEES_INDEX.rm_id,
    dataIndex: DASHBOARD_EMPLOYEES_INDEX.rm_name,
    sorter: true,
    sortOrder: getSortOrder(DASHBOARD_EMPLOYEES_INDEX.rm_name, sortInfo),
    filters: getEmployeeFilters(filterData.rms),
    filteredValue: filteredValue(DASHBOARD_EMPLOYEES_INDEX.rm_id, filterInfo),
  },
  {
    title: DASHBOARD_EMPLOYEES_INDEX.revenue_title,
    key: DASHBOARD_EMPLOYEES_INDEX.revenue,
    dataIndex: DASHBOARD_EMPLOYEES_INDEX.revenue,
    sorter: true,
    sortOrder: getSortOrder(DASHBOARD_EMPLOYEES_INDEX.revenue, sortInfo),
    render: formatNumberWithCommas,
  },
  {
    title: DASHBOARD_EMPLOYEES_INDEX.cost_title,
    key: DASHBOARD_EMPLOYEES_INDEX.cost,
    dataIndex: DASHBOARD_EMPLOYEES_INDEX.cost,
    sorter: true,
    sortOrder: getSortOrder(DASHBOARD_EMPLOYEES_INDEX.cost, sortInfo),
    render: formatNumberWithCommas,
  },
  {
    title: DASHBOARD_EMPLOYEES_INDEX.profit_title,
    key: DASHBOARD_EMPLOYEES_INDEX.profit,
    dataIndex: DASHBOARD_EMPLOYEES_INDEX.profit,
    sorter: true,
    sortOrder: getSortOrder(DASHBOARD_EMPLOYEES_INDEX.profit, sortInfo),
    render: value => ({
      props: { className: value < 0 && styles.redCell },
      children: formatNumberWithCommas(value),
    }),
  },
  {
    title: DASHBOARD_EMPLOYEES_INDEX.aer_title,
    key: DASHBOARD_EMPLOYEES_INDEX.aer,
    dataIndex: DASHBOARD_EMPLOYEES_INDEX.aer,
    sorter: true,
    sortOrder: getSortOrder(DASHBOARD_EMPLOYEES_INDEX.aer, sortInfo),
    render: value => ({
      props: { className: value < averageAer && styles.redCell },
      children: formatNumberWithCommas(value),
    }),
  },
  {
    title: DASHBOARD_EMPLOYEES_INDEX.com_title,
    key: DASHBOARD_EMPLOYEES_INDEX.com,
    dataIndex: DASHBOARD_EMPLOYEES_INDEX.com,
    sorter: true,
    sortOrder: getSortOrder(DASHBOARD_EMPLOYEES_INDEX.com, sortInfo),
    render: value => ({
      props: { className: getComClassName(value) },
      children: `${formatNumberWithCommas(value)}%`,
    }),
  },
  {
    title: DASHBOARD_EMPLOYEES_INDEX.utilization_title,
    key: DASHBOARD_EMPLOYEES_INDEX.utilization,
    dataIndex: DASHBOARD_EMPLOYEES_INDEX.utilization,
    sorter: true,
    sortOrder: getSortOrder(DASHBOARD_EMPLOYEES_INDEX.utilization, sortInfo),
    render: value => ({
      props: { className: value < 80 && styles.redCell },
      children: `${formatNumberWithCommas(value)}%`,
    }),
  },
];
