import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select as AntdSelect, Form, Tabs } from 'antd';

import { INVOICES_MODAL_TAB } from 'containers/ClientPage/constants';
import {
  INVOICES_PERMISSIONS,
  INVOICE_STATUSES,
  TIME_FIELDS,
} from 'containers/InvoicesPage/constants';
import DatePicker from 'components/Common/DatePicker';
import Modal from 'components/Common/Modal';
import { Button } from 'components/Common';
import Input from 'components/Common/Input';
import Select, { Option } from 'components/Common/Select';
import { getPopupContainerHandler } from 'utils/helpers';
import { hasRights } from 'utils/permissions';

import WorkflowCommon from './WorkflowCommon';
import { FIELD_TITLE, INVOICE_SENT } from './constants';
import styles from './styles.scss';

const InvoicesModal = ({
  visible,
  onHideModal,
  form,
  onFinish,
  currentInvoice,
  invoiceModalTab,
  setInvoiceModalTab,
  isInvoiceAuditLoading,
  usersObject,
  isClientPage,
  role,
}) => {
  const [invoiceStatus, setInvoiceStatus] = useState('');
  const [sentAt, setSentAt] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const onUpdateInvoiceStatusWorkflow = () => {
    setErrorMessage('');
    onFinish(invoiceStatus, sentAt);
    setSentAt(null);
  };

  const onUpdateInvoiceStatusForm = () => {
    const { status, sent_at } = form.getFieldsValue();

    setErrorMessage('');
    onFinish(status, sent_at);
    setSentAt(null);
  };

  const onValuesChange = () => {
    setErrorMessage('');
  };

  const onChangeStatus = status => {
    setInvoiceStatus(status);
  };

  useEffect(() => {
    form.setFieldsValue({
      status: invoiceStatus,
    });
  }, [invoiceStatus]);

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage('');
    }
  }, [invoiceStatus, sentAt]);

  const tabItems = [
    {
      label: INVOICES_MODAL_TAB.information,
      key: INVOICES_MODAL_TAB.information,
      children: (
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          onFinish={onUpdateInvoiceStatusForm}
          name="updateInvoice"
          onValuesChange={onValuesChange}
        >
          {Object.keys(FIELD_TITLE).map(item => {
            if (item === 'sent_at') {
              return (
                <Form.Item
                  name={item}
                  label={FIELD_TITLE[item]}
                  key={item}
                  rules={[
                    {
                      required: invoiceStatus === INVOICE_SENT,
                      message: 'Please, enter invoice sent date',
                    },
                  ]}
                >
                  <DatePicker
                    getPopupContainer={getPopupContainerHandler}
                    disabled={
                      !hasRights(role, INVOICES_PERMISSIONS.updateSentAt)
                    }
                  />
                </Form.Item>
              );
            }

            if (TIME_FIELDS.includes(item)) {
              return (
                <Form.Item name={item} label={FIELD_TITLE[item]} key={item}>
                  <DatePicker
                    disabled
                    getPopupContainer={getPopupContainerHandler}
                  />
                </Form.Item>
              );
            }

            if (item === 'status') {
              return (
                <Form.Item name={item} label={FIELD_TITLE[item]} key={item}>
                  <Select
                    getPopupContainer={getPopupContainerHandler}
                    onChange={onChangeStatus}
                    disabled={
                      !hasRights(role, INVOICES_PERMISSIONS.updateStatus)
                    }
                  >
                    {Object.keys(INVOICE_STATUSES)
                      .filter(item =>
                        hasRights(role, INVOICE_STATUSES[item].scopes),
                      )
                      .map(item => (
                        <Option
                          value={INVOICE_STATUSES[item].key}
                          key={INVOICE_STATUSES[item].key}
                        >
                          {INVOICE_STATUSES[item].key.replace(/_/g, ' ')}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              );
            }

            return (
              <Form.Item name={item} label={FIELD_TITLE[item]} key={item}>
                <Input disabled />
              </Form.Item>
            );
          })}
        </Form>
      ),
    },
    {
      label: INVOICES_MODAL_TAB.workflow,
      key: INVOICES_MODAL_TAB.workflow,
      disabled: !isClientPage,
      children: (
        <div className={styles.worklflowContainer}>
          <WorkflowCommon
            currentInvoice={currentInvoice}
            role={role}
            invoiceStatus={invoiceStatus}
            setInvoiceStatus={setInvoiceStatus}
            sentAt={sentAt}
            setSentAt={setSentAt}
            isInvoiceAuditLoading={isInvoiceAuditLoading}
            invoiceAudit={currentInvoice?.audits}
            usersObject={usersObject}
          />
        </div>
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      onCancel={onHideModal}
      destroyOnClose
      title="Invoice"
      bodyStyle={{ height: '70vh', overflowY: 'auto' }}
      width="1000px"
      footer={
        <>
          <span className={styles.errorMessage}>{errorMessage}</span>
          <Button onClick={onHideModal}>Cancel</Button>
          {INVOICES_MODAL_TAB.information === invoiceModalTab ? (
            <Button onClick={form.submit} type="primary">
              Update information
            </Button>
          ) : (
            <Button
              type="primary"
              disabled={invoiceStatus === 'SENT' && !sentAt}
              onClick={onUpdateInvoiceStatusWorkflow}
            >
              Update Status
            </Button>
          )}
        </>
      }
    >
      <Tabs
        defaultActiveKey={invoiceModalTab}
        tabPosition="left"
        onChange={setInvoiceModalTab}
        items={tabItems}
      />
    </Modal>
  );
};

InvoicesModal.propTypes = {
  visible: PropTypes.bool,
  isInvoiceAuditLoading: PropTypes.bool,
  onHideModal: PropTypes.func,
  onFinish: PropTypes.func,
  form: PropTypes.object,
  currentInvoice: PropTypes.object,
  usersObject: PropTypes.object,
  invoiceModalTab: PropTypes.string,
  isClientPage: PropTypes.bool,
  setInvoiceModalTab: PropTypes.func,
  role: PropTypes.string,
};

export default InvoicesModal;
