/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { isNumber, isString, get } from 'lodash';
import { Table, Avatar, Typography, Tooltip } from 'antd';
import { SearchOutlined, DollarOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { getStatusTag } from 'containers/ExtraPayments/utils';
import AvatarWithName from 'components/AvatarWithName';

import { getInitials } from 'utils/getInitials';
import { TEAM_ID, DEFAULT_AVATAR } from 'utils/constants';
import { DATE_FORMAT, DATE_MONTH_FORMAT } from 'utils/timeConstants';

import { formatNumberWithCommas } from 'utils/helpers';
import { LINKS } from 'config/routing';
import Tag from 'components/Common/Tag';
import FilterDropdown from './FilterDropdown';
import RevenueCell from './RevenueCell';
import EmployeeCell from './EmployeeCell';
import AdjustmentEditableCell from './AdjustmentEditableCell';
import MoneyCell from './MoneyCell';
import MoneyYearsCell from './MoneyYearsCell';
import TotalCell from './TotalCell';
import ProfitCell from './ProfitCell';
import TotalMoneyCell from './TotalMoneyCell';

import {
  defaultMonth,
  PROJECT_CATEGORIES,
  TITLE_ARRAY,
  ICON_COLORS,
  CELL_COLORS,
  EXTRA_PAYMENTS_STATUS,
  PROJECT_PROFITS_INDEX,
} from './constants';

import styles from './styles.scss';

const { Text } = Typography;

export const compareLastCalculate = () => {
  let last = localStorage.getItem('last-calculate');

  if (!last) return false;

  return dayjs(new Date()).diff(dayjs(last), 'minutes') <= 5;
};

const profitCell = (value, project) =>
  project.isChildren ? null : (
    <Tooltip title={value}>
      <div className={styles.profitCell}>
        <ProfitCell value={value} project={project} />
      </div>
    </Tooltip>
  );

const costCell = (value, { isChildren, formula }) =>
  isChildren ? (
    <Tooltip title={formula}>
      <div>
        <MoneyCell value={value} />
      </div>
    </Tooltip>
  ) : (
    <MoneyCell value={value} />
  );

const revenueCell = ({ onEditRevenue, onClearRevenue }) => (
  revenue,
  project,
) => (
  <RevenueCell
    onEdit={onEditRevenue}
    initialValue={+revenue}
    project={project}
    onClear={onClearRevenue}
  />
);

const adjustmentCell = onShowModal => (adjustment, record) => (
  <AdjustmentEditableCell
    onShowModal={() => onShowModal({ adjustment, record })}
    initialValue={+adjustment}
    project={record}
  />
);

const filteredValue = (valueType, filterInfo) =>
  (filterInfo && filterInfo[valueType]) || null;

const getColumnSearchProps = dataIndex => ({
  filterDropdown: props => <FilterDropdown {...props} dataIndex={dataIndex} />,
  filterIcon: <SearchOutlined />,
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : '',
});

const getFilters = filters =>
  filters && [
    ...Object.entries(filters).map(([id, data]) => ({
      text: data ? data.name : id,
      value: id,
    })),
    { text: '', value: null },
  ];

const getSortOrder = (condition, { field, order }) =>
  field === condition && order;

const renderId = (value, { isChildren, salary, hourly }) => {
  switch (true) {
    case !isChildren: {
      return value;
    }
    case !!salary: {
      return `${salary}$`;
    }
    case !!hourly: {
      return `${hourly}$/h`;
    }
    default: {
      return '';
    }
  }
};

const getCategoryFilters = filters =>
  filters?.map(filter => ({ text: filter, value: filter })) || [];

const getProfitPercentClassName = value => {
  if (value === null) return null;

  if (value < 0) {
    return styles.redCell;
  }

  if (value <= 30) {
    return styles.yellowCell;
  }

  return styles.greenCell;
};

export const getColumns = ({
  filterInfo,
  filtersData,
  onShowModal,
  onShowPaymentsModal,
  canRequestPayments,
  canOpenProjectModal,
  onShowAdjustmentModal,
  sortOrder,
  onClearRevenue,
  onEditRevenue,
}) => [
  {
    title: PROJECT_PROFITS_INDEX.id_title,
    dataIndex: PROJECT_PROFITS_INDEX.project_key,
    key: PROJECT_PROFITS_INDEX.project_key,
    sorter: true,
    sortOrder: getSortOrder(PROJECT_PROFITS_INDEX.project_key, sortOrder),
    width: 95,
    render: renderId,
  },
  {
    title: PROJECT_PROFITS_INDEX.project_title,
    dataIndex: PROJECT_PROFITS_INDEX.project_name,
    key: PROJECT_PROFITS_INDEX.project_name,
    width: 250,
    sortOrder: getSortOrder(PROJECT_PROFITS_INDEX.project_name, sortOrder),
    sorter: true,
    filteredValue: filteredValue(
      PROJECT_PROFITS_INDEX.project_name,
      filterInfo,
    ),
    render: (value, record) =>
      record.isChildren ? (
        <EmployeeCell record={record} />
      ) : (
        <div
          className={styles.projectName}
          onClick={
            canOpenProjectModal && onShowModal.bind(null, { value, record })
          }
        >
          {value}
        </div>
      ),
    ...getColumnSearchProps(PROJECT_PROFITS_INDEX.project_name),
  },
  {
    title: PROJECT_PROFITS_INDEX.hours_title,
    dataIndex: PROJECT_PROFITS_INDEX.spent_hours,
    key: PROJECT_PROFITS_INDEX.spent_hours,
    sorter: true,
    sortOrder: getSortOrder(PROJECT_PROFITS_INDEX.spent_hours, sortOrder),
    render: value => (
      <div className={styles.spentHours}>{Number(value).toFixed(2)}</div>
    ),
    width: 110,
  },
  {
    title: PROJECT_PROFITS_INDEX.cost_title,
    dataIndex: PROJECT_PROFITS_INDEX.cost,
    key: PROJECT_PROFITS_INDEX.cost,
    render: costCell,
    sorter: true,
    sortOrder: getSortOrder(PROJECT_PROFITS_INDEX.cost, sortOrder),
    width: 110,
  },
  {
    title: PROJECT_PROFITS_INDEX.revenue_title,
    dataIndex: PROJECT_PROFITS_INDEX.revenue,
    key: PROJECT_PROFITS_INDEX.revenue,
    render: revenueCell({ onEditRevenue, onClearRevenue }),
    sorter: true,
    sortOrder: getSortOrder(PROJECT_PROFITS_INDEX.revenue, sortOrder),
    width: 110,
  },
  {
    title: PROJECT_PROFITS_INDEX.adjustment_title,
    dataIndex: PROJECT_PROFITS_INDEX.adjustment,
    key: PROJECT_PROFITS_INDEX.adjustment,
    render: adjustmentCell(onShowAdjustmentModal),
    sorter: true,
    sortOrder: getSortOrder(PROJECT_PROFITS_INDEX.adjustment, sortOrder),
    width: 110,
  },
  {
    title: PROJECT_PROFITS_INDEX.profit_title,
    dataIndex: PROJECT_PROFITS_INDEX.profit,
    key: PROJECT_PROFITS_INDEX.profit,
    render: profitCell,
    sorter: true,
    sortOrder: getSortOrder(PROJECT_PROFITS_INDEX.profit, sortOrder),
    width: 110,
  },
  {
    title: PROJECT_PROFITS_INDEX.profit_percent_title,
    dataIndex: PROJECT_PROFITS_INDEX.profit_percent,
    key: PROJECT_PROFITS_INDEX.profit_percent,
    render: (value, record) => ({
      props: {
        className: !record.isChildren && getProfitPercentClassName(value),
      },
      children: value && `${formatNumberWithCommas(value)}%`,
    }),
    align: 'right',
    sortOrder: getSortOrder(PROJECT_PROFITS_INDEX.profit_percent, sortOrder),
    sorter: true,
    width: 110,
  },
  {
    title: PROJECT_PROFITS_INDEX.category_title,
    dataIndex: PROJECT_PROFITS_INDEX.project_category,
    key: PROJECT_PROFITS_INDEX.project_category,
    width: 130,
    render: (value, record) =>
      getProjectCategoryTag(record.isChildren ? record.team_id : value),
    filters: getCategoryFilters(filtersData.categories),
    filteredValue: filteredValue(
      PROJECT_PROFITS_INDEX.project_category,
      filterInfo,
    ),
    sorter: true,
    sortOrder: getSortOrder(PROJECT_PROFITS_INDEX.project_category, sortOrder),
  },
  {
    title: PROJECT_PROFITS_INDEX.pm_title,
    dataIndex: PROJECT_PROFITS_INDEX.project_manager,
    key: PROJECT_PROFITS_INDEX.project_manager,
    render: (value, record) =>
      getManagerValue({
        value,
        onShowPaymentsModal,
        field: PROJECT_PROFITS_INDEX.project_manager,
        canRequestPayments,
        record,
        filters: filtersData.project_managers,
      }),
    sorter: true,
    sortOrder: getSortOrder(PROJECT_PROFITS_INDEX.project_manager, sortOrder),
    filters: getFilters(filtersData.project_managers),
    filteredValue: filteredValue(
      PROJECT_PROFITS_INDEX.project_manager,
      filterInfo,
    ),
    width: 110,
  },
  {
    title: PROJECT_PROFITS_INDEX.bdm_title,
    dataIndex: PROJECT_PROFITS_INDEX.sales_manager,
    key: PROJECT_PROFITS_INDEX.sales_manager,
    render: (value, record) =>
      getManagerValue({
        value,
        onShowPaymentsModal,
        field: PROJECT_PROFITS_INDEX.sales_manager,
        canRequestPayments,
        record,
        filters: filtersData.sales_managers,
      }),
    sorter: true,
    sortOrder: getSortOrder(PROJECT_PROFITS_INDEX.sales_manager, sortOrder),
    filters: getFilters(filtersData.sales_managers),
    filteredValue: filteredValue(
      PROJECT_PROFITS_INDEX.sales_manager,
      filterInfo,
    ),
    width: 110,
  },
];

export const getProjectCategoryTag = category => {
  if (!category) return null;

  if (category && isString(category)) {
    const value = PROJECT_CATEGORIES[category];

    return (
      value && (
        <div className={styles.categoryTag}>
          <Tag color={get(value, ['color'], 'default')} key={status}>
            {get(value, ['title'], 'Не указан')}
          </Tag>
        </div>
      )
    );
  }

  if (category && isNumber(category)) {
    const team = Object.entries(TEAM_ID).find(team => team[1] === category);

    return (
      <div className={styles.categoryTag}>
        <Tag color="default">{team[0]}</Tag>
      </div>
    );
  }
};

export const getYear = month => dayjs(month).format('YYYY');

const getColor = ({ extraPayments, colorObj }) => {
  if (!extraPayments.length) return colorObj.gray;

  const currentColor = { color: colorObj.gray, status: '' };

  extraPayments.forEach(item => {
    switch (true) {
      case item.status === EXTRA_PAYMENTS_STATUS.declined:
        currentColor.color = colorObj.red;
        currentColor.status = item.status;

        break;
      case item.status === EXTRA_PAYMENTS_STATUS.pending &&
        currentColor.status !== EXTRA_PAYMENTS_STATUS.declined:
        currentColor.color = colorObj.yellow;
        currentColor.status = item.status;

        break;
      case item.status === EXTRA_PAYMENTS_STATUS.approved &&
        currentColor.status !== EXTRA_PAYMENTS_STATUS.declined &&
        currentColor.status !== EXTRA_PAYMENTS_STATUS.pending:
        currentColor.color = colorObj.green;
        currentColor.status = EXTRA_PAYMENTS_STATUS.approved;

        break;
      default:
        break;
    }
  });

  return currentColor.color;
};

const getManagerValue = ({
  value,
  filters,
  onShowPaymentsModal,
  canRequestPayments,
  field,
  record,
}) => {
  if (!value || !value.jira_id || !filters) return;

  const employeeData = filters[value.jira_id];

  if (!employeeData) return value.jira_id;

  const { avatarUrls, summary_employee_id, name } = employeeData;

  const extraPayments = value.extra_payments;

  if (!canRequestPayments) {
    return (
      <div>
        <Avatar size={22} src={avatarUrls || DEFAULT_AVATAR} />
        <span style={{ marginLeft: 10 }}>{name}</span>
      </div>
    );
  }

  const displayName = name ? getInitials(name) : value.jira_id;

  const onShowPaymentsModalHandler = () =>
    onShowPaymentsModal({
      record,
      employee_id: summary_employee_id,
      field,
      extraPayments,
      name,
      jiraId: value.jira_id,
    });

  return {
    props: {
      style: {
        background: getColor({ extraPayments, colorObj: CELL_COLORS }),
      },
    },
    children: (
      <div className={styles.managerContainer}>
        <AvatarWithName
          payload={displayName}
          avatarUrl={avatarUrls || DEFAULT_AVATAR}
          title={name}
        />
        <DollarOutlined
          style={{
            color: getColor({
              extraPayments,
              colorObj: ICON_COLORS,
            }),
            cursor: 'pointer',
          }}
          onClick={onShowPaymentsModalHandler}
        />
      </div>
    ),
  };
};

export const calculateTotal = (projects, field) =>
  projects.map(project => project[field]).reduce((acc, el) => (acc += +el), 0);

export const formatMoney = value => {
  const isValueNegative = value < 0;

  if (isValueNegative) {
    return `-$${(-Number(value))
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }

  return `$${Number(value)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const formatProgressStatus = calculateProgress =>
  (calculateProgress && calculateProgress.progress === 100
    ? calculateProgress.status
    : `${calculateProgress.status} ${calculateProgress.progress}%`) || '';

export const getTagColorByProgressStatus = status =>
  status === 'OK' ? 'green' : 'orange';

export const formatProgressDuration = duration => {
  const minutes = dayjs.utc(duration * 1000).format('m');
  const seconds = dayjs.utc(duration * 1000).format('s');

  return `${minutes !== '0' ? `${minutes} min` : ''} ${seconds} sec`;
};

const getIsColumnsShown = columns => {
  const isColumnsShown = {};

  columns.forEach(column => {
    isColumnsShown[column.dataIndex] = column.isShowColumn;
  });

  return isColumnsShown;
};

export const getSummary = (
  {
    count,
    hours,
    costs,
    revenue: revenueValue,
    profit: profitValue,
    adjustments,
  },
  columnsSettings,
) => {
  const {
    project_key,
    project_name,
    spent_hours,
    cost,
    revenue,
    adjustment,
    profit,
    profit_percent,
    project_category,
    project_manager,
    sales_manager,
  } = getIsColumnsShown(columnsSettings);

  return (
    <>
      <Table.Summary.Row>
        {project_key && (
          <Table.Summary.Cell>
            <TotalCell>TOTAL</TotalCell>
          </Table.Summary.Cell>
        )}
        {project_name && (
          <Table.Summary.Cell>
            <Text strong>{count}</Text>
          </Table.Summary.Cell>
        )}
        {spent_hours && (
          <Table.Summary.Cell>
            <TotalCell>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {Number(hours).toFixed(2)}
              </div>
            </TotalCell>
          </Table.Summary.Cell>
        )}
        {cost && <TotalMoneyCell value={costs} />}
        {revenue && <TotalMoneyCell value={revenueValue} />}
        {adjustment && <TotalMoneyCell value={adjustments} />}
        {profit && <TotalMoneyCell value={profitValue} />}
        {profit_percent && <Table.Summary.Cell />}
        {project_category && <Table.Summary.Cell />}
        {project_manager && <Table.Summary.Cell />}
        {sales_manager && <Table.Summary.Cell />}
      </Table.Summary.Row>
    </>
  );
};

export const getProjectProfitsMonth = () => {
  let month = localStorage.getItem('project-profits-month');

  if (!month) {
    month = defaultMonth.startOf('month').format(DATE_FORMAT);
    localStorage.setItem('project-profits-month', month);
  }

  return month;
};

export const setProjectProfitsMonth = month => {
  localStorage.setItem(
    'project-profits-month',
    month.startOf('month').format(DATE_FORMAT),
  );
};

export const formatResult = (result, arrayOfMonth) =>
  result.map((item, index) => ({
    month: arrayOfMonth[index],
    data: get(item, 'data', []),
  }));

export const getLastTwelveMonths = date => {
  const newDate = new Date(date);
  const arrayOfMonth = [];
  for (let i = 0; i > -12; i--) {
    const past = new Date(newDate.getFullYear(), newDate.getMonth() + i, 1);
    const month = past.getMonth() + 1;
    const year = past.getFullYear();

    arrayOfMonth.unshift(
      `${year}-${month.toString().length === 1 ? '0' : ''}${month}-01`,
    );
  }

  return arrayOfMonth;
};

export const getInfoAboutCurrentProject = ({
  infoAboutProjectProfitsByYear,
  arrayOfMonth,
}) =>
  TITLE_ARRAY.map(title =>
    arrayOfMonth.reduce((acc, cur) => {
      const currPeriod = infoAboutProjectProfitsByYear.find(
        elem => get(elem, 'period') === cur,
      );
      const cost =
        +get(currPeriod, ['cost'], '') - +get(currPeriod, ['adjustment'], '');

      return {
        ...acc,
        title: title,
        [dayjs(cur).format(DATE_MONTH_FORMAT)]:
          title === 'cost' ? cost : get(currPeriod, [title], ''),
      };
    }, {}),
  );

const getModalFieldColor = field => {
  switch (field) {
    case 'cost':
      return 'red';
    case 'revenue':
      return '#FFCE45';
    case 'profit':
      return 'green';
    default:
      return 'blue';
  }
};

export const getModalColumns = ({ arrayOfMonth }) => [
  {
    dataIndex: 'title',
    key: 'title',
    width: 120,
    render: value => (
      <span
        style={{
          fontWeight: 600,
          fontSize: '0.875rem',
          textTransform: 'capitalize',
          color: getModalFieldColor(value),
        }}
      >
        {value}
      </span>
    ),
  },
  ...arrayOfMonth.map(item => ({
    title: (
      <span style={{ fontSize: '0.8rem' }}>
        {dayjs(item).format('MMMM, YY')}
      </span>
    ),
    dataIndex: item,
    key: item,
    width: 130,
    render: (value, data, index) =>
      !!value && (
        <MoneyYearsCell
          value={value}
          style={{ fontSize: '0.875rem' }}
          index={index}
        />
      ),
  })),
];

export const getExtraPaymentsColumns = ({ name, employeeId }) => [
  {
    title: 'Employee',
    dataIndex: 'employee_id',
    key: 'employee_id',
    align: 'center',
    render: () => <Link to={LINKS.summaryEmployee(employeeId)}>{name}</Link>,
  },
  {
    title: 'Period',
    dataIndex: 'valid_from',
    key: 'valid_from',
    align: 'center',
    render: value => dayjs(value).format(DATE_FORMAT),
  },
  {
    title: 'Amount',
    dataIndex: 'amount_usd',
    key: 'amount_usd',
    render: value => formatMoney(value),
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: value => getStatusTag(value),
  },
];

export const getDataSource = data =>
  data.map(project => ({
    ...project,
    key: project.project_id,
    children: project.cost_rates?.map(costRate => ({
      ...costRate,
      isChildren: true,
    })),
  }));

getSummary.propTypes = {
  count: PropTypes.number,
  hours: PropTypes.number,
  costs: PropTypes.number,
  revenue: PropTypes.number,
  adjustments: PropTypes.number,
  profit: PropTypes.number,
};
