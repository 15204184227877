import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Modal } from 'antd';
import PageHeader from 'components/Common/PageHeader';

import { Button, Loader } from 'components/Common';
import InvoiceTypesModal from 'components/InvoiceTypesCommon/InvoiceTypesModal';

import { invoiceTypesSelector } from 'redux/selectors';
import {
  getInvoiceTypes,
  updateInvoiceType,
  createInvoiceType,
  deleteInvoiceType,
} from 'redux/InvoiceTypes/actions';

import { getRowKey } from 'utils/helpers';
import { getColumns } from './utils';

const InvoiceTypesPage = () => {
  const [currentType, setCurrentType] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch = useDispatch();

  const { invoiceTypes, isInvoiceTypesLoading } = useSelector(
    invoiceTypesSelector,
  );

  useEffect(() => {
    dispatch(getInvoiceTypes());
  }, []);

  const toggleModal = isVisible => () => {
    setIsModalVisible(isVisible);

    if (!isVisible) {
      setCurrentType(null);
    }
  };

  const onEdit = type => () => {
    setCurrentType(type);
    toggleModal(true)();
  };

  const onUpdateType = type => {
    dispatch(updateInvoiceType(type));
    toggleModal(false)();
  };

  const onCreateType = type => {
    dispatch(createInvoiceType(type));
    toggleModal(false)();
  };

  const onDeleteType = id => () => {
    Modal.confirm({
      title: 'Delete invoice type',
      content: 'Are you sure you want to delete invoice type?',
      onOk: () => {
        dispatch(deleteInvoiceType(id));
        Modal.destroyAll();
      },
    });
  };

  const columns = useMemo(() => getColumns({ onEdit, onDeleteType }), [
    invoiceTypes,
  ]);

  return (
    <div>
      <PageHeader
        title="Incoming invoice types"
        extra={
          <Button type="primary" onClick={toggleModal(true)}>
            Add invoice type
          </Button>
        }
      />
      <InvoiceTypesModal
        currentType={currentType}
        isVisible={isModalVisible}
        onCreateInvoiceType={onCreateType}
        onUpdateInvoiceType={onUpdateType}
        toggleModal={toggleModal}
      />
      <Loader loading={isInvoiceTypesLoading}>
        <Table
          columns={columns}
          dataSource={invoiceTypes}
          pagination={false}
          rowKey={getRowKey}
        />
      </Loader>
    </div>
  );
};

export default InvoiceTypesPage;
