import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isAverageSalariesLoading: false,
  averageSalaries: {},
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_AVERAGE_SALARY_BY_YEAR:
      return state.set('isAverageSalariesLoading', true);
    case types.ADD_AVERAGE_SALARY:
      return state.set('isAverageSalariesLoading', true);
    case types.UPDATE_AVERAGE_SALARY:
      return state.set('isAverageSalariesLoading', true);

    case types.GET_AVERAGE_SALARY_BY_YEAR_SUCCESS:
      return state
        .set('averageSalaries', payload)
        .set('isAverageSalariesLoading', false);

    case types.GET_AVERAGE_SALARY_BY_YEAR_FAILED:
      return state.set('isAverageSalariesLoading', false);
    default:
      return state;
  }
}
