import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DatePicker } from 'antd';

import styles from './styles.scss';

const { RangePicker: AntdRangePicker } = DatePicker;

const RangePicker = ({ picker, ...props }) => (
  <AntdRangePicker
    {...props}
    picker={picker || 'date'}
    className={classNames([props.className, styles.rangePicker])}
  />
);

RangePicker.propTypes = {
  className: PropTypes.string,
  picker: PropTypes.oneOf(['date', 'week', 'month', 'quarter', 'year']),
};

export default RangePicker;
