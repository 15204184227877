import React from 'react';
import PropTypes from 'prop-types';

import { TableListView } from 'components/DataListCommon';
import { getRowKey } from 'utils/helpers';

import { getTableColumns } from './utils';

export default function UserPaysheets({ paysheets = [], ...props }) {
  const onRow = () => ({
    onClick: () => null,
  });

  return (
    <TableListView
      style={{ margin: '20px 20px 20px 0' }}
      dataSource={paysheets}
      columns={getTableColumns()}
      onRow={onRow}
      {...props}
      storageKey="employeesPayments"
      isPageWithId
      rowKey={getRowKey}
    />
  );
}

UserPaysheets.propTypes = {
  paysheets: PropTypes.array,
};
