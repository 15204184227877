import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import currencyFormatter from 'currency-formatter';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import Timeline from 'components/Common/Timeline';
import TimelineItem from 'components/Common/TimelineItem';
import { DATE_MONTH_FORMAT } from 'utils/timeConstants';
import { CURRENCY_CODES } from 'utils/constants';

import styles from './styles.scss';

const SalaryList = ({ salaries, onSalariesDelete, onEditSalary }) => {
  const deleteFactory = id => () => onSalariesDelete(id);

  return (
    <Timeline highlightFirst>
      {salaries.map(
        ({
          id,
          fixed_salary_byn,
          valid_from,
          fixed_salary_usd,
          hourly_rate_usd,
        }) => (
          <TimelineItem key={id}>
            <div className={styles.field}>
              {fixed_salary_byn !== null && (
                <span className={styles.salary}>
                  {currencyFormatter.format(fixed_salary_byn, {
                    code: CURRENCY_CODES.BYR,
                  })}
                </span>
              )}
              {fixed_salary_usd !== null && (
                <span className={styles.salary}>
                  {currencyFormatter.format(fixed_salary_usd, {
                    code: CURRENCY_CODES.USD,
                  })}
                </span>
              )}
              {hourly_rate_usd !== null && (
                <span className={styles.salary}>
                  {currencyFormatter.format(hourly_rate_usd, {
                    code: CURRENCY_CODES.USD,
                  })}
                  /h{' '}
                  {`(${currencyFormatter.format(168 * hourly_rate_usd, {
                    code: CURRENCY_CODES.USD,
                  })})`}
                </span>
              )}
              {' - '}
              {dayjs(valid_from).format(DATE_MONTH_FORMAT)}
              <div className={styles.hoverButton}>
                <EditOutlined
                  className={styles.editIcon}
                  onClick={() =>
                    onEditSalary({
                      salary:
                        fixed_salary_byn || fixed_salary_usd || hourly_rate_usd,
                      id,
                      valid_from,
                      fixed_salary_byn,
                      fixed_salary_usd,
                      hourly_rate_usd,
                    })
                  }
                />

                <DeleteOutlined
                  className={styles.deleteIcon}
                  type="delete"
                  onClick={deleteFactory(id)}
                />
              </div>
            </div>
          </TimelineItem>
        ),
      )}
    </Timeline>
  );
};

SalaryList.propTypes = {
  salaries: PropTypes.array,
  onSalariesDelete: PropTypes.func,
  onEditSalary: PropTypes.func,
};

export default SalaryList;
