export const types = {
  GET_LEADS: 'leads/GET_LEADS',
  GET_LEADS_DATA_SUCCESS: 'leads/GET_LEADS_SUCCESS',
  GET_LEADS_DATA_FAILED: 'leads/GET_LEADS_FAILED',

  GET_LEADS_FILTERS: 'leads/GET_LEADS_FILTERS',
  GET_LEADS_FILTERS_FAILED: 'leads/GET_LEADS_FILTERS_FAILED',
  GET_LEADS_FILTERS_SUCCESS: 'leads/GET_LEADS_FILTERS_SUCCESS',

  GET_LEADS_NOTES: 'leads/GET_LEADS_NOTES',
  GET_LEADS_NOTES_FAILED: 'leads/GET_LEADS_NOTES_FAILED',
  GET_LEADS_NOTES_SUCCESS: 'leads/GET_LEADS_NOTES_SUCCESS',

  GET_LEAD_DATA: 'leads/GET_LEAD_DATA',
  GET_LEAD_DATA_SUCCESS: 'leads/GET_LEAD_DATA_SUCCESS',
  GET_LEAD_DATA_FAILED: 'leads/GET_LEAD_DATA_FAILED',

  GET_LEAD_ATTACHMENTS: 'leads/GET_LEAD_ATTACHMENTS',
  GET_LEAD_ATTACHMENTS_SUCCESS: 'leads/GET_LEAD_ATTACHMENTS_SUCCESS',
  GET_LEAD_ATTACHMENTS_FAILED: 'leads/GET_LEAD_ATTACHMENTS_FAILED',

  GET_LEAD_ACTIVITIES: 'leads/GET_LEAD_ACTIVITIES',
  GET_LEAD_ACTIVITIES_SUCCESS: 'leads/GET_LEAD_ACTIVITIES_SUCCESS',
  GET_LEAD_ACTIVITIES_FAILED: 'leads/GET_LEAD_ACTIVITIES_FAILED',

  UPDATE_LEAD_ACTIVITIES: 'leads/UPDATE_LEAD_ACTIVITIES',
  UPDATE_LEAD_ACTIVITIES_SUCCESS: 'leads/UPDATE_LEAD_ACTIVITIES_SUCCESS',
  UPDATE_LEAD_ACTIVITIES_FAILED: 'leads/UPDATE_LEAD_ACTIVITIES_FAILED',

  UPDATE_LEAD: 'leads/UPDATE_LEAD',
  UPDATE_LEAD_SUCCESS: 'leads/UPDATE_LEAD_SUCCESS',
  UPDATE_LEAD_FAILED: 'leads/UPDATE_LEAD_FAILED',

  SELECT_LEAD_OWNER: 'leads/SELECT_LEAD_OWNER',
  SELECT_LEAD_OWNER_SUCCESS: 'leads/SELECT_LEAD_OWNER_SUCCESS',
  SELECT_LEAD_OWNER_FAILED: 'leads/SELECT_LEAD_OWNER_FAILED',

  SAVE_LEAD_NOTE: 'leads/SAVE_LEAD_NOTE',
  SAVE_LEAD_NOTE_SUCCESS: 'leads/SAVE_LEAD_NOTE_SUCCESS',
  SAVE_LEAD_NOTE_FAILED: 'leads/SAVE_LEAD_NOTE_FAILED',

  UPDATE_LEAD_NOTE: 'leads/UPDATE_LEAD_NOTE',
  UPDATE_LEAD_NOTE_FAILED: 'leads/UPDATE_LEAD_NOTE_FAILED',
  UPDATE_LEAD_NOTE_SUCCESS: 'leads/UPDATE_LEAD_NOTE_SUCCESS',

  UPDATE_LEAD_STATUS: 'leads/UPDATE_LEAD_STATUS',
  UPDATE_LEAD_STATUS_FAILED: 'leads/UPDATE_LEAD_STATUS_FAILED',
  UPDATE_LEAD_STATUS_SUCCESS: 'leads/UPDATE_LEAD_STATUS_SUCCESS',

  GET_LEAD_NOTES: 'leads/GET_LEAD_NOTES',
  GET_LEAD_NOTES_SUCCESS: 'leads/GET_LEAD_NOTES_SUCCESS',
  GET_LEAD_NOTES_FAILED: 'leads/GET_LEAD_NOTES_FAILED',

  GET_LEAD_AUDIT: 'leads/GET_LEAD_AUDIT',
  GET_LEAD_AUDIT_SUCCESS: 'leads/GET_LEAD_AUDIT_SUCCESS',
  GET_LEAD_AUDIT_FAILED: 'leads/GET_LEAD_AUDIT_FAILED',

  GET_LEAD_GEO: 'leads/GET_LEAD_GEO',
  GET_LEAD_GEO_SUCCESS: 'leads/GET_LEAD_GEO_SUCCESS',
  GET_LEAD_GEO_FAILED: 'leads/GET_LEAD_GEO_FAILED',
};
