import { actionFactory } from 'redux/factories';
import { types } from './types';

export const getOrganizationsSearch = actionFactory(
  types.GET_ORGANIZATIONS_SEARCH,
);
export const getOrganizations = actionFactory(types.GET_ORGANIZATIONS);
export const updateOrganization = actionFactory(types.UPDATE_ORGANIZATION);
export const createOrganization = actionFactory(types.CREATE_ORGANIZATION);
export const deleteOrganization = actionFactory(types.DELETE_ORGANIZATION);
export const getInvoiceOrganizationAccount = actionFactory(
  types.GET_INVOICE_ORGANIZATION_ACCOUNTS,
);
export const getOrganizationAccounts = actionFactory(
  types.GET_ORGANIZATION_ACCOUNTS,
);

export const createOrganizationAccount = actionFactory(
  types.CREATE_ORGANIZATION_ACCOUNT,
);
export const updateOrganizationAccount = actionFactory(
  types.UPDATE_ORGANIZATION_ACCOUNT,
);
export const deleteOrganizationAccount = actionFactory(
  types.DELETE_ORGANIZATION_ACCOUNT,
);
export const clearOrganizationAccounts = actionFactory(
  types.CLEAR_ORGANIZATION_ACCOUNTS,
);
