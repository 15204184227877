export const types = {
  GET_SUMMARY_EMPLOYEES: 'summary-employees/GET_SUMMARY_EMPLOYEES',
  GET_SUMMARY_EMPLOYEES_SUCCESS:
    'summary-employees/GET_SUMMARY_EMPLOYEES_SUCCESS',
  GET_SUMMARY_EMPLOYEES_FAILED:
    'summary-employees/GET_SUMMARY_EMPLOYEES_FAILED',

  GET_SUMMARY_EMPLOYEE: 'summary-employees/GET_SUMMARY_EMPLOYEE',
  GET_SUMMARY_EMPLOYEE_SUCCESS:
    'summary-employees/GET_SUMMARY_EMPLOYEE_SUCCESS',
  GET_SUMMARY_EMPLOYEE_FAILED: 'summary-employees/GET_SUMMARY_EMPLOYEE_FAILED',

  GET_SUMMARY_EMPLOYEE_SALARIES:
    'summary-employees/GET_SUMMARY_EMPLOYEE_SALARIES',
  GET_SUMMARY_EMPLOYEE_SALARIES_SUCCESS:
    'summary-employees/GET_SUMMARY_EMPLOYEE_SALARIES_SUCCESS',
  GET_SUMMARY_EMPLOYEE_SALARIES_FAILED:
    'summary-employees/GET_SUMMARY_EMPLOYEE_SALARIES_FAILED',

  GET_SUMMARY_EMPLOYEE_COMMENTS:
    'summary-employees/GET_SUMMARY_EMPLOYEE_COMMENTS',
  GET_SUMMARY_EMPLOYEE_COMMENTS_SUCCESS:
    'summary-employees/GET_SUMMARY_EMPLOYEE_COMMENTS_SUCCESS',
  GET_SUMMARY_EMPLOYEE_COMMENTS_FAILED:
    'summary-employees/GET_SUMMARY_EMPLOYEE_COMMENTS_FAILED',

  GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS:
    'summary-employees/GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS',
  GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
    'summary-employees/GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS',
  GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    'summary-employees/GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS_FAILED',

  GET_SUMMARY_SOCIAL_REQUESTS: 'summary-employees/GET_SUMMARY_SOCIAL_REQUESTS',
  GET_SUMMARY_SOCIAL_REQUESTS_SUCCESS:
    'summary-employees/GET_SUMMARY_SOCIAL_REQUESTS_SUCCESS',
  GET_SUMMARY_SOCIAL_REQUESTS_FAILED:
    'summary-employees/GET_SUMMARY_SOCIAL_REQUESTS_FAILED',

  GET_SUMMARY_SOCIAL_REQUESTS_INFO:
    'summary-employees/GET_SUMMARY_SOCIAL_REQUESTS_INFO',
  GET_SUMMARY_SOCIAL_REQUESTS_INFO_SUCCESS:
    'summary-employees/GET_SUMMARY_SOCIAL_REQUESTS_INFO_SUCCESS',
  GET_SUMMARY_SOCIAL_REQUESTS_INFO_FAILED:
    'summary-employees/GET_SUMMARY_SOCIAL_REQUESTS_INFO_FAILED',

  CREATE_SUMMARY_SOCIAL_REQUEST:
    'summary-employees/CREATE_SUMMARY_SOCIAL_REQUEST',
  CREATE_SUMMARY_SOCIAL_REQUEST_SUCCESS:
    'summary-employees/CREATE_SUMMARY_SOCIAL_REQUEST_SUCCESS',
  CREATE_SUMMARY_SOCIAL_REQUEST_FAILED:
    'summary-employees/CREATE_SUMMARY_SOCIAL_REQUEST_FAILED',

  UPDATE_SUMMARY_SOCIAL_REQUEST:
    'summary-employees/UPDATE_SUMMARY_SOCIAL_REQUEST',
  UPDATE_SUMMARY_SOCIAL_REQUEST_SUCCESS:
    'summary-employees/UPDATE_SUMMARY_SOCIAL_REQUEST_SUCCESS',
  UPDATE_SUMMARY_SOCIAL_REQUEST_FAILED:
    'summary-employees/UPDATE_SUMMARY_SOCIAL_REQUEST_FAILED',

  DELETE_SUMMARY_SOCIAL_REQUEST:
    'summary-employees/DELETE_SUMMARY_SOCIAL_REQUEST',
  DELETE_SUMMARY_SOCIAL_REQUEST_SUCCESS:
    'summary-employees/DELETE_SUMMARY_SOCIAL_REQUEST_SUCCESS',
  DELETE_SUMMARY_SOCIAL_REQUEST_FAILED:
    'summary-employees/DELETE_SUMMARY_SOCIAL_REQUEST_FAILED',

  GET_SUMMARY_PROFILES: 'summary-employees/GET_SUMMARY_PROFILES',
  GET_SUMMARY_PROFILES_SUCCESS:
    'summary-employees/GET_SUMMARY_PROFILES_SUCCESS',
  GET_SUMMARY_PROFILES_FAILED: 'summary-employees/GET_SUMMARY_PROFILES_FAILED',
};
