import { takeLatest, call, put, select } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';
import { REDUX_DATA_HELPERS } from 'utils/helpers';
import { invoiceTypesSelector } from 'redux/selectors';

import { sagaErrorHandler } from 'utils/common';
import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { INVOICE_TYPES_MESSAGES, types } from './types';

function* getInvoiceTypes() {
  try {
    const { data } = yield call(API.getInvoiceTypes);

    yield put({ type: types.GET_INVOICE_TYPES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_INVOICE_TYPES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* createInvoiceType({ payload }) {
  try {
    const { invoiceTypes } = yield select(invoiceTypesSelector);
    const { data } = yield call(API.createInvoiceType, payload);

    yield put({
      type: types.CREATE_INVOICE_TYPE_SUCCESS,
      payload: [...invoiceTypes, data],
    });
    message.success(INVOICE_TYPES_MESSAGES.CREATE_INVOICE_TYPE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_INVOICE_TYPE_FAILED,
      INVOICE_TYPES_MESSAGES.CREATE_INVOICE_TYPE_FAILED,
    );
  }
}

function* updateInvoiceType({ payload }) {
  try {
    const { invoiceTypes } = yield select(invoiceTypesSelector);
    const { data } = yield call(API.updateInvoiceType, payload);

    const updatedInvoiceTypes = REDUX_DATA_HELPERS.updateData({
      data: invoiceTypes,
      editedItem: data,
      id: payload.id,
    });

    yield put({
      type: types.UPDATE_INVOICE_TYPE_SUCCESS,
      payload: updatedInvoiceTypes,
    });
    message.success(INVOICE_TYPES_MESSAGES.UPDATE_INVOICE_TYPE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_INVOICE_TYPE_FAILED,
      INVOICE_TYPES_MESSAGES.UPDATE_INVOICE_TYPE_FAILED,
    );
  }
}

function* deleteInvoiceType({ payload }) {
  try {
    const { invoiceTypes } = yield select(invoiceTypesSelector);
    yield call(API.deleteInvoiceType, payload);

    const updatedInvoiceTypes = REDUX_DATA_HELPERS.removeItem({
      data: invoiceTypes,
      id: payload,
    });

    yield put({
      type: types.DELETE_INVOICE_TYPE_SUCCESS,
      payload: updatedInvoiceTypes,
    });
    message.success(INVOICE_TYPES_MESSAGES.DELETE_INVOICE_TYPE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_INVOICE_TYPE_FAILED,
      INVOICE_TYPES_MESSAGES.DELETE_INVOICE_TYPE_FAILED,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_INVOICE_TYPES, getInvoiceTypes);
  yield takeLatest(types.CREATE_INVOICE_TYPE, createInvoiceType);
  yield takeLatest(types.UPDATE_INVOICE_TYPE, updateInvoiceType);
  yield takeLatest(types.DELETE_INVOICE_TYPE, deleteInvoiceType);
}
