import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  EnvironmentOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import get from 'lodash/get';

import styles from './styles.scss';

const ClientSummary = ({
  client,
  type = 'horizontal',
  showDescription = false,
}) => (
  <ul
    className={[
      styles[`client-summary`],
      styles[`client-summary_${type}`],
    ].join(' ')}
  >
    <li key="location">
      <UserOutlined className={styles.infoIcon} />
      {client.owner_name}
    </li>
    <li key="owner">
      <EnvironmentOutlined className={styles.infoIcon} />
      {client.location}
    </li>
    {showDescription && client.description && (
      <li key="description" className={styles.itemDescription}>
        <InfoCircleOutlined className={styles.infoIcon} />
        <div>{client.description}</div>
      </li>
    )}
  </ul>
);

ClientSummary.propTypes = {
  client: PropTypes.object,
  showDescription: PropTypes.bool,
  type: PropTypes.oneOf(['vertical', 'horizontal']),
};

export default ClientSummary;
