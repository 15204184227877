import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { formatMoney } from '../utils';
import { editableItemsStyles } from './constants';
// @ts-ignore
import styles from './styles.scss';

const RevenueCell = ({ initialValue, project, onClear, onEdit }) => {
  const [isHover, setHover] = useState(false);

  const onEditHandler = () => {
    onEdit(initialValue, project);
  };

  const onChangeHover = value => e => {
    e.preventDefault();
    e.stopPropagation();
    setHover(value);
  };

  const onClearHandler = () => {
    Modal.warning({
      title: 'Clear project revenue',
      content: 'Are you sure you want to clear project revenue?',
      okText: 'Clear',
      cancelText: 'Cancel',
      onOk: () => onClear(project),
    });
  };

  return (
    <div
      onMouseEnter={onChangeHover(true)}
      onMouseLeave={onChangeHover(false)}
      className={styles.editableCellWrapper}
    >
      {!project.isChildren && (
        <div className={styles.editableCellEdit}>
          {!isHover ? (
            <div style={editableItemsStyles}>
              {Number(project.profit) === 0 && Number(project.revenue) === 0
                ? ''
                : formatMoney(initialValue)}
            </div>
          ) : (
            <>
              <Button
                style={editableItemsStyles}
                type="link"
                onClick={onEditHandler}
              >
                Edit
              </Button>
              {initialValue ? (
                <Button
                  style={editableItemsStyles}
                  type="link"
                  onClick={onClearHandler}
                >
                  Clear
                </Button>
              ) : null}
            </>
          )}
        </div>
      )}
    </div>
  );
};

RevenueCell.propTypes = {
  initialValue: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  onClear: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default RevenueCell;
