/* eslint-disable indent */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, groupBy, sortBy } from 'lodash';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Modal as AntdModal, Alert, Typography, Dropdown } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  EllipsisOutlined,
  CalendarOutlined,
} from '@ant-design/icons';

import DatePicker from 'components/Common/DatePicker';
import Tag from 'components/Common/Tag';
import Table from 'components/Common/Table';
import Modal from 'components/Common/Modal';
import { Button } from 'components/Common';
import { DATE_FORMAT } from 'utils/timeConstants';
import { formatNumberWithCommas, getRowKey } from 'utils/helpers';

import { hasRights } from 'utils/permissions';
import { CLIENT_PERMISSIONS } from 'containers/ClientPage/constants';
import {
  getColumns,
  getSummary,
  getCurrentProjectTitle,
  getFilteredAllocations,
  getTagParams,
} from './utils';
import { EVENT_TYPES, GREEN_COLOR, DELETED_COLOR } from '../constants';

import styles from './styles.scss';

const { Text } = Typography;

const InfoItem = ({
  type,
  action,
  deal = null,
  actions,
  allocations = null,
  jiraRoles = null,
  jiraProjects = null,
  onShowModalDeal = () => {},
  endDeal = () => {},
  deleteDeal = () => {},
  filteredClientAudit = [],
  groupedDealsByProject = {},
  allocationsGroupsById = {},
  clientAudit = [],
  role,
}) => {
  const [filteredAllocations, setFilteredAllocations] = useState([]);
  const [isVisibleEndDealModal, setIsVisibleEndDealModal] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [endsAt, setEndsAt] = useState(dayjs());

  const sortedDealsByStartsAt = useMemo(
    () =>
      deal &&
      groupedDealsByProject[deal.project_id] &&
      sortBy(groupedDealsByProject[deal.project_id], 'starts_at'),
    [deal, groupedDealsByProject],
  );

  const indexOfDeal =
    sortedDealsByStartsAt &&
    sortedDealsByStartsAt.findIndex(item => item.id === deal.id);
  const dealsInArray = sortedDealsByStartsAt ? sortedDealsByStartsAt.length : 0;

  const isPreviousDealExisted = indexOfDeal > 0 && indexOfDeal < dealsInArray;
  const previousDeal =
    isPreviousDealExisted && sortedDealsByStartsAt[indexOfDeal - 1];
  const isPreviousDealHasEndDate = Boolean(previousDeal.ends_at);

  const projectTitle =
    deal &&
    getCurrentProjectTitle({ jiraProjects, projectId: deal.project_id });

  const columns =
    jiraRoles &&
    filteredAllocations &&
    getColumns({
      jiraRoles: groupBy(jiraRoles, 'id'),
      filteredClientAudit: filteredClientAudit[0],
      deal,
      groupedDealsByProject,
      allocationsGroupsById,
    });

  const endDealModalToggle = () => {
    setIsVisibleEndDealModal(prev => !prev);
  };

  const onChangeDealEndsAtDate = value => {
    if (value) {
      setEndsAt(dayjs(value));
      setIsShowAlert(dayjs(value).isBefore(deal.starts_at));
    } else {
      setEndsAt(null);
    }
  };

  const onEndDeal = () => {
    // @ts-ignore
    endDeal({ dealId: deal.id, endsAt: dayjs(endsAt).format(DATE_FORMAT) });
    setIsVisibleEndDealModal(false);
  };

  const onDeleteDeal = () => {
    AntdModal.confirm({
      title: 'Delete deal',
      content: 'Are you sure you want to delete this deal?',
      onOk: () => {
        // @ts-ignore
        deleteDeal({ deal_id: deal.id });
        AntdModal.destroyAll();
      },
    });
  };

  const infoChanges = useMemo(
    () =>
      deal
        ? clientAudit.reduceRight((acc, item) => {
            if (
              item.auditable_type === 'Deal' &&
              item.action === 'update' &&
              item.auditable_id === deal.id
            ) {
              return { ...acc, ...item.audited_changes };
            }

            return acc;
          }, {})
        : {},
    [clientAudit, deal],
  );

  useEffect(() => {
    if (allocations && deal) {
      setFilteredAllocations(
        getFilteredAllocations({
          allocations,
          dealAllocations: filteredClientAudit[0]?.dealAllocations,
        }),
      );
    }
  }, [allocations, deal, filteredClientAudit]);

  useEffect(() => {
    if (isVisibleEndDealModal) {
      onChangeDealEndsAtDate(dayjs(deal.starts_at));
    }
  }, [isVisibleEndDealModal]);

  const onGetSummary = () =>
    getSummary({
      filteredAllocations,
      deal,
      infoChanges,
    });

  const dropdownItem = deal && [
    {
      label: 'Edit',
      key: 'Edit',
      onClick: () => onShowModalDeal(),
      icon: deal.deleted_at ? <EyeOutlined /> : <EditOutlined />,
      disabled: !hasRights(role, CLIENT_PERMISSIONS.updateDeal),
    },
    {
      label: 'Remove',
      key: 'Remove',
      onClick: () => onDeleteDeal(),
      disabled:
        deal.deleted_at || !hasRights(role, CLIENT_PERMISSIONS.deleteDeal),
      icon: <DeleteOutlined />,
    },
    {
      label: 'Set end date',
      key: 'End',
      onClick: () => endDealModalToggle(),
      disabled:
        deal.deleted_at || !hasRights(role, CLIENT_PERMISSIONS.updateDeal),
      icon: <CalendarOutlined />,
    },
  ];

  const tagParams = getTagParams({
    deal,
    isPreviousDealHasEndDate,
    previousDeal,
    isPreviousDealExisted,
    actions,
    action,
  });

  return (
    <div className={styles.dealInfo}>
      <div className={styles.actionContainer}>
        <div>
          <span className={classNames(styles.actionDescription, styles[type])}>
            {
              <span className={styles.dealMargins}>
                {deal
                  ? projectTitle
                  : `${EVENT_TYPES[`${type}`.toLowerCase()]}`}
              </span>
            }
          </span>
          {tagParams && (
            <Tag color={tagParams.color} className={styles.tag}>
              {tagParams.title}
            </Tag>
          )}
        </div>

        <div className={styles.actionInfo}>
          {deal && (
            <>
              <span className={styles.dealPeriod}>
                <span
                  className={styles.dealStarts}
                  style={{
                    color: 'starts_at' in infoChanges ? GREEN_COLOR : '',
                  }}
                >
                  {`Starting ${deal.starts_at}`}
                </span>
                {deal.ends_at ? (
                  <span className={styles.dealEnds}>
                    {` - Ends at: ${deal.ends_at}`}
                  </span>
                ) : (
                  <span className={styles.dealEnds}> - now</span>
                )}
              </span>
              <Dropdown menu={{ items: dropdownItem }}>
                <EllipsisOutlined className={styles.dealMenu} />
              </Dropdown>
            </>
          )}
        </div>
      </div>
      <>
        {!isEmpty(filteredAllocations) ? (
          <div>
            <Table
              pagination={false}
              bordered={false}
              dataSource={filteredAllocations}
              size="small"
              columns={columns}
              summary={onGetSummary}
              rowKey={getRowKey}
            />
          </div>
        ) : (
          <span className={styles.tableItem}>
            {deal && deal.currency_code && deal.fix_price && (
              <>
                Fixed price
                <Text
                  style={{
                    color: 'fix_price' in infoChanges ? GREEN_COLOR : '',
                  }}
                >
                  {deal.currency_code} {formatNumberWithCommas(deal.fix_price)}
                </Text>
              </>
            )}
          </span>
        )}
      </>
      <Modal
        open={isVisibleEndDealModal}
        onCancel={endDealModalToggle}
        destroyOnClose
        title="End deal"
        footer={
          <>
            <Button onClick={endDealModalToggle}>Cancel</Button>
            <Button
              type="primary"
              disabled={!endsAt || isShowAlert}
              onClick={onEndDeal}
            >
              OK
            </Button>
          </>
        }
      >
        <div className={styles.endDealPickerContainer}>
          Select the date when you want to end the deal
          <div>
            <DatePicker
              value={endsAt}
              format={DATE_FORMAT}
              onChange={onChangeDealEndsAtDate}
            />
          </div>
        </div>
        {isShowAlert && (
          <Alert
            type="error"
            message={`Thе end date of the deal cannot be earlier than the start date of the deal (${
              deal.starts_at
            })`}
            style={{ marginTop: '15px' }}
          />
        )}
      </Modal>
    </div>
  );
};

InfoItem.propTypes = {
  action: PropTypes.string,
  deal: PropTypes.object,
  onShowModalDeal: PropTypes.func,
  endDeal: PropTypes.func,
  deleteDeal: PropTypes.func,
  filteredClientAudit: PropTypes.array,
  type: PropTypes.string,
  actions: PropTypes.object,
  allocations: PropTypes.array,
  jiraRoles: PropTypes.array,
  jiraProjects: PropTypes.object,
  groupedDealsByProject: PropTypes.object,
  allocationsGroupsById: PropTypes.object,
  clientAudit: PropTypes.array,
  role: PropTypes.string,
};

export default InfoItem;
