import React from 'react';
import PropTypes from 'prop-types';
import { MailOutlined, UserOutlined } from '@ant-design/icons';

import { Loader } from 'components/Common/';
import { OwnerCommon } from '..';
import styles from './styles.scss';

const LeadSummary = ({
  type = 'horizontal',
  leadEmail,
  users,
  selectOwner,
  isLoading,
  owner_id = 'No owner',
}) => (
  <ul
    className={[styles[`lead-summary`], styles[`lead-summary_${type}`]].join(
      ' ',
    )}
  >
    <li key="mail" style={{ display: 'flex' }}>
      <MailOutlined className={styles.infoIcon} /> {leadEmail}
    </li>
    <li key="owner" style={{ display: 'flex', position: 'relative' }}>
      <UserOutlined className={styles.ownerIcon} />{' '}
      <span style={{ marginRight: '5px' }}>Owner:</span>
      <Loader loading={isLoading}>
        <OwnerCommon
          users={users}
          selectOwner={selectOwner}
          ownerId={owner_id}
        />
      </Loader>
    </li>
  </ul>
);

LeadSummary.propTypes = {
  type: PropTypes.oneOf(['vertical', 'horizontal']),
  leadEmail: PropTypes.string,
  isLoading: PropTypes.bool,
  users: PropTypes.array,
  owner_id: PropTypes.string,
  selectOwner: PropTypes.func,
};

export default LeadSummary;
