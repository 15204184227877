import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isClientsLoading: false,
  clients: [],
  totalClients: null,
  clientFilters: {},
  client: null,
  clientStatus: null,
  clientPaid: [],
  clientPending: [],
  clientAllocations: [],
  clientInvoices: [],
  clientAudit: [],
  clientContracts: [],
  clientNotes: [],
  clientNotesObject: {},
  clientOrganizations: [],
  clientProjects: [],
  clientDeals: [],
  responseStatus: null,

  isAllocationsStepLoading: false,
  isAllocationsStepDataLoaded: false,
  dealAllocations: {},
  summaryWorklogs: {},
  worklogs: {},
  projectActors: {},
  invoiceData: null,
  invoiceStatus: '',
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.LOAD_CLIENTS:
    case types.CREATE_CLIENT:
    case types.DELETE_CLIENT:
    case types.UPDATE_CLIENT:
    case types.GET_CLIENTS_FILTERS:
    case types.SAVE_CLIENT_NOTE:
    case types.UPDATE_CLIENT_NOTE:
    case types.DELETE_CLIENT_NOTE:
    case types.GET_CLIENT_ORGANIZATIONS:
    case types.GET_CLIENT_AUDIT:
    case types.GET_CLIENT_ALLOCATIONS:
    case types.GET_CLIENT_INVOICES:
    case types.GET_CLIENT_CONTRACTS:
    case types.GET_CLIENT_DEALS:
    case types.CREATE_CLIENT_DEAL:
    case types.UPDATE_CLIENT_DEAL:
    case types.DELETE_CLIENT_DEAL:
    case types.DELETE_CLIENT_INVOICE:
    case types.UPDATE_CLIENT_CONTRACT:
    case types.ADD_CLIENT_INVOICE:
    case types.UPDATE_CLIENT_INVOICE:
    case types.RESTORE_CLIENT:
    case types.GET_CLIENT_INVOICE:
      return state.set('isClientsLoading', true);
    case types.GET_CLIENT:
      return state
        .set('isClientsLoading', true)
        .set('client', null)
        .set('clientStatus', null);
    case types.GET_ALLOCATIONS_STEP_DATA:
      return state.set('isAllocationsStepLoading', true);
    case types.CLEAR_RESPONSE:
      return state
        .set('responseStatus', null)
        .set('invoiceData', null)
        .set('invoiceStatus', null)
        .set('isAllocationsStepDataLoaded', false)
        .set('worklogs', {})
        .set('summaryWorklogs', {})
        .set('dealAllocations', {})
        .set('projectActors', {})
        .set('clientStatus', null);

    /// SUCCESS
    case types.ADD_CLIENT_INVOICE_SUCCESS:
    case types.UPDATE_CLIENT_INVOICE_SUCCESS:
      return state
        .set('invoiceData', payload.data)
        .set('invoiceStatus', payload.status)
        .set('clientPaid', payload.data.client_totals_paid)
        .set('clientPending', payload.data.client_totals_pending)
        .set('isClientsLoading', false);
    case types.GET_CLIENT_SUCCESS:
      return state
        .set('isClientsLoading', false)
        .set('client', payload.client)
        .set('clientPaid', payload.client.paid)
        .set('clientPending', payload.client.pending)
        .set('clientProjects', payload.clientProjects)
        .set('clientOrganizations', payload.clientOrganizations)
        .set('clientNotes', payload.clientNotes)
        .set('clientNotesObject', payload.clientNotesObject)
        .set('clientStatus', null);
    case types.GET_CLIENT_PROJECTS_SUCCESS:
      return state
        .set('clientProjects', payload)
        .set('isClientsLoading', false);
    case types.SAVE_CLIENT_NOTE_SUCCESS:
    case types.UPDATE_CLIENT_NOTE_SUCCESS:
    case types.DELETE_CLIENT_NOTE_SUCCESS:
      return state
        .set('clientNotesObject', payload.clientNotesObject)
        .set('clientNotes', payload.clientNotes)
        .set('isClientsLoading', false);
    case types.CREATE_CLIENT_DEAL_SUCCESS:
      return state
        .set('clientDeals', payload.data)
        .set('isClientsLoading', false)
        .set('responseStatus', payload.status);
    case types.UPDATE_CLIENT_DEAL_SUCCESS:
      return state
        .set('clientDeals', payload.deals)
        .set('isClientsLoading', false)
        .set('responseStatus', payload.status);
    case types.DELETE_CLIENT_DEAL_SUCCESS:
    case types.GET_CLIENT_DEALS_SUCCESS:
      return state.set('clientDeals', payload).set('isClientsLoading', false);
    case types.CREATE_CLIENT_SUCCESS:
    case types.DELETE_CLIENT_SUCCESS:
    case types.RESTORE_CLIENT_SUCCESS:
      return state.set('isClientsLoading', false);
    case types.UPDATE_CLIENT_SUCCESS:
      return state
        .set('isClientsLoading', false)
        .set('client', payload.client)
        .set('clients', payload.clients);
    case types.GET_CLIENT_ORGANIZATIONS_SUCCESS:
      return state
        .set('isClientsLoading', false)
        .set('clientOrganizations', payload);
    case types.GET_CLIENT_AUDIT_SUCCESS:
      return state
        .set('isClientsLoading', false)
        .set('clientAudit', payload.sort((a, b) => b.id - a.id));
    case types.GET_CLIENT_ALLOCATIONS_SUCCESS:
      return state
        .set('isClientsLoading', false)
        .set('clientAllocations', payload);
    case types.GET_CLIENT_INVOICE_SUCCESS:
      return state.set('invoiceData', payload).set('isClientsLoading', false);
    case types.GET_CLIENT_INVOICES_SUCCESS:
      return state
        .set('isClientsLoading', false)
        .set('clientInvoices', payload);
    case types.DELETE_CLIENT_INVOICE_SUCCESS:
      return state
        .set('isClientsLoading', false)
        .set('clientPaid', payload.client_totals_paid)
        .set('clientPending', payload.client_totals_pending);
    case types.GET_CLIENT_CONTRACTS_SUCCESS:
      return state
        .set('isClientsLoading', false)
        .set('clientContracts', payload);
    case types.LOAD_CLIENTS_SUCCESS:
      return state
        .set('isClientsLoading', false)
        .set('clients', payload.data.data)
        .set('clientFilters', payload.data.data_for_filters)
        .set('totalClients', payload.headers['x-total-count']);
    case types.GET_ALLOCATIONS_STEP_DATA_SUCCESS:
      return state
        .set('isAllocationsStepLoading', false)
        .set('dealAllocations', payload.dealAllocations)
        .set('summaryWorklogs', payload.summaryWorklogs)
        .set('projectActors', payload.projectActors)
        .set('worklogs', payload.worklogs)
        .set('isAllocationsStepDataLoaded', true);

    /// FAILED
    case types.LOAD_CLIENTS_FAILED:
    case types.CREATE_CLIENT_FAILED:
    case types.DELETE_CLIENT_FAILED:
    case types.UPDATE_CLIENT_FAILED:
    case types.GET_CLIENTS_FILTERS_FAILED:
    case types.GET_CLIENT_ORGANIZATIONS_FAILED:
    case types.ADD_CLIENT_ORGANIZATION_FAILED:
    case types.GET_CLIENT_AUDIT_FAILED:
    case types.GET_CLIENT_ALLOCATIONS_FAILED:
    case types.GET_CLIENT_INVOICES_FAILED:
    case types.DELETE_CLIENT_INVOICE_FAILED:
    case types.GET_CLIENT_CONTRACTS_FAILED:
    case types.DELETE_CLIENT_DEAL_FAILED:
    case types.GET_CLIENT_DEALS_FAILED:
    case types.ADD_CLIENT_INVOICE_FAILED:
    case types.UPDATE_CLIENT_INVOICE_FAILED:
    case types.GET_CLIENT_INVOICE_FAILED:
    case types.RESTORE_CLIENT_FAILED:
      return state.set('isClientsLoading', false);
    case types.GET_CLIENT_FAILED:
      return state.set('isClientsLoading', false).set('clientStatus', payload);
    case types.CREATE_CLIENT_DEAL_FAILED:
    case types.UPDATE_CLIENT_DEAL_FAILED:
      return state
        .set('isClientsLoading', false)
        .set('responseStatus', payload);

    default:
      return state;
  }
}
