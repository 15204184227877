/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable indent */
import React, { useMemo, useState } from 'react';
import { Tabs, message, Form } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { PROFILES_PERMISSIONS } from 'containers/ProfilesPage/constants';
import EmployeeAttachments from 'components/ProfilesCommon/EmployeeAttachments';
import { Button } from 'components/Common';
import { PmDashboard } from 'components/ProfilesCommon';
import { hasRights } from 'utils/permissions';
import {
  addEmployeeAttachment,
  cancelAttachmentUpload,
  deleteEmployeeAttachment,
} from 'redux/Employees/actions';
import { LINKS } from 'config/routing';
import { createSummarySocialRequest } from 'redux/SummaryEmployees/actions';

import { SUMMARY_FIRED } from 'containers/EmployeesSummary/constants';
import { TEAM_ID } from 'utils/constants';
import ProfileExtraPayments from '../ProfileExtraPayments';
import ProfileRequests from '../ProfileRequests';

import styles from './styles.scss';

const ProfileMain = ({
  profile,
  id,
  role,
  salaries,
  comments,
  onAddComment,
  onCommentDelete,
  onSaveEditedComment,
  authUser,
  employees,
  users,
  extraPayments,
  currentEmployeeId,
  projects,
  isLoading,
  isAttachmentLoading,
  attachments,
  isSummary,
  isSummaryTab,
  className,
  isWard,
  isOwnProfile,
}) => {
  const [
    isExtraPaymentsModalVisible,
    setIsExtraPaymentsModalVisible,
  ] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [isAttachmentsModalVisible, setIsAttachmentsModalVisible] = useState(
    false,
  );
  const [isRequestModalVisible, setIsRequestModalVisible] = useState(false);
  const [tab, setTab] = useState('1');

  const [socialRequestForm] = Form.useForm();

  const dispatch = useDispatch();

  const onVisibleExtraPaymentsModal = () =>
    setIsExtraPaymentsModalVisible(true);
  const onHideExtraPaymentsModal = () => setIsExtraPaymentsModalVisible(false);

  const onVisibleRequestsModal = () => setIsRequestModalVisible(true);
  const onHideRequestsModal = () => {
    socialRequestForm.resetFields();
    setIsRequestModalVisible(false);
  };

  const showAttachmentModal = () => {
    setIsAttachmentsModalVisible(true);
  };

  const hideAttachmentsModal = () => {
    dispatch(cancelAttachmentUpload());
    setIsAttachmentsModalVisible(false);
    setAttachmentFile(null);
  };

  const onAttachmentFileChange = info => {
    const { file } = info;

    if (file.status === 'done') {
      setAttachmentFile(file.originFileObj);
    }
  };

  const onSuccessAttachmentUpload = fileName => {
    setIsAttachmentsModalVisible(false);
    setAttachmentFile(null);
    message.success(`${fileName} attachment uploaded successfully.`);
  };

  const onFailedAttachmentUpload = fileName => {
    message.error(`${fileName} attachment upload failed.`);
    attachmentFile(null);
  };

  const onRemoveAttachment = () => {
    setAttachmentFile(null);
  };

  const uploadAttachment = () => {
    dispatch(
      addEmployeeAttachment({
        employeeId: currentEmployeeId,
        file: attachmentFile,
        fileName: attachmentFile.name,
        onSuccessAttachmentUpload,
        onFailedAttachmentUpload,
      }),
    );
  };

  const onDeleteEmployeeAttachment = ({ name, url }) => {
    dispatch(
      deleteEmployeeAttachment({
        employee_id: currentEmployeeId,
        file_name: name,
        url,
      }),
    );
  };

  const attachmentsList = useMemo(
    () =>
      attachments
        ? attachments.map(({ url, file_name }) => ({
            name: file_name,
            status: 'done',
            url: url || '',
            uid: `attachment_${file_name}_${url}`,
          }))
        : [],
    [attachments],
  );

  const isDisplayUploadAttachment =
    hasRights(role, PROFILES_PERMISSIONS.addAttachment) && !isSummary;

  const isDisplayAddExtraPayment =
    (hasRights(role, PROFILES_PERMISSIONS.addExtraPayment) || isWard) &&
    !isSummary;

  const isDisplayRequests =
    (isOwnProfile || hasRights(role, PROFILES_PERMISSIONS.request) || isWard) &&
    isSummary &&
    isSummaryTab &&
    profile.team_id !== TEAM_ID.SUBCONTRACTORS;

  const renderExtraButton = () => {
    switch (true) {
      case tab === '1':
        return (
          isSummary &&
          !isSummaryTab && (
            <Button type="primary" className={styles.profileButton}>
              <Link to={LINKS.profile(currentEmployeeId)}>
                Go to profile page
              </Link>
            </Button>
          )
        );
      case tab === '2':
        return (
          isDisplayAddExtraPayment && (
            <Button type="primary" onClick={onVisibleExtraPaymentsModal}>
              Request extra payment
            </Button>
          )
        );
      case tab === '3':
        return (
          isDisplayUploadAttachment && (
            <Button type="primary" onClick={showAttachmentModal}>
              Upload attachment
            </Button>
          )
        );
      case tab === '4':
        return (
          isDisplayRequests &&
          profile?.status !== SUMMARY_FIRED && (
            <Button type="primary" onClick={onVisibleRequestsModal}>
              Make a request
            </Button>
          )
        );

      default:
        return null;
    }
  };

  const tabItems = [
    {
      label: 'Dashboard',
      key: '1',
      children: (
        <PmDashboard
          salaries={salaries}
          role={role}
          isWard={isWard}
          comments={comments}
          onAddComment={onAddComment}
          onCommentDelete={onCommentDelete}
          onSaveEditedComment={onSaveEditedComment}
          authUser={authUser}
          employees={employees}
          isLoading={isLoading}
          currentEmployeeId={currentEmployeeId}
          isSummary={isSummary}
          isOwnProfile={isOwnProfile}
        />
      ),
    },
    {
      label: 'Extra payments',
      key: '2',
      children: (
        <ProfileExtraPayments
          isLoading={isLoading}
          role={role}
          extraPayments={extraPayments}
          currentEmployeeId={currentEmployeeId}
          onHideRequestModal={onHideExtraPaymentsModal}
          users={users}
          projects={projects}
          employees={employees}
          isSummary={isSummary}
          isVisible={isExtraPaymentsModalVisible}
          isWard={isWard}
          user={authUser}
        />
      ),
    },
    {
      label: 'Attachments',
      key: '3',
      disabled: !hasRights(role, PROFILES_PERMISSIONS.attachments) && !isWard,
      children: (
        <EmployeeAttachments
          isAttachmentsModalVisible={isAttachmentsModalVisible}
          hideAttachmentsModal={hideAttachmentsModal}
          uploadAttachment={uploadAttachment}
          isAttachmentLoading={isAttachmentLoading}
          attachmentsList={attachmentsList}
          onAttachmentFileChange={onAttachmentFileChange}
          onRemoveAttachment={onRemoveAttachment}
          onDeleteEmployeeAttachment={onDeleteEmployeeAttachment}
          isLoading={isLoading}
          isSummary={isSummary}
          role={role}
        />
      ),
    },
    {
      label: 'Requests',
      key: '4',
      hidden: !isDisplayRequests,
      children: (
        <ProfileRequests
          isVisible={isRequestModalVisible}
          onHide={onHideRequestsModal}
          form={socialRequestForm}
          id={id}
          role={role}
          isWard={isWard}
          isOwnProfile={isOwnProfile}
          onHideRequestsModal={onHideRequestsModal}
        />
      ),
    },
  ].filter(tab => !tab.hidden);

  return (
    <div className={classNames([styles.profileMain, className])}>
      <Tabs
        activeKey={tab}
        onChange={setTab}
        className={styles.profileTabs}
        tabBarExtraContent={{
          right: renderExtraButton(),
        }}
        items={tabItems}
      />
    </div>
  );
};

ProfileMain.defaultProps = {
  isSummary: false,
  salaries: [],
  employees: [],
  comments: [],
  users: [],
  projects: [],
  extraPayments: [],
  attachments: [],
};

ProfileMain.propTypes = {
  profile: PropTypes.object,
  id: PropTypes.string,
  extraPayments: PropTypes.array,
  role: PropTypes.string,
  salaries: PropTypes.array,
  employees: PropTypes.array,
  comments: PropTypes.array,
  users: PropTypes.array,
  projects: PropTypes.array,
  currentEmployeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAddComment: PropTypes.func,
  onCommentDelete: PropTypes.func,
  onSaveEditedComment: PropTypes.func,
  authUser: PropTypes.object,
  isLoading: PropTypes.bool,
  isAttachmentLoading: PropTypes.bool.isRequired,
  attachments: PropTypes.array,
  isSummary: PropTypes.bool,
  isSummaryTab: PropTypes.bool,
  className: PropTypes.string,
  isWard: PropTypes.bool,
  isOwnProfile: PropTypes.bool,
};

export default ProfileMain;
