const arrayToTreeArray = ({ item, title, dataIndex, treeKey }) => {
  const parentKeys = Object.keys(item);

  return {
    title: `${title} ${dataIndex}`,
    key: `${dataIndex}_${treeKey}`,
    children: parentKeys.map((key, keyIndex) => {
      if (Array.isArray(item[key])) {
        return {
          title: key,
          key: `${key}__${keyIndex}__${dataIndex}__${treeKey}`,
          children: transformActivitiesData(
            item[key],
            key,
            `${treeKey}-${keyIndex}-${dataIndex}`,
          ),
        };
      }

      if (key === 'event' || key === 'pageview') {
        const eventKeys = Object.keys(item[key]);

        return {
          title: key,
          key: `${treeKey}-${keyIndex}-${dataIndex}__${key}`,
          children: eventKeys.map(eventKey => ({
            title: `${eventKey}: ${item[key][eventKey]}`,
          })),
        };
      }

      if (key === 'location') {
        const eventKeys = Object.keys(item[key]);

        return {
          title: key,
          key: `${treeKey}-${keyIndex}-${dataIndex}__${key}`,
          children: eventKeys.map(eventKey => {
            if (eventKey === 'languages') {
              return {
                title: 'languages',
                key: `${treeKey}-${keyIndex}-${dataIndex}__${key}__languages`,
                children: transformActivitiesData(
                  item[key]['languages'],
                  'language',
                  `${treeKey}-${keyIndex}-${dataIndex}__${key}__languages`,
                ),
              };
            }

            return {
              title: `${eventKey}: ${item[key][eventKey]}`,
            };
          }),
        };
      }

      if (key === 'goals') {
        return {
          title: key,
          key: `${treeKey}-${keyIndex}-${dataIndex}__${key}`,
          children: transformActivitiesData(
            item[key]['goals'],
            'goal',
            `${treeKey}-${keyIndex}-${dataIndex}__${key}__goals`,
          ),
        };
      }

      if (key === 'languages') {
        return {
          title: key,
          key: `${treeKey}-${keyIndex}-${dataIndex}__${key}`,
          children: transformActivitiesData(
            item[key]['languages'],
            'languages',
            `${treeKey}-${keyIndex}-${dataIndex}__${key}__languages`,
          ),
        };
      }

      return {
        title: `${key}: ${item[key]}`,
        key: `${key}__${keyIndex}__${dataIndex}__${treeKey}`,
      };
    }),
  };
};

export const transformActivitiesData = (
  data,
  title = 'Activity',
  treeKey = '0',
) => {
  if (Array.isArray(data)) {
    return data.map((item, dataIndex) =>
      arrayToTreeArray({ item, title, dataIndex, treeKey }),
    );
  }

  return data;
};
