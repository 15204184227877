import { takeLatest, call, put, all, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { findIndex } from 'lodash';

import {
  RM_EMPLOYEES_EXPAND,
  RM_PLAN_TYPES,
} from 'containers/ResourceManagementPage/constants';
import API from 'utils/api';
import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';

import { types } from './types';

function* getData({ payload: { start, end } }) {
  try {
    const {
      0: { data: plans },
      1: { data: plansByType },
    } = yield all([
      call(API.getPlans, start, end),
      call(API.getPlansByType, start, end),
    ]);
    const filteredPlans = plans.filter(
      item => item.planItem.type === RM_PLAN_TYPES.PROJECT,
    );
    yield put({
      type: types.GET_DATA_SUCCESS,
      payload: {
        plans: [...filteredPlans, ...plansByType],
      },
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_DATA_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* createPlan({ payload: { params } }) {
  try {
    const { data } = yield call(API.createPlan, params);
    yield put({ type: types.CREATE_PLAN_SUCCESS, payload: data });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_PLAN_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* updatePlanById({ payload: { plans, updatingPlanId, params } }) {
  try {
    const { data } = yield call(API.updatePlanById, updatingPlanId, params);
    const id = findIndex(
      plans,
      item => item.id === data.id && item.planItem.type === data.planItem.type,
    );
    const payload = [...plans.slice(0, id), data, ...plans.slice(id + 1)];
    yield put({ type: types.UPDATE_PLAN_BY_ID_SUCCESS, payload });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_PLAN_BY_ID_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* deletePlanById({ payload: { plans, id, type } }) {
  try {
    yield call(API.deletePlanById, id);
    const index = findIndex(
      plans,
      item => item.id === id && item.planItem.type === type,
    );
    const payload = [...plans.slice(0, index), ...plans.slice(index + 1)];
    yield put({ type: types.DELETE_PLAN_BY_ID_SUCCESS, payload });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_PLAN_BY_ID_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* createPlanByType({ payload: { params } }) {
  try {
    const { data } = yield call(API.createPlanByType, params);
    message.success(SAGA_MESSAGES.SUCCESS);
    yield put({ type: types.CREATE_PLAN_BY_TYPE_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_PLAN_BY_TYPE_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* updatePlanByType({ payload: { plans, updatingPlanId, params } }) {
  try {
    const { data } = yield call(API.updatePlanByType, updatingPlanId, params);
    const id = findIndex(
      plans,
      item => item.id === data.id && item.planItem.type === data.planItem.type,
    );
    const payload = [...plans.slice(0, id), data, ...plans.slice(id + 1)];
    yield put({ type: types.UPDATE_PLAN_BY_TYPE_SUCCESS, payload });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_PLAN_BY_TYPE_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* deletePlanByType({ payload: { plans, id, type } }) {
  try {
    yield call(API.deletePlanByType, id);
    const index = findIndex(
      plans,
      item => item.id === id && item.planItem.type === type,
    );
    const payload = [...plans.slice(0, index), ...plans.slice(index + 1)];
    yield put({ type: types.DELETE_PLAN_BY_TYPE_SUCCESS, payload });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_PLAN_BY_TYPE_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getRmEmployees({ payload: { from, to } }) {
  try {
    const { data } = yield call(API.getResourceManagementEmployees, {
      filters: { from, to },
      expand: RM_EMPLOYEES_EXPAND,
    });

    yield put({ type: types.GET_RM_EMPLOYEES_SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: types.GET_RM_EMPLOYEES_FAILED });
  }
}

function* getRmProjects() {
  try {
    const { data } = yield call(API.getResourceManagementProjects);

    yield put({ type: types.GET_RM_PROJECTS_SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: types.GET_RM_PROJECTS_FAILED });
  }
}

export default function* saga() {
  yield takeEvery(types.CREATE_PLAN, createPlan);
  yield takeEvery(types.UPDATE_PLAN_BY_ID, updatePlanById);
  yield takeLatest(types.DELETE_PLAN_BY_ID, deletePlanById);
  yield takeLatest(types.GET_DATA, getData);
  yield takeEvery(types.CREATE_PLAN_BY_TYPE, createPlanByType);
  yield takeEvery(types.UPDATE_PLAN_BY_TYPE, updatePlanByType);
  yield takeLatest(types.DELETE_PLAN_BY_TYPE, deletePlanByType);
  yield takeLatest(types.GET_RM_EMPLOYEES, getRmEmployees);
  yield takeLatest(types.GET_RM_PROJECTS, getRmProjects);
}
