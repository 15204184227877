/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React, { useRef, useState, useContext, useEffect } from 'react';
import { Form, Input, Result } from 'antd';
import { groupBy, flatten } from 'lodash';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'utils/timeConstants';
import { Loader } from 'components/Common/';
import ClientProject from 'components/ClientCommon/ClientModal/ClientProject';
import ClientSmartProject from 'components/ClientCommon/ClientModal/ClientSmartProject';
import ClientOverview from 'components/ClientCommon/ClientModal/ClientOverview';
import styles from './styles.scss';

const EditableContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      /* eslint-disable no-console */
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          addonAfter={title === 'Rate' ? 'per hour' : 'h/week'}
        />
      </Form.Item>
    ) : (
      <div className={styles.editableCellStyle} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export const getSteps = ({
  projects,
  form,
  jiraRoles,
  setProject,
  filteredDeals,
  project,
  allocations,
  currencies,
  allocationsData,
  setAllocationsData,
  isFinishEditDeal,
  setDeletedAllocations,
  deletedAllocations,
  dealValues,
  dealStartsAt,
  onChangeDealStartsAt,
  setIsDisableNextButton,
  isLoading,
}) => [
  {
    title: 'Project',
    content: (
      <ClientProject
        projects={projects}
        setProject={setProject}
        project={project}
        filteredDeals={filteredDeals}
      />
    ),
  },
  {
    title: 'Deal',
    content: (
      <ClientSmartProject
        dealFormValues={dealValues}
        form={form}
        jiraRoles={jiraRoles}
        filteredDeals={filteredDeals}
        project={project}
        allocations={allocations}
        currencies={currencies}
        allocationsData={allocationsData}
        setAllocationsData={setAllocationsData}
        isFinishEditDeal={isFinishEditDeal}
        setDeletedAllocations={setDeletedAllocations}
        dealStartsAt={dealStartsAt}
        onChangeDealStartsAt={onChangeDealStartsAt}
        setIsDisableNextButton={setIsDisableNextButton}
      />
    ),
  },
  {
    title: 'Overview',
    content: (
      <ClientOverview
        currencies={currencies}
        jiraRoles={jiraRoles}
        filteredDeals={filteredDeals}
        project={project}
        allocations={allocations}
        allocationsData={allocationsData}
        deletedAllocations={deletedAllocations}
        dealValues={dealValues}
        dealStartsAt={dealStartsAt}
        isLoading={isLoading}
      />
    ),
  },
  {
    title: 'Finish',
    content: (
      <Loader loading={isLoading}>
        <Result status="success" title="Success!" />
      </Loader>
    ),
  },
];

export const formatAllocationsData = (data, dealId, isUpdate = false) =>
  data.reduce((acc, curr) => {
    if (curr.jira_role_id) {
      if (curr.id && isUpdate) {
        return [
          ...acc,
          {
            deal_id: dealId,
            jira_role_id: curr.jira_role_id,
            rate: curr.rate || 0,
            hours_per_week: curr.hours_per_week || 0,
            currency_code: curr.currency_code,
            condition: curr.condition,
            id: curr.id,
          },
        ];
      }

      return [
        ...acc,
        {
          deal_id: dealId,
          jira_role_id: curr.jira_role_id,
          rate: curr.rate || 0,
          hours_per_week: curr.hours_per_week || 0,
          condition: curr.condition,
          currency_code: curr.currency_code,
        },
      ];
    }

    return acc;
  }, []);

export const filterDeals = ({ deals, id }) =>
  deals.filter(deal => Number(deal.client_id) === Number(id));

export const getDataWithValues = ({ jiraRoles, allocations }) => {
  const allocationsObj = groupBy(allocations, 'jira_role_id');

  return allocations && jiraRoles
    ? jiraRoles.map(item => {
        if (allocationsObj[item.id]) {
          return {
            ...item,
            rate: item.rate || allocationsObj[item.id][0].rate,
            hours_per_week:
              item.hours_per_week || allocationsObj[item.id][0].hours_per_week,
          };
        }

        return item;
      })
    : jiraRoles;
};

const removeEmpty = obj =>
  Object.entries(obj).reduce(
    (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
    {},
  );

export const formatDealValues = ({
  dealValues,
  id,
  dealStartsAt,
  isCreate = false,
  project = null,
}) => {
  let values;

  if (dealValues.fix_price) {
    values = removeEmpty({
      title: dealValues.title,
      description: dealValues.description,
      fix_price: dealValues.fix_price,
      currency_code: dealValues.currency_code,
    });
  } else {
    values = removeEmpty({
      title: dealValues.title,
      description: dealValues.description,
    });
  }

  if (isCreate) {
    return {
      client_id: id,
      starts_at: dayjs(dealStartsAt).format(DATE_FORMAT),
      project_id: project,
      ...values,
    };
  } else {
    return {
      client_id: id,
      starts_at: dayjs(dealStartsAt).format(DATE_FORMAT),
      ...values,
    };
  }
};

export const getStartAndEndOfMonth = month => {
  const dateFrom = dayjs(month)
    .clone()
    .startOf('month')
    .format(DATE_FORMAT);
  const dateTo = dayjs(month)
    .clone()
    .endOf('month')
    .format(DATE_FORMAT);

  return [dateFrom, dateTo];
};

export const getFormattedAudit = ({
  clientDeals,
  clientInvoices,
  clientNotes,
  clientAudit,
  clientAllocations,
}) => {
  const dealAudits = clientDeals.map(deal => deal.audits.map(audit => audit));
  const invoiceAudits = clientInvoices.map(invoice => invoice.audits);
  const noteAudits = clientNotes.map(note => note.audits);
  const allocationAudit = clientAllocations.map(all => all.audits);

  const fullAudit = flatten([
    ...clientAudit,
    ...dealAudits,
    ...invoiceAudits,
    ...noteAudits,
    ...allocationAudit,
  ]).sort((a, b) => b.created_at.localeCompare(a.created_at));

  return fullAudit;
};
