export const SALARY_CHART_COLORS = [
  '#DC2626',
  '#EA580C',
  '#CA8A04',
  '#65A30D',
  '#16A34A',
  '#0D9488',
  '#0284C7',
  '#4F46E5',
  '#7C3AED',
  '#9333EA',
  '#C026D3',
  '#DB2777',
  '#F87171',
  '#FB923C',
  '#FACC15',
  '#A3E635',
  '#4ADE80',
  '#2DD4BF',
  '#1890FF',
  '#818CF8',
  '#A78BFA',
  '#C084FC',
  '#E879F9',
  '#F472B6',
];
