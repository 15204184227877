exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".item-fa116{margin-bottom:12px;padding:16px 20px;border:1px solid #d9d9d9;border-radius:8px}.item-fa116 .title-b3a27{display:flex;align-items:center;margin-bottom:8px;font-weight:500}.item-fa116 .title-b3a27 .info-6b592{width:16px;height:16px;margin-left:6px}.item-fa116 .value-ac6f4{color:#595959}.label-67a1e{display:inline-block;margin-bottom:6px;font-size:16px;font-weight:600}", ""]);

// Exports
exports.locals = {
	"item": "item-fa116",
	"title": "title-b3a27",
	"info": "info-6b592",
	"value": "value-ac6f4",
	"label": "label-67a1e"
};