exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".actionType-a056d{text-transform:lowercase}.attachmentsContainer-00658{display:flex}.content-ecc8c{padding:12px 20px;border:1px solid #d9d9d9;border-radius:8px}.content-ecc8c .amount-627c0{margin-bottom:6px;font-size:20px;line-height:28px;font-weight:600}.content-ecc8c .source-ff5a4{font-size:14px;line-height:22px;color:#8c8c8c}.content-ecc8c .source-ff5a4 .created-766d7{margin-right:12px;padding-right:12px;border-right:1px solid #d9d9d9}.content-ecc8c .attachments-8a2f2{margin-top:12px;padding-top:4px;border-top:1px solid #d9d9d9}.content-ecc8c .attachments-8a2f2 .ant-upload-list-item-name{font-size:14px}.invoiceInfo-71bc5{display:flex;align-items:center;justify-content:space-between;margin-bottom:12px}.invoiceInfo-71bc5 .infoLeft-ac6d0,.invoiceInfo-71bc5 .infoRight-44709{display:flex;align-items:center}.invoiceInfo-71bc5 .invoiceMenu-6b608{cursor:pointer}.invoiceInfo-71bc5 .invoicePeriod-e6e6a{font-size:14px;color:#8c8c8c}.invoiceInfo-71bc5 .number-98ac3{display:inline-block;margin-right:12px}.invoiceInfo-71bc5 .statusEdit-c58cb{width:9px;height:6px}", ""]);

// Exports
exports.locals = {
	"actionType": "actionType-a056d",
	"attachmentsContainer": "attachmentsContainer-00658",
	"content": "content-ecc8c",
	"amount": "amount-627c0",
	"source": "source-ff5a4",
	"created": "created-766d7",
	"attachments": "attachments-8a2f2",
	"invoiceInfo": "invoiceInfo-71bc5",
	"infoLeft": "infoLeft-ac6d0",
	"infoRight": "infoRight-44709",
	"invoiceMenu": "invoiceMenu-6b608",
	"invoicePeriod": "invoicePeriod-e6e6a",
	"number": "number-98ac3",
	"statusEdit": "statusEdit-c58cb"
};