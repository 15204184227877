import { takeLatest, call, put } from 'redux-saga/effects';
import { SAGA_MESSAGES } from 'redux/sagaMessages';

import API from 'utils/api';
import { sagaErrorHandler } from 'utils/common';

import { types } from './types';

function* getTeams() {
  try {
    const { data } = yield call(API.getEmployeeTeams);

    yield put({ type: types.GET_TEAMS_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_TEAMS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_TEAMS, getTeams);
}
