import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from 'containers/Login/Login';
import AuthRoute from 'components/AuthRoute/AuthRoute';
import NotFound from 'components/NotFoundCommon';
import PaysheetTable from 'containers/PaysheetTable/PaysheetTable';
import EmployeesProfiles from 'containers/EmployeesProfiles/EmployeesProfiles';
import AverageSalariesPage from 'containers/AverageSalariesPage/AverageSalariesPage';
import ExchangeRatesPage from 'containers/ExchangeRatesPage';
import TechnologiesPage from 'containers/TechnologiesPage/TechnologiesPage';
import PositionsPage from 'containers/PositionsPage/PositionsPage';
import CalendarPage from 'containers/CalendarPage/CalendarPage';
import UserPage from 'containers/User/UserPage/UserPage';
import UsersList from 'containers/Users/UsersList';
import OrganizationsList from 'containers/Organizations/OrganizationsList';
import ExtraPaymentsList from 'containers/ExtraPayments/ExtraPayments';
import ResourceManagementPage from 'containers/ResourceManagementPage/ResourceManagementPage';
import ProfilesPage from 'containers/ProfilesPage/ProfilesPage';
import ProjectProfits from 'containers/ProjectProfits/ProjectProfits';
import AuditPage from 'containers/AuditPage/AuditPage';
import ReportSubcontractorsPage from 'containers/ReportSubcontractorsPage/ReportSubcontractorsPage';
import ClientsPage from 'containers/ClientsPage/ClientsPage';
import Client from 'containers/ClientPage/ClientPage';
import Lead from 'containers/LeadPage/Lead';
import LeadsPage from 'containers/LeadsPage/LeadsPage';
import ClientContractsPage from 'containers/ClientContractsPage/ClientContractsPage';
import InvoicesPage from 'containers/InvoicesPage/InvoicesPage';
import DashboardOverviewPage from 'containers/DashboardOverviewPage/DashboardOverviewPage';
import DashboardEffectivenessPage from 'containers/DashboardEffectivenessPage/DashboardEffectivenessPage';
import DashboardEmployeeOverview from 'containers/DashboardEmployeeOverview/DashboardEmployeeOverview';
import DashboardSalaryPage from 'containers/DashboardSalaryPage/DashboardSalaryPage';
import DashboardSubcontractors from 'containers/DashboardSubcontractors/DashboardSubcontractorsPage';
import InvoiceTypesPage from 'containers/InvoiceTypesPage/InvoiceTypesPage';
import IncomingInvoicesPage from 'containers/IncomingInvoicesPage/IncomingInvoicesPage';
import BanksPage from 'containers/BanksPage/BanksPage';
import CurrenciesPage from 'containers/CurrenciesPage/CurrenciesPage';
import EmployeesSummary from 'containers/EmployeesSummary/EmployeesSummary';
import SummaryPage from 'containers/SummaryPage/SummaryPage';
import RolesPage from 'containers/Roles/RolesPage';
import SocialRequestsPage from 'containers/SocialRequestsPage/SocialRequestsPage';
import { ROUTING } from 'config/routing';

const Routes = () => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to={ROUTING.LOGIN.path} />} />
    <Route exact path={ROUTING.LOGIN.path} component={Login} />
    <AuthRoute
      exact
      path={ROUTING.AVERAGE_SALARY.path}
      scopes={ROUTING.AVERAGE_SALARY.scopes}
      component={AverageSalariesPage}
    />
    <AuthRoute
      exact
      path={ROUTING.PAYSHEET_TABLE.path}
      scopes={ROUTING.PAYSHEET_TABLE.scopes}
      component={PaysheetTable}
    />
    <AuthRoute
      exact
      path={ROUTING.EMPLOYEES_PROFILES.path}
      scopes={ROUTING.EMPLOYEES_PROFILES.scopes}
      component={EmployeesProfiles}
    />
    <AuthRoute
      exact
      path={ROUTING.EMPLOYEES_SUMMARY.path}
      scopes={ROUTING.EMPLOYEES_SUMMARY.scopes}
      component={EmployeesSummary}
    />
    <AuthRoute
      path={ROUTING.PROFILE.path}
      scopes={ROUTING.PROFILE.scopes}
      component={ProfilesPage}
    />
    <AuthRoute
      path={ROUTING.SUMMARY.path}
      scopes={ROUTING.SUMMARY.scopes}
      component={SummaryPage}
    />
    <AuthRoute
      exact
      path={ROUTING.ORGANIZATION.path}
      scopes={ROUTING.ORGANIZATION.scopes}
      component={OrganizationsList}
    />
    <AuthRoute
      exact
      path={ROUTING.EXTRA_PAYMENTS.path}
      scopes={ROUTING.EXTRA_PAYMENTS.scopes}
      component={ExtraPaymentsList}
    />
    <AuthRoute
      exact
      path={ROUTING.EXCHANGE_RATES.path}
      scopes={ROUTING.EXCHANGE_RATES.scopes}
      component={ExchangeRatesPage}
    />
    <AuthRoute
      exact
      path={ROUTING.TECHNOLOGIES.path}
      scopes={ROUTING.TECHNOLOGIES.scopes}
      component={TechnologiesPage}
    />
    <AuthRoute
      exact
      path={ROUTING.EMPLOYEE_POSITION.path}
      scopes={ROUTING.EMPLOYEE_POSITION.scopes}
      component={PositionsPage}
    />
    <AuthRoute
      exact
      path={ROUTING.EMPLOYEE.path}
      scopes={ROUTING.EMPLOYEE.scopes}
      component={UserPage}
    />
    <AuthRoute
      exact
      path={ROUTING.CALENDAR.path}
      scopes={ROUTING.CALENDAR.scopes}
      component={CalendarPage}
    />
    <AuthRoute
      path={ROUTING.RESOURCE_MANAGEMENT.path}
      scopes={ROUTING.RESOURCE_MANAGEMENT.scopes}
      component={ResourceManagementPage}
    />
    <AuthRoute
      path={ROUTING.PROJECT_PROFITS.path}
      scopes={ROUTING.PROJECT_PROFITS.scopes}
      component={ProjectProfits}
    />
    <AuthRoute
      path={ROUTING.AUDIT.path}
      scopes={ROUTING.AUDIT.scopes}
      component={AuditPage}
    />
    <AuthRoute
      path={ROUTING.SUBCONTRACTORS.path}
      scopes={ROUTING.SUBCONTRACTORS.scopes}
      component={ReportSubcontractorsPage}
    />
    <AuthRoute
      exact
      path={ROUTING.CLIENTS.path}
      scopes={ROUTING.CLIENTS.scopes}
      component={ClientsPage}
    />
    <AuthRoute
      exact
      path={ROUTING.CLIENT.path}
      scopes={ROUTING.CLIENT.scopes}
      component={Client}
    />
    <AuthRoute
      exact
      path={ROUTING.LEADS.path}
      scopes={ROUTING.LEADS.scopes}
      component={LeadsPage}
    />
    <AuthRoute
      exact
      path={ROUTING.LEAD.path}
      scopes={ROUTING.LEAD.scopes}
      component={Lead}
    />
    <AuthRoute
      exact
      path={ROUTING.INVOICES.path}
      scopes={ROUTING.INVOICES.scopes}
      component={InvoicesPage}
    />
    <AuthRoute
      exact
      path={ROUTING.CLIENTS_CONTRACTS.path}
      scopes={ROUTING.CLIENTS_CONTRACTS.scopes}
      component={ClientContractsPage}
    />
    <AuthRoute
      exact
      path={ROUTING.USERS.path}
      scopes={ROUTING.USERS.scopes}
      component={UsersList}
    />
    <AuthRoute
      exact
      path={ROUTING.DASHBOARD_OVERVIEW.path}
      scopes={ROUTING.DASHBOARD_OVERVIEW.scopes}
      component={DashboardOverviewPage}
    />
    <AuthRoute
      exact
      path={ROUTING.DASHBOARD_EFFECTIVENESS.path}
      scopes={ROUTING.DASHBOARD_EFFECTIVENESS.scopes}
      component={DashboardEffectivenessPage}
    />
    <AuthRoute
      exact
      path={ROUTING.DASHBOARD_EMPLOYEE_OVERVIEW.path}
      scopes={ROUTING.DASHBOARD_EMPLOYEE_OVERVIEW.scopes}
      component={DashboardEmployeeOverview}
    />
    <AuthRoute
      path={ROUTING.DASHBOARD_SALARY.path}
      scopes={ROUTING.DASHBOARD_SALARY.scopes}
      component={DashboardSalaryPage}
    />
    <AuthRoute
      path={ROUTING.DASHBOARD_SUBCONTRACTORS.path}
      scopes={ROUTING.DASHBOARD_SUBCONTRACTORS.scopes}
      component={DashboardSubcontractors}
    />
    <AuthRoute
      path={ROUTING.INVOICE_TYPES.path}
      scopes={ROUTING.INVOICE_TYPES.scopes}
      component={InvoiceTypesPage}
    />
    <AuthRoute
      path={ROUTING.INCOMING_INVOICES.path}
      scopes={ROUTING.INCOMING_INVOICES.scopes}
      component={IncomingInvoicesPage}
    />
    <AuthRoute
      path={ROUTING.BANKS.path}
      scopes={ROUTING.BANKS.scopes}
      component={BanksPage}
    />
    <AuthRoute
      path={ROUTING.CURRENCIES.path}
      scopes={ROUTING.CURRENCIES.scopes}
      component={CurrenciesPage}
    />
    <AuthRoute
      path={ROUTING.ROLES.path}
      scopes={ROUTING.ROLES.scopes}
      component={RolesPage}
    />
    <AuthRoute
      path={ROUTING.SOCIAL_REQUESTS.path}
      scopes={ROUTING.SOCIAL_REQUESTS.scopes}
      component={SocialRequestsPage}
    />
    <AuthRoute exact path={{ path: '*', scopes: null }} component={NotFound} />
  </Switch>
);

export default Routes;
