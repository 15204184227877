import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isRmLoading: false,
  plans: [],
  data: [],
  dealAllocations: [],
  rmEmployees: [],
  projects: [],
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_TABLE_DATA:
      return state.set('data', payload);
    case types.CREATE_PLAN:
    case types.UPDATE_PLAN_BY_ID:
    case types.DELETE_PLAN_BY_ID:
    case types.GET_DATA:
    case types.CREATE_PLAN_BY_TYPE:
    case types.UPDATE_PLAN_BY_TYPE:
    case types.DELETE_PLAN_BY_TYPE:
    case types.GET_RM_EMPLOYEES:
    case types.GET_RM_PROJECTS:
      return state.set('isRmLoading', true);

    case types.CREATE_PLAN_SUCCESS:
      return state
        .set('isRmLoading', false)
        .set('plans', [...state.get('plans'), payload]);
    case types.UPDATE_PLAN_BY_ID_SUCCESS:
    case types.DELETE_PLAN_BY_ID_SUCCESS:
    case types.UPDATE_PLAN_BY_TYPE_SUCCESS:
    case types.DELETE_PLAN_BY_TYPE_SUCCESS:
      return state.set('isRmLoading', false).set('plans', payload);
    case types.GET_DATA_SUCCESS:
      return state.set('plans', payload.plans).set('isRmLoading', false);
    case types.CREATE_PLAN_BY_TYPE_SUCCESS:
      return state
        .set('isRmLoading', false)
        .set('plans', [...state.get('plans'), payload]);
    case types.GET_RM_EMPLOYEES_SUCCESS:
      return state.set('rmEmployees', payload).set('isRmLoading', false);
    case types.GET_RM_PROJECTS_SUCCESS:
      return state.set('projects', payload).set('isRmLoading', false);

    case types.CREATE_PLAN_FAILED:
    case types.UPDATE_PLAN_BY_ID_FAILED:
    case types.DELETE_PLAN_BY_ID_FAILED:
    case types.GET_DATA_FAILED:
    case types.CREATE_PLAN_BY_TYPE_FAILED:
    case types.UPDATE_PLAN_BY_TYPE_FAILED:
    case types.DELETE_PLAN_BY_TYPE_FAILED:
    case types.GET_RM_EMPLOYEES_FAILED:
    case types.GET_RM_PROJECTS_FAILED:
      return state.set('isRmLoading', false);

    default:
      return state;
  }
}
