import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useDispatch } from 'react-redux';
import setupIndexedDB from 'use-indexeddb';

import HttpsRedirect from 'utils/httpsRedirect';
import { getUser } from 'redux/Auth/actions';

import { DBConfig } from 'config/indexedDB';
import Routes from '../../routes';
import GlobalStyle from '../../global-styles';
import styles from './styles.scss';

setupIndexedDB(DBConfig);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <HttpsRedirect>
      <Layout className={styles.layout} hasSider>
        <Routes />
        <GlobalStyle />
      </Layout>
    </HttpsRedirect>
  );
}

export default App;
