exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".clientConcentration-8ef90{padding:24px;border-radius:12px;background-color:#fff;box-shadow:0 4px 20px 0 rgba(112,112,128,.1);padding-left:0;padding-right:0}.clientConcentration-8ef90 .clientConcentrationHeader-1f200{margin-bottom:24px;padding:0 24px 20px;border-bottom:1px solid #d9d9d9}.clientConcentration-8ef90 .clientConcentrationHeader-1f200 .title-e526e{display:flex;align-items:center;margin-bottom:0}.clientConcentration-8ef90 .clientConcentrationHeader-1f200 .title-e526e .info-4aea2{margin-left:14px}.clientConcentration-8ef90 .clientConcentrationHeader-1f200 .title-e526e .info-4aea2 svg{width:21px}.clientConcentration-8ef90 .clientConcentrationContainer-676a4{display:flex;justify-content:space-between;padding:0 24px}.clientConcentration-8ef90 .table-dc676{width:35%}.clientConcentration-8ef90 .charts-3fd41{display:flex;justify-content:space-between;width:60%}.clientConcentration-8ef90 .chartsItem-4a903{display:flex;flex-direction:column;align-items:center;width:50%}", ""]);

// Exports
exports.locals = {
	"clientConcentration": "clientConcentration-8ef90",
	"clientConcentrationHeader": "clientConcentrationHeader-1f200",
	"title": "title-e526e",
	"info": "info-4aea2",
	"clientConcentrationContainer": "clientConcentrationContainer-676a4",
	"table": "table-dc676",
	"charts": "charts-3fd41",
	"chartsItem": "chartsItem-4a903"
};