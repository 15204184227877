import React from 'react';
import { Spin } from 'antd';

import styles from './styles.scss';

const PageLoader = () => (
  <div className={styles.pageLoader}>
    <Spin size="large" />
  </div>
);

export default PageLoader;
