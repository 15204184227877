import React from 'react';
import PropTypes from 'prop-types';

import LeadOwner from '../LeadOwner';
import LeadCommon from '../LeadCommon';
import LeadNote from '../../LeadNote';
import LeadStatus from '../LeadStatus';
import LeadScore from '../LeadScore';

const LeadTimelineItem = ({
  event,
  updateLeadNote,
  leadAttachments,
  usersObject,
  role,
  notesObject,
}) => {
  const { auditable_type } = event;

  switch (auditable_type) {
    case 'LeadNote':
      return (
        <LeadNote
          event={event}
          updateLeadNote={updateLeadNote}
          role={role}
          notesObject={notesObject}
        />
      );
    case 'Lead':
      return <LeadCommon event={event} leadAttachments={leadAttachments} />;
    case 'LeadOwner':
      return <LeadOwner event={event} usersObject={usersObject} />;
    case 'LeadStatus':
      return <LeadStatus event={event} />;
    case 'LeadScore':
      return <LeadScore event={event} />;

    default:
      return <div>{auditable_type} updated</div>;
  }
};

LeadTimelineItem.propTypes = {
  event: PropTypes.array,
  leadAttachments: PropTypes.array,
  updateLeadNote: PropTypes.func,
  usersObject: PropTypes.object,
  notesObject: PropTypes.object,
  role: PropTypes.string,
};

export default LeadTimelineItem;
