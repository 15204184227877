export const Y_M_D_H_M_ZONE = 'YYYY-MM-DD HH:mm Z';
export const Y_M_01 = 'YYYY/MM/01';
export const D_M_Y = 'MM/DD/YYYY';
export const Y_M_D = 'YYYY/MM/DD';
export const Y_M_D_H_M = 'YYYY-MM-DD HH-mm';
export const HH_mm = 'HH:mm';
export const HH_mm_zz = 'HH:mm Z';

export const DATE_MONTH_FORMAT = 'DD MMM YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const MONTH_FORMAT = 'MMM YYYY';

export const FULL_DATE_FORMAT = 'DD MMM YYYY HH:mm';
export const BEGIN_MONTH_FORMAT = 'YYYY-MM-01';
export const MONTH_NAME_FORMAT = 'MMMM YYYY';
export const MONTH_SHORTEN_NAME_FORMAT = 'MMM YY';
export const INVALID_DATE = 'Invalid date';
export const EXTRA_PAYMENT_FORMAT = 'MMMM YYYY';
