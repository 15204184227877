import { SORT_DIRECTIONS } from 'utils/constants';

export const INCOMING_INVOICE_MESSAGES = {
  GET_INCOMING_INVOICES_FAILED: 'Failed to get incoming invoices',
  CREATE_INCOMING_INVOICE_FAILED: 'Failed to create incoming invoice',
  CREATE_INCOMING_INVOICE_SUCCESS: 'Incoming invoice created successfully',
  UPDATE_INCOMING_INVOICE_FAILED: 'Failed to update incoming invoice',
  UPDATE_INCOMING_INVOICE_SUCCESS: 'Incoming invoice updated successfully',
  DELETE_INCOMING_INVOICE_SUCCESS: 'Incoming invoice deleted successfully',
  DELETE_INCOMING_INVOICE_FAILED: 'Failed to delete incoming invoice',
  APPROVE_INCOMING_INVOICE_FINANCIER_SUCCESS:
    'Invoice successfully approved by financier',
  APPROVE_INCOMING_INVOICE_FINANCIER_FAILED:
    'Failed to approve invoice by financier',
  DECLINE_INCOMING_INVOICE_FINANCIER_SUCCESS:
    'Invoice successfully declined by financier',
  DECLINE_INCOMING_INVOICE_FINANCIER_FAILED:
    'Failed to decline invoice by financier',
  APPROVE_INCOMING_INVOICE_APPROVER_SUCCESS:
    'Invoice successfully approved by approver',
  APPROVE_INCOMING_INVOICE_APPROVER_FAILED:
    'Failed to approve invoice by approver',
  DECLINE_INCOMING_INVOICE_APPROVER_SUCCESS:
    'Invoice successfully declined by approver',
  DECLINE_INCOMING_INVOICE_APPROVER_FAILED:
    'Failed to decline invoice by approver',
  SAVE_INCOMING_INVOICE_ATTACHMENTS_FAILED:
    'Failed to upload incoming invoice attachment',
};

export const INCOMING_INVOICES_TABLE_COMMON = {
  invoice_number_title: '№',
  invoice_number: 'invoice_number',
  period_title: 'Period',
  period: 'period',
  organization_id_title: 'Company',
  organization_id: 'organization_id',
  project_name_title: 'Project',
  project_name: 'project_name',
  employee_id_title: 'Employee',
  employee_id: 'employee_id',
  bank_id_title: 'Bank',
  bank_id: 'bank_id',
  attachments_title: 'Attachments',
  attachments: 'attachments',
  cost_title: 'Cost',
  cost: 'cost',
  currency_id_title: 'CCY',
  currency_id: 'currency_id',
  comments_title: 'Comments',
  comments: 'comments',
  author_id_title: 'Author',
  author_id: 'author_id',
  approver_id_title: 'Approver',
  approver_id: 'approver_id',
  is_approver_approve: 'is_approver_approve',
  approver_approve: 'approver_approve',
  financier: 'Financier',
  is_financier_approve: 'is_financier_approve',
  status_title: 'Status',
  status: 'status',
  invoice_type_title: 'Type',
  invoice_type: 'invoice_type',
  type_id: 'type_id',
  actions_title: 'Actions',
  actions: 'actions',
  company_ids: 'company_ids',
  employee_ids: 'employee_ids',
  bank_ids: 'bank_ids',
  currency_ids: 'currency_ids',
  approver_ids: 'approver_ids',
  invoice_type_ids: 'invoice_type_ids',
  ready_for_check_title: 'Ready for check',
  ready_for_check: 'ready_for_check',
};

export const APPROVE_FILTERS = [
  { text: 'Approved', value: true },
  { text: 'Not approved', value: false },
];

export const EMPLOYEES_REQ_PARAMS = {
  sort: `full_name:${SORT_DIRECTIONS.ascend}`,
  size: 9999,
};
export const USERS_REQ_PARAMS = { sort: `name:${SORT_DIRECTIONS.ascend}` };
