exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".header-12a53{display:flex;justify-content:space-between;margin-bottom:15px}.inputNubmer-1959b{width:auto;display:inline-block;text-align:right}.controlsRow-d2aec{display:flex;flex-wrap:wrap;align-items:center}.controlsRow-d2aec .controlsLabel-cd8ea{margin-right:10px}", ""]);

// Exports
exports.locals = {
	"header": "header-12a53",
	"inputNubmer": "inputNubmer-1959b",
	"controlsRow": "controlsRow-d2aec",
	"controlsLabel": "controlsLabel-cd8ea"
};