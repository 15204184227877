import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'antd';
import { get } from 'lodash';
import dayjs from 'dayjs';
import {
  clientsSelector,
  currenciesSelector,
  invoicesSelector,
  organizationsSelector,
} from 'redux/selectors';

import { getInvoiceOrganizationAccount } from 'redux/Organizations/actions';
import { Loader } from 'components/Common';
import { apiUrl } from 'utils/api';
import { mapArrayToEntities } from 'utils/common';

import ClientInvoiceDetailsForm from './ClientInvoiceDetailsForm';

import styles from './styles.scss';

const ClientInvoiceDetails = ({
  isFullScreen,
  detailsForm,
  setIsInvoiceValuesTouched,
}) => {
  const [formHeight, setFormHeight] = useState(window.innerHeight / 12);

  const { clientContracts, isClientsLoading, invoiceData } = useSelector(
    clientsSelector,
  );

  const { invoiceTemplates } = useSelector(invoicesSelector);

  const { currencies } = useSelector(currenciesSelector);

  const { organizations, invoiceAccounts } = useSelector(organizationsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    const setFormHeightState = () => {
      setFormHeight(window.innerHeight / 12);
    };

    window.addEventListener('resize', setFormHeightState);

    return () => window.removeEventListener('resize', setFormHeightState);
  }, []);

  const setIsDetailsTouched = () => {
    setIsInvoiceValuesTouched(prev => ({
      ...prev,
      details: true,
    }));
  };

  const dispatchOrganizationAccounts = (organization, field) => {
    dispatch(
      getInvoiceOrganizationAccount({
        organization,
        field,
      }),
    );
  };

  const handleChangeConractor = organizationId => {
    dispatchOrganizationAccounts(organizationId, 'contractor');
    detailsForm.setFieldsValue({ contractor_account_id: null });
  };

  const handleChangeCustomer = organizationId => {
    dispatchOrganizationAccounts(organizationId, 'customer');
    detailsForm.setFieldsValue({ customer_account_id: null });
  };

  const onFormValuesChange = changedValues => {
    setIsDetailsTouched();

    if (changedValues.hasOwnProperty('contractor_id')) {
      handleChangeConractor(changedValues.contractor_id);

      return;
    }

    if (changedValues.hasOwnProperty('customer_id')) {
      handleChangeCustomer(changedValues.customer_id);

      return;
    }
  };

  const templateGroupById = useMemo(
    () => mapArrayToEntities(invoiceTemplates).entities,
    [invoiceTemplates],
  );

  useEffect(() => {
    if (invoiceData?.contractor_account_id) {
      dispatchOrganizationAccounts(invoiceData.contractor_id, 'contractor');
    }

    if (invoiceData?.customer_account_id) {
      dispatchOrganizationAccounts(invoiceData.customer_id, 'customer');
    }
  }, [invoiceData?.contractor_id, invoiceData?.customer_id]);

  useEffect(() => {
    if (invoiceData) {
      detailsForm.setFieldsValue({
        currency_code: invoiceData.currency_code || 'USD',
        invoice_template_id: invoiceData.invoice_template_id || 1,
        issue_date: dayjs(invoiceData.issue_date).isValid()
          ? dayjs(invoiceData.issue_date)
          : null,
        due_date: dayjs(invoiceData.due_date).isValid()
          ? dayjs(invoiceData.due_date)
          : null,
        contractor_id: invoiceData.contractor_id,
        contractor_account_id: invoiceData.contractor_account_id,
        customer_id: invoiceData.customer_id,
        customer_account_id: invoiceData.customer_account_id,
        contract_id: invoiceData.contract_id,
      });
    }
  }, [invoiceData]);

  useEffect(() => {
    if (clientContracts.length) {
      const firstContract = clientContracts[0];

      if (!detailsForm.getFieldValue('contract_id')) {
        detailsForm.setFieldsValue({ contract_id: firstContract.id });
      }

      if (!detailsForm.getFieldValue('contractor_id')) {
        detailsForm.setFieldsValue({
          contractor_id: firstContract.contractor_id,
        });
        dispatchOrganizationAccounts(firstContract.contractor_id, 'contractor');
      }

      if (!detailsForm.getFieldValue('customer_id')) {
        detailsForm.setFieldsValue({ customer_id: firstContract.customer_id });
        dispatchOrganizationAccounts(firstContract.customer_id, 'customer');
      }
    }
  }, [clientContracts]);

  const url = apiUrl.replace('/api/v1', '');

  return (
    <div
      className={
        isFullScreen
          ? styles.invoiceIsFullScreen
          : styles.invoiceIsNotFullScreen
      }
    >
      <Loader loading={isClientsLoading}>
        <div className={styles.invoiceDeatailsContainer}>
          <ClientInvoiceDetailsForm
            detailsForm={detailsForm}
            invoiceTemplates={invoiceTemplates}
            currencies={currencies}
            contracts={clientContracts}
            organizations={organizations}
            contractorAccounts={invoiceAccounts.contractor}
            customerAccounts={invoiceAccounts.customer}
            isAccountsLoading={invoiceAccounts.isAccountsLoading}
            onFormValuesChange={onFormValuesChange}
            formHeight={formHeight}
          />
          <div>
            {detailsForm.getFieldValue('invoice_template_id') && (
              <Image
                width={400}
                src={`${url}${get(
                  templateGroupById[
                    detailsForm.getFieldValue('invoice_template_id')
                  ],
                  'preview_url',
                  '',
                )}`}
              />
            )}
          </div>
        </div>
      </Loader>
    </div>
  );
};

ClientInvoiceDetails.propTypes = {
  isFullScreen: PropTypes.bool.isRequired,
  setIsInvoiceValuesTouched: PropTypes.func.isRequired,
  detailsForm: PropTypes.object.isRequired,
};

export default ClientInvoiceDetails;
