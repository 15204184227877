import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';
import isEmpty from 'lodash/isEmpty';

import CustomLegend from 'components/Common/Recharts/Legend';
import TooltipContentPie from 'components/Common/Recharts/TooltipContentPie';
import { formatNumberWithCommas } from 'utils/helpers';

import { getClientTotal } from './utils';

import styles from './styles.scss';

const ClientConcentrationChart = ({
  clientsData,
  period,
  className,
  clientsColors,
}) => {
  const total = getClientTotal(clientsData);

  const renderLabel = ({ cx, cy, fill, index }) =>
    index === 0 ? (
      <text x={cx} y={cy} textAnchor="middle" fill={fill}>
        <tspan x="50%" className={styles.chartLabelPeriod}>
          {period}
        </tspan>
        <tspan dy="1.2em" x="50%" className={styles.chartLabelValue}>
          {formatNumberWithCommas(total)}
        </tspan>
      </text>
    ) : (
      ''
    );

  const getContainerHeight = useMemo(
    () => 380 + (clientsData.length / 3) * 40,
    [clientsData],
  );

  return (
    !isEmpty(clientsData) && (
      <div className={className}>
        <ResponsiveContainer width="100%" height={getContainerHeight}>
          <PieChart>
            <Pie
              cy={160}
              data={clientsData}
              dataKey="revenue_sum"
              nameKey="client_name"
              cx="50%"
              outerRadius={160}
              innerRadius={80}
              label={renderLabel}
              labelLine={false}
              startAngle={90}
              endAngle={-450}
            >
              {clientsData.map(item => (
                <Cell
                  fill={clientsColors[item.client_name]}
                  key={item.client_name}
                />
              ))}
            </Pie>
            <Legend content={CustomLegend} />
            <Tooltip content={TooltipContentPie} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    )
  );
};

ClientConcentrationChart.propTypes = {
  clientsData: PropTypes.array.isRequired,
  period: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  clientsColors: PropTypes.object.isRequired,
};

export default ClientConcentrationChart;
