import React from 'react';
import PropTypes from 'prop-types';

import { FILTER_TYPES } from 'components/ClientCommon/ClientTimeline/constants';

import CommonTimelineItem from './CommonItem';
import NoteItem from './NoteItem';
import InvoiceItem from './InvoiceItem';

const TimeLineItem = ({
  event = {},
  users,
  updateNote,
  jiraRoles,
  deals,
  allocations,
  organizationsData,
  jiraProjects,
  onEndDeal,
  onDeleteDeal,
  clientAuditWithGroupDealAllocations,
  clientAudit,
  groupedDealsByProject,
  isActive,
  invoicesGroupById,
  onDeleteInvoice,
  onVisibleInvoiceModal,
  clientNotesObject,
  deleteNote,
  onEditInvoice,
  onEditDeal,
  role,
}) => {
  // @ts-ignore
  const { auditable_type } = event;

  switch (auditable_type) {
    case FILTER_TYPES.clientnote.type:
      return (
        <NoteItem
          event={event}
          updateNote={updateNote}
          clientNotesObject={clientNotesObject}
          deleteNote={deleteNote}
        />
      );

    case FILTER_TYPES.invoice.type:
      return (
        <InvoiceItem
          event={event}
          invoicesGroupById={invoicesGroupById}
          deleteInvoice={onDeleteInvoice}
          onVisibleInvoiceModal={onVisibleInvoiceModal}
          onEditInvoice={onEditInvoice}
          role={role}
        />
      );
    default:
      // Используетя для сделок и организаций
      return (
        <CommonTimelineItem
          event={event}
          users={users}
          jiraRoles={jiraRoles}
          deals={deals}
          allocations={allocations}
          organizationsData={organizationsData}
          jiraProjects={jiraProjects}
          onEndDeal={onEndDeal}
          onDeleteDeal={onDeleteDeal}
          clientAudit={clientAudit}
          clientAuditWithGroupDealAllocations={
            clientAuditWithGroupDealAllocations
          }
          groupedDealsByProject={groupedDealsByProject}
          isActive={isActive}
          onEditDeal={onEditDeal}
          role={role}
        />
      );
  }
};

TimeLineItem.propTypes = {
  event: PropTypes.object,
  clientAuditWithGroupDealAllocations: PropTypes.array,
  users: PropTypes.object,
  groupedDealsByProject: PropTypes.object,
  updateNote: PropTypes.func,
  jiraRoles: PropTypes.array,
  onVisibleInvoiceModal: PropTypes.func,
  deals: PropTypes.object,
  invoicesGroupById: PropTypes.object,
  allocations: PropTypes.object,
  organizationsData: PropTypes.object,
  clientAudit: PropTypes.array,
  clientNotesObject: PropTypes.object,
  jiraProjects: PropTypes.object,
  onEndDeal: PropTypes.func,
  onDeleteDeal: PropTypes.func,
  onDeleteInvoice: PropTypes.func,
  deleteNote: PropTypes.func,
  isActive: PropTypes.bool,
  onEditInvoice: PropTypes.func.isRequired,
  onEditDeal: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
};

export default TimeLineItem;
