import { RM_PLAN_TYPES } from 'containers/ResourceManagementPage/constants';
import styles from './styles.scss';

const cellColor = cell => {
  switch (cell.planItem.type) {
    case RM_PLAN_TYPES.BENCH:
      return `${styles.magentaColor}`;
    case RM_PLAN_TYPES.PROJECT:
      return cell.positive_rate
        ? `${styles.volcanoColor}`
        : `${styles.magentaLightColor}`;
    case RM_PLAN_TYPES.SICKLEAVE:
      return `${styles.purpleColor}`;
    case RM_PLAN_TYPES.VACATION:
      return `${styles.vacationColor}`;
    default:
      return `${styles.primaryColor}`;
  }
};

const closeIconColor = cell => {
  switch (cell.planItem.type) {
    case RM_PLAN_TYPES.BENCH:
      return `${styles.closeIconMagentaColor}`;
    case RM_PLAN_TYPES.PROJECT:
      return cell.positive_rate
        ? `${styles.closeIconVolcanoColor}`
        : `${styles.closeIconPrimaryColor}`;
    case RM_PLAN_TYPES.SICKLEAVE:
      return `${styles.closeIconPurpleColor}`;
    case RM_PLAN_TYPES.VACATION:
      return `${styles.closeIconVacationColor}`;
    default:
      return `${styles.closeIconPrimaryColor}`;
  }
};

const arrowRightColor = type => {
  switch (type) {
    case RM_PLAN_TYPES.BENCH:
      return `${styles.arrowRightMagenta}`;
    case RM_PLAN_TYPES.PROJECT:
      return `${styles.arrowRightPrimary}`;
    case RM_PLAN_TYPES.SICKLEAVE:
      return `${styles.arrowRightPurple}`;
    case RM_PLAN_TYPES.VACATION:
      return `${styles.arrowRightVolcano}`;
    default:
      return `${styles.closeIconPrimaryColor}`;
  }
};

const arrowLeftColor = type => {
  switch (type) {
    case RM_PLAN_TYPES.BENCH:
      return `${styles.arrowLeftMagenta}`;
    case RM_PLAN_TYPES.PROJECT:
      return `${styles.arrowLeftPrimary}`;
    case RM_PLAN_TYPES.SICKLEAVE:
      return `${styles.arrowLeftPurple}`;
    case RM_PLAN_TYPES.VACATION:
      return `${styles.arrowLeftVolcano}`;
    default:
      return `${styles.closeIconPrimaryColor}`;
  }
};

export { cellColor, closeIconColor, arrowRightColor, arrowLeftColor };
