/* eslint-disable indent */
// @ts-nocheck
import React, { Fragment, useState, useMemo, useEffect } from 'react';
import dayjs from 'dayjs';
import { Modal, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import PageHeader from 'components/Common/PageHeader';
import { Loader, Table } from 'components/Common';
import { PaymentForm } from 'components/PaymentsCommon';
import RangePicker from 'components/Common/RangePicker';
import { formatDate } from 'utils/formHelper';
import { SORT_DIRECTIONS } from 'utils/constants';
import {
  BEGIN_MONTH_FORMAT,
  DATE_MONTH_FORMAT,
  MONTH_FORMAT,
} from 'utils/timeConstants';
import {
  authSelector,
  extraPaymentsSelector,
  summaryEmployeesSelector,
} from 'redux/selectors';
import {
  getExtraPayments,
  approveExtraPayment,
  declineExtraPayment,
  deleteExtraPayments,
  updateExtraPayments,
} from 'redux/ExtraPayments/actions';

import { getRowKey } from 'utils/helpers';
import { useRoleContext } from 'utils/hooks';
import { getSummaryProfiles } from 'redux/SummaryEmployees/actions';
import { getColumns, getFiltersWithOutNull } from './utils';
import {
  EXTRA_PAYMENTS_DEFAULT_PAGINATION,
  EXTRA_PAYMENTS_MODAL,
  EXTRA_PAYMENT_AMOUNT,
} from './constants';

import styles from './styles.scss';

const formatExtraPayments = ({ validFrom, currency, sum, ...values }) => ({
  valid_from: formatDate(validFrom, BEGIN_MONTH_FORMAT),
  [currency]: sum,
  ...values,
});

const ExtraPayments = () => {
  const [isVisibleEditModal, setIsVisibleEditModal] = useState(false);
  const [currentExtraPaymentId, setCurrentExtraPaymentId] = useState(null);
  const [modalFieldValues, setModalFieldValues] = useState({});
  const [period, setPeriod] = useState([
    dayjs().startOf('month'),
    dayjs().startOf('month'),
  ]);
  const [filtersInfo, setFiltersInfo] = useState({});
  const [pagination, setPagination] = useState(
    EXTRA_PAYMENTS_DEFAULT_PAGINATION,
  );
  const [sortInfo, setSortInfo] = useState({
    field: 'id',
    order: SORT_DIRECTIONS.ascend,
  });

  const role = useRoleContext();

  const dispatch = useDispatch();

  const {
    isExtraPaymentsLoading,
    extraPayments,
    extraPaymentsFilters,
    totalExtraPayments,
  } = useSelector(extraPaymentsSelector);

  const { user } = useSelector(authSelector);

  const { summaryEmployeeProfiles } = useSelector(summaryEmployeesSelector);

  const fetchPayments = ({ period }) => {
    const filtersWithOutNull = getFiltersWithOutNull(filtersInfo);

    dispatch(
      getExtraPayments({
        filters: {
          ...filtersWithOutNull,
          period_from: dayjs(period[0]).format(BEGIN_MONTH_FORMAT),
          period_to: dayjs(period[1]).format(BEGIN_MONTH_FORMAT),
        },
        page: pagination.current,
        size: pagination.pageSize,
      }),
    );
  };

  useEffect(() => {
    setPagination(prev => ({ ...prev, total: Number(totalExtraPayments) }));
  }, [totalExtraPayments]);

  const onChangePeriod = period => {
    if (period) {
      setPeriod(period);
      fetchPayments({ period });
    }
  };
  const onTableChange = (pagination, filters, { field, order }) => {
    const filtersWithOutNull = getFiltersWithOutNull(filters);
    setFiltersInfo(filtersWithOutNull);
    setPagination(pagination);
    setSortInfo({
      field,
      order,
    });
    dispatch(
      getExtraPayments({
        filters: {
          ...filtersWithOutNull,
          period_from: dayjs(period[0]).format(BEGIN_MONTH_FORMAT),
          period_to: dayjs(period[1]).format(BEGIN_MONTH_FORMAT),
        },
        sort: `${field}:${order}`,
        page: pagination.current,
        size: pagination.pageSize,
      }),
    );
  };

  const onHideModal = () => setIsVisibleEditModal(false);

  const onDelete = id => {
    Modal.confirm({
      title: EXTRA_PAYMENTS_MODAL.deleteTitle,
      content: EXTRA_PAYMENTS_MODAL.delete,
      onOk: () => {
        dispatch(
          deleteExtraPayments({
            id,
            filters: {
              ...filtersInfo,
              period_from: dayjs(period[0]).format(BEGIN_MONTH_FORMAT),
              period_to: dayjs(period[1]).format(BEGIN_MONTH_FORMAT),
            },
          }),
        );

        Modal.destroyAll();
      },
      onCancel: Modal.destroyAll,
    });
  };

  const onSubmit = values => {
    if (values) {
      dispatch(
        updateExtraPayments({
          currentExtraPaymentId,
          filters: {
            ...filtersInfo,
            period_from: dayjs(period[0]).format(BEGIN_MONTH_FORMAT),
            period_to: dayjs(period[1]).format(BEGIN_MONTH_FORMAT),
          },
          ...formatExtraPayments(values),
        }),
      );
    }

    onHideModal();
  };

  const onShowModal = ({
    amount_byn,
    amount_usd,
    valid_from,
    description,
    title,
    id,
    employee_id,
  }) => {
    setIsVisibleEditModal(true);
    setCurrentExtraPaymentId(id);
    dispatch(getSummaryProfiles({ employee_id }));

    setModalFieldValues({
      sum: amount_usd || amount_byn,
      validFrom: dayjs(valid_from, BEGIN_MONTH_FORMAT),
      currency: amount_usd
        ? EXTRA_PAYMENT_AMOUNT.USD
        : EXTRA_PAYMENT_AMOUNT.BYN,
      description,
      title,
      employee_id,
    });
  };

  const onApproveExtraPayment = ({ id }) => {
    if (id) {
      Modal.confirm({
        title: EXTRA_PAYMENTS_MODAL.approveTitle,
        content: EXTRA_PAYMENTS_MODAL.approve,
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: () => {
          dispatch(
            approveExtraPayment({
              id,
              filters: {
                ...filtersInfo,
                period_from: dayjs(period[0]).format(BEGIN_MONTH_FORMAT),
                period_to: dayjs(period[1]).format(BEGIN_MONTH_FORMAT),
              },
            }),
          );

          Modal.destroyAll();
        },
        onCancel: Modal.destroyAll,
      });
    }
  };

  const onDeclineExtraPayment = ({ id }) => {
    if (id) {
      Modal.confirm({
        title: EXTRA_PAYMENTS_MODAL.rejectTitle,
        content: EXTRA_PAYMENTS_MODAL.decline,
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: () => {
          dispatch(
            declineExtraPayment({
              id,
              filters: {
                ...filtersInfo,
                period_from: dayjs(period[0]).format(BEGIN_MONTH_FORMAT),
                period_to: dayjs(period[1]).format(BEGIN_MONTH_FORMAT),
              },
            }),
          );

          Modal.destroyAll();
        },
        onCancel: Modal.destroyAll,
      });
    }
  };

  const columns = useMemo(
    () =>
      getColumns({
        onDelete: onDelete,
        onShowModal: onShowModal,
        onApproveExtraPayment,
        onDeclineExtraPayment,
        filtersInfo,
        filtersData: extraPaymentsFilters,
        sortInfo,
        role,
        user,
      }),
    [filtersInfo, extraPayments, extraPaymentsFilters, sortInfo, user],
  );

  useEffect(() => {
    fetchPayments({ period });
  }, []);

  return (
    <Fragment>
      <PageHeader
        title="Extra payments"
        extra={
          <>
            <span style={{ marginTop: '5px', display: 'inline-block' }}>
              Period
            </span>
            <RangePicker
              picker="month"
              defaultValue={period}
              format={MONTH_FORMAT}
              onChange={onChangePeriod}
              className={styles.datePicker}
            />
          </>
        }
      />

      <Modal
        className={styles.modal}
        title="Extra payments"
        open={isVisibleEditModal}
        onCancel={onHideModal}
        okText="Save"
        cancelText="Cancel"
        destroyOnClose
        maskClosable
        footer={
          <>
            <Button onClick={onHideModal}>Отмена</Button>
            <Button
              form="paymentForm"
              key="submit"
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>
          </>
        }
      >
        <PaymentForm
          fieldValues={modalFieldValues}
          monthFormat={BEGIN_MONTH_FORMAT}
          onSubmit={onSubmit}
          isPaymentsPage
          summaryEmployeeProfiles={summaryEmployeeProfiles}
        />
      </Modal>
      <Loader loading={isExtraPaymentsLoading}>
        <Table
          pagination={pagination}
          dataSource={extraPayments}
          rowKey={getRowKey}
          columns={columns}
          onChange={onTableChange}
          bordered
          scroll={{ x: true }}
        />
      </Loader>
    </Fragment>
  );
};

export default ExtraPayments;
