import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import dayjs from 'dayjs';

import { checkCurrencyType } from 'containers/User/UserProfile/utils';
import DatePicker from 'components/Common/DatePicker';
import Modal from 'components/Common/Modal';
import Input from 'components/Common/Input';
import Select, { Option } from 'components/Common/Select';
import { DATE_FORMAT } from 'utils/timeConstants';

import styles from './styles.scss';

const { Item } = Form;

const SalaryModal = ({
  isVisible,
  onSubmit,
  hideModal,
  currentSalary,
  isExternal,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentSalary) {
      const {
        fixed_salary_byn,
        fixed_salary_usd,
        hourly_rate_usd,
        valid_from,
      } = currentSalary;
      form.setFieldsValue({
        currency: checkCurrencyType(currentSalary),
        valid_from: dayjs(valid_from),
        sum: fixed_salary_byn || fixed_salary_usd || hourly_rate_usd,
      });
    }

    return () => form.resetFields();
  }, [currentSalary]);

  const onCancel = () => {
    hideModal();
    form.resetFields();
  };

  const onFinish = values => {
    onSubmit(values);
    form.resetFields();
  };

  const okText = currentSalary ? 'Save' : 'Add';

  return (
    <Modal
      title="Salary"
      open={isVisible}
      onCancel={onCancel}
      onOk={form.submit}
      destroyOnClose
      maskClosable
      cancelText="Cancel"
      okText={okText}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Item
          label="Amount"
          name="sum"
          rules={[
            {
              required: true,
              message: 'Enter salary amount',
            },
            {
              pattern: /^(-)?(\d)*(\.)?([0-9]{1,2})?$/,
              message: 'No more than two decimal places',
            },
          ]}
        >
          <Input
            placeholder="Enter salary amount..."
            className={styles.salaryInput}
            addonBefore={
              <Item
                name="currency"
                initialValue={
                  isExternal ? 'hourly_rate_usd' : 'fixed_salary_usd'
                }
                rules={[
                  {
                    required: true,
                    message: 'Please select currency',
                  },
                ]}
                noStyle
              >
                <Select>
                  <Option value="fixed_salary_usd">USD</Option>
                  <Option value="fixed_salary_byn">BYN</Option>
                  <Option value="hourly_rate_usd">USD/h</Option>
                </Select>
              </Item>
            }
          />
        </Item>
        <Item
          label="Valid from"
          name="valid_from"
          rules={[
            {
              required: true,
              message: 'Enter date of new salary',
            },
          ]}
        >
          <DatePicker
            format={DATE_FORMAT}
            placeholder="Enter date of new salary"
          />
        </Item>
      </Form>
    </Modal>
  );
};

SalaryModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  currentSalary: PropTypes.object,
  isExternal: PropTypes.bool.isRequired,
};

export default SalaryModal;
