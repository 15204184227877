import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isCurrenciesLoading: false,
  currencies: [],
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_CUSTOM_CURRENCIES:
      return state.set('isCurrenciesLoading', true);

    case types.GET_CUSTOM_CURRENCIES_SUCCESS:
      return state.set('isCurrenciesLoading', false).set('currencies', payload);
    case types.ADD_CUSTOM_CURRENCY_SUCCESS:
    case types.UPDATE_CUSTOM_CURRENCY_SUCCESS:
    case types.DELETE_CUSTOM_CURRENCY_SUCCESS:
      return state.set('currencies', payload);

    case types.GET_CUSTOM_CURRENCIES_FAILED:
    case types.ADD_CUSTOM_CURRENCY_FAILED:
    case types.UPDATE_CUSTOM_CURRENCY_FAILED:
    case types.DELETE_CUSTOM_CURRENCY_FAILED:
      return state.set('isCurrenciesLoading', false);

    default:
      return state;
  }
}
