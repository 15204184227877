import React from 'react';
import { get } from 'lodash';
import { TEAM_ID, EMPLOYEE_STATUSES, OK_STATUS } from 'utils/constants';
import Tag from 'components/Common/Tag';
import { SUMMARY_STATUSE_COLORS } from 'containers/EmployeesSummary/constants';
import { SUBCONTRACTORS_MISSING_IBAN_STATUS } from '../../../containers/ProfilesPage/constants';

export const getJiraEmployees = (employees, jira_id) =>
  employees.filter(
    item =>
      (item.jira_id || item.jiraId) &&
      item.jira_id !== jira_id &&
      item.jira_user?.name !== jira_id,
  );

export const getResourceManagerId = (employees, jira_id) =>
  employees.filter(item => get(item, ['jira_user', 'id']) === jira_id)[0].id;

export const getStatusIsSubcontractor = (status, team_id) => {
  if (
    team_id === TEAM_ID.SUBCONTRACTORS &&
    status === SUBCONTRACTORS_MISSING_IBAN_STATUS
  ) {
    return OK_STATUS;
  }

  return status;
};

export function getStatusTag(status, isSummary) {
  if (isSummary) {
    return (
      <Tag color={SUMMARY_STATUSE_COLORS[status]} key={status}>
        {status}
      </Tag>
    );
  }

  const statusValue = EMPLOYEE_STATUSES[status];

  if (!statusValue) {
    return null;
  }

  return (
    <Tag color={get(statusValue, ['color'], 'blue')} key={status}>
      {get(statusValue, ['title'], 'Не указан')}
    </Tag>
  );
}

export const getTeamName = (team_id, teams) =>
  get(teams.find(item => item.id === team_id), 'title', '');

export const onChangeValue = value => ({ setValue, setIsSaveVisible }) => {
  setIsSaveVisible(true);
  setValue(value);
};
