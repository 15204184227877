exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".flexCenter-9edf8,.loader-06507,.globalLoader-aa318{display:flex;justify-content:center;align-items:center}.globalLoader-aa318{background:#fff;width:100%;min-height:100vh;overflow:hidden}.loadingChildren-04d5d{opacity:.5;color:#4a4a4a}.loaderContainer-caeb7{position:relative}.loader-06507{position:absolute;top:0px;width:100%;height:100%}.loaderTable-f2322{position:absolute;top:0px;width:100%;height:100%}.loaderTable-f2322 .spin-1916a{position:absolute;left:50%;transform:translate(-50%, -50%)}", ""]);

// Exports
exports.locals = {
	"flexCenter": "flexCenter-9edf8",
	"loader": "loader-06507",
	"globalLoader": "globalLoader-aa318",
	"loadingChildren": "loadingChildren-04d5d",
	"loaderContainer": "loaderContainer-caeb7",
	"loaderTable": "loaderTable-f2322",
	"spin": "spin-1916a"
};