import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form, Upload, Checkbox } from 'antd';
import dayjs from 'dayjs';
import { InboxOutlined } from '@ant-design/icons';

import { INCOMING_INVOICES_TABLE_COMMON } from 'containers/IncomingInvoicesPage/constants';
import { attachmentsFormatter } from 'containers/IncomingInvoicesPage/utils';
import DatePicker from 'components/Common/DatePicker';
import { getJiraProjects } from 'redux/Jira/actions';
import { getMonthFormat, getPopupContainerHandler } from 'utils/helpers';
import { SORT_DIRECTIONS } from 'utils/constants';
import Modal from 'components/Common/Modal';
import Input from 'components/Common/Input';
import Select, { Option } from 'components/Common/Select';
import TextArea from 'components/Common/TextArea';

import { formItemLayout } from './constants';

const { Item } = Form;
const { Dragger } = Upload;

const IncomingInvoicesModal = ({
  employees,
  organizations,
  invoiceTypes,
  projects,
  users,
  currencies,
  banks,
  currentInvoice,
  isVisible,
  onUpdateIncomingInvoice,
  onCreateIncomingInvoice,
  toggleInvoiceModal,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!projects.length) {
      dispatch(getJiraProjects({ sort: `name:${SORT_DIRECTIONS.ascend}` }));
    }
  }, []);

  useEffect(() => {
    if (currentInvoice) {
      form.setFieldsValue({
        ...currentInvoice,
        period: dayjs(currentInvoice.period),
        type_id: currentInvoice.invoice_type_id,
        attachments: attachmentsFormatter(currentInvoice.attachments),
      });
    }

    return () => form.resetFields();
  }, [currentInvoice]);

  const onSubmit = () => {
    const values = form.getFieldsValue();

    const period = getMonthFormat(values.period);

    if (currentInvoice) {
      onUpdateIncomingInvoice({
        ...values,
        id: currentInvoice.id,
        period,
      });
      form.resetFields();

      return;
    }

    onCreateIncomingInvoice({ ...values, period });
    form.resetFields();
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const attachmentFakeRequest = options => {
    setTimeout(() => {
      options.onSuccess('ok');
    }, 0);
  };

  const filterOptionHandler = (input, option) =>
    option.children?.toLowerCase()?.includes(input.toLowerCase());

  const modalTitle = currentInvoice
    ? 'Edit incoming invoice'
    : 'Add incoming invoice';

  const okText = currentInvoice ? 'Save' : 'Add';

  return (
    <Modal
      open={isVisible}
      title={modalTitle}
      okText={okText}
      onOk={form.submit}
      onCancel={toggleInvoiceModal(false)}
      destroyOnClose
    >
      <Form {...formItemLayout} form={form} onFinish={onSubmit}>
        <Item
          name={INCOMING_INVOICES_TABLE_COMMON.period}
          label={INCOMING_INVOICES_TABLE_COMMON.period_title}
          rules={[
            {
              required: true,
              message: 'Please select period',
            },
          ]}
        >
          <DatePicker picker="month" />
        </Item>
        <Item
          name={INCOMING_INVOICES_TABLE_COMMON.employee_id}
          label={INCOMING_INVOICES_TABLE_COMMON.employee_id_title}
        >
          <Select
            getPopupContainer={getPopupContainerHandler}
            allowClear
            showSearch
            filterOption={filterOptionHandler}
          >
            {employees.map(({ id, summary_employee }) => (
              <Option value={id} key={id}>
                {summary_employee.name_en || summary_employee.name_ru}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name={INCOMING_INVOICES_TABLE_COMMON.organization_id}
          label={INCOMING_INVOICES_TABLE_COMMON.organization_id_title}
          rules={[
            {
              required: true,
              message: 'Please select organization',
            },
          ]}
        >
          <Select getPopupContainer={getPopupContainerHandler}>
            {organizations.map(({ friendly_name, id }) => (
              <Option value={id} key={id}>
                {friendly_name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name={INCOMING_INVOICES_TABLE_COMMON.project_name}
          label={INCOMING_INVOICES_TABLE_COMMON.project_name_title}
        >
          <Select
            getPopupContainer={getPopupContainerHandler}
            allowClear
            showSearch
            filterOption={filterOptionHandler}
          >
            {projects.map(({ id, name }) => (
              <Option value={name} key={id}>
                {name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name={INCOMING_INVOICES_TABLE_COMMON.invoice_number}
          label={INCOMING_INVOICES_TABLE_COMMON.invoice_number_title}
          rules={[
            {
              required: true,
              message: 'Please enter invoice number',
            },
          ]}
        >
          <Input type="number" />
        </Item>
        <Item
          name={INCOMING_INVOICES_TABLE_COMMON.cost}
          label={INCOMING_INVOICES_TABLE_COMMON.cost_title}
          rules={[
            {
              required: true,
              message: 'Please enter cost',
            },
          ]}
        >
          <Input type="number" />
        </Item>
        <Item
          name={INCOMING_INVOICES_TABLE_COMMON.currency_id}
          label={INCOMING_INVOICES_TABLE_COMMON.currency_id_title}
          rules={[
            {
              required: true,
              message: 'Please select currency',
            },
          ]}
        >
          <Select getPopupContainer={getPopupContainerHandler}>
            {currencies.map(({ name, id }) => (
              <Option value={id} key={id}>
                {name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name={INCOMING_INVOICES_TABLE_COMMON.comments}
          label={INCOMING_INVOICES_TABLE_COMMON.comments_title}
        >
          <TextArea cols={3} />
        </Item>
        <Item
          name={INCOMING_INVOICES_TABLE_COMMON.approver_id}
          label={INCOMING_INVOICES_TABLE_COMMON.approver_id_title}
        >
          <Select
            getPopupContainer={getPopupContainerHandler}
            allowClear
            showSearch
            filterOption={filterOptionHandler}
          >
            {users.map(({ name, id }) => (
              <Option value={id} key={id}>
                {name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name={INCOMING_INVOICES_TABLE_COMMON.type_id}
          label={INCOMING_INVOICES_TABLE_COMMON.invoice_type_title}
        >
          <Select getPopupContainer={getPopupContainerHandler}>
            {invoiceTypes.map(({ name, id }) => (
              <Option value={id} key={id}>
                {name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name={INCOMING_INVOICES_TABLE_COMMON.ready_for_check}
          label={INCOMING_INVOICES_TABLE_COMMON.ready_for_check_title}
          valuePropName="checked"
        >
          <Checkbox />
        </Item>
        <Item
          name={INCOMING_INVOICES_TABLE_COMMON.bank_id}
          label={INCOMING_INVOICES_TABLE_COMMON.bank_id_title}
        >
          <Select getPopupContainer={getPopupContainerHandler}>
            {banks.map(({ name, id }) => (
              <Option value={id} key={id}>
                {name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label={INCOMING_INVOICES_TABLE_COMMON.attachments_title}>
          <Item
            name={INCOMING_INVOICES_TABLE_COMMON.attachments}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <Dragger customRequest={attachmentFakeRequest} multiple>
              <p>
                <InboxOutlined />
              </p>
              <p>Click or drag file to this area to upload</p>
            </Dragger>
          </Item>
        </Item>
      </Form>
    </Modal>
  );
};

IncomingInvoicesModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  employees: PropTypes.array.isRequired,
  organizations: PropTypes.array.isRequired,
  invoiceTypes: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  banks: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  currentInvoice: PropTypes.object,
  onUpdateIncomingInvoice: PropTypes.func.isRequired,
  onCreateIncomingInvoice: PropTypes.func.isRequired,
  toggleInvoiceModal: PropTypes.func.isRequired,
};

export default IncomingInvoicesModal;
