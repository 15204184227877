import React from 'react';
import PropTypes from 'prop-types';
import { Timeline as AntdTimeline } from 'antd';
import classNames from 'classnames';

import styles from './styles.scss';

const Timeline = props => {
  const { children, className, highlightFirst } = props;

  return (
    <AntdTimeline
      {...props}
      className={classNames([
        className,
        styles.timeline,
        { [styles.highlight]: highlightFirst },
      ])}
    >
      {children}
    </AntdTimeline>
  );
};

Timeline.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  highlightFirst: PropTypes.bool,
};

export default Timeline;
