exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".projectProfitsWrapper-e4358 .projectProfitsContainer-c1530{display:flex;flex-direction:column}.projectProfitsWrapper-e4358 .projectProfitsContainer-c1530 .projectProfitsTable-2fc91{background-color:#fff;box-shadow:0 0 3px #ccc;border-radius:4px}.projectProfitsWrapper-e4358 .projectProfitsContainer-c1530 .projectProfitsTable-2fc91 .ant-table-row-level-1 .ant-table-row-expand-icon{display:none}.projectProfitsWrapper-e4358 .projectProfits-f585d{display:flex;justify-content:space-between}.projectProfitsWrapper-e4358 .projectProfits-f585d .projectProfitsControls-72dad{display:flex;justify-content:flex-end;align-items:center;margin-bottom:20px}.projectProfitsWrapper-e4358 .projectProfits-f585d .projectProfitsControls-72dad .controlsItem-9709e{margin-right:20px}.projectProfitsWrapper-e4358 .projectProfits-f585d .projectProfitsControls-72dad .controlsItem-9709e:last-child{margin-right:0}.employeeSalary-62b46{text-align:end}.managerContainer-2bab6{display:flex;align-items:center;justify-content:space-between}.statusTitle-7da8f{display:flex;justify-content:space-around;align-items:center}.plusButton-3b64d{display:flex;justify-content:center;align-items:center;height:20px;margin-left:20px;font-weight:700}.projectName-c2ad4{font-weight:500;color:#1677ff;cursor:pointer}.spentHours-a99bb{display:flex;justify-content:flex-end}.categoryTag-1b07c{display:flex;justify-content:center}.redCell-ce9ae{background-color:rgba(222,96,96,.25) !important}.greenCell-bab7e{background-color:rgba(144,222,96,.25) !important}.yellowCell-09287{background-color:#fffbe6 !important}.profitCell-1bd74{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}", ""]);

// Exports
exports.locals = {
	"projectProfitsWrapper": "projectProfitsWrapper-e4358",
	"projectProfitsContainer": "projectProfitsContainer-c1530",
	"projectProfitsTable": "projectProfitsTable-2fc91",
	"projectProfits": "projectProfits-f585d",
	"projectProfitsControls": "projectProfitsControls-72dad",
	"controlsItem": "controlsItem-9709e",
	"employeeSalary": "employeeSalary-62b46",
	"managerContainer": "managerContainer-2bab6",
	"statusTitle": "statusTitle-7da8f",
	"plusButton": "plusButton-3b64d",
	"projectName": "projectName-c2ad4",
	"spentHours": "spentHours-a99bb",
	"categoryTag": "categoryTag-1b07c",
	"redCell": "redCell-ce9ae",
	"greenCell": "greenCell-bab7e",
	"yellowCell": "yellowCell-09287",
	"profitCell": "profitCell-1bd74"
};