import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Table } from 'antd';
import PageHeader from 'components/Common/PageHeader';

import DashboardEmployeeExpandableRow from 'components/DashboardCommon/DashboardEmployeeExpandableRow';
import { Loader } from 'components/Common';
import RangePicker from 'components/Common/RangePicker';
import { getFiltersOrder, getMonthFormat, getRowKey } from 'utils/helpers';
import { getFiltersWithOutNull } from 'utils/common';
import { SORT_DIRECTIONS } from 'utils/constants';
import {
  getDashboardEmployees,
  getDashboardEmployeeData,
} from 'redux/Dashboard/actions';

import { dashboardSelector } from 'redux/selectors';
import { MONTH_FORMAT } from 'utils/timeConstants';
import {
  getColumns,
  getCurrentMonthRange,
  getEmployeeDataPeriod,
} from './utils';
import { DASHBOARD_EMPLOYEES_INDEX } from './constants';

import styles from './styles.scss';

const DashboardEmployeeOverview = () => {
  const [period, setPeriod] = useState(getCurrentMonthRange(new Date()));
  const [isDeliveryOnly, setIsDeliveryOnly] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [filterInfo, setFilterInfo] = useState({});
  const [sortInfo, setSortInfo] = useState({
    field: DASHBOARD_EMPLOYEES_INDEX.employee_name,
    order: SORT_DIRECTIONS.ascend,
  });
  const [filtersOrder, setFiltersOrder] = useState([]);

  const dispatch = useDispatch();

  const {
    dashboardEmployees,
    averageAer,
    totalDelivery,
    totalNotDelivery,
    employeeData,
    employeesFilters,
    isEmployeeLoading,
    isDashboardLoading,
  } = useSelector(dashboardSelector);

  const dashboardDefaultRequest = {
    from: getMonthFormat(period[0]),
    to: getMonthFormat(period[1]),
    only_delivery: isDeliveryOnly,
  };

  useEffect(() => {
    dispatch(getDashboardEmployees(dashboardDefaultRequest));
    setFilterInfo({});
  }, [period]);

  const hideExpandedRows = () => setExpandedRowKeys([]);

  const onExpand = (expanded, record) => {
    if (!expanded) {
      hideExpandedRows();

      return;
    }

    setExpandedRowKeys([record.id]);

    dispatch(
      getDashboardEmployeeData({
        params: getEmployeeDataPeriod(period),
        employeeId: record.id,
      }),
    );
  };
  const columns = useMemo(
    () =>
      getColumns({
        sortInfo,
        filterInfo,
        averageAer,
        filterData: employeesFilters,
      }),
    [dashboardEmployees, filterInfo, sortInfo, employeesFilters],
  );

  const onTableChange = (pagination, filters, sorter) => {
    const filtersWithoutNull = getFiltersWithOutNull(filters);
    const order = getFiltersOrder({
      order: filtersOrder,
      filters: filtersWithoutNull,
    });
    setFilterInfo(filtersWithoutNull);
    setFiltersOrder(order);
    setSortInfo(sorter);
    dispatch(
      getDashboardEmployees({
        ...dashboardDefaultRequest,
        sort: `${sorter.field}:${sorter.order}`,
        filters: filtersWithoutNull,
        filtersOrder: order,
      }),
    );
  };

  const onPeriodChange = period => {
    setPeriod([period[0], period[1]]);
    setFilterInfo({});
    setFiltersOrder([]);
  };

  const onIsDeliveryOnlyChange = checked => {
    dispatch(
      getDashboardEmployees({
        ...dashboardDefaultRequest,
        only_delivery: checked,
      }),
    );
    setIsDeliveryOnly(checked);
  };

  useEffect(() => {
    hideExpandedRows();
  }, [filterInfo, sortInfo, period, isDeliveryOnly]);

  return (
    <div className={styles.dashboardEmployeeOverview}>
      <PageHeader
        title="Dashboard employee overview"
        subTitle={
          <>
            <span className={styles.totalDelivery}>
              Total delivery: {totalDelivery}
            </span>
            <span>Total not delivery: {totalNotDelivery}</span>
          </>
        }
        extra={
          <div className={styles.dashboardHeader}>
            <div className={styles.withoutSubcontractors}>
              <span>Only delivery</span>
              <Switch
                checked={isDeliveryOnly}
                onChange={onIsDeliveryOnlyChange}
              />
            </div>
            <RangePicker
              picker="month"
              value={period}
              format={MONTH_FORMAT}
              onChange={onPeriodChange}
              className={styles.rangePicker}
            />
          </div>
        }
      />
      <Loader loading={isDashboardLoading} isDynamicPosition>
        <Table
          dataSource={dashboardEmployees}
          columns={columns}
          onChange={onTableChange}
          pagination={false}
          rowKey={getRowKey}
          expandable={{
            expandedRowRender: () => (
              <DashboardEmployeeExpandableRow
                employeeData={employeeData}
                isLoading={isEmployeeLoading}
                period={period}
              />
            ),
            expandedRowKeys: expandedRowKeys,
            rowExpandable: () => true,
            onExpand,
          }}
        />
      </Loader>
    </div>
  );
};

export default DashboardEmployeeOverview;
