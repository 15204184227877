export const types = {
  GET_BANKS: 'banks/GET_BANKS',
  GET_BANKS_SUCCESS: 'banks/GET_BANKS_SUCCESS',
  GET_BANKS_FAILED: 'banks/GET_BANKS_FAILED',

  ADD_BANK: 'banks/ADD_BANK',
  ADD_BANK_SUCCESS: 'banks/ADD_BANK_SUCCESS',
  ADD_BANK_FAILED: 'banks/ADD_BANK_FAILED',

  UPDATE_BANK: 'banks/UPDATE_BANK',
  UPDATE_BANK_SUCCESS: 'banks/UPDATE_BANK_SUCCESS',
  UPDATE_BANK_FAILED: 'banks/UPDATE_BANK_FAILED',

  DELETE_BANK: 'banks/DELETE_BANK',
  DELETE_BANK_SUCCESS: 'banks/DELETE_BANK_SUCCESS',
  DELETE_BANK_FAILED: 'banks/DELETE_BANK_FAILED',
};
