import styled from 'styled-components';

const TableStyles = styled.div`
  padding: 1rem 0;

  .table {
    border-spacing: 0;
    border: 1px solid #f0f0f0;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    box-shadow: 0 0 3px #ccc;
    border-radius: 4px;
    .tr {
      display: flex;
      width: 100%;
      left: 0px;
      top: 70px;
      height: 55px;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      :first-child {
        border-right: 0;
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;

      :last-child {
        border-right: 0;
      }
    }

    .manager {
      background: #f6f6f6;
    }

    .isdayoff {
      background: #f5c6c6;
    }

    .th {
      width: 100px;
      font-weight: 500;
      background: #fafafa;
      text-align: center;
    }

    .td {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
    }
    .td:hover {
      background: #cccccc;
    }
  }
`;

export default TableStyles;
