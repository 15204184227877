import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';

import { FILTER_TYPES, TIMELINE_FILTERS } from '../constants';
import TimelineTabsExtra from './TimelineTabsExtra';

import styles from './styles.scss';

const TimelineTabs = ({
  changeFilter,
  filter,
  onChangeProjectFilter,
  clientProjects,
  onShowClientModal,
  progress,
  onUpdateJiraProjects,
  role,
}) => {
  const onChangeFilter = type => {
    if (type !== filter) {
      const filterValue = FILTER_TYPES[type.toString().toLowerCase()].type;
      changeFilter(filterValue);
    }
  };

  const items = TIMELINE_FILTERS.map(type => ({
    label: FILTER_TYPES[type.toString().toLowerCase()].title,
    key: type,
  }));

  return (
    <div className={styles.clientTimelineTabs}>
      <Tabs
        items={items}
        activeKey={filter}
        onChange={onChangeFilter}
        tabBarExtraContent={{
          right: (
            <TimelineTabsExtra
              onChangeProjectFilter={onChangeProjectFilter}
              clientProjects={clientProjects}
              onShowClientModal={onShowClientModal}
              filter={filter}
              progress={progress}
              onUpdateJiraProjects={onUpdateJiraProjects}
              role={role}
            />
          ),
        }}
      />
    </div>
  );
};

TimelineTabs.propTypes = {
  changeFilter: PropTypes.func,
  onChangeProjectFilter: PropTypes.func,
  filter: PropTypes.string,
  role: PropTypes.string,
  clientProjects: PropTypes.array.isRequired,
  onShowClientModal: PropTypes.func.isRequired,
  progress: PropTypes.object,
  onUpdateJiraProjects: PropTypes.func.isRequired,
};

export default TimelineTabs;
