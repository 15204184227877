import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import Input from 'components/Common/Input';

const { Item } = Form;

const TechnologiesForm = ({ form, onSubmit }) => (
  <Form layout="vertical" form={form} onFinish={onSubmit}>
    <Item
      label="Title"
      name="title"
      rules={[
        {
          required: true,
          message: 'Required field',
        },
      ]}
    >
      <Input />
    </Item>
    <Item
      label="Key"
      name="key"
      rules={[
        {
          required: true,
          message: 'Required field',
        },
      ]}
    >
      <Input />
    </Item>
  </Form>
);

TechnologiesForm.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TechnologiesForm;
