import React, { Fragment, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import dayjs from 'dayjs';

import { Button, Table } from 'components/Common';
import { PaymentForm } from 'components/PaymentsCommon';
import { SORT_DIRECTIONS } from 'utils/constants';
import { getRowKey } from 'utils/helpers';
import Modal from 'components/Common/Modal';
import { MONTH_FORMAT } from './constants';
import { getTableColumns, getModifiedData, formatExtraPayments } from './utils';
import styles from './styles.scss';

const UserPayments = ({
  onUpdateExtraPayments,
  currentEmployeeId,
  getJiraProjects,
  extraPayments = [],
  onDelete,
  projects,
  users,
  employees,
}) => {
  const [currentExtraPaymentId, setCurrentExtraPaymentId] = useState(null);
  const [isVisibleEditModal, setIsVisibleEditModal] = useState(false);
  const [filtersInfo, setFiltersInfo] = useState({});
  const [extraPaymentsData, setExtraPaymentsData] = useState([]);

  const dispatch = useDispatch();

  const onSubmit = values => {
    if (values) {
      dispatch(
        onUpdateExtraPayments({
          currentExtraPaymentId,
          currentEmployeeId,
          ...formatExtraPayments(values),
        }),
      );
    }

    onHideModal();
  };

  const onShowModal = id => {
    setIsVisibleEditModal(true);
    setCurrentExtraPaymentId(id);
  };

  const onHideModal = () => {
    setIsVisibleEditModal(false);
  };

  const onRow = () => ({
    onClick: () => null,
  });

  const columns = useMemo(
    () =>
      getTableColumns({
        onDelete,
        onShowModal,
        users,
        projects,
        employees,
      }),
    [users, filtersInfo, projects],
  );

  const currentExtraPayment = useMemo(
    () => extraPayments.find(item => get(item, 'id') === currentExtraPaymentId),
    [extraPayments, currentExtraPaymentId],
  );

  const fieldValues = useMemo(() => {
    if (currentExtraPayment)
      return {
        sum: currentExtraPayment.amount_usd || currentExtraPayment.amount_byn,
        validFrom: dayjs(currentExtraPayment.valid_from, MONTH_FORMAT),
        currency: currentExtraPayment.amount_usd ? 'amount_usd' : 'amount_byn',
        description: currentExtraPayment.description,
        title: currentExtraPayment.title,
      };
  }, [currentExtraPayment, extraPayments]);

  const dataSource = useMemo(() => getModifiedData(extraPaymentsData), [
    extraPayments,
    filtersInfo,
    extraPaymentsData,
  ]);

  useEffect(() => {
    setExtraPaymentsData(extraPayments);
  }, [extraPayments]);

  useEffect(() => {
    dispatch(getJiraProjects({ sort: `name:${SORT_DIRECTIONS.ascend}` }));
  }, []);

  return (
    <Fragment>
      <Modal
        className={styles.modal}
        title="Extra payments"
        open={isVisibleEditModal}
        onCancel={onHideModal}
        okText="Сохранить"
        cancelText="Отмена"
        destroyOnClose
        maskClosable
        footer={[
          <Button onClick={onHideModal}>Отмена</Button>,
          <Button
            form="paymentForm"
            key="submit"
            htmlType="submit"
            type="primary"
          >
            Save
          </Button>,
        ]}
      >
        <PaymentForm
          fieldValues={fieldValues}
          monthFormat={MONTH_FORMAT}
          onSubmit={onSubmit}
        />
      </Modal>
      <Table
        rowKey={getRowKey}
        style={{ margin: '20px 20px 20px 0' }}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        onRow={onRow}
        scroll={{ x: 1500 }}
        bordered
        size="small"
        storageKey="employeesExtrapayments"
        isPageWithId
        hey="hey"
      />
    </Fragment>
  );
};

UserPayments.propTypes = {
  currentEmployeeId: PropTypes.string,
  extraPayments: PropTypes.array,
  projects: PropTypes.array,
  onDelete: PropTypes.func.isRequired,
  onUpdateExtraPayments: PropTypes.func.isRequired,
  getJiraProjects: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
};

export default UserPayments;
