export const CREATION_ROW = {
  id: 'new_accountant',
  title: '',
  bank_name: '',
  bank_address: '',
  billing_address: '',
  account_number: '',
  currency_code: '',
  swift: '',
};
