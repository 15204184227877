import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Typography, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { DASHBOARD_OVERVIEW_TOOLTIPS } from 'containers/DashboardOverviewPage/constants';
import { getMonthNameWithYear } from 'utils/helpers';

import styles from './styles.scss';

const { Title } = Typography;

const ProfitAndLossHeader = ({ period, calculateProgress }) => (
  <div className={styles.profitAndLossHeader}>
    <div className={styles.title}>
      <Title level={5}>Profit and Loss</Title>
      <Tooltip title={DASHBOARD_OVERVIEW_TOOLTIPS.PROFIT_AND_LOSS}>
        <InfoCircleOutlined className={styles.info} />
      </Tooltip>
    </div>
    <div className={styles.details}>
      <span className={styles.period}>{getMonthNameWithYear(period[1])}</span>
      <span className={styles.updatedAt}>
        Updated:{' '}
        {dayjs(calculateProgress?.updated_at.seconds * 1000).format(
          'MMMM Do YYYY, h:mm:ss a',
        )}
      </span>
    </div>
  </div>
);

ProfitAndLossHeader.propTypes = {
  calculateProgress: PropTypes.object,
  period: PropTypes.array.isRequired,
};

export default ProfitAndLossHeader;
