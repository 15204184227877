export const DASHBOARD_EMPLOYEES_INDEX = {
  employee_title: 'Full name',
  employee_id: 'empl_ids',
  employee_name: 'name',
  role_title: 'Role',
  role: 'role',
  role_id: 'pos_ids',
  rm_title: 'RM',
  rm_id: 'rm_ids',
  rm_name: 'rm_name',
  revenue_title: 'Revenue, USD',
  revenue: 'total_revenue',
  cost_title: 'Cost, USD',
  cost: 'total_cost',
  profit_title: 'Profit, USD',
  profit: 'total_profit',
  aer_title: 'AER, USD',
  aer: 'total_aer',
  com_title: 'COM %',
  com: 'total_com_prc',
  utilization_title: 'Utilization %',
  utilization: 'total_utilization',
};
