import dayjs from 'dayjs';

import { HOLIDAY_TYPES } from 'containers/CalendarPage/constants';

export const getSortedHolidays = holidays => {
  if (!holidays) return [];

  const sortedOther =
    holidays.others.sort((a, b) => {
      const aIndex = HOLIDAY_TYPES[a.holiday_type].order;
      const bIndex = HOLIDAY_TYPES[b.holiday_type].order;

      return aIndex >= bIndex ? 1 : -1;
    }) || [];

  const formattedBirthdays = holidays.birthdays.map(holiday => ({
    ...holiday,
    holiday_type: 'birthday',
  }));
  const formattedAnniversaries = holidays.anniversaries.map(holiday => ({
    ...holiday,
    holiday_type: 'anniversary',
  }));

  return [...sortedOther, ...formattedAnniversaries, ...formattedBirthdays];
};

export const getHolidayTitle = (
  { holiday_type, title, holiday_date },
  date,
) => {
  switch (holiday_type) {
    case HOLIDAY_TYPES.anniversary.key:
      return `${dayjs(date).diff(
        holiday_date,
        'years',
      )}-year anniversary of ${title}'s work in the company`;
    case HOLIDAY_TYPES.birthday.key:
      return `${title}'s birthday`;
    default:
      return title;
  }
};
