import React from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { DoubleRightOutlined } from '@ant-design/icons';

import { INVOICE_KEYS } from './constants';

export const getExpandedColumns = () => [
  {
    dataIndex: 'title',
    key: 'title',
    render: value => (
      <div
        style={{ fontWeight: 600, display: 'flex', justifyContent: 'center' }}
      >
        {value}
      </div>
    ),
    width: '26%',
  },
  {
    dataIndex: 'old_value',
    key: 'oldValue',
    render: (value, record) => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {INVOICE_KEYS.includes(record.key) ? <pre>{value}</pre> : value}
      </div>
    ),
    width: '32%',
  },
  {
    dataIndex: 'arrow',
    key: 'arrow',
    render: () => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DoubleRightOutlined />
      </div>
    ),
    width: '10%',
  },

  {
    dataIndex: 'new_value',
    key: 'newValue',
    render: (value, record) => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {INVOICE_KEYS.includes(record.key) ? <pre>{value}</pre> : value}
      </div>
    ),
    width: '32%',
  },
];

export const transformObjectToArray = ({
  audited_changes,
  action,
  auditable_type,
}) => {
  if (auditable_type === 'Allocation') {
    return Object.keys(audited_changes).map(item => {
      if (item === 'assignee') {
        return {
          title: item,
          old_value: `${get(audited_changes, [item, 'key'], '-')}`,
          new_value: `${get(audited_changes, [item, 'key'], '-')}`,
          key: item,
        };
      }

      if (item === 'planItem') {
        return {
          title: item,
          old_value: `${get(audited_changes, [item, 'rule'], '-')}`,
          new_value: `${get(audited_changes, [item, 'rule'], '-')}`,
          key: item,
        };
      }

      if (item === 'recurrence') {
        return {
          title: item,
          old_value: `${get(audited_changes, [item, 'type'], '-')}}`,
          new_value: `${get(audited_changes, [item, 'type'], '-')}`,
          key: item,
        };
      }

      return {
        title: item,
        old_value: '-',
        new_value: `${get(audited_changes, [item], [])}`,
        key: item,
      };
    });
  }

  if (auditable_type === 'Invoice') {
    return Object.keys(audited_changes).map(item => {
      if (INVOICE_KEYS.includes(item)) {
        return {
          title: item,
          old_value: JSON.stringify(get(audited_changes, item, '-'), null, 2),
          new_value: JSON.stringify(get(audited_changes, item, '-'), null, 2),
          key: item,
        };
      }

      if (item === 'amount') {
        return {
          title: item,
          old_value: `${get(audited_changes, [item], '-')[0]}`,
          new_value: `${get(audited_changes, [item], '-')[1]}`,
          key: item,
        };
      }

      if (item === 'attachments') {
        const newValue =
          get(audited_changes, [item], '-') &&
          get(audited_changes, [item], '-')[1];
        const oldValue =
          get(audited_changes, [item], '-') &&
          get(audited_changes, [item], '-')[0];

        const oldValueNode = oldValue ? (
          <div>
            {oldValue.map(item => (
              <a href={item.url} target="_blank">
                {item.title}
              </a>
            ))}
          </div>
        ) : (
          '-'
        );

        const newValueNode = newValue ? (
          <div>
            {newValue.map(item => (
              <a href={item.url} target="_blank">
                {item.title}
              </a>
            ))}
          </div>
        ) : (
          '-'
        );

        return {
          title: item,
          old_value: oldValueNode,
          new_value: newValueNode,
          key: item,
        };
      }

      return {
        title: item,
        old_value: '-',
        new_value: get(audited_changes, item, ''),
        key: item,
      };
    });
  }

  if (action === 'create') {
    return Object.keys(audited_changes).map(item => ({
      title: item,
      old_value: '-',
      new_value: `${get(audited_changes, [item], [])}`,
      key: item,
    }));
  }

  if (action === 'destroy') {
    return Object.keys(audited_changes).map(item => ({
      title: item,
      old_value: `${get(audited_changes, [item], [])}`,
      new_value: '-',
      key: item,
    }));
  }

  if (action === 'update') {
    return Object.keys(audited_changes).map(item => {
      if (item === 'jira_user') {
        return {
          title: item,
          old_value: `${get(get(audited_changes, [item])[0], ['name'], '-')}`,
          new_value: `${get(get(audited_changes, [item])[1], ['name'], '-')}`,
          key: item,
        };
      }

      return {
        title: item,
        old_value: `${get(audited_changes, [item], [])[0]}`,
        new_value: `${get(audited_changes, [item], [])[1]}`,
        key: item,
      };
    });
  }
};

export const changeIdToFullName = ({ dataSource, users, employees }) =>
  dataSource.map(item => {
    if (get(item, 'title', false) === 'author_id') {
      const newUser = users.find(
        elem => get(elem, 'id') === Number(get(item, 'new_value')),
      );

      const oldUser = users.find(
        elem => get(elem, 'id') === Number(get(item, 'old_value')),
      );

      return {
        ...item,
        title: 'author',
        old_value:
          get(oldUser, 'email') ||
          get(oldUser, 'email') ||
          get(item, 'old_value', '-'),
        new_value:
          get(newUser, 'name') ||
          get(newUser, 'email') ||
          get(item, 'new_value', '-'),
      };
    }

    if (get(item, 'title') === 'employee_id') {
      const currentEmployee = employees.find(
        employee =>
          Number(get(employee, 'id')) === Number(get(item, 'new_value')),
      );
      const previousEmployee = employees.find(
        employee =>
          Number(get(employee, 'id')) === Number(get(item, 'old_value')),
      );

      return {
        ...item,
        title: 'employee',
        old_value:
          (
            <Link to={`/profiles/${get(previousEmployee, 'id')}`}>
              {get(previousEmployee, 'summary_employee.name_en') ||
                get(previousEmployee, 'summary_employee.name_ru')}
            </Link>
          ) || '-',
        new_value:
          (
            <Link to={`/profiles/${get(currentEmployee, 'id')}`}>
              {get(currentEmployee, 'summary_employee.name_en') ||
                get(currentEmployee, 'summary_employee.name_ru')}
            </Link>
          ) || '-',
      };
    }

    if (get(item, 'title') === 'resource_manager_id') {
      const currentRm = employees.find(
        employee => get(employee, 'id') === Number(get(item, 'new_value')),
      );
      const previousRm = employees.find(
        employee => get(employee, 'id') === Number(get(item, 'old_value')),
      );

      return {
        ...item,
        title: 'resource manager',
        old_value:
          (
            <Link to={`/profiles/${get(previousRm, 'id')}`}>
              {get(previousRm, 'summary_employee.name_en') ||
                get(previousRm, 'summary_employee.name_ru')}
            </Link>
          ) || '-',
        new_value:
          (
            <Link to={`/profiles/${get(previousRm, 'id')}`}>
              {get(currentRm, 'summary_employee.name_en') ||
                get(currentRm, 'summary_employee.name_ru')}
            </Link>
          ) || '-',
      };
    }

    return item;
  });
