/* eslint-disable no-case-declarations */
import React from 'react';
import { get, isEmpty, sortBy } from 'lodash';
import dayjs from 'dayjs';
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ScheduleOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

import { FILTER_TYPES, AUDIT_ACTIONS } from './constants';

export function filterEventsByType(
  events = [],
  type,
  invoicesGroupById,
  deals,
  projectFilter,
  clientNotesObject,
) {
  if (type === FILTER_TYPES.all.type) {
    return events.filter(event => {
      if (
        (event.auditable_type === FILTER_TYPES.client.type &&
          event.action === AUDIT_ACTIONS.update) ||
        (event.auditable_type === FILTER_TYPES.deal.type &&
          (event.action === AUDIT_ACTIONS.update ||
            event.action === AUDIT_ACTIONS.destroy)) ||
        event.auditable_type === FILTER_TYPES.dealAllocations.type ||
        (event.auditable_type === FILTER_TYPES.invoice.type &&
          (event.action === AUDIT_ACTIONS.update ||
            event.action === AUDIT_ACTIONS.destroy ||
            !invoicesGroupById[event.auditable_id])) ||
        (event.action === AUDIT_ACTIONS.update &&
          event.auditable_type === FILTER_TYPES.clientnote.type)
      ) {
        return false;
      }

      return true;
    });
  }

  if (type === FILTER_TYPES.deal.type) {
    return events.filter(event => {
      if (
        event.action === AUDIT_ACTIONS.update ||
        event.action === AUDIT_ACTIONS.destroy
      ) {
        return false;
      }

      if (event.auditable_type === type) {
        const dealProject =
          deals[event.auditable_id] && deals[event.auditable_id].project_id;

        return (
          dealProject &&
          (projectFilter.includes(dealProject.toString()) ||
            projectFilter.length === 0)
        );
      }

      return event.auditable_type === type;
    });
  }

  if (type === FILTER_TYPES.clientnote.type) {
    return events.filter(event => {
      if (
        event.action === AUDIT_ACTIONS.update ||
        event.action === AUDIT_ACTIONS.destroy ||
        !Object.keys(clientNotesObject).includes(String(event.auditable_id))
      ) {
        return false;
      }

      return event.auditable_type === type;
    });
  }

  if (type === FILTER_TYPES.invoice.type) {
    return events.filter(event => {
      if (
        event.action === AUDIT_ACTIONS.update ||
        event.action === AUDIT_ACTIONS.destroy ||
        !invoicesGroupById[event.auditable_id]
      ) {
        return false;
      }

      return event.auditable_type === type;
    });
  }

  return events.filter(event => event.auditable_type === type);
}

export const getTimeLineColor = ({ action, event, deals }) => {
  const { auditable_id, auditable_type } = event;

  if (auditable_type === FILTER_TYPES.deal.type) {
    const isDealDeleted = deals && !!get(deals, [auditable_id, 'deleted_at']);

    if (isDealDeleted) {
      return 'red';
    }
  }

  if (action === AUDIT_ACTIONS.destroy) return 'red';

  if (action === AUDIT_ACTIONS.update) return 'blue';

  return 'green';
};

export const getGroupDealAllocations = ({
  clientAudit,
  deals,
  allocationsGroupById,
}) => {
  const details = {
    groupedClientAudit: [],
    tempDealAllocations: [],
    dealAllocationUid: '',
    auditable_id: '',
    dealTitle: '',
    created_at: null,
    deal_id: null,
    actions: [],
  };

  clientAudit.forEach(item => {
    const {
      auditable_id,
      auditable_type: type,
      request_uuid: requestUid,
      created_at,
      user_id,
    } = item;

    if (type === FILTER_TYPES.dealAllocations.type) {
      if (details.dealAllocationUid !== requestUid) {
        details.dealAllocationUid = requestUid;
        details.actions.push(item.action);

        if (!isEmpty(details.tempDealAllocations)) {
          details.groupedClientAudit.push({
            dealAllocations: details.tempDealAllocations,
            action: details.actions.every(
              action => action === AUDIT_ACTIONS.create,
            )
              ? AUDIT_ACTIONS.create
              : AUDIT_ACTIONS.update,
            dealTitle:
              allocationsGroupById[auditable_id] &&
              get(
                deals[allocationsGroupById[auditable_id][0].deal_id],
                'title',
              ),
            request_uuid: details.dealAllocationUid,
            auditable_type: type,
            user_id,
            created_at: details.created_at,
            deal_id:
              allocationsGroupById[
                details.tempDealAllocations[0].auditable_id
              ] &&
              allocationsGroupById[
                details.tempDealAllocations[0].auditable_id
              ][0].deal_id,
          });
          details.tempDealAllocations = [];
          details.actions = [];
        }

        details.tempDealAllocations.push(item);
        details.created_at = created_at;
      } else {
        details.actions.push(item.action);
        details.tempDealAllocations.push(item);
      }
    } else {
      if (!isEmpty(details.tempDealAllocations)) {
        details.groupedClientAudit.push({
          dealAllocations: details.tempDealAllocations,
          request_uuid: details.tempDealAllocations[0].request_uuid,
          user_id: details.tempDealAllocations[0].user_id,
          created_at: details.created_at,
          auditable_type: details.tempDealAllocations[0].auditable_type,
          deal_id:
            allocationsGroupById[details.tempDealAllocations[0].auditable_id] &&
            allocationsGroupById[details.tempDealAllocations[0].auditable_id][0]
              .deal_id,
          action: details.actions.every(
            action => action === AUDIT_ACTIONS.create,
          )
            ? AUDIT_ACTIONS.create
            : AUDIT_ACTIONS.update,
          dealTitle:
            allocationsGroupById[details.tempDealAllocations[0].auditable_id] &&
            get(
              deals[
                allocationsGroupById[
                  details.tempDealAllocations[0].auditable_id
                ][0].deal_id
              ],
              'title',
            ),
        });
        details.tempDealAllocations = [];
        details.actions = [];
      }

      details.groupedClientAudit.push(item);
    }
  });

  return details.groupedClientAudit;
};

export const getTimeLineIcon = ({ event, deals, groupedDealsByProject }) => {
  const { auditable_type, action, auditable_id } = event;

  switch (auditable_type) {
    case FILTER_TYPES.client.type:
      return { icon: <CheckCircleOutlined /> };
    case FILTER_TYPES.deal.type:
      if (action === AUDIT_ACTIONS.destroy) {
        return { icon: <CloseCircleOutlined /> };
      }

      const today = dayjs();
      const deal = deals[auditable_id];
      const projectDeals =
        deal && groupedDealsByProject[deal.project_id]
          ? sortBy(groupedDealsByProject[deal.project_id], 'starts_at')
          : [];
      const filteredProjectDeals = projectDeals.reduceRight((acc, item) => {
        if (dayjs(item.starts_at).isBefore(today)) {
          return [...acc, item];
        }

        return acc;
      }, []);

      const indexOfDeal = filteredProjectDeals.findIndex(
        item => item.id === deal.id,
      );

      if (deal) {
        if (indexOfDeal === 0) {
          return { icon: <ScheduleOutlined />, isActive: true };
        }

        if (dayjs(deal.starts_at).isAfter(today)) {
          return { icon: <ClockCircleOutlined /> };
        }
      }

      return { icon: '' };
    case FILTER_TYPES.invoice.type:
      return { icon: <FileTextOutlined /> };
    default:
      return { icon: '' };
  }
};
