import { takeLatest, call, put } from 'redux-saga/effects';

import API from 'utils/api';

import { sagaErrorHandler } from 'utils/common';
import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { types } from './types';

function* getRmDealAllocations({ payload }) {
  try {
    const { data } = yield call(API.getModalData, payload);
    yield put({ type: types.GET_RM_DEAL_ALLOCATIONS_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_RM_DEAL_ALLOCATIONS_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getDeals() {
  try {
    const { data } = yield call(API.getDeals);
    yield put({ type: types.GET_DEALS_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_DEALS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_RM_DEAL_ALLOCATIONS, getRmDealAllocations);
  yield takeLatest(types.GET_DEALS, getDeals);
}
