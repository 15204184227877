import React from 'react';
import { Divider, Input, Button } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';

import DatePicker from 'components/Common/DatePicker';
import PageHeader from 'components/Common/PageHeader';
import { hasRights } from 'utils/permissions';
import { PAYSHEETS_PERMISSIONS } from 'containers/PaysheetTable/constants';
import { currencyFormatter } from 'utils/helpers';
import { CURRENCY_CODES } from 'utils/constants';
import styles from './styles.scss';

const dateFormat = 'DD MMM YYYY';
const monthFormat = 'MMM YYYY';

const PaysheetTableHeader = ({
  title,
  period,
  averageSalaryPeriod,
  exchangeRateDate,
  averageSalary,
  exchangeRate,
  onPeriodChange,
  onAverageSalaryPeriodChange,
  onExchangeRateDateChange,
  onDownload,
  onReload,
  isModified = false,
  onUpdateValues,
  role,
}) => {
  const averagePeriod = dayjs.isDayjs(averageSalaryPeriod)
    ? averageSalaryPeriod
    : dayjs(averageSalaryPeriod);
  const exhangeDate = dayjs.isDayjs(exchangeRateDate)
    ? exchangeRateDate
    : dayjs(exchangeRateDate);

  return (
    <PageHeader
      title={title}
      extra={
        <div className={styles.controlsRow}>
          {hasRights(role, PAYSHEETS_PERMISSIONS.exportData) && (
            <Button
              style={{ marginRight: '10px' }}
              icon={<DownloadOutlined />}
              onClick={onDownload}
            >
              Экспорт
            </Button>
          )}
          {hasRights(role, PAYSHEETS_PERMISSIONS.updateData) && (
            <>
              <Button
                style={{ marginRight: '10px' }}
                icon={<ReloadOutlined />}
                onClick={onReload}
              >
                Обновить
              </Button>
              <span className={styles.controlsLabel}>НЗП за:</span>
              <DatePicker
                picker="month"
                style={{ width: '120px' }}
                value={averagePeriod}
                format={monthFormat}
                onChange={onAverageSalaryPeriodChange}
              />
              <Input
                style={{ marginLeft: 20, width: '120px' }}
                className={styles.inputNubmer}
                value={currencyFormatter(averageSalary, CURRENCY_CODES.BYR)}
                disabled
              />
              <Divider type="vertical" />
              <span className={styles.controlsLabel}>Курс на:</span>
              <DatePicker
                style={{ width: '150px' }}
                value={exhangeDate}
                format={dateFormat}
                onChange={onExchangeRateDateChange}
              />
              <Input
                className={styles.inputNubmer}
                style={{ marginLeft: 20, width: '120px' }}
                value={currencyFormatter(exchangeRate, CURRENCY_CODES.BYR)}
                disabled
              />
            </>
          )}
          <Divider type="vertical" />
          <span className={styles.controlsLabel}>Период:</span>
          <DatePicker
            picker="month"
            style={{ width: '120px' }}
            defaultValue={null}
            value={period}
            format={monthFormat}
            onChange={onPeriodChange}
          />
          <Divider type="vertical" />
          {hasRights(role, PAYSHEETS_PERMISSIONS.updateData) && (
            <Button
              type="primary"
              disabled={!isModified}
              onClick={onUpdateValues}
            >
              Применить
            </Button>
          )}
        </div>
      }
    />
  );
};

PaysheetTableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  period: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  averageSalaryPeriod: PropTypes.object,
  averageSalary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exchangeRateDate: PropTypes.object,
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPeriodChange: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  onAverageSalaryPeriodChange: PropTypes.func.isRequired,
  onExchangeRateDateChange: PropTypes.func.isRequired,
  onUpdateValues: PropTypes.func.isRequired,
  isModified: PropTypes.bool,
  role: PropTypes.string,
};

export default PaysheetTableHeader;
