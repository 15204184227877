import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Common';
import JiraProgress from 'components/Common/JiraProgress';
import { OK_STATUS } from 'utils/constants';

import { hasRights } from 'utils/permissions';
import { CLIENT_PERMISSIONS } from 'containers/ClientPage/constants';
import DealSelect from '../../DealSelect';
import { FILTER_TYPES } from '../../constants';
import styles from './styles.scss';

const TimelineTabsExtra = ({
  onChangeProjectFilter,
  clientProjects,
  onShowClientModal,
  filter,
  progress,
  onUpdateJiraProjects,
  role,
}) => (
  <div className={styles.timelineTabsExtra}>
    {hasRights(role, CLIENT_PERMISSIONS.updateProjects) && (
      <>
        <JiraProgress progress={progress} />
        <Button
          onClick={onUpdateJiraProjects}
          className={styles.updateButton}
          type="primary"
          loading={progress && progress.status !== OK_STATUS}
        >
          Update projects
        </Button>
      </>
    )}
    <Button onClick={onShowClientModal}>Add record</Button>
    {filter === FILTER_TYPES.deal.type && (
      <>
        <div className={styles.devider} />
        <DealSelect
          onChangeProjectFilter={onChangeProjectFilter}
          clientProjects={clientProjects}
        />
      </>
    )}
  </div>
);

TimelineTabsExtra.propTypes = {
  onChangeProjectFilter: PropTypes.func.isRequired,
  onShowClientModal: PropTypes.func.isRequired,
  clientProjects: PropTypes.array,
  filter: PropTypes.string,
  role: PropTypes.string,
  progress: PropTypes.object,
  onUpdateJiraProjects: PropTypes.func.isRequired,
};

export default TimelineTabsExtra;
