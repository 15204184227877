export const INFO_KEYS = [
  'ga_client_id',
  'ga_session_id',
  'ga_session_date',
  'ga_device_category',
  'ga_platform',
  'ga_data_source',
  'ga_campaign',
  'ga_keyword',
  'ga_hostname',
  'ga_landing_page_path',
  'ga_medium',
  'ga_source',
  'channel',
];

export const LEAD_INFO_OBJECT = {
  ga_client_id: {
    title: 'Client ID',
    description: 'description',
  },
  ga_session_date: {
    title: 'Last visit',
    description: 'description',
  },
  ga_session_id: {
    title: 'Session id',
    description: 'description',
  },
  ga_device_category: {
    title: 'Device type',
    description: 'description',
  },
  ga_platform: {
    title: 'Platform',
    description: 'description',
  },
  ga_data_source: {
    title: 'Device platform',
    description: 'description',
  },
  channel: { title: 'Channel', description: 'description' },
  ga_source: {
    title: 'Source or channel',
    description: 'description',
  },
  ga_campaign: {
    title: 'Campaign',
    description: 'description',
  },
  ga_keyword: {
    title: 'Keyword',
    description: 'description',
  },
  ga_landing_page_path: {
    title: 'Landing page path',
    description: 'description',
  },
  ga_medium: {
    title: 'Medium',
    description: 'description',
  },
  ga_hostname: {
    title: 'Host name',
    description: 'description',
  },
};
