import { formatNumberWithCommas, getMonthNameWithYear } from 'utils/helpers';
import { AER_LABELS } from './constants';

export const formatterWithUsd = value => `${formatNumberWithCommas(value)} USD`;

export const getAERTotalConfig = data => ({
  data,
  xField: 'month',
  yField: 'value',
  seriesField: 'type',
  heigth: '300px',
  xAxis: {
    label: {
      formatter: getMonthNameWithYear,
    },
  },
  yAxis: {
    label: {
      formatter: formatterWithUsd,
    },
  },
  tooltip: {
    formatter: datum => ({
      name: datum.type,
      value: formatterWithUsd(datum.value),
    }),
  },
  legend: {
    maxRow: 3,
    flipPage: false,
  },
  lineStyle: ({ type }) =>
    type === AER_LABELS.AVG ? { lineDash: [5, 5] } : {},
});
