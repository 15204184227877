import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Dropdown, Tooltip } from 'antd';

import {
  HOLIDAYS_IMMUTABLE,
  HOLIDAY_COMMON,
  HOLIDAY_TYPES,
} from 'containers/CalendarPage/constants';
import { DATE_FORMAT, HH_mm, HH_mm_zz } from 'utils/timeConstants';

import { getHolidayTitle, getSortedHolidays } from './utils';
import styles from './styles.scss';

const CalendarDataCell = ({
  calendarObject,
  onAddHoliday,
  onEditHoliday,
  onDeleteHoliday,
  canUpdate,
}) => value => {
  const currentData = calendarObject[value.format(DATE_FORMAT)] || {};

  const onAdd = () => {
    onAddHoliday(value);
  };

  const sortedHolidays = getSortedHolidays(currentData?.holidays);

  const getDropdownItems = holiday => [
    { label: 'Edit', key: 'edit', onClick: () => onEditHoliday(holiday) },
    { label: 'Delete', key: 'delete', onClick: () => onDeleteHoliday(holiday) },
  ];

  const isToday = dayjs().format(DATE_FORMAT) === value.format(DATE_FORMAT);

  return (
    <div
      className={cx([
        styles.cell,
        {
          [styles.dayOff]: currentData.is_day_off,
          [styles.resetSelected]: !isToday,
        },
      ])}
    >
      {sortedHolidays.map(holiday => {
        const title = getHolidayTitle(holiday, value);

        return (
          <Dropdown
            menu={{ items: getDropdownItems(holiday) }}
            trigger={['click']}
            disabled={
              HOLIDAYS_IMMUTABLE.includes(
                holiday[HOLIDAY_COMMON.holiday_type],
              ) || !canUpdate
            }
          >
            <Tooltip
              title={
                <>
                  <div>{title}</div>
                  <div>{holiday.description}</div>
                </>
              }
            >
              <div
                className={styles.holiday}
                style={{
                  background: HOLIDAY_TYPES[holiday.holiday_type].color,
                }}
              >
                {holiday.holiday_time &&
                  `${dayjs(holiday.holiday_time, HH_mm_zz).format(HH_mm)} `}
                {title}
              </div>
            </Tooltip>
          </Dropdown>
        );
      })}
      {canUpdate && (
        <button className={styles.addButton} onClick={onAdd} type="button">
          + Add holiday
        </button>
      )}
    </div>
  );
};

CalendarDataCell.propTypes = {
  calendar: PropTypes.array,
  today: PropTypes.string,
};
export default CalendarDataCell;
