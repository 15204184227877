import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { get } from 'lodash';

const { Option } = Select;

const TeamSelect = ({ data = [], width = '100%', onChange, value = '' }) => {
  const filterOption = useCallback(
    (input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    [],
  );

  return (
    <Select
      showSearch
      style={{ width }}
      onChange={onChange}
      optionFilterProp="children"
      filterOption={filterOption}
      value={value}
    >
      {data.map(item => (
        <Option key={item.id} value={item.id}>
          {get(item, 'title')}
        </Option>
      ))}
    </Select>
  );
};
TeamSelect.propTypes = {
  data: PropTypes.array,
  width: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default TeamSelect;
