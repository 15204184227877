import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import styles from '../styles.scss';

const DealPopoverContent = ({
  clientProjects,
  onChangeCheckbox,
  projectFilter,
}) => (
  <div className={styles.dealsFilter}>
    {Array.isArray(clientProjects) &&
      clientProjects.map(project => (
        <Checkbox
          onChange={onChangeCheckbox}
          value={project.id}
          checked={projectFilter.find(elem => elem === project.id)}
        >
          {project.name}
        </Checkbox>
      ))}
  </div>
);
DealPopoverContent.propTypes = {
  onChangeCheckbox: PropTypes.func,
  clientProjects: PropTypes.array,
  projectFilter: PropTypes.array,
};

export default DealPopoverContent;
