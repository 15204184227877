/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useMemo } from 'react';
import { Select, Avatar } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { get, uniqBy } from 'lodash';

import { Button } from 'components/Common';

import { hasRights } from 'utils/permissions';
import { PROFILES_PERMISSIONS } from 'containers/ProfilesPage/constants';
import styles from './styles.scss';

const { Option } = Select;

const ResourceManagementSelect = ({
  summaryEmployees = [],
  userRm,
  onChangeResourceManager,
  onUpdateResourceManager,
  onHideRmSelect,
  isSummary,
  role,
  resourceManager,
}) => {
  const [showRmSelect, setShowRmSelect] = useState(false);

  const canUpdateRm = hasRights(role, PROFILES_PERMISSIONS.updateRm);

  const updatedEmployees = useMemo(
    () => uniqBy([...summaryEmployees, resourceManager], 'id'),
    [resourceManager, summaryEmployees],
  );

  const onShowRmSelect = () => {
    if (isSummary || !canUpdateRm) return;

    setShowRmSelect(true);
  };

  const onHide = () => {
    onHideRmSelect();
    setShowRmSelect(false);
  };

  const userRmInfo = useMemo(
    () => updatedEmployees.find(item => item?.id === userRm),
    [updatedEmployees, userRm],
  );

  const isDisplayEditRm = !showRmSelect && !isSummary && canUpdateRm;

  return (
    <div className={styles.userResourceManager}>
      <div className={styles.resourceManagerHeader}>
        <h3 className={styles.title}>Resource Manager</h3>
        {showRmSelect && (
          <div className={styles.actionButtons}>
            <Button size="small" onClick={onHide}>
              Cancel
            </Button>
            <Button size="small" onClick={onUpdateResourceManager}>
              Save
            </Button>
          </div>
        )}
      </div>
      {isDisplayEditRm && (
        <EditOutlined className={styles.editIcon} onClick={onShowRmSelect} />
      )}
      {!showRmSelect && userRmInfo && (
        <div onClick={onShowRmSelect} className={styles.userInfo}>
          <Avatar
            className={styles.avatar}
            shape="square"
            size={24}
            src={get(
              userRmInfo,
              ['jira_user', 'avatarUrls', '32x32'],
              'https://www.gravatar.com/avatar/e5ffa8aecb9a36a76676407d8bfd1d36?d=mm&s=32',
            )}
          />
          {get(userRmInfo, 'name_en', '')}
        </div>
      )}
      {showRmSelect && !isSummary && (
        <Select
          showSearch
          placeholder="Select a person"
          optionFilterProp="children"
          defaultValue={userRm}
          onChange={onChangeResourceManager}
          className={styles.select}
        >
          {updatedEmployees.map(item => (
            <Option
              key={get(item, ['jira_user', 'id'])}
              value={get(item, 'id')}
            >
              <Avatar
                style={{ marginRight: '10px' }}
                shape="square"
                size={20}
                src={get(item, ['jira_user', 'avatarUrls', '32x32'])}
              />
              {get(item, 'name_en')}
              <span style={{ fontWeight: 600 }}>
                ({get(item, ['jira_user', 'id'])})
              </span>
            </Option>
          ))}
        </Select>
      )}
    </div>
  );
};

ResourceManagementSelect.propTypes = {
  summaryEmployees: PropTypes.array,
  userRm: PropTypes.number,
  isSummary: PropTypes.bool,
  onChangeResourceManager: PropTypes.func,
  onUpdateResourceManager: PropTypes.func,
  onHideRmSelect: PropTypes.func,
  role: PropTypes.string,
  resourceManager: PropTypes.object,
};

export default ResourceManagementSelect;
