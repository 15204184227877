import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Common';
import styles from './styles.scss';

const BuddyHeader = ({
  showBuddySelect,
  isSaveBuddyVisible,
  onHide,
  onUpdateBuddy,
}) => (
  <div className={styles.buddyHeader}>
    <h3 className={styles.title}>Buddy</h3>
    <div className={styles.actionButtons}>
      {showBuddySelect && (
        <Button size="small" onClick={onHide}>
          Cancel
        </Button>
      )}
      {isSaveBuddyVisible && (
        <Button size="small" onClick={onUpdateBuddy}>
          Save
        </Button>
      )}
    </div>
  </div>
);

BuddyHeader.propTypes = {
  showBuddySelect: PropTypes.bool.isRequired,
  isSaveBuddyVisible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onUpdateBuddy: PropTypes.func.isRequired,
};

export default BuddyHeader;
