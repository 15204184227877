/* eslint-disable indent */
import React, { useState, useEffect, memo } from 'react';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { Tooltip, Dropdown, Menu, Modal } from 'antd';
import { Comment } from '@ant-design/compatible';
import PropTypes from 'prop-types';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { MoreOutlined } from '@ant-design/icons';

import { PROFILES_PERMISSIONS } from 'containers/ProfilesPage/constants';
import { Button } from 'components/Common';
import { hasRights } from 'utils/permissions';

import CommentEditor from '../CommentEditor';
import { getInfoAboutAuthor } from './utils';
import styles from './styles.scss';

const CommentCommon = ({
  comment,
  onCommentDelete,
  onSaveEditedComment,
  role,
  authUser,
  employees,
  isSummary,
  isWard,
}) => {
  const [isCommentEdit, setIsCommentEdit] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentComment, setCurrentComment] = useState(null);
  const [authorInfo, setAuthorInfo] = useState(null);
  const [time, setTime] = useState('');
  const onClickEdit = () => setIsCommentEdit(prev => !prev);

  const onSave = () => {
    onSaveEditedComment(
      stateToHTML(currentComment.getCurrentContent()),
      get(comment, 'id'),
    );
    setIsCommentEdit(false);
  };

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => {
    setIsModalVisible(false);
    onCommentDelete(get(comment, 'id'));
  };

  const handleCancel = () => setIsModalVisible(false);

  useEffect(() => {
    setTime(dayjs(get(comment, 'updated_at')).fromNow());
    const contentState = stateFromHTML(get(comment, 'text'));
    setCurrentComment(EditorState.createWithContent(contentState));
  }, [comment]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(dayjs(get(comment, 'updated_at')).fromNow());
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setAuthorInfo(
      getInfoAboutAuthor({
        employees,
        email: get(comment, 'author_email'),
        name: get(comment, 'author_name'),
      }),
    );
  }, [comment, employees]);

  const isDisplayCommentActions =
    (hasRights(role, PROFILES_PERMISSIONS.deleteComment) || isWard) &&
    get(authUser, 'id') === get(comment, 'author_id') &&
    !isSummary;

  return (
    <>
      <Modal
        title="Delete comment"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        Are you sure you want to delete this comment?
      </Modal>
      <Comment
        className={styles.comment}
        author={authorInfo}
        content={
          isCommentEdit ? (
            <CommentEditor
              comment={currentComment}
              setComment={setCurrentComment}
            />
          ) : (
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: get(comment, ['text'], '') }}
            />
          )
        }
        datetime={
          <Tooltip
            title={dayjs(new Date(get(comment, 'updated_at'))).format(
              'YYYY-MM-DD HH:mm:ss a',
            )}
          >
            <span className={styles.time}> ∘ {time}</span>
          </Tooltip>
        }
        actions={[
          isDisplayCommentActions && (
            <Dropdown
              className={styles.dropdown}
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={showModal}>
                    Delete
                  </Menu.Item>
                  <Menu.Item key="2" onClick={onClickEdit}>
                    Edit
                  </Menu.Item>
                </Menu>
              }
            >
              <MoreOutlined className={styles.more} />
            </Dropdown>
          ),
          <div className={styles.buttons}>
            {isCommentEdit && (
              <>
                <Button size="small" onClick={onSave}>
                  Save
                </Button>
                <Button
                  size="small"
                  style={{ marginLeft: 10 }}
                  onClick={onClickEdit}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>,
        ]}
      />
    </>
  );
};

CommentCommon.propTypes = {
  comment: PropTypes.object,
  authUser: PropTypes.object,
  employees: PropTypes.array,
  onCommentDelete: PropTypes.func,
  onSaveEditedComment: PropTypes.func,
  role: PropTypes.string,
  isSummary: PropTypes.bool,
  isWard: PropTypes.bool,
};

export default memo(CommentCommon);
