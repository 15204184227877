import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';

import Tag from 'components/Common/Tag';
import TechnologiesSelect from 'containers/TechnologiesPage/TechnologiesSelect/TechnologiesSelect';

import { hasRights } from 'utils/permissions';
import { PROFILES_PERMISSIONS } from 'containers/ProfilesPage/constants';
import { Button } from 'components/Common';
import styles from './styles.scss';

const UserSkills = ({ skills, id, role, isSummary }) => {
  const [isEditing, setIsEditing] = useState(false);

  const toggleEditing = () => {
    setIsEditing(prev => !prev);
  };

  return (
    <div className={styles.userSkills}>
      <div className={styles.header}>
        <h3 className={styles.title}>Technologies</h3>
        {hasRights(role, PROFILES_PERMISSIONS.updateTechnologies) &&
          !isSummary &&
          !isEditing && (
            <EditOutlined className={styles.editIcon} onClick={toggleEditing} />
          )}
        <div className={styles.actionButtons}>
          {isEditing && (
            <Button size="small" onClick={toggleEditing}>
              Cancel
            </Button>
          )}
          {isEditing && (
            <Button
              size="small"
              onClick={toggleEditing}
              className={styles.button}
            >
              Save
            </Button>
          )}
        </div>
      </div>
      {isEditing ? (
        <TechnologiesSelect currentEmpoyeeId={id} isProfilePage />
      ) : (
        skills?.map(item => (
          <Tag className={styles.skillsTag} key={item.key}>
            {item.title}
          </Tag>
        ))
      )}
    </div>
  );
};

UserSkills.propTypes = {
  skills: PropTypes.array,
  id: PropTypes.number,
  role: PropTypes.string,
  isSummary: PropTypes.bool,
};

export default UserSkills;
