import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Avatar, Checkbox, Select } from 'antd';
import { isEmpty } from 'lodash';

import { BEGIN_MONTH_FORMAT, DATE_FORMAT } from 'utils/timeConstants';
import {
  formatNumberWithCommas,
  getPopupContainerHandler,
} from 'utils/helpers';

import { ALLOCATIONS_TABLE_TYPES } from './constants';
import { getConditionPreview } from '../../ClientAllocationTable/utils';

import styles from './styles.scss';

const getDateTo = (acc, period) =>
  !acc.length
    ? dayjs(period)
        .endOf('M')
        .format(DATE_FORMAT)
    : dayjs(acc[acc.length - 1].dateFrom)
        .subtract(1, 'days')
        .format(DATE_FORMAT);

// Формирует сделки в зависимости от их порядка для каждого проекта.
// В каждую сделку добавляются вычисляемые свойства dateFrom, dateTo.
// Более новые сделки перекрывают собой старые, даже если у них нет даты завершения.
export const formatDeals = ({ deals, period }) => {
  let formattedDeals = {};
  deals &&
    Object.entries(deals).forEach(([projectId, projectDeals]) => {
      let dealsStartedBefore;
      const dealsStartedAfter = [];

      projectDeals?.forEach(projectDeal => {
        if (dayjs(projectDeal.starts_at).isAfter(period, 'D')) {
          dealsStartedAfter.push(projectDeal);

          return;
        }

        if (!dayjs(projectDeal.starts_at).isAfter(period)) {
          if (
            dayjs(projectDeal.created_at).isAfter(
              dealsStartedBefore?.created_at,
              'minute',
            ) ||
            !dealsStartedBefore
          ) {
            dealsStartedBefore = projectDeal;
          }
        }
      });

      const sortedDeals = (dealsStartedBefore
        ? [dealsStartedBefore, ...dealsStartedAfter]
        : dealsStartedAfter
      ).sort((a, b) => (dayjs(a.starts_at).isBefore(b.starts_at) ? 1 : -1));

      const monthEnd = dayjs(period)
        .endOf('M')
        .format(DATE_FORMAT);

      const dealsWithPeriod = sortedDeals
        .reduce((acc, deal) => {
          const dateFrom = !dayjs(deal.starts_at).isAfter(period)
            ? dayjs(period).format(BEGIN_MONTH_FORMAT)
            : deal.starts_at;
          const dateTo =
            deal.ends_at && dayjs(deal.ends_at).isBefore(monthEnd)
              ? deal.ends_at
              : getDateTo(acc, period);

          return [...acc, { ...deal, dateFrom, dateTo }];
        }, [])
        .reverse();
      formattedDeals[projectId] = dealsWithPeriod;
    });

  return formattedDeals;
};

export const getInvoiceAllocations = deals => {
  let invoiceAllocations = {};

  Object.entries(deals).forEach(([projectId, projectDeals]) => {
    projectDeals.forEach(deal => {
      const allocationsWithPeriod =
        deal.deal_allocations?.map(allocation => ({
          ...allocation,
          period_from: deal.dateFrom,
          period_to: deal.dateTo,
        })) || [];
      invoiceAllocations[projectId] = invoiceAllocations[projectId]
        ? [...invoiceAllocations[projectId], ...allocationsWithPeriod]
        : allocationsWithPeriod;
    });
  });

  return invoiceAllocations;
};

//  Пожалуй главный костыль в инвойсинге.
// Добавляет аллокейшены из удаленных сделок, но которые все еще используются в редактируемом инвойсе
export const getExistingInvoiceAllocations = ({
  deals,
  clientAllocations,
  invoiceDataAllocations,
}) => {
  const invoiceAllocations = getInvoiceAllocations(deals);
  const flattenInvoiceAllocations = [];

  for (let projectId in invoiceAllocations) {
    invoiceAllocations[projectId].forEach(allocation => {
      flattenInvoiceAllocations.push(allocation);
    });
  }

  invoiceDataAllocations.forEach(allocation => {
    const invoiceAllocation = flattenInvoiceAllocations.find(
      al => al.id === allocation.allocation_id,
    );

    if (!invoiceAllocation) {
      const fullAllocation = clientAllocations.find(
        al => al.id === allocation.allocation_id,
      );

      if (!fullAllocation) return;

      const missingAllocation = {
        ...fullAllocation,
        ...allocation,
        period_from: dayjs(allocation.period_from).format(DATE_FORMAT),
        period_to: dayjs(allocation.period_to).format(DATE_FORMAT),
      };
      invoiceAllocations[allocation.project_id]?.push(missingAllocation);
      flattenInvoiceAllocations.push(missingAllocation);
    }
  });

  return invoiceAllocations;
};

// Формирует массив для таблицы Allocations при создании инвойса
export const getDataSource = ({
  summaryWorklogs,
  dealAllocations,
  projects,
  invoiceProjects,
  invoiceDate,
  isAllocationsStepLoading,
  projectActors,
}) => {
  if (
    isAllocationsStepLoading ||
    isEmpty(summaryWorklogs) ||
    isEmpty(projectActors)
  )
    return {
      dataSource: [],
      invoiceAllocations: {},
      dataSourceMap: {},
      loggedAllocations: [],
    };

  const period = dayjs(invoiceDate).format(BEGIN_MONTH_FORMAT);

  const formattedDeals = formatDeals({
    deals: dealAllocations,
    period,
  });

  const invoiceAllocations = getInvoiceAllocations(formattedDeals);
  let dataSourceMap = {};
  const loggedAllocations = [];

  const dataSource = invoiceProjects.map((projectId, projectIndex) => {
    const currentProject = projects.find(project => project.id === projectId);
    let spentSeconds = 0;
    let spentHours = 0;

    // Формирует sourcemap данных для оптимизации.
    dataSourceMap = {
      ...dataSourceMap,
      [projectId]: {
        index: projectIndex,
        workers: {},
      },
    };

    const children = summaryWorklogs[projectId]?.map((actor, actorIndex) => {
      spentSeconds += actor.spent_seconds;
      spentHours += actor.spent_hours;

      dataSourceMap[projectId].workers[actor.worker] = {
        index: actorIndex,
        deals: {},
      };

      return {
        name: actor.displayName,
        spentSeconds: actor.spent_seconds,
        spentHours: actor.spent_hours,
        type: ALLOCATIONS_TABLE_TYPES.ACTOR,
        firstKey: `first_key_${actorIndex}`,
        secondKey: `second_key_${actorIndex}`,
        thirdKey: `third_key_${actorIndex}`,
        children:
          formattedDeals[projectId]?.map((deal, dealIndex) => {
            dataSourceMap[projectId].workers[actor.worker].deals[deal.id] = {
              index: dealIndex,
            };

            const allocationsWithActors = deal.deal_allocations.map(
              allocation => {
                const role = projectActors[projectId].find(
                  role => role.id === allocation.jira_role_id,
                );

                return { ...allocation, actors: role?.actors };
              },
            );

            // Находит совпадения между ролями сотрудников и ролями в аллокейшенах и предзаполняет селекты.
            const prefilledAllocation = allocationsWithActors.find(allocation =>
              Boolean(
                allocation.actors.find(
                  allocationActor => allocationActor.jira_key === actor.worker,
                ),
              ),
            );

            if (prefilledAllocation) {
              loggedAllocations.push({
                ...prefilledAllocation,
                period_from: deal.dateFrom,
                period_to: deal.dateTo,
                project_id: projectId,
                worker: actor.worker,
                deal_id: deal.id,
              });
            }

            return {
              type: ALLOCATIONS_TABLE_TYPES.DEAL,
              allocations: deal.deal_allocations,
              dateFrom: deal.dateFrom,
              dateTo: deal.dateTo,
              projectId: projectId,
              worker: actor.worker,
              dealId: deal.id,
              selectedAllocation: prefilledAllocation?.id || '',
              firstKey: `first_key_${actorIndex}_${deal.id}`,
              secondKey: `second_key_${actorIndex}_${deal.id}`,
              thirdKey: `third_key_${actorIndex}_${deal.id}`,
            };
          }) || [],
      };
    });

    return {
      projectId,
      name: currentProject?.name,
      projectKey: currentProject?.key,
      key: currentProject?.key,
      spentSeconds,
      spentHours,
      type: ALLOCATIONS_TABLE_TYPES.PROJECT,
      children,
      firstKey: `first_key_${projectId}`,
      secondKey: `second_key_${projectId}`,
      thirdKey: `third_key_${projectId}`,
    };
  });

  return {
    dataSource,
    invoiceAllocations,
    dataSourceMap,
    loggedAllocations,
  };
};

const renderProjectCell = value => (
  <span className={styles.projectCell}>{value}</span>
);

const renderActor = (value, record) => (
  <span>
    <Avatar size={22} src={record.avatarUrl} className={styles.actorAvatar} />
    <span>{value}</span>
  </span>
);

const renderDealPeriod = ({ onChangeCheckbox, record }) => (
  <span>
    <Checkbox
      className={styles.allocationCheckbox}
      onChange={() => onChangeCheckbox(record)}
      checked={Boolean(record.selectedAllocation)}
      disabled={!record.selectedAllocation}
    />
    <span>{`${record.dateFrom} -> ${record.dateTo}`}</span>
  </span>
);

renderDealPeriod.propTypes = {
  onChangeCheckbox: PropTypes.func,
  record: PropTypes.object,
};

const renderAllocationSelect = ({
  allocationHandler,
  allocations,
  roles,
  selectedAllocation,
}) => (
  <Select
    onChange={value =>
      allocationHandler(allocations.find(allocation => allocation.id === value))
    }
    value={selectedAllocation}
    className={styles.allocationSelect}
    getPopupContainer={getPopupContainerHandler}
  >
    {allocations.map(
      ({
        jira_role_id,
        rate,
        currency_code,
        hours_per_week,
        condition,
        id,
      }) => {
        // Refactor: использовать объект ролей
        const roleName = roles.find(role => role.id === jira_role_id)?.name;
        const formattedCondition = getConditionPreview(condition);

        return (
          <Select.Option
            key={id}
            value={id}
          >{`${roleName} / ${currency_code} ${rate} ${formattedCondition} ${hours_per_week} h/week`}</Select.Option>
        );
      },
    )}
  </Select>
);

renderAllocationSelect.propTypes = {
  allocationHandler: PropTypes.func,
  allocations: PropTypes.array,
  roles: PropTypes.array,
  selectedAllocation: PropTypes.object,
};

const renderNameColumn = ({ value, record, onChangeCheckbox }) => {
  switch (record.type) {
    case ALLOCATIONS_TABLE_TYPES.PROJECT:
      return renderProjectCell(value);
    case ALLOCATIONS_TABLE_TYPES.ACTOR:
      return renderActor(value, record);
    case ALLOCATIONS_TABLE_TYPES.DEAL:
      return renderDealPeriod({ onChangeCheckbox, record });
    default:
      return value;
  }
};

const renderKeyColumn = ({ value, record, allocationHandler, roles }) => {
  switch (record.type) {
    case ALLOCATIONS_TABLE_TYPES.PROJECT:
      return renderProjectCell(value);
    case ALLOCATIONS_TABLE_TYPES.DEAL:
      return renderAllocationSelect({
        allocationHandler,
        allocations: record.allocations,
        selectedAllocation: record.selectedAllocation,
        roles,
      });
    default:
      return value;
  }
};

const renderLoggedColumns = record => {
  const { spentSeconds, spentHours } = record;
  const logged = `${formatNumberWithCommas(
    spentSeconds ? spentSeconds / 3600 : spentHours / 3600,
  )} h`;

  switch (record.type) {
    case ALLOCATIONS_TABLE_TYPES.PROJECT:
      return renderProjectCell(logged);
    case ALLOCATIONS_TABLE_TYPES.ACTOR:
      return logged;
    default:
      return '';
  }
};

export const getColumns = ({ roles, onSelectAllocation, onChangeCheckbox }) => [
  {
    title: 'Project name',
    dataIndex: 'name',
    key: 'firstKey',
    width: '35%',
    render: (value, record) => ({
      props: {
        style: {
          paddingLeft:
            record.type === ALLOCATIONS_TABLE_TYPES.PROJECT ? '0' : '35px',
          background:
            record.type !== ALLOCATIONS_TABLE_TYPES.PROJECT && '#fafafa',
        },
      },
      children: renderNameColumn({ value, record, onChangeCheckbox }),
    }),
  },
  {
    title: 'Key',
    dataIndex: 'projectKey',
    key: 'secondKey',
    render: (value, record) => {
      const allocationHandler = allocation => {
        onSelectAllocation({
          ...allocation,
          period_from: record.dateFrom,
          period_to: record.dateTo,
          project_id: record.projectId,
          worker: record.worker,
          deal_id: record.dealId,
        });
      };

      return {
        props: {
          style: {
            background:
              record.type !== ALLOCATIONS_TABLE_TYPES.PROJECT && '#fafafa',
          },
        },
        children: renderKeyColumn({ value, record, allocationHandler, roles }),
      };
    },
  },
  {
    title: 'Logged',
    dataIndex: 'logged',
    key: 'thirdKey',
    width: '15%',
    render: (value, record) => ({
      props: {
        style: {
          background:
            record.type !== ALLOCATIONS_TABLE_TYPES.PROJECT && '#fafafa',
        },
      },
      children: renderLoggedColumns(record),
    }),
  },
];

export const getDataSourceIndexes = ({
  dataSourceMap,
  projectId,
  worker,
  dealId,
}) => {
  const projectIndex = dataSourceMap[projectId].index;
  const workerIndex = dataSourceMap[projectId].workers[worker].index;
  const dealIndex =
    dataSourceMap[projectId].workers[worker].deals[dealId].index;

  return {
    projectIndex,
    workerIndex,
    dealIndex,
  };
};

export const getEditDataSource = ({
  summaryWorklogs,
  projects,
  invoiceProjects,
  invoiceDate,
  isAllocationsStepLoading,
  dealAllocations,
  loggedAllocations,
  invoiceAllocations,
}) => {
  if (isAllocationsStepLoading)
    return {
      dataSource: [],
      dataSourceMap: {},
    };

  const period = dayjs(invoiceDate).format(BEGIN_MONTH_FORMAT);
  const formattedDeals = formatDeals({
    deals: dealAllocations,
    period,
  });

  let dataSourceMap = {};

  const dataSource = invoiceProjects.map((projectId, projectIndex) => {
    const currentProject = projects.find(project => project.id === projectId);
    let spentSeconds = 0;
    let spentHours = 0;

    dataSourceMap = {
      ...dataSourceMap,
      [projectId]: {
        index: projectIndex,
        workers: {},
      },
    };

    const children = summaryWorklogs[projectId]?.map((actor, actorIndex) => {
      spentSeconds += actor.spent_seconds;
      spentHours += actor.spent_hours;

      dataSourceMap[projectId].workers[actor.worker] = {
        index: actorIndex,
        deals: {},
      };

      return {
        name: actor.displayName,
        spentSeconds: actor.spent_seconds,
        spentHours: actor.spent_hours,
        type: ALLOCATIONS_TABLE_TYPES.ACTOR,
        firstKey: `first_key_${actorIndex}`,
        secondKey: `second_key_${actorIndex}`,
        thirdKey: `third_key_${actorIndex}`,
        children: formattedDeals[projectId].map((deal, dealIndex) => {
          dataSourceMap[projectId].workers[actor.worker].deals[deal.id] = {
            index: dealIndex,
          };
          const prefilledAllocation =
            invoiceAllocations[projectId]?.filter(al =>
              loggedAllocations.some(
                ({ id, worker }) => id === al.id && worker === actor.worker,
              ),
            ) || [];

          return {
            type: ALLOCATIONS_TABLE_TYPES.DEAL,
            allocations: invoiceAllocations[projectId].filter(
              allocation => allocation.deal_id === deal.id,
            ),
            dateFrom: deal.dateFrom,
            dateTo: deal.dateTo,
            projectId: projectId,
            worker: actor.worker,
            dealId: deal.id,
            selectedAllocation: prefilledAllocation.length
              ? prefilledAllocation[0]?.id
              : '',
            firstKey: `first_key_${actorIndex}_${deal.id}`,
            secondKey: `second_key_${actorIndex}_${deal.id}`,
            thirdKey: `third_key_${actorIndex}_${deal.id}`,
          };
        }),
      };
    });

    return {
      projectId,
      name: currentProject?.name,
      projectKey: currentProject?.key,
      key: currentProject?.key,
      spentSeconds,
      spentHours,
      type: ALLOCATIONS_TABLE_TYPES.PROJECT,
      children,
      firstKey: `first_key_${projectId}`,
      secondKey: `second_key_${projectId}`,
      thirdKey: `third_key_${projectId}`,
    };
  });

  return {
    dataSource,
    dataSourceMap,
  };
};
