import { actionFactory } from 'redux/factories';
import { types } from './types';

export const loadClients = actionFactory(types.LOAD_CLIENTS);
export const createClient = actionFactory(types.CREATE_CLIENT);
export const deleteClient = actionFactory(types.DELETE_CLIENT);
export const restoreClient = actionFactory(types.RESTORE_CLIENT);
export const updateClient = actionFactory(types.UPDATE_CLIENT);
export const getClientsFilter = actionFactory(types.GET_CLIENTS_FILTERS);
export const getClient = actionFactory(types.GET_CLIENT);
export const getClientProjects = actionFactory(types.GET_CLIENT_PROJECTS);
export const addClientOrganization = actionFactory(
  types.ADD_CLIENT_ORGANIZATION,
);
export const getClientAllocations = actionFactory(types.GET_CLIENT_ALLOCATIONS);

export const getClientInvoices = actionFactory(types.GET_CLIENT_INVOICES);
export const getClientInvoice = actionFactory(types.GET_CLIENT_INVOICE);
export const addClientInvoice = actionFactory(types.ADD_CLIENT_INVOICE);
export const deleteClientInvoice = actionFactory(types.DELETE_CLIENT_INVOICE);
export const updateClientInvoice = actionFactory(types.UPDATE_CLIENT_INVOICE);

export const getClientAudit = actionFactory(types.GET_CLIENT_AUDIT);

export const getClientContracts = actionFactory(types.GET_CLIENT_CONTRACTS);
export const updateClientContract = actionFactory(types.UPDATE_CLIENT_CONTRACT);

export const saveClientNote = actionFactory(types.SAVE_CLIENT_NOTE);
export const updateClientNote = actionFactory(types.UPDATE_CLIENT_NOTE);
export const deleteClientNote = actionFactory(types.DELETE_CLIENT_NOTE);
export const getClientDeals = actionFactory(types.GET_CLIENT_DEALS);
export const createClientDeal = actionFactory(types.CREATE_CLIENT_DEAL);
export const updateClientDeal = actionFactory(types.UPDATE_CLIENT_DEAL);
export const deleteClientDeal = actionFactory(types.DELETE_CLIENT_DEAL);

export const clearResponseStatus = actionFactory(types.CLEAR_RESPONSE);
export const getAllocationsStepData = actionFactory(
  types.GET_ALLOCATIONS_STEP_DATA,
);
