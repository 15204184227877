exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dealsFilter-c8065{display:flex;flex-direction:column;min-width:200px}.dealsFilter-c8065 label:first-child{margin-left:8px}.popoverTitle-1be41{display:flex;justify-content:space-between;width:100%;margin:5px}.popoverDealButtonStyleContainer-babe9{width:calc(100% + 32px);margin-left:-16px;margin-top:10px;border-top:1px solid #f0f0f0}.dealButtons-6d20d{margin:7px 0 0 16px;display:flex;justify-content:space-between}.timelineFilter-03c58{margin-left:12px;padding:12px;border:1px solid #d9d9d9;border-radius:8px;line-height:initial}", ""]);

// Exports
exports.locals = {
	"dealsFilter": "dealsFilter-c8065",
	"popoverTitle": "popoverTitle-1be41",
	"popoverDealButtonStyleContainer": "popoverDealButtonStyleContainer-babe9",
	"dealButtons": "dealButtons-6d20d",
	"timelineFilter": "timelineFilter-03c58"
};