import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isTechnologiesLoading: false,
  technologies: [],
  users: [],
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_TECHNOLOGIES:
    case types.ADD_TECHNOLOGY:
    case types.UPDATE_TECHNOLOGY:
    case types.DELETE_TECHNOLOGY: {
      return state.set('isTechnologiesLoading', true).set('users', []);
    }
    case types.DELETE_TECHNOLOGY_ERRORS: {
      return state.set('users', []);
    }

    case types.GET_TECHNOLOGIES_SUCCESS: {
      return state
        .set('technologies', payload)
        .set('isTechnologiesLoading', false);
    }
    case types.ADD_TECHNOLOGY_SUCCESS: {
      return state
        .set('technologies', [...state.get('technologies'), payload])
        .set('isTechnologiesLoading', false);
    }

    case types.ADD_TECHNOLOGY_FAILED:
    case types.GET_TECHNOLOGIES_FAILED:
    case types.UPDATE_TECHNOLOGY_FAILED:
    case types.DELETE_TECHNOLOGY_FAILED: {
      return state.set('isTechnologiesLoading', false).set('users', payload);
    }
    default:
      return state;
  }
}
