import React, { useEffect, useState } from 'react';
import { Modal, Row, Form, Calendar } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import PageHeader from 'components/Common/PageHeader';
import { Loader } from 'components/Common';
import DatePicker from 'components/Common/DatePicker';
import { ExchangeRatesForm } from 'components/ExchangeRatesCommon';
import { DATE_FORMAT, MONTH_FORMAT } from 'utils/timeConstants';
import { exchangeRatesSelector } from 'redux/selectors';
import {
  createExchangeRates,
  editExchangeRates,
  getExchangeRates,
} from 'redux/ExchangeRates/actions';

import styles from './styles.scss';

const { useForm } = Form;

const formatDateForRequest = 'YYYY-MM-01';

const ExchangeRatesPage = () => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [isVisible, setIsVisible] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const [form] = useForm();

  const dispatch = useDispatch();

  const { exchangeRates, isExchangeRatesLoading } = useSelector(
    exchangeRatesSelector,
  );

  useEffect(() => {
    const period = dayjs(currentDate).format(formatDateForRequest);

    dispatch(
      getExchangeRates({
        period,
      }),
    );
  }, [currentDate]);

  const onSelectCell = value => {
    const selectedDate = value.format(DATE_FORMAT);

    const selectedData = exchangeRates.find(
      item => item.date === selectedDate,
    ) || {
      date: selectedDate,
      value: '',
    };
    setIsVisible(true);
    setSelectedData(selectedData);
    form.setFieldsValue(selectedData);
  };

  const onSubmit = values => {
    const formatedCurDate = dayjs(currentDate).format(formatDateForRequest);

    if (selectedData.value) {
      dispatch(
        editExchangeRates({
          ...selectedData,
          value: values.value,
          currentDate: formatedCurDate,
        }),
      );
    } else {
      dispatch(
        createExchangeRates({
          ...selectedData,
          value: values.value,
          currentDate: formatedCurDate,
        }),
      );
    }

    hideModal();
  };

  const hideModal = () => {
    setIsVisible(false);
    form.resetFields();
    setSelectedData({});
  };

  const dateCellRender = value => {
    const currentData =
      exchangeRates.length &&
      exchangeRates.find(item => item.date === value.format(DATE_FORMAT));

    return currentData ? (
      <p
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20 0 0 0',
        }}
      >
        {currentData.value}
      </p>
    ) : (
      ''
    );
  };

  return (
    <Loader loading={isExchangeRatesLoading}>
      <PageHeader title="Exchange rates" />
      <Modal
        className={styles.modal}
        title={`Exchange rate on ${selectedData.date}`}
        open={isVisible}
        onCancel={hideModal}
        onOk={form.submit}
        okText="Save"
        cancelText="Cancel"
      >
        <ExchangeRatesForm onSubmit={onSubmit} form={form} />
      </Modal>
      <Calendar
        dateCellRender={dateCellRender}
        onSelect={onSelectCell}
        value={currentDate}
        className={styles.calendar}
        headerRender={() => (
          <div style={{ padding: 10 }}>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexWrap: 'nowrap',
              }}
              gutter={8}
            >
              <DatePicker
                picker="month"
                allowClear={false}
                onChange={setCurrentDate}
                defaultValue={currentDate}
                format={MONTH_FORMAT}
              />
            </Row>
          </div>
        )}
      />
    </Loader>
  );
};

export default ExchangeRatesPage;
