import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isUsersLoading: false,
  users: [],
  currentUser: null,
  error: null,
  permissions: [],
  totalUsers: null,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_USERS:
    case types.CREATE_USER:
    case types.UPDATE_USER:
    case types.DELETE_USER:
      return state.set('isUsersLoading', true).set('error', null);
    case types.SET_CURRENT_USER: {
      return state.set('currentUser', payload);
    }

    case types.GET_USERS_SUCCESS:
      return state
        .set('users', payload.data)
        .set('isUsersLoading', false)
        .set('totalUsers', payload.total_data);
    case types.GET_USER_PERMISSIONS_SUCCESS:
      return state.set('permissions', payload);
    case types.CREATE_USER_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
    case types.DELETE_USER_SUCCESS:
      return state.set('isUsersLoading', false).set('users', payload.data);

    case types.GET_USERS_FAILED:
    case types.CREATE_USER_FAILED:
    case types.UPDATE_USER_FAILED:
    case types.DELETE_USER_FAILED:
      return state.set('isUsersLoading', false).set('error', payload.error);
    default:
      return state;
  }
}
