import React from 'react';
import { Select, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Loader } from 'components/Common/';
import DatePicker from 'components/Common/DatePicker';
import { getPopupContainerHandler } from 'utils/helpers';

import styles from './styles.scss';

const { Option } = Select;

const ClientInvoiceFirstStep = ({
  projects,
  isFullScreen,
  isCreateCustomInvoice,
  isInvoiceLoading,
  customInvoiceHandler,
  invoiceDate,
  invoiceProjects,
  setInvoiceProjects,
  setInvoiceDate,
}) => {
  const onSelectProjects = projects =>
    setInvoiceProjects(projects.map(project => project.toString()));

  return (
    <Loader loading={isInvoiceLoading}>
      <div
        className={classNames(
          styles.invoiceFirstStepContainer,
          isFullScreen
            ? styles.isFullScrenClientInvoice
            : styles.isNotFullScreenClientInvoice,
        )}
      >
        <div className={styles.invoiceDatePickerContainer}>
          <span>Select period</span>
          <DatePicker
            picker="month"
            getPopupContainer={getPopupContainerHandler}
            value={invoiceDate}
            onChange={setInvoiceDate}
          />
        </div>
        <div className={styles.invoiceProjectSelectContainer}>
          <span>Select projects</span>
          <Select
            mode="multiple"
            className={styles.projectSelect}
            placeholder="Projects"
            disabled={isCreateCustomInvoice}
            getPopupContainer={getPopupContainerHandler}
            value={invoiceProjects}
            onChange={onSelectProjects}
          >
            {projects.map(project => (
              <Option key={project.id} value={project.id}>
                {project.key} / {project.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className={styles.clientInvoiceFirstStepMarginTop}>
          <Checkbox
            checked={isCreateCustomInvoice}
            onChange={customInvoiceHandler}
          >
            Freeform Invoice
          </Checkbox>
        </div>
      </div>
    </Loader>
  );
};

ClientInvoiceFirstStep.propTypes = {
  projects: PropTypes.array.isRequired,
  invoiceProjects: PropTypes.array.isRequired,
  invoiceDate: PropTypes.object.isRequired,
  setInvoiceDate: PropTypes.func.isRequired,
  setInvoiceProjects: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  isCreateCustomInvoice: PropTypes.bool.isRequired,
  isInvoiceLoading: PropTypes.bool.isRequired,
  customInvoiceHandler: PropTypes.func.isRequired,
};

export default ClientInvoiceFirstStep;
