import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, Button } from 'antd';

import DealPopoverCommon from './DealPopoverCommon';
import DealPopoverContent from './DealPopoverContent';
import styles from './styles.scss';

const DealSelect = ({ onChangeProjectFilter, clientProjects }) => {
  const [dealProjects, setDealProjects] = useState([]);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleVisibleChange = () => setIsPopoverVisible(prev => !prev);

  const onChangeCheckbox = e => {
    setDealProjects(prev =>
      e?.target?.checked
        ? [...prev, e.target.value]
        : prev.filter(item => item !== e.target.value),
    );
  };

  const onFilter = () => {
    onChangeProjectFilter({ projects: dealProjects });
    setIsPopoverVisible(false);
  };

  const onResetFilter = () => {
    setDealProjects([]);
    onChangeProjectFilter({
      isReset: true,
    });
    setIsPopoverVisible(false);
  };

  return (
    <div className={styles.timelineFilter}>
      <Popover
        placement="bottom"
        content={
          <div>
            <DealPopoverContent
              clientProjects={clientProjects}
              onChangeCheckbox={onChangeCheckbox}
              projectFilter={dealProjects}
            />
            <div className={styles.popoverDealButtonStyleContainer}>
              <div className={styles.dealButtons}>
                <Button
                  onClick={onResetFilter}
                  size="small"
                  type="link"
                  style={{ fontWeight: 500 }}
                >
                  Reset
                </Button>
                <Button
                  size="small"
                  type="primary"
                  style={{ fontWeight: 500, marginRight: '16px' }}
                  onClick={onFilter}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
        }
        open={isPopoverVisible}
        trigger="click"
      >
        <DealPopoverCommon
          projectFilter={dealProjects}
          handleVisibleChange={handleVisibleChange}
        />
      </Popover>
    </div>
  );
};

DealSelect.propTypes = {
  onChangeProjectFilter: PropTypes.func,
  clientProjects: PropTypes.array,
};

export default DealSelect;
