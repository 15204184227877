import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import styles from './styles.scss';

const ProfitAndLossProjectItem = ({ title, projectName, tooltip, gpm }) => (
  <>
    <span className={styles.label}>{title}</span>
    <div className={styles.item}>
      <div className={styles.title}>
        <span>{projectName}</span>
        <Tooltip title={tooltip}>
          <InfoCircleOutlined className={styles.info} />
        </Tooltip>
      </div>
      <div className={styles.value}>{gpm}</div>
    </div>
  </>
);

ProfitAndLossProjectItem.propTypes = {
  title: PropTypes.string,
  projectName: PropTypes.string,
  tooltip: PropTypes.string,
  gpm: PropTypes.string,
};

export default ProfitAndLossProjectItem;
