export const ISSUE_TYPES = ['Task', 'Story', 'Bug', 'Epic'];

export const CREATION_ROW = {
  key: 'new_allocation',
  project_key: '',
  issue_type: '',
  issue_key: '',
  issue_summary: '',
  date: '',
  worker: '',
  worker_name: '',
  role: '',
  spent_hours: '',
  rate: '',
  currency_code: '',
  hours_per_week: '',
  comment: '',
  project_id: '',
};

export const BUTTON_ROW = {
  project_id: null,
  project_key: null,
  issue_type: null,
  issue_key: null,
  issue_summary: null,
  date: null,
  worker: null,
  worker_name: null,
  role: null,
  spent_hours: null,
  rate: null,
  currency_code: null,
  hours_per_week: null,
  comment: null,
  isAddButton: true,
  key: 'addButton',
};

export const WORKLOGS_EDITABLE_FIELDS = {
  comment: 'comment',
  spent_hours: 'spent_hours',
  project_key: 'project_key',
  issue_type: 'issue_type',
  issue_key: 'issue_key',
  issue_summary: 'issue_summary',
  date: 'date',
  worker: 'worker_name',
};

export const WORKLOGS_SELECT_FIELDS = [
  WORKLOGS_EDITABLE_FIELDS.spent_hours,
  WORKLOGS_EDITABLE_FIELDS.issue_type,
  WORKLOGS_EDITABLE_FIELDS.worker,
];

export const ENTER_KEY_CODE = '13';
export const SHIFT_KEY_CODE = '16';
