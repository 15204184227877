const checkCurrencyType = data => {
  if (data.fixed_salary_usd) {
    return 'fixed_salary_usd';
  }

  if (data.fixed_salary_byn) {
    return 'fixed_salary_byn';
  }

  if (data.hourly_rate_usd) {
    return 'hourly_rate_usd';
  }
};

export { checkCurrencyType };
