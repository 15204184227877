import React from 'react';
import PropTypes from 'prop-types';
import { Line } from '@ant-design/charts';
import { formatProjectInfo } from './utils';

const ProjectChart = ({ infoAboutCurrentProject }) => {
  const config = {
    data: formatProjectInfo({ infoAboutCurrentProject }),
    xField: 'date',
    yField: 'value',
    seriesField: 'title',
    color: _ref => {
      switch (_ref.title) {
        case 'cost':
          return 'red';
        case 'revenue':
          return '#FFCE45';
        case 'profit':
          return 'green';
        default:
          return 'blue';
      }
    },
  };

  return (
    <div>
      <Line {...config} />
    </div>
  );
};

ProjectChart.propTypes = {
  infoAboutCurrentProject: PropTypes.array,
};

export default ProjectChart;
