exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".client-summary-3280f{padding:0;margin:0;list-style:none;font-size:16px;color:#595959}.client-summary-3280f>li{margin-bottom:6px;line-height:24px}.client-summary_vertical-86afd .client-summary-item-04a57{display:block}.client-summary-item-04a57{list-style:none;padding:5px 10px}.client-summary_horizontal-027bb .client-summary-item-04a57{display:inline-block}.client-summary_horizontal-027bb .client-summary-item-04a57:first-child{padding-left:0}.infoIcon-da791{margin-right:6px}.itemDescription-dbf80{display:flex}.itemMoney-50ba9{display:flex;align-items:center}", ""]);

// Exports
exports.locals = {
	"client-summary": "client-summary-3280f",
	"client-summary_vertical": "client-summary_vertical-86afd",
	"client-summary-item": "client-summary-item-04a57",
	"client-summary_horizontal": "client-summary_horizontal-027bb",
	"infoIcon": "infoIcon-da791",
	"itemDescription": "itemDescription-dbf80",
	"itemMoney": "itemMoney-50ba9"
};