import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Tooltip } from 'antd';

import styles from './styles.scss';

const AvatarWithName = ({ avatarUrl, payload, title }) => (
  <div>
    <Tooltip
      className={styles.managerContainer}
      getPopupContainer={e => e.parentElement || e}
      title={title}
    >
      {avatarUrl && <Avatar size={22} src={avatarUrl} />}
      <span className={styles.nameTooltip}>{payload}</span>
    </Tooltip>
  </div>
);

AvatarWithName.propTypes = {
  avatarUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  payload: PropTypes.string,
  title: PropTypes.string,
};

export default AvatarWithName;
