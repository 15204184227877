import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const SocialRequestsTotalItem = ({ title, value }) => (
  <div className={styles.socialRequestsTotalItem}>
    <span className={styles.title}>{title}</span>
    <span className={styles.value}>{value}</span>
  </div>
);

SocialRequestsTotalItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SocialRequestsTotalItem;
