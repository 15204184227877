import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const ClientContractsItem = ({ label, children }) => (
  <div className={styles.clientContractsItem}>
    <span className={styles.field}>{label} </span>
    {children}
  </div>
);

ClientContractsItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default ClientContractsItem;
