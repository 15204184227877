export const INVOICE_TYPES_MESSAGES = {
  CREATE_INVOICE_TYPE_SUCCESS: 'Invoice type created successfully',
  CREATE_INVOICE_TYPE_FAILED: 'Failed to create invoice type',
  UPDATE_INVOICE_TYPE_SUCCESS: 'Invoice type updated successfully',
  UPDATE_INVOICE_TYPE_FAILED: 'Failed to update invoice type',
  DELETE_INVOICE_TYPE_SUCCESS: 'Invoice type deleted successfully',
  DELETE_INVOICE_TYPE_FAILED: 'Failed to delete invoice type',
};

export const INVOICE_TYPES_COMMON = {
  id_title: 'ID',
  id: 'id',
  name_title: 'Name',
  name: 'name',
  actions_title: 'Actions',
  actions: 'actions',
};
