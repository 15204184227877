import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import Input from 'components/Common/Input';
import Select, { Option } from 'components/Common/Select';

import styles from './styles.scss';

const { Item } = Form;

const UserForm = ({ form, roles, currentUser, onSubmit }) => {
  useEffect(() => {
    if (currentUser) {
      form.setFieldsValue(currentUser);
    }

    return () => form.resetFields();
  }, []);

  return (
    <Form
      layout="vertical"
      className={styles.form}
      form={form}
      onFinish={onSubmit}
    >
      <Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Enter name',
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Enter email',
          },
          {
            type: 'email',
            message: 'Invalid email',
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label="Password"
        name="password"
        rules={[
          !currentUser && {
            required: true,
            message: 'Enter password',
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label="Role"
        name="role_id"
        rules={[
          {
            required: true,
            message: 'Please select at least one element',
          },
        ]}
      >
        <Select>
          {roles.map(role => (
            <Option value={role.id} key={role.id}>
              {role.name}
            </Option>
          ))}
        </Select>
      </Item>
    </Form>
  );
};

UserForm.propTypes = {
  form: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};

export default UserForm;
