import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

// @ts-ignore
import styles from './styles.scss';
import { formatMoney } from '../utils';

const MoneyCell = ({ value, style = {}, project }) => {
  const [isVisibleData, setIsVisibleData] = useState(true);

  useEffect(() => {
    setIsVisibleData(
      Number(get(project, 'profit')) === 0 && Number(project.revenue) === 0,
    );
  }, [value, project]);

  return (
    <div style={style} className={styles.moneyCellWrapper}>
      <div
        className={styles.moneyCell}
        style={{ color: value >= 0 ? '#237804' : '#a8071a' }}
      >
        {!isVisibleData ? formatMoney(value) : ''}
      </div>
    </div>
  );
};

MoneyCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  project: PropTypes.object,
};

export default MoneyCell;
