import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { DASHBOARD_OVERVIEW_TOOLTIPS } from 'containers/DashboardOverviewPage/constants';
import Table from 'components/Common/Table';
import { getMonthNameWithYearShorten } from 'utils/helpers';

import ClientConcentrationChart from './ClientConcentrationChart';
import {
  getDataSource,
  getClientConcentrationColumns,
  clientDataFilter,
  getClientsColors,
} from './utils';
import { SALARY_CHART_COLORS } from '../DashboardSalaryChart/constants';
import styles from './styles.scss';

const { Title } = Typography;

const ClientConcentration = ({
  dashboardData: { clients_last_period, clients_total },
  period,
}) => {
  const dataSource = useMemo(
    () => getDataSource({ clients_last_period, clients_total }),
    [clients_last_period, clients_total],
  );

  const columns = getClientConcentrationColumns(period);

  const lastPeriod = getMonthNameWithYearShorten(period[1]);

  const totalPeriod = `${getMonthNameWithYearShorten(
    period[0],
  )} - ${getMonthNameWithYearShorten(period[1])}`;

  const clientsLastPeriod = clientDataFilter(clients_last_period);

  const clientsTotal = clientDataFilter(clients_total);

  const clientsColors = useMemo(
    () => getClientsColors(clientsTotal, SALARY_CHART_COLORS),
    [clientsTotal],
  );

  return (
    <div className={styles.clientConcentration}>
      <div className={styles.clientConcentrationHeader}>
        <Title level={5} className={styles.title}>
          Client concentration
          <Tooltip title={DASHBOARD_OVERVIEW_TOOLTIPS.CLIENT_CONCENTRATION}>
            <InfoCircleOutlined className={styles.info} />
          </Tooltip>
        </Title>
      </div>
      <div className={styles.clientConcentrationContainer}>
        <div className={styles.table}>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowKey={record => record.clientName}
          />
        </div>
        <div className={styles.charts}>
          <ClientConcentrationChart
            clientsData={clientsLastPeriod}
            period={lastPeriod}
            className={styles.chartsItem}
            clientsColors={clientsColors}
          />
          <ClientConcentrationChart
            clientsData={clientsTotal}
            period={totalPeriod}
            className={styles.chartsItem}
            clientsColors={clientsColors}
          />
        </div>
      </div>
    </div>
  );
};

ClientConcentration.propTypes = {
  dashboardData: PropTypes.shape({
    clients_last_period: PropTypes.array,
    clients_total: PropTypes.array,
  }),
  period: PropTypes.array.isRequired,
};

export default memo(ClientConcentration);
