import React from 'react';

const YellowIcon = () => (
  <svg
    width="102"
    height="36"
    viewBox="0 0 102 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1013_6584)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9168 1.05511C27.2666 1.05511 34.8468 8.63533 34.8468 17.9851C34.8468 27.3349 27.2666 34.9151 17.9168 34.9151C8.56703 34.9151 0.986816 27.3349 0.986816 17.9851C0.986816 8.63533 8.56703 1.05511 17.9168 1.05511Z"
        fill="#FFE603"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9168 1.05511C27.2666 1.05511 34.8468 8.63533 34.8468 17.9851C34.8468 27.3349 27.2666 34.9151 17.9168 34.9151C8.56703 34.9151 0.986816 27.3349 0.986816 17.9851C0.986816 8.63533 8.56703 1.05511 17.9168 1.05511V1.05511Z"
        stroke="#171717"
        strokeWidth="0.361308"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11609 21.5073L9.05192 22.9365C14.7009 19.2443 20.5201 19.2613 26.8497 22.9536L27.7175 21.4903C20.8604 17.4747 14.2755 17.4747 8.11609 21.5073Z"
        fill="#171717"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9339 25.829C17.2704 25.829 16.7429 25.2845 16.7429 24.6379C16.7429 23.9913 17.2874 23.4469 17.9339 23.4469C18.5805 23.4469 19.125 23.9913 19.125 24.6379C19.125 25.2845 18.5975 25.829 17.9339 25.829ZM17.9339 21.7283C16.3345 21.7283 15.0414 23.0215 15.0414 24.6209C15.0414 26.2203 16.3345 27.5135 17.9339 27.5135C19.5334 27.5135 20.8265 26.2203 20.8265 24.6209C20.8265 23.0215 19.5334 21.7283 17.9339 21.7283Z"
        fill="#171717"
      />
      <mask
        id="mask0_1013_6584"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="-1"
        y="0"
        width="37"
        height="36"
      >
        <path
          d="M-0.00012207 0.0680542H35.8336V35.8916H-0.00012207V0.0680542Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1013_6584)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7258 15.8582L17.9168 17.0493L25.3184 9.66472L24.1103 8.45665L17.9509 14.6331L11.7574 8.43964L10.5493 9.64771L16.7428 15.8242L16.7258 15.8582Z"
          fill="#171717"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9167 34.2003C8.96682 34.2003 1.70138 26.9178 1.70138 17.9849C1.70138 9.05201 8.98383 1.76956 17.9167 1.76956C26.8667 1.76956 34.1321 9.05201 34.1321 17.9849C34.1321 26.9178 26.8497 34.2003 17.9167 34.2003ZM17.9167 0.0680542C8.03099 0.0680542 -0.00012207 8.09917 -0.00012207 17.9849C-0.00012207 27.8707 8.03099 35.9018 17.9167 35.9018C27.8025 35.9018 35.8336 27.8707 35.8336 17.9849C35.8336 8.09917 27.8025 0.0680542 17.9167 0.0680542Z"
          fill="#171717"
        />
      </g>
    </g>
    <path
      d="M51.2535 12.6182L44.7746 28.125H42.5127L44.6596 22.9944L40.5 12.6182H42.9344L45.9055 20.6588L48.9917 12.6182H51.2535Z"
      fill="#171717"
    />
    <path
      d="M62.5827 17.634C62.5827 18.0297 62.5572 18.387 62.5061 18.7061H54.4361C54.5 19.5484 54.8131 20.2248 55.3754 20.7354C55.9377 21.2459 56.6277 21.5011 57.4456 21.5011C58.6212 21.5011 59.4519 21.0098 59.9375 20.027H62.2952C61.9757 20.997 61.3943 21.7947 60.5509 22.42C59.7202 23.0327 58.6851 23.339 57.4456 23.339C56.436 23.339 55.5287 23.1156 54.7236 22.6689C53.9313 22.2095 53.3052 21.5713 52.8451 20.7545C52.3979 19.9249 52.1742 18.9677 52.1742 17.8829C52.1742 16.798 52.3915 15.8472 52.826 15.0304C53.2732 14.2008 53.893 13.5627 54.6853 13.116C55.4904 12.6693 56.4105 12.4459 57.4456 12.4459C58.4423 12.4459 59.3305 12.6629 60.11 13.0968C60.8895 13.5308 61.4965 14.1434 61.931 14.9347C62.3655 15.7132 62.5827 16.613 62.5827 17.634ZM60.3017 16.9448C60.2889 16.1408 60.0014 15.4962 59.4391 15.0113C58.8768 14.5263 58.1804 14.2838 57.3497 14.2838C56.5958 14.2838 55.9504 14.5263 55.4137 15.0113C54.877 15.4835 54.5575 16.128 54.4553 16.9448H60.3017Z"
      fill="#171717"
    />
    <path d="M66.9184 9V23.1667H64.7332V9H66.9184Z" fill="#171717" />
    <path d="M71.9726 9V23.1667H69.7874V9H71.9726Z" fill="#171717" />
    <path
      d="M79.4229 23.339C78.4261 23.339 77.5252 23.1156 76.7201 22.6689C75.915 22.2095 75.2825 21.5713 74.8224 20.7545C74.3624 19.9249 74.1324 18.9677 74.1324 17.8829C74.1324 16.8108 74.3688 15.86 74.8416 15.0304C75.3144 14.2008 75.9598 13.5627 76.7776 13.116C77.5955 12.6693 78.5092 12.4459 79.5187 12.4459C80.5283 12.4459 81.442 12.6693 82.2598 13.116C83.0777 13.5627 83.723 14.2008 84.1959 15.0304C84.6687 15.86 84.9051 16.8108 84.9051 17.8829C84.9051 18.955 84.6623 19.9058 84.1767 20.7354C83.6911 21.5649 83.0266 22.2095 82.1832 22.6689C81.3525 23.1156 80.4324 23.339 79.4229 23.339ZM79.4229 21.4437C79.9852 21.4437 80.5091 21.3097 80.9947 21.0417C81.4931 20.7736 81.8956 20.3716 82.2023 19.8356C82.509 19.2995 82.6624 18.6486 82.6624 17.8829C82.6624 17.1171 82.5154 16.4726 82.2215 15.9493C81.9276 15.4133 81.5378 15.0113 81.0522 14.7432C80.5666 14.4752 80.0427 14.3412 79.4804 14.3412C78.9181 14.3412 78.3942 14.4752 77.9086 14.7432C77.4358 15.0113 77.0588 15.4133 76.7776 15.9493C76.4965 16.4726 76.3559 17.1171 76.3559 17.8829C76.3559 19.0188 76.6435 19.8994 77.2185 20.5248C77.8063 21.1374 78.5411 21.4437 79.4229 21.4437Z"
      fill="#171717"
    />
    <path
      d="M101.25 12.6182L97.9722 23.1667H95.672L93.5443 15.375L91.4165 23.1667H89.1163L85.8193 12.6182H88.0429L90.2473 21.0991L92.49 12.6182H94.771L96.9179 21.0608L99.1031 12.6182H101.25Z"
      fill="#171717"
    />
    <defs>
      <clipPath id="clip0_1013_6584">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default YellowIcon;
