import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';

import { REQUESTS_COMMON } from 'containers/SocialRequestsPage/constants';
import { Table } from 'components/Common';
import { SORT_DIRECTIONS } from 'utils/constants';
import { summaryEmployeesSelector } from 'redux/selectors';

import {
  createSummarySocialRequest,
  getSummarySocialRequests,
  getSummarySocialRequestsInfo,
} from 'redux/SummaryEmployees/actions';
import SocialRequestAttachmentModal from 'components/SocialRequestsCommon/SocialRequestAttachmentModal';
import RequestsModal from './RequestsModal';
import RequestsInfo from './RequestsInfo';
import { getColumns } from './utils';
import styles from './styles.scss';

const ProfileRequests = ({
  form,
  isVisible,
  onHide,
  onHideRequestsModal,
  id,
  role,
  isWard,
  isOwnProfile,
}) => {
  const [sortInfo, setSortInfo] = useState({
    field: REQUESTS_COMMON.sent_at,
    order: SORT_DIRECTIONS.descend,
  });
  const [doc, setDoc] = useState(null);

  const dispatch = useDispatch();

  const {
    summaryEmployeeSocialRequests,
    summaryEmployeeSocialRequestsInfo,
    isSummarySocialRequestsLoading,
  } = useSelector(summaryEmployeesSelector);

  const REQ_PARAMS = { summary_employee_id: id, size: 9999 };

  useEffect(() => {
    dispatch(
      getSummarySocialRequests({
        ...REQ_PARAMS,
        sort: `${sortInfo.field}:${sortInfo.order}`,
      }),
    );
    dispatch(getSummarySocialRequestsInfo({ summary_employee_id: id }));
  }, []);

  const onPreview = url => () => {
    setDoc([{ uri: url }]);
  };

  const onClosePreviewModal = () => {
    setDoc(null);
  };

  const onDownloadAttachment = doc => () => {
    doc.length && window.open(doc[0].uri);
  };

  const columns = useMemo(() => getColumns({ sortInfo, onPreview }), [
    sortInfo,
  ]);

  const onTableChange = (pag, filters, sort) => {
    setSortInfo(sort);
    dispatch(
      getSummarySocialRequests({
        ...REQ_PARAMS,
        sort: `${sort.field}:${sort.order}`,
      }),
    );
  };

  const onAddSocialRequest = values => {
    dispatch(
      createSummarySocialRequest({
        ...values,
        summary_employee_id: id,
        ...REQ_PARAMS,
        sort: `${sortInfo.field}:${sortInfo.order}`,
      }),
    );
    onHideRequestsModal();
  };

  if (isSummarySocialRequestsLoading || !summaryEmployeeSocialRequestsInfo)
    return (
      <div className={styles.loading}>
        <Spin />
      </div>
    );

  return (
    <div className={styles.profileRequests}>
      <RequestsModal
        isVisible={isVisible}
        onHide={onHide}
        form={form}
        onAddSocialRequest={onAddSocialRequest}
        requestsInfo={summaryEmployeeSocialRequestsInfo}
        role={role}
        isWard={isWard}
        isOwnProfile={isOwnProfile}
      />
      <SocialRequestAttachmentModal
        doc={doc}
        isVisible={!!doc}
        onClose={onClosePreviewModal}
        onDownloadAttachment={onDownloadAttachment}
      />
      <RequestsInfo
        requestsInfo={summaryEmployeeSocialRequestsInfo}
        role={role}
        isWard={isWard}
        isOwnProfile={isOwnProfile}
      />
      <Table
        columns={columns}
        dataSource={summaryEmployeeSocialRequests}
        pagination={false}
        onChange={onTableChange}
      />
    </div>
  );
};

ProfileRequests.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onHideRequestsModal: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  isWard: PropTypes.bool.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
};

export default ProfileRequests;
