import React from 'react';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { STATUS_TAG_COLOR } from 'containers/LeadPage/constants';
import Tag from 'components/Common/Tag';
import { mapArrayToEntities } from 'utils/common';
import { Y_M_D_H_M } from 'utils/timeConstants';
import { LINKS } from 'config/routing';

const renderCellAsLink = (value, record) => (
  <Link to={LINKS.lead(record.id)}>{value}</Link>
);

const renderValue = (value, record) => {
  const formattedValue = value ? value : '-';

  return renderCellAsLink(formattedValue, record);
};

const renderTimeValue = (value, record) => {
  const formattedValue = dayjs(value).format(Y_M_D_H_M);

  return renderCellAsLink(formattedValue, record);
};

const getOwnerFilters = filters =>
  filters?.map(({ id, name }) => ({
    text: name,
    value: id,
  })) || [];

const getFilters = filters =>
  filters?.map(value => ({
    text: value,
    value,
  })) || [];

export const getColumns = ({
  sortInfo,
  users,
  leadFilters,
  getColumnSearchProps,
}) => {
  const { entities: usersObject } = mapArrayToEntities(users);

  return [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
      sorter: true,
      sortOrder: sortInfo.columnKey === 'id' && sortInfo.order,
      render: renderCellAsLink,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      align: 'center',
      ...getColumnSearchProps('name'),
      sorter: true,
      sortOrder: sortInfo.columnKey === 'name' && sortInfo.order,
      render: renderCellAsLink,
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      ...getColumnSearchProps('email'),
      align: 'center',
      sorter: true,
      sortOrder: sortInfo.columnKey === 'email' && sortInfo.order,
      render: renderCellAsLink,
    },
    {
      title: 'Owner',
      key: 'owner_id',
      dataIndex: 'owner_id',
      sorter: true,
      sortOrder: sortInfo.columnKey === 'owner_id' && sortInfo.order,
      align: 'center',
      filters: getOwnerFilters(leadFilters.owners),
      render: (value, record) => {
        const renderValue = value
          ? get(usersObject, [value, 'name'], '-')
          : '-';

        return renderCellAsLink(renderValue, record);
      },
    },
    {
      title: 'Source',
      key: 'ga_source',
      dataIndex: 'ga_source',
      sorter: true,
      sortOrder: sortInfo.columnKey === 'ga_source' && sortInfo.order,
      filters: getFilters(leadFilters.ga_sources),
      align: 'center',
      render: renderValue,
    },
    {
      title: 'Medium',
      key: 'ga_medium',
      dataIndex: 'ga_medium',
      sorter: true,
      sortOrder: sortInfo.columnKey === 'ga_medium' && sortInfo.order,
      filters: getFilters(leadFilters.ga_mediums),
      align: 'center',
      render: renderValue,
    },
    {
      title: 'Channel',
      key: 'channel',
      dataIndex: 'channel',
      sorter: true,
      sortOrder: sortInfo.columnKey === 'channel' && sortInfo.order,
      filters: getFilters(leadFilters.channels),
      align: 'center',
      render: renderValue,
    },
    {
      title: 'Budget',
      key: 'budget',
      dataIndex: 'budget',
      align: 'center',
      sorter: true,
      sortOrder: sortInfo.columnKey === 'budget' && sortInfo.order,
      render: (value, record) => {
        const renderValue = value ? `$${value}` : '-';

        return renderCellAsLink(renderValue, record);
      },
    },
    {
      title: 'Contry name',
      key: 'country_name',
      dataIndex: 'country_name',
      filters: getFilters(leadFilters.country_names),
      align: 'center',
      sorter: true,
      sortOrder: sortInfo.columnKey === 'country_name' && sortInfo.order,
      render: renderValue,
    },
    {
      title: 'City',
      key: 'city',
      dataIndex: 'city',
      ...getColumnSearchProps('city'),
      align: 'center',
      sorter: true,
      sortOrder: sortInfo.columnKey === 'city' && sortInfo.order,
      render: renderValue,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      sortOrder: sortInfo.columnKey === 'status' && sortInfo.order,
      filters: getFilters(leadFilters.status),
      align: 'center',
      render: (value, record) => {
        const tag = <Tag color={STATUS_TAG_COLOR[value]}>{value}</Tag>;

        return renderCellAsLink(tag, record);
      },
    },
    {
      title: 'Created at',
      key: 'created_at',
      dataIndex: 'created_at',
      sorter: true,
      sortOrder: sortInfo.columnKey === 'created_at' && sortInfo.order,
      align: 'center',
      render: renderTimeValue,
    },
    {
      title: 'Updated at',
      key: 'updated_at',
      dataIndex: 'updated_at',
      sorter: true,
      sortOrder: sortInfo.columnKey === 'updated_at' && sortInfo.order,
      align: 'center',
      render: renderTimeValue,
    },
  ];
};

export const getModifiedFilters = ({ FILTER_STRING, filters }) => {
  const filterKeys = Object.keys(filters);

  return filterKeys.reduce((acc, curr) => {
    if (FILTER_STRING.includes(curr)) {
      return { ...acc, [curr]: filters[curr][0] };
    }

    return { ...acc, [curr]: filters[curr] };
  }, {});
};
