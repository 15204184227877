import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Table, Typography } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { CopyOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import TableButton from 'components/Common/TableButton';
import { formatNumberWithCommas, numberSorter } from 'utils/helpers';
import { stringSorter } from 'utils/stringSorter';
import { DATE_FORMAT } from 'utils/timeConstants';

import InvoiceWorklogWorkerCell from './InvoiceWorklogWorkerCell';
import InvoiceOveriewEditableCell from './InvoiceWorklogEditableCell';
import styles from './styles.scss';

const { Text } = Typography;
const { Summary } = Table;

const getEmployeeFilters = workers =>
  workers?.map(worker => ({ value: worker, text: worker })) || [];

export const groupById = data =>
  data?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),
    {},
  ) || {};

const getWorkDays = ({ calendar, period_from, period_to }) =>
  calendar.reduce(
    (acc, curr) =>
      !curr.is_day_off &&
      !dayjs(curr.date).isBefore(period_from) &&
      !dayjs(curr.date).isAfter(period_to)
        ? acc + 1
        : acc,
    0,
  );

export const getSummaryDataSource = ({
  worklogsGroupedByProject,
  invoiceAllocations,
  invoiceProjects,
  calendar,
  projectGroupedById,
  jiraRoles,
  isAllocationsStepLoading,
}) => {
  if (
    !calendar.length ||
    isEmpty(worklogsGroupedByProject) ||
    isAllocationsStepLoading ||
    isEmpty(invoiceAllocations)
  )
    return [];

  const dataSource = invoiceProjects.reduce((acc, projectId) => {
    let spentProject = 0;
    let costProject = 0;

    // Вычисляет суммарное затреканное время по проекту
    const spentSummary =
      worklogsGroupedByProject[projectId]?.reduce((summary, worklog) => {
        const { spent_seconds, spent_hours, allocation_id, deleted } = worklog;

        if (deleted) return summary;

        const spent = spent_seconds ? spent_seconds / 3600 : spent_hours;

        return {
          ...summary,
          [allocation_id]: summary[allocation_id]
            ? summary[allocation_id] + spent
            : spent,
        };
      }, {}) || {};

    const children = Object.entries(spentSummary).map(
      ([allocationId, spent]) => {
        const allocation = invoiceAllocations[projectId].find(
          allocation => allocation.id == allocationId,
        );

        const { period_from, period_to } = allocation;

        const projectRole = jiraRoles.find(
          role => role.id === allocation.jira_role_id,
        )?.name;

        const workDays = getWorkDays({ calendar, period_from, period_to });

        // Вычисляет ожидаемое кол-во затреканных часов
        // в зависимости от кол-ва рабочих дней и hours_per_week у аллокейшена
        const expected = (workDays / 5) * allocation.hours_per_week;
        const cost = spent * allocation.rate;

        spentProject += spent;
        costProject += cost;

        return {
          project: '',
          projectRole,
          dateFrom: period_from,
          dateTo: period_to,
          expected,
          spent,
          billiable: spent,
          currency: allocation.currency_code,
          rate: allocation.rate,
          cost,
          allocationId: allocation.id,
          isChildren: true,
          key: allocation.id,
        };
      },
    );

    return [
      ...acc,
      {
        project: projectGroupedById[projectId].name,
        projectId,
        spent: spentProject,
        cost: costProject,
        isChildren: false,
        children,
        key: projectId,
      },
    ];
  }, []);

  return dataSource;
};

export const formatNewWorklog = ({
  values,
  currentProjectId,
  invoiceAllocations,
  searchedJiraUsers,
  jiraProjects,
  jiraRolesGroupedById,
}) => {
  const { allocation_id, date, spent_hours, worker, project_id } = values;

  // Use project_id from form values if modal opened with alert button
  const projectId = project_id || currentProjectId;

  const worklogAllocation = invoiceAllocations[projectId].find(
    allocation => allocation.id === allocation_id,
  );
  const role_id = worklogAllocation?.jira_role_id;
  const role = jiraRolesGroupedById[role_id]?.name;

  const worker_name =
    searchedJiraUsers.find(jiraUser => jiraUser.key === worker)?.displayName ||
    '';

  const project_key = jiraProjects.find(project => project.id == projectId)
    ?.key;

  return {
    ...values,
    comment: values.comment || '',
    role_id,
    role,
    worker_name,
    project_key,
    spent_seconds: spent_hours * 3600,
    project_id: projectId,
    rate: worklogAllocation.rate,
    hours_per_week: worklogAllocation.hours_per_week,
    currency_code: worklogAllocation.currency_code,
    date: dayjs(date).format(DATE_FORMAT),
    worklog_id: uuidv4(),
    isNew: true,
  };
};

export const getWorkerFilters = groupWorklogsByProject =>
  (groupWorklogsByProject &&
    Object.entries(groupWorklogsByProject).reduce(
      (projectAcc, [key, value]) => ({
        ...projectAcc,
        [key]: value.reduce((worklogsAcc, worklog) => {
          if (
            worklog.project_id == key &&
            !worklogsAcc.includes(worklog.worker_name)
          ) {
            return [...worklogsAcc, worklog.worker_name];
          }

          return worklogsAcc;
        }, []),
      }),
      {},
    )) ||
  {};

export const getSummaryColumns = () => [
  {
    title: 'Project',
    dataIndex: 'project',
  },
  {
    title: 'Project role',
    dataIndex: 'projectRole',
  },
  {
    title: 'From',
    dataIndex: 'dateFrom',
  },
  {
    title: 'To',
    dataIndex: 'dateTo',
  },
  {
    title: 'Expected',
    dataIndex: 'expected',
    render: (value, { isChildren }) =>
      isChildren && formatNumberWithCommas(value),
  },
  {
    title: 'Spent',
    dataIndex: 'spent',
    render: (value, record) => {
      const color = record.expected > value ? '#a8071a' : '#0050b3';

      return (
        <Text strong style={{ color }}>
          {formatNumberWithCommas(value)}
        </Text>
      );
    },
  },
  {
    title: 'Billiable',
    dataIndex: 'billiable',
    render: (value, { isChildren }) =>
      isChildren && formatNumberWithCommas(value),
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
  },
  {
    title: 'Rate',
    dataIndex: 'rate',
    render: (value, { isChildren }) =>
      isChildren && formatNumberWithCommas(value),
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    render: (value, record) => (
      <Text strong={record.isChildren ? false : true}>
        {formatNumberWithCommas(value)}
      </Text>
    ),
  },
];

export const getProjectColumns = ({
  currentProjectId,
  onCellClick,
  onCellBlur,
  editableCell,
  setEditableCell,
  workersGroupedByProject,
  onDeleteRestoreWorklog,
  onSearchJiraUsers,
  searchedJiraUsers,
  isJiraLoading,
  onDuplicateWorklog,
}) => [
  {
    title: 'Key',
    dataIndex: 'project_key',
    key: 'project_key',
    width: 70,
    render: value => <span>{value}</span>,
  },
  {
    title: 'Issue type',
    dataIndex: 'issue_type',
    key: 'issue_type',
    width: 75,
    sorter: stringSorter('issue_type'),
    render: (value, record) => (
      <InvoiceOveriewEditableCell
        value={value}
        inputType="issue_type"
        onClick={onCellClick}
        record={record}
        editableCell={editableCell}
        onCellBlur={onCellBlur}
      />
    ),
  },
  {
    title: 'Issue key',
    dataIndex: 'issue_key',
    key: 'issue_key',
    width: 100,
    sorter: stringSorter('issue_key'),
    render: value => <span>{value}</span>,
  },
  {
    title: 'Issue',
    dataIndex: 'issue_summary',
    key: 'issue_summary',
    sorter: stringSorter('issue_summary'),
    width: 250,
    render: (value, record) => (
      <InvoiceOveriewEditableCell
        value={value}
        inputType="issue_summary"
        onClick={onCellClick}
        record={record}
        editableCell={editableCell}
        onCellBlur={onCellBlur}
      />
    ),
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    editable: true,
    key: 'comment',
    width: 230,
    render: (value, record) => (
      <InvoiceOveriewEditableCell
        value={value}
        inputType="comment"
        onClick={onCellClick}
        record={record}
        editableCell={editableCell}
        onCellBlur={onCellBlur}
      />
    ),
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 100,
    sorter: stringSorter('date'),
    render: (value, record) => (
      <InvoiceOveriewEditableCell
        value={value}
        inputType="date"
        onClick={onCellClick}
        record={record}
        editableCell={editableCell}
        onCellBlur={onCellBlur}
      />
    ),
  },
  {
    title: 'Worker',
    dataIndex: 'worker_name',
    key: 'worker_name',
    width: 170,
    sorter: stringSorter('worker_name'),
    filters: getEmployeeFilters(workersGroupedByProject[currentProjectId]),
    onFilter: (value, record) => record.worker_name === value,
    render: (value, record) => (
      <InvoiceWorklogWorkerCell
        value={value}
        inputType="worker_name"
        onClick={onCellClick}
        record={record}
        editableCell={editableCell}
        setEditableCell={setEditableCell}
        onCellBlur={onCellBlur}
        onSearchJiraUsers={onSearchJiraUsers}
        searchedJiraUsers={searchedJiraUsers}
        isJiraLoading={isJiraLoading}
      />
    ),
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    width: 150,
    sorter: stringSorter('role'),
    render: (value, record) =>
      value ? (
        <span>
          {`${value} ${record.currency_code} ${record.rate}  ${
            record.hours_per_week
          } h/week`}
        </span>
      ) : (
        ''
      ),
  },
  {
    title: 'Spent hours',
    dataIndex: 'spent_hours',
    key: 'spent_hours',
    width: 120,
    align: 'center',
    editable: true,
    sorter: numberSorter('spent_hours'),
    render: (value, record) => (
      <InvoiceOveriewEditableCell
        value={formatNumberWithCommas(value)}
        inputType="spent_hours"
        onClick={onCellClick}
        record={record}
        editableCell={editableCell}
        onCellBlur={onCellBlur}
      />
    ),
  },
  {
    title: 'Rate',
    dataIndex: 'rate',
    key: 'rate',
    align: 'center',
    width: 100,
    sorter: numberSorter('rate'),
    render: value => <span>{formatNumberWithCommas(value)}</span>,
  },
  {
    title: 'Currency',
    dataIndex: 'currency_code',
    key: 'currency_code',
    align: 'center',
    width: 100,
    render: value => <span>{value}</span>,
  },
  {
    title: 'Hours per week',
    dataIndex: 'hours_per_week',
    key: 'hours_per_week',
    align: 'center',
    sorter: numberSorter('hours_per_week'),
    width: 100,
    render: value => <span>{value}</span>,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: 80,
    align: 'center',
    render: (_, record) => (
      <>
        {record.deleted ? (
          <TableButton
            icon={<UndoOutlined />}
            className={styles.restoreBtn}
            size="small"
            onClick={() => onDeleteRestoreWorklog({ record, isDelete: false })}
          />
        ) : (
          <>
            <TableButton
              icon={<CopyOutlined />}
              size="small"
              type="primary"
              ghost
              className={styles.duplicateBtn}
              onClick={() => onDuplicateWorklog(record)}
            />
            <TableButton
              icon={<DeleteOutlined />}
              danger
              ghost
              size="small"
              onClick={() => onDeleteRestoreWorklog({ record, isDelete: true })}
            />
          </>
        )}
      </>
    ),
  },
];

const getShownColumns = columns => {
  const isColumnsShown = {};

  columns.forEach(({ dataIndex, isShowColumn }) => {
    isColumnsShown[dataIndex] = isShowColumn;
  });

  return isColumnsShown;
};

export const getSummary = ({
  data,
  worklogFilters,
  project,
  columnsSettings,
}) => {
  const {
    project_key,
    issue_type,
    issue_key,
    issue_summary,
    comment,
    date,
    worker_name,
    role,
    spent_hours,
    rate,
    currency_code,
    hours_per_week,
  } = getShownColumns(columnsSettings);
  const filters = Object.entries(worklogFilters).find(
    item => item[0] == project,
  );

  const total =
    data &&
    data[project]?.reduce(
      (acc, curr) => {
        if (
          (filters &&
            filters[1]?.worker_name?.length &&
            !filters[1]?.worker_name?.includes(curr.worker_name)) ||
          curr.deleted
        ) {
          return acc;
        }

        return {
          hours: acc.hours + curr.spent_hours,
          seconds: acc.seconds + curr.spent_seconds,
        };
      },
      { hours: 0, seconds: 0 },
    );

  const spent = total?.seconds ? total?.seconds / 3600 : total?.hours;

  return (
    <Summary fixed>
      <Summary.Row>
        {project_key && <Summary.Cell index={0} />}
        {issue_type && <Summary.Cell index={1} />}
        {issue_key && <Summary.Cell index={2} />}
        {issue_summary && <Summary.Cell index={3} />}
        {comment && <Summary.Cell index={4} />}
        {date && <Summary.Cell index={5} />}
        {worker_name && <Summary.Cell index={6} />}
        {role && <Summary.Cell index={7} />}
        {spent_hours && (
          <Summary.Cell index={8}>
            <span className={styles.totalCell}>
              {Number(spent)
                .toFixed(2)
                .toString()}
            </span>
          </Summary.Cell>
        )}
        {rate && <Summary.Cell index={9} />}
        {currency_code && <Summary.Cell index={10} />}
        {hours_per_week && <Summary.Cell index={11} />}
      </Summary.Row>
    </Summary>
  );
};

getSummary.propTypes = {
  data: PropTypes.object,
  worklogFilters: PropTypes.object,
  project: PropTypes.string,
  columnsSettings: PropTypes.array,
};

export const getModifiedColumns = ({ talbeSettings, columns }) =>
  talbeSettings
    ? columns.map(column => ({
        ...column,
        isShowColumn: talbeSettings[column.dataIndex]?.isShowColumn,
        width: talbeSettings[column.dataIndex]?.width,
      }))
    : columns.map(column => ({
        ...column,
        isShowColumn: column.isShowColumn !== false,
      }));

export const getDeletedWorklogs = ({ worklogsGroupedById, originalWorklogs }) =>
  originalWorklogs.reduce((acc, curr) => {
    if (!worklogsGroupedById[curr.worklog_id]) {
      return [...acc, { ...curr, deleted: true }];
    }

    return acc;
  }, []);

export const addEmptyWorklogArrays = ({ worklogs, projects }) => {
  projects.forEach(project => {
    if (!worklogs.hasOwnProperty(project)) {
      worklogs[project] = [];
    }
  });
};
