import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import Modal from 'components/Common/Modal';
import { Button } from 'components/Common';
import Input from 'components/Common/Input';
import Select, { Option } from 'components/Common/Select';
import DatePicker from 'components/Common/DatePicker';
import { formTrimValidator } from 'utils/formHelper';

const ClientContractsModal = ({
  isVisibleModal,
  onHideModal,
  organizationsData,
  form,
  onFinish,
  isEditRecord,
}) => (
  <Modal
    open={isVisibleModal}
    destroyOnClose
    onCancel={onHideModal}
    title={isEditRecord ? 'Update contract' : 'Add contract'}
    footer={
      <>
        <Button onClick={onHideModal}>Cancel</Button>
        <Button type="primary" htmlType="submit" form="contractForm">
          {isEditRecord ? 'Update' : 'Add'}
        </Button>
      </>
    }
  >
    <Form layout="vertical" form={form} onFinish={onFinish} id="contractForm">
      <Form.Item
        label="Number"
        name="number"
        rules={[
          {
            message: 'This field is  required.',
            required: true,
            validator: formTrimValidator,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Contractor"
        name="contractor_id"
        rules={[
          {
            message: 'This field is  required.',
            required: true,
          },
        ]}
      >
        <Select>
          {[...organizationsData]
            .sort((a, b) =>
              a.is_yellow_group === b.is_yellow_group
                ? 0
                : a.is_yellow_group
                ? -1
                : 1,
            )
            .map(item => (
              <Option value={item.id} key={item.id}>
                {item.friendly_name}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Customer"
        name="customer_id"
        rules={[
          {
            message: 'This field is  required.',
            required: true,
          },
        ]}
      >
        <Select>
          {[...organizationsData]
            .sort((a, b) =>
              a.is_yellow_group === b.is_yellow_group
                ? 0
                : a.is_yellow_group
                ? -1
                : 1,
            )
            .map(item => (
              <Option value={item.id} key={item.id}>
                {item.friendly_name}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Starts at"
        name="start_date"
        rules={[
          {
            message: 'This field is  required.',
            required: true,
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item label="Ends at" name="end_date">
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="Dated"
        name="dated"
        rules={[
          {
            message: 'This field is  required.',
            required: true,
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
    </Form>
  </Modal>
);

ClientContractsModal.propTypes = {
  isVisibleModal: PropTypes.bool,
  isEditRecord: PropTypes.bool,
  onHideModal: PropTypes.func,
  organizationsData: PropTypes.array,
  form: PropTypes.object,
  onFinish: PropTypes.func,
};

export default ClientContractsModal;
