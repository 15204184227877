import { ROLE_TYPES } from 'utils/permissions';

export const TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const STATUS_INFO = {
  INCOMPLETE: {
    color: 'orange',
    text: 'INCOMPLETE',
  },
  ACTIVE: {
    color: 'green',
    text: 'ACTIVE',
  },
  ARCHIVED: {
    color: '',
    text: 'ARCHIVED',
  },
};

export const FILTER_FIELDS = ['status'];
export const FILTER_STRING = ['title', 'description', 'location'];

export const CLIENT_TABLE_INDEX = {
  id: 'id',
  title: 'title',
  owner_id: 'owner_id',
  owner_name: 'owner_name',
  description: 'description',
  location: 'location',
  status: 'status',
  created_at: 'created_at',
  updated_at: 'updated_at',
  actions: 'actions',
};

export const CLIENTS_PERMISSIONS = {
  addClient: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist, ROLE_TYPES.bdm],
  updateClient: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist, ROLE_TYPES.bdm],
  deleteClient: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  openClientPage: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.dm,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
  ],
};
