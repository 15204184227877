import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isInvoicesLoading: false,
  invoices: [],
  invoiceFilters: {},
  invoiceTemplates: [],
  invoiceStatuses: [],
  invoiceAudit: [],
  invoicesTotal: 0,

  invoice: null,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_INVOICES:
    case types.DECLINE_INVOICE:
    case types.APPROVE_INVOICE:
    case types.UPDATE_INVOICE:
    case types.DELETE_INVOICE:
    case types.GET_INVOICE_STATUSES:
    case types.GET_INVOICE_TEMPLATES:
    case types.GET_INVOICE_AUDIT:
      return state.set('isInvoicesLoading', true);

    case types.APPROVE_INVOICE_SUCCESS:
    case types.DECLINE_INVOICE_SUCCESS:
    case types.UPDATE_INVOICE_SUCCESS:
    case types.DELETE_INVOICE_SUCCESS:
      return state.set('isInvoicesLoading', false);
    case types.GET_INVOICES_SUCCESS:
      return state
        .set('isInvoicesLoading', false)
        .set('invoices', payload.data.data)
        .set('invoiceFilters', payload.data.data_for_filters)
        .set('invoicesTotal', payload.headers['x-total-count']);
    case types.GET_INVOICE_TEMPLATES_SUCCESS:
      return state.set('invoiceTemplates', payload);
    case types.GET_INVOICE_STATUSES_SUCCESS:
      return state
        .set('invoiceStatuses', payload)
        .set('isInvoicesLoading', false);
    case types.GET_INVOICE_AUDIT_SUCCESS:
      return state.set('invoiceAudit', payload).set('isInvoicesLoading', false);

    case types.GET_INVOICES_FAILED:
    case types.DECLINE_INVOICE_FAILED:
    case types.APPROVE_INVOICE_FAILED:
    case types.UPDATE_INVOICE_FAILED:
    case types.DELETE_INVOICE_FAILED:
    case types.GET_INVOICE_TEMPLATES_FAILED:
    case types.GET_INVOICE_STATUSES_FAILED:
    case types.GET_INVOICE_AUDIT_FAILED:
      return state.set('isInvoicesLoading', false);

    default:
      return state;
  }
}
