export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const PERMISSIONS = {
  admin: 'admin',
  users: 'users',
  paysheets: {
    calculate: 'paysheets:calculate',
  },
  employees: 'employees',
  extraPayments: 'extra_payments',
  exchangeRates: 'exchange_rates',
  avarageSalaryRate: 'avarage_salary_rate',
  technologies: 'technologies',
  positions: 'employee_positions',
  calendar: 'calendar',
  resourcePlannings: 'resource_plannings',
  clients: 'clients',
  deals: 'deals',
  contracts: 'contracts',
  invoices: 'invoices',
  organizations: 'organizations',
  projectProfits: 'project_profits',
  profiles: 'profiles',
  audit: 'audit',
  reportSubcontractors: 'report_subcontractors',
  leads: 'leads',
  clientContracts: 'contracts',
};

export const EMPLOYEE_STATUSES = {
  OK: { title: 'Works', color: 'green' },
  MISSING_SALARY: { title: 'Salary not specified', color: 'red' },
  FIRED: { title: 'Fired', color: 'gray' },
  INCOMPLETE: { title: 'Data not specified', color: 'orange' },
  MISSING_IBAN: { title: 'IBAN not specified', color: 'orange' },
  MISSING_RESOURCE_MANAGER: { title: 'RM not specified', color: 'orange' },
  MISSING_JIRA_USER: { title: 'JIRA ID not specified', color: 'orange' },
};

export const EMPLOYEE_OK_STATUSES = [
  'OK',
  'MISSING_SALARY',
  'INCOMPLETE',
  'MISSING_IBAN',
  'MISSING_RESOURCE_MANAGER',
  'MISSING_JIRA_USER',
];

export const OK_STATUS = 'OK';
export const FIRED_STATUS = 'FIRED';

export const TEAM_ID = {
  YELLOW: 1,
  SUBCONTRACTORS: 2,
};

export const TEAMS = {
  1: 'Yellow',
  2: 'Subcontractors',
};

export const ICONS_COLORS = {
  ellipseOutlined: {
    border: '#434343',
    color: '#434343',
  },
  disabled: { color: '#bfbfbf', border: '#bfbfbf' },
};

export const MOBILE_WIDTH = 768;

export const SORT_DIRECTIONS = {
  descend: 'descend',
  ascend: 'ascend',
};

export const DEFAULT_AVATAR =
  'https://www.gravatar.com/avatar/e5ffa8aecb9a36a76676407d8bfd1d36?d=mm&s=32';

export const ANTD_THEME = {
  token: {
    fontSize: 16,
    colorTextBase: '#262626',
  },
  components: {
    Table: {
      fontSize: 12,
    },
    Button: {
      paddingMD: 20,
      paddingContentVertical: 20,
    },
  },
};

export const RECHARTS_AXIS_STYLES = {
  tick: { fontSize: 12, fill: '#000' },
  tickLine: { fill: '#000' },
  axisLine: { fill: '#000' },
};

export const CURRENCY_CODES = {
  BYR: 'BYR',
  USD: 'USD',
};

export const JIRA_FIREBASE_PATH = {
  collection: 'projects-synchronize',
  doc: 'progress',
};
