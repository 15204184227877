import React, { Fragment, useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import dayjs from 'dayjs';
import { Input, Button, Alert, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { TableListView } from 'components/DataListCommon';
import { PaysheetTableHeader, PaysheetView } from 'components/PaysheetCommon';

import { BEGIN_MONTH_FORMAT, DATE_FORMAT } from 'utils/timeConstants';
import { LOCAL_STORAGE_ITEMS } from 'utils/localStorage';
import { exchangeRatesSelector, paysheetsSelector } from 'redux/selectors';
import {
  downloadPaysheets,
  getAverageSalary,
  loadPaysheets,
  updatePaysheetsValues,
} from 'redux/Paysheets/actions';
import { getExchangeRateForDate } from 'redux/ExchangeRates/actions';

import { useRoleContext } from 'utils/hooks';
import { hasRights } from 'utils/permissions';
import { EXCHANGE_RATES_PERMISSION } from 'containers/ExchangeRatesPage/constants';
import { AVERAGE_SALARIES_PERMISSIONS } from 'containers/AverageSalariesPage/constants';
import SearchTableIcon from 'components/Icons/SearchIcon';
import { formatPaySheetTable, getTableColumns, getSummary } from './utils';

import styles from './styles.scss';

const PaysheetTable = () => {
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchTableIcon isFiltered={filtered} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    render: text => (
      <div className={styles.highlightCell}>
        <Tooltip arrow={false} title={text.toString()}>
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Tooltip>
      </div>
    ),
  });

  const [period, setPeriod] = useState(dayjs());
  const [averageSalaryPeriod, setAverageSalaryPeriod] = useState(null);
  const [exchangeDate, setExchangeDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const dispatch = useDispatch();

  const history = useHistory();

  const role = useRoleContext();

  const {
    data,
    isPaysheetsLoading,
    averageSalaryPeriod: originalAverageSalaryPeriod,
    averageSalaryRate,
    error,
  } = useSelector(paysheetsSelector);

  const { exchangeDate: originalExchangeDate, exchangeRate } = useSelector(
    exchangeRatesSelector,
  );

  const canGetAverageSalariesPeriod = hasRights(
    role,
    AVERAGE_SALARIES_PERMISSIONS.averageSalaries,
  );
  const canGetExchangeRates = hasRights(
    role,
    EXCHANGE_RATES_PERMISSION.exchangeRates,
  );

  const getPaysheets = period => {
    dispatch(
      loadPaysheets({
        period: dayjs(period).format(BEGIN_MONTH_FORMAT),
        canGetAverageSalariesPeriod,
        canGetExchangeRates,
      }),
    );
  };

  useEffect(() => {
    const lsRecordPeriod = localStorage.getItem(
      LOCAL_STORAGE_ITEMS.selectedPaysheeetsPeriod,
    );
    const period = dayjs(lsRecordPeriod).isValid()
      ? dayjs(lsRecordPeriod)
      : dayjs();

    setPeriod(period);
    getPaysheets(period);
  }, []);

  const onReload = () => {
    getPaysheets(period);
  };

  const updateValues = () => {
    dispatch(
      updatePaysheetsValues({
        period: dayjs(period).format(BEGIN_MONTH_FORMAT),
        exchange_rate_date: dayjs(exchangeDate || originalExchangeDate).format(
          DATE_FORMAT,
        ),
        average_salary_period: dayjs(
          averageSalaryPeriod || originalAverageSalaryPeriod,
        ).format(DATE_FORMAT),
      }),
    );
  };

  const handlePeriodChange = period => {
    localStorage.setItem(LOCAL_STORAGE_ITEMS.selectedPaysheeetsPeriod, period);
    setPeriod(period);
    getPaysheets(period);
    setExpandedRowKeys([]);
  };

  const handleAverageSalaryPeriodChange = period => {
    if (!period) return;

    setAverageSalaryPeriod(period);
    dispatch(getAverageSalary(period.format(BEGIN_MONTH_FORMAT)));
  };

  const handleExchangeRateDateChange = date => {
    if (!date) return;

    setExchangeDate(date);
    dispatch(getExchangeRateForDate(date.format(DATE_FORMAT)));
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const onDownload = () => {
    dispatch(downloadPaysheets(dayjs(period).format(BEGIN_MONTH_FORMAT)));
  };

  const onExpand = (expanded, record) => {
    if (!expanded) {
      setExpandedRowKeys([]);

      return;
    }

    setExpandedRowKeys([record.key]);
  };

  const formattedData = formatPaySheetTable(data);

  const tableColumns = getTableColumns(
    { push: history.push },
    getColumnSearchProps,
  );
  const isModified =
    (exchangeDate && !isEqual(exchangeDate, originalExchangeDate)) ||
    (averageSalaryPeriod &&
      !isEqual(averageSalaryPeriod, originalAverageSalaryPeriod));
  const errorStyle = !error ? { display: 'none' } : {};

  function onRow() {
    return {
      onClick: () => null,
    };
  }

  return (
    <Fragment>
      <PaysheetTableHeader
        title="Salary"
        period={period || dayjs()}
        averageSalaryPeriod={dayjs(
          averageSalaryPeriod || originalAverageSalaryPeriod,
        )}
        averageSalary={averageSalaryRate}
        exchangeRateDate={dayjs(exchangeDate || originalExchangeDate)}
        exchangeRate={exchangeRate}
        onAverageSalaryPeriodChange={handleAverageSalaryPeriodChange}
        onPeriodChange={handlePeriodChange}
        onExchangeRateDateChange={handleExchangeRateDateChange}
        onReload={onReload}
        onDownload={onDownload}
        isModified={isModified}
        onUpdateValues={updateValues}
        role={role}
      />
      {error && (
        <Alert
          className={styles.error}
          style={errorStyle}
          message={error}
          type="error"
          showIcon
        />
      )}
      <TableListView
        size="small"
        dataSource={formattedData}
        summary={getSummary}
        pagination={false}
        columns={tableColumns}
        loading={isPaysheetsLoading}
        onRow={onRow}
        rowClassName={undefined}
        scroll={{ y: '80vh', x: 1800 }}
        expandable={{
          onExpand,
          expandedRowRender: record => <PaysheetView paysheet={record} />,
          expandedRowKeys,
          rowExpandable: () => true,
        }}
      />
    </Fragment>
  );
};

export default PaysheetTable;
