exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dashboardDropdown-2db32{display:flex;flex-direction:column;align-items:center;justify-content:space-between;width:200px;margin-left:20px;padding:9px 12px;background-color:#fff;border:1px solid #d9d9d9;border-radius:8px;cursor:pointer}.dashboardDropdown-2db32 .content-a306e{display:flex;align-items:center;justify-content:space-between;width:100%}.dashboardDropdown-2db32 span{color:#595959;line-height:22px}.dashboardDropdown-2db32 .appliedFilter-9be85{color:#1677ff}.dashboardDropdown-2db32 .icon-aea87{width:9px;height:6px;color:#262626}.dashboardDropdown-2db32 .filters-9e89a{display:flex;flex-direction:column;align-items:center;font-weight:500}", ""]);

// Exports
exports.locals = {
	"dashboardDropdown": "dashboardDropdown-2db32",
	"content": "content-a306e",
	"appliedFilter": "appliedFilter-9be85",
	"icon": "icon-aea87",
	"filters": "filters-9e89a"
};