import { ROLE_TYPES } from 'utils/permissions';

export const REQUESTS_COMMON = {
  id: 'id',
  id_title: 'ID',
  request_type: 'request_type',
  request_type_title: 'Type',
  summary_employee: 'summary_employee',
  summary_employee_title: 'Employee',
  amount: 'amount',
  amount_title: 'Amount',
  attachments: 'attachments',
  attachments_title: 'Attachments',
  description: 'description',
  description_title: 'Description',
  salary: 'extra_payment',
  salary_title: 'Salary',
  profile: 'approved_employee_name',
  profile_title: 'Profile',
  sent_at: 'sent_at',
  sent_at_title: 'Sent at',
  reviewed_at: 'reviewed_at',
  reviewed_at_title: 'Reviewed at',
  status: 'status',
  status_title: 'Status',
  reviewer: 'reviewer',
  reviewer_title: 'Reviewer',
  sick_day_date: 'sick_day_date',
  sick_day_date_title: 'Sick day date',
  full_name: 'full_name',
};

export const REQUEST_TYPES = {
  sport: { key: 'sport', title: 'Sport' },
  sick_day: { key: 'sick_day', title: 'Sick day' },
  education: { key: 'education', title: 'Education' },
  health: { key: 'health', title: 'Health' },
};

export const SOCIAL_REQUESTS_STATUSES = {
  approved: { key: 'approved', color: 'green' },
  declined: { key: 'declined', color: 'red' },
  pending_in_requests: { key: 'pending_in_requests', color: 'gold' },
  pending_in_extra_payments: {
    key: 'pending_in_extra_payments',
    color: 'gold',
  },
};

export const APPROVES_STATUSES = [
  SOCIAL_REQUESTS_STATUSES.approved.key,
  SOCIAL_REQUESTS_STATUSES.pending_in_extra_payments.key,
];

export const REQUESTS_PERMISSIONS = {
  balance: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  healthReview: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  sportReview: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
  ],
  educationReview: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
  ],
  sickdayReview: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
  ],
};
