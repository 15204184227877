import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Input from 'components/Common/Input';
import { Button } from 'components/Common';
import { login } from 'redux/Auth/actions';
import { authSelector } from 'redux/selectors';
import { ROUTING } from 'config/routing';
import { LOGIN_MESSAGES } from './constants';

import styles from './styles.scss';

const FormError = ({ error }) => <div className={styles.error}>{error}</div>;

FormError.propTypes = { error: PropTypes.string };

const LoginForm = ({ form }) => {
  const { isUserLoading, isLogged } = useSelector(authSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isLogged) {
      history.push(ROUTING.EMPLOYEES_SUMMARY.path);
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        dispatch(login(values));
      }
    });
  };

  const { getFieldDecorator } = form;

  const lockIcon = <Icon type="lock" className={styles.inputIcon} />;
  const userIcon = <Icon type="mail" className={styles.inputIcon} />;

  return (
    <Form layout="vertical" className={styles.login} onSubmit={handleSubmit}>
      <h1>Login</h1>
      <Form.Item>
        {getFieldDecorator('username', {
          rules: [
            {
              required: true,
              message: LOGIN_MESSAGES.EMPTY_USERNAME,
            },
          ],
        })(<Input prefix={userIcon} placeholder="Username" />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: LOGIN_MESSAGES.EMPTY_PASSWORD,
            },
          ],
        })(<Input prefix={lockIcon} type="password" placeholder="Password" />)}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isUserLoading}>
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

LoginForm.propTypes = {
  form: PropTypes.object.isRequired,
};

const WrappedLoginForm = Form.create({ name: 'login' })(LoginForm);

export default WrappedLoginForm;
