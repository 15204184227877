exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".profileMain-d5efd{padding:24px;border-radius:12px;background-color:#fff;box-shadow:0 4px 20px 0 rgba(112,112,128,.1);width:calc(100% - 364px);padding:0}.profileMain-d5efd .ant-tabs-ink-bar{display:none}.profileMain-d5efd .ant-tabs-nav{padding:12px 24px;margin-bottom:0 !important}.profileMain-d5efd .ant-tabs-nav:before{border-bottom-color:#d9d9d9 !important}.profileMain-d5efd .commentsTab-8fe8c{display:flex;justify-content:space-between}.profileMain-d5efd .hoverButton-289ca{visibility:hidden}.profileMain-d5efd .field-0a650:hover .hoverButton-289ca{visibility:visible}.profileMain-d5efd .profileButton-1a81b a{color:#fff}", ""]);

// Exports
exports.locals = {
	"profileMain": "profileMain-d5efd",
	"commentsTab": "commentsTab-8fe8c",
	"hoverButton": "hoverButton-289ca",
	"field": "field-0a650",
	"profileButton": "profileButton-1a81b"
};