exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".modal-30c8d>div>button>span{display:flex;justify-content:center;align-items:center}.employeeTable-bfba2 table>tbody>tr>td{padding:0}.employeeTable-bfba2 table>tbody>tr>td a{display:block;padding:16px;color:#000}.statusDropdownBtns-b6a27{display:flex;justify-content:space-between;width:100%;padding:5px}.statusMenuItem-44c8e{padding:5px 12px !important;margin:0 !important}.filterDropdown-c1bd1{max-width:250px;padding:8px}.input-a7688{display:block;width:180px;margin-bottom:8px}.button-9e433{width:90px}.searchButtons-082e0{justify-content:space-between;margin-top:5px}.cellLink-72f90{white-space:nowrap;width:120px;display:block;overflow:hidden;text-overflow:ellipsis}", ""]);

// Exports
exports.locals = {
	"modal": "modal-30c8d",
	"employeeTable": "employeeTable-bfba2",
	"statusDropdownBtns": "statusDropdownBtns-b6a27",
	"statusMenuItem": "statusMenuItem-44c8e",
	"filterDropdown": "filterDropdown-c1bd1",
	"input": "input-a7688",
	"button": "button-9e433",
	"searchButtons": "searchButtons-082e0",
	"cellLink": "cellLink-72f90"
};