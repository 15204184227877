import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const DashboardAERTotalHeader = ({ penultimateMonth, lastMonth }) => (
  <div className={styles.dashboardAERTotalHeader}>
    <span>{penultimateMonth}</span>
    <span>{lastMonth}</span>
  </div>
);

DashboardAERTotalHeader.propTypes = {
  penultimateMonth: PropTypes.string.isRequired,
  lastMonth: PropTypes.string.isRequired,
};

export default DashboardAERTotalHeader;
