import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tree } from 'antd';

import { transformActivitiesData } from './utils';

const LeadActivities = ({ leadActivities }) => {
  const transformedActivitesData = useMemo(
    () => transformActivitiesData(leadActivities),
    [leadActivities],
  );

  return (
    <Tree
      showLine={{ showLeafIcon: false }}
      treeData={transformedActivitesData}
    />
  );
};

LeadActivities.propTypes = {
  leadActivities: PropTypes.array,
};

export default LeadActivities;
