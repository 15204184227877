exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".tooltipContentPie-a4c19{box-shadow:0 4px 20px 0 rgba(112,112,128,.1);padding:20px;border:1px solid #d9d9d9;border-radius:8px;background-color:#fff}.tooltipContentPie-a4c19 .items-9732c .item-1e0f8{display:flex;align-items:center;justify-content:space-between;min-width:150px;margin-bottom:12px;font-size:14px;color:#595959}.tooltipContentPie-a4c19 .items-9732c .left-b17c0{display:flex;align-items:center;margin-right:10px}.tooltipContentPie-a4c19 .items-9732c .icon-59000{width:12px;height:12px;margin-right:6px;border-radius:4px}", ""]);

// Exports
exports.locals = {
	"tooltipContentPie": "tooltipContentPie-a4c19",
	"items": "items-9732c",
	"item": "item-1e0f8",
	"left": "left-b17c0",
	"icon": "icon-59000"
};