import React from 'react';
import PropTypes from 'prop-types';
import { getMonthNameWithYear } from 'utils/helpers';
import { DASHBOARD_OVERVIEW_TOOLTIPS } from 'containers/DashboardOverviewPage/constants';
import ProfitAndLossProjectItem from './ProfitAndLossProjectItem';
import { getGPM, getMonth, getRevenue } from './utils';

import styles from './styles.scss';
import ProfitAndLossTotalItem from './ProfitAndLossTotalItem';

const ProfitAndLossTotal = ({
  monthsData,
  mostProfitableProject,
  mostUnprofitableProject,
}) => {
  const lastMonth = getMonth({ monthsData, index: 1 });
  const penultimateMonth = getMonth({ monthsData, index: 2 });

  const lastMonthName = getMonthNameWithYear(lastMonth?.month);
  const penultimateMonthName = getMonthNameWithYear(penultimateMonth?.month);

  const lastMonthRevenue = getRevenue(lastMonth);
  const penultimateMonthRevenue = getRevenue(penultimateMonth);

  const lastMonthGPM = getGPM(lastMonth);
  const penultimateMonthGPM = getGPM(penultimateMonth);

  const mostProfitableProjectGPM = getGPM(mostProfitableProject);
  const mostUnprofitableProjectGPM = getGPM(mostUnprofitableProject);

  return (
    <div className={styles.profitAndLossTotal}>
      <ProfitAndLossTotalItem
        title="Revenue"
        tooltipContent={DASHBOARD_OVERVIEW_TOOLTIPS.REVENUE}
        penultimateMonthValue={penultimateMonth?.revenue_sum}
        lastMonthValue={lastMonth?.revenue_sum}
        penultimateMonthFormattedValue={penultimateMonthRevenue}
        lastMonthFormattedValue={lastMonthRevenue}
        penultimateMonth={penultimateMonthName}
        lastMonth={lastMonthName}
      />
      <ProfitAndLossTotalItem
        title="GPM %"
        tooltipContent={DASHBOARD_OVERVIEW_TOOLTIPS.GPM}
        penultimateMonthValue={penultimateMonth?.g_p_m}
        lastMonthValue={lastMonth?.g_p_m}
        penultimateMonthFormattedValue={penultimateMonthGPM}
        lastMonthFormattedValue={lastMonthGPM}
        penultimateMonth={penultimateMonthName}
        lastMonth={lastMonthName}
      />
      <ProfitAndLossProjectItem
        title="The most profitable project"
        projectName={mostProfitableProject?.project_name}
        tooltip={DASHBOARD_OVERVIEW_TOOLTIPS.MPP}
        gpm={mostProfitableProjectGPM}
      />
      <ProfitAndLossProjectItem
        title="The most unprofitable project"
        projectName={mostUnprofitableProject?.project_name}
        tooltip={DASHBOARD_OVERVIEW_TOOLTIPS.MUP}
        gpm={mostUnprofitableProjectGPM}
      />
    </div>
  );
};

ProfitAndLossTotal.propTypes = {
  monthsData: PropTypes.array,
  mostProfitableProject: PropTypes.object,
  mostUnprofitableProject: PropTypes.object,
};

export default ProfitAndLossTotal;
