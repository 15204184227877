export const types = {
  GET_EXCHANGE_RATES: 'exchange-rates/GET_EXCHANGE_RATES',
  GET_EXCHANGE_RATES_SUCCESS: 'exchange-rates/GET_EXCHANGE_RATES_SUCCESS',
  GET_EXCHANGE_RATES_FAILED: 'exchange-rates/GET_EXCHANGE_RATES_FAILED',

  CREATE_EXCHANGE_RATES: 'exchange-rates/CREATE_EXCHANGE_RATES',
  CREATE_EXCHANGE_RATES_SUCCESS: 'exchange-rates/CREATE_EXCHANGE_RATES_SUCCESS',
  CREATE_EXCHANGE_RATES_FAILED: 'exchange-rates/CREATE_EXCHANGE_RATES_FAILED',

  UPDATE_EXCHANGE_RATES: 'exchange-rates/UPDATE_EXCHANGE_RATES',
  UPDATE_EXCHANGE_RATES_SUCCESS: 'exchange-rates/UPDATE_EXCHANGE_RATES_SUCCESS',
  UPDATE_EXCHANGE_RATES_FAILED: 'exchange-rates/UPDATE_EXCHANGE_RATES_FAILED',

  GET_EXCHANGE_RATE_FOR_DATE: 'exchange-rates/GET_EXCHANGE_RATE_FOR_DATE',
  GET_EXCHANGE_RATE_FOR_DATE_SUCCESS:
    'exchange-rates/GET_EXCHANGE_RATE_FOR_DATE_SUCCESS',
  GET_EXCHANGE_RATE_FOR_DATE_FAILED:
    'exchange-rates/GET_EXCHANGE_RATE_FOR_DATE_FAILED',
};
