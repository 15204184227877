exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".iconPlus-565da{display:inline-block}.iconPlus-565da>i{margin:1px}.timeField-7827c{font-size:14px;text-align:center;width:50px}.userTitle-e4bfd{display:flex;align-items:center;width:100%}.nameUser-2f391,.positionTitle-944f5{display:block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:150px}.positionTitle-944f5{font-size:14px;font-style:italic}.technologiesPopover-45440{font-size:14px;font-style:italic}.nameTech-c54c8>span{font-size:14px}.techList-8c262{padding:0px;margin:0px}.cell-be477{width:calc(100% + 1px);position:absolute;display:flex;align-items:center;justify-content:center;left:0;z-index:1;height:55px}.contentCell-e014e{width:100%;height:100%;display:flex;justify-content:center;align-items:center}", ""]);

// Exports
exports.locals = {
	"iconPlus": "iconPlus-565da",
	"timeField": "timeField-7827c",
	"userTitle": "userTitle-e4bfd",
	"nameUser": "nameUser-2f391",
	"positionTitle": "positionTitle-944f5",
	"technologiesPopover": "technologiesPopover-45440",
	"nameTech": "nameTech-c54c8",
	"techList": "techList-8c262",
	"cell": "cell-be477",
	"contentCell": "contentCell-e014e"
};