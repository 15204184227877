import { ROLE_TYPES } from 'utils/permissions';

export const loadEmployeeParams = {
  page: 1,
  size: 1000,
  sort: 'full_name:ascend',
  filters: {},
};
export const SUBCONTRACTORS_MISSING_IBAN_STATUS = 'MISSING_IBAN';
export const INVALID_DATE = 'Invalid date';

export const PROFILES_MESSAGES = {
  SUCCESS: 'Successfully',
  ERROR: 'Error',
  EMPLOYEE_UPDATE_SUCCESS: 'Employee updated successfully',
  EMPLOYEE_UPDATE_ERROR: 'Failed to update employee',
  COMMENT_UPLOAD_ERROR: 'Failed to load comments',
  COMMENT_ADD_SUCCESS: 'Comment added',
  COMMENT_ADD_ERROR: 'Failed to add comment',
  COMMENT_DELETE_ERROR: 'Failed to delete comment',
  COMMENT_DELETE_SUCCESS: 'Comment deleted',
  COMMENT_UPDATE_SUCCESS: 'Comment updated',
  COMMENT_UPDATE_ERROR: 'Failed to update comment',
  DATA_LOAD_ERROR: 'Failed to load data',
  BUDDIES_UPDATE_SUCCESS: 'Buddies updated successfully',
  BUDDIES_UPDATE_ERROR: 'Buddies update failed',
};

export const PROFILES_PERMISSIONS = {
  updateRm: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.office_manger,
    ROLE_TYPES.hr,
  ],
  updateBuddy: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.dm,
    ROLE_TYPES.pm,
    ROLE_TYPES.office_manger,
    ROLE_TYPES.hr,
  ],
  updateProfilePage: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.office_manger,
  ],
  salaries: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.office_manger,
    ROLE_TYPES.pm,
    ROLE_TYPES.dm,
  ],
  comments: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.hr,
    ROLE_TYPES.pm,
    ROLE_TYPES.dm,
  ],
  addComment: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.hr,
    ROLE_TYPES.pm,
    ROLE_TYPES.dm,
  ],
  deleteComment: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.hr,
    ROLE_TYPES.pm,
    ROLE_TYPES.dm,
  ],
  addExtraPayment: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.dm,
    ROLE_TYPES.pm,
  ],
  updateExtraPayment: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.dm,
    ROLE_TYPES.pm,
  ],
  deleteExtraPayment: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  approveExtraPayment: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  declineExtraPayment: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  attachments: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.dm,
    ROLE_TYPES.pm,
    ROLE_TYPES.office_manger,
    ROLE_TYPES.hr,
  ],
  addAttachment: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.dm,
    ROLE_TYPES.pm,
    ROLE_TYPES.office_manger,
    ROLE_TYPES.hr,
  ],
  deleteAttachment: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
  ],
  healthBalance: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  educationBalance: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
  ],
  sportBalance: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
  ],
  sickdayBalance: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
  ],
  healthRequest: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  educationRequest: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
  ],
  sportRequest: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
  ],
  sickdayRequest: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
  ],
  request: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist, ROLE_TYPES.hr_lead],
  updateTechnologies: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.hr,
    ROLE_TYPES.office_manger,
  ],
};
