import React, { useState, useEffect, useMemo, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import lodash from 'lodash';
import { Modal } from 'antd';

import PageHeader from 'components/Common/PageHeader';
import { IncomingInvoicesModal } from 'components/IncomingInvoicesCommon';
import { Button, Loader, Table } from 'components/Common';
import DatePicker from 'components/Common/DatePicker';
import { BEGIN_MONTH_FORMAT } from 'utils/timeConstants';
import { getFiltersWithOutNull } from 'utils/common';
import {
  getIncomingInvoices,
  createIncomingInvoice,
  updateIncomingInvoice,
  approveIncomingInvoiceByApprover,
  approveIncomingInvoiceByFinancier,
  declineIncomingInvoiceByApprover,
  declineIncomingInvoiceByFinancier,
  deleteIncomingInvoice,
} from 'redux/IncomingInvoices/actions';
import { getOrganizations } from 'redux/Organizations/actions';
import { getInvoiceTypes } from 'redux/InvoiceTypes/actions';
import { getBanks } from 'redux/Banks/actions';
import { getCurrencies } from 'redux/Currencies/actions';
import { loadUsers } from 'redux/Users/actions';
import { loadEmployees } from 'redux/Employees/actions';
import {
  banksSelector,
  currenciesSelector,
  employeesSelector,
  incomingInvoicesSelector,
  invoiceTypesSelector,
  jiraSelector,
  organizationsSelector,
  usersSelector,
} from 'redux/selectors';
import { getRowKey } from 'utils/helpers';

import { getColumns } from './utils';
import { EMPLOYEES_REQ_PARAMS, USERS_REQ_PARAMS } from './constants';

import styles from './styles.scss';

const IncomingInvoicesPage = () => {
  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false);
  const [period, setPeriod] = useState(dayjs());
  const [filterInfo, setFilterInfo] = useState({});
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();

  const searchInput = createRef();

  const {
    isIncomingInvoicesLoading,
    incomingInvoices,
    incomingInvoicesFilters,
  } = useSelector(incomingInvoicesSelector);

  const { users } = useSelector(usersSelector);

  const { employees } = useSelector(employeesSelector);

  const { organizationsFull } = useSelector(organizationsSelector);

  const { banks } = useSelector(banksSelector);

  const { currencies } = useSelector(currenciesSelector);

  const { invoiceTypes } = useSelector(invoiceTypesSelector);

  const { jiraProjects } = useSelector(jiraSelector);

  useEffect(() => {
    dispatch(getOrganizations());
    dispatch(loadEmployees(EMPLOYEES_REQ_PARAMS));
    dispatch(getCurrencies());
    dispatch(loadUsers(USERS_REQ_PARAMS));
    dispatch(getInvoiceTypes());
    dispatch(getBanks());
  }, []);

  const fetchIncomingInvoices = ({ period, filters }) => {
    if (filters.invoice_number) return;

    dispatch(
      getIncomingInvoices({
        period: dayjs(period).format(BEGIN_MONTH_FORMAT),
        filters,
      }),
    );
  };

  useEffect(() => {
    fetchIncomingInvoices({ period, filters: filterInfo });
  }, [period, filterInfo]);

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const onPeriodChange = value => {
    setPeriod(value);
  };

  const toggleInvoiceModal = isVisible => () => {
    setIsInvoiceModalVisible(isVisible);

    if (!isVisible) {
      setCurrentInvoice(null);
    }
  };

  const onInvoiceEdit = record => () => {
    setIsInvoiceModalVisible(true);
    setCurrentInvoice(record);
  };

  const onUpdateIncomingInvoice = invoice => {
    dispatch(updateIncomingInvoice(invoice));
    toggleInvoiceModal(false)();
  };

  const onCreateIncomingInvoice = invoice => {
    dispatch(createIncomingInvoice(invoice));
    toggleInvoiceModal(false)();
  };

  const onDeleteIncomingInvoice = id => () => {
    Modal.confirm({
      title: 'Delete incoming invoice',
      content: 'Are you sure you want to delete this invoice?',
      onOk: () => {
        dispatch(deleteIncomingInvoice(id));
        Modal.destroyAll();
      },
    });
  };

  const onTableChange = (pagination, filters) => {
    const filtersWithoutNull = getFiltersWithOutNull(filters);

    if (lodash.isEqual(filtersWithoutNull, filterInfo)) return;

    setFilterInfo(filtersWithoutNull);
  };

  const approveByFinancier = id => {
    Modal.confirm({
      title: 'Approve incoming invoice',
      content: 'Are you sure you want to approve this invoice?',
      onOk: () => {
        dispatch(approveIncomingInvoiceByFinancier(id));
        Modal.destroyAll();
      },
    });
  };

  const declineByFinancier = id => {
    Modal.confirm({
      title: 'Decline incoming invoice',
      content: 'Are you sure you want to decline this invoice?',
      onOk: () => {
        dispatch(declineIncomingInvoiceByFinancier(id));
        Modal.destroyAll();
      },
    });
  };

  const approveByApprover = id => {
    Modal.confirm({
      title: 'Approve incoming invoice',
      content: 'Are you sure you want to approve this invoice?',
      onOk: () => {
        dispatch(approveIncomingInvoiceByApprover(id));
        Modal.destroyAll();
      },
    });
  };

  const declineByApprover = id => {
    Modal.confirm({
      title: 'Decline incoming invoice',
      content: 'Are you sure you want to decline this invoice?',
      onOk: () => {
        dispatch(declineIncomingInvoiceByApprover(id));
        Modal.destroyAll();
      },
    });
  };

  const columns = useMemo(
    () =>
      getColumns({
        employees,
        organizations: organizationsFull,
        banks,
        currencies,
        users,
        filters: incomingInvoicesFilters,
        onInvoiceEdit,
        filterInfo,
        approveByFinancier,
        declineByFinancier,
        approveByApprover,
        declineByApprover,
        onDeleteIncomingInvoice,
        searchInput,
        handleReset,
      }),
    [
      incomingInvoices,
      employees,
      organizationsFull,
      banks,
      currencies,
      users,
      incomingInvoicesFilters,
      filterInfo,
      searchText,
      searchInput,
    ],
  );

  return (
    <div className={styles.incomingInvoicesPage}>
      <PageHeader
        title="Incoming invoices"
        extra={
          <div>
            <DatePicker
              picker="month"
              value={period}
              onChange={onPeriodChange}
            />
            <Button
              type="primary"
              onClick={toggleInvoiceModal(true)}
              className={styles.addInvoiceButton}
            >
              Add incoming invoice
            </Button>
          </div>
        }
      />
      <IncomingInvoicesModal
        employees={employees}
        organizations={organizationsFull}
        invoiceTypes={invoiceTypes}
        projects={jiraProjects}
        users={users}
        banks={banks}
        currencies={currencies}
        currentInvoice={currentInvoice}
        isVisible={isInvoiceModalVisible}
        onUpdateIncomingInvoice={onUpdateIncomingInvoice}
        onCreateIncomingInvoice={onCreateIncomingInvoice}
        toggleInvoiceModal={toggleInvoiceModal}
      />
      <Loader loading={isIncomingInvoicesLoading} isDynamicPosition>
        <Table
          dataSource={incomingInvoices}
          columns={columns}
          onChange={onTableChange}
          rowKey={getRowKey}
        />
      </Loader>
    </div>
  );
};

export default IncomingInvoicesPage;
