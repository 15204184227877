import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import dayjs from 'dayjs';

import AvatarWithName from 'components/AvatarWithName';
import { getInitials } from 'utils/getInitials';

export const getAvatarAuthor = ({ user, users, employees = [] }) => {
  const currentUser = users.find(item => get(item, 'id') === user?.id);
  const currentEmployee =
    employees.find(
      item =>
        get(item, ['jira_user', 'displayName']) === currentUser?.name ||
        get(item, ['email']) === currentUser?.email,
    ) || [];
  const userName = currentUser?.name || currentUser?.email || user?.name || '-';

  return (
    <AvatarWithName
      payload={getInitials(userName) || userName}
      avatarUrl={
        userName !== '-' &&
        get(
          currentEmployee,
          ['jira_user', 'avatarUrls', '32x32'],
          'https://www.gravatar.com/avatar/e5ffa8aecb9a36a76676407d8bfd1d36?d=mm&s=32',
        )
      }
      title={userName}
    />
  );
};

getAvatarAuthor.propTypes = {
  user: PropTypes.object,
  users: PropTypes.array,
  employees: PropTypes.array,
};

export const filterByPeriod = ({ data, period }) =>
  data.filter(item => {
    const { valid_from = '' } = item;

    return dayjs(valid_from).format('YYYY/MM/01') === period;
  });
