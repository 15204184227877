import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';

import { LEAD_TABS } from 'containers/LeadPage/constants';
import { Button } from 'components/Common';

import styles from './styles.scss';

const LeadFiltersCommon = ({ tab, onChangeTab, onShowModal }) => {
  const items = Object.values(LEAD_TABS).map(tab => ({
    label: tab,
    key: tab,
  }));

  return (
    <div className={styles.leadTabs}>
      <Tabs
        items={items}
        activeKey={tab}
        onChange={onChangeTab}
        tabBarExtraContent={<Button onClick={onShowModal}>Add record</Button>}
      />
    </div>
  );
};

LeadFiltersCommon.propTypes = {
  tab: PropTypes.string,
  onChangeTab: PropTypes.func,
  onShowModal: PropTypes.func,
};

export default LeadFiltersCommon;
