export const onValueSelect = ({
  getDashboard,
  dashboardDefaultRequest,
  dispatch,
  values,
  setValues,
  field,
  setVisible,
}) => {
  setValues(prev => ({
    ...prev,
    applied: prev.selected,
  }));
  dispatch(
    getDashboard({ ...dashboardDefaultRequest, [field]: values.selected }),
  );
  setVisible(false);
};

export const onReset = ({
  getDashboard,
  dashboardDefaultRequest,
  field,
  setValue,
  dispatch,
}) => {
  dispatch(
    getDashboard({
      ...dashboardDefaultRequest,
      [field]: [],
    }),
  );
  setValue({ selected: [], applied: [] });
};

export const onValueChange = ({ value, values, setValues }) => {
  if (values.selected.includes(value) || values.applied.includes(value)) {
    const filteredSelectedValues = values.selected.filter(
      type => type !== value,
    );
    setValues(prev => ({
      ...prev,
      selected: filteredSelectedValues,
    }));

    return;
  }

  setValues(prev => ({
    ...prev,
    selected: [...prev.selected, value],
  }));
};
