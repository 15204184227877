import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isExtraPaymentsLoading: false,
  extraPayments: [],
  extraPaymentsFilters: {},
  totalExtraPayments: null,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_EXTRA_PAYMENTS:
    case types.DELETE_EXTRA_PAYMENT:
    case types.UPDATE_EXTRA_PAYMENT:
    case types.APPROVE_EXTRA_PAYMENT:
    case types.DECLINE_EXTRA_PAYMENT:
      return state.set('isExtraPaymentsLoading', true);

    case types.GET_EXTRA_PAYMENTS_SUCCESS:
      return state
        .set('extraPayments', payload.data.data)
        .set('extraPaymentsFilters', payload.data.data_for_filters)
        .set('isExtraPaymentsLoading', false)
        .set(
          'totalExtraPayments',
          parseInt(payload.headers['x-total-count'], 10),
        );
    case types.DELETE_EXTRA_PAYMENT_SUCCESS:
    case types.APPROVE_EXTRA_PAYMENT_SUCCESS:
    case types.DECLINE_EXTRA_PAYMENT_SUCCESS:
      return state.set('isExtraPaymentsLoading', false);

    case types.GET_EXTRA_PAYMENTS_FAILED:
    case types.DELETE_EXTRA_PAYMENT_FAILED:
    case types.UPDATE_EXTRA_PAYMENT_FAILED:
    case types.APPROVE_EXTRA_PAYMENT_FAILED:
    case types.DECLINE_EXTRA_PAYMENT_FAILED:
      return state.set('isExtraPaymentsLoading', false);

    default:
      return state;
  }
}
