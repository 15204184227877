import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import { debounce, get } from 'lodash';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { useIndexedDBStore } from 'use-indexeddb';

import { PROFILES_PERMISSIONS } from 'containers/ProfilesPage/constants';
import { Button } from 'components/Common';
import { DB_STORES } from 'config/indexedDB';
import { hasRights } from 'utils/permissions';

import Comment from '../Comment';
import CommentEditor from '../CommentEditor';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './styles.scss';

const Comments = ({
  role,
  isWard,
  comments,
  onAddComment,
  onCommentDelete,
  onSaveEditedComment,
  authUser,
  employees,
  currentEmployeeId,
  isSummary,
}) => {
  const { getByID, update, deleteByID } = useIndexedDBStore(DB_STORES.comments);
  const [comment, setComment] = useState(EditorState.createEmpty());

  useEffect(() => {
    let commentState;
    /// Get comment draft from IndexedDB by employeeId as key
    getByID(currentEmployeeId)
      .then(commentObject => {
        if (commentObject) {
          /// Format and set as default comment state if draft has been found
          const contentState = stateFromHTML(commentObject.comment);
          commentState = EditorState.createWithContent(contentState);
        } else {
          commentState = EditorState.createEmpty();
        }

        setComment(commentState);
      })
      .catch(() => {
        setComment(EditorState.createEmpty());
      });
  }, []);

  const addCommentHandler = () => {
    onAddComment(stateToHTML(comment.getCurrentContent()));
    setComment(EditorState.createEmpty());
    deleteByID(currentEmployeeId);
  };

  const setCommentDraft = useCallback(
    debounce(comment => {
      if (comment.getCurrentContent().hasText()) {
        update({
          comment: stateToHTML(comment.getCurrentContent()),
          employeeId: currentEmployeeId,
        });
      } else {
        deleteByID(currentEmployeeId);
      }
    }, 1000),
    [],
  );

  useEffect(() => {
    if (comment) {
      setCommentDraft(comment);
    }
  }, [comment]);

  const isDisplayCommentEditor =
    (hasRights(role, PROFILES_PERMISSIONS.addComment) || isWard) && !isSummary;

  return (
    <div className={styles.comments}>
      <h3 className={styles.title}>Comments</h3>
      <List>
        {!!comments.length &&
          comments.map(comment => (
            <Comment
              key={get(comment, 'id')}
              comment={comment}
              onCommentDelete={onCommentDelete}
              onSaveEditedComment={onSaveEditedComment}
              role={role}
              authUser={authUser}
              isWard={isWard}
              employees={employees}
              isSummary={isSummary}
            />
          ))}
      </List>
      {isDisplayCommentEditor && (
        <>
          <div className={styles.commentEditor}>
            <CommentEditor comment={comment} setComment={setComment} />
          </div>
          <Button
            onClick={addCommentHandler}
            type="primary"
            disabled={!comment?.getCurrentContent().hasText()}
          >
            Add Comment
          </Button>
        </>
      )}
    </div>
  );
};

Comments.propTypes = {
  role: PropTypes.string,
  isWard: PropTypes.bool,
  authUser: PropTypes.object,
  comments: PropTypes.array,
  employees: PropTypes.array,
  onAddComment: PropTypes.func,
  onCommentDelete: PropTypes.func,
  onSaveEditedComment: PropTypes.func,
  currentEmployeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSummary: PropTypes.bool,
};

export default Comments;
