import dayjs from 'dayjs';
import { ROLE_TYPES } from 'utils/permissions';

export const totalType = 'TOTAL';

export const defaultMonth = dayjs()
  .startOf('month')
  .add(-1, 'month');

export const firebasePath = 'project-profits';

export const loadEmployeeParams = {
  page: 1,
  size: 1000,
  sort: 'full_name:ascend',
  filters: { status: [] },
};

export const PROJECT_CATEGORIES = {
  commercial: { title: 'commercial', color: 'geekblue', value: 'commercial' },
  supermachine: { title: 'supermachine', color: 'gold', value: 'supermachine' },
  internal: { title: 'internal', value: 'internal' },
  blank: { title: 'blank', value: null },
  archiv: { title: 'archiv', value: 'archiv' },
};

export const DEFAULT_FILTERS = { project_category: ['commercial'] };
export const TITLE_ARRAY = ['cost', 'revenue', 'profit'];
export const ICON_COLORS = {
  green: '#237804',
  red: '#a8071a',
  yellow: '#ad8b00',
  gray: '#595959',
};

export const CELL_COLORS = {
  green: '#f6ffed',
  yellow: '#fff7e6',
  red: '#fff1f0',
  gray: '',
};

export const EXTRA_PAYMENTS_STATUS = {
  approved: 'approved',
  declined: 'declined',
  pending: 'pending',
};

export const EMPLOYEE_WITHOUT_TEAM_ID = 99999;

export const PROJECT_PROFITS_INDEX = {
  project_key: 'project_key',
  project_name: 'project_name',
  spent_hours: 'spent_hours',
  cost: 'cost',
  revenue: 'revenue',
  adjustment: 'adjustment',
  profit: 'profit',
  profit_percent: 'profit_percent',
  project_category: 'project_category',
  project_manager: 'project_manager',
  sales_manager: 'sales_manager',
  project_title: 'Project',
  id_title: 'ID',
  hours_title: 'Hours',
  cost_title: 'Costs, USD',
  revenue_title: 'Revenue, USD',
  adjustment_title: 'Adjustment, USD',
  profit_title: 'Profit, USD',
  profit_percent_title: 'Profit, %',
  category_title: 'Category',
  pm_title: 'PM',
  bdm_title: 'BDM',
};

export const PP_PERMISSIONS = {
  requestPayment: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  projectModal: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
};
