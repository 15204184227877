import { takeLatest, call, put } from 'redux-saga/effects';

import API from 'utils/api';
import { SAGA_MESSAGES } from 'redux/sagaMessages';

import { sagaErrorHandler } from 'utils/common';
import { types } from './types';

function* getJiraRoles() {
  try {
    const { data } = yield call(API.getJiraRoles);
    yield put({ type: types.GET_JIRA_ROLES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_JIRA_ROLES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getJiraProjects({ payload }) {
  try {
    const { data } = yield call(API.getJiraProjects, { params: payload });
    yield put({
      type: types.GET_JIRA_PROJECTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_JIRA_PROJECTS_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* searchJiraUsers({ payload: { name } }) {
  try {
    const { data } = yield call(API.getJiraUsers, name);
    yield put({
      type: types.SEARCH_JIRA_USERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.SEARCH_JIRA_USERS_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* updateJiraProjects() {
  try {
    yield call(API.updateJiraProjects);

    yield put({ type: types.UPDATE_JIRA_PROJECTS_SUCCESS });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_JIRA_PROJECTS_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_JIRA_PROJECTS, getJiraProjects);
  yield takeLatest(types.GET_JIRA_ROLES, getJiraRoles);
  yield takeLatest(types.SEARCH_JIRA_USERS, searchJiraUsers);
  yield takeLatest(types.UPDATE_JIRA_PROJECTS, updateJiraProjects);
}
