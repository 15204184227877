import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  employees: [],
  employeesFilters: {},
  employeesTotal: 0,
  employeesFull: [],
  employeesWithJira: [],
  isEmployeesLoading: false,
  isEmployeeUpdateLoading: false,
  isEmployeeProfileDataLoading: false,

  profile: null,
  employeeComments: [],
  employeeExtraPayments: [],
  employeeSalaries: [],
  employeePaysheets: [],
  employeeTechnologies: [],
  employeeAttachments: null,
  ibanCurrencies: {},
  isAttachmentLoading: false,
  isEmployeeTechnologiesLoading: false,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.ADD_EMPLOYEE_COMMENT:
    case types.DELETE_EMPLOYEE_COMMENT:
    case types.ADD_EMPLOYEE_EXTRA_PAYMENTS:
    case types.UPDATE_EMPLOYEE:
    case types.UPDATE_EMPLOYEE_RM:
    case types.UPDATE_EMPLOYEE_EXTRA_PAYMENTS:
    case types.DELETE_EMPLOYEE_EXTRA_PAYMENTS:
    case types.APPROVE_EMPLOYEE_EXTRA_PAYMENTS:
    case types.DECLINE_EMPLOYEE_EXTRA_PAYMENTS:
    case types.DELETE_EMPLOYEE_SALARIES:
    case types.UPDATE_EMPLOYEE_SALARIES:
    case types.ADD_EMPLOYEE_SALARIES:
    case types.ADD_AND_UPDATE_TECHNOLOGIES:
    case types.DELETE_EMPLOYEE_ATTACHMENT:
    case types.UPDATE_RESOURCE_MANAGER:
    case types.UPDATE_BUDDIES:
    case types.UPDATE_EMPLOYEE_COMMENT:
      return state.set('isEmployeeUpdateLoading', true);
    case types.UPDATE_EMPLOYEE_TECHNOLOGIES:
      return state.set('isEmployeeTechnologiesLoading', true);
    case types.LOAD_EMPLOYEES:
    case types.GET_EMPLOYEE_EXTRA_PAYMENTS:
    case types.GET_EMPLOYEE_SALARIES:
    case types.GET_EMPLOYEE_ATTACHMENTS:
    case types.GET_FULL_EMPLOYEES:
    case types.GET_EMPLOYEE_PAYSHEETS:
    case types.GET_EMPLOYEE_TECHNOLOGIES:
    case types.GET_EMPLOYEES_WITH_JIRA:
      return state.set('isEmployeesLoading', true);
    case types.GET_EMPLOYEE_PROFILE_DATA:
      return state.set('isEmployeeProfileDataLoading', true);
    case types.GET_EMPLOYEE:
      return state.set('isEmployeesLoading', true).set('profile', null);
    case types.GET_EMPLOYEE_COMMENTS:
      return state.set('isEmployeesLoading', true).set('employeeComments', []);
    case types.ADD_EMPLOYEE_ATTACHMENT:
      return state.set('isAttachmentLoading', true);
    case types.SET_IBAN_CURRENCY:
      return state.set('ibanCurrencies', payload);
    case types.SET_IBAN_SELECTED_CURRENCY:
      return state.set('ibanCurrencies', {
        ...state.get('ibanCurrencies'),
        [payload.iban]: {
          currency_type: payload.currency_id,
          isEditable: true,
          iban_number: payload.iban,
        },
      });
    case types.CLEAR_EMPLOYEE_STORE:
      return state.set('profile', null);

    /// SUCCESS
    case types.LOAD_EMPLOYEES_SUCCESS:
      return state
        .set('employees', payload.data)
        .set('employeesFilters', payload.data_for_filters)
        .set('isEmployeesLoading', false)
        .set('employeesTotal', payload.total_data);
    case types.GET_EMPLOYEES_WITH_JIRA_SUCCESS:
      return state
        .set('employeesWithJira', payload)
        .set('isEmployeesLoading', false);
    case types.GET_EMPLOYEE_SUCCESS:
      return state
        .set('profile', payload)
        .set('isEmployeesLoading', false)
        .set('employeeAttachments', payload.attachments)
        .set('employeeTechnologies', payload.technologies);
    case types.UPDATE_EMPLOYEE_SUCCESS:
      return state
        .set('profile', payload)
        .set('isEmployeeUpdateLoading', false)
        .set('employeeAttachments', payload.attachments);
    case types.UPDATE_BUDDIES_SUCCESS:
      return state
        .set('profile', { ...state.get('profile'), buddy_ids: payload })
        .set('isEmployeeUpdateLoading', false);
    case types.UPDATE_RESOURCE_MANAGER_SUCCESS:
      return state
        .set('profile', { ...state.get('profile'), resource_manager: payload })
        .set('isEmployeeUpdateLoading', false);
    case types.GET_EMPLOYEE_PROFILE_DATA_SUCCESS:
      return state
        .set('employeeSalaries', payload.salaries)
        .set('employeeExtraPayments', payload.extraPayments)
        .set('employeeComments', payload.comments)
        .set('isEmployeeProfileDataLoading', false);
    case types.UPDATE_EMPLOYEE_TECHNOLOGIES_SUCCESS:
      return state
        .set('employeeTechnologies', payload)
        .set('isEmployeeTechnologiesLoading', false)
        .set('isEmployeeUpdateLoading', false);
    case types.UPDATE_EMPLOYEE_RM_SUCCESS:
      return state
        .set('isEmployeeUpdateLoading', false)
        .set('profile', { ...state.get('profile'), resource_manager: payload });
    case types.GET_EMPLOYEE_TECHNOLOGIES_SUCCESS:
      return state
        .set('employeeTechnologies', payload)
        .set('isEmployeesLoading', false);
    case types.ADD_AND_UPDATE_TECHNOLOGIES_SUCCESS:
      return state
        .set('employeeTechnologies', payload)
        .set('isEmployeeUpdateLoading', false);
    case types.DELETE_EMPLOYEE_SALARIES_SUCCESS:
    case types.ADD_EMPLOYEE_SALARIES_SUCCESS:
    case types.UPDATE_EMPLOYEE_SALARIES_SUCCESS:
      return state
        .set('employeeSalaries', payload)
        .set('isEmployeeUpdateLoading', false);
    case types.GET_EMPLOYEE_PAYSHEETS_SUCCESS:
      return state.set('employeePaysheets', payload);
    case types.GET_EMPLOYEE_COMMENTS_SUCCESS:
      return state
        .set('isEmployeesLoading', false)
        .set('employeeComments', payload);
    case types.ADD_EMPLOYEE_COMMENT_SUCCESS:
      return state
        .set('isEmployeeUpdateLoading', false)
        .set('employeeComments', [...state.get('employeeComments'), payload]);
    case types.DELETE_EMPLOYEE_COMMENT_SUCCESS:
      return state
        .set('isEmployeeUpdateLoading', false)
        .set(
          'employeeComments',
          state.get('employeeComments').filter(item => item.id !== payload),
        );
    case types.UPDATE_EMPLOYEE_COMMENT_SUCCESS:
      return state
        .set('isEmployeeUpdateLoading', false)
        .set(
          'employeeComments',
          state
            .get('employeeComments')
            .map(item => (payload.id === item.id ? payload : item)),
        );
    case types.GET_EMPLOYEE_ATTACHMENTS_SUCCESS:
      return state
        .set('isEmployeesLoading', false)
        .set('employeeAttachments', payload);
    case types.DELETE_EMPLOYEE_ATTACHMENT_SUCCESS:
      return state
        .set('isEmployeeUpdateLoading', false)
        .set('employeeAttachments', payload);
    case types.ADD_EMPLOYEE_ATTACHMENT_SUCCESS:
      return state
        .set('isAttachmentLoading', false)
        .set('employeeAttachments', payload);
    case types.GET_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
      return state
        .set('isEmployeesLoading', false)
        .set('employeeExtraPayments', payload);
    case types.GET_EMPLOYEE_SALARIES_SUCCESS:
      return state
        .set('isEmployeesLoading', false)
        .set('employeeSalaries', payload);
    case types.GET_FULL_EMPLOYEES_SUCCESS:
      return state
        .set('isEmployeesLoading', false)
        .set('employeesFull', payload);
    case types.UPDATE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
    case types.ADD_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
    case types.DELETE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
    case types.APPROVE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
    case types.DECLINE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
      return state
        .set('employeeExtraPayments', payload)
        .set('isEmployeeUpdateLoading', false);
    case types.GET_IBAN_CURRENCY_SUCCESS:
      return state.set('ibanCurrencies', {
        ...state.get('ibanCurrencies'),
        [payload.iban_number]: {
          currency_type: payload.currency_type,
          isEditable: payload.isEditable,
          iban_number: payload.iban_number,
        },
      });

    /// FAILED
    case types.GET_IBAN_CURRENCY_FAILED:
      return state.set('ibanCurrencies', {
        ...state.get('ibanCurrencies'),
        [payload]: null,
      });
    case types.ADD_EMPLOYEE_COMMENT_FAILED:
    case types.UPDATE_EMPLOYEE_COMMENT_FAILED:
    case types.DELETE_EMPLOYEE_COMMENT_FAILED:
    case types.ADD_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    case types.UPDATE_EMPLOYEE_FAILED:
    case types.UPDATE_EMPLOYEE_RM_FAILED:
    case types.UPDATE_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    case types.DELETE_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    case types.APPROVE_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    case types.DECLINE_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    case types.DELETE_EMPLOYEE_SALARIES_FAILED:
    case types.UPDATE_EMPLOYEE_SALARIES_FAILED:
    case types.ADD_AND_UPDATE_TECHNOLOGIES_FAILED:
    case types.DELETE_EMPLOYEE_ATTACHMENT_FAILED:
    case types.UPDATE_BUDDIES_FAILED:
    case types.UPDATE_RESOURCE_MANAGER_FAILED:
      return state.set('isEmployeeUpdateLoading', false);
    case types.UPDATE_EMPLOYEE_TECHNOLOGIES_FAILED:
      return state.set('isEmployeeTechnologiesLoading', false);
    case types.LOAD_EMPLOYEES_FAILED:
    case types.GET_EMPLOYEE_COMMENTS_FAILED:
    case types.GET_EMPLOYEE_FAILED:
    case types.GET_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    case types.GET_EMPLOYEE_SALARIES_FAILED:
    case types.GET_EMPLOYEE_ATTACHMENTS_FAILED:
    case types.GET_FULL_EMPLOYEES_FAILED:
    case types.GET_EMPLOYEE_PAYSHEETS_FAILED:
    case types.GET_EMPLOYEE_TECHNOLOGIES_FAILED:
    case types.GET_EMPLOYEES_WITH_JIRA_FAILED:
      return state.set('isEmployeesLoading', false);
    case types.GET_EMPLOYEE_PROFILE_DATA_FAILED:
      return state.set('isEmployeeProfileDataLoading', false);
    case types.ADD_EMPLOYEE_ATTACHMENT_FAILED:
      return state.set('isAttachmentLoading', false);

    default:
      return state;
  }
}
