exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".tableSettingsButton-acc81{display:flex;margin-top:15px;justify-content:space-between}.tableLastColumnTitle-33101{display:flex;justify-content:space-between;align-items:center}.tableDrawerWrapper-8c7df .ant-drawer-content{transition:.3s cubic-bezier(0.7, 0.3, 0.1, 1)}.columnSettings-3bee1{padding:6px;display:flex;justify-content:space-between}", ""]);

// Exports
exports.locals = {
	"tableSettingsButton": "tableSettingsButton-acc81",
	"tableLastColumnTitle": "tableLastColumnTitle-33101",
	"tableDrawerWrapper": "tableDrawerWrapper-8c7df",
	"columnSettings": "columnSettings-3bee1"
};