exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".cell-f70f5{position:absolute;top:1px;left:5px;display:flex;flex-direction:column;align-items:flex-start;height:calc(100% - 2px);width:calc(100% - 10px);padding:24px 5px 5px;font-size:14px;overflow-y:scroll}.cell-f70f5.dayOff-c21c0{background-color:rgba(245,198,198,.4)}.cell-f70f5.resetSelected-4fc72>div{border:none;background-color:#fff}.cell-f70f5 .holiday-f72f7{display:block;text-align:center;width:100%;min-height:25px;margin-bottom:4px;padding:3px 4px;font-size:14px;font-weight:700;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;cursor:pointer}.cell-f70f5 .addButton-2afb8{display:flex;justify-content:center;width:100%;padding:2px 0;visibility:hidden;font-size:14px;color:#979797;border:none;background-color:rgba(217,217,217,.5);cursor:pointer;outline:none}.cell-f70f5:hover .addButton-2afb8{visibility:initial}", ""]);

// Exports
exports.locals = {
	"cell": "cell-f70f5",
	"dayOff": "dayOff-c21c0",
	"resetSelected": "resetSelected-4fc72",
	"holiday": "holiday-f72f7",
	"addButton": "addButton-2afb8"
};