import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isOrganizationsLoading: false,
  organizations: [],
  organizationsFull: [],
  organizationAccounts: [],
  totalOrganizations: 1,
  error: null,
  invoiceAccounts: { customer: [], contractor: [], isAccountsLoading: false },
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_ORGANIZATIONS:
    case types.GET_ORGANIZATIONS_SEARCH:
    case types.CREATE_ORGANIZATION:
    case types.UPDATE_ORGANIZATION:
    case types.DELETE_ORGANIZATION:
    case types.GET_ORGANIZATION_ACCOUNTS:
    case types.CREATE_ORGANIZATION_ACCOUNT:
    case types.UPDATE_ORGANIZATION_ACCOUNT:
    case types.DELETE_ORGANIZATION_ACCOUNT:
      return state.set('isOrganizationsLoading', true);
    case types.GET_INVOICE_ORGANIZATION_ACCOUNTS:
      return state.setIn(['invoiceAccounts', 'isAccountsLoading'], true);
    case types.CLEAR_ORGANIZATION_ACCOUNTS:
      return state.set('organizationAccounts', []);

    case types.GET_ORGANIZATIONS_SEARCH_SUCCESS:
      return state
        .set('isOrganizationsLoading', false)
        .set('organizations', payload.data)
        .set('totalOrganizations', payload.headers['x-total-count']);
    case types.GET_ORGANIZATIONS_SUCCESS:
      return state
        .set('isOrganizationsLoading', false)
        .set('organizationsFull', payload);
    case types.GET_ORGANIZATION_ACCOUNTS_SUCCESS:
      return state
        .set('isOrganizationsLoading', false)
        .set('organizationAccounts', payload);
    case types.CREATE_ORGANIZATION_SUCCESS:
    case types.DELETE_ORGANIZATION_SUCCESS:
      return state.set('isOrganizationsLoading', false);

    case types.CREATE_ORGANIZATION_ACCOUNT_SUCCESS:
      return state
        .set('isOrganizationsLoading', false)
        .set('organizationAccounts', payload);
    case types.UPDATE_ORGANIZATION_ACCOUNT_SUCCESS:
    case types.DELETE_ORGANIZATION_ACCOUNT_SUCCESS:
    case types.UPDATE_ORGANIZATION_SUCCESS:
      return state.set('isOrganizationsLoading', false);

    case types.GET_INVOICE_ORGANIZATION_ACCOUNTS_SUCCESS:
      return state
        .setIn(['invoiceAccounts', 'isAccountsLoading'], false)
        .setIn(['invoiceAccounts', payload.field], payload.data);

    case types.GET_ORGANIZATIONS_FAILED:
    case types.GET_ORGANIZATIONS_SEARCH_FAILED:
    case types.CREATE_ORGANIZATION_FAILED:
    case types.DELETE_ORGANIZATION_FAILED:
    case types.UPDATE_ORGANIZATION_FAILED:
    case types.GET_ORGANIZATION_ACCOUNTS_FAILED:
    case types.CREATE_ORGANIZATION_ACCOUNT_FAILED:
    case types.UPDATE_ORGANIZATION_ACCOUNT_FAILED:
    case types.DELETE_ORGANIZATION_ACCOUNT_FAILED:
      return state.set('isOrganizationsLoading', false);
    case types.GET_INVOICE_ORGANIZATION_ACCOUNTS_FAILED:
      return state.setIn(['invoiceAccounts', 'isAccountsLoading'], false);

    default:
      return state;
  }
}
