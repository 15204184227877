import { takeLatest, call, put, select } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';

import { usersSelector } from 'redux/selectors';
import { USERS_EXPAND } from 'containers/Users/constants';
import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';
import { types } from './types';

function* loadUsers({ payload }) {
  try {
    const { data } = yield call(API.loadUsers, {
      params: payload,
    });

    yield put({
      type: types.GET_USERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_USERS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getUserPermissions() {
  try {
    const { data } = yield call(API.loadPermissions);
    yield put({ type: types.GET_USER_PERMISSIONS_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_USER_PERMISSIONS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* createUser({ payload: { data } }) {
  try {
    const { users } = yield select(usersSelector);
    const { data: userData } = yield call(API.createUser, data);
    users[userData.id] = userData;
    yield put({
      type: types.CREATE_USER_SUCCESS,
      payload: {
        data: users,
      },
    });

    yield put({
      type: types.SET_CURRENT_USER,
      payload: userData.id,
    });

    message.success(SAGA_MESSAGES.CREATE_USER_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_USER_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* deleteUser({ payload: { id, sort, size } }) {
  try {
    yield call(API.deleteUser, id);
    yield call(loadUsers, { payload: { sort, size, expand: USERS_EXPAND } });

    yield put({
      type: types.SET_CURRENT_USER,
      payload: null,
    });

    message.success(SAGA_MESSAGES.DELETE_USER_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_USER_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* updateUser({ payload: { id, data } }) {
  try {
    const { users } = yield select(usersSelector);
    const { data: userData } = yield call(API.editUser, id, data);

    const userIndex = users.findIndex(user => user.id === userData.id);
    users[userIndex] = userData;
    yield put({
      type: types.UPDATE_USER_SUCCESS,
      payload: {
        data: users,
      },
    });

    message.success(SAGA_MESSAGES.UPDATE_USER_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_USER_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* restoreUser({ payload: { id, sort, size, page } }) {
  try {
    yield call(API.restoreUser, id);
    yield call(loadUsers, {
      payload: { sort, size, page, expand: USERS_EXPAND },
    });
    message.success(SAGA_MESSAGES.RESTORE_USER_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.RESTORE_USER_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_USERS, loadUsers);
  yield takeLatest(types.CREATE_USER, createUser);
  yield takeLatest(types.DELETE_USER, deleteUser);
  yield takeLatest(types.UPDATE_USER, updateUser);
  yield takeLatest(types.RESTORE_USER, restoreUser);
  yield takeLatest(types.GET_USER_PERMISSIONS, getUserPermissions);
}
