import dayjs from 'dayjs';

export const CREATION_ROW = {
  key: 'new_allocation',
  id: 'new_allocation',
  currency_code: '',
  period_from: dayjs(),
  period_to: dayjs(),
  group_title: '',
  title: '',
  quantity: '',
  expected_quantity: '',
  original_quantity: '',
  unit: '',
  unit_price: '',
  discount: 0,
  tax_rate: 0,
  amount: '',
};

export const BUTTON_ROW = {
  group_title: null,
  title: null,
  quantity: null,
  expected_quantity: null,
  original_quantity: null,
  unit: null,
  unit_price: null,
  discount: null,
  tax_rate: null,
  amount: null,
  period_from: null,
  period_to: null,
  isAddButton: true,
  key: 'addButton',
  id: 'addButton',
};

export const FORM_FIELDS = [
  {
    field: 'group_title',
    type: 'text',
    label: 'Group title',
    message: 'Please input group title!',
  },
  {
    field: 'title',
    type: 'text',
    label: 'Item',
    message: 'Please input item!',
  },
  {
    field: 'unit',
    type: 'text',
    label: 'Unit',
    message: 'Please input unit!',
  },
  {
    field: 'quantity',
    type: 'number',
    label: 'Quantity',
    message: 'Please input quantity!',
  },
  {
    field: 'unit_price',
    type: 'number',
    label: 'Unit price',
    message: 'Please input unit price!',
  },
];

export const DEFAULT_VALUES = {
  period_from: dayjs(),
  period_to: dayjs(),
  discount: 0,
  tax_rate: 0,
  currency_code: 'USD',
  group_title: '',
  title: '',
  unit: '',
  quantity: null,
  unit_price: null,
  group_title_select: '',
};

export const GROUP_TITLE = 'group_title';
export const NEW_ALLOCATION = 'new_allocation';
export const CUSTOM = 'CUSTOM';
export const TITLE = 'title';
export const EXPECTED_QUANTITY = 'expected_quantity';
export const ORIGINAL_QUANTITY = 'original_quantity';
export const UNIT_PRICE = 'unit_price';
export const DISCOUNT = 'discount';
export const TAX_RATE = 'tax_rate';
export const UNIT = 'unit';
export const QUANTITY = 'quantity';
export const PERIOD_FROM = 'period_from';
export const PERIOD_TO = 'period_to';
export const CURRENCY_CODE = 'currency_code';
