exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".source-726bc{margin-top:12px;font-size:14px;color:#8c8c8c}.source-726bc .dealUpdated-16b15{margin-left:12px;padding-left:12px;border-left:1px solid #d9d9d9}.title-11658{font-size:16px;font-weight:500;display:inline;cursor:pointer}.organizationTitle-4fcdd{font-size:16px;font-weight:400;display:inline}.subtitle-eb2d1{color:#ccc;text-transform:capitalize}.updateTitle-5de85{display:flex;align-items:center}.actionType-c0fbf{margin-left:5px;text-transform:lowercase}.actionTitle-d23fb{font-size:16px;font-weight:600;display:flex}.dealSummaryInfo-e40eb{display:flex;justify-content:center;font-size:.88rem}.dealButtons-44a19{visibility:hidden}.dealButton-77c3d{margin-left:15px}.endDealPickerContainer-1887f{display:flex;justify-content:space-around;align-items:center;margin-top:30px}.dealDescriptionContainer-43f03{background-color:#fff;box-shadow:0 0 3px #ccc;border-radius:4px;display:inline-block;margin-top:10px;padding:10px}", ""]);

// Exports
exports.locals = {
	"source": "source-726bc",
	"dealUpdated": "dealUpdated-16b15",
	"title": "title-11658",
	"organizationTitle": "organizationTitle-4fcdd",
	"subtitle": "subtitle-eb2d1",
	"updateTitle": "updateTitle-5de85",
	"actionType": "actionType-c0fbf",
	"actionTitle": "actionTitle-d23fb",
	"dealSummaryInfo": "dealSummaryInfo-e40eb",
	"dealButtons": "dealButtons-44a19",
	"dealButton": "dealButton-77c3d",
	"endDealPickerContainer": "endDealPickerContainer-1887f",
	"dealDescriptionContainer": "dealDescriptionContainer-43f03"
};