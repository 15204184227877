import React, { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';

import { formatMoney } from 'containers/ProjectProfits/utils';
import DatePicker from 'components/Common/DatePicker';
import styles from './styles.scss';

const { Item } = Form;
const { Option } = Select;

const ExtraPaymentsForm = ({
  form,
  fieldValues,
  onFinish,
  getManagerProfiles,
  isManagerProfilesLoading,
  managerProfiles,
}) => {
  useEffect(() => {
    if (fieldValues) {
      getManagerProfiles({
        project_profit_id: fieldValues.id,
        jira_id: fieldValues.jiraId,
      });
    }
  }, [fieldValues]);

  useEffect(() => {
    if (managerProfiles.length === 1) {
      form.setFieldsValue({ employeeId: managerProfiles[0].id });
    }
  }, [managerProfiles]);

  const onChangeAmount = ({ target }) => {
    form.setFieldsValue({
      amount: target.value,
      percentage:
        fieldValues.profit &&
        fieldValues.revenue &&
        (
          (target.value / fieldValues[form.getFieldValue('source')]) *
          100
        ).toFixed(2),
    });
  };

  const onChangePercentage = ({ target }) => {
    if (fieldValues.profit && fieldValues.revenue) {
      form.setFieldsValue({
        amount: (
          fieldValues[form.getFieldValue('source')] *
          (target.value / 100)
        ).toFixed(2),
        percentage: target.value,
      });
    }
  };

  const onSourceChange = value => {
    switch (value) {
      case 'profit':
        form.setFieldsValue({ source: 'profit' });

        return;
      case 'revenue':
        form.setFieldsValue({ source: 'revenue' });

        return;
    }
  };

  const shouldAmountUpdate = (prevValues, currentValues) => {
    if (prevValues.source !== currentValues.source) {
      form.setFieldsValue({
        amount:
          fieldValues[currentValues.source] * (currentValues.percentage / 100),
      });

      return true;
    }

    return prevValues.percentage !== currentValues.percentage;
  };

  return (
    <Form
      layout="vertical"
      id="projectProfitsForm"
      form={form}
      onFinish={onFinish}
      fields={[
        { name: 'name', value: fieldValues.name },
        { name: 'projectName', value: fieldValues.projectName },
        {
          name: 'source',
          value: fieldValues.field === 'project_manager' ? 'profit' : 'revenue',
        },
        { name: 'period', value: fieldValues.period },
        { name: 'percentage', value: fieldValues.percentage },
        { name: 'employeeId', value: null },
        {
          name: 'amount',
          value: (
            fieldValues[
              fieldValues.field === 'project_manager' ? 'profit' : 'revenue'
            ] *
            (fieldValues.percentage / 100)
          ).toFixed(2),
        },
      ]}
    >
      <Item label="Person" name="name">
        <Input disabled />
      </Item>
      <Item label="Profile" name="employeeId">
        <Select
          loading={isManagerProfilesLoading}
          placeholder="Select profile for extra payment "
        >
          {managerProfiles.map(manager => (
            <Option value={manager.id} key={manager.id}>
              {manager.profile_name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item label="Project" name="projectName">
        <Input disabled />
      </Item>
      <Item label="Source" style={{ position: 'relative' }}>
        <Input.Group>
          <Item name="source" style={{ width: '60%' }} noStyle>
            <Select style={{ width: '50%' }} onChange={onSourceChange}>
              <Option value="revenue">Revenue</Option>
              <Option value="profit">Profit</Option>
            </Select>
          </Item>
          <Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.source !== currentValues.source
            }
            className={styles.totalField}
          >
            {({ getFieldValue }) =>
              getFieldValue('source') === 'profit'
                ? formatMoney(fieldValues.profit)
                : formatMoney(fieldValues.revenue)
            }
          </Item>
        </Input.Group>
      </Item>
      <Item label="Percentage" name="percentage" style={{ width: '60%' }}>
        <Input addonAfter="%" onChange={onChangePercentage} />
      </Item>
      <Item
        label="Amount"
        name="amount"
        style={{ width: '60%' }}
        shouldUpdate={shouldAmountUpdate}
        rules={[
          {
            pattern: /^(-)?(\d)*(\.)?([0-9]{1,2})?$/,
            message: 'No more than two decimal places',
          },
        ]}
      >
        <Input addonAfter="USD" onChange={onChangeAmount} />
      </Item>
      <Item label="Period" style={{ width: '60%' }} name="period">
        <DatePicker
          picker="month"
          format="YYYY/MM/01"
          placeholder="Period..."
          style={{ width: '100%' }}
        />
      </Item>
    </Form>
  );
};

ExtraPaymentsForm.propTypes = {
  form: PropTypes.object,
  fieldValues: PropTypes.object,
  onFinish: PropTypes.func,
  getManagerProfiles: PropTypes.func,
  isManagerProfilesLoading: PropTypes.bool,
  managerProfiles: PropTypes.array,
};

export default ExtraPaymentsForm;
