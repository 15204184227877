export const DASHBOARD_EMPLOYEE_PROJECTS_INDEX = {
  project_title: 'Project name',
  project_name: 'name',
  rate_title: 'Rate, USD',
  rate: 'rate',
  hours_title: 'Hours',
  hours: 'spent_hours',
};

export const DASHBOARD_EMPLOYEE_LABELS = {
  com: 'COM %',
  utilization: 'Utilization %',
};
