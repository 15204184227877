import React from 'react';
import PropTypes from 'prop-types';

import { formatNumberWithCommas } from 'utils/helpers';

import styles from './styles.scss';

const TooltipContent = ({ label, payload }) => (
  <div className={styles.tooltipContent}>
    <span className={styles.label}>{label}</span>
    <div className={styles.items}>
      {payload.map(item => (
        <div className={styles.item} key={item.name}>
          <div className={styles.left}>
            <span
              className={styles.icon}
              style={{ backgroundColor: item.color }}
            />
            <span className={styles.name}>{item.name}</span>
          </div>
          <span className={styles.value}>
            {formatNumberWithCommas(item.value)}
          </span>
        </div>
      ))}
    </div>
  </div>
);

TooltipContent.propTypes = {
  label: PropTypes.string,
  payload: PropTypes.array,
};

export default TooltipContent;
