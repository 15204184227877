export const types = {
  GET_INCOMING_INVOICES: 'incoming-invoices/GET_INCOMING_INVOICES',
  GET_INCOMING_INVOICES_SUCCESS:
    'incoming-invoices/GET_INCOMING_INVOICES_SUCCESS',
  GET_INCOMING_INVOICES_FAILED:
    'incoming-invoices/GET_INCOMING_INVOICES_FAILED',

  GET_INCOMING_INVOICE_ATTACHMENTS:
    'incoming-invoices/GET_INCOMING_INVOICE_ATTACHMENTS',
  GET_INCOMING_INVOICE_ATTACHMENTS_SUCCESS:
    'incoming-invoices/GET_INCOMING_INVOICE_ATTACHMENTS_SUCCESS',
  GET_INCOMING_INVOICE_ATTACHMENTS_FAILED:
    'incoming-invoices/GET_INCOMING_INVOICE_ATTACHMENTS_FAILED',

  CREATE_INCOMING_INVOICE: 'incoming-invoices/CREATE_INCOMING_INVOICE',
  CREATE_INCOMING_INVOICE_SUCCESS:
    'incoming-invoices/CREATE_INCOMING_INVOICE_SUCCESS',
  CREATE_INCOMING_INVOICE_FAILED:
    'incoming-invoices/CREATE_INCOMING_INVOICE_FAILED',

  UPDATE_INCOMING_INVOICE: 'incoming-invoices/UPDATE_INCOMING_INVOICE',
  UPDATE_INCOMING_INVOICE_SUCCESS:
    'incoming-invoices/UPDATE_INCOMING_INVOICE_SUCCESS',
  UPDATE_INCOMING_INVOICE_FAILED:
    'incoming-invoices/UPDATE_INCOMING_INVOICE_FAILED',

  DELETE_INCOMING_INVOICE: 'incoming-invoices/DELETE_INCOMING_INVOICE',
  DELETE_INCOMING_INVOICE_SUCCESS:
    'incoming-invoices/DELETE_INCOMING_INVOICE_SUCCESS',
  DELETE_INCOMING_INVOICE_FAILED:
    'incoming-invoices/DELETE_INCOMING_INVOICE_FAILED',

  APPROVE_INCOMING_INVOICE_FINANCIER:
    'incoming-invoices/APPROVE_INCOMING_INVOICE_FINANCIER',
  APPROVE_INCOMING_INVOICE_FINANCIER_SUCCESS:
    'incoming-invoices/APPROVE_INCOMING_INVOICE_FINANCIER_SUCCESS',
  APPROVE_INCOMING_INVOICE_FINANCIER_FAILED:
    'incoming-invoices/APPROVE_INCOMING_INVOICE_FINANCIER_FAILED',

  DECLINE_INCOMING_INVOICE_FINANCIER:
    'incoming-invoices/DECLINE_INCOMING_INVOICE_FINANCIER',
  DECLINE_INCOMING_INVOICE_FINANCIER_SUCCESS:
    'incoming-invoices/DECLINE_INCOMING_INVOICE_FINANCIER_SUCCESS',
  DECLINE_INCOMING_INVOICE_FINANCIER_FAILED:
    'incoming-invoices/DECLINE_INCOMING_INVOICE_FINANCIER_FAILED',

  APPROVE_INCOMING_INVOICE_APPROVER:
    'incoming-invoices/APPROVE_INCOMING_INVOICE_APPROVER',
  APPROVE_INCOMING_INVOICE_APPROVER_SUCCESS:
    'incoming-invoices/APPROVE_INCOMING_INVOICE_APPROVER_SUCCESS',
  APPROVE_INCOMING_INVOICE_APPROVER_FAILED:
    'incoming-invoices/APPROVE_INCOMING_INVOICE_APPROVER_FAILED',

  DECLINE_INCOMING_INVOICE_APPROVER:
    'incoming-invoices/DECLINE_INCOMING_INVOICE_APPROVER',
  DECLINE_INCOMING_INVOICE_APPROVER_SUCCESS:
    'incoming-invoices/DECLINE_INCOMING_INVOICE_APPROVER_SUCCESS',
  DECLINE_INCOMING_INVOICE_APPROVER_FAILED:
    'incoming-invoices/DECLINE_INCOMING_INVOICE_APPROVER_FAILED',

  SAVE_INCOMING_INVOICE_ATTACHMENTS:
    'incoming-invoices/SAVE_INCOMING_INVOICE_ATTACHMENTS',
  SAVE_INCOMING_INVOICE_ATTACHMENTS_SUCCESS:
    'incoming-invoices/SAVE_INCOMING_INVOICE_ATTACHMENTS_SUCCESS',
  SAVE_INCOMING_INVOICE_ATTACHMENTS_FAILED:
    'incoming-invoices/SAVE_INCOMING_INVOICE_ATTACHMENTS_FAILED',

  UPLOAD_INCOMING_INVOICE_ATTACHMENT_URL:
    'incoming-invoices/UPLOAD_INCOMING_INVOICE_ATTACHMENT_URL',
  UPLOAD_INCOMING_INVOICE_ATTACHMENT_URL_SUCCESS:
    'incoming-invoices/UPLOAD_INCOMING_INVOICE_ATTACHMENT_URL_SUCCESS',
  UPLOAD_INCOMING_INVOICE_ATTACHMENT_URL_FAILED:
    'incoming-invoices/UPLOAD_INCOMING_INVOICE_ATTACHMENT_URL_FAILED',
};

export const INCOMING_INVOICE_MESSAGES = {
  GET_INCOMING_INVOICES_FAILED: 'Failed to get incoming invoices',
  CREATE_INCOMING_INVOICE_FAILED: 'Failed to create incoming invoice',
  CREATE_INCOMING_INVOICE_SUCCESS: 'Incoming invoice created successfully',
  UPDATE_INCOMING_INVOICE_FAILED: 'Failed to update incoming invoice',
  UPDATE_INCOMING_INVOICE_SUCCESS: 'Incoming invoice updated successfully',
  DELETE_INCOMING_INVOICE_SUCCESS: 'Incoming invoice deleted successfully',
  DELETE_INCOMING_INVOICE_FAILED: 'Failed to delete incoming invoice',
  APPROVE_INCOMING_INVOICE_FINANCIER_SUCCESS:
    'Invoice successfully approved by financier',
  APPROVE_INCOMING_INVOICE_FINANCIER_FAILED:
    'Failed to approve invoice by financier',
  DECLINE_INCOMING_INVOICE_FINANCIER_SUCCESS:
    'Invoice successfully declined by financier',
  DECLINE_INCOMING_INVOICE_FINANCIER_FAILED:
    'Failed to decline invoice by financier',
  APPROVE_INCOMING_INVOICE_APPROVER_SUCCESS:
    'Invoice successfully approved by approver',
  APPROVE_INCOMING_INVOICE_APPROVER_FAILED:
    'Failed to approve invoice by approver',
  DECLINE_INCOMING_INVOICE_APPROVER_SUCCESS:
    'Invoice successfully declined by approver',
  DECLINE_INCOMING_INVOICE_APPROVER_FAILED:
    'Failed to decline invoice by approver',
  SAVE_INCOMING_INVOICE_ATTACHMENTS_FAILED:
    'Failed to upload incoming invoice attachment',
};
