import dayjs from 'dayjs';
import React from 'react';
import { Popover } from 'antd';
import currencyFormatter from 'currency-formatter';
import { get } from 'lodash';

import { EXTRA_PAYMENTS_TABLE_CONTENT } from 'containers/ExtraPayments/constants';
import { getAvatarAuthor } from 'components/ProfilesCommon/ProfileMain/utils';
import TableActions from 'components/Common/TableActions';
import Tag from 'components/Common/Tag';
import { formatDate } from 'utils/formHelper';
import {
  BEGIN_MONTH_FORMAT,
  DATE_MONTH_FORMAT,
  EXTRA_PAYMENT_FORMAT,
} from 'utils/timeConstants';
import { CURRENCY_CODES } from 'utils/constants';

import { STATUS_INFO } from './constants';
import styles from './styles.scss';

export function formatPayments(data) {
  const formatter = payment => {
    const memo = payment;

    if (payment.amount_byn) {
      memo.amount = currencyFormatter.format(payment.amount_byn, {
        code: CURRENCY_CODES.BYR,
      });
    }

    if (payment.amount_usd) {
      memo.amount = currencyFormatter.format(payment.amount_usd, {
        code: CURRENCY_CODES.USD,
      });
    }

    return memo;
  };

  if (Array.isArray(data)) {
    return data.map(item => formatter(item));
  }

  return formatter(data);
}

const getStatusTag = status => (
  <Tag color={get(STATUS_INFO, [status, 'color'], '')}>
    {get(STATUS_INFO, [status, 'title'], 'undefined')}
  </Tag>
);

export function getTableColumns({ onDelete, onShowModal, users, employees }) {
  return [
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.period_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.valid_from,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.valid_from,
      render: (_, { firstRowId, rowSpan, id, currentPeriod }) => ({
        children: dayjs(currentPeriod).format(EXTRA_PAYMENT_FORMAT),
        props: {
          rowSpan: id === firstRowId ? rowSpan : 0,
        },
      }),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.amount_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.amount,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.amount,
      render: (_, { amount_byn, amount_usd }) => (
        <div className={styles.field} style={{ fontWeight: 700 }}>
          {amount_byn && (
            <span>
              {currencyFormatter.format(amount_byn, {
                code: CURRENCY_CODES.BYR,
              })}
            </span>
          )}
          {amount_usd && (
            <span>
              {currencyFormatter.format(amount_usd, {
                code: CURRENCY_CODES.USD,
              })}
            </span>
          )}
        </div>
      ),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.description_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.title,
      render: (value, record) => (
        <Popover
          placement="bottom"
          title="Description"
          content={get(record, 'description') || 'Description is missing'}
          trigger="hover"
        >
          <div>{value}</div>
        </Popover>
      ),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.project_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.project_name,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.project_name,
      width: 180,
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.author_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.author_id,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.author_id,
      render: (value, record) =>
        getAvatarAuthor({ user: record.author, users, employees }),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.status_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.status,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.status,
      width: 90,
      render: status => status && getStatusTag(status),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.created_at_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.created_at,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.created_at,
      render: value => dayjs(value).format(DATE_MONTH_FORMAT),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.reviewed_at_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.reviewed_at,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.reviewed_at,
      render: value => (value ? dayjs(value).format(DATE_MONTH_FORMAT) : ''),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.reviewer_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.reviewer_id,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.reviewer_id,
      render: (value, record) =>
        getAvatarAuthor({ user: record.reviewer, users, employees }),
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      render: (_, { id }) => {
        const onDeletePayment = () => onDelete(id);
        const onUpdatePayment = () => onShowModal(id);

        return (
          <TableActions
            onDelete={onDeletePayment}
            onEdit={onUpdatePayment}
            canDelete
            canEdit
          />
        );
      },
    },
  ];
}

// const getCurrentUser = (value, users) => {
//   const currentUser = users.find(user => get(user, 'id') === value);

//   if (currentUser) {
//     return (
//       <>
//         <span>
//           {!get(currentUser, ['name'], '')
//             ? get(currentUser, 'email')
//             : get(currentUser, ['name'])}
//         </span>
//       </>
//     );
//   }
// };

export const addKeyToData = data =>
  data.map(item => ({ key: `${item.id}__${item.amount}`, ...item }));

export const getModifiedData = data => {
  const sortedData = data.sort((a, b) =>
    b.valid_from.localeCompare(a.valid_from),
  );

  const groupByPeriods = sortedData.reduce(function(a, e) {
    let estKey = e['valid_from'];
    (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);

    return a;
  }, {});
  const modifyData = [];
  const currentPeriod = {
    periodRowSpan: 1,
    period: '',
    firstRowId: '',
  };

  sortedData.forEach(elem => {
    if (get(currentPeriod, 'period') !== get(elem, 'valid_from')) {
      currentPeriod.period = get(elem, 'valid_from');
      currentPeriod.periodRowSpan = 1;
      currentPeriod.firstRowId = get(elem, 'id');
    } else {
      currentPeriod.periodRowSpan += 1;
    }

    modifyData.push({
      ...elem,
      rowSpan: get(groupByPeriods, get(elem, 'valid_from')).length,
      firstRowId: currentPeriod.firstRowId,
      currentPeriod: currentPeriod.period,
    });
  });

  return modifyData;
};

export const formatExtraPayments = ({
  validFrom,
  currency,
  sum,
  ...values
}) => ({
  valid_from: formatDate(validFrom, BEGIN_MONTH_FORMAT),
  [currency]: sum,
  ...values,
});
