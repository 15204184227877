import dayjs from 'dayjs';
import { DATE_FORMAT } from 'utils/timeConstants';

export const calculateDate = currentDate => {
  const period = dayjs(currentDate).format(DATE_FORMAT);
  const startMonth = dayjs(period)
    .date(1)
    .format(DATE_FORMAT);
  const day = dayjs(period)
    .date(1)
    .day();

  const from = dayjs(startMonth, DATE_FORMAT)
    .subtract(day, 'day')
    .format(DATE_FORMAT);

  const to = dayjs(from)
    .add(41, 'day')
    .format(DATE_FORMAT);

  return { from, to };
};

export const groupCalendar = data =>
  data?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr['date']]: curr,
    }),
    {},
  ) || {};
