import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Loader } from 'components/Common';
import { rolesSelector } from 'redux/selectors';
import { getRowKey } from 'utils/helpers';
import {
  createRole,
  deleteRole,
  getPermissions,
  getRoles,
  getRoleTypes,
  updateRole,
} from 'redux/Roles/actions';
import RolesModal from 'components/RolesCommon/RolesModal';
import PageHeader from 'components/Common/PageHeader';
import Table from 'components/Common/Table';

import { Modal } from 'antd';
import {
  getColumns,
  getformattedPermissions,
  groupPermissionsById,
} from './utils';

const RolesPage = () => {
  const [currentRole, setCurrentRole] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch = useDispatch();

  const { roles, permissions, roleTypes, isRolesLoading } = useSelector(
    rolesSelector,
  );

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getPermissions());
    dispatch(getRoleTypes());
  }, []);

  const permissionsGroupedById = useMemo(
    () => groupPermissionsById(permissions),
    [permissions],
  );

  const permissionsArray = useMemo(() => getformattedPermissions(permissions), [
    permissions,
  ]);

  const toggleModal = isVisible => () => {
    setIsModalVisible(isVisible);

    if (!isVisible) {
      setCurrentRole(null);
    }
  };

  const onEdit = role => () => {
    setCurrentRole(role);
    toggleModal(true)();
  };

  const onUpdateRole = role => {
    dispatch(updateRole(role));
    toggleModal(false)();
  };

  const onCreateRole = role => {
    dispatch(createRole(role));
    toggleModal(false)();
  };

  const onDeleteRole = id => () => {
    Modal.confirm({
      title: 'Delete role',
      content: 'Are you sure you want to delete this role?',
      onOk: () => {
        dispatch(deleteRole(id));
        Modal.destroyAll();
      },
    });
  };

  const columns = useMemo(
    () =>
      getColumns({
        onEdit,
        onDelete: onDeleteRole,
        groupedPermissions: permissionsGroupedById,
      }),
    [roles, permissionsGroupedById],
  );

  return (
    <div>
      <PageHeader
        title="Roles"
        extra={
          <Button type="primary" onClick={toggleModal(true)}>
            Add role
          </Button>
        }
      />
      <RolesModal
        currentRole={currentRole}
        isVisible={isModalVisible}
        onCreate={onCreateRole}
        onUpdate={onUpdateRole}
        toggleModal={toggleModal}
        permissions={permissionsArray}
        roleTypes={roleTypes}
      />
      <Loader loading={isRolesLoading}>
        <Table
          columns={columns}
          dataSource={roles}
          pagination={false}
          rowKey={getRowKey}
        />
      </Loader>
    </div>
  );
};

export default RolesPage;
