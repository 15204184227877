import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isPaysheetsLoading: false,
  total: null,
  error: null,
  period: null,
  averageSalaryRate: null,
  averageSalaryPeriod: null,
  isLoadingAverageSalary: false,
  data: [],
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_PAYSHEETS:
    case types.GET_AVERAGE_SALARY_PERIOD:
      return state.set('error', null).set('isPaysheetsLoading', true);
    case types.GET_AVERAGE_SALARY:
      return state.set('error', null).set('isLoadingAverageSalary', true);

    case types.GET_PAYSHEETS_SUCCESS: {
      return state
        .set('isPaysheetsLoading', false)
        .set('error', null)
        .set('data', payload.paysheets.data)
        .set('period', payload.period)
        .set('total', parseInt(payload.paysheets.headers['x-total-count'], 10));
    }
    case types.GET_AVERAGE_SALARY_SUCCESS:
      return state
        .set('error', null)
        .set('averageSalaryRate', payload.value)
        .set('isLoadingAverageSalary', false);
    case types.GET_AVERAGE_SALARY_PERIOD_SUCCESS:
      return state
        .set('averageSalaryRate', payload.averageSalaryRate)
        .set('averageSalaryPeriod', payload.avaragePeriod)
        .set('isLoadingAverageSalary', false);

    case types.GET_PAYSHEETS_FAILED:
    case types.GET_AVERAGE_SALARY_PERIOD_FAILED:
      return state
        .set('isPaysheetsLoading', false)
        .set('error', payload)
        .set('data', [])
        .set('total', 0);
    case types.GET_AVERAGE_SALARY_FAILED:
      return state.set('isLoadingAverageSalary', false);
    default:
      return state;
  }
}
