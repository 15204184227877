import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import {
  getDashboardData,
  getDashboardChartConfig,
} from 'containers/DashboardEffectivenessPage/utils';
import { getTotalHoursData, utilizationLineStyle } from './utils';
import UtilizationTotalHours from './UtilizationTotalHours';
import DashboardUtilizationChart from './DashboardUtilizationChart';
import { UTILIZATION_LABELS } from './constants';

import styles from './styles.scss';

const DashboardUtilization = ({
  dashboardData: { months_com, average_utilization, average_logged_hours },
}) => {
  const utilizationDataTotal = useMemo(
    () =>
      getDashboardData({
        data: months_com,
        average: average_utilization,
        currentLabel: UTILIZATION_LABELS.CURRENT,
        averageLabel: UTILIZATION_LABELS.AVG,
        value: 'utilization',
      }),
    [months_com, average_utilization],
  );

  const totalHoursData = useMemo(
    () => getTotalHoursData({ months_com, average_logged_hours }),
    [],
  );

  const utilizationTotalConfig = getDashboardChartConfig({
    data: utilizationDataTotal,
    seriesField: 'type',
    lineStyle: utilizationLineStyle,
  });

  return (
    <div className={styles.dashboardUtilization}>
      <div className={styles.container}>
        {utilizationDataTotal && (
          <DashboardUtilizationChart config={utilizationTotalConfig} />
        )}
        {months_com && <UtilizationTotalHours data={totalHoursData} />}
      </div>
    </div>
  );
};

DashboardUtilization.propTypes = {
  dashboardData: PropTypes.shape({
    months_com: PropTypes.array,
    average_utilization: PropTypes.number,
    average_logged_hours: PropTypes.number,
  }),
};

export default memo(DashboardUtilization);
