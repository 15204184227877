import React from 'react';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

import { MONTH_FORMAT_DAY_DIGIT } from 'containers/InvoicesPage/constants';

export const getUpOrDownIcon = (value1, value2) => {
  if (!value1 || !value2 || value1 === value2) return null;

  return value2 > value1 ? (
    <CaretUpFilled style={{ color: '#52c41a' }} />
  ) : (
    <CaretDownFilled style={{ color: '#ff6347' }} />
  );
};

const getArrayOfMonth = period => {
  const [firstMonth, lastMonth] = period;
  const monthsAmount = dayjs(lastMonth).diff(firstMonth, 'month') + 1;
  const monthsArray = [];

  for (let i = 0; i < monthsAmount; i++) {
    const month = dayjs(lastMonth)
      .subtract(i, 'month')
      .format(MONTH_FORMAT_DAY_DIGIT);
    monthsArray.push(month);
  }

  return monthsArray.reverse();
};

export const getProfitAndLossData = ({ monthData, period }) => {
  const monthsArray = getArrayOfMonth(period);

  return (
    monthData &&
    monthsArray.map(month => {
      const monthItemData = monthData.find(item => item.month === month);

      if (monthItemData) {
        const { cost_adjustment, cost_sum, g_p_m, revenue_sum } = monthItemData;

        return {
          cost_adjustment,
          cost_sum,
          g_p_m,
          month,
          revenue_sum,
        };
      }

      return {
        cost_adjustment: 0,
        cost_sum: 0,
        g_p_m: 0,
        month,
        revenue_sum: 0,
      };
    })
  );
};
