import { message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { rolesSelector } from 'redux/selectors';
import API from 'utils/api';
import { sagaErrorHandler } from 'utils/common';
import { REDUX_DATA_HELPERS } from 'utils/helpers';

import { types } from './types';

function* getRoles() {
  try {
    const { data } = yield call(API.getRoles, { expand: ['permissions'] });

    yield put({ type: types.GET_ROLES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_ROLES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* createRole({ payload }) {
  try {
    const { data } = yield call(API.createRole, payload);

    const { roles } = yield select(rolesSelector);

    yield put({ type: types.CREATE_ROLE_SUCCESS, payload: [...roles, data] });

    message.success(SAGA_MESSAGES.CREATE_ROLE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_ROLE_FAILED,
      SAGA_MESSAGES.CREATE_ROLE_FAILED,
    );
  }
}

function* updateRole({ payload: { id, ...role } }) {
  try {
    const { data } = yield call(API.updateRole, { role, id });

    const { roles } = yield select(rolesSelector);

    const updatedRoles = REDUX_DATA_HELPERS.updateData({
      data: roles,
      editedItem: data,
      id,
    });

    yield put({ type: types.UPDATE_ROLE_SUCCESS, payload: updatedRoles });
    message.success(SAGA_MESSAGES.UPDATE_ROLE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_ROLE_FAILED,
      SAGA_MESSAGES.UPDATE_ROLE_FAILED,
    );
  }
}

function* deleteRole({ payload }) {
  try {
    yield call(API.deleteRole, payload);

    const { roles } = yield select(rolesSelector);

    const updatedRoles = REDUX_DATA_HELPERS.removeItem({
      data: roles,
      id: payload,
    });

    yield put({ type: types.DELETE_ROLE_SUCCESS, payload: updatedRoles });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_ROLE_FAILED,
      SAGA_MESSAGES.DELETE_ROLE_FAILED,
    );
  }
}

function* getPermissions() {
  try {
    const { data } = yield call(API.getPermissions);

    yield put({ type: types.GET_PERMISSIONS_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_PERMISSIONS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getRoleTypes() {
  try {
    const { data } = yield call(API.getRoleTypes);

    yield put({ type: types.GET_ROLE_TYPES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_ROLE_TYPES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_ROLES, getRoles);
  yield takeLatest(types.CREATE_ROLE, createRole);
  yield takeLatest(types.UPDATE_ROLE, updateRole);
  yield takeLatest(types.DELETE_ROLE, deleteRole);
  yield takeLatest(types.GET_PERMISSIONS, getPermissions);
  yield takeLatest(types.GET_ROLE_TYPES, getRoleTypes);
}
