import styled from 'styled-components';

import { Table } from 'components/Common';

const LowRowTable = styled(Table)`
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td,
  table > tbody > tr > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td {
    // height: 5px;
    padding: 1px 4px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
  }
`;

export default LowRowTable;
