import { actionFactory } from 'redux/factories';
import { types } from './types';

export const loadPaysheets = actionFactory(types.GET_PAYSHEETS);
export const downloadPaysheets = actionFactory(types.DOWNLOAD_PAYSHEETS);
export const updatePaysheetsValues = actionFactory(
  types.UPDATE_PAYSHEETS_VALUES,
);
export const getAverageSalary = actionFactory(types.GET_AVERAGE_SALARY);
export const getAverageSalaryPeriod = actionFactory(
  types.GET_AVERAGE_SALARY_PERIOD,
);
