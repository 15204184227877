exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".item-5190c{margin-bottom:10px}.item-5190c:last-child{margin:0}.organizationItem-a027d{margin-bottom:12px;padding:16px 20px;border:1px solid #d9d9d9;border-radius:8px;position:relative}.organizationItem-a027d:hover .organizationIcon-6cb71{visibility:visible}.organizationItem-a027d:last-child{margin-bottom:0}.organizationTitle-9bbde{margin-bottom:6px;font-size:16px;line-height:24px;font-weight:600}.organizationLocation-b1e9e,.organizationInfo-0a9e3,.organizationTitle-9bbde{display:flex}.organizationLocation-b1e9e,.organizationTitle-9bbde{align-items:center}.organizationLocation-b1e9e{margin-bottom:0;color:#595959}.organizationIcon-6cb71{position:absolute;right:16px;top:10px;visibility:hidden}.organizationValue-c8625{margin-left:5px}.addOrganizationButtonContainer-329dd{display:flex;justify-content:center}", ""]);

// Exports
exports.locals = {
	"item": "item-5190c",
	"organizationItem": "organizationItem-a027d",
	"organizationIcon": "organizationIcon-6cb71",
	"organizationTitle": "organizationTitle-9bbde",
	"organizationLocation": "organizationLocation-b1e9e",
	"organizationInfo": "organizationInfo-0a9e3",
	"organizationValue": "organizationValue-c8625",
	"addOrganizationButtonContainer": "addOrganizationButtonContainer-329dd"
};