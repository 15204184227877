exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".userResourceManager-43049{position:relative}.userResourceManager-43049 .resourceManagerHeader-4cb1c{width:100%;display:flex;justify-content:space-between}.userResourceManager-43049 .resourceManagerHeader-4cb1c .title-5c021{margin-bottom:12px;font-size:20px;font-weight:600;line-height:28px;color:#262626}.userResourceManager-43049:hover .editIcon-70c7d{visibility:visible;cursor:pointer}.userResourceManager-43049 .actionButtons-c2ef6{display:flex;gap:2px;margin-top:4px}.userResourceManager-43049 .userInfo-f1512{color:#595959;cursor:pointer}.userResourceManager-43049 .userInfo-f1512 .avatar-ca123{margin-right:6px}.userResourceManager-43049 .select-3393a{width:292px}.userResourceManager-43049 .editIcon-70c7d{position:absolute;top:6px;right:0;font-size:18px;color:#1677ff;visibility:hidden}", ""]);

// Exports
exports.locals = {
	"userResourceManager": "userResourceManager-43049",
	"resourceManagerHeader": "resourceManagerHeader-4cb1c",
	"title": "title-5c021",
	"editIcon": "editIcon-70c7d",
	"actionButtons": "actionButtons-c2ef6",
	"userInfo": "userInfo-f1512",
	"avatar": "avatar-ca123",
	"select": "select-3393a"
};