import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch } from 'antd';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { MONTH_FORMAT_DAY_DIGIT } from 'containers/InvoicesPage/constants';
import { DEFAULT_FILTER_STATE } from 'containers/DashboardOverviewPage/constants';
import PageHeader from 'components/Common/PageHeader';
import DashboardDropdown from 'components/DashboardCommon/DashboardDropdown';
import DashboardDropdownOverlay from 'components/DashboardCommon/DashboardDropdownOverlay';
import DashboardCOM from 'components/DashboardCommon/DashboardCOM';
import DashboardAER from 'components/DashboardCommon/DashboardAER';
import DashboardUtilization from 'components/DashboardCommon/DashboardUtilization';
import { Loader } from 'components/Common';
import RangePicker from 'components/Common/RangePicker';
import { getMonthsRange } from 'utils/helpers';
import { useRoleContext } from 'utils/hooks';
import { hasRights } from 'utils/permissions';
import { getDashboardEffectiveness } from 'redux/Dashboard/actions';
import { dashboardSelector } from 'redux/selectors';

import { MONTH_FORMAT } from 'utils/timeConstants';
import {
  onReset,
  onValueChange,
  onValueSelect,
} from '../DashboardOverviewPage/utils';
import { DE_PERMISSIONS, PROJECT_CATEGORIES } from './constants';

import styles from './styles.scss';

const DashboardEffectivenessPage = () => {
  const [period, setPeriod] = useState(getMonthsRange(new Date(), 5));
  const [category, setCategory] = useState(DEFAULT_FILTER_STATE);
  const [pm, setPm] = useState(DEFAULT_FILTER_STATE);
  const [bdm, setBdm] = useState(DEFAULT_FILTER_STATE);
  const [withoutSubcontractors, setWithoutSubcontractors] = useState(false);

  const [isCategoryVisible, setIsCategoryVisible] = useState(false);
  const [isPmVisible, setIsPmVisible] = useState(false);
  const [isBdmVisible, setIsBdmVisible] = useState(false);

  const role = useRoleContext();

  const dispatch = useDispatch();

  const { dashboardEffectiveness, isDashboardLoading } = useSelector(
    dashboardSelector,
  );

  const { data_for_filters } = dashboardEffectiveness;

  const dashboardDefaultRequest = {
    from: dayjs(period[0]).format(MONTH_FORMAT_DAY_DIGIT),
    to: dayjs(period[1]).format(MONTH_FORMAT_DAY_DIGIT),
    category: category.applied,
    pm_login: pm.applied,
    sm_login: bdm.applied,
    minus_subcontractors: withoutSubcontractors,
  };

  useEffect(() => {
    const dashboardReqParams = {
      ...dashboardDefaultRequest,
    };

    if (!isEmpty(category.applied)) {
      dashboardReqParams.category = [...category.applied];
    }

    setPm({
      selected: [],
      applied: [],
    });
    setBdm({
      selected: [],
      applied: [],
    });

    dispatch(getDashboardEffectiveness(dashboardReqParams));
  }, [period]);

  const onPeriodChange = period => {
    setPeriod([period[0], period[1]]);
  };

  const onCategoryChange = value =>
    onValueChange({
      value,
      values: category,
      setValues: setCategory,
    });

  const onPMChange = value =>
    onValueChange({
      value,
      values: pm,
      setValues: setPm,
    });

  const onBDMChange = value =>
    onValueChange({
      value,
      values: bdm,
      setValues: setBdm,
    });

  const onCategoryReset = () =>
    onReset({
      getDashboard: getDashboardEffectiveness,
      dashboardDefaultRequest,
      setValue: setCategory,
      field: 'category',
      dispatch,
    });

  const onPMReset = () =>
    onReset({
      getDashboard: getDashboardEffectiveness,
      dashboardDefaultRequest,
      setValue: setPm,
      field: 'pm_login',
      dispatch,
    });

  const onBDMReset = () =>
    onReset({
      getDashboard: getDashboardEffectiveness,
      dashboardDefaultRequest,
      setValue: setBdm,
      field: 'sm_login',
      dispatch,
    });

  const onCategoryApply = () =>
    onValueSelect({
      getDashboard: getDashboardEffectiveness,
      dashboardDefaultRequest,
      dispatch,
      values: category,
      setValues: setCategory,
      field: 'category',
      setVisible: setIsCategoryVisible,
    });

  const onPMApply = () =>
    onValueSelect({
      getDashboard: getDashboardEffectiveness,
      dashboardDefaultRequest,
      dispatch,
      values: pm,
      setValues: setPm,
      field: 'pm_login',
      setVisible: setIsPmVisible,
    });

  const onBDMApply = () =>
    onValueSelect({
      getDashboard: getDashboardEffectiveness,
      dashboardDefaultRequest,
      dispatch,
      values: bdm,
      setValues: setBdm,
      field: 'sm_login',
      setVisible: setIsBdmVisible,
    });

  const onWitoutSubcontractorsChange = checked => {
    dispatch(
      getDashboardEffectiveness({
        ...dashboardDefaultRequest,
        minus_subcontractors: checked,
      }),
    );
    setWithoutSubcontractors(checked);
  };

  const categoryDropdownOverlay = () => (
    <DashboardDropdownOverlay
      values={category}
      valuesList={PROJECT_CATEGORIES}
      onApply={onCategoryApply}
      onReset={onCategoryReset}
      onValueSelect={onCategoryChange}
    />
  );

  const pmDropdownOverlay = () => (
    <DashboardDropdownOverlay
      values={pm}
      valuesList={data_for_filters?.project_managers || []}
      onApply={onPMApply}
      onReset={onPMReset}
      onValueSelect={onPMChange}
    />
  );

  const bdmDropdownOverlay = () => (
    <DashboardDropdownOverlay
      values={bdm}
      valuesList={data_for_filters?.sales_managers || []}
      onApply={onBDMApply}
      onReset={onBDMReset}
      onValueSelect={onBDMChange}
    />
  );

  const canUseFilters = hasRights(role, DE_PERMISSIONS.filters);

  return (
    <div>
      <PageHeader
        title="Dashboard effectiveness"
        extra={
          <div className={styles.dashboardHeader}>
            {canUseFilters && (
              <div className={styles.withoutSubcontractors}>
                <span>Without subcontractors</span>
                <Switch
                  checked={withoutSubcontractors}
                  onChange={onWitoutSubcontractorsChange}
                />
              </div>
            )}
            <RangePicker
              picker="month"
              value={period}
              format={MONTH_FORMAT}
              onChange={onPeriodChange}
              className={styles.rangePicker}
            />
            {canUseFilters && (
              <>
                <DashboardDropdown
                  overlay={categoryDropdownOverlay}
                  values={category}
                  setValues={setCategory}
                  title="Project category"
                  visible={isCategoryVisible}
                  setVisible={setIsCategoryVisible}
                />
                <DashboardDropdown
                  overlay={pmDropdownOverlay}
                  values={pm}
                  setValues={setPm}
                  title="PM"
                  visible={isPmVisible}
                  setVisible={setIsPmVisible}
                />
                <DashboardDropdown
                  overlay={bdmDropdownOverlay}
                  values={bdm}
                  setValues={setBdm}
                  title="BDM"
                  visible={isBdmVisible}
                  setVisible={setIsBdmVisible}
                />
              </>
            )}
          </div>
        }
      />
      <Loader loading={isDashboardLoading} isDynamicPosition>
        <DashboardCOM dashboardData={dashboardEffectiveness} />
        <DashboardAER dashboardData={dashboardEffectiveness} />
        <DashboardUtilization dashboardData={dashboardEffectiveness} />
      </Loader>
    </div>
  );
};

export default DashboardEffectivenessPage;
