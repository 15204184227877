export const auditsSelector = state => state.get('audits').toJS();
export const authSelector = state => state.get('auth').toJS();
export const averageSalariesSelector = state =>
  state.get('averageSalaries').toJS();
export const banksSelector = state => state.get('banks').toJS();
export const calendarSelector = state => state.get('calendar').toJS();
export const contractsSelector = state => state.get('contracts').toJS();
export const costRatesSelector = state => state.get('costRates').toJS();
export const clientsSelector = state => state.get('clients').toJS();
export const currenciesSelector = state => state.get('currencies').toJS();
export const dashboardSelector = state => state.get('dashboard').toJS();
export const employeesSelector = state => state.get('employees').toJS();
export const exchangeRatesSelector = state => state.get('exchangeRates').toJS();
export const extraPaymentsSelector = state => state.get('extraPayments').toJS();
export const incomingInvoicesSelector = state =>
  state.get('incomingInvoices').toJS();
export const invoiceTypesSelector = state => state.get('invoiceTypes').toJS();
export const jiraSelector = state => state.get('jira').toJS();
export const invoicesSelector = state => state.get('invoices').toJS();
export const leadsSelector = state => state.get('leads').toJS();
export const projectProfitsSelector = state =>
  state.get('projectProfits').toJS();
export const dealsSelector = state => state.get('deals').toJS();
export const teamsSelector = state => state.get('teams').toJS();
export const technologiesSelector = state => state.get('technologies').toJS();
export const employeePositionsSelector = state =>
  state.get('employeePositions').toJS();
export const organizationsSelector = state => state.get('organizations').toJS();
export const usersSelector = state => state.get('users').toJS();
export const notificationsSelector = state => state.get('notifications').toJS();
export const resourceManagementSelector = state =>
  state.get('resourceManagement').toJS();
export const paysheetsSelector = state => state.get('paysheets').toJS();
export const summaryEmployeesSelector = state =>
  state.get('summaryEmployees').toJS();
export const rolesSelector = state => state.get('roles').toJS();
export const socialRequestsSelector = state =>
  state.get('socialRequests').toJS();
