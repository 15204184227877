exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".lead-summary-a9eea{padding:0;margin:0;list-style:none}.lead-summary_vertical-82d19 .lead-summary-item-b6a0c{display:block}.lead-summary-item-b6a0c{list-style:none;padding:5px 10px}.lead-summary_horizontal-5434b .lead-summary-item-b6a0c{display:inline-block}.lead-summary_horizontal-5434b .lead-summary-item-b6a0c:first-child{padding-left:0}.infoIcon-23062{margin:5px}.ownerIcon-b3ba9{margin:3px 5px 5px 5px}.itemDescription-61bd2{display:flex}.itemMoney-e9647{display:flex;align-items:center}.selectButtonContainer-5551b{position:absolute;right:0px;bottom:-28px;padding:2px;background:#fff}", ""]);

// Exports
exports.locals = {
	"lead-summary": "lead-summary-a9eea",
	"lead-summary_vertical": "lead-summary_vertical-82d19",
	"lead-summary-item": "lead-summary-item-b6a0c",
	"lead-summary_horizontal": "lead-summary_horizontal-5434b",
	"infoIcon": "infoIcon-23062",
	"ownerIcon": "ownerIcon-b3ba9",
	"itemDescription": "itemDescription-61bd2",
	"itemMoney": "itemMoney-e9647",
	"selectButtonContainer": "selectButtonContainer-5551b"
};