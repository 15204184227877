export const types = {
  SYNC_NOTIFICATIONS: 'notifications/SYNC_NOTIFICATIONS',
  SYNC_NOTIFICATIONS_SUCCESS: 'notifications/SYNC_NOTIFICATIONS_SUCCESS',
  SYNC_NOTIFICATIONS_FAILED: 'notifications/SYNC_NOTIFICATIONS_FAILED',
  CLEAR_NOTIFICATIONS: 'notifications/CLEAR_NOTIFICATIONS',
  REFRESH_SYNC_NOTIFICATIONS: 'notifications/REFRESH_SYNC_NOTIFICATIONS',
  REFRESH_SYNC_NOTIFICATIONS_SUCCESS:
    'notifications/REFRESH_SYNC_NOTIFICATIONS_SUCCESS',
  REFRESH_NOTIFICATIONS_FAILED: 'notifications/REFRESH_NOTIFICATIONS_FAILED',
};

export const changeType = {
  ADDED: 'added',
  MODIFIED: 'modified',
  REMOVED: 'removed',
};
