import React from 'react';
import PropTypes from 'prop-types';
import { ClockCircleOutlined, SettingOutlined } from '@ant-design/icons';

import styles from './styles.scss';

const ProjectItem = ({ project }) => {
  if (!project) {
    return null;
  }

  const { name, key, tempo_url = '', settings_url = '' } = project;

  return (
    <div className={styles.clientProjectItem}>
      <p className={styles.clientProjectName}>
        {name}/{key}
      </p>
      <span className={styles.clientProjectIcons}>
        <a href={tempo_url} target="_blank">
          <ClockCircleOutlined
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
        </a>
        <a href={settings_url} target="_blank">
          <SettingOutlined style={{ cursor: 'pointer' }} />
        </a>
      </span>
    </div>
  );
};

ProjectItem.propTypes = {
  project: PropTypes.object,
};

export default ProjectItem;
