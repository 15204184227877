import React from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ArrowDownOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { CELL_COLORS } from 'components/ClientCommon/ClientTimeline/TimelineItems/CommonItem/constants';
import styles from './styles.scss';

const { Item } = Form;

const DealOverviewForm = ({ dealFormValues, previousDeal }) => (
  <Form layout="vertical">
    <Item label="Title">
      <Input
        disabled
        value={previousDeal ? previousDeal.title : get(dealFormValues, 'title')}
      />
    </Item>
    {previousDeal && previousDeal.title !== dealFormValues.title && (
      <>
        <div className={styles.overviewIconContainer}>
          <ArrowDownOutlined />
        </div>
        <Item>
          <Input
            disabled
            value={dealFormValues.title}
            style={{ backgroundColor: CELL_COLORS['green'] }}
          />
        </Item>
      </>
    )}
    <Item label="Description">
      <Input
        disabled
        value={
          previousDeal
            ? previousDeal.description
            : get(dealFormValues, 'description')
        }
      />
    </Item>
    {previousDeal && previousDeal.description !== dealFormValues.description && (
      <>
        <div className={styles.overviewIconContainer}>
          <ArrowDownOutlined />
        </div>
        <Item>
          <Input
            disabled
            value={dealFormValues.description}
            style={{ backgroundColor: CELL_COLORS['green'] }}
          />
        </Item>
      </>
    )}
    {dealFormValues && dealFormValues.fix_price && (
      <Item label="Fixed price">
        <Item style={{ display: 'inline-block', width: 'calc(50% - 30px)' }}>
          <Input
            disabled
            value={
              previousDeal
                ? previousDeal.fix_price
                : get(dealFormValues, 'fix_price')
            }
            suffix={
              previousDeal
                ? previousDeal.currency_code
                : get(dealFormValues, 'currency_code')
            }
          />
        </Item>
        {previousDeal && previousDeal.fix_price !== dealFormValues.fix_price && (
          <>
            <ArrowRightOutlined
              style={{
                position: 'absolute',
                left: ' 49%',
                top: '7px',
              }}
            />
            <Item
              style={{
                display: 'inline-block',
                width: 'calc(50% - 30px)',
                margin: '0 0 0 60px',
              }}
            >
              <Input
                disabled
                value={
                  dealFormValues.fix_price ? dealFormValues.fix_price : '-'
                }
                style={{ backgroundColor: CELL_COLORS['green'] }}
                suffix={
                  dealFormValues.currency_code
                    ? dealFormValues.currency_code
                    : '-'
                }
              />
            </Item>
          </>
        )}
      </Item>
    )}
  </Form>
);

DealOverviewForm.propTypes = {
  dealFormValues: PropTypes.object,
  previousDeal: PropTypes.object,
};

export default DealOverviewForm;
