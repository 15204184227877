import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber as AntdInputNumber } from 'antd';
import classNames from 'classnames';

import styles from './styles.scss';

const InputNumber = ({ ...props }) => (
  <AntdInputNumber
    {...props}
    className={classNames([styles.input, props.className])}
  />
);

InputNumber.propTypes = {
  className: PropTypes.string,
};

export default InputNumber;
