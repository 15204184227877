export const types = {
  GET_AVERAGE_SALARY_BY_YEAR: 'average-salaries/GET_AVERAGE_SALARY_BY_YEAR',
  GET_AVERAGE_SALARY_BY_YEAR_SUCCESS:
    'average-salaries/GET_AVERAGE_SALARY_BY_YEAR_SUCCESS',
  GET_AVERAGE_SALARY_BY_YEAR_FAILED:
    'average-salaries/GET_AVERAGE_SALARY_BY_YEAR_FAILED',
  ADD_AVERAGE_SALARY: 'average-salaries/ADD_AVERAGE_SALARY',
  ADD_AVERAGE_SALARY_SUCCESS: 'average-salaries/ADD_AVERAGE_SALARY_SUCCESS',
  ADD_AVERAGE_SALARY_FAILED: 'average-salaries/ADD_AVERAGE_SALARY_FAILED',
  UPDATE_AVERAGE_SALARY: 'average-salaries/UPDATE_AVERAGE_SALARY',
  UPDATE_AVERAGE_SALARY_SUCCESS:
    'average-salaries/UPDATE_AVERAGE_SALARY_SUCCESS',
  UPDATE_AVERAGE_SALARY_FAILED: 'average-salaries/UPDATE_AVERAGE_SALARY_FAILED',
};
