import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Input as AntdInput } from 'antd';
import get from 'lodash/get';

import Input from 'components/Common/Input';
import TextArea from 'components/Common/TextArea';
import Select, { Option } from 'components/Common/Select';
import { Button } from 'components/Common';

const { Item } = Form;
const { Group } = AntdInput;

const DealForm = ({
  onFinish = () => {},
  fieldValues = {},
  isModal = false,
  isDisplayAddButton = true,
  form,
  currentProject = null,
  currencies = [],
  onChangeFormValue,
}) => {
  const checkFields = (_, value) => {
    const { fix_price } = form.getFieldsValue();

    if (!fix_price) {
      return Promise.resolve();
    }

    if (value > 0) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('Value must be greater than zero!'));
  };

  return (
    <Form
      layout="vertical"
      id="dealsForm"
      onFinish={onFinish}
      form={form}
      onFieldsChange={onChangeFormValue}
      fields={[
        {
          name: 'title',
          value:
            get(fieldValues, 'title', form.getFieldValue('title')) ||
            get(currentProject, 'title'),
        },
        {
          name: 'fix_price',
          value:
            get(fieldValues, 'fix_price', form.getFieldValue('fix_price')) ||
            get(currentProject, 'fix_price'),
        },
        {
          name: 'description',
          value: get(
            fieldValues,
            'description',
            form.getFieldValue('description') ||
              get(currentProject, 'description'),
          ),
        },
        {
          name: 'currency_code',
          value: get(
            fieldValues,
            'currency_code',
            form.getFieldValue('currency_code') ||
              get(currentProject, 'currency_code'),
          ),
        },
      ]}
    >
      <Item
        label="Deal title"
        key="title"
        name="title"
        rules={[
          {
            message: 'This field is  required.',
          },
        ]}
      >
        <Input placeholder="Title" disabled={get(fieldValues, 'deleted_at')} />
      </Item>
      <Item
        label="Description"
        key="description"
        name="description"
        rules={[
          {
            message: 'This field is  required.',
          },
        ]}
      >
        <TextArea
          placeholder="Description"
          rows={3}
          disabled={get(fieldValues, 'deleted_at')}
        />
      </Item>
      <Form.Item label="Fixed price">
        <Group compact>
          <Form.Item
            name="fix_price"
            noStyle
            rules={[
              {
                validator: checkFields,
                message: 'Fixed must be number and greater than zero!',
              },
              {
                pattern: /^(-)?(\d)*(\.)?([0-9]{1,2})?$/,
                message: 'No more than two decimal places',
              },
            ]}
          >
            <Input style={{ width: '60%' }} />
          </Form.Item>
          <Form.Item name="currency_code" noStyle>
            <Select placeholder="Select currency" style={{ width: '40%' }}>
              {currencies.map(item => (
                <Option key={item.id} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Group>
      </Form.Item>
      {isDisplayAddButton && (
        <Row type="flex" align="middle" justify="end">
          <Button type="primary" htmlType="submit">
            {isModal ? 'Save' : 'Add'}
          </Button>
        </Row>
      )}
    </Form>
  );
};

DealForm.propTypes = {
  form: PropTypes.object,
  onFinish: PropTypes.func,
  isModal: PropTypes.bool,
  fieldValues: PropTypes.object,
  isDisplayAddButton: PropTypes.bool,
  currentProject: PropTypes.object,
  currencies: PropTypes.array,
  onChangeFormValue: PropTypes.func,
};

export default DealForm;
