import { actionFactory } from 'redux/factories';
import { types } from './types';

export const getProjectProfits = actionFactory(types.GET_PROJECT_PROFITS);
export const calculateProjectProfits = actionFactory(
  types.CALCULATE_PROJECT_PROFITS,
);
export const updateProjectProfits = actionFactory(types.UPDATE_PROJECT_PROFITS);
export const getInfoAboutProjectProfitsByYear = actionFactory(
  types.GET_PROJECT_PROFITS_BY_YEAR,
);
export const deleteRevenueFromProject = actionFactory(
  types.DELETE_REVENUE_FROM_PROJECT_PROFIT,
);
export const addProjectExtraPayment = actionFactory(
  types.ADD_PROJECT_EXTRA_PAYMENT,
);
export const getManagerProfiles = actionFactory(types.GET_MANAGER_PROFILES);
