import React from 'react';
import PropTypes from 'prop-types';
import { FilterOutlined, FilterFilled } from '@ant-design/icons';

import styles from './styles.scss';

const DealPopoverCommon = ({ projectFilter, handleVisibleChange }) => (
  <div>
    {Array.isArray(projectFilter) && projectFilter.length > 0 ? (
      <FilterFilled
        onClick={handleVisibleChange}
        className={styles.activeFilter}
      />
    ) : (
      <FilterOutlined onClick={handleVisibleChange} />
    )}
  </div>
);

DealPopoverCommon.propTypes = {
  projectFilter: PropTypes.array,
  handleVisibleChange: PropTypes.func,
};

export default DealPopoverCommon;
