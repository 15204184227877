import { ROUTING } from 'config/routing';

export const menuItems = [
  {
    key: '1',
    title: 'Employees',
    items: [
      {
        key: 'employeesProfiles',
        title: 'Profiles',
        route: ROUTING.EMPLOYEES_PROFILES,
      },
      {
        key: 'employeesSummary',
        title: 'Summary',
        route: ROUTING.EMPLOYEES_SUMMARY,
      },
    ],
  },
  {
    key: '2',
    title: 'Salary',
    items: [
      {
        key: '4',
        title: 'Payroll',
        route: ROUTING.PAYSHEET_TABLE,
      },
      {
        key: '5',
        title: 'Extra payments',
        route: ROUTING.EXTRA_PAYMENTS,
      },
    ],
  },
  {
    key: '8',
    title: 'Directory',
    items: [
      {
        key: '3',
        title: 'Nominal wage',
        route: ROUTING.AVERAGE_SALARY,
      },
      {
        key: '6',
        title: 'Exchange rates',
        route: ROUTING.EXCHANGE_RATES,
      },
      {
        key: '7',
        title: 'Technologies',
        route: ROUTING.TECHNOLOGIES,
      },
      {
        key: 'positions',
        title: 'Positions',
        route: ROUTING.EMPLOYEE_POSITION,
      },
      {
        key: '10',
        title: 'Calendar',
        route: ROUTING.CALENDAR,
      },
      {
        key: '9',
        title: 'Users',
        route: ROUTING.USERS,
      },
      {
        key: 'audit',
        title: 'Audit',
        route: ROUTING.AUDIT,
      },
      {
        key: 'banks',
        title: 'Banks',
        route: ROUTING.BANKS,
      },
      {
        key: 'invoiceTypes',
        title: 'Invoice types',
        route: ROUTING.INVOICE_TYPES,
      },
      {
        key: 'currencies',
        title: 'Currencies',
        route: ROUTING.CURRENCIES,
      },
      {
        key: 'roles',
        title: 'Roles',
        route: ROUTING.ROLES,
      },
    ],
  },
  {
    key: '12',
    title: 'Reports',
    items: [
      {
        key: '13',
        title: 'Projects',
        route: ROUTING.PROJECT_PROFITS,
      },
      {
        key: '14',
        title: 'Subcontractors',
        route: ROUTING.SUBCONTRACTORS,
      },
      {
        key: '15',
        title: 'Dashboard overview',
        route: ROUTING.DASHBOARD_OVERVIEW,
      },
      {
        key: '16',
        title: 'Dashboard effectiveness',
        route: ROUTING.DASHBOARD_EFFECTIVENESS,
      },
      {
        key: '17',
        title: 'Dashboard employee overview',
        route: ROUTING.DASHBOARD_EMPLOYEE_OVERVIEW,
      },
      {
        key: '18',
        title: 'Dashboard salary fund',
        route: ROUTING.DASHBOARD_SALARY,
      },
      {
        key: '19',
        title: 'Dashboard subcontractors',
        route: ROUTING.DASHBOARD_SUBCONTRACTORS,
      },
    ],
  },
  {
    key: 'crm',
    title: 'CRM',
    items: [
      {
        key: 'clientContracts',
        title: 'Contracts',
        route: ROUTING.CLIENTS_CONTRACTS,
      },
      {
        key: 'clients',
        title: 'Clients',
        route: ROUTING.CLIENTS,
      },
      {
        key: 'leads',
        title: 'Leads',
        route: ROUTING.LEADS,
      },
      {
        key: 'organizations',
        title: 'Organizations',
        route: ROUTING.ORGANIZATION,
      },
      {
        key: 'invoices',
        title: 'Invoices',
        route: ROUTING.INVOICES,
      },
      {
        key: 'incomingInvoices',
        title: 'Incoming invoices',
        route: ROUTING.INCOMING_INVOICES,
      },
    ],
  },
  {
    key: 'requests',
    title: 'Requests',
    route: ROUTING.SOCIAL_REQUESTS,
  },
  {
    key: '11',
    title: 'Resource management',
    route: ROUTING.RESOURCE_MANAGEMENT,
  },
];

export const changeType = {
  ADDED: 'added',
  MODIFIED: 'modified',
  REMOVED: 'removed',
};

export default {
  SYNC_NOTIFICATIONS: 'notifications/SYNC_NOTIFICATIONS',
  SYNC_NOTIFICATIONS_SUCCESS: 'notifications/SYNC_NOTIFICATIONS_SUCCESS',
  SYNC_NOTIFICATIONS_FAILED: 'notifications/SYNC_NOTIFICATIONS_FAILED',
  CLEAR_NOTIFICATIONS: 'notifications/CLEAR_NOTIFICATIONS',
  REFRESH_SYNC_NOTIFICATIONS: 'notifications/REFRESH_SYNC_NOTIFICATIONS',
  REFRESH_SYNC_NOTIFICATIONS_SUCCESS:
    'notifications/REFRESH_SYNC_NOTIFICATIONS_SUCCESS',
  REFRESH_NOTIFICATIONS_FAILED: 'notifications/REFRESH_NOTIFICATIONS_FAILED',
};
