/* eslint-disable react/prop-types */
import React from 'react';
import dayjs from 'dayjs';

import { DATE_MONTH_FORMAT } from 'utils/timeConstants';
import { OrganizationSearch } from 'components/OrganizationsCommon';
import { hasRights } from 'utils/permissions';
import { mapArrayToEntities } from 'utils/common';

import TableActions from 'components/Common/TableActions';
import {
  ORGANIZATIONS_PERMISSIONS,
  ORGANIZATIONS_TABLE_COMMON,
} from './constants';

export const formatOrganization = data => {
  const formatter = ({ accounts = [], ...organization }) => ({
    ...organization,
    accounts: mapArrayToEntities(accounts),
  });

  if (Array.isArray(data)) {
    return data.map(formatter);
  }

  return formatter(data);
};

const filteredValue = (valueType, filterInfo) =>
  (filterInfo && filterInfo[valueType]) || null;

export const getTableColumns = ({
  onDeleteOrganization,
  handleSearch,
  handleReset,
  sortInfo,
  role,
  onShowModal,
  searchInput,
  filtersInfo,
}) => [
  {
    title: ORGANIZATIONS_TABLE_COMMON.id_title,
    dataIndex: ORGANIZATIONS_TABLE_COMMON.id,
    key: ORGANIZATIONS_TABLE_COMMON.id,
    sorter: true,
    width: 100,
    align: 'center',
    sortOrder:
      sortInfo.columnKey === ORGANIZATIONS_TABLE_COMMON.id && sortInfo.order,
    filteredValue: filteredValue(ORGANIZATIONS_TABLE_COMMON.id, filtersInfo),
    ...OrganizationSearch({
      handleSearch,
      handleReset,
      dataIndex: ORGANIZATIONS_TABLE_COMMON.id,
      searchInput,
    }),
  },
  {
    title: ORGANIZATIONS_TABLE_COMMON.friendly_name_title,
    dataIndex: ORGANIZATIONS_TABLE_COMMON.friendly_name,
    key: ORGANIZATIONS_TABLE_COMMON.friendly_name,
    sorter: true,
    sortOrder:
      sortInfo.columnKey === ORGANIZATIONS_TABLE_COMMON.friendly_name &&
      sortInfo.order,
    filteredValue: filteredValue(
      ORGANIZATIONS_TABLE_COMMON.friendly_name,
      filtersInfo,
    ),
    ...OrganizationSearch({
      handleSearch,
      handleReset,
      dataIndex: ORGANIZATIONS_TABLE_COMMON.friendly_name,
      searchInput,
    }),
  },
  {
    title: ORGANIZATIONS_TABLE_COMMON.name_title,
    dataIndex: ORGANIZATIONS_TABLE_COMMON.title,
    key: ORGANIZATIONS_TABLE_COMMON.title,
    sorter: true,
    sortOrder:
      sortInfo.columnKey === ORGANIZATIONS_TABLE_COMMON.title && sortInfo.order,
    filteredValue: filteredValue(ORGANIZATIONS_TABLE_COMMON.title, filtersInfo),
    ...OrganizationSearch({
      handleSearch,
      handleReset,
      dataIndex: ORGANIZATIONS_TABLE_COMMON.title,
      searchInput,
    }),
  },
  {
    title: ORGANIZATIONS_TABLE_COMMON.tax_id_title,
    dataIndex: ORGANIZATIONS_TABLE_COMMON.tax_id,
    key: ORGANIZATIONS_TABLE_COMMON.tax_id,
    sorter: true,
    sortOrder:
      sortInfo.columnKey === ORGANIZATIONS_TABLE_COMMON.tax_id &&
      sortInfo.order,
    filteredValue: filteredValue(
      ORGANIZATIONS_TABLE_COMMON.tax_id,
      filtersInfo,
    ),
    ...OrganizationSearch({
      handleSearch,
      handleReset,
      dataIndex: ORGANIZATIONS_TABLE_COMMON.tax_id,
      searchInput,
    }),
  },
  {
    title: ORGANIZATIONS_TABLE_COMMON.country_code_title,
    dataIndex: ORGANIZATIONS_TABLE_COMMON.country_code,
    key: ORGANIZATIONS_TABLE_COMMON.country_code,
    sorter: true,
    sortOrder:
      sortInfo.columnKey === ORGANIZATIONS_TABLE_COMMON.country_code &&
      sortInfo.order,
    filteredValue: filteredValue(
      ORGANIZATIONS_TABLE_COMMON.country_code,
      filtersInfo,
    ),
    ...OrganizationSearch({
      handleSearch,
      handleReset,
      dataIndex: ORGANIZATIONS_TABLE_COMMON.country_code,
      searchInput,
    }),
  },
  {
    title: ORGANIZATIONS_TABLE_COMMON.is_yellow_group_title,
    dataIndex: ORGANIZATIONS_TABLE_COMMON.is_yellow_group,
    key: ORGANIZATIONS_TABLE_COMMON.is_yellow_group,
    isShowColumn: false,
    sorter: true,
    sortOrder:
      sortInfo.columnKey === ORGANIZATIONS_TABLE_COMMON.is_yellow_group &&
      sortInfo.order,
    render: value => (value ? 'Yes' : 'No'),
  },
  {
    title: ORGANIZATIONS_TABLE_COMMON.created_at_title,
    dataIndex: ORGANIZATIONS_TABLE_COMMON.created_at,
    key: ORGANIZATIONS_TABLE_COMMON.created_at,
    sorter: true,
    sortOrder:
      sortInfo.columnKey === ORGANIZATIONS_TABLE_COMMON.created_at &&
      sortInfo.order,
    render: value => dayjs(value).format(DATE_MONTH_FORMAT),
  },
  {
    title: ORGANIZATIONS_TABLE_COMMON.updated_at_title,
    dataIndex: ORGANIZATIONS_TABLE_COMMON.updated_at,
    key: ORGANIZATIONS_TABLE_COMMON.updated_at,
    sorter: true,
    sortOrder:
      sortInfo.columnKey === ORGANIZATIONS_TABLE_COMMON.updated_at &&
      sortInfo.order,
    render: value => dayjs(value).format(DATE_MONTH_FORMAT),
  },
  {
    title: ' ',
    dataIndex: 'action',
    width: 50,
    align: 'center',
    key: 'action',
    render: (_, record) => {
      const onDeleteHandler = () => {
        onDeleteOrganization({
          id: record.id,
          sort: `${sortInfo.columnKey}:${sortInfo.order}`,
        });
      };

      return (
        <TableActions
          onEdit={() => onShowModal(record)}
          onDelete={onDeleteHandler}
          canDelete={hasRights(
            role,
            ORGANIZATIONS_PERMISSIONS.deleteOrganization,
          )}
          canEdit
        />
      );
    },
  },
];

export const getFiltersWithOutNull = filtersInfo =>
  Object.keys(filtersInfo).reduce(
    (acc, item) =>
      filtersInfo[item] ? { ...acc, [item]: filtersInfo[item] } : acc,
    {},
  );

export const formatSearchFilters = filters =>
  Object.keys(filters).reduce(
    (acc, key) => ({
      ...acc,
      [key]: filters[key][0],
    }),
    {},
  );
