exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".profitAndLossHeader-21bdb{display:flex;justify-content:space-between;align-items:center;margin-bottom:24px;padding:0 24px 20px;border-bottom:1px solid #d9d9d9}.profitAndLossHeader-21bdb .title-b3c6d{display:flex;align-items:center}.profitAndLossHeader-21bdb .title-b3c6d h5{margin-bottom:0}.profitAndLossHeader-21bdb .title-b3c6d .info-883f8{margin-left:14px}.profitAndLossHeader-21bdb .title-b3c6d .info-883f8 svg{width:21px}.profitAndLossHeader-21bdb .details-a8f7b{display:flex;align-items:center;color:#595959}.profitAndLossHeader-21bdb .details-a8f7b .period-bad5c{margin-right:12px;padding-right:12px;border-right:1px solid #d9d9d9}", ""]);

// Exports
exports.locals = {
	"profitAndLossHeader": "profitAndLossHeader-21bdb",
	"title": "title-b3c6d",
	"info": "info-883f8",
	"details": "details-a8f7b",
	"period": "period-bad5c"
};