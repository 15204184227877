exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".userInfo-b52f4{padding:24px;border-radius:12px;background-color:#fff;box-shadow:0 4px 20px 0 rgba(112,112,128,.1);width:100%;display:flex;flex-direction:column;align-items:center;justify-content:space-evenly;margin-bottom:24px}.userInfo-b52f4 .avatar-3d853{position:relative;padding:2px;border:2px solid #1677ff}.userInfo-b52f4 .avatar-3d853:before{content:\" \";position:absolute;top:0;bottom:0;left:0;right:0;border-radius:50%;border:2px solid #fff}.userInfo-b52f4 .userName-7154f{display:flex;align-items:center;flex-direction:column;margin-top:20px}.userInfo-b52f4 .userName-7154f .englishName-5ab31{margin-bottom:6px;display:flex;align-items:center;text-align:center;font-style:normal;font-weight:600;font-size:24px;line-height:140%;color:#262626}.userInfo-b52f4 .userName-7154f .russianName-8314a{display:flex;align-items:center;margin-bottom:12px;font-style:normal;font-weight:normal;font-size:16px;line-height:157%;text-align:center;color:#595959}.userInfo-b52f4 .editIcon-fee79{color:#1677ff;cursor:pointer;font-size:22px;position:absolute;right:21px;top:18px}", ""]);

// Exports
exports.locals = {
	"userInfo": "userInfo-b52f4",
	"avatar": "avatar-3d853",
	"userName": "userName-7154f",
	"englishName": "englishName-5ab31",
	"russianName": "russianName-8314a",
	"editIcon": "editIcon-fee79"
};