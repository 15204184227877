export const BANKS_COMMON = {
  id_title: 'ID',
  id: 'id',
  name_title: 'Name',
  name: 'name',
  actions_title: 'Actions',
  actions: 'actions',
};

export const BANKS_MESSAGES = {
  ADD_BANK_SUCCESS: 'Banks added successfully',
  ADD_BANK_FAILED: 'Failed to add bank',

  UPDATE_BANK_SUCCESS: 'Banks updated successfully',
  UPDATE_BANK_FAILED: 'Failed to update bank',

  DELETE_BANK_SUCCESS: 'Banks deleted successfully',
  DELETE_BANK_FAILED: 'Failed to delete bank',
};
