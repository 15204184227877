import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const TimelineCard = ({ attachments, budget, channel, description }) => {
  const createMarkup = () => {
    return { __html: description };
  };

  return (
    <div className={styles.timelineCard}>
      <div>
        <span>
          <Tag color="orange">{channel}</Tag>
        </span>
        <Tag style={{ marginLeft: '7px' }}>${Number(budget)}</Tag>
      </div>
      <div
        dangerouslySetInnerHTML={createMarkup()}
        style={{ marginLeft: '2px' }}
      />
      <div>
        <ul className={styles.leadsTimeline}>
          {attachments.map(attachment => {
            const decodedUrl = decodeURI(attachment);
            const hrefText = decodedUrl.substring(
              decodedUrl.lastIndexOf('/') + 1,
            );

            return (
              <li>
                <a href={attachment} target="blank">
                  {hrefText}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

TimelineCard.propTypes = {
  attachments: PropTypes.array,
  budget: PropTypes.string,
  channel: PropTypes.string,
  description: PropTypes.array,
};

export default TimelineCard;
