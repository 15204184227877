exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".notificationTitleContainer-37b1f{display:flex;flex-direction:column}.notificationTitleContainer-37b1f span{line-height:1}.dealPeriod-d0a39{display:block;font-size:10px;opacity:.8}.message-0488d{display:block;margin-top:6px}.notificationResult-a9893{display:flex;justify-content:space-between;margin-bottom:5px}.notificationResult-a9893 span{font-size:14px}.role-986c0{margin-bottom:4px}.role-986c0 code{margin:0}", ""]);

// Exports
exports.locals = {
	"notificationTitleContainer": "notificationTitleContainer-37b1f",
	"dealPeriod": "dealPeriod-d0a39",
	"message": "message-0488d",
	"notificationResult": "notificationResult-a9893",
	"role": "role-986c0"
};