import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { get } from 'lodash';

import DatePicker from 'components/Common/DatePicker';
import TextArea from 'components/Common/TextArea';
import Input from 'components/Common/Input';
import Select, { Option } from 'components/Common/Select';

import styles from './styles.scss';

const { Item } = Form;

const PaymentsFormExternal = ({
  fieldValues = {},
  onSubmit,
  isPaymentsPage,
  summaryEmployeeProfiles,
}) => {
  const [form] = Form.useForm();

  const currencySelect = (
    <Item
      name="currency"
      rules={[
        {
          required: true,
          message: 'Please select currency!',
        },
      ]}
      noStyle
    >
      <Select className={styles.currencySelect}>
        <Option value="amount_usd">USD</Option>
        <Option value="amount_byn">BYN</Option>
      </Select>
    </Item>
  );

  return (
    <Form
      layout="vertical"
      id="paymentForm"
      onFinish={onSubmit}
      form={form}
      fields={[
        { name: 'sum', value: get(fieldValues, 'sum') },
        {
          name: 'currency',
          value: get(fieldValues, 'currency') || 'amount_usd',
        },
        { name: 'title', value: get(fieldValues, 'title') },
        { name: 'description', value: get(fieldValues, 'description') },
        {
          name: 'validFrom',
          value: get(fieldValues, 'validFrom'),
        },
        {
          name: 'employee_id',
          value: get(fieldValues, 'employee_id'),
        },
      ]}
    >
      {isPaymentsPage && (
        <Item label="Profile" name="employee_id">
          <Select disabled={summaryEmployeeProfiles.length === 1}>
            {summaryEmployeeProfiles?.map(profile => (
              <Option key={profile.id} value={profile.id}>
                {profile.value}
              </Option>
            ))}
          </Select>
        </Item>
      )}
      <Item label="Amount" required noStyle>
        <Item
          name="sum"
          rules={[
            {
              pattern: /^(-)?(\d)*(\.)?([0-9]{1,2})?$/,
              message: 'No more than two decimal places',
              required: true,
            },
            {
              max: 11,
              message: 'Value should be less than 12 character',
            },
          ]}
        >
          <Input
            type="number"
            addonBefore={currencySelect}
            className={styles.amountInput}
          />
        </Item>
      </Item>
      <Item
        label="Period"
        name="validFrom"
        required
        rules={[{ required: true, message: 'Enter date' }]}
      >
        <DatePicker
          picker="month"
          className={styles.input}
          format="YYYY/MM/01"
          placeholder="Enter the period in YYYY/MM/DD format"
        />
      </Item>
      <Item
        label="Title"
        name="title"
        required
        rules={[{ required: true, message: 'Required field' }]}
      >
        <Input maxLength={59} />
      </Item>
      <Item label="Description" name="description">
        <TextArea rows={3} />
      </Item>
    </Form>
  );
};

PaymentsFormExternal.propTypes = {
  fieldValues: PropTypes.object,
  onSubmit: PropTypes.func,
  isPaymentsPage: PropTypes.bool,
  summaryEmployeeProfiles: PropTypes.array,
};

export default PaymentsFormExternal;
