import React from 'react';
import PropTypes from 'prop-types';

import {
  CodeOutlined,
  MobileOutlined,
  HomeOutlined,
  EnvironmentOutlined,
  MailOutlined,
} from '@ant-design/icons';

import styles from './styles.scss';

const UserContact = ({
  position = '',
  phoneNumber,
  email,
  currentOrganization,
  location,
}) => (
  <ul className={styles.userContact}>
    <h3 className={styles.title}>Information</h3>

    {currentOrganization && (
      <li className={styles.userContactItem}>
        <HomeOutlined className={styles.userContactIcon} />
        {currentOrganization.friendly_name}
      </li>
    )}
    {position && (
      <li className={styles.userContactItem}>
        <CodeOutlined className={styles.userContactIcon} />
        {position}
      </li>
    )}
    {phoneNumber && (
      <li className={styles.userContactItem}>
        <MobileOutlined className={styles.userContactIcon} />
        {phoneNumber}
      </li>
    )}
    {location && (
      <li className={styles.userContactItem}>
        <EnvironmentOutlined className={styles.userContactIcon} />
        <div className={styles.userValue}>{location}</div>
      </li>
    )}
    {email && (
      <li className={styles.userContactItem}>
        <MailOutlined className={styles.userContactIcon} />
        <div className={styles.userValue}>{email}</div>
      </li>
    )}
  </ul>
);

UserContact.propTypes = {
  position: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  currentOrganization: PropTypes.object,
  location: PropTypes.string,
};

export default UserContact;
