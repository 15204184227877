import React from 'react';
import { stringSorter } from 'utils/stringSorter';
import TableActions from 'components/Common/TableActions';
import { hasRights } from 'utils/permissions';
import { TECHNOLOGIES_PERMISSIONS } from './constants';

export const getColumns = ({ onDelete, showEditModal, role }) => [
  {
    title: 'Technologies',
    dataIndex: 'title',
    key: 'title',
    sorter: stringSorter('title'),
  },
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
    sorter: stringSorter('key'),
  },
  {
    title: ' ',
    dataIndex: 'action',
    key: 'action',
    width: 50,
    align: 'center',
    render: (_, record) => (
      <TableActions
        onEdit={() => showEditModal(record)}
        onDelete={() => onDelete(record.id)}
        canDelete={hasRights(role, TECHNOLOGIES_PERMISSIONS.deletePermission)}
        canEdit={hasRights(role, TECHNOLOGIES_PERMISSIONS.updatePermission)}
      />
    ),
  },
];
