export const dateSorter = (a, b) => new Date(a.month) - new Date(b.month);

export const findMonthItemData = (monthData, month) =>
  Object.entries(monthData).find(item => item[0] === month);

export const tooltipFormatter = datum => ({
  name: datum.type,
  value: datum.value?.toFixed(2),
});

export const tooltipPercentFormatter = datum => ({
  name: datum.type,
  value: `${datum.value?.toFixed(2)}%`,
});
