import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option } = Select;

const TechnologySelect = ({
  data = [],
  width = '100%',
  onChange,
  value = '',
}) => (
  <Select
    showSearch
    style={{ width }}
    onChange={onChange}
    optionFilterProp="children"
    filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    value={value}
  >
    {data.map(item => (
      <Option key={item.id} value={item.title}>
        {item.title}
      </Option>
    ))}
  </Select>
);

TechnologySelect.propTypes = {
  data: PropTypes.array,
  width: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default TechnologySelect;
