import React from 'react';
import dayjs from 'dayjs';

import {
  REQUESTS_COMMON,
  REQUEST_TYPES,
  SOCIAL_REQUESTS_STATUSES,
} from 'containers/SocialRequestsPage/constants';
import Tag from 'components/Common/Tag';
import { DATE_MONTH_FORMAT, MONTH_NAME_FORMAT } from 'utils/timeConstants';
import { formatNumberWithCommas, getSortOrder } from 'utils/helpers';

import styles from './styles.scss';

export const getColumns = ({ sortInfo, onPreview }) => [
  {
    title: REQUESTS_COMMON.request_type_title,
    dataIndex: REQUESTS_COMMON.request_type,
    key: REQUESTS_COMMON.type,
    sorter: true,
    sortOrder: getSortOrder({
      field: REQUESTS_COMMON.request_type,
      fieldType: 'field',
      sortInfo,
    }),
    align: 'center',
  },
  {
    title: REQUESTS_COMMON.amount_title,
    dataIndex: REQUESTS_COMMON.amount,
    key: REQUESTS_COMMON.amount,
    align: 'center',
    render: value => value && formatNumberWithCommas(value),
  },
  {
    title: REQUESTS_COMMON.attachments_title,
    dataIndex: REQUESTS_COMMON.attachments,
    key: REQUESTS_COMMON.attachments,
    align: 'center',
    render: value =>
      value?.map(attachment => (
        /* eslint-disable jsx-a11y/click-events-have-key-events*/
        /* eslint-disable jsx-a11y/no-static-element-interactions*/
        <div
          onClick={onPreview(attachment.url)}
          className={styles.attachment}
          key={attachment.file_name}
        >
          {attachment.file_name}
        </div>
      )),
  },
  {
    title: REQUESTS_COMMON.description_title,
    dataIndex: REQUESTS_COMMON.description,
    key: REQUESTS_COMMON.description,
    align: 'center',
    width: 150,
  },
  {
    title: REQUESTS_COMMON.sick_day_date_title,
    dataIndex: REQUESTS_COMMON.sick_day_date,
    key: REQUESTS_COMMON.sick_day_date,
    align: 'center',
    render: value => value && dayjs(value).format(DATE_MONTH_FORMAT),
  },
  {
    title: REQUESTS_COMMON.salary_title,
    dataIndex: REQUESTS_COMMON.salary,
    key: REQUESTS_COMMON.salary,
    sorter: true,
    sortOrder: getSortOrder({
      field: REQUESTS_COMMON.salary,
      fieldType: 'field',
      sortInfo,
    }),
    render: value => value && dayjs(value.valid_from).format(MONTH_NAME_FORMAT),
  },
  {
    title: REQUESTS_COMMON.sent_at_title,
    dataIndex: REQUESTS_COMMON.sent_at,
    key: REQUESTS_COMMON.sent_at,
    align: 'center',
    sorter: true,
    sortOrder: getSortOrder({
      field: REQUESTS_COMMON.sent_at,
      fieldType: 'field',
      sortInfo,
    }),
    render: value => value && dayjs(value).format(DATE_MONTH_FORMAT),
  },
  {
    title: REQUESTS_COMMON.reviewed_at_title,
    dataIndex: REQUESTS_COMMON.reviewed_at,
    key: REQUESTS_COMMON.reviewed_at,
    align: 'center',
    render: value => value && dayjs(value).format(DATE_MONTH_FORMAT),
  },
  {
    title: REQUESTS_COMMON.status_title,
    dataIndex: REQUESTS_COMMON.status,
    key: REQUESTS_COMMON.status,
    align: 'center',
    sorter: true,
    sortOrder: getSortOrder({
      field: REQUESTS_COMMON.status,
      fieldType: 'field',
      sortInfo,
    }),
    render: value => (
      <Tag color={SOCIAL_REQUESTS_STATUSES[value]?.color}>
        {value.replace(/_/g, ' ').toUpperCase()}
      </Tag>
    ),
  },
];

export const formatBalanceValue = ({ value, type }) => {
  if (type === REQUEST_TYPES.sick_day.key) {
    return value;
  }

  return `$${formatNumberWithCommas(value)}`;
};
