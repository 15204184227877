import React, { useState, useEffect, memo } from 'react';
import { Divider } from 'antd';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import {
  UserSkills,
  ResourceManagementSelect,
  UserContract,
  UserInfo,
  UserContact,
} from 'components/ProfilesCommon';
import Buddy from 'components/ProfilesCommon/Buddy';
import {
  updateEmployeeRm,
  updateBuddies,
  updateResourceManager,
} from 'redux/Employees/actions';

import { useDispatch } from 'react-redux';
import { getStatusIsSubcontractor, onChangeValue } from './utils';
import styles from './styles.scss';

const ProfileDetails = ({
  profile,
  summaryEmployees,
  onEditClick,
  teams,
  organizations,
  role,
  isSummary,
  isSummaryTab,
  contractInfo,
  employeeTechnologies,
  isWard,
}) => {
  const {
    id,
    name_en,
    name_ru,
    email,
    employee_position,
    phone,
    resource_manager,
    contract_starts_at,
    contract_ends_at,
    is_contract_termless,
    status,
    team_id,
    jira_user,
    organization_id,
    location,
    buddy_ids,
    buddies,
  } = profile;
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [isSaveBuddyVisible, setIsSaveBuddyVisible] = useState(false);
  const [currentBuddyIds, setCurrentBuddyIds] = useState([]);
  const [currentResourceManagerId, setCurrentResourceManagerId] = useState(
    null,
  );
  const [profileStatus, setProfileStatus] = useState(null);
  const [currentOrganization, setCurrentOrganization] = useState(null);

  const dispatch = useDispatch();

  const onChangeResourceManager = value =>
    onChangeValue(value)({
      setValue: setCurrentResourceManagerId,
      setIsSaveVisible,
    });

  const onChangeBuddy = value =>
    onChangeValue(value)({
      setValue: setCurrentBuddyIds,
      setIsSaveVisible: setIsSaveBuddyVisible,
    });

  const onHideRmSelect = () => {
    setIsSaveVisible(false);
    setCurrentResourceManagerId(get(profile, ['resource_manager', 'id']));
  };

  const onHideBuddySelect = () => {
    setIsSaveBuddyVisible(false);
    setCurrentBuddyIds(buddy_ids);
  };

  useEffect(() => {
    if (resource_manager) {
      setCurrentResourceManagerId(get(profile, ['resource_manager', 'id']));
    }

    if (buddy_ids) {
      setCurrentBuddyIds(buddy_ids);
    }
  }, [profile, resource_manager, buddy_ids]);

  const onUpdateResourceManager = () => {
    onHideRmSelect();
    dispatch(
      updateResourceManager({
        resource_manager_id: currentResourceManagerId,
        id,
      }),
    );
  };

  const onUpdateBuddy = () => {
    dispatch(updateBuddies({ employee_id: id, buddy_ids: currentBuddyIds }));
    onHideBuddySelect();
  };

  useEffect(() => {
    if (organization_id) {
      setCurrentOrganization(
        organizations.find(item => item.id === organization_id),
      );
    }
  }, [profile, organizations]);

  useEffect(() => {
    setProfileStatus(getStatusIsSubcontractor(status, team_id));
  }, [status, team_id]);

  return (
    <div className={styles.detailsContainer}>
      <div className={styles.wrapper}>
        <UserInfo
          nameEn={name_en}
          nameRu={name_ru}
          onEditClick={onEditClick}
          status={profileStatus}
          teams={teams}
          team_id={team_id}
          jiraUser={jira_user}
          isSummary={isSummary}
          role={role}
        />
        <div className={styles.details}>
          <UserContact
            position={employee_position && employee_position.title}
            phoneNumber={phone}
            email={email}
            currentOrganization={currentOrganization}
            location={location}
          />
          <Divider className={styles.divider} />
          <UserSkills
            skills={employeeTechnologies}
            id={id}
            role={role}
            isSummary={isSummary}
          />
          <Divider className={styles.divider} />
          <ResourceManagementSelect
            summaryEmployees={summaryEmployees}
            userRm={currentResourceManagerId}
            isSummary={isSummary}
            onChangeResourceManager={onChangeResourceManager}
            onUpdateResourceManager={onUpdateResourceManager}
            onHideRmSelect={onHideRmSelect}
            role={role}
            resourceManager={resource_manager}
          />
          <Divider className={styles.divider} />
          <Buddy
            summaryEmployees={summaryEmployees}
            currentBuddyIds={currentBuddyIds}
            isSaveBuddyVisible={isSaveBuddyVisible}
            onChangeBuddy={onChangeBuddy}
            onHideBuddySelect={onHideBuddySelect}
            onUpdateBuddy={onUpdateBuddy}
            role={role}
            isSummary={isSummary}
            isWard={isWard}
            buddies={buddies}
          />
          <Divider className={styles.divider} />
          {!isSummaryTab && (
            <UserContract
              contractStartsAt={
                isSummary ? contractInfo.contract_starts_at : contract_starts_at
              }
              contractEndsAt={
                isSummary ? contractInfo.contract_ends_at : contract_ends_at
              }
              isContractTermless={
                isSummary
                  ? contractInfo.is_contract_termless
                  : is_contract_termless
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

ProfileDetails.defaultProps = {
  isSummary: false,
};

ProfileDetails.propTypes = {
  profile: PropTypes.object,
  summaryEmployees: PropTypes.array,
  onEditClick: PropTypes.func,
  teams: PropTypes.array,
  organizations: PropTypes.array,
  role: PropTypes.string.isRequired,
  isSummary: PropTypes.bool,
  isSummaryTab: PropTypes.bool,
  contractInfo: PropTypes.object,
  isWard: PropTypes.bool,
  employeeTechnologies: PropTypes.array,
};

export default memo(ProfileDetails);
