import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Menu, Avatar, Dropdown, Drawer, Badge, Button, Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BellOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';

import { Loader } from 'components/Common';
import { HeaderMenu } from 'components/HeaderCommon';
import Notification from 'components/Notification';
import YellowIcon from 'components/HeaderCommon/YellowIcon';
import {
  syncNotifications,
  clearNotificationsStore,
  refreshNotifications,
} from 'redux/Notifications/actions';
import { logout } from 'redux/Auth/actions';
import { authSelector, notificationsSelector } from 'redux/selectors';

import { hasRights, ROLE_TYPES } from 'utils/permissions';
import { menuItems } from './constants';
import { getSelectedKeys, getItems } from './utils';

import styles from './styles.scss';

const { Header: LayoutHeader } = Layout;

function Header({ role }) {
  const [visible, toggleVisible] = useState(false);

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  // Temp
  const isUser = role === ROLE_TYPES.user;

  const { isNotificationsLoading, notifications } = useSelector(
    notificationsSelector,
  );

  const { user } = useSelector(authSelector);

  useEffect(() => {
    dispatch(clearNotificationsStore());
    !isUser && dispatch(syncNotifications());
  }, []);

  const items = useMemo(() => getItems(role, user), [role, user]);
  const currentItem = useMemo(() => getSelectedKeys(menuItems, pathname), [
    pathname,
    menuItems,
  ]);

  const onToggleVisible = useCallback(
    value => () => {
      toggleVisible(value);
    },
    [],
  );

  const onRefreshNotifications = () => {
    toggleVisible(false);
    dispatch(refreshNotifications());
  };

  const onLogout = () => dispatch(logout());

  return (
    <LayoutHeader className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.headerLeft}>
          <YellowIcon />
        </div>
        <HeaderMenu items={items} currentItem={currentItem} />

        <div className={styles.headerRight}>
          {!isUser && (
            <>
              <Badge
                count={notifications.length}
                size="small"
                className={styles.badge}
              >
                <BellOutlined
                  className={styles.bell}
                  onClick={onToggleVisible(true)}
                />
              </Badge>
              <Drawer
                width={400}
                title={
                  <div className={styles.notificationTitle}>
                    <span>Notification</span>
                    <Button type="primary" onClick={onRefreshNotifications}>
                      Refresh notifications
                    </Button>
                  </div>
                }
                placement="right"
                closable={false}
                onClose={onToggleVisible(false)}
                open={visible}
                getContainer={false}
              >
                <Loader loading={isNotificationsLoading}>
                  {notifications.map((notification, index) => (
                    <Notification
                      //eslint-disable-next-line
                key={`${notification.title}-${index}`}
                      notification={notification}
                    />
                  ))}
                </Loader>
              </Drawer>
            </>
          )}
          <Dropdown
            menu={{
              items: [{ key: 0, label: 'Log out', onClick: onLogout }],
            }}
            className={styles.logout}
          >
            <span>
              <Avatar
                size={36}
                icon={<UserOutlined />}
                className={styles.avatar}
              />
              <DownOutlined />
            </span>
          </Dropdown>
        </div>
      </div>
    </LayoutHeader>
  );
}

Header.propTypes = {
  role: PropTypes.string,
};

export default Header;
