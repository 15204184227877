import { get } from 'lodash';

export const getCommentsWithEmployeesInfo = ({ users, comments }) =>
  comments.map(comment => {
    const currentEmployee = users.find(
      employee => employee.id === get(comment, 'author_id'),
    );

    return {
      name: get(currentEmployee, 'name'),
      key: `${get(comment, 'author_id', '')}__${get(comment, 'id', '')}`,
      ...comment,
    };
  });
