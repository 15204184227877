import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button } from 'antd';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AdjustmentModal = ({
  isVisible,
  onFinish,
  onHideModal,
  adjustmentData,
}) => {
  const [isAdjustmentIntroduced, setIsAdjustmentIntroduced] = useState(false);
  const [formFields, setFormFields] = useState({
    adjustment: '',
    adjustment_comment: '',
  });

  useEffect(() => {
    if (adjustmentData) {
      setIsAdjustmentIntroduced(adjustmentData.adjustment);
      setFormFields({
        adjustment: adjustmentData.adjustment,
        adjustment_comment: adjustmentData.adjustment_comment,
      });
    }
  }, [adjustmentData]);

  const onValuesChange = (field, allFields) => {
    setIsAdjustmentIntroduced(allFields.adjustment);
    setFormFields(allFields);
  };

  const onFormSubmit = fields => {
    onFinish({
      adjustment: fields.adjustment,
      adjustment_comment: fields.adjustment_comment,
      project_id: adjustmentData.project_id,
      period: adjustmentData.period,
    });
  };

  return (
    <Modal
      open={isVisible}
      footer={[
        <Button type="primary" htmlType="submit" form="adjustmentForm">
          Submit
        </Button>,
        <Button onClick={onHideModal}>Cancel</Button>,
      ]}
      title="Adjustment"
      onCancel={onHideModal}
      destroyOnClose
    >
      <Form
        onValuesChange={onValuesChange}
        {...layout}
        name="basic"
        initialValues={{
          adjustment: formFields.adjustment,
          adjustment_comment: formFields.adjustment_comment,
        }}
        id="adjustmentForm"
        onFinish={onFormSubmit}
      >
        <Form.Item label="Adjustement, USD" name="adjustment">
          <Input placeholder="Adjustment, USD" type="number" />
        </Form.Item>
        <Form.Item
          label="Adjustment comment"
          name="adjustment_comment"
          rules={[
            {
              required: isAdjustmentIntroduced,
              message: 'Adjustment comment is required',
            },
          ]}
        >
          <Input.TextArea placeholder="Adjustment comment" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

AdjustmentModal.propTypes = {
  isVisible: PropTypes.bool,
  onFinish: PropTypes.func,
  onHideModal: PropTypes.func,
  adjustmentData: PropTypes.object,
};

export default AdjustmentModal;
