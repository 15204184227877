import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import { getPopupContainerHandler } from 'utils/helpers';

import styles from './styles.scss';

const { Option } = Select;
const { Item } = Form;

const AllocationSelect = ({ data = [] }) => (
  <Item
    className={styles.formItem}
    label="Allocation"
    name="dealAllocationId"
    rules={[{ required: true, message: 'Select an allocation' }]}
  >
    <Select
      className={styles.allocationSelect}
      getPopupContainer={getPopupContainerHandler}
    >
      {data.map(item => (
        <Option key={item.id} value={item.id}>
          {`${item.id} | ${item.jira_role} ${item.rate} ${item.currency_code} ${
            item.condition
          } ${item.hours_per_week} h/week`}
        </Option>
      ))}
    </Select>
  </Item>
);

AllocationSelect.propTypes = {
  data: PropTypes.array,
};

export default AllocationSelect;
