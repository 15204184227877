import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';

import Table from 'components/Common/Table';
import { getRowKey } from 'utils/helpers';

import { getColumns, EditableCell, formatNewAccount } from './utils';
import { CREATION_ROW } from './constants';

const OrganizationAccounts = ({
  organizationId,
  isLoading,
  onDelete,
  createAccount,
  onEdit,
  accounts,
  role,
  accountsData,
  setAccountsData,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [isAddAccount, setIsAddAccount] = useState(false);

  const isEditing = record => record.id === editingKey;

  const onEditRow = record => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };

  const onCancelEditOrCreate = () => {
    Modal.confirm({
      title: 'Sure to cancel?',
      onOk: () => {
        if (isAddAccount) {
          setAccountsData(accounts);
          setIsAddAccount(false);
        }

        setEditingKey('');
        Modal.destroyAll();
      },
    });
  };

  const onCreateNewAccount = accountValues => {
    const formattedValue = formatNewAccount(accountValues);

    setAccountsData(prev => [...prev, formattedValue]);
  };

  const onEditNewAccount = accountValues => {
    setAccountsData(prev =>
      prev.map(account =>
        account.id === editingKey ? accountValues : account,
      ),
    );
  };

  const onSaveNew = accountValues => {
    if (isAddAccount) {
      onCreateNewAccount(accountValues);
    } else {
      onEditNewAccount(accountValues);
    }

    setIsAddAccount(false);
    setEditingKey('');
  };

  const onDeleteNew = record => {
    setAccountsData(prev => prev.filter(account => account.id !== record.id));
  };

  const onSave = async record => {
    const isValidate = await form.validateFields();

    if (isValidate) {
      const values = form.getFieldsValue();
      const valuesWithoutNull = Object.keys(values).reduce(
        (acc, cur) => (values[cur] ? { ...acc, [cur]: values[cur] } : acc),
        {},
      );

      if (!organizationId) {
        onSaveNew(valuesWithoutNull);

        return;
      }

      if (isAddAccount) {
        createAccount({ values: valuesWithoutNull, organizationId });
      } else {
        onEdit({ values: valuesWithoutNull, organizationId, id: record.id });
      }

      setIsAddAccount(false);
      setEditingKey('');
    }
  };

  const onDeleteAccount = record => {
    Modal.confirm({
      title: 'Delete account',
      content: 'Are you sure you want to delete this account?',
      onOk: () => {
        if (!organizationId) {
          onDeleteNew(record);

          return;
        }

        if (record) {
          onDelete({
            accountId: record.id,
            organizationId: record.organization_id,
          });
        }

        Modal.destroyAll();
      },
    });
  };

  const handleAdd = () => {
    if (isAddAccount) {
      setEditingKey(CREATION_ROW.id);
      setAccountsData(prev => [...prev, CREATION_ROW]);
    } else {
      setEditingKey('');
      setAccountsData(prev => prev.filter(row => row.id !== CREATION_ROW.id));
    }

    form.resetFields();
  };

  const isAddAccountToggle = () => setIsAddAccount(prev => !prev);

  const columns = useMemo(
    () =>
      getColumns({
        isEditing,
        onSave,
        onCancelEditOrCreate,
        onEditRow,
        editingKey,
        isAddAccountToggle,
        onDeleteAccount,
        role,
      }),
    [accounts, editingKey, accountsData, role],
  );

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    setAccountsData(accounts);
  }, [accounts]);

  useEffect(() => {
    handleAdd();
  }, [isAddAccount]);

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={accountsData}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        loading={isLoading}
        size="small"
        rowKey={getRowKey}
      />
    </Form>
  );
};

OrganizationAccounts.propTypes = {
  onEdit: PropTypes.func,
  createAccount: PropTypes.func,
  onDelete: PropTypes.func,
  role: PropTypes.string,
  isLoading: PropTypes.bool,
  organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accounts: PropTypes.array,
  accountsData: PropTypes.array,
  setAccountsData: PropTypes.func,
};

export default OrganizationAccounts;
