import { EMPLOYEES_EXPAND_PARAMETERS } from 'containers/EmployeesProfiles/constants';
import { ROLE_TYPES } from 'utils/permissions';

export const RM_PLAN_TYPES = {
  SICKLEAVE: 'SICKLEAVE',
  SICKLEAVE_TITLE: 'SICK LEAVE',
  VACATION: 'VACATION',
  PROJECT: 'PROJECT',
  BENCH: 'BENCH',
  DEALS: 'DEAL',
};

export const PLAN_TYPES_WITH_PROJECT = [
  RM_PLAN_TYPES.PROJECT,
  RM_PLAN_TYPES.DEALS,
];

export const LOCAL_STORAGE_KEYS = {
  rmPeriod: 'rmPeriod',
  rmRadio: 'rmRadio',
  rmDelivery: 'rmDelivery',
  rmFilters: 'rmFilters',
  rmHidePms: 'rmHidePms',
};

export const RM_MODES = {
  BY_RM: 'by_rm',
  ALL: 'all',
};

export const RM_EMPLOYEES_EXPAND = [
  EMPLOYEES_EXPAND_PARAMETERS.technologies,
  EMPLOYEES_EXPAND_PARAMETERS.employee_position,
  EMPLOYEES_EXPAND_PARAMETERS.resource_manager,
];

export const EMPLOYEE_COLUMN_KEY = 'Employees';

export const MANAGER_POSITIONS = [
  'project manager',
  'product manager',
  'delivery manager',
];

export const RM_PERMISSIONS = {
  filters: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.dm,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
    ROLE_TYPES.office_manger,
    ROLE_TYPES.hr,
  ],
  overall: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.dm,
    ROLE_TYPES.pm,
    ROLE_TYPES.office_manger,
    ROLE_TYPES.hr,
  ],
  updatePlans: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.dm,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
    ROLE_TYPES.office_manger,
    ROLE_TYPES.hr,
  ],
  openProfile: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.hr_lead,
    ROLE_TYPES.dm,
    ROLE_TYPES.pm,
    ROLE_TYPES.office_manger,
    ROLE_TYPES.hr,
  ],
  updateProjectPlan: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.dm,
    ROLE_TYPES.pm,
    ROLE_TYPES.bdm,
  ],
};
