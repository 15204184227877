import React from 'react';
import PropTypes from 'prop-types';

import { requestFormatter } from 'containers/SocialRequestsPage/utils';

import SocialRequestsTotalItem from './SocialRequestsTotalItem';
import styles from './styles.scss';

const SocialRequestsTotal = ({ socialRequestsInfo }) => {
  if (!socialRequestsInfo) return null;

  const { left, paid } = socialRequestsInfo;

  return (
    <div className={styles.socialRequestsTotal}>
      <div className={styles.block}>
        <span className={styles.title}>Paid</span>
        <div className={styles.items}>
          <SocialRequestsTotalItem
            title="Health:"
            value={requestFormatter(paid.health)}
          />
          <SocialRequestsTotalItem
            title="Education:"
            value={requestFormatter(paid.education)}
          />
          <SocialRequestsTotalItem
            title="Sport:"
            value={requestFormatter(paid.sport)}
          />
          <SocialRequestsTotalItem title="Sick days:" value={paid.sick_day} />
        </div>
      </div>
      <div className={styles.block}>
        <span className={styles.title}>Balance</span>
        <div className={styles.items}>
          <SocialRequestsTotalItem
            title="Health:"
            value={requestFormatter(left.health)}
          />
          <SocialRequestsTotalItem
            title="Education:"
            value={requestFormatter(left.education)}
          />
          <SocialRequestsTotalItem
            title="Sport:"
            value={requestFormatter(left.sport)}
          />
          <SocialRequestsTotalItem title="Sick days:" value={left.sick_day} />
        </div>
      </div>
    </div>
  );
};

SocialRequestsTotal.propTypes = {
  socialRequestsInfo: PropTypes.object,
};

export default SocialRequestsTotal;
