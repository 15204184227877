exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".userContact-a7ba5{list-style:none;font-size:.875rem;line-height:22px;display:flex;flex-direction:column;align-items:flex-start;justify-content:space-evenly;padding:0;width:100%}.userContact-a7ba5 .title-f3848{margin-bottom:12px;font-size:20px;font-weight:600;line-height:28px;color:#262626}.userContact-a7ba5 .userContactIcon-e26f4{margin:2px 6px 0 0;font-size:16px}.userContact-a7ba5 .userContactItem-55951{display:flex;width:100%;margin-bottom:8px;font-size:16px;color:#595959}.userContact-a7ba5 .userContactItem-55951:last-child{margin-bottom:0}.userContact-a7ba5 .userEmail-fdb3d{margin-top:8px;width:105%;display:flex}.userContact-a7ba5 .userValue-3c9b5{margin-top:-3px}", ""]);

// Exports
exports.locals = {
	"userContact": "userContact-a7ba5",
	"title": "title-f3848",
	"userContactIcon": "userContactIcon-e26f4",
	"userContactItem": "userContactItem-55951",
	"userEmail": "userEmail-fdb3d",
	"userValue": "userValue-3c9b5"
};