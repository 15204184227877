exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dashboardEmployeeExpandableRow-2c565{display:flex}.dashboardEmployeeExpandableRow-2c565 .projectsTable-d131e{width:30%;margin-right:25px}.dashboardEmployeeExpandableRow-2c565 .comChart-fbed0{width:35%}.loader-25666{width:100%;margin:0 auto}", ""]);

// Exports
exports.locals = {
	"dashboardEmployeeExpandableRow": "dashboardEmployeeExpandableRow-2c565",
	"projectsTable": "projectsTable-d131e",
	"comChart": "comChart-fbed0",
	"loader": "loader-25666"
};