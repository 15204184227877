import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal as AntdModal } from 'antd';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { get } from 'lodash';

import { formatExtraPayments } from 'containers/User/UserPayments/utils';
import { EXTRA_PAYMENTS_MODAL } from 'containers/ExtraPayments/constants';
import { PaymentForm } from 'components/PaymentsCommon';
import Modal from 'components/Common/Modal';
import { Button, Loader, Table } from 'components/Common';
import { BEGIN_MONTH_FORMAT } from 'utils/timeConstants';
import { getRowKey } from 'utils/helpers';
import {
  addEmployeeExtraPayments,
  approveEmployeeExtraPayment,
  declineEmployeeExtraPayment,
  deleteEmployeeExtraPayment,
  updateEmployeeExtraPayment,
} from 'redux/Employees/actions';

import { getColumns, getModifiedData } from './utils';
import styles from './styles.scss';

const ProfileExtraPayments = ({
  isLoading,
  role,
  extraPayments,
  currentEmployeeId,
  onHideRequestModal,
  users,
  projects,
  employees,
  isSummary,
  isVisible,
  isWard,
  user,
}) => {
  const [isVisibleEditModal, setIsVisibleEditModal] = useState(false);
  const [currentExtraPaymentId, setCurrentExtraPaymentId] = useState('');

  const dispatch = useDispatch();

  const onHideModal = () => setIsVisibleEditModal(false);

  const currentExtraPayment = useMemo(
    () =>
      extraPayments?.find(item => get(item, 'id') === currentExtraPaymentId),
    [extraPayments, currentExtraPaymentId],
  );

  const fieldValues = useMemo(() => {
    if (currentExtraPayment)
      return {
        sum: currentExtraPayment.amount_usd || currentExtraPayment.amount_byn,
        validFrom: dayjs(currentExtraPayment.valid_from, BEGIN_MONTH_FORMAT),
        currency: currentExtraPayment.amount_usd ? 'amount_usd' : 'amount_byn',
        description: currentExtraPayment.description,
        title: currentExtraPayment.title,
      };
  }, [currentExtraPayment, extraPayments]);

  const onShowModal = id => () => {
    setIsVisibleEditModal(true);
    setCurrentExtraPaymentId(id);
  };

  const onSubmit = values => {
    if (values) {
      dispatch(
        updateEmployeeExtraPayment({
          currentExtraPaymentId,
          currentEmployeeId,
          ...formatExtraPayments(values),
        }),
      );
    }

    onHideModal();
  };

  const onApproveExtraPayment = ({ id, employee_id }) => {
    AntdModal.confirm({
      title: EXTRA_PAYMENTS_MODAL.approveTitle,
      content: EXTRA_PAYMENTS_MODAL.approve,
      okText: EXTRA_PAYMENTS_MODAL.ok,
      cancelText: EXTRA_PAYMENTS_MODAL.cancel,
      onOk: () => {
        dispatch(approveEmployeeExtraPayment({ id, employee_id }));

        AntdModal.destroyAll();
      },
      onCancel: AntdModal.destroyAll,
    });
  };

  const onDeclineExtraPayment = ({ id, employee_id }) => {
    AntdModal.confirm({
      title: EXTRA_PAYMENTS_MODAL.rejectTitle,
      content: EXTRA_PAYMENTS_MODAL.decline,
      okText: EXTRA_PAYMENTS_MODAL.ok,
      cancelText: EXTRA_PAYMENTS_MODAL.cancel,
      onOk: () => {
        dispatch(declineEmployeeExtraPayment({ id, employee_id }));

        AntdModal.destroyAll();
      },
      onCancel: AntdModal.destroyAll,
    });
  };

  const onExtraPaymentsDelete = id => () => {
    AntdModal.confirm({
      title: EXTRA_PAYMENTS_MODAL.deleteTitle,
      content: EXTRA_PAYMENTS_MODAL.delete,
      onOk: () => {
        dispatch(
          deleteEmployeeExtraPayment({
            id,
            employeeId: currentEmployeeId,
          }),
        );
        AntdModal.destroyAll();
      },
      onCancel: AntdModal.destroyAll,
    });
  };

  const onAddPayment = values => {
    if (values) {
      dispatch(
        addEmployeeExtraPayments({
          employee_id: currentEmployeeId,
          ...formatExtraPayments(values),
        }),
      );
    }

    onHideRequestModal();
  };

  const columns = useMemo(
    () =>
      getColumns({
        users,
        onShowModal,
        onExtraPaymentsDelete,
        projects,
        onDeclineExtraPayment,
        onApproveExtraPayment,
        employees,
        isSummary,
        isWard,
        user,
        role,
      }),
    [
      users,
      currentEmployeeId,
      extraPayments,
      projects,
      employees,
      isSummary,
      isWard,
      user,
      role,
    ],
  );

  const dataSource = useMemo(() => getModifiedData(extraPayments), [
    extraPayments,
  ]);

  return (
    <>
      <Loader loading={isLoading}>
        <Table
          rowKey={getRowKey}
          style={{ margin: 10 }}
          columns={columns}
          bordered
          scroll={{ x: 1600 }}
          dataSource={dataSource}
          pagination={false}
          size="small"
          storageKey="profileExtrapayments"
          isPageWithId
        />
      </Loader>
      <Modal
        className={styles.modal}
        title="Extra payments"
        open={isVisibleEditModal}
        onCancel={onHideModal}
        cancelText="Cancel"
        destroyOnClose
        maskClosable
        footer={[
          <Button onClick={onHideModal}>Cancel</Button>,
          <Button
            form="paymentForm"
            key="submit"
            htmlType="submit"
            type="primary"
          >
            Save
          </Button>,
        ]}
      >
        <PaymentForm
          fieldValues={fieldValues}
          monthFormat={BEGIN_MONTH_FORMAT}
          onSubmit={onSubmit}
        />
      </Modal>
      <Modal
        className={styles.modal}
        title="Extra payments"
        open={isVisible}
        onCancel={onHideRequestModal}
        cancelText="Cancel"
        destroyOnClose
        maskClosable
        footer={[
          <Button onClick={onHideRequestModal}>Cancel</Button>,
          <Button
            form="paymentForm"
            key="submit"
            htmlType="submit"
            type="primary"
          >
            Request
          </Button>,
        ]}
      >
        <PaymentForm onSubmit={onAddPayment} />
      </Modal>
    </>
  );
};

ProfileExtraPayments.propTypes = {
  isLoading: PropTypes.bool,
  role: PropTypes.string,
  extraPayments: PropTypes.array,
  currentEmployeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onHideRequestModal: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  isSummary: PropTypes.bool,
  isVisible: PropTypes.bool,
  isWard: PropTypes.bool,
  user: PropTypes.object,
};

export default ProfileExtraPayments;
