import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { formatNumberWithCommas, getMonthNameWithYear } from 'utils/helpers';
import DashboardSalaryTotal from './DashboardSalaryTotal';
import { getSalaryData, getTooltipWidth } from './utils';
import { SALARY_CHART_COLORS } from './constants';

import styles from './styles.scss';

const DashboardSalaryChart = ({
  chartDetails: { data, roles, total_data },
}) => {
  const salaryData = useMemo(() => getSalaryData(data), [data]);

  const tooltipSorter = item => -item.value;

  return (
    <div className={styles.dashboardSalaryChart}>
      <ResponsiveContainer width="75%" height={300}>
        <BarChart
          data={salaryData}
          margin={{
            top: 20,
            right: 30,
            left: 40,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" tickFormatter={getMonthNameWithYear} />
          <YAxis tickFormatter={formatNumberWithCommas} />
          <Tooltip
            formatter={formatNumberWithCommas}
            contentStyle={{
              width: getTooltipWidth(roles),
            }}
            wrapperStyle={{ zIndex: 1000 }}
            wrapperClassName={styles.tooltipWrapper}
            allowEscapeViewBox={{
              y: true,
            }}
            position={{
              y: 250,
            }}
            itemSorter={tooltipSorter}
          />
          <Legend align="left" />
          {roles?.map((role, index) => (
            <Bar
              dataKey={role}
              key={role}
              stackId="a"
              fill={SALARY_CHART_COLORS[index]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <DashboardSalaryTotal totalData={total_data} />
    </div>
  );
};

DashboardSalaryChart.propTypes = {
  chartDetails: PropTypes.shape({
    data: PropTypes.object,
    roles: PropTypes.array,
    total_data: PropTypes.object,
  }),
};

export default DashboardSalaryChart;
