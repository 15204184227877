import { useContext, useEffect, useRef, useState } from 'react';
import { ScopeContext } from 'containers/AuthLayout/AuthLayout';
import firebase from '../firebase';

export const usePrevState = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useRoleContext = () => {
  const { role } = useContext(ScopeContext);

  return role;
};

export const useFirebaseProgress = () => {
  const [progress, setProgress] = useState(null);
  const db = firebase.firestore();

  const getFirebaseData = ({ collection, doc }) =>
    db
      .collection(collection)
      .doc(doc)
      .onSnapshot(doc => {
        setProgress(doc.data());
      });

  return {
    progress,
    setProgress,
    getFirebaseData,
  };
};
