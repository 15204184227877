import React from 'react';
import PropTypes from 'prop-types';

import TimelineCard from '../../TimelineCard';

const LeadCommon = ({ event: { audited_changes }, leadAttachments }) => {
  const { budget, channel, description } = audited_changes;

  return (
    <TimelineCard
      attachments={leadAttachments}
      budget={budget}
      channel={channel}
      description={description}
    />
  );
};

LeadCommon.propTypes = {
  event: PropTypes.array,
  leadAttachments: PropTypes.array,
};

export default LeadCommon;
