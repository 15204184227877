import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import {
  MailOutlined,
  DollarOutlined,
  UserOutlined,
  StarOutlined,
  EnvironmentOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { Button } from 'components/Common';
import DetailsContainer from 'components/Common/DetailsContainer';

import LeadEditModal from '../LeadEditModal';
import styles from './styles.scss';

const DetailsCommon = ({
  leadData: {
    email,
    budget,
    owner_id,
    score,
    country_code,
    country_name,
    city,
    status,
  },
  users,
  ownerName,
  onLeadUpdate,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  const openModal = () => {
    setIsModalOpen(true);
    form.setFieldsValue({
      owner_id,
      score,
      status,
    });
  };

  const hideModal = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onSubmit = values => {
    const updateOwner = values.owner_id !== owner_id;
    const updateLead = status !== values.status || score !== values.score;

    const updatedValues = {
      owner_id: updateOwner && values.owner_id,
      status: updateLead && values.status,
      score: updateLead && values.score,
    };

    onLeadUpdate(updatedValues);
    hideModal();
  };

  return (
    <>
      <LeadEditModal
        form={form}
        users={users}
        onSubmit={onSubmit}
        open={isModalOpen}
        onCancel={hideModal}
      />
      <DetailsContainer
        name="General information"
        extra={
          <Button
            onClick={openModal}
            size="small"
            icon={<EditOutlined />}
            type="link"
          />
        }
      >
        <ul className={styles.details}>
          <li key="email">
            <MailOutlined className={styles.infoIcon} />
            {email}
          </li>
          <li key="money">
            <DollarOutlined className={styles.infoIcon} />
            {Number(budget)}
          </li>
          {(country_code || country_name || city) && (
            <li key="contry_code">
              <EnvironmentOutlined className={styles.infoIcon} />
              {`${country_code}, ${country_name}, ${city}`}
            </li>
          )}
          <li key="owner">
            <UserOutlined className={styles.infoIcon} />
            {ownerName}
          </li>
          <li key="score">
            <StarOutlined className={styles.infoIcon} />
            {score ? score : 'No score'}
          </li>
        </ul>
      </DetailsContainer>
    </>
  );
};

DetailsCommon.propTypes = {
  leadData: PropTypes.object,
  ownerName: PropTypes.string,
  users: PropTypes.array,
  onLeadUpdate: PropTypes.func,
};

export default DetailsCommon;
