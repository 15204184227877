/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Divider, Switch, Checkbox, Form } from 'antd';
import { orderBy } from 'lodash';
import dayjs from 'dayjs';
import IBAN from 'iban';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import {
  getIbanCurrency,
  setIbanSelectedCurrency,
} from 'redux/Employees/actions';
import DatePicker from 'components/Common/DatePicker';
import UserSelect from 'components/ResourceManagementCommon/UserSelect';
import Select, { Option } from 'components/Common/Select';
import Input from 'components/Common/Input';
import { Button } from 'components/Common';
import { TEAM_ID } from 'utils/constants';
import { DATE_FORMAT, DATE_MONTH_FORMAT } from 'utils/timeConstants';
import { formItemLayout } from 'utils/formHelper';

import { setEmployeeFieldsValue } from './utils';
import JiraUserSelect from '../JiraUserSelect';
import { PHONE_NUMBER_PATTERN, LOCATION_PATTERN } from './constants';

import styles from './styles.scss';

const { Item, List } = Form;

const EmployeeEditForm = ({
  form,
  employees,
  jiraUsers,
  employeePositions,
  teams,
  organizations,
  onJiraSearch,
  onSubmit,
  employee,
  isEditable,
  setIsEditable,
  isExternal,
  setIsExternal,
  ibanCurrencies,
  currencies,
}) => {
  const dispatch = useDispatch();

  const [isSkipJiraUser, setIsSkipJiraUser] = useState(false);
  const [isSkipResourceManager, setIsSkipResourceManager] = useState(false);
  const [isContractTermless, setIsContractTermless] = useState(false);

  useEffect(() => {
    setEmployeeFieldsValue({ form, employee });
    setIsSkipJiraUser(employee.is_skip_jira_user);
    setIsSkipResourceManager(employee.is_skip_resource_manager);
    setIsContractTermless(employee.is_contract_termless);
  }, [employee]);

  const filteredEmployees = employees.filter(
    employee => employee.jira_user?.name !== isEditable,
  );

  const onSkipJiraUser = value => {
    setIsSkipJiraUser(value);
    setIsEditable(value ? '' : employee.jira_id);
  };

  const onExternal = value => {
    setIsExternal(value === TEAM_ID.SUBCONTRACTORS);
  };

  const onSkipresourceManager = value => {
    setIsSkipResourceManager(value);
  };

  const onContractTermless = ({ target }) => {
    setIsContractTermless(target.checked);
  };

  const onJiraUser = value => {
    setIsEditable(value);
  };

  const onFilterOption = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const positions = orderBy(employeePositions, ['title']);

  const onMainIban = ({ target }, ibanIndex) => {
    if (!target.checked) return;

    const formValues = form.getFieldsValue();
    const formattedIbans = formValues.ibans.map((iban, index) => ({
      ...iban,
      is_main: index === ibanIndex ? true : false,
    }));
    form.setFieldsValue({ ...formValues, ibans: formattedIbans });
  };

  const onIbanBlur = ibanIndex => {
    const ibans = form.getFieldValue('ibans');
    const currentIban = ibans[ibanIndex];

    if (IBAN.isValid(currentIban.iban_number)) {
      dispatch(getIbanCurrency(currentIban.iban_number));
    }
  };

  const getIbanCurrencyObject = ibanIndex => {
    const iban = form?.getFieldValue('ibans')[ibanIndex];
    const currency = ibanCurrencies[(iban?.iban_number)];

    return currency;
  };

  const onIbanCurrencySelect = (currency_id, ibanIndex) => {
    const iban = form?.getFieldValue('ibans')[ibanIndex];
    dispatch(setIbanSelectedCurrency({ iban: iban.iban_number, currency_id }));
  };

  return (
    <Form
      {...formItemLayout}
      className={styles.form}
      form={form}
      onFinish={onSubmit}
    >
      <Divider>Information</Divider>
      <Item label="Team" name="team_id">
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={onFilterOption}
          onChange={onExternal}
        >
          {teams.map(item => (
            <Option value={item.id} key={item.id}>
              {item.title}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        label="1C ID"
        name="internal_id"
        rules={[
          {
            required: true,
            message: 'Required field',
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label="Full name (rus)"
        name="name_ru"
        rules={[
          {
            required: true,
            message: 'Required field',
          },
        ]}
      >
        <Input />
      </Item>
      <Item label="Full name (eng)" name="name_en">
        <Input disabled={isEditable} />
      </Item>
      <Item
        label="Position"
        name="employee_position_id"
        rules={[
          {
            required: true,
            message: 'Required field',
          },
        ]}
      >
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={onFilterOption}
        >
          {positions.map(item => (
            <Option value={item.id} key={item.id}>
              {item.title}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        label="Organization"
        name="organization_id"
        rules={[
          {
            required: true,
            message: 'Required field',
          },
        ]}
      >
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={onFilterOption}
        >
          {organizations.map(item => (
            <Option value={item.id} key={item.id}>
              {item.friendly_name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        label="Location"
        name="location"
        rules={[
          {
            pattern: LOCATION_PATTERN,
            message: 'Invalid location format',
          },
        ]}
      >
        <Input placeholder="Format: Minsk, Belarus" />
      </Item>
      <List name="ibans">
        {(fields, { add, remove }) => (
          <>
            {fields.map(iban => {
              const currency = getIbanCurrencyObject(iban.name);
              const isCurrencyGenerated = form?.getFieldValue('ibans')[
                iban.name
              ]?.is_currency_generated;

              return (
                <Item label="IBAN" key={iban.key} className={styles.ibanItem}>
                  <Item
                    {...iban}
                    name={[iban.name, 'iban_number']}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter IBAN',
                      },
                      () => ({
                        validator(_, value) {
                          const formattedValue = value.replace(/\s+/gim, '');

                          if (value && !IBAN.isValid(formattedValue)) {
                            return Promise.reject(new Error('Inavalid IBAN'));
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      disabled={isExternal}
                      onBlur={() => onIbanBlur(iban.name)}
                      className={styles.ibanInput}
                      addonAfter={
                        <Select
                          value={currency?.currency_type}
                          disabled={
                            !currency?.isEditable || isCurrencyGenerated
                          }
                          onChange={value =>
                            onIbanCurrencySelect(value, iban.name)
                          }
                          style={{ width: 80 }}
                        >
                          {currencies.map(currency => (
                            <Option value={currency.id} key={currency.id}>
                              {currency.name}
                            </Option>
                          ))}
                        </Select>
                      }
                    />
                  </Item>
                  <span className={styles.isMainLabel}>Is main: </span>
                  <Item
                    name={[iban.name, 'is_main']}
                    valuePropName="checked"
                    noStyle
                  >
                    <Checkbox
                      onChange={e => onMainIban(e, iban.name)}
                      className={styles.mainIban}
                      disabled={isExternal}
                    />
                  </Item>
                  <MinusCircleOutlined onClick={() => remove(iban.name)} />
                </Item>
              );
            })}

            <Item label=" " colon={false}>
              <Button
                type="dashed"
                onClick={() => add({ iban_number: '', is_main: false })}
                className={styles.addIbanBtn}
                icon={<PlusOutlined />}
                disabled={isExternal}
              >
                Add IBAN
              </Button>
            </Item>
          </>
        )}
      </List>

      <Item label="Date of Birth" name="birthday">
        <DatePicker
          disabled={isExternal}
          format={DATE_FORMAT}
          defaultPickerValue={employee.birthday && dayjs(employee.birthday)}
        />
      </Item>
      <Item
        label="Hi-Tech Park"
        name="is_high_tech_park"
        valuePropName="checked"
      >
        <Switch disabled={isExternal} />
      </Item>
      <Divider>JIRA profile</Divider>
      <Item label="JIRA user" className={styles.checkboxSelect}>
        <Item name="jira_id" noStyle>
          <JiraUserSelect
            disabled={isSkipJiraUser}
            onSearch={onJiraSearch}
            data={jiraUsers}
            onChange={onJiraUser}
            jiraUser={employee.jira_user}
            width="340px"
          />
        </Item>

        <div className={styles.fieldSwitch}>
          <span className={styles.switch}>
            <Item name="is_skip_jira_user" valuePropName="checked" noStyle>
              <Switch onChange={onSkipJiraUser} />
            </Item>
          </span>
          <span>not apply</span>
        </div>
      </Item>
      <Item label="Resource manager" className={styles.checkboxSelect}>
        <Item name="resource_manager" noStyle>
          <UserSelect
            disabled={isSkipResourceManager}
            data={filteredEmployees}
            isReturnAccountId
            width="340px"
          />
        </Item>

        <div className={styles.fieldSwitch}>
          <span className={styles.switch}>
            <Item
              name="is_skip_resource_manager"
              valuePropName="checked"
              noStyle
            >
              <Switch disabled={isExternal} onChange={onSkipresourceManager} />
            </Item>
          </span>
          <span>not apply</span>
        </div>
      </Item>

      <Item label="Buddy" name="buddies">
        <UserSelect
          mode="multiple"
          data={filteredEmployees}
          isReturnAccountId
        />
      </Item>
      <Divider>Contacts</Divider>
      <Item
        label="E-mail"
        name="email"
        rules={[
          {
            type: 'email',
            message: 'Invalid email',
          },
        ]}
      >
        <Input type="email" disabled={isEditable} />
      </Item>
      <Item
        label="Phone"
        name="phone"
        rules={[
          {
            pattern: PHONE_NUMBER_PATTERN,
            message: 'Invalid phone number',
          },
        ]}
      >
        <Input disabled={isExternal} placeholder="Example: +375291231231" />
      </Item>
      <Divider>Contract</Divider>
      <div className={styles.contractStartsAt}>
        <Item label="Contract starts" name="contract_starts_at">
          <DatePicker
            disabled={isExternal}
            format={DATE_MONTH_FORMAT}
            defaultPickerValue={
              employee.contract_starts_at && dayjs(employee.contract_starts_at)
            }
          />
        </Item>
        <div className={styles.unlimitedCheckbox}>
          <Item name="is_contract_termless" valuePropName="checked">
            <Checkbox disabled={isExternal} onChange={onContractTermless}>
              Termless contract
            </Checkbox>
          </Item>
        </div>
      </div>

      {!isContractTermless && (
        <Item label="Contract ends" name="contract_ends_at">
          <DatePicker
            disabled={isExternal}
            format={DATE_MONTH_FORMAT}
            defaultPickerValue={
              employee.contract_ends_at && dayjs(employee.contract_ends_at)
            }
          />
        </Item>
      )}
      <Item label="Termination date" name="fired_at">
        <DatePicker
          disabled={isExternal}
          format={DATE_MONTH_FORMAT}
          defaultPickerValue={employee.fired_at && dayjs(employee.fired_at)}
        />
      </Item>
    </Form>
  );
};

EmployeeEditForm.propTypes = {
  form: PropTypes.object,
  employees: PropTypes.array,
  teams: PropTypes.array,
  employee: PropTypes.object,
  isEditable: PropTypes.string,
  isExternal: PropTypes.bool,
  jiraUsers: PropTypes.array,
  employeePositions: PropTypes.array,
  organizations: PropTypes.array,
  onSubmit: PropTypes.func,
  onJiraSearch: PropTypes.func,
  setIsEditable: PropTypes.func.isRequired,
  setIsExternal: PropTypes.func.isRequired,
  ibanCurrencies: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
};

export default EmployeeEditForm;
