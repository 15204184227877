// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Dropdown, Tooltip } from 'antd';
import get from 'lodash/get';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  RollbackOutlined,
} from '@ant-design/icons';

import { TableSearchProps } from 'components/TableSearchCommon/';
import Tag from 'components/Common/Tag';
import { hasRights } from 'utils/permissions';
import { LINKS } from 'config/routing';

import { DATE_MONTH_FORMAT } from 'utils/timeConstants';
import {
  STATUS_INFO,
  CLIENT_TABLE_INDEX,
  CLIENTS_PERMISSIONS,
} from './constants';
import styles from './styles.scss';

const filteredValue = (valueType, filterInfo) =>
  (filterInfo && filterInfo[valueType]) || null;

const getOwnerFilters = filters =>
  filters?.map(item => ({
    text: item.name,
    value: item.id,
  })) || [];

const getStatusFilters = filters =>
  filters?.map(item => ({
    text: item,
    value: item,
  })) || [];

const renderCellAsLink = (value, record) => (
  <Link to={LINKS.client(record.id)}>{value}</Link>
);

export const getColumns = ({
  filtersInfo,
  searchInput,
  handleSearch,
  handleReset,
  role,
  onDeleteClient,
  onEditClient,
  sortInfo,
  clientFilters,
  onRestoreClient,
}) => {
  const columns = [
    {
      title: 'ID',
      key: CLIENT_TABLE_INDEX.id,
      dataIndex: CLIENT_TABLE_INDEX.id,
      align: 'center',
      width: '7%',
      sorter: true,
      sortOrder: sortInfo.columnKey === CLIENT_TABLE_INDEX.id && sortInfo.order,
      render: renderCellAsLink,
    },
    {
      title: 'Title',
      key: CLIENT_TABLE_INDEX.title,
      dataIndex: CLIENT_TABLE_INDEX.title,
      sorter: true,
      sortOrder:
        sortInfo.columnKey === CLIENT_TABLE_INDEX.title && sortInfo.order,
      filteredValue: filteredValue(CLIENT_TABLE_INDEX.title, filtersInfo),
      width: '24%',
      ...TableSearchProps({
        dataIndex: CLIENT_TABLE_INDEX.title,
        searchInput,
        handleSearch,
        handleReset,
      }),
      render: renderCellAsLink,
    },
    {
      title: 'Owner',
      key: CLIENT_TABLE_INDEX.owner_id,
      dataIndex: CLIENT_TABLE_INDEX.owner_name,
      sorter: true,
      sortOrder:
        sortInfo.columnKey === CLIENT_TABLE_INDEX.owner_id && sortInfo.order,
      width: '11%',
      filters: getOwnerFilters(clientFilters.owners),
      filteredValue: filteredValue(CLIENT_TABLE_INDEX.owner_id, filtersInfo),
    },
    {
      title: 'Description',
      key: CLIENT_TABLE_INDEX.description,
      dataIndex: CLIENT_TABLE_INDEX.description,
      filteredValue: filteredValue(CLIENT_TABLE_INDEX.description, filtersInfo),
      width: '13%',
      sorter: true,
      sortOrder:
        sortInfo.columnKey === CLIENT_TABLE_INDEX.description && sortInfo.order,
      render: (value, record) => {
        const str =
          value.length > 25 ? `${value.substring(0, 25).trim()}...` : value;

        return (
          <Tooltip placement="top" title={str}>
            <Link to={LINKS.client(record.id)}>{str}</Link>
          </Tooltip>
        );
      },
      ...TableSearchProps({
        dataIndex: CLIENT_TABLE_INDEX.description,
        searchInput,
        handleSearch,
        handleReset,
      }),
    },
    {
      title: 'Location',
      key: CLIENT_TABLE_INDEX.location,
      dataIndex: CLIENT_TABLE_INDEX.location,
      width: '10%',
      sorter: true,
      filteredValue: filteredValue(CLIENT_TABLE_INDEX.location, filtersInfo),
      sortOrder:
        sortInfo.columnKey === CLIENT_TABLE_INDEX.location && sortInfo.order,
      render: renderCellAsLink,
      ...TableSearchProps({
        dataIndex: CLIENT_TABLE_INDEX.location,
        searchInput,
        handleSearch,
        handleReset,
      }),
    },
    {
      title: 'Status',
      key: CLIENT_TABLE_INDEX.status,
      dataIndex: CLIENT_TABLE_INDEX.status,
      width: '10%',
      filteredValue: filteredValue(CLIENT_TABLE_INDEX.status, filtersInfo),
      filters: getStatusFilters(clientFilters.statuses),
      sorter: true,
      sortOrder:
        sortInfo.columnKey === CLIENT_TABLE_INDEX.status && sortInfo.order,
      align: 'center',
      render: (value, record) => (
        <Link to={LINKS.client(record.id)}>
          <Tag color={get(STATUS_INFO, [value, 'color'])}>{value}</Tag>
        </Link>
      ),
    },
    {
      title: 'Created at',
      key: CLIENT_TABLE_INDEX.created_at,
      dataIndex: CLIENT_TABLE_INDEX.created_at,
      width: '8%',
      sorter: true,
      sortOrder:
        sortInfo.columnKey === CLIENT_TABLE_INDEX.created_at && sortInfo.order,
      render: (value, record) => (
        <Link to={LINKS.client(record.id)} className={styles.dateCell}>
          {value && dayjs(value).format(DATE_MONTH_FORMAT)}
        </Link>
      ),
    },
    {
      title: 'Updated at',
      key: CLIENT_TABLE_INDEX.updated_at,
      dataIndex: CLIENT_TABLE_INDEX.updated_at,
      sorter: true,
      sortOrder:
        sortInfo.columnKey === CLIENT_TABLE_INDEX.created_at && sortInfo.order,
      width: '8%',
      render: (value, record) => (
        <Link to={LINKS.client(record.id)} className={styles.dateCell}>
          {value && dayjs(value).format(DATE_MONTH_FORMAT)}
        </Link>
      ),
    },
    {
      title: ' ',
      width: 50,
      align: 'center',
      key: CLIENT_TABLE_INDEX.actions,
      dataIndex: CLIENT_TABLE_INDEX.actions,
      render: (_, record) => {
        const onDeleteHandler = () => {
          onDeleteClient({ clientId: record.id });
        };

        const onRestoreHandler = () => {
          onRestoreClient({ clientId: record.id });
        };

        const isDeleted = !!record.deleted_at;

        return (
          <div>
            <Dropdown
              menu={{
                items: [
                  {
                    label: 'Edit',
                    key: 'Edit',
                    onClick: () => onEditClient({ id: record.id }),
                    icon: <EditOutlined />,
                    disabled: !hasRights(
                      role,
                      CLIENTS_PERMISSIONS.updateClient,
                    ),
                  },
                  {
                    label: isDeleted ? 'Restore' : 'Remove',
                    key: 'Remove',
                    onClick: () =>
                      isDeleted ? onRestoreHandler() : onDeleteHandler(),
                    icon: isDeleted ? <RollbackOutlined /> : <DeleteOutlined />,
                    disabled: !hasRights(
                      role,
                      CLIENTS_PERMISSIONS.deleteClient,
                    ),
                  },
                ],
              }}
            >
              <EllipsisOutlined className={styles.actionsButton} />
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return columns;
};

export const getCurrentClientData = ({ clients, currentClientId }) => {
  if (clients && currentClientId) {
    const currentClient = clients.find(client => client.id === currentClientId);

    return currentClient ? currentClient : {};
  }

  return {};
};

export const getFiltersWithOutNull = filtersInfo =>
  Object.keys(filtersInfo).reduce(
    (acc, item) =>
      filtersInfo[item] ? { ...acc, [item]: filtersInfo[item] } : acc,
    {},
  );

export const getModifiedFilters = ({ FILTER_STRING, filters }) => {
  if (!filters) return {};

  const filterKeys = Object.keys(filters);

  return filterKeys.reduce((acc, curr) => {
    if (FILTER_STRING.includes(curr)) {
      return { ...acc, [curr]: filters[curr][0] };
    }

    return { ...acc, [curr]: filters[curr] };
  }, {});
};
