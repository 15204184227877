export const types = {
  GET_POSITIONS: 'employee-position/GET_POSITIONS',
  GET_POSITIONS_SUCCESS: 'employee-position/GET_POSITIONS_SUCCESS',
  GET_POSITIONS_FAILED: 'employee-position/GET_POSITIONS_FAILED',
  EDIT_POSITION: 'employee-position/EDIT_POSITION',
  EDIT_POSITION_FAILED: 'employee-position/EDIT_POSITION_FAILED',
  DELETE_POSITION: 'employee-position/DELETE_POSITION',
  DELETE_POSITION_FAILED: 'employee-position/DELETE_POSITION_FAILED',
  ADD_POSITION: 'employee-position/ADD_POSITION',
  ADD_POSITION_SUCCESS: 'employee-position/ADD_POSITION_SUCCESS',
  ADD_POSITION_FAILED: 'employee-position/ADD_POSITION_FAILED',
  DELETE_POSITION_ERRORS: 'employee-position/DELETE_POSITION_ERRORS',
};
