import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import get from 'lodash/get';
import classNames from 'classnames';

import { STATUS_INFO } from 'containers/ClientsPage/constants';
import ClientHeaderTotal from 'components/ClientCommon/ClientInfo/ClientHeaderTotal';
import Tag from 'components/Common/Tag';

import styles from './styles.scss';

const ClientInfo = ({ client, clientPaid, clientPending }) => {
  const { title, status, avatar, owner_name } = client;

  return (
    <div className={styles.clientHeader}>
      <Avatar size={120} src={avatar} className={styles.avatar}>
        {!avatar && title[0]?.toUpperCase()}
      </Avatar>

      <div
        className={classNames({
          [styles.summary]: true,
          [styles.summaryWithTotal]: clientPending.length || clientPaid.length,
        })}
      >
        <span className={styles.title}>{title}</span>
        <span className={styles.ownerName}>{owner_name}</span>
        <span className={styles.location}>{client.location}</span>
        <Tag className={styles.tag} color={get(STATUS_INFO, [status, 'color'])}>
          {status}
        </Tag>
      </div>
      <ClientHeaderTotal data={clientPending} title="Pending" />
      <ClientHeaderTotal data={clientPaid} title="Paid" />
    </div>
  );
};

ClientInfo.propTypes = {
  client: PropTypes.object,
  clientPaid: PropTypes.array,
  clientPending: PropTypes.array,
};

export default ClientInfo;
