import { ROLE_TYPES } from 'utils/permissions';

export const CONTRACTS_TABLE_COMMON = {
  id_title: 'ID',
  id: 'id',
  contractor: 'Contractor',
  contractor_title: 'contractor_title',
  contractor_id: 'contractor_id',
  customer: 'Customer',
  customer_title: 'customer_title',
  customer_id: 'customer_id',
  owner: 'Owner',
  owner_name: 'owner_name',
  owner_id: 'owner_id',
  attachments_title: 'Attachments',
  attachments: 'attachments',
  dated_title: 'Dated',
  dated: 'dated',
  number_title: 'Number',
  number: 'number',
  start_date_title: 'Starts at',
  start_date: 'start_date',
  end_date_title: 'Ends at',
  end_date: 'end_date',
  created_at_title: 'Created at',
  created_at: 'created_at',
  updated_at_title: 'Updated at',
  updated_at: 'updated_at',
};

export const CONTRACTS_PERMISSIONS = {
  deleteContract: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
};
