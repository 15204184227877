import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Steps, Button } from 'antd';

const { Step } = Steps;

const ClientEditDealSteps = ({
  steps,
  dealStartsAt,
  onUpdateDeal,
  setIsFinishEditDeal,
  dealForm,
  setDealValues,
  dealValues,
  isDisableNextButton,
}) => {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);

    if (current === 0) {
      setIsFinishEditDeal(false);
    }
  };

  const onDealEditCompleted = async () => {
    const isValidate = await dealForm.validateFields();

    if (isValidate) {
      setIsFinishEditDeal(true);
      setDealValues(dealForm.getFieldsValue());
      next();
    }
  };

  return (
    <>
      <Steps current={current} style={{ marginBottom: '15px' }} size="small">
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action" style={{ marginTop: '15px' }}>
        {current > 0 && current !== 3 && (
          <Button style={{ margin: '0 8px' }} onClick={prev}>
            Previous
          </Button>
        )}
        {current === 0 && !dealValues.deleted_at && (
          <Button
            type="primary"
            onClick={onDealEditCompleted}
            disabled={!dealStartsAt || isDisableNextButton}
          >
            Next
          </Button>
        )}
        {current === 1 && (
          <Button type="primary" onClick={onUpdateDeal}>
            Confirm & Save
          </Button>
        )}
      </div>
    </>
  );
};

ClientEditDealSteps.propTypes = {
  steps: PropTypes.array,
  dealForm: PropTypes.object,
  setIsFinishEditDeal: PropTypes.func,
  setDealValues: PropTypes.func,
  onUpdateDeal: PropTypes.func,
  dealStartsAt: PropTypes.object,
  dealValues: PropTypes.object,
  isDisableNextButton: PropTypes.bool,
};

export default ClientEditDealSteps;
