import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Upload } from 'antd';
import { isEmpty } from 'lodash';
import { InboxOutlined } from '@ant-design/icons';

import { PROFILES_PERMISSIONS } from 'containers/ProfilesPage/constants';
import { Loader } from 'components/Common';
import { hasRights } from 'utils/permissions';

import AttachmentPreviewModal from './AttachmentPreviewModal';
import styles from './styles.scss';

const { Dragger } = Upload;

const EmployeeAttachments = ({
  isAttachmentsModalVisible,
  hideAttachmentsModal,
  uploadAttachment,
  isAttachmentLoading,
  attachmentsList,
  onAttachmentFileChange,
  onRemoveAttachment,
  isLoading,
  onDeleteEmployeeAttachment,
  role,
  isSummary,
}) => {
  const [doc, setDoc] = useState(null);

  const attachmentFakeRequest = options => {
    setTimeout(() => {
      options.onSuccess('ok');
    }, 0);
  };

  const onPreview = file => {
    setDoc([{ uri: file.url }]);
  };

  const onClosePreviewModal = () => {
    setDoc(null);
  };

  const onDownloadAttachment = doc => {
    doc.length && window.open(doc[0].uri);
  };

  return (
    <div className={styles.profileAttachments}>
      <Modal
        className={styles.modal}
        title="Upload attachment"
        open={isAttachmentsModalVisible}
        onCancel={hideAttachmentsModal}
        cancelText="Отмена"
        destroyOnClose
        maskClosable
        footer={[
          <Button onClick={hideAttachmentsModal}>Cancel</Button>,
          <Button
            type="primary"
            onClick={uploadAttachment}
            loading={isAttachmentLoading}
          >
            Upload
          </Button>,
        ]}
      >
        <Dragger
          name="file"
          multiple={false}
          maxCount={1}
          onChange={onAttachmentFileChange}
          customRequest={attachmentFakeRequest}
          onRemove={onRemoveAttachment}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
      </Modal>
      <div className={styles.attachmentsList}>
        <Loader loading={isLoading}>
          {!isEmpty(attachmentsList) ? (
            <Upload
              showUploadList={{
                showDownloadIcon: false,
                showRemoveIcon:
                  !isSummary &&
                  hasRights(role, PROFILES_PERMISSIONS.deleteAttachment),
              }}
              onRemove={onDeleteEmployeeAttachment}
              onPreview={onPreview}
              fileList={attachmentsList}
            />
          ) : (
            <p className={styles.attachmentsEmpty}>No attachments found</p>
          )}
        </Loader>
      </div>
      {doc && (
        <AttachmentPreviewModal
          doc={doc}
          isVisible={!!doc}
          onClose={onClosePreviewModal}
          onDownloadAttachment={onDownloadAttachment}
        />
      )}
    </div>
  );
};

EmployeeAttachments.propTypes = {
  isAttachmentsModalVisible: PropTypes.bool.isRequired,
  hideAttachmentsModal: PropTypes.func.isRequired,
  uploadAttachment: PropTypes.func.isRequired,
  isAttachmentLoading: PropTypes.bool.isRequired,
  attachmentsList: PropTypes.array,
  onAttachmentFileChange: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  onDeleteEmployeeAttachment: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  isSummary: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default EmployeeAttachments;
