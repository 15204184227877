import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import TechnologySelect from 'components/ResourceManagementCommon/TechnologySelect';

import styles from './styles.scss';

const FilterContent = ({
  data,
  selectedIndex,
  onSearchByType,
  onChangeCheckbox,
  field,
}) => (
  <div className={styles.filterContent}>
    <TechnologySelect
      width="300px"
      onChange={onSearchByType(field)}
      data={data}
      value=""
    />
    <span className={styles.titleGray}>Search and select to filter by.</span>
    {selectedIndex.length !== 0 && (
      <span className={styles.titleBold}>selected</span>
    )}
    <div className={styles.listCheckbox}>
      {selectedIndex.map(item => {
        const dataItem = data.find(elem => elem.title === item);

        if (!dataItem) return null;

        return (
          <Checkbox
            key={item}
            checked
            onChange={onChangeCheckbox(dataItem.title, field)}
          >
            {dataItem.title}
          </Checkbox>
        );
      })}
    </div>
  </div>
);

FilterContent.propTypes = {
  data: PropTypes.array.isRequired,
  selectedIndex: PropTypes.array.isRequired,
  onSearchByType: PropTypes.func.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
};

export default FilterContent;
