import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import isEmpty from 'lodash/isEmpty';

import Select, { Option } from 'components/Common/Select';

import styles from './styles.scss';

const JiraUserSelect = ({
  data = [],
  width = '100%',
  onChange = () => {},
  onSearch = () => {},
  value,
  disabled = true,
  jiraUser,
}) => {
  const defaultData = isEmpty(data) ? (jiraUser ? [jiraUser] : []) : data;

  return (
    <Select
      disabled={disabled}
      value={value}
      showSearch
      style={{ width }}
      optionFilterProp="children"
      onSearch={onSearch}
      onChange={onChange}
      filterOption={(input, option) =>
        option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >=
          0 ||
        option.props.children[3].props.children[1]
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
    >
      {defaultData.map(item => (
        <Option key={item.name} value={item.name}>
          <Avatar
            className={styles.avatar}
            shape="square"
            size={20}
            src={item.avatarUrls['32x32']}
          />
          {item.displayName}{' '}
          <span className={styles.jiraName}>({item.name})</span>
        </Option>
      ))}
    </Select>
  );
};

JiraUserSelect.propTypes = {
  data: PropTypes.array,
  width: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  jiraUser: PropTypes.object,
};

export default JiraUserSelect;
