import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getProfitAndLossData } from './utils';
import ProfitAndLossChart from './ProfitAndLossChart';
import ProfitAndLossTotal from './ProfitAndLossTotal';
import ProfitAndLossHeader from './ProfitAndLossHeader';

import styles from './styles.scss';

const ProfitAndLoss = ({
  dashboardData: {
    months_data,
    most_profitable_project,
    most_unprofitable_project,
  },
  period,
  calculateProgress,
}) => {
  const profitAndLossData = useMemo(
    () =>
      getProfitAndLossData({
        monthData: months_data,
        period,
      }),
    [months_data, period],
  );

  return (
    <div className={styles.profitAndLoss}>
      <ProfitAndLossHeader
        period={period}
        calculateProgress={calculateProgress}
      />
      <div className={styles.profitAndLossContainer}>
        <ProfitAndLossChart monthData={profitAndLossData} />
        <ProfitAndLossTotal
          monthsData={months_data}
          mostProfitableProject={most_profitable_project}
          mostUnprofitableProject={most_unprofitable_project}
        />
      </div>
    </div>
  );
};

ProfitAndLoss.propTypes = {
  dashboardData: PropTypes.shape({
    most_profitable_project: PropTypes.object,
    most_unprofitable_project: PropTypes.object,
    months_data: PropTypes.array,
  }),
  period: PropTypes.array.isRequired,
  calculateProgress: PropTypes.object,
};

export default ProfitAndLoss;
