export const types = {
  GET_DASHBOARD_EFFECTIVENESS: 'dashboard/GET_DASHBOARD_EFFECTIVENESS',
  GET_DASHBOARD_EFFECTIVENESS_SUCCESS:
    'dashboard/GET_DASHBOARD_EFFECTIVENESS_SUCCESS',
  GET_DASHBOARD_EFFECTIVENESS_FAILED:
    'dashboard/GET_DASHBOARD_EFFECTIVENESS_FAILED',

  GET_DASHBOARD_EMPLOYEES: 'dashboard/GET_DASHBOARD_EMPLOYEES',
  GET_DASHBOARD_EMPLOYEES_SUCCESS: 'dashboard/GET_DASHBOARD_EMPLOYEES_SUCCESS',
  GET_DASHBOARD_EMPLOYEES_FAILED: 'dashboard/GET_DASHBOARD_EMPLOYEES_FAILED',

  GET_DASHBOARD_EMPLOYEE_DATA: 'dashboard/GET_DASHBOARD_EMPLOYEE_DATA',
  GET_DASHBOARD_EMPLOYEE_DATA_SUCCESS:
    'dashboard/GET_DASHBOARD_EMPLOYEE_DATA_SUCCESS',
  GET_DASHBOARD_EMPLOYEE_DATA_FAILED:
    'dashboard/GET_DASHBOARD_EMPLOYEE_DATA_FAILED',

  GET_DASHBOARD_OVERVIEW: 'dashboard/GET_DASHBOARD_OVERVIEW',
  GET_DASHBOARD_OVERVIEW_SUCCESS: 'dashboard/GET_DASHBOARD_OVERVIEW_SUCCESS',
  GET_DASHBOARD_OVERVIEW_FAILED: 'dashboard/GET_DASHBOARD_OVERVIEW_FAILED',

  GET_DASHBOARD_SALARY_CHART: 'dashboard/GET_DASHBOARD_SALARY_CHART',
  GET_DASHBOARD_SALARY_CHART_SUCCESS:
    'dashboard/GET_DASHBOARD_SALARY_CHART_SUCCESS',
  GET_DASHBOARD_SALARY_CHART_FAILED:
    'dashboard/GET_DASHBOARD_SALARY_CHART_FAILED',

  GET_DASHBOARD_SALARY_TABLE: 'dashboard/GET_DASHBOARD_SALARY_TABLE',
  GET_DASHBOARD_SALARY_TABLE_SUCCESS:
    'dashboard/GET_DASHBOARD_SALARY_TABLE_SUCCESS',
  GET_DASHBOARD_SALARY_TABLE_FAILED:
    'dashboard/GET_DASHBOARD_SALARY_TABLE_FAILED',

  GET_DASHBOARD_SUBCONTRACTORS_TABLE:
    'dashboard/GET_DASHBOARD_SUBCONTRACTORS_TABLE',
  GET_DASHBOARD_SUBCONTRACTORS_TABLE_SUCCESS:
    'dashboard/GET_DASHBOARD_SUBCONTRACTORS_TABLE_SUCCESS',
  GET_DASHBOARD_SUBCONTRACTORS_TABLE_FAILED:
    'dashboard/GET_DASHBOARD_SUBCONTRACTORS_TABLE_FAILED',

  GET_DASHBOARD_SUBCONTRACTORS: 'dashboard/GET_DASHBOARD_SUBCONTRACTORS',
  GET_DASHBOARD_SUBCONTRACTORS_SUCCESS:
    'dashboard/GET_DASHBOARD_SUBCONTRACTORS_SUCCESS',
  GET_DASHBOARD_SUBCONTRACTORS_FAILED:
    'dashboard/GET_DASHBOARD_SUBCONTRACTORS_FAILED',
};
