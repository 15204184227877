import React from 'react';
import PropTypes from 'prop-types';
import { AER, COM, HIGHEST_RATIO, LOWEST_RATIO } from '../constants';

import styles from './styles.scss';
import DashboardAERTotalProjectsItem from './DashboardAERTotalProjectsItem';

const DashboardAERTotalProjects = ({
  highestAer,
  highestCom,
  lowestAer,
  lowestCom,
  lastMonth,
  highestAerProject,
  lowestAerProject,
}) => (
  <div className={styles.dashboardAERTotalProjects}>
    <div className={styles.subHeader}>
      <span>{lastMonth?.month}</span>
      <div className={styles.sign}>
        <span>{AER}</span>
        <span>{COM}</span>
      </div>
    </div>
    <DashboardAERTotalProjectsItem
      title={HIGHEST_RATIO}
      project={highestAerProject}
      aer={highestAer}
      com={highestCom}
    />
    <DashboardAERTotalProjectsItem
      title={LOWEST_RATIO}
      project={lowestAerProject}
      aer={lowestAer}
      com={lowestCom}
    />
  </div>
);

DashboardAERTotalProjects.propTypes = {
  highestAer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highestCom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lowestAer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lowestCom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highestAerProject: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lowestAerProject: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DashboardAERTotalProjects;
