import { takeLatest, call, put, select } from 'redux-saga/effects';
import { message } from 'antd';
import dayjs from 'dayjs';

import { averageSalariesSelector } from 'redux/selectors';
import API from 'utils/api';

import { set } from 'lodash';
import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';
import { types } from './types';

function* getAverageSalaries({ payload }) {
  try {
    const { data } = yield call(API.getAverageSalaries, payload);
    const formattedData = data.reduce((memo, { date, value }) => {
      const buf = memo;
      const monthNumber = dayjs(date).get('month');
      set(buf, [monthNumber], value);

      return buf;
    }, {});

    const { averageSalaries } = yield select(averageSalariesSelector);

    averageSalaries[payload] = formattedData;

    yield put({
      type: types.GET_AVERAGE_SALARY_BY_YEAR_SUCCESS,
      payload: averageSalaries,
    });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_AVERAGE_SALARY_BY_YEAR_FAILED);
  }
}

function* handleNewSalary({ date, value }) {
  try {
    const { averageSalaries } = yield select(averageSalariesSelector);
    set(
      averageSalaries,
      [dayjs(date).get('year'), dayjs(date).get('month')],
      value,
    );

    yield put({
      type: types.GET_AVERAGE_SALARY_BY_YEAR_SUCCESS,
      payload: averageSalaries,
    });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_AVERAGE_SALARY_BY_YEAR_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* addAverageSalary({ payload }) {
  try {
    const { data } = yield call(API.addAverageSalary, payload);
    yield call(handleNewSalary, data);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_AVERAGE_SALARY_BY_YEAR_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* updateAverageSalary({ payload }) {
  try {
    const { data } = yield call(API.updateAverageSalary, payload);
    yield call(handleNewSalary, data);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_AVERAGE_SALARY_BY_YEAR_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_AVERAGE_SALARY_BY_YEAR, getAverageSalaries);
  yield takeLatest(types.ADD_AVERAGE_SALARY, addAverageSalary);
  yield takeLatest(types.UPDATE_AVERAGE_SALARY, updateAverageSalary);
}
