import { takeLatest, call, put } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { get } from 'lodash';
import Cookies from 'js-cookie';

import { DATE_FORMAT } from 'utils/timeConstants';
import API from 'utils/api';
import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';
import { types as exchangeRatesTypes } from 'redux/ExchangeRates/types';

import { types } from './types';

function* getPaysheets({ payload }) {
  try {
    const period = dayjs.isDayjs(payload.period)
      ? payload.period.format(DATE_FORMAT)
      : payload.period;

    const response = yield call(API.loadPaySheets, {
      period,
      params: {
        expand: ['employee'],
      },
    });
    const avaragePeriod = get(
      response.headers,
      'x-average-salary-rate-date',
      null,
    );

    const exchangeDate = get(response.headers, 'x-exchange-rate-date', null);

    yield payload.canGetAverageSalariesPeriod &&
      put({
        type: types.GET_AVERAGE_SALARY_PERIOD,
        payload: { avaragePeriod },
      });

    yield payload.canGetExchangeRates &&
      put({
        type: exchangeRatesTypes.GET_EXCHANGE_RATE_FOR_DATE,
        payload: exchangeDate,
      });

    yield put({
      type: types.GET_PAYSHEETS_SUCCESS,
      payload: {
        paysheets: response,
        exchangeDate,
        period,
      },
    });
  } catch (error) {
    if (!error.response) {
      yield put({
        type: types.GET_PAYSHEETS_FAILED,
        payload: error.message,
      });
    } else {
      const errorMessage = get(
        error,
        'response.data.message',
        'Unknown error!',
      );

      yield put({
        type: types.GET_PAYSHEETS_FAILED,
        payload: errorMessage,
      });
    }
  }
}

function* getAverageSalaryForPeriod({ payload: { avaragePeriod } }) {
  try {
    const { data } = yield call(API.loadAverageSalaryForPeriod, avaragePeriod);

    yield put({
      type: types.GET_AVERAGE_SALARY_PERIOD_SUCCESS,
      payload: { averageSalaryRate: data?.value, avaragePeriod },
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_AVERAGE_SALARY_PERIOD_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* downloadPaysheets({ payload }) {
  try {
    const token = yield Cookies.get('accessToken');
    yield call(API.getExports, dayjs(payload).format(DATE_FORMAT), token);
    yield put({ type: types.DOWNLOAD_PAYSHEETS_SUCCESS });
  } catch (error) {
    if (!error.response) {
      yield put({
        type: types.DOWNLOAD_PAYSHEETS_FAILED,
        payload: error.message,
      });
    } else {
      const errorMessage = get(
        error,
        'response.data.message',
        'Unknown error!',
      );

      yield put({
        type: types.DOWNLOAD_PAYSHEETS_FAILED,
        payload: errorMessage,
      });
    }
  }
}

function* updatePaysheets({ payload: { period, ...payload } }) {
  try {
    yield call(API.updatePaysheetValues, period, payload);
    yield put({ type: types.GET_PAYSHEETS, payload: { period } });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_PAYSHEETS_VALUES_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getAverageSalary({ payload }) {
  try {
    const response = yield call(API.getAverageSalaryForPeriod, payload);

    if (!response.data) {
      response.data = {
        date: payload,
        value: 0,
      };
    }

    yield put({
      type: types.GET_AVERAGE_SALARY_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_AVERAGE_SALARY_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_PAYSHEETS, getPaysheets);
  yield takeLatest(types.DOWNLOAD_PAYSHEETS, downloadPaysheets);
  yield takeLatest(types.UPDATE_PAYSHEETS_VALUES, updatePaysheets);
  yield takeLatest(types.GET_AVERAGE_SALARY, getAverageSalary);
  yield takeLatest(types.GET_AVERAGE_SALARY_PERIOD, getAverageSalaryForPeriod);
}
