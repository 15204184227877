import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { FULL_DATE_FORMAT } from 'utils/timeConstants';

const TimelineItemLabel = ({ filteredInvoiceAudit, usersObject, status }) => {
  const currentAudit = filteredInvoiceAudit[status];

  if (!currentAudit) {
    return null;
  }

  const currentUser = usersObject[currentAudit.user_id];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>{dayjs(currentAudit.created_at).format(FULL_DATE_FORMAT)}</div>
      <div>{currentUser?.name || currentUser?.email}</div>
    </div>
  );
};

TimelineItemLabel.propTypes = {
  usersObject: PropTypes.object,
  filteredInvoiceAudit: PropTypes.object,
  status: PropTypes.string,
};

export default TimelineItemLabel;
