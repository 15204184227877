import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Result } from 'antd';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

import styles from './styles.scss';

const NoRendered = () => (
  <div className={styles.noRenderer}>
    <Result
      status="warning"
      title="Unable to preview this type of file. You still can download it"
    />
  </div>
);

const AttachmentPreviewModal = ({
  doc,
  isVisible,
  onClose,
  onDownloadAttachment,
}) => (
  <Modal
    open={isVisible}
    onCancel={onClose}
    centered
    width={700}
    footer={
      <Button onClick={() => onDownloadAttachment(doc)}>Download file</Button>
    }
  >
    <div className={styles.attachmentPreviewModal}>
      <DocViewer
        documents={doc}
        prefetchMethod="GET"
        pluginRenderers={DocViewerRenderers}
        config={{
          noRenderer: {
            overrideComponent: NoRendered,
          },
        }}
      />
    </div>
  </Modal>
);

AttachmentPreviewModal.propTypes = {
  doc: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadAttachment: PropTypes.func.isRequired,
};

export default AttachmentPreviewModal;
