import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components/Common';

import styles from './styles.scss';

const TableViewList = ({ entity, push, ...props }) => {
  function onRow(record) {
    return {
      onClick: () => push(`/${entity}/${record.userId}`),
    };
  }

  return (
    <Table
      className={styles.table}
      rowClassName={styles.table__row}
      onRow={entity && onRow}
      {...props}
    />
  );
};

TableViewList.propTypes = {
  entity: PropTypes.string,
  push: PropTypes.func,
};

export default TableViewList;
