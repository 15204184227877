import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import Modal from 'components/Common/Modal';
import Input from 'components/Common/Input';

import styles from './styles.scss';

const { Item } = Form;

const RevenueModal = ({ open, onSave, form, onClose }) => (
  <Modal
    title="Edit project revenue"
    open={open}
    onOk={form.submit}
    onCancel={onClose}
  >
    <Form form={form} onFinish={onSave}>
      <Item name="revenue" label="Revenue">
        <Input className={styles.input} />
      </Item>
      <Item name="period" hidden />
      <Item name="project_id" hidden />
    </Form>
  </Modal>
);

RevenueModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RevenueModal;
