export const DB_STORES = {
  comments: 'comments',
  worklogs: 'worklogs',
};

export const DBConfig = {
  databaseName: 'yellowERP',
  version: 1,
  stores: [
    {
      name: DB_STORES.comments,
      id: { keyPath: 'employeeId', autoIncrement: false },
      indices: [],
    },
    {
      name: DB_STORES.worklogs,
      id: { keyPath: 'invoiceId', autoIncrement: false },
      indices: [],
    },
  ],
};
