/* eslint-disable indent */
import {
  GREEN_STATUSES,
  RED_STATUSES,
  GRAY_STATUSES,
  YELLOW_STATUSES,
} from 'components/ClientCommon/ClientTimeline/TimelineItems/InvoiceItem/constants';
import { TIMELINE_COLORS } from './constants';

export const getFilteredInvoiceAudit = invoiceAudit =>
  invoiceAudit
    ? invoiceAudit.reduce((acc, curr) => {
        const isStatus = Object.keys(curr.audited_changes).includes('status');

        if (isStatus) {
          const { status } = curr.audited_changes;

          const statusKey = Array.isArray(status) ? status[1] : status;

          return { ...acc, [statusKey]: curr };
        }

        return acc;
      }, {})
    : [];

export const getTimelineColors = ({ status, index, invoiceStatusIndex }) => {
  if (invoiceStatusIndex < index) {
    return getTimelineIconColor({ status });
  }

  return '#000000d9 ';
};

export const getTimelineIconColor = ({ status }) =>
  (RED_STATUSES.includes(status) && TIMELINE_COLORS.red) ||
  (GRAY_STATUSES.includes(status) && TIMELINE_COLORS.gray) ||
  (GREEN_STATUSES.includes(status) && TIMELINE_COLORS.green) ||
  (YELLOW_STATUSES.includes(status) && TIMELINE_COLORS.yellow);
