import React from 'react';
import PropTypes from 'prop-types';

import { formatNumberWithCommas } from 'utils/helpers';

import styles from './styles.scss';

const DashboardSalaryTotal = ({ totalData }) => (
  <div className={styles.dashboardSalaryTotal}>
    {totalData &&
      Object.entries(totalData).map(([organization, value]) => (
        <div className={styles.totalItem} key={organization}>
          <span className={styles.organization}>{organization}</span>
          <span className={styles.value}>{`${formatNumberWithCommas(
            value,
          )} USD`}</span>
        </div>
      ))}
  </div>
);

DashboardSalaryTotal.propTypes = {
  totalData: PropTypes.object,
};

export default DashboardSalaryTotal;
