import React from 'react';

import Tag from 'components/Common/Tag';
import TableActions from 'components/Common/TableActions';
import { ROLES_COMMON } from './constants';

import styles from './styles.scss';

export const getColumns = ({ onEdit, onDelete, groupedPermissions }) => [
  {
    title: ROLES_COMMON.id_title,
    dataIndex: ROLES_COMMON.id,
    width: 50,
  },
  {
    title: ROLES_COMMON.name_title,
    dataIndex: ROLES_COMMON.name,
    width: 170,
  },
  {
    title: ROLES_COMMON.role_type_title,
    dataIndex: ROLES_COMMON.role_type,
    width: 150,
  },
  {
    title: ROLES_COMMON.permission_ids_title,
    dataIndex: ROLES_COMMON.permission_ids,
    width: 760,
    render: permissionIds => {
      const permissions =
        permissionIds?.map(id => groupedPermissions[id]) || [];

      return (
        <div className={styles.permissionCell}>
          {permissions.sort().map(permission => (
            <Tag className={styles.roleTag} key={permission}>
              {permission}
            </Tag>
          ))}
        </div>
      );
    },
  },
  {
    title: '',
    dataIndex: 'actions',
    width: 50,
    align: 'center',
    render: (value, record) => (
      <TableActions
        onEdit={onEdit(record)}
        onDelete={onDelete(record.id)}
        canEdit
        canDelete
      />
    ),
  },
];

export const groupPermissionsById = data =>
  data?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr.key,
    }),
    {},
  ) || {};

export const getformattedPermissions = permissions =>
  permissions?.map(item => ({
    key: item.id,
    title: item.key,
  })) || [];
