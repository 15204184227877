export const types = {
  GET_ORGANIZATIONS_SEARCH: 'organizations/GET_ORGANIZATIONS_SEARCH',
  GET_ORGANIZATIONS_SEARCH_SUCCESS:
    'organizations/GET_ORGANIZATIONS_SEARCH_SUCCESS',
  GET_ORGANIZATIONS_SEARCH_FAILED:
    'organizations/GET_ORGANIZATIONS_SEARCH_FAILED',

  GET_ORGANIZATIONS: 'organizations/GET_ORGANIZATIONS',
  GET_ORGANIZATIONS_SUCCESS: 'organizations/GET_ORGANIZATIONS_SUCCESS',
  GET_ORGANIZATIONS_FAILED: 'organizations/GET_ORGANIZATIONS_FAILED',

  CREATE_ORGANIZATION: 'organizations/CREATE_ORGANIZATION',
  CREATE_ORGANIZATION_FAILED: 'organizations/CREATE_ORGANIZATION_FAILED',
  CREATE_ORGANIZATION_SUCCESS: 'organizations/CREATE_ORGANIZATION_SUCCESS',

  UPDATE_ORGANIZATION: 'organizations/UPDATE_ORGANIZATION',
  UPDATE_ORGANIZATION_SUCCESS: 'organizations/UPDATE_ORGANIZATION_SUCCESS',
  UPDATE_ORGANIZATION_FAILED: 'organizations/UPDATE_ORGANIZATION_FAILED',

  DELETE_ORGANIZATION: 'organizations/DELETE_ORGANIZATION',
  DELETE_ORGANIZATION_FAILED: 'organizations/DELETE_ORGANIZATION_FAILED',
  DELETE_ORGANIZATION_SUCCESS: 'organizations/DELETE_ORGANIZATION_SUCCESS',

  GET_ORGANIZATION_ACCOUNTS: 'organizations/GET_ORGANIZATION_ACCOUNTS',
  GET_ORGANIZATION_ACCOUNTS_SUCCESS:
    'organizations/GET_ORGANIZATION_ACCOUNTS_SUCCESS',
  GET_ORGANIZATION_ACCOUNTS_FAILED:
    'organizations/GET_ORGANIZATION_ACCOUNTS_FAILED',

  CREATE_ORGANIZATION_ACCOUNT: 'organizations/CREATE_ORGANIZATION_ACCOUNT',
  CREATE_ORGANIZATION_ACCOUNT_SUCCESS:
    'organizations/CREATE_ORGANIZATION_ACCOUNT_SUCCESS',
  CREATE_ORGANIZATION_ACCOUNT_FAILED:
    'organizations/CREATE_ORGANIZATION_ACCOUNT_FAILED',

  UPDATE_ORGANIZATION_ACCOUNT: 'organizations/UPDATE_ORGANIZATION_ACCOUNT',
  UPDATE_ORGANIZATION_ACCOUNT_SUCCESS:
    'organizations/UPDATE_ORGANIZATION_ACCOUNT_SUCCESS',
  UPDATE_ORGANIZATION_ACCOUNT_FAILED:
    'organizations/UPDATE_ORGANIZATION_ACCOUNT_FAILED',

  DELETE_ORGANIZATION_ACCOUNT: 'organizations/DELETE_ORGANIZATION_ACCOUNT',
  DELETE_ORGANIZATION_ACCOUNT_FAILED:
    'organizations/DELETE_ORGANIZATION_ACCOUNT_FAILED',
  DELETE_ORGANIZATION_ACCOUNT_SUCCESS:
    'organizations/DELETE_ORGANIZATION_ACCOUNT_SUCCESS',

  GET_INVOICE_ORGANIZATION_ACCOUNTS:
    'organizations/GET_INVOICE_ORGANIZATION_ACCOUNTS',
  GET_INVOICE_ORGANIZATION_ACCOUNTS_SUCCESS:
    'organizations/GET_INVOICE_ORGANIZATION_ACCOUNTS_SUCCESS',
  GET_INVOICE_ORGANIZATION_ACCOUNTS_FAILED:
    'organizations/GET_INVOICE_ORGANIZATION_ACCOUNTS_FAILED',

  CLEAR_ORGANIZATION_ACCOUNTS: 'organizations/CLEAR_ORGANIZATION_ACCOUNTS',
};
