import { ROLE_TYPES } from 'utils/permissions';

export const PROJECT_CATEGORIES = {
  commercial: 'commercial',
  supermachine: 'supermachine',
  internal: 'internal',
};

export const DASHBOARD_OVERVIEW_TOOLTIPS = {
  PROFIT_AND_LOSS: `The graph shows revenue, costs and profit for the company's projects. The data is taken from the projects report. The costs contain adjustments`,
  REVENUE: `Revenue is calculated according to the project report`,
  GPM: `GPM % shows the profitability of projects.
  GPM % = Profit / Revenue * 100%
  All data is taken from the projects report`,
  MPP: `The most profitable project shows data of the project with the largest GPM % for the selected period.
  GPM % = Profit / Revenue * 100% `,
  MUP: `The most unprofitable project shows data of the project with the lowest GPM % for the selected period.
  GPM % = Profit / Revenue * 100% `,
  CLIENT_CONCENTRATION: `The table with Client concentration shows the TOP 5 customers by revenue for the last selected period. Revenue information is taken from the project report`,
};

export const DEFAULT_FILTER_STATE = {
  selected: [],
  applied: [],
};

export const DO_PERMISSIONS = {
  filters: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
};
