import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Breadcrumb } from 'antd';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { EMPLOYEES_PAGE_SIZE } from 'containers/EmployeesProfiles/constants';
import ProfileMain from 'components/ProfilesCommon/ProfileMain';
import ProfileDetails from 'components/ProfilesCommon/ProfileDetails';
import { Loader } from 'components/Common';
import PageLoader from 'components/Common/PageLoader';
import { SORT_DIRECTIONS } from 'utils/constants';
import { useRoleContext } from 'utils/hooks';
import {
  authSelector,
  employeesSelector,
  jiraSelector,
  organizationsSelector,
  summaryEmployeesSelector,
  teamsSelector,
  usersSelector,
} from 'redux/selectors';
import {
  loadEmployees,
  getEmployee,
  getEmployeeComments,
  addEmployeeComment,
  updateEmployeeComment,
  deleteEmployeeComment,
  getEmployeeExtraPayments,
  getEmployeeSalaries,
} from 'redux/Employees/actions';
import { getTeams } from 'redux/Teams/actions';
import { loadUsers } from 'redux/Users/actions';
import { getOrganizations } from 'redux/Organizations/actions';
import { getJiraProjects } from 'redux/Jira/actions';
import { getSummaryEmployees } from 'redux/SummaryEmployees/actions';
import { ROUTING } from 'config/routing';

import { getCommentsWithEmployeesInfo } from './utils';

import styles from './styles.scss';

const ProfilesPage = () => {
  const [commentsWithEmployeesInfo, setCommentsWithEmployeesInfo] = useState(
    [],
  );

  const { id } = useParams();

  const dispatch = useDispatch();

  const {
    employees,
    profile,
    employeeComments,
    employeeExtraPayments,
    employeeSalaries,
    employeeAttachments,
    employeeTechnologies,
    isAttachmentLoading,
    isEmployeesLoading,
    isEmployeeUpdateLoading,
  } = useSelector(employeesSelector);

  const { organizationsFull, isOrganizationsLoading } = useSelector(
    organizationsSelector,
  );

  const { jiraProjects, isJiraLoading } = useSelector(jiraSelector);

  const { user, isAuthLoading } = useSelector(authSelector);

  const { teams, isTeamsLoading } = useSelector(teamsSelector);

  const { users, isUsersLoading } = useSelector(usersSelector);

  const { summaryEmployees, isSummaryEmployeesLoading } = useSelector(
    summaryEmployeesSelector,
  );

  const isPageLoading =
    isEmployeesLoading ||
    isOrganizationsLoading ||
    isJiraLoading ||
    isAuthLoading ||
    isTeamsLoading ||
    isUsersLoading ||
    isSummaryEmployeesLoading;

  const role = useRoleContext();

  const history = useHistory();

  useEffect(() => {
    dispatch(getEmployee(id));
    dispatch(loadEmployees({ size: EMPLOYEES_PAGE_SIZE }));
    dispatch(getTeams());
    dispatch(getEmployeeComments(id));
    dispatch(loadUsers());
    dispatch(getSummaryEmployees({ size: 9999 }));
    dispatch(getEmployeeExtraPayments(id));
    dispatch(getEmployeeSalaries(id));
    dispatch(getOrganizations());

    dispatch(getJiraProjects({ sort: `name:${SORT_DIRECTIONS.ascend}` }));

    window.scrollTo(0, 0);
  }, []);

  const onEditClick = () => {
    history.push(`/employees/${id}`);
  };

  useEffect(() => {
    setCommentsWithEmployeesInfo(
      getCommentsWithEmployeesInfo({ comments: employeeComments, users }),
    );
  }, [employeeComments, users]);

  const onAddComment = currentComment => {
    dispatch(addEmployeeComment({ currentId: id, currentComment }));
  };

  const onCommentDelete = commentId =>
    dispatch(deleteEmployeeComment({ comment_id: commentId, employee_id: id }));

  const onSaveEditedComment = (comment, commentId) =>
    dispatch(
      updateEmployeeComment({
        text: comment,
        comment_id: commentId,
        employee_id: id,
      }),
    );

  const isWard = user.employee_ids.includes(+id);

  if (!profile || isPageLoading) return <PageLoader />;

  const isOwnProfile = user.email === profile.email;

  const breadcrumbItems = [
    {
      title: 'Yellow',
      key: '0',
    },
    {
      title: <Link to={ROUTING.EMPLOYEES_PROFILES.path}>Employees</Link>,
      key: '1',
    },
    {
      title: get(profile, 'name_en', get(profile, 'name_ru')) || 'User name',
      key: '2',
    },
  ];

  return (
    <>
      <div className={styles.container}>
        <Breadcrumb items={breadcrumbItems} className={styles.breadcrumbs} />
        <div className={styles.content}>
          <Loader loading={isEmployeeUpdateLoading}>
            <ProfileDetails
              profile={profile}
              summaryEmployees={summaryEmployees}
              onEditClick={onEditClick}
              teams={teams}
              organizations={organizationsFull}
              role={role}
              isWard={isWard}
              employeeTechnologies={employeeTechnologies}
            />
          </Loader>
          <ProfileMain
            role={role}
            isWard={isWard}
            salaries={employeeSalaries}
            comments={commentsWithEmployeesInfo}
            onAddComment={onAddComment}
            onCommentDelete={onCommentDelete}
            onSaveEditedComment={onSaveEditedComment}
            authUser={user}
            employees={summaryEmployees}
            extraPayments={employeeExtraPayments}
            users={users}
            currentEmployeeId={id}
            isLoading={isEmployeeUpdateLoading}
            projects={jiraProjects}
            attachments={employeeAttachments}
            isAttachmentLoading={isAttachmentLoading}
            isOwnProfile={isOwnProfile}
          />
        </div>
      </div>
    </>
  );
};

export default ProfilesPage;
