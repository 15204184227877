import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import { REQUEST_TYPES } from 'containers/SocialRequestsPage/constants';
import { hasRights } from 'utils/permissions';
import { PROFILES_PERMISSIONS } from 'containers/ProfilesPage/constants';

import RequestsInfoItem from './RequestsInfoItem';
import styles from './styles.scss';

const { Text } = Typography;

const RequestsInfo = ({ requestsInfo, role, isWard, isOwnProfile }) => {
  const canSeeSickDays =
    isOwnProfile ||
    isWard ||
    hasRights(role, PROFILES_PERMISSIONS.sickdayBalance);
  const canSeeEducation =
    isOwnProfile ||
    isWard ||
    hasRights(role, PROFILES_PERMISSIONS.educationBalance);
  const canSeeHealth =
    isOwnProfile || hasRights(role, PROFILES_PERMISSIONS.healthBalance);
  const canSeeSport =
    isOwnProfile || hasRights(role, PROFILES_PERMISSIONS.sportBalance);

  if (!requestsInfo) return null;

  return (
    <div className={styles.requestsInfo}>
      <div className={styles.balance}>
        <span className={styles.balanceTitle}>Balance</span>
        <div className={styles.balanceItems}>
          {canSeeSickDays && (
            <RequestsInfoItem
              title="Sick day:"
              value={requestsInfo.sick_day}
              type={REQUEST_TYPES.sick_day.key}
            />
          )}
          {canSeeEducation && (
            <RequestsInfoItem
              title="Education:"
              value={requestsInfo.education}
              type={REQUEST_TYPES.education.key}
            />
          )}
          {canSeeHealth && (
            <RequestsInfoItem
              title="Health:"
              value={requestsInfo.health}
              type={REQUEST_TYPES.health.key}
            />
          )}
          {canSeeSport && (
            <RequestsInfoItem
              title="Sport:"
              value={requestsInfo.sport}
              type={REQUEST_TYPES.sport.key}
            />
          )}
        </div>
      </div>
      <Text type="secondary" className={styles.description}>
        Categories ‘Sick days’, ‘Health’ and ‘Education’ will be updated on
        December 1st. ‘Sport’ updates every month You could see your aproved
        requests in the Extra payments tab
      </Text>
    </div>
  );
};

RequestsInfo.propTypes = {
  requestsInfo: PropTypes.object,
  role: PropTypes.string.isRequired,
  isWard: PropTypes.bool.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
};

export default RequestsInfo;
