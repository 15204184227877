import React from 'react';
import dayjs from 'dayjs';
import { get } from 'lodash';

import Tag from 'components/Common/Tag';
import { DATE_MONTH_FORMAT } from 'utils/timeConstants';

import { actionsTagColor, ACTION_TYPES } from './constants';

const getTypeFilters = types =>
  types?.map(type => ({ text: type, value: type })) || [];

const getActorFilters = actors =>
  actors?.map(actor => ({
    text: actor.name,
    value: actor.id,
  })) || [];

const filteredValue = (valueType, filterInfo) =>
  (filterInfo && filterInfo[valueType]) || null;

export const getColumns = ({
  users,
  auditFilters,
  sorter,
  getColumnSearchProps,
  filtersInfo,
}) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    ...getColumnSearchProps('id'),
    sorter: true,
    sortOrder: sorter.columnKey === 'id' && sorter.order,
  },
  {
    title: 'Auditable type',
    dataIndex: 'auditable_type',
    key: 'auditable_type',
    filterMultiple: true,
    filters: getTypeFilters(auditFilters.auditable_types),
    filteredValue: filteredValue('auditable_type', filtersInfo),
  },
  {
    title: 'Auditable ID',
    dataIndex: 'auditable_id',
    key: 'auditable_id',
    ...getColumnSearchProps('auditable_id'),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    filters: ACTION_TYPES,
    render: getActionTag,
    filteredValue: filteredValue('action', filtersInfo),
  },
  {
    title: 'Version',
    dataIndex: 'version',
    key: 'version',
    ...getColumnSearchProps('version'),
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    key: 'created_at',
    ...getColumnSearchProps('created_at'),
    sorter: true,
    sortOrder: sorter.columnKey === 'created_at' && sorter.order,
    render: value => dayjs(value).format(DATE_MONTH_FORMAT),
  },
  {
    title: 'Actor',
    dataIndex: 'user_id',
    key: 'actor',
    render: value => getCurrentEmployee(value, users),
    filterMultiple: true,
    filters: getActorFilters(auditFilters.actors),
    filteredValue: filteredValue('actor', filtersInfo),
  },
];

const getActionTag = value => (
  <Tag color={get(actionsTagColor, [value, 'color'], '')}>{value}</Tag>
);

const getCurrentEmployee = (value, employees) => {
  const currentEmployee = employees.find(employee => employee.id === value);

  if (currentEmployee) {
    return (
      <>
        <span>
          {!get(currentEmployee, ['name'], '')
            ? get(currentEmployee, 'email')
            : get(currentEmployee, ['name'])}
        </span>
      </>
    );
  }
};
