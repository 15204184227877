import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import TableButton from 'components/Common/TableButton';
import Tag from 'components/Common/Tag';
import FilterDropdown from 'components/Common/FilterDropdown';
import {
  formatNumberWithCommas,
  getFilteredValue,
  getSortOrder,
} from 'utils/helpers';
import { LINKS } from 'config/routing';
import { DATE_MONTH_FORMAT, MONTH_NAME_FORMAT } from 'utils/timeConstants';
import { hasRights } from 'utils/permissions';

import { Tooltip } from 'antd';
import SearchTableIcon from 'components/Icons/SearchIcon';
import {
  APPROVES_STATUSES,
  REQUESTS_COMMON,
  REQUESTS_PERMISSIONS,
  REQUEST_TYPES,
  SOCIAL_REQUESTS_STATUSES,
} from './constants';
import styles from './styles.scss';

const getColumnSearchProps = ({ dataIndex, searchInput }) => ({
  filterDropdown: props => <FilterDropdown {...props} dataIndex={dataIndex} />,
  filterIcon: filtered => <SearchTableIcon isFiltered={filtered} />,
  onFilterDropdownOpenChange: open => {
    if (open) {
      setTimeout(() => searchInput.current.select(), 100);
    }
  },
});

const getTypeFilters = filters =>
  filters?.map(filter => ({
    text: REQUEST_TYPES[filter].title,
    value: REQUEST_TYPES[filter].key,
  })) || [];

export const getColumns = ({
  sortInfo,
  filterInfo,
  onApprove,
  onDecline,
  onPreview,
  searchInput,
  role,
  user,
  filters,
}) => [
  {
    title: REQUESTS_COMMON.id_title,
    dataIndex: REQUESTS_COMMON.id,
    key: REQUESTS_COMMON.id,
    sorter: true,
    width: 60,
    sortOrder: getSortOrder({
      field: REQUESTS_COMMON.id,
      fieldType: 'field',
      sortInfo,
    }),
  },
  {
    title: REQUESTS_COMMON.request_type_title,
    dataIndex: REQUESTS_COMMON.request_type,
    key: REQUESTS_COMMON.request_type,
    width: 100,
    filters: getTypeFilters(filters?.request_types),
    filteredValue: getFilteredValue({
      field: REQUESTS_COMMON.request_type,
      filterInfo,
    }),
    sorter: true,
    sortOrder: getSortOrder({
      field: REQUESTS_COMMON.request_type,
      fieldType: 'field',
      sortInfo,
    }),
  },
  {
    title: REQUESTS_COMMON.summary_employee_title,
    dataIndex: REQUESTS_COMMON.summary_employee,
    key: REQUESTS_COMMON.full_name,
    width: 125,
    sorter: true,
    sortOrder: getSortOrder({
      field: REQUESTS_COMMON.summary_employee,
      fieldType: 'field',
      sortInfo,
    }),
    ...getColumnSearchProps({
      dataIndex: REQUESTS_COMMON.full_name,
      searchInput,
    }),
    filteredValue: getFilteredValue({
      field: REQUESTS_COMMON.full_name,
      filterInfo,
    }),
    render: value => (
      <Link to={LINKS.summaryEmployee(value.id)}>{value.full_name}</Link>
    ),
  },
  {
    title: REQUESTS_COMMON.amount_title,
    dataIndex: REQUESTS_COMMON.amount,
    key: REQUESTS_COMMON.amount,
    width: 75,
    render: value => value && formatNumberWithCommas(value),
  },
  {
    title: REQUESTS_COMMON.attachments_title,
    dataIndex: REQUESTS_COMMON.attachments,
    key: REQUESTS_COMMON.attachments,
    width: 140,
    render: value =>
      value?.map(attachment => (
        /* eslint-disable jsx-a11y/no-static-element-interactions */
        /* eslint-disable jsx-a11y/click-events-have-key-events */
        <Tooltip title={attachment.file_name}>
          <div
            onClick={onPreview(attachment.url)}
            className={styles.attachment}
            key={attachment.file_name}
          >
            {attachment.file_name}
          </div>
        </Tooltip>
      )),
  },

  {
    title: REQUESTS_COMMON.description_title,
    dataIndex: REQUESTS_COMMON.description,
    key: REQUESTS_COMMON.description,
    width: 130,
  },
  {
    title: REQUESTS_COMMON.salary_title,
    dataIndex: REQUESTS_COMMON.salary,
    key: REQUESTS_COMMON.salary,
    sorter: true,
    width: 100,
    sortOrder: getSortOrder({
      field: REQUESTS_COMMON.salary,
      fieldType: 'field',
      sortInfo,
    }),
    render: value => value && dayjs(value.valid_from).format(MONTH_NAME_FORMAT),
  },
  {
    title: REQUESTS_COMMON.profile_title,
    dataIndex: REQUESTS_COMMON.profile,
    key: REQUESTS_COMMON.profile,
    width: 70,
    render: (value, record) =>
      value && (
        <Link to={LINKS.profile(record.approved_employee_id)}>{value}</Link>
      ),
  },
  {
    title: REQUESTS_COMMON.sent_at_title,
    dataIndex: REQUESTS_COMMON.sent_at,
    key: REQUESTS_COMMON.sent_at,
    sorter: true,
    width: 110,
    sortOrder: getSortOrder({
      field: REQUESTS_COMMON.sent_at,
      fieldType: 'field',
      sortInfo,
    }),
    render: value => value && dayjs(value).format(DATE_MONTH_FORMAT),
  },
  {
    title: REQUESTS_COMMON.reviewed_at_title,
    dataIndex: REQUESTS_COMMON.reviewed_at,
    key: REQUESTS_COMMON.reviewed_at,
    width: 100,
    render: value => value && dayjs(value).format(DATE_MONTH_FORMAT),
  },
  {
    title: REQUESTS_COMMON.status_title,
    dataIndex: REQUESTS_COMMON.status,
    key: REQUESTS_COMMON.status,
    sorter: true,
    width: 150,
    sortOrder: getSortOrder({
      field: REQUESTS_COMMON.status,
      fieldType: 'field',
      sortInfo,
    }),
    render: value => (
      <Tag color={SOCIAL_REQUESTS_STATUSES[value]?.color}>
        {value.replace(/_/g, ' ').toUpperCase()}
      </Tag>
    ),
  },
  {
    title: REQUESTS_COMMON.reviewer_title,
    dataIndex: REQUESTS_COMMON.reviewer,
    key: REQUESTS_COMMON.reviewer,
    sorter: true,
    width: 110,
    sortOrder: getSortOrder({
      field: REQUESTS_COMMON.reviewer,
      fieldType: 'field',
      sortInfo,
    }),
    render: value => value?.name,
  },
  {
    title: ' ',
    dataIndex: 'actions',
    width: 90,
    render: (_, record) => {
      const canReview = () => {
        const isRm = user.summary_ids.includes(record.summary_employee.id);

        switch (record.request_type) {
          case REQUEST_TYPES.health:
            return hasRights(role, REQUESTS_PERMISSIONS.healthReview);

          case REQUEST_TYPES.education:
            return (
              hasRights(role, REQUESTS_PERMISSIONS.educationReview) || isRm
            );

          case REQUEST_TYPES.sport:
            return hasRights(role, REQUESTS_PERMISSIONS.sportReview);

          case REQUEST_TYPES.sick_day:
            return hasRights(role, REQUESTS_PERMISSIONS.sickdayReview) || isRm;

          default:
            false;
        }
      };

      return (
        <>
          <TableButton
            type="primary"
            onClick={() => onApprove(record)}
            icon={<CheckOutlined />}
            disabled={!canReview || APPROVES_STATUSES.includes(record.status)}
            className={styles.approveBtn}
          />
          <TableButton
            danger
            onClick={() => onDecline(record)}
            icon={<CloseOutlined />}
            disabled={
              !canReview ||
              record.status === SOCIAL_REQUESTS_STATUSES.declined.key
            }
          />
        </>
      );
    },
  },
];

export const requestFormatter = value => `$${formatNumberWithCommas(value)}`;
