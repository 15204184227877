import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isProjectProfitsLoading: false,
  projectProfits: [],
  projectProfitsFilters: {},
  totals: {},
  infoAboutProjectProfitsByYear: [],
  isInfoAboutProjectProfitsLoading: false,
  managerProfiles: [],
  isManagerProfilesLoading: false,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_PROJECT_PROFITS:
    case types.UPDATE_PROJECT_PROFITS:
    case types.DELETE_REVENUE_FROM_PROJECT_PROFIT:
      return state.set('isProjectProfitsLoading', true);
    case types.GET_PROJECT_PROFITS_BY_YEAR:
      return state
        .set('isInfoAboutProjectProfitsLoading', true)
        .set('infoAboutProjectProfitsByYear', []);
    case types.GET_MANAGER_PROFILES:
      return state.set('isManagerProfilesLoading', true);

    case types.DELETE_REVENUE_FROM_PROJECT_PROFIT_SUCCESS:
      return state.set('isProjectProfitsLoading', false);
    case types.GET_PROJECT_PROFITS_BY_YEAR_SUCCESS:
      return state
        .set('isInfoAboutProjectProfitsLoading', false)
        .set('infoAboutProjectProfitsByYear', payload);
    case types.GET_PROJECT_PROFITS_SUCCESS:
      return state
        .set('isProjectProfitsLoading', false)
        .set('projectProfits', payload.data)
        .set('projectProfitsFilters', payload.data_for_filters)
        .set('totals', payload.totals);
    case types.UPDATE_PROJECT_PROFITS_SUCCESS:
      return state.set('isProjectProfitsLoading', false);
    case types.GET_MANAGER_PROFILES_SUCCESS:
      return state
        .set('isManagerProfilesLoading', false)
        .set('managerProfiles', payload);

    case types.GET_PROJECT_PROFITS_FAILED:
    case types.UPDATE_PROJECT_PROFITS_FAILED:
    case types.DELETE_REVENUE_FROM_PROJECT_PROFIT_FAILED:
      return state.set('isProjectProfitsLoading', false);
    case types.GET_PROJECT_PROFITS_BY_YEAR_FAILED:
      return state.set('isInfoAboutProjectProfitsLoading', false);
    case types.GET_MANAGER_PROFILES_FAILED:
      return state.set('isManagerProfilesLoading', false);
    default:
      return state;
  }
}
