export const ROLES_COMMON = {
  id_title: 'ID',
  id: 'id',
  name_title: 'Name',
  name: 'name',
  role_type: 'role_type',
  role_type_title: 'Type',
  permission_ids: 'permission_ids',
  permission_ids_title: 'Permissions',
};
