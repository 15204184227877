import { actionFactory } from 'redux/factories';
import { types } from './types';

export const getDeals = actionFactory(types.GET_DEALS);
export const updateDeal = actionFactory(types.UPDATE_DEAL);
export const getRmDealAllocations = actionFactory(
  types.GET_RM_DEAL_ALLOCATIONS,
);
export const clearRmDealAllocations = actionFactory(
  types.CLEAR_RM_DEAL_ALLOCATIONS,
);
