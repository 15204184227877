import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DealForm from 'components/ClientCommon/ClientForms/DealForm';
import DatePicker from 'components/Common/DatePicker';
import ClientAllocationTable from 'components/ClientCommon/ClientModal/ClientAllocationTable/';
import { DATE_FORMAT } from 'utils/timeConstants';
import { getPopupContainerHandler } from 'utils/helpers';

import { findProject } from './utils';
import styles from './styles.scss';

const ClientSmartProject = ({
  dealFormValues,
  form,
  jiraRoles,
  filteredDeals,
  project = null,
  allocations,
  currencies,
  allocationsData,
  setAllocationsData,
  isFinishEditDeal = false,
  setDeletedAllocations = () => {},
  dealStartsAt,
  onChangeDealStartsAt,
  setIsDisableNextButton,
  onChangeFormValue = () => {},
}) => {
  const [data, setData] = useState([]);

  const currentProject = useMemo(
    () => findProject({ filteredDeals, project }),
    [filteredDeals, project],
  );

  useEffect(() => {
    if (currentProject && !isFinishEditDeal) {
      setData(allocations[currentProject.id]);
    } else {
      setData(allocationsData);
    }
  }, [allocations, currentProject]);

  return (
    <div>
      <div className={styles.dealInfoContainer}>
        <div className={styles.dealFormContainer}>
          <div className={styles.dealPeriodContainer}>
            <div>
              <span style={{ color: 'red' }}>*</span> Starts at
            </div>
            <DatePicker
              format={DATE_FORMAT}
              value={dealStartsAt}
              onChange={onChangeDealStartsAt}
              style={{ width: '100%', marginTop: '7px' }}
              getPopupContainer={getPopupContainerHandler}
            />
          </div>
          <DealForm
            isDisplayAddButton={false}
            fieldValues={dealFormValues}
            currentProject={currentProject}
            form={form}
            currencies={currencies}
            onChangeFormValue={onChangeFormValue}
          />
        </div>
        <ClientAllocationTable
          dataSource={data}
          jiraRoles={jiraRoles}
          currencies={currencies}
          allocationsData={allocationsData}
          setAllocationsData={setAllocationsData}
          isFinishEditDeal={isFinishEditDeal}
          setDeletedAllocations={setDeletedAllocations}
          setIsDisableNextButton={setIsDisableNextButton}
        />
      </div>
    </div>
  );
};

ClientSmartProject.propTypes = {
  dealFormValues: PropTypes.object,
  form: PropTypes.object,
  jiraRoles: PropTypes.array,
  filteredDeals: PropTypes.array,
  allocations: PropTypes.object,
  currencies: PropTypes.array,
  allocationsData: PropTypes.array,
  setAllocationsData: PropTypes.func,
  onChangeDealStartsAt: PropTypes.func,
  setIsDisableNextButton: PropTypes.func,
  isFinishEditDeal: PropTypes.bool,
  setDeletedAllocations: PropTypes.func,
  onChangeFormValue: PropTypes.func,
  project: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dealStartsAt: PropTypes.object,
};

export default ClientSmartProject;
