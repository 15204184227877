exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".approveBtn-2ee18{margin-right:6px}.pageExtra-1991d{display:flex;align-items:start}.attachment-1c535{width:150px;overflow:hidden;color:#1677ff;font-size:10px;cursor:pointer}.searchContainer-3af14{padding:10px 8px}.searchContainer-3af14 .searchInput-0d560{width:188px;margin-bottom:8px;display:block}.searchContainer-3af14 .searchButton-74f9a{width:90px;margin-right:8px}.searchContainer-3af14 .resetButton-c0c1f{width:90px}", ""]);

// Exports
exports.locals = {
	"approveBtn": "approveBtn-2ee18",
	"pageExtra": "pageExtra-1991d",
	"attachment": "attachment-1c535",
	"searchContainer": "searchContainer-3af14",
	"searchInput": "searchInput-0d560",
	"searchButton": "searchButton-74f9a",
	"resetButton": "resetButton-c0c1f"
};