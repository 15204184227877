import React from 'react';
import { Typography } from 'antd';

import { formatNumberWithCommas } from 'utils/helpers';

import styles from './styles.scss';

const { Text } = Typography;

const ClientHeaderTotal = ({ data, title }) =>
  !!data.length && (
    <div className={styles.headerTotal}>
      <Text type="secondary">{title}:</Text>
      <div className={styles.values}>
        {data.map(item => (
          <div
            className={styles.totalItem}
            key={item.id}
          >{`${formatNumberWithCommas(item.amount)} ${item.currency}`}</div>
        ))}
      </div>
    </div>
  );

export default ClientHeaderTotal;
