import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Switch } from 'antd';

import { formTrimValidator } from 'utils/formHelper';
import Input from 'components/Common/Input';
import Select, { Option } from 'components/Common/Select';
import TextArea from 'components/Common/TextArea';

import { COUNTRY_LIST } from './constants';
import styles from './styles.scss';

const { Item } = Form;

const OrganizationForm = ({ onFinish, form }) => {
  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const getPopupContainerHandler = useCallback(
    event => event.parentElement || event,
    [],
  );

  return (
    <Form
      form={form}
      className={styles.form}
      layout="vertical"
      id="organizationForm"
      onFinish={onFinish}
    >
      <Item
        label="Title"
        key="title"
        name="title"
        rules={[
          {
            required: true,
            message: 'This field is  required.',
            validator: formTrimValidator,
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label="Tax ID"
        key="tax_id"
        name="tax_id"
        initialValue=""
        rules={[
          {
            message: 'This field is  required.',
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label="Friendly name"
        key="friendly_name"
        name="friendly_name"
        initialValue=""
        rules={[
          {
            required: true,
            message: 'This field is  required.',
            validator: formTrimValidator,
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label="Country"
        key="country_code"
        name="country_code"
        rules={[
          {
            required: true,
            message: 'This field is  required.',
          },
        ]}
      >
        <Select
          getPopupContainer={getPopupContainerHandler}
          filterOption={filterOption}
          showSearch
        >
          {COUNTRY_LIST.map(country => (
            <Option value={country.code} key={country.code}>
              {country.name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        key="is_yellow_group"
        name="is_yellow_group"
        valuePropName="checked"
        label="Yellow group"
        initialValue={false}
      >
        <Switch />
      </Item>
      <Item
        label="Details"
        key="details"
        name="details"
        initialValue=""
        rules={[
          {
            message: 'This field is  required.',
          },
        ]}
      >
        <TextArea rows={6} />
      </Item>
    </Form>
  );
};

OrganizationForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  form: PropTypes.object,
};

export default OrganizationForm;
