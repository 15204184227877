import { actionFactory } from 'redux/factories';
import { types } from './types';

export const getDashboardEffectiveness = actionFactory(
  types.GET_DASHBOARD_EFFECTIVENESS,
);

export const getDashboardEmployees = actionFactory(
  types.GET_DASHBOARD_EMPLOYEES,
);

export const getDashboardEmployeeData = actionFactory(
  types.GET_DASHBOARD_EMPLOYEE_DATA,
);

export const getDashboardOverview = actionFactory(types.GET_DASHBOARD_OVERVIEW);

export const getDashboardSalaryChart = actionFactory(
  types.GET_DASHBOARD_SALARY_CHART,
);

export const getDashboardSalaryTable = actionFactory(
  types.GET_DASHBOARD_SALARY_TABLE,
);

export const getDashboardSubcontractorsTable = actionFactory(
  types.GET_DASHBOARD_SUBCONTRACTORS_TABLE,
);

export const getDashboardSubcontractors = actionFactory(
  types.GET_DASHBOARD_SUBCONTRACTORS,
);
