import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import styles from './styles.scss';

const CommentEditor = ({ comment, setComment }) => (
  <Editor
    editorState={comment}
    onEditorStateChange={value => setComment(value)}
    editorClassName={styles.commentEditor}
    toolbar={{
      options: ['inline'],
      inline: {
        options: ['bold', 'italic', 'underline'],
      },
    }}
    toolbarStyle={{ border: 'none', borderRadius: 8 }}
  />
);

CommentEditor.propTypes = {
  comment: PropTypes.object.isRequired,
  setComment: PropTypes.func.isRequired,
};

export default CommentEditor;
