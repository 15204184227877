import { ROLE_TYPES } from 'utils/permissions';

export const ORGANIZATIONS_TABLE_COMMON = {
  id_title: 'ID',
  id: 'id',
  friendly_name_title: 'Friendly name',
  friendly_name: 'friendly_name',
  name_title: 'Title',
  title: 'title',
  tax_id_title: 'Tax ID',
  tax_id: 'tax_id',
  country_code_title: 'Country',
  country_code: 'country_code',
  is_yellow_group_title: 'Yellow group',
  is_yellow_group: 'is_yellow_group',
  created_at_title: 'Created at',
  created_at: 'created_at',
  updated_at_title: 'Updated at',
  updated_at: 'updated_at',
};

export const ORGANIZATIONS_PERMISSIONS = {
  organizations: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
  ],
  deleteOrganization: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  addAccount: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist, ROLE_TYPES.bdm],
  updateAccount: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
  ],
  deleteAccount: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.bdm,
  ],
};
