import { takeLatest, call, put, all } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';

import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';
import { types } from './types';

function* getProjectProfits({ payload }) {
  try {
    const { data } = yield call(API.getProjectProfits, payload);
    yield put({ type: types.GET_PROJECT_PROFITS_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_PROJECT_PROFITS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* calculateProjectProfits({ payload }) {
  try {
    yield call(API.calculateProjectProfits, payload);
    yield put({ type: types.CALCULATE_PROJECT_PROFITS_SUCCESS });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CALCULATE_PROJECT_PROFITS_FAILED,
      SAGA_MESSAGES.CALCULATE_PROJECTS_FAILES,
    );
  }
}

function* updateProjectProfits({ payload }) {
  try {
    yield call(API.updateProjectProfits, payload);
    yield put({ type: types.UPDATE_PROJECT_PROFITS_SUCCESS });
    yield call(getProjectProfits, { payload });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_PROJECT_PROFITS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getInfoAboutProjectProfitsByYear({ payload }) {
  try {
    const {
      0: { data: currentYear },
      1: { data: previousYear },
    } = yield all([
      yield call(API.getProjectProfitsByYear, payload),
      yield call(API.getProjectProfitsByYear, {
        ...payload,
        year: Number(payload.year) - 1,
      }),
    ]);

    yield put({
      type: types.GET_PROJECT_PROFITS_BY_YEAR_SUCCESS,
      payload: [...currentYear, ...previousYear],
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_PROJECT_PROFITS_BY_YEAR_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* deleteRevenueFromProject({ payload }) {
  try {
    yield call(API.deleteRevenueProjectProfits, payload);
    yield call(getProjectProfits, { payload });
    yield put({
      type: types.DELETE_REVENUE_FROM_PROJECT_PROFIT_SUCCESS,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_REVENUE_FROM_PROJECT_PROFIT_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* addProjectExtraPayment({
  payload: { employee_id, extra_payment, filters, period, sort },
}) {
  try {
    yield call(API.addExtraPayment, {
      params: { employee_id, ...extra_payment },
    });
    yield call(getProjectProfits, { payload: { period, filters, sort } });
    yield put({ type: types.ADD_PROJECT_EXTRA_PAYMENT_SUCCESS });
    message.success(SAGA_MESSAGES.ADD_EXTRA_PAYMENT_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.ADD_PROJECT_EXTRA_PAYMENT_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getManagerProfiles({ payload }) {
  try {
    const { data } = yield call(API.getManagerProfiles, {
      ...payload,
      expand: ['summary_employee'],
    });

    yield put({ type: types.GET_MANAGER_PROFILES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_MANAGER_PROFILES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_PROJECT_PROFITS, getProjectProfits);
  yield takeLatest(types.CALCULATE_PROJECT_PROFITS, calculateProjectProfits);
  yield takeLatest(types.UPDATE_PROJECT_PROFITS, updateProjectProfits);
  yield takeLatest(
    types.GET_PROJECT_PROFITS_BY_YEAR,
    getInfoAboutProjectProfitsByYear,
  );
  yield takeLatest(
    types.DELETE_REVENUE_FROM_PROJECT_PROFIT,
    deleteRevenueFromProject,
  );
  yield takeLatest(types.ADD_PROJECT_EXTRA_PAYMENT, addProjectExtraPayment);
  yield takeLatest(types.GET_MANAGER_PROFILES, getManagerProfiles);
}
