export const DASHBOARD_SALARY_INDEX = {
  full_name_title: 'Full name',
  full_name: 'full_name',
  role_title: 'Role',
  role: 'role',
  rm_title: 'RM',
  rm: 'rm',
  salary_title: 'Salary, USD',
  salary: 'salary',
  extra_payments_title: 'Extra payments, USD',
  extra_payments: 'extra_payments',
  total_title: 'Total, USD',
  total: 'total',
  organization_title: 'Organization',
  organization: 'organization',
};
