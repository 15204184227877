import React from 'react';
import PropTypes from 'prop-types';
import { Select, Avatar } from 'antd';
import get from 'lodash/get';

import styles from './styles.scss';

const { Option } = Select;

const BuddySelect = ({ currentBuddyIds, onChangeBuddy, employees }) => (
  <Select
    mode="multiple"
    showSearch
    className={styles.buddySelect}
    placeholder="Select a person"
    optionFilterProp="children"
    defaultValue={currentBuddyIds}
    onChange={onChangeBuddy}
  >
    {employees.map(item => (
      <Option key={get(item, 'id')} value={get(item, 'id')}>
        <Avatar
          className={styles.avatar}
          shape="square"
          size={20}
          src={get(item, ['jira_user', 'avatarUrls', '32x32'])}
        />
        {item.name_en || item.name_ru}
        <span className={styles.label}>({get(item, ['jira_user', 'id'])})</span>
      </Option>
    ))}
  </Select>
);
BuddySelect.propTypes = {
  currentBuddyIds: PropTypes.array.isRequired,
  onChangeBuddy: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
};

export default BuddySelect;
