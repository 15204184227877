import { CLIENT_PERMISSIONS } from 'containers/ClientPage/constants';
import { ROLE_TYPES } from 'utils/permissions';

export const MONTH_FORMAT_DAY_DIGIT = 'YYYY-MM-01';
export const LOAD_CLIENTS_PARAMS = { page: 1, size: 10000 };

export const STATUS_INFO = {
  DRAFT: { title: 'DRAFT', color: '' },
  APPROVED: { title: 'APPROVED', color: 'green' },
  DECLINED: { title: 'DECLINED', color: 'red' },
};

export const TIME_FIELDS = [
  'issue_date',
  'sent_at',
  'period_from',
  'period_to',
  'reviewed_at',
  'issue_date',
];

export const INVOICE_STATUSES = {
  ON_ALLOCATIONS: {
    key: 'ON_ALLOCATIONS',
    scopes: CLIENT_PERMISSIONS.statusAllocations,
  },
  ON_WORKLOGS: {
    key: 'ON_WORKLOGS',
    scopes: CLIENT_PERMISSIONS.statusWorklogs,
  },
  WORKLOGS_READY: {
    key: 'WORKLOGS_READY',
    scopes: CLIENT_PERMISSIONS.statusWorklogsReady,
  },
  INVOICE_READY: {
    key: 'APPROVED',
    scopes: CLIENT_PERMISSIONS.statusApprovedDeclined,
  },
  APPROVED: {
    key: 'APPROVED',
    scopes: CLIENT_PERMISSIONS.statusApprovedDeclined,
  },
  DECLINED: {
    key: 'DECLINED',
    scopes: CLIENT_PERMISSIONS.statusApprovedDeclined,
  },
  CLIENT_REJECTED: {
    key: 'CLIENT_REJECTED',
    scopes: CLIENT_PERMISSIONS.statusClientRejected,
  },
  SENT: {
    key: 'SENT',
    scopes: CLIENT_PERMISSIONS.statusSent,
  },
  PAID: {
    key: 'PAID',
    scopes: CLIENT_PERMISSIONS.statusPaid,
  },
};

export const CONFIRM_BLOCKING_STATUSES = [
  'APPROVED',
  'CLIENT_REJECTED',
  'SENT',
  'PAID',
];

export const DECLINE_BLOCKING_STATUSES = [
  'CLIENT_REJECTED',
  'SENT',
  'PAID',
  'DECLINED',
];

export const FILTER_FIELDS = ['status'];

export const DISABLE_COLOR = '#bfbfbf';

export const DEFAULT_INVOICE_PAGINATION = {
  page: 1,
  size: 50,
};

export const INVOICE_CONFIRM_ACTIONS = {
  CONFIRM_APPROVE_TITLE: 'Approve invoice',
  CONFIRM_REJECT_TITLE: 'Reject invoice ',
  OK: 'OK',
  CANCEL: 'Cancel',
  CONFIRM_APPROVE: 'Are you sure you want to approve this invoice?',
  CONFIRM_DECLINE: 'Are you sure you want to reject this invoice?',
};

export const INVOICES_INDEX = {
  client_id_title: 'Client',
  client_id: 'client_id',
  client_title: 'client_title',
  number_title: 'No.',
  number: 'number',
  contractor_title: 'Contractor',
  contractor_id: 'contractor_id',
  contractor_name: 'contractor_name',
  author_title: 'Author',
  owner_id: 'owner_id',
  author_name: 'author_name',
  reviewer_title: 'Reviewer',
  reviewer_id: 'reviewer_id',
  reviewer_name: 'reviewer_name',
  amount_title: 'Amount',
  amount: 'amount',
  attachments_title: 'Attachments',
  attachments: 'attachments',
  status_title: 'Status',
  status: 'status',
  sent_title: 'Sent',
  sent_at: 'sent_at',
  issue_title: 'Issued',
  issue_date: 'issue_date',
  reviewed_title: 'Reviewed',
  reviewed_at: 'reviewed_at',
  created_title: 'Created',
  created_at: 'created_at',
  updated_title: 'Updated',
  updated_at: 'updated_at',
};

export const INVOICES_PERMISSIONS = {
  attachments: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.dm,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
  ],
  updateStatus: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.dm,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
  ],
  updateSentAt: [
    ROLE_TYPES.ceo,
    ROLE_TYPES.finance_specialist,
    ROLE_TYPES.dm,
    ROLE_TYPES.bdm,
    ROLE_TYPES.pm,
  ],
  declineApprove: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
};
