import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { STATUS_TAG_COLOR } from 'containers/LeadPage/constants';
import styles from './styles.scss';

const { Text } = Typography;

const LeadStatus = ({ event }) => {
  const { audited_changes } = event;
  const isPreviosOwnerExisted = Boolean(audited_changes.status[0]);
  const [previousStatus, currentStatus] = audited_changes.status;

  return (
    <div>
      <Text strong>Status updated</Text>
      {isPreviosOwnerExisted ? (
        <div className={styles.previousStatus}>
          <Tag color={STATUS_TAG_COLOR[previousStatus]}>{previousStatus}</Tag>
          <ArrowRightOutlined style={{ margin: '0px 10px 0px 5px' }} />
          <Tag color={STATUS_TAG_COLOR[currentStatus]}>{currentStatus}</Tag>
        </div>
      ) : (
        <Tag color={STATUS_TAG_COLOR[currentStatus]}>{currentStatus}</Tag>
      )}
    </div>
  );
};

LeadStatus.propTypes = {
  event: PropTypes.object,
};

export default LeadStatus;
