import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isLeadsLoading: false,
  leads: [],
  totalLeads: 1,
  leadFilters: {},

  leadData: {},
  leadActivities: [],
  leadAttachments: [],
  leadNotes: [],
  leadAudit: [],
  leadGeo: null,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_LEADS:
    case types.GET_LEAD_DATA:
    case types.GET_LEAD_ACTIVITIES:
    case types.GET_LEAD_ATTACHMENTS:
    case types.UPDATE_LEAD_ACTIVITIES:
    case types.SAVE_LEAD_NOTE:
    case types.GET_LEAD_AUDIT:
    case types.UPDATE_LEAD_NOTE:
    case types.UPDATE_LEAD_STATUS:
    case types.GET_LEAD_GEO:
    case types.GET_LEAD_NOTES:
    case types.UPDATE_LEAD:
      return state.set('isLeadsLoading', true);

    case types.GET_LEADS_DATA_SUCCESS:
      return state
        .set('leads', payload.data)
        .set('totalLeads', payload.total_data)
        .set('leadFilters', payload.data_for_filters)
        .set('isLeadsLoading', false);
    case types.GET_LEAD_DATA_SUCCESS:
      return state.set('leadData', payload.data).set('isLeadsLoading', false);
    case types.GET_LEAD_NOTES_SUCCESS:
      return state.set('leadNotes', payload).set('isLeadsLoading', false);
    case types.GET_LEAD_ACTIVITIES_SUCCESS:
      return state
        .set('leadActivities', payload.data)
        .set('isLeadsLoading', false);
    case types.GET_LEAD_ATTACHMENTS_SUCCESS:
      return state
        .set('leadAttachments', payload.data)
        .set('isLeadsLoading', false);
    case types.GET_LEAD_GEO_SUCCESS:
      return state.set('leadGeo', payload).set('isLeadsLoading', false);

    case types.GET_LEAD_AUDIT_SUCCESS:
      return state.set('isLeadsLoading', false).set('leadAudit', payload);

    case types.GET_LEADS_DATA_FAILED:
    case types.GET_LEADS_FILTERS_FAILED:
    case types.GET_LEAD_DATA_FAILED:
    case types.GET_LEAD_ACTIVITIES_FAILED:
    case types.GET_LEAD_ATTACHMENTS_FAILED:
    case types.UPDATE_LEAD_ACTIVITIES_FAILED:
    case types.UPDATE_LEAD_ACTIVITIES_SUCCESS:
    case types.UPDATE_LEAD_STATUS_SUCCESS:
    case types.SAVE_LEAD_NOTE_SUCCESS:
    case types.SAVE_LEAD_NOTE_FAILED:
    case types.GET_LEAD_AUDIT_FAILED:
    case types.UPDATE_LEAD_NOTE_FAILED:
    case types.UPDATE_LEAD_STATUS_FAILED:
    case types.GET_LEAD_GEO_FAILED:
    case types.GET_LEAD_NOTES_FAILED:
    case types.UPDATE_LEAD_SUCCESS:
    case types.UPDATE_LEAD_FAILED:
      return state.set('isLeadsLoading', false);
    default:
      return state;
  }
}
