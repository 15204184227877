import { takeLatest, call, put } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';

import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';
import { types } from './types';

function* getPositions() {
  try {
    const { data } = yield call(API.getPositions);
    yield put({
      type: types.GET_POSITIONS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_POSITIONS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* editPosition({ payload: { id, ...payload } }) {
  try {
    yield call(API.editPosition, id, payload);
    yield call(getPositions);
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.EDIT_POSITION_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* deletePosition({ payload: { id } }) {
  try {
    yield call(API.deletePosition, id);
    yield call(getPositions);
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_POSITION_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* addPosition({ payload }) {
  try {
    const { data } = yield call(API.addPosition, payload);
    yield put({
      type: types.ADD_POSITION_SUCCESS,
      payload: data,
    });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.ADD_POSITION_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_POSITIONS, getPositions);
  yield takeLatest(types.EDIT_POSITION, editPosition);
  yield takeLatest(types.DELETE_POSITION, deletePosition);
  yield takeLatest(types.ADD_POSITION, addPosition);
}
