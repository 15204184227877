import {
  findMonthItemData,
  tooltipFormatter,
} from 'containers/DashboardSubcontractors/utils';
import { getMonthNameWithYear, getArrayOfMonth } from 'utils/helpers';

export const getOverallData = (monthData, period) => {
  const arrayOfMonth = getArrayOfMonth(period);

  return (
    monthData &&
    arrayOfMonth.reduce((acc, month) => {
      const monthItemData = findMonthItemData(monthData, month);

      const revenue = {
        type: 'Revenue',
        value: monthItemData ? +monthItemData[1].revenue : 0,
        month,
      };
      const cost = {
        type: 'Cost',
        value: monthItemData ? +monthItemData[1].cost : 0,
        month,
      };
      const profit = {
        type: 'Profit',
        value: monthItemData ? +monthItemData[1].profit : monthItemData,
        month: month,
      };

      return [...acc, revenue, cost, profit];
    }, [])
  );
};

export const getOverallConfig = data => ({
  data,
  xField: 'month',
  yField: 'value',
  seriesField: 'type',
  xAxis: {
    type: 'time',
    label: {
      formatter: getMonthNameWithYear,
    },
  },
  tooltip: {
    formatter: tooltipFormatter,
  },
  legend: {
    maxRow: 3,
    flipPage: false,
  },
});
