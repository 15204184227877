import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { MONTH_FORMAT_DAY_DIGIT } from 'containers/InvoicesPage/constants';
import { firebasePath } from 'containers/ProjectProfits/constants';
import ClientConcentration from 'components/DashboardCommon/ClientConcentration';
import PageHeader from 'components/Common/PageHeader';
import ProfitAndLoss from 'components/DashboardCommon/ProfitAndLoss';
import DashboardDropdown from 'components/DashboardCommon/DashboardDropdown';
import DashboardDropdownOverlay from 'components/DashboardCommon/DashboardDropdownOverlay';
import { Loader } from 'components/Common';
import RangePicker from 'components/Common/RangePicker';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getMonthsRange,
} from 'utils/helpers';
import { useRoleContext } from 'utils/hooks';
import { hasRights } from 'utils/permissions';
import { DATE_FORMAT, MONTH_FORMAT } from 'utils/timeConstants';
import { getDashboardOverview } from 'redux/Dashboard/actions';
import { dashboardSelector } from 'redux/selectors';

import { onReset, onValueChange, onValueSelect } from './utils';
import {
  PROJECT_CATEGORIES,
  DEFAULT_FILTER_STATE,
  DO_PERMISSIONS,
} from './constants';
import firebase from '../../firebase';

import styles from './styles.scss';

let unsubscribe = null;

async function getFirebaseData(month, setCalculateProgress) {
  const db = firebase.firestore();

  const query = dayjs(month)
    .startOf('month')
    .format(DATE_FORMAT);

  unsubscribe = db
    .collection(firebasePath)
    .doc(query)
    .onSnapshot(doc => {
      setCalculateProgress(doc.data());
    });
}

const DashboardOverviewPage = () => {
  const [period, setPeriod] = useState(getMonthsRange(new Date(), 12));
  const [category, setCategory] = useState({
    ...DEFAULT_FILTER_STATE,
    applied: [PROJECT_CATEGORIES.commercial],
  });
  const [pm, setPm] = useState(DEFAULT_FILTER_STATE);
  const [bdm, setBdm] = useState(DEFAULT_FILTER_STATE);

  const [isCategoryVisible, setIsCategoryVisible] = useState(false);
  const [isPmVisible, setIsPmVisible] = useState(false);
  const [isBdmVisible, setIsBdmVisible] = useState(false);

  const [calculateProgress, setCalculateProgress] = useState(null);

  const role = useRoleContext();

  const dispatch = useDispatch();

  const { dashboardOverview, isDashboardLoading } = useSelector(
    dashboardSelector,
  );

  const { data_for_filters } = dashboardOverview;

  const dashboardDefaultRequest = {
    from: dayjs(period[0]).format(MONTH_FORMAT_DAY_DIGIT),
    to: dayjs(period[1]).format(MONTH_FORMAT_DAY_DIGIT),
    category: category.applied,
    pm_login: pm.applied,
    sm_login: bdm.applied,
  };

  useEffect(() => {
    const dashboardReqParams = {
      ...dashboardDefaultRequest,
    };

    if (!isEmpty(category)) {
      dashboardReqParams.category = [...category.applied];
    }

    setPm({
      selected: [],
      applied: [],
    });
    setBdm({
      selected: [],
      applied: [],
    });

    dispatch(getDashboardOverview(dashboardReqParams));

    unsubscribe && unsubscribe();
    getFirebaseData(period[1], setCalculateProgress);

    return () => unsubscribe();
  }, [period]);

  const onPeriodChange = period => {
    setPeriod([getFirstDayOfMonth(period[0]), getLastDayOfMonth(period[1])]);
  };

  const onCategoryChange = value =>
    onValueChange({
      value,
      values: category,
      setValues: setCategory,
    });

  const onPMChange = value =>
    onValueChange({
      value,
      values: pm,
      setValues: setPm,
    });

  const onBDMChange = value =>
    onValueChange({
      value,
      values: bdm,
      setValues: setBdm,
    });

  const onCategoryReset = () =>
    onReset({
      getDashboard: getDashboardOverview,
      dashboardDefaultRequest,
      setValue: setCategory,
      field: 'category',
      dispatch,
    });

  const onPMReset = () =>
    onReset({
      getDashboard: getDashboardOverview,
      dashboardDefaultRequest,
      setValue: setPm,
      field: 'pm_login',
      dispatch,
    });

  const onBDMReset = () =>
    onReset({
      getDashboard: getDashboardOverview,
      dashboardDefaultRequest,
      setValue: setBdm,
      field: 'sm_login',
      dispatch,
    });

  const onCategoryApply = () =>
    onValueSelect({
      getDashboard: getDashboardOverview,
      dashboardDefaultRequest,
      dispatch,
      values: category,
      setValues: setCategory,
      field: 'category',
      setVisible: setIsCategoryVisible,
    });

  const onPMApply = () =>
    onValueSelect({
      getDashboard: getDashboardOverview,
      dashboardDefaultRequest,
      dispatch,
      values: pm,
      setValues: setPm,
      field: 'pm_login',
      setVisible: setIsPmVisible,
    });

  const onBDMApply = () =>
    onValueSelect({
      getDashboard: getDashboardOverview,
      dashboardDefaultRequest,
      dispatch,
      values: bdm,
      setValues: setBdm,
      field: 'sm_login',
      setVisible: setIsBdmVisible,
    });

  const categoryDropdownOverlay = () => (
    <DashboardDropdownOverlay
      values={category}
      valuesList={Object.values(PROJECT_CATEGORIES)}
      onApply={onCategoryApply}
      onReset={onCategoryReset}
      onValueSelect={onCategoryChange}
    />
  );

  const pmDropdownOverlay = () => (
    <DashboardDropdownOverlay
      values={pm}
      valuesList={data_for_filters?.project_managers || []}
      onApply={onPMApply}
      onReset={onPMReset}
      onValueSelect={onPMChange}
    />
  );
  const bdmDropdownOverlay = () => (
    <DashboardDropdownOverlay
      values={bdm}
      valuesList={data_for_filters?.sales_managers || []}
      onApply={onBDMApply}
      onReset={onBDMReset}
      onValueSelect={onBDMChange}
    />
  );

  return (
    <div>
      <PageHeader
        title="Dashboard overview"
        extra={
          <div className={styles.dashboardHeader}>
            <RangePicker
              picker="month"
              value={period}
              format={MONTH_FORMAT}
              onChange={onPeriodChange}
              className={styles.rangePicker}
            />
            {hasRights(role, DO_PERMISSIONS) && (
              <>
                <DashboardDropdown
                  overlay={categoryDropdownOverlay}
                  values={category}
                  setValues={setCategory}
                  title="Project category"
                  visible={isCategoryVisible}
                  setVisible={setIsCategoryVisible}
                />
                <DashboardDropdown
                  overlay={pmDropdownOverlay}
                  values={pm}
                  setValues={setPm}
                  title="PM"
                  visible={isPmVisible}
                  setVisible={setIsPmVisible}
                />
                <DashboardDropdown
                  overlay={bdmDropdownOverlay}
                  values={bdm}
                  setValues={setBdm}
                  title="BDM"
                  visible={isBdmVisible}
                  setVisible={setIsBdmVisible}
                />
              </>
            )}
          </div>
        }
      />
      <div className={styles.container}>
        <Loader loading={isDashboardLoading} isDynamicPosition>
          <ProfitAndLoss
            dashboardData={dashboardOverview}
            period={period}
            calculateProgress={calculateProgress}
          />
          <ClientConcentration
            dashboardData={dashboardOverview}
            period={period}
          />
        </Loader>
      </div>
    </div>
  );
};

export default DashboardOverviewPage;
