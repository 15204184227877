export const types = {
  GET_INVOICE_TYPES: 'invoiceTypes/GET_INVOICE_TYPES',
  GET_INVOICE_TYPES_SUCCESS: 'invoiceTypes/GET_INVOICE_TYPES_SUCCESS',
  GET_INVOICE_TYPES_FAILED: 'invoiceTypes/GET_INVOICE_TYPES_FAILED',

  CREATE_INVOICE_TYPE: 'invoiceTypes/CREATE_INVOICE_TYPE',
  CREATE_INVOICE_TYPE_SUCCESS: 'invoiceTypes/CREATE_INVOICE_TYPE_SUCCESS',
  CREATE_INVOICE_TYPE_FAILED: 'invoiceTypes/CREATE_INVOICE_TYPE_FAILED',

  UPDATE_INVOICE_TYPE: 'invoiceTypes/UPDATE_INVOICE_TYPE',
  UPDATE_INVOICE_TYPE_SUCCESS: 'invoiceTypes/UPDATE_INVOICE_TYPE_SUCCESS',
  UPDATE_INVOICE_TYPE_FAILED: 'invoiceTypes/UPDATE_INVOICE_TYPE_FAILED',

  DELETE_INVOICE_TYPE: 'invoiceTypes/DELETE_INVOICE_TYPE',
  DELETE_INVOICE_TYPE_SUCCESS: 'invoiceTypes/DELETE_INVOICE_TYPE_SUCCESS',
  DELETE_INVOICE_TYPE_FAILED: 'invoiceTypes/DELETE_INVOICE_TYPE_FAILED',
};

export const INVOICE_TYPES_MESSAGES = {
  CREATE_INVOICE_TYPE_SUCCESS: 'Invoice type created successfully',
  CREATE_INVOICE_TYPE_FAILED: 'Failed to create invoice type',
  UPDATE_INVOICE_TYPE_SUCCESS: 'Invoice type updated successfully',
  UPDATE_INVOICE_TYPE_FAILED: 'Failed to update invoice type',
  DELETE_INVOICE_TYPE_SUCCESS: 'Invoice type deleted successfully',
  DELETE_INVOICE_TYPE_FAILED: 'Failed to delete invoice type',
};
