export const types = {
  GET_DEALS: 'deals/GET_DEALS',
  GET_DEALS_SUCCESS: 'deals/GET_DEALS_SUCCESS',
  GET_DEALS_FAILED: 'deals/GET_DEALS_FAILED',

  UPDATE_DEAL: 'deals/UPDATE_DEAL',
  UPDATE_DEAL_SUCCESS: 'deals/UPDATE_DEAL_SUCCESS',
  UPDATE_DEAL_FAILED: 'deals/UPDATE_DEAL_FAILED',

  GET_RM_DEAL_ALLOCATIONS: 'deals/GET_RM_DEAL_ALLOCATIONS',
  GET_RM_DEAL_ALLOCATIONS_SUCCESS: 'deals/GET_RM_DEAL_ALLOCATIONS_SUCCESS',
  GET_RM_DEAL_ALLOCATIONS_FAILED: 'deals/GET_RM_DEAL_ALLOCATIONS_FAILED',

  CLEAR_RM_DEAL_ALLOCATIONS: 'deals/CLEAR_RM_DEAL_ALLOCATIONS',
};
