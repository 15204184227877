import { call, put, select, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import { WITH_EMPLOYEES } from 'containers/SummaryPage/constants';
import API from 'utils/api';
import { sagaErrorHandler } from 'utils/common';
import { REDUX_DATA_HELPERS } from 'utils/helpers';
import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { socialRequestsSelector } from 'redux/selectors';

import { hasRights } from 'utils/permissions';
import { REQUESTS_PERMISSIONS } from 'containers/SocialRequestsPage/constants';
import { types } from './types';

function* getSocialRequests({ payload }) {
  try {
    const { data } = yield call(API.getSocialRequests, payload);

    yield put({ type: types.GET_SOCIAL_REQUESTS_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_SOCIAL_REQUESTS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getSocialRequestsInfo({ payload }) {
  try {
    const { data } = yield call(API.getSocialRequestsInfo, payload);

    yield put({ type: types.GET_SOCIAL_REQUESTS_INFO_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_SOCIAL_REQUESTS_INFO_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getSocialRequestsStatuses() {
  try {
    const { data } = yield call(API.getSocialRequestStatuses);

    yield put({
      type: types.GET_SOCIAL_REQUEST_STATUSES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_SOCIAL_REQUEST_STATUSES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getSocialRequestsTypes() {
  try {
    const { data } = yield call(API.getSocialRequestTypes);

    yield put({ type: types.GET_SOCIAL_REQUEST_TYPES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_SOCIAL_REQUEST_TYPES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* createSocialRequest({ attachments, ...payload }) {
  try {
    const { data } = yield call(API.createSocialRequest, payload);

    const { socialRequests } = yield select(socialRequestsSelector);

    yield put({
      type: types.CREATE_SOCIAL_REQUEST_SUCCESS,
      payload: [...socialRequests, data],
    });

    message.success(SAGA_MESSAGES.CREATE_SOCIAL_REQUEST_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_SOCIAL_REQUEST_FAILED,
      SAGA_MESSAGES.CREATE_SOCIAL_REQUEST_FAILED,
    );
  }
}

function* updateSocialRequest({ payload: { id, ...params } }) {
  try {
    const { data } = yield call(API.updateSocialRequest, { id, params });

    const { socialRequests } = yield select(socialRequestsSelector);

    const updatedData = REDUX_DATA_HELPERS.updateData({
      data: socialRequests,
      editedItem: data,
      id,
    });

    yield put({
      type: types.UPDATE_SOCIAL_REQUEST_SUCCESS,
      payload: updatedData,
    });

    message.success(SAGA_MESSAGES.UPDATE_SOCIAL_REQUEST_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_SOCIAL_REQUEST_FAILED,
      SAGA_MESSAGES.UPDATE_SOCIAL_REQUEST_FAILED,
    );
  }
}

function* deleteSocialRequest({ payload: { id } }) {
  try {
    yield call(API.deleteSocialRequest, { id });

    const { socialRequests } = yield select(socialRequestsSelector);

    const updatedData = REDUX_DATA_HELPERS.removeItem({
      data: socialRequests,
      id,
    });

    yield put({
      type: types.DELETE_SOCIAL_REQUEST_SUCCESS,
      payload: updatedData,
    });

    message.success(SAGA_MESSAGES.DELETE_SOCIAL_REQUEST_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_SOCIAL_REQUEST_FAILED,
      SAGA_MESSAGES.DELETE_SOCIAL_REQUEST_FAILED,
    );
  }
}

function* approveSocialRequest({
  payload: { from, to, id, employee_id, role },
}) {
  try {
    const { data } = yield call(API.approveSocialRequest, {
      id,
      employee_id,
      expand: [WITH_EMPLOYEES],
    });

    const { socialRequests } = yield select(socialRequestsSelector);

    const updatedData = REDUX_DATA_HELPERS.updateData({
      data: socialRequests,
      editedItem: data,
      id,
    });

    if (hasRights(role, REQUESTS_PERMISSIONS.balance)) {
      yield call(getSocialRequestsInfo, { payload: { from, to } });
    }

    yield put({
      type: types.APPROVE_SOCIAL_REQUEST_SUCCESS,
      payload: updatedData,
    });

    message.success(SAGA_MESSAGES.APPROVE_SOCIAL_REQUEST_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.APPROVE_SOCIAL_REQUEST_FAILED,
      SAGA_MESSAGES.APPROVE_SOCIAL_REQUEST_FAILED,
    );
  }
}

function* declineSocialRequest({ payload: { from, to, id, role } }) {
  try {
    const { data } = yield call(API.declineSocialRequest, {
      id,
      expand: [WITH_EMPLOYEES],
    });

    const { socialRequests } = yield select(socialRequestsSelector);

    const updatedData = REDUX_DATA_HELPERS.updateData({
      data: socialRequests,
      editedItem: data,
      id,
    });

    if (hasRights(role, REQUESTS_PERMISSIONS.balance)) {
      yield call(getSocialRequestsInfo, { payload: { from, to } });
    }

    yield put({
      type: types.DECLINE_SOCIAL_REQUEST_SUCCESS,
      payload: updatedData,
    });

    message.success(SAGA_MESSAGES.DECLINE_SOCIAL_REQUEST_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DECLINE_SOCIAL_REQUEST_FAILED,
      SAGA_MESSAGES.DECLINE_SOCIAL_REQUEST_FAILED,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_SOCIAL_REQUESTS, getSocialRequests);
  yield takeLatest(types.GET_SOCIAL_REQUESTS_INFO, getSocialRequestsInfo);
  yield takeLatest(
    types.GET_SOCIAL_REQUEST_STATUSES,
    getSocialRequestsStatuses,
  );
  yield takeLatest(types.GET_SOCIAL_REQUEST_TYPES, getSocialRequestsTypes);
  yield takeLatest(types.CREATE_SOCIAL_REQUEST, createSocialRequest);
  yield takeLatest(types.UPDATE_SOCIAL_REQUEST, updateSocialRequest);
  yield takeLatest(types.DELETE_SOCIAL_REQUEST, deleteSocialRequest);
  yield takeLatest(types.APPROVE_SOCIAL_REQUEST, approveSocialRequest);
  yield takeLatest(types.DECLINE_SOCIAL_REQUEST, declineSocialRequest);
}
