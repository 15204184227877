import React, { memo } from 'react';
import { Alert, Button, Typography } from 'antd';
import PropTypes from 'prop-types';
import { getStartAndEndOfMonth } from 'containers/ClientPage/utils';
import dayjs from 'dayjs';

import styles from './styles.scss';

const { Text } = Typography;

const ClientInvoiceNotificationsItem = ({
  alert,
  current,
  addWorklogFromAlert,
}) => {
  const {
    logged,
    expected,
    message,
    type,
    roleName,
    periodFrom,
    periodTo,
    projectName,
    projectId,
    allocationId,
  } = alert;

  const addLogButton =
    logged < expected ? (
      <Button
        size="small"
        type="link"
        style={{ fontSize: 12 }}
        onClick={() =>
          addWorklogFromAlert({
            project_id: projectId,
            allocation_id: allocationId,
            spent_hours: expected - logged,
            date: dayjs(periodTo),
          })
        }
      >
        Add worklog
      </Button>
    ) : null;

  const getDateRangeBlock = () => {
    const [startDate, endDate] = getStartAndEndOfMonth(periodFrom);

    if (startDate === periodFrom && endDate === periodTo) {
      return null;
    }

    return (
      <span
        className={styles.dealPeriod}
      >{`${periodFrom} -> ${periodTo}`}</span>
    );
  };

  return (
    <Alert
      type={type}
      style={{ marginBottom: '5px' }}
      message={
        <div className={styles.notificationTitleContainer}>
          <span className={styles.projectName}>{projectName}</span>
        </div>
      }
      description={
        <div>
          {getDateRangeBlock()}
          <div className={styles.message}>{message}</div>
          <Text code className={styles.role}>
            {roleName}
          </Text>
          <div className={styles.notificationResult}>
            <Text type="secondary">Log: {logged.toFixed(2)}</Text>
            <Text type="secondary">Exp: {expected.toFixed(2)}</Text>
            {current === 2 ? addLogButton : null}
          </div>
        </div>
      }
    />
  );
};

ClientInvoiceNotificationsItem.propTypes = {
  alert: PropTypes.object.isRequired,
  current: PropTypes.number.isRequired,
  addWorklogFromAlert: PropTypes.func.isRequired,
};

export default memo(ClientInvoiceNotificationsItem);
