/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConfigProvider } from 'antd';
import history from 'utils/history';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import 'sanitize.css/sanitize.css';
import 'antd/dist/reset.css';

// Import root app
import App from 'containers/App/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./assets/images/favicon.ico';
import '!file-loader?name=[name].[ext]!./assets/images/logo.png';
import 'file-loader?name=.htaccess!./.htaccess';
/* eslint-enable import/no-unresolved, import/extensions */

import { ANTD_THEME } from 'utils/constants';
import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

// Create redux store with history
const initialState = {};
export const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const root = createRoot(MOUNT_NODE);

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(isBetween);

const render = messages => {
  root.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <ConfigProvider theme={ANTD_THEME}>
            <App />
          </ConfigProvider>
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App/App.jsx'], () => {
    root.unmount();
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: window.SERVER_DATA.SENTRY_DSN,
    // normalizeDepth: 5,
    debug: true,
    tracesSampleRate: 1.0,
    integrations: [new Integrations.BrowserTracing()],
    maxBreadcrumbs: 30,
  });

  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
