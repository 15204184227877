import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { Line } from '@ant-design/charts';
import { getDashboardData } from 'containers/DashboardEffectivenessPage/utils';
import { getAERTotalConfig } from './utils';
import DashboardAERTotal from './DashboardAERTotal';
import { AER_LABELS } from './constants';
import styles from './styles.scss';

const { Title } = Typography;

const DashboardAER = ({ dashboardData }) => {
  const { months_com, average_aer } = dashboardData;

  const aerData = useMemo(
    () =>
      getDashboardData({
        data: months_com,
        average: average_aer,
        currentLabel: AER_LABELS.CURRENT,
        averageLabel: AER_LABELS.AVG,
        value: 'aer',
      }),
    [months_com, average_aer],
  );

  const aerConfig = getAERTotalConfig(aerData);

  return (
    <div className={styles.dashboardAER}>
      <Title level={5}>Average effective rate</Title>
      <div className={styles.container}>
        {aerData && <Line {...aerConfig} height={400} />}
        <DashboardAERTotal dashboardData={dashboardData} />
      </div>
    </div>
  );
};

DashboardAER.propTypes = {
  dashboardData: PropTypes.shape({
    months_com: PropTypes.array,
    average_aer: PropTypes.string,
  }),
};

export default memo(DashboardAER);
