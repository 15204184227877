exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".incomingInvoicesPage-dbc78 .addInvoiceButton-2580f{margin-left:15px}.incomingInvoicesPage-dbc78 .actionButtons-801c8{display:flex}.incomingInvoicesPage-dbc78 .editButton-e4ccd{margin-right:5px}.incomingInvoicesPage-dbc78 .removeButton-84f3b{color:#ff4d4f}.incomingInvoicesPage-dbc78 .approveButtons-a5c29{display:flex;flex-wrap:nowrap}.incomingInvoicesPage-dbc78 .approveButton-cbc8b{margin-right:5px}.incomingInvoicesPage-dbc78 .attachments-12bd1{max-width:150px}.searchContainer-7cab1{padding:10px 8px}.searchContainer-7cab1 .searchInput-b274b{width:188px;margin-bottom:8px;display:block}.searchContainer-7cab1 .searchButton-c02df{width:90px;margin-right:8px}.searchContainer-7cab1 .resetButton-5bdd1{width:90px}.searchContainer-7cab1 .searchIcon-9cb26{color:#1677ff}", ""]);

// Exports
exports.locals = {
	"incomingInvoicesPage": "incomingInvoicesPage-dbc78",
	"addInvoiceButton": "addInvoiceButton-2580f",
	"actionButtons": "actionButtons-801c8",
	"editButton": "editButton-e4ccd",
	"removeButton": "removeButton-84f3b",
	"approveButtons": "approveButtons-a5c29",
	"approveButton": "approveButton-cbc8b",
	"attachments": "attachments-12bd1",
	"searchContainer": "searchContainer-7cab1",
	"searchInput": "searchInput-b274b",
	"searchButton": "searchButton-c02df",
	"resetButton": "resetButton-5bdd1",
	"searchIcon": "searchIcon-9cb26"
};