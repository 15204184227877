import React from 'react';
import PropTypes from 'prop-types';
import { Form, Switch } from 'antd';
import classNames from 'classnames';

import {
  HOLIDAY_COMMON,
  HOLIDAY_REPEAT_TYPES,
  HOLIDAY_TYPES,
  HOLIDAY_WORK_TYPES,
} from 'containers/CalendarPage/constants';
import Input from 'components/Common/Input';
import Select, { Option } from 'components/Common/Select';
import DatePicker from 'components/Common/DatePicker';
import TextArea from 'components/Common/TextArea';
import { DATE_FORMAT, HH_mm } from 'utils/timeConstants';

import styles from './styles.scss';

const { Item } = Form;

const HolidayForm = ({
  form,
  isAllDay,
  isRepeat,
  onValuesChange,
  onSubmit,
}) => (
  <Form
    layout="vertical"
    form={form}
    onFinish={onSubmit}
    onValuesChange={onValuesChange}
  >
    <Item
      label="Holiday type"
      name={HOLIDAY_COMMON.holiday_type}
      required
      rules={[{ required: true, message: 'Select type' }]}
    >
      <Select placeholder="Select type">
        {Object.values(HOLIDAY_TYPES)
          .filter(({ selectable }) => selectable)
          .map(({ key, title }) => (
            <Option value={key} key={key}>
              {title}
            </Option>
          ))}
      </Select>
    </Item>
    <Item
      label="Title"
      name={HOLIDAY_COMMON.title}
      required
      rules={[
        { required: true, message: 'Enter title' },
        {
          min: 2,
          message: 'The minimum number of characters is 2',
        },
        {
          max: 30,
          message: 'The maximum number of characters is 30',
        },
      ]}
    >
      <Input placeholder="Add title" />
    </Item>
    <Item>
      <Item
        label="Holiday date"
        name={HOLIDAY_COMMON.holiday_date}
        required
        className={styles.horizontalItem}
      >
        <DatePicker format={DATE_FORMAT} />
      </Item>
      {!isAllDay && (
        <Item
          label="Holiday time"
          name={HOLIDAY_COMMON.holiday_time}
          required
          className={styles.horizontalItem}
        >
          <DatePicker picker="time" format={HH_mm} />
        </Item>
      )}
      <Item
        label="All day"
        name={HOLIDAY_COMMON.is_all_day}
        className={styles.horizontalItem}
        valuePropName="checked"
      >
        <Switch />
      </Item>
    </Item>
    <Item>
      <Item
        label="Repeat"
        name={HOLIDAY_COMMON.repeat_type}
        required
        className={classNames([styles.horizontalItem, styles.repeatSelect])}
      >
        <Select>
          {Object.values(HOLIDAY_REPEAT_TYPES).map(({ key, title }) => (
            <Option value={key} key={key}>
              {title}
            </Option>
          ))}
        </Select>
      </Item>
      {isRepeat && (
        <Item
          label="Until"
          name={HOLIDAY_COMMON.repeat_until_date}
          required
          rules={[{ required: true, message: 'Enter until date' }]}
          className={styles.horizontalItem}
        >
          <DatePicker />
        </Item>
      )}
    </Item>
    <Item label="Work schedule" name={HOLIDAY_COMMON.work_type} required>
      <Select>
        {Object.values(HOLIDAY_WORK_TYPES).map(({ key, title }) => (
          <Option value={key} key={key}>
            {title}
          </Option>
        ))}
      </Select>
    </Item>
    <Item
      label="Description"
      name={HOLIDAY_COMMON.description}
      rules={[
        {
          max: 100,
          message: 'The maximum number of characters is 100',
        },
      ]}
    >
      <TextArea placeholder="Describe a holiday" />
    </Item>
  </Form>
);

HolidayForm.propTypes = {
  form: PropTypes.object.isRequired,
  isAllDay: PropTypes.bool.isRequired,
  isRepeat: PropTypes.bool.isRequired,
  onValuesChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default HolidayForm;
