import React from 'react';
import { Table, List } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import currencyFormatter from 'currency-formatter';

import styles from './styles.scss';

const dateFormat = 'YYYY-MM-DD';
const monthFormat = 'YYYY/MM';

const columns = [
  {
    title: 'Начисления',
    children: [
      {
        title: 'Вид начисления',
        dataIndex: 'accrual',
        key: 'accrual',
      },
      {
        title: 'Период',
        dataIndex: 'accrual_period',
        key: 'accrual_period',
        render: value => dayjs(value, dateFormat).format(monthFormat),
      },
      {
        title: 'Сумма',
        dataIndex: 'accrual_sum',
        key: 'accrual_sum',
        render: value => currencyFormatter.format(value, { code: 'BYR' }),
      },
      {
        title: 'Дни',
        dataIndex: 'accrual_days',
        key: 'accrual_days',
      },
    ],
  },
  {
    title: 'Удержания',
    children: [
      {
        title: 'Вид удержания',
        dataIndex: 'recoupment',
        key: 'recoupment',
      },
      {
        title: 'Период',
        dataIndex: 'recoupment_period',
        key: 'recoupment_period',
        render: value => dayjs(value, dateFormat).format(monthFormat),
      },
      {
        title: 'Сумма',
        dataIndex: 'recoupment_sum',
        key: 'recoupment_sum',
        render: value => currencyFormatter.format(value, { code: 'BYR' }),
      },
    ],
  },
];

const PaysheetView = ({ paysheet }) => (
  <div className={styles.paysheet}>
    <Table
      columns={columns}
      dataSource={paysheet.paysheetRows}
      bordered
      size="small"
      pagination={false}
      footer={() => (
        <div className={styles.footer}>
          <div>
            <b>Всего начислено:</b>&nbsp;
            {currencyFormatter.format(paysheet.accruals_sum, { code: 'BYR' })}
            &nbsp;
            {currencyFormatter.format(paysheet.beforeTaxesSalary, {
              code: 'BYR',
            })}
            &nbsp;
            {currencyFormatter.format(paysheet.calculatedAccrualsSum, {
              code: 'BYR',
            })}
          </div>
          <div>
            <b>Всего удержано:</b>&nbsp;
            {currencyFormatter.format(paysheet.recoupments_sum, {
              code: 'BYR',
            })}
            &nbsp;
          </div>
        </div>
      )}
    />
    <br />
    <List
      size="small"
      header={
        <div>
          <b>Налоговые вычеты</b>
        </div>
      }
      bordered
      dataSource={paysheet.taxCredits}
      renderItem={item => (
        <List.Item>
          - {item.title}:{' '}
          {currencyFormatter.format(item.value, { code: 'BYR' })}
        </List.Item>
      )}
    />
  </div>
);

PaysheetView.propTypes = {
  paysheet: PropTypes.object.isRequired,
};

export default PaysheetView;
