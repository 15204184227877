exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".button-7a1eb:not(:first-child){margin-left:5px}.actionHeader-e36f0{display:flex;justify-content:space-between;align-items:center}.requestContainer-d083e{width:100%}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}.worklogsEditableCell-9f498{cursor:pointer;padding:3px 7px;border:1px solid rgba(0,0,0,0)}.worklogsEditableCell-9f498:hover{border:1px solid #d9d9d9}.editableRow-eaf7b:hover .worklogsEditableCell-9f498{border:1px solid #d9d9d9}.totalSpentHours-63df1{font-weight:500}.totalCell-c6542{display:inline-block;width:100%;text-align:center}.addWorklogBtn-6cfb9{margin-top:15px}.editedRow-01e16{background:#fff7e6}.editedRow-01e16:hover>td{background:#fff7e6 !important}.deletedRow-89c5e{background:#fff1f0}.deletedRow-89c5e:hover>td{background:#fff1f0 !important}.newRow-5219a{background:#f6ffed}.newRow-5219a:hover>td{background:#f6ffed !important}.restoreBtn-d29fe{color:#237804;border-color:#237804}.duplicateBtn-2e982{margin-right:5px}", ""]);

// Exports
exports.locals = {
	"button": "button-7a1eb",
	"actionHeader": "actionHeader-e36f0",
	"requestContainer": "requestContainer-d083e",
	"worklogsEditableCell": "worklogsEditableCell-9f498",
	"editableRow": "editableRow-eaf7b",
	"totalSpentHours": "totalSpentHours-63df1",
	"totalCell": "totalCell-c6542",
	"addWorklogBtn": "addWorklogBtn-6cfb9",
	"editedRow": "editedRow-01e16",
	"deletedRow": "deletedRow-89c5e",
	"newRow": "newRow-5219a",
	"restoreBtn": "restoreBtn-d29fe",
	"duplicateBtn": "duplicateBtn-2e982"
};