import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Modal, Result } from 'antd';
import { groupBy, get } from 'lodash';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import { TIME_FIELDS } from 'containers/InvoicesPage/constants';
import {
  ClientInfo,
  ClientTimeline,
  ClientModal,
} from 'components/ClientCommon';
import { InvoicesModal } from 'components/InvoicesCommon';
import {
  ClientOrganizations,
  ClientProjects,
  ClientSummary,
  ClientContracts,
} from 'components/ClientCommon/ClientDetails';
import PageLoader from 'components/Common/PageLoader';
import {
  JIRA_FIREBASE_PATH,
  OK_STATUS,
  SORT_DIRECTIONS,
} from 'utils/constants';
import { DATE_FORMAT } from 'utils/timeConstants';
import { mapArrayToEntities } from 'utils/common';
import { useFirebaseProgress, usePrevState, useRoleContext } from 'utils/hooks';
import { hasRights } from 'utils/permissions';
import {
  clientsSelector,
  jiraSelector,
  organizationsSelector,
  usersSelector,
} from 'redux/selectors';
import {
  getClient,
  updateClient,
  addClientOrganization,
  getClientAudit,
  updateClientInvoice,
  getClientInvoices,
  getClientContracts,
  saveClientNote,
  updateClientNote,
  deleteClientNote,
  updateClientDeal,
  getClientAllocations,
  getClientDeals,
  deleteClientDeal,
  deleteClientInvoice,
  updateClientContract,
  clearResponseStatus,
  getClientInvoice,
} from 'redux/Clients/actions';
import { loadUsers } from 'redux/Users/actions';
import {
  getInvoiceStatuses,
  getInvoiceTemplates,
} from 'redux/Invoices/actions';
import {
  getJiraRoles,
  getJiraProjects,
  updateJiraProjects,
} from 'redux/Jira/actions';
import { getCurrencies } from 'redux/Currencies/actions';
import {
  getOrganizationsSearch,
  updateOrganization,
  createOrganizationAccount,
  updateOrganizationAccount,
  deleteOrganizationAccount,
} from 'redux/Organizations/actions';

import { filterDeals, getFormattedAudit } from './utils';
import { CLIENT_PERMISSIONS, INVOICES_MODAL_TAB } from './constants';

let unsubscribe;

const ClientPage = () => {
  const {
    client,
    clientStatus,
    clientPaid,
    clientPending,
    clientAllocations,
    clientInvoices,
    clientAudit,
    clientContracts,
    clientNotesObject,
    clientNotes,
    clientOrganizations,
    clientProjects,
    clientDeals,
    responseStatus,
    isClientsLoading,
  } = useSelector(clientsSelector);
  const { users } = useSelector(usersSelector);
  const { jiraRoles } = useSelector(jiraSelector);
  const { organizations, isOrganizationsLoading } = useSelector(
    organizationsSelector,
  );

  const dispatch = useDispatch();

  const role = useRoleContext();

  const { id } = useParams();

  const [status, setStatus] = useState(null);
  const [isVisibleClientModal, setIsVisibleClientModal] = useState(false);
  const [tabKey, setTabKey] = useState('Deal');
  const [groupProjectsById, setGroupProjectsById] = useState(null);
  const [isDisableNextButton, setIsDisableNextButton] = useState(false);

  const [isVisibleContractModal, setIsVisibleContractModal] = useState(false);
  const [isVisibleInvoiceModal, setisVisibleInvoiceModal] = useState(false);
  const [editedInvoiceId, setEditedInvoiceId] = useState(null);
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [invoiceModalTab, setInvoiceModalTab] = useState(
    INVOICES_MODAL_TAB.information,
  );
  const [chosenTab, setChosenTab] = useState('Deal');
  const [invoiceEditId, setInvoiceEditId] = useState('');
  const [isInvoiceEditModalVisible, setIsInvoiceEditModalVisible] = useState(
    false,
  );

  const [editingDealId, setEditingDealId] = useState(null);
  const [allocationsData, setAllocationsData] = useState([]);
  const [dealValues, setDealValues] = useState(null);

  const { progress, getFirebaseData } = useFirebaseProgress();

  const prevProgress = usePrevState(progress);

  const [invoiceForm] = Form.useForm();

  const usersObject = useMemo(() => mapArrayToEntities(users).entities, [
    users,
  ]);

  useEffect(() => {
    dispatch(clearResponseStatus());
    dispatch(getClient(id));
    dispatch(loadUsers());
    dispatch(getClientAudit({ clientId: id }));
    hasRights(role, CLIENT_PERMISSIONS.clientContracts) &&
      dispatch(getClientContracts({ id }));
    dispatch(getClientDeals({ id }));
    dispatch(getInvoiceStatuses());
    dispatch(getClientInvoices({ clientId: id }));
    dispatch(getInvoiceTemplates());
    dispatch(getJiraProjects({ sort: `name:${SORT_DIRECTIONS.ascend}` }));
    dispatch(getJiraRoles());
    dispatch(getClientAllocations({ clientId: id }));
    hasRights(role, CLIENT_PERMISSIONS.clientOrganizations) &&
      dispatch(getOrganizationsSearch({ size: 1000 }));
    dispatch(getCurrencies());
  }, []);

  useEffect(() => {
    unsubscribe && unsubscribe();
    unsubscribe = getFirebaseData({
      collection: JIRA_FIREBASE_PATH.collection,
      doc: JIRA_FIREBASE_PATH.doc,
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (
      progress &&
      progress.status === OK_STATUS &&
      prevProgress &&
      prevProgress.status !== OK_STATUS
    ) {
      dispatch(getJiraProjects({ sort: `name:${SORT_DIRECTIONS.ascend}` }));
    }
  }, [progress]);

  const allocationsGroupByDeal = useMemo(
    () => groupBy(clientAllocations, 'deal_id'),
    [clientAllocations],
  );

  const allocationsGroupById = useMemo(
    () => groupBy(clientAllocations, 'id') || null,
    [clientAllocations],
  );

  useEffect(() => {
    if (responseStatus) setStatus(responseStatus);
  }, [responseStatus]);

  const filteredDeals = useMemo(() => filterDeals({ deals: clientDeals, id }), [
    clientDeals,
    id,
  ]);

  const dealsObject = useMemo(
    () => mapArrayToEntities(filteredDeals).entities,
    [filteredDeals],
  );

  const organizationDataObject = useMemo(() => groupBy(organizations, 'id'), [
    organizations,
  ]);

  const onEditDeal = ({ dealValues, allocationsData }) => {
    setIsVisibleClientModal(true);
    setEditingDealId(dealValues.id);
    setDealValues(dealValues);
    setAllocationsData(allocationsData);
  };

  const onUpdateNote = ({ text, noteId }) => {
    dispatch(updateClientNote({ text, noteId, clientId: id }));
  };

  const onDeleteNote = ({ noteId }) => {
    Modal.confirm({
      title: 'Delete comment',
      content: 'Are you sure you want to delete this comment?',
      onOk: () => {
        dispatch(deleteClientNote({ noteId, clientId: id }));
        Modal.destroyAll();
      },
    });
  };

  const onSaveNote = values => {
    if (values.text) {
      dispatch(saveClientNote({ clientId: id, text: values.text }));
    }

    onHideClientModal();
  };

  const onDeleteInvoice = invoiceId => {
    dispatch(deleteClientInvoice({ clientId: id, invoiceId }));
  };

  const onAddOrganization = organizationId => {
    dispatch(addClientOrganization({ clientId: id, organizationId }));
  };

  const showClientModal = filter => {
    setIsVisibleClientModal(true);
    setTabKey(filter);
  };

  const clearInvoiceData = () => {
    dispatch(clearResponseStatus());
  };

  const onHideClientModal = () => {
    setIsInvoiceEditModalVisible(false);
    setIsVisibleClientModal(false);
    setStatus(null);
    setDealValues(null);
    setAllocationsData([]);
    setIsDisableNextButton(false);
    clearInvoiceData();
    setEditingDealId(null);
  };

  const onVisibleInvoiceModal = (
    record,
    type = INVOICES_MODAL_TAB.information,
  ) => {
    setInvoiceModalTab(type);
    setisVisibleInvoiceModal(true);
    setCurrentInvoice(record);
    const formValues = Object.keys(record).reduce((acc, curr) => {
      if (TIME_FIELDS.includes(curr)) {
        return record[curr]
          ? { ...acc, [curr]: dayjs(record[curr]) }
          : { ...acc, [curr]: record[curr] };
      }

      if ((curr === 'owner_id' || curr === 'reviewer_id') && record[curr]) {
        const ownerId = record[curr];
        const owner = usersObject[ownerId];

        return { ...acc, [curr]: owner.name || owner.email };
      }

      if (curr === 'client_id') {
        return { ...acc, [curr]: client.title };
      }

      if (curr === 'contractor_id') {
        const contractorId = record[curr];
        const contractor =
          organizationDataObject[contractorId] &&
          organizationDataObject[contractorId][0];

        return { ...acc, [curr]: get(contractor, 'friendly_name', '') };
      }

      return { ...acc, [curr]: record[curr] };
    }, {});

    setEditedInvoiceId(record.id);
    invoiceForm.setFieldsValue(formValues);
  };

  const onHideInvoiceModal = () => {
    setisVisibleInvoiceModal(false);
    invoiceForm.resetFields();
    setCurrentInvoice(null);
    setEditedInvoiceId(null);
    dispatch(clearResponseStatus());
  };

  const onUpdateInvoiceStatusOrSentAt = (invoiceStatus, invoiceSentAt) => {
    const sent_at = invoiceSentAt
      ? dayjs(invoiceSentAt).format(DATE_FORMAT)
      : null;

    const updatedInvoiceData = {
      sent_at,
      status: invoiceStatus,
      invoice_id: editedInvoiceId,
      client_id: id,
      isClearResponseAfter: true,
    };

    dispatch(updateClientInvoice(updatedInvoiceData));
    onHideInvoiceModal();
  };

  useEffect(() => {
    setGroupProjectsById(groupBy(clientProjects, 'id'));
  }, [clientProjects]);

  const onCreateAccount = ({ values, organizationId }) => {
    dispatch(
      createOrganizationAccount({ values, organizationId, clientId: id }),
    );
  };

  const onEditAccount = ({ values, organizationId, id: accountId }) => {
    dispatch(
      updateOrganizationAccount({
        values,
        organizationId,
        id: accountId,
        clientId: id,
      }),
    );
  };

  const onDeleteAccount = ({ accountId, organizationId }) => {
    dispatch(
      deleteOrganizationAccount({ accountId, organizationId, clientId: id }),
    );
  };

  const onEditOrganization = ({ organizationId, data }) => {
    dispatch(
      updateOrganization({
        organizationId,
        data,
        clientId: id,
      }),
    );
  };

  const invoicesGroupById = useMemo(
    () => mapArrayToEntities(clientInvoices).entities,
    [clientInvoices],
  );

  const onEndDeal = ({ dealId, endsAt }) => {
    dispatch(
      updateClientDeal({
        values: { ends_at: endsAt },
        dealId,
        clientId: id,
        isUpdate: true,
        isEndDeal: true,
      }),
    );
  };

  const onDeleteDeal = ({ deal_id }) => {
    if (deal_id) {
      dispatch(deleteClientDeal({ deal_id, clientId: id }));
    }
  };

  const onUpdateClientContract = values =>
    dispatch(updateClientContract(values));

  const onEditInvoice = invoiceId => {
    setIsInvoiceEditModalVisible(true);
    setIsVisibleClientModal(true);
    setInvoiceEditId(invoiceId);
    dispatch(getClientInvoice({ invoiceId, clientId: id }));
    setChosenTab('Invoice');
  };

  const onUpdateClient = values => dispatch(updateClient(values));

  const onUpdateJiraProjects = () => {
    dispatch(updateJiraProjects());
  };

  // Формирует массив из всех аудитов для отображения таймлайна
  const audits = useMemo(
    () =>
      getFormattedAudit({
        clientDeals,
        clientInvoices,
        clientNotes,
        clientAudit,
        clientAllocations,
      }),
    [clientDeals, clientInvoices, clientNotes, clientAudit, clientAllocations],
  );

  if (clientStatus) {
    return <Result status={clientStatus} />;
  }

  if (!client) {
    return <PageLoader />;
  }

  return (
    <div>
      <Row gutter={24}>
        <Col md={{ span: 6 }}>
          <ClientInfo
            client={client}
            clientPaid={clientPaid}
            clientPending={clientPending}
          />
          {hasRights(role, CLIENT_PERMISSIONS.generalInfo) && (
            <ClientSummary
              client={client}
              updateClient={onUpdateClient}
              id={id}
              role={role}
            />
          )}
          {hasRights(role, CLIENT_PERMISSIONS.clientOrganizations) && (
            <ClientOrganizations
              organizations={clientOrganizations}
              editOrganization={onEditOrganization}
              addOrganization={onAddOrganization}
              deleteAccount={onDeleteAccount}
              createAccount={onCreateAccount}
              editAccount={onEditAccount}
              isLoading={isOrganizationsLoading}
              clientId={id}
              role={role}
            />
          )}
          <ClientProjects projects={clientProjects} />
          {hasRights(role, CLIENT_PERMISSIONS.clientContracts) && (
            <ClientContracts
              isVisible={isVisibleContractModal}
              setIsVisible={setIsVisibleContractModal}
              contracts={clientContracts}
              organizationDataObject={organizationDataObject}
              organizationsData={organizations}
              updateContract={onUpdateClientContract}
              id={id}
            />
          )}
        </Col>
        <Col md={{ span: 18 }}>
          <InvoicesModal
            visible={isVisibleInvoiceModal}
            form={invoiceForm}
            onHideModal={onHideInvoiceModal}
            onFinish={onUpdateInvoiceStatusOrSentAt}
            currentInvoice={currentInvoice}
            invoiceModalTab={invoiceModalTab}
            setInvoiceModalTab={setInvoiceModalTab}
            isInvoiceAuditLoading={isClientsLoading}
            usersObject={usersObject}
            isClientPage
            role={role}
          />
          <ClientModal
            isInvoiceEditModalVisible={isInvoiceEditModalVisible}
            isModalVisible={isVisibleClientModal}
            onHideClientModal={onHideClientModal}
            status={status}
            onSaveNote={onSaveNote}
            tabKey={tabKey}
            isDisableNextButton={isDisableNextButton}
            setIsDisableNextButton={setIsDisableNextButton}
            clientOrganizations={clientOrganizations}
            onDeleteInvoice={onDeleteInvoice}
            clearInvoiceData={clearInvoiceData}
            invoiceEditId={invoiceEditId}
            chosenTab={chosenTab}
            setChosenTab={setChosenTab}
            filteredDeals={filteredDeals}
            allocationsGroupByDeal={allocationsGroupByDeal}
            setDealValues={setDealValues}
            dealValues={dealValues}
            allocationsData={allocationsData}
            setAllocationsData={setAllocationsData}
            editingDealId={editingDealId}
            role={role}
            setIsInvoiceEditModalVisible={setIsInvoiceEditModalVisible}
          />
          <ClientTimeline
            isLoading={isClientsLoading}
            users={usersObject}
            updateNote={onUpdateNote}
            jiraRoles={jiraRoles}
            showClientModal={showClientModal}
            clientAudit={audits}
            deals={dealsObject}
            allocations={allocationsGroupByDeal}
            organizationsData={organizationDataObject}
            filteredDeals={filteredDeals}
            allocationsGroupById={allocationsGroupById}
            jiraProjects={groupProjectsById}
            onEndDeal={onEndDeal}
            onDeleteDeal={onDeleteDeal}
            invoicesGroupById={invoicesGroupById}
            onDeleteInvoice={onDeleteInvoice}
            onVisibleInvoiceModal={onVisibleInvoiceModal}
            clientNotesObject={clientNotesObject}
            clientProjects={clientProjects}
            isTimelineDataLoading={isClientsLoading}
            isInvoiceEditModalVisible={isInvoiceEditModalVisible}
            isUpdateInvoice={isClientsLoading}
            deleteNote={onDeleteNote}
            onEditInvoice={onEditInvoice}
            onEditDeal={onEditDeal}
            role={role}
            onUpdateJiraProjects={onUpdateJiraProjects}
            progress={progress}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ClientPage;
