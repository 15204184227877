exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".socialRequestsTotal-f0877{display:grid;grid-template-columns:repeat(2, 1fr);gap:50px;min-width:390px;margin-right:24px}.socialRequestsTotal-f0877 .title-e9f3c{display:inline-block;margin-bottom:12px;font-weight:700}.items-3dc13{width:100%}.block-ea65d{display:flex;flex-direction:column;align-items:center;min-width:142px;padding:10px 16px;background:#fff;border:1px solid #d9d9d9;border-radius:10px}", ""]);

// Exports
exports.locals = {
	"socialRequestsTotal": "socialRequestsTotal-f0877",
	"title": "title-e9f3c",
	"items": "items-3dc13",
	"block": "block-ea65d"
};