import {
  takeLatest,
  call,
  put,
  takeEvery,
  all,
  select,
} from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';

import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';
import { organizationsSelector } from 'redux/selectors';
import { types as clientTypes } from 'redux/Clients/types';
import { types } from './types';

function* getOrganizationsSearch({ payload = {} }) {
  try {
    const { data, headers } = yield call(API.getOrganizationsSearch, payload);
    yield put({
      type: types.GET_ORGANIZATIONS_SEARCH_SUCCESS,
      payload: { data: data.data, headers },
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_ORGANIZATIONS_SEARCH_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getOrganizations() {
  try {
    const { data } = yield call(API.getOrganizations);
    yield put({
      type: types.GET_ORGANIZATIONS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_ORGANIZATIONS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* createOrganization({ payload: { values, sort, accounts } }) {
  try {
    const { data } = yield call(API.createOrganization, values);

    yield all(
      accounts.map(account => {
        delete account.id;

        return createOrganizationAccount({
          payload: {
            organizationId: data.id,
            values: account,
          },
        });
      }),
    );

    yield call(getOrganizationsSearch, { payload: { sort } });
    message.success(SAGA_MESSAGES.CREATE_ORGANIZATIONS_SUCCESS);
    yield put({ type: types.CREATE_ORGANIZATION_SUCCESS });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_ORGANIZATION_FAILED,
      SAGA_MESSAGES.CREATE_ORGANIZATIONS_FAILED,
    );
  }
}

function* updateOrganization({ payload: { organizationId, data, sort } }) {
  try {
    yield call(API.editOrganization, { organizationId, data });
    yield call(getOrganizationsSearch, { payload: { sort } });

    yield put({ type: types.UPDATE_ORGANIZATION_SUCCESS });
    message.success(SAGA_MESSAGES.UPDATE_ORGANIZATIONS_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_ORGANIZATION_FAILED,
      SAGA_MESSAGES.UPDATE_ORGANIZATIONS_FAILED,
    );
  }
}

function* getInvoiceOrganizationAccounts({
  payload: { organization = '', field = '' },
}) {
  try {
    const { data } = yield call(API.getOrganizationAccounts, organization);
    yield put({
      type: types.GET_INVOICE_ORGANIZATION_ACCOUNTS_SUCCESS,
      payload: { data, field },
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_INVOICE_ORGANIZATION_ACCOUNTS_FAILED,
    );
  }
}

function* deleteOrganization({ payload: { id, sort } }) {
  try {
    yield call(API.deleteOrganization, id);
    yield call(getOrganizationsSearch, { payload: { sort } });
    message.success(SAGA_MESSAGES.DELETE_ORGANIZATIONS_SUCCESS);
    yield put({ type: types.DELETE_ORGANIZATION_SUCCESS });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.DELETE_ORGANIZATION_FAILED);
  }
}

function* getOrganizationAccounts({ payload: { organizationId } }) {
  try {
    const { data } = yield call(API.getOrganizationAccounts, organizationId);
    yield put({ type: types.GET_ORGANIZATION_ACCOUNTS_SUCCESS, payload: data });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_ORGANIZATION_ACCOUNTS_FAILED);
  }
}

function* createOrganizationAccount({
  payload: { organizationId, values, clientId },
}) {
  try {
    const { data } = yield call(API.createOrganizationAccount, {
      organizationId: organizationId,
      data: values,
    });

    const { organizationAccounts } = yield select(organizationsSelector);

    if (clientId) {
      yield put({
        type: clientTypes.GET_CLIENT_ORGANIZATIONS,
        payload: { clientId },
      });
    }

    yield put({
      type: types.CREATE_ORGANIZATION_ACCOUNT_SUCCESS,
      payload: [...organizationAccounts, data],
    });
    message.success(SAGA_MESSAGES.CREATE_ACCOUNT_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_ORGANIZATION_ACCOUNT_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* updateOrganizationAccount({
  payload: { organizationId, id, values, clientId },
}) {
  try {
    yield call(API.editOrganizationAccount, {
      organizationId: organizationId,
      accountId: id,
      data: values,
    });

    if (clientId) {
      yield put({
        type: clientTypes.GET_CLIENT_ORGANIZATIONS,
        payload: { clientId },
      });
    } else {
      yield call(getOrganizationAccounts, { payload: { organizationId } });
    }

    yield put({ type: types.UPDATE_ORGANIZATION_ACCOUNT_SUCCESS });
    message.success(SAGA_MESSAGES.UPDATE_ACCOUNT_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_ORGANIZATION_ACCOUNT_FAILED,
      SAGA_MESSAGES.UPDATE_ACCOUNT_FAILED,
    );
  }
}

function* deleteOrganizationAccount({
  payload: { organizationId, accountId, clientId },
}) {
  try {
    yield call(API.deleteOrganizationAccount, {
      organizationId,
      accountId,
    });

    if (clientId) {
      yield put({
        type: clientTypes.GET_CLIENT_ORGANIZATIONS,
        payload: { clientId },
      });
    } else {
      yield call(getOrganizationAccounts, { payload: { organizationId } });
    }

    yield put({ type: types.DELETE_ORGANIZATION_ACCOUNT_SUCCESS });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_ORGANIZATION_ACCOUNT_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_ORGANIZATIONS_SEARCH, getOrganizationsSearch);
  yield takeLatest(types.GET_ORGANIZATIONS, getOrganizations);
  yield takeLatest(types.CREATE_ORGANIZATION, createOrganization);
  yield takeLatest(types.UPDATE_ORGANIZATION, updateOrganization);
  yield takeLatest(types.DELETE_ORGANIZATION, deleteOrganization);
  yield takeEvery(
    types.GET_INVOICE_ORGANIZATION_ACCOUNTS,
    getInvoiceOrganizationAccounts,
  );
  yield takeLatest(
    types.UPDATE_ORGANIZATION_ACCOUNT,
    updateOrganizationAccount,
  );
  yield takeLatest(types.GET_ORGANIZATION_ACCOUNTS, getOrganizationAccounts);
  yield takeLatest(
    types.DELETE_ORGANIZATION_ACCOUNT,
    deleteOrganizationAccount,
  );
  yield takeLatest(
    types.CREATE_ORGANIZATION_ACCOUNT,
    createOrganizationAccount,
  );
}
