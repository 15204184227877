import React from 'react';
import PropTypes from 'prop-types';
import { Modal as AntdModal } from 'antd';
import classNames from 'classnames';

import styles from './styles.scss';

const Modal = ({ ...props }) => (
  <AntdModal {...props} className={classNames([styles.modal, props.className])}>
    {props.children}
  </AntdModal>
);

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Modal;
