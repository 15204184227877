/* eslint-disable react/prop-types */
import React from 'react';

import FilterDropdown from 'components/Common/FilterDropdown';
import SearchTableIcon from 'components/Icons/SearchIcon';

const getColumnSearchProps = ({ dataIndex }) => ({
  filterDropdown: props => <FilterDropdown {...props} dataIndex={dataIndex} />,
  filterIcon: filtered => <SearchTableIcon isFiltered={filtered} />,
});

export default getColumnSearchProps;
