import { hasRights, ROLE_TYPES } from 'utils/permissions';

export const ROUTING = {
  LOGIN: { path: '/login', scopes: () => true },
  AVERAGE_SALARY: {
    path: '/salaries/average',
    scopes: role =>
      hasRights(role, [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist]),
  },
  PAYSHEET_TABLE: { path: '/salaries/calculate', scopes: () => true },
  EMPLOYEES_PROFILES: { path: '/profiles', scopes: () => true },
  EMPLOYEES_SUMMARY: { path: '/summary', scopes: () => true },
  PROFILE: { path: '/profiles/:id', scopes: () => true },
  SUMMARY: { path: '/summary/:id', scopes: () => true },
  ORGANIZATION: {
    path: '/organizations',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.bdm,
      ]),
  },
  EXTRA_PAYMENTS: { path: '/extra-payments', scopes: () => true },
  EXCHANGE_RATES: {
    path: '/exchange-rates',
    scopes: role =>
      hasRights(role, [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist]),
  },
  TECHNOLOGIES: {
    path: '/technologies',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.hr_lead,
        ROLE_TYPES.hr,
        ROLE_TYPES.dm,
        ROLE_TYPES.bdm,
        ROLE_TYPES.pm,
        ROLE_TYPES.office_manger,
      ]),
  },
  EMPLOYEE_POSITION: {
    path: '/employee-positions',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.hr_lead,
      ]),
  },
  EMPLOYEE: {
    path: '/employees/:id/:tab?',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.hr_lead,
        ROLE_TYPES.office_manger,
      ]),
  },
  CALENDAR: { path: '/calendar', scopes: () => true },
  RESOURCE_MANAGEMENT: { path: '/resource-management', scopes: () => true },
  PROJECT_PROFITS: {
    path: '/reports/project-profits',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.dm,
        ROLE_TYPES.bdm,
        ROLE_TYPES.pm,
      ]),
  },
  AUDIT: {
    path: '/audit',
    scopes: role =>
      hasRights(role, [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist]),
  },
  SUBCONTRACTORS: {
    path: '/reports/subcontractors',
    scopes: role =>
      hasRights(role, [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist]),
  },
  CLIENTS: {
    path: '/clients',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.dm,
        ROLE_TYPES.bdm,
        ROLE_TYPES.pm,
      ]),
  },
  CLIENT: {
    path: '/clients/:id',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.dm,
        ROLE_TYPES.bdm,
        ROLE_TYPES.pm,
      ]),
  },
  LEADS: {
    path: '/leads',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.bdm,
        ROLE_TYPES.head_of_marketing,
      ]),
  },
  LEAD: {
    path: '/leads/:id',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.bdm,
        ROLE_TYPES.head_of_marketing,
      ]),
  },
  INVOICES: {
    path: '/invoices/',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.dm,
        ROLE_TYPES.bdm,
        ROLE_TYPES.pm,
      ]),
  },
  CLIENTS_CONTRACTS: {
    path: '/contracts/',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.bdm,
      ]),
  },
  USERS: {
    path: '/users',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.hr_lead,
      ]),
  },
  DASHBOARD_OVERVIEW: {
    path: '/dashboard-overview',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        // ROLE_TYPES.dm,
        // ROLE_TYPES.bdm,
        // ROLE_TYPES.pm,
      ]),
  },
  DASHBOARD_EFFECTIVENESS: {
    path: '/dashboard-effectiveness',
    scopes: role =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        // ROLE_TYPES.dm,
        // ROLE_TYPES.bdm,
        // ROLE_TYPES.pm,
      ]),
  },
  DASHBOARD_EMPLOYEE_OVERVIEW: {
    path: '/dashboard-employee-overview',
    scopes: role =>
      hasRights(role, [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist]),
  },
  DASHBOARD_SALARY: {
    path: '/dashboard-salary',
    scopes: role =>
      hasRights(role, [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist]),
  },
  DASHBOARD_SUBCONTRACTORS: {
    path: '/dashboard-subcontractors',
    scopes: role =>
      hasRights(role, [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist]),
  },
  INCOMING_INVOICES: {
    path: '/incoming-invoices',
    scopes: () => false,
  },
  INVOICE_TYPES: {
    path: '/invoice-types',
    scopes: () => false,
  },
  BANKS: {
    path: '/banks',
    scopes: () => false,
  },
  CURRENCIES: {
    path: '/currencies',
    scopes: role =>
      hasRights(role, [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist]),
  },
  ROLES: {
    path: '/roles',
    scopes: role =>
      hasRights(role, [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist]),
  },
  SOCIAL_REQUESTS: {
    path: '/social-requests',
    scopes: (role, user) =>
      hasRights(role, [
        ROLE_TYPES.ceo,
        ROLE_TYPES.finance_specialist,
        ROLE_TYPES.hr_lead,
      ]) || !!user?.summary_ids?.length,
  },
};

export const LINKS = {
  profile: employeeId => `/profiles/${employeeId}`,
  summaryEmployee: summaryEmployeeId => `/summary/${summaryEmployeeId}`,
  client: clientId => `/clients/${clientId}`,
  lead: leadId => `/leads/${leadId}`,
};
