import React, { useState, useEffect, useMemo, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { MONTH_FORMAT_DAY_DIGIT } from 'containers/InvoicesPage/constants';
import PageHeader from 'components/Common/PageHeader';
import { Loader } from 'components/Common';
import RangePicker from 'components/Common/RangePicker';
import DashboardSalaryChart from 'components/DashboardCommon/DashboardSalaryChart';
import DashboardSalaryTable from 'components/DashboardCommon/DashboardSalaryTable';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getMonthsRange,
} from 'utils/helpers';
import { SORT_DIRECTIONS } from 'utils/constants';
import { getFiltersWithOutNull } from 'utils/common';
import {
  getDashboardSalaryChart,
  getDashboardSalaryTable,
} from 'redux/Dashboard/actions';
import { dashboardSelector } from 'redux/selectors';

import { getColumns, getSummary } from './utils';
import { DASHBOARD_SALARY_INDEX } from './constants';
import styles from './styles.scss';

const DashboardSalaryPage = () => {
  const [period, setPeriod] = useState(getMonthsRange(new Date(), 2));
  const [filterInfo, setFilterInfo] = useState({});
  const [sortInfo, setSortInfo] = useState({
    field: DASHBOARD_SALARY_INDEX.full_name,
    order: SORT_DIRECTIONS.ascend,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const searchInput = createRef();

  const dispatch = useDispatch();

  const {
    dashboardSalaryChart,
    dashboardSalaryTable,
    dashboardSalaryTableFilters,
    dashboardSalaryTableTotal,
    dashboardSalaryTableSummary,
    isDashboardLoading,
  } = useSelector(dashboardSelector);

  useEffect(() => {
    setPagination(prev => ({ ...prev, total: dashboardSalaryTableTotal }));
  }, [dashboardSalaryTableTotal]);

  const chartDefaultRequest = {
    from: dayjs(period[0]).format(MONTH_FORMAT_DAY_DIGIT),
    to: dayjs(period[1]).format(MONTH_FORMAT_DAY_DIGIT),
  };
  const tableDefaultRequest = {
    from: dayjs(period[0]).format(MONTH_FORMAT_DAY_DIGIT),
    to: dayjs(period[1]).format(MONTH_FORMAT_DAY_DIGIT),
    filters: filterInfo,
    sort: `${sortInfo.field}:${sortInfo.order}`,
    page: pagination.current,
    size: pagination.pageSize,
  };

  useEffect(() => {
    dispatch(getDashboardSalaryChart(chartDefaultRequest));
    dispatch(getDashboardSalaryTable(tableDefaultRequest));
  }, [period]);

  const onPeriodChange = period => {
    setPeriod([getFirstDayOfMonth(period[0]), getLastDayOfMonth(period[1])]);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = clearFilters => {
    clearFilters();
    setFilterInfo({});
    dispatch(
      getDashboardSalaryTable({
        ...tableDefaultRequest,
        filters: {},
      }),
    );
  };

  const columns = useMemo(
    () =>
      getColumns({
        filters: dashboardSalaryTableFilters,
        sortInfo,
        filterInfo,
        handleSearch,
        handleReset,
        searchInput,
      }),
    [dashboardSalaryTable, dashboardSalaryTableFilters],
  );

  const summary = () => getSummary(dashboardSalaryTableSummary);

  const onTableChange = (pagination, filters, sort) => {
    const filterWithoutNull = getFiltersWithOutNull(filters);
    setFilterInfo(filterWithoutNull);

    if ('full_name' in filterWithoutNull) {
      filterWithoutNull.full_name = filterWithoutNull.full_name[0];
    }

    setSortInfo(sort);
    setPagination(pagination);

    dispatch(
      getDashboardSalaryTable({
        ...tableDefaultRequest,
        sort: `${sort.field}:${sort.order}`,
        filters: filterWithoutNull,
        page: pagination.current,
        size: pagination.pageSize,
      }),
    );
  };

  return (
    <div className={styles.dashboardSalaryPage}>
      <PageHeader
        title="Dashboard salary fund"
        extra={
          <div className={styles.dashboardHeader}>
            <RangePicker
              picker="month"
              value={period}
              format="YYYY/MM"
              onChange={onPeriodChange}
              className={styles.rangePicker}
            />
          </div>
        }
      />
      <Loader loading={isDashboardLoading} isDynamicPosition>
        <DashboardSalaryChart chartDetails={dashboardSalaryChart} />
        <DashboardSalaryTable
          tableData={dashboardSalaryTable}
          columns={columns}
          pagination={pagination}
          summary={summary}
          onTableChange={onTableChange}
        />
      </Loader>
    </div>
  );
};

export default DashboardSalaryPage;
