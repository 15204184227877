export const CURRENCY_MESSAGES = {
  CREATE_CURRENCY_SUCCESS: 'Currency created successfully',
  CREATE_CURRENCY_FAILED: 'Failed to create currency',
  UPDATE_CURRENCY_SUCCESS: 'Currency updated successfully',
  UPDATE_CURRENCY_FAILED: 'Failed to update curency',
  DELETE_CURRENCY_SUCCESS: 'Currency deleted successfully',
  DELETE_CURRENCY_FAILED: 'Failed to delete currency',
};

export const CURRENCIES_COMMON = {
  id_title: 'ID',
  id: 'id',
  name_title: 'Name',
  name: 'name',
  actions_title: 'Actions',
  actions: 'actions',
};
