import React from 'react';
import { Link } from 'react-router-dom';
import { Tag, List, Popover, Alert, Table, Tooltip } from 'antd';
import { get, flattenDeep } from 'lodash';
import dayjs from 'dayjs';

import { stringSorter } from 'utils/stringSorter';
import { CURRENCY_CODES } from 'utils/constants';
import { currencyFormatter } from 'utils/helpers';
import TotalCell from './TotalCell';
import MoneyCell from './MoneyCell';

import styles from './styles.scss';

export const PAYSHEET_STATUSES = {
  OK: { title: 'Ок', color: 'green' },
  FAILED: { title: 'Есть ошибки', color: 'red' },
  PENDING: { title: 'В процессе', color: 'orange' },
  MISSING_IBAN: { title: 'Не указан IBAN', color: 'red' },
  FIRED: { title: 'Уволен', color: 'gray' },
};

export function getStatusTag(status) {
  return (
    <Tag color={get(PAYSHEET_STATUSES, [status, 'color'], 'blue')} key={status}>
      {get(PAYSHEET_STATUSES, [status, 'title'], 'Не указан')}
    </Tag>
  );
}

export function parseWarnings(warnings = {}) {
  return flattenDeep(Object.values(warnings));
}

export function renderWarnings(warnings) {
  const message = warnings.map(warn => <p key={warn}>{warn}</p>);

  return <Alert message={message} type="warning" showIcon />;
}

export const formatAccruals = accruals =>
  accruals.map(exp => ({
    accrual: exp.title,
    accrual_period: exp.period,
    accrual_sum: exp.value,
    accrual_days: exp.days,
  }));

export const formatRecoupments = recoupments =>
  recoupments.map(exp => ({
    recoupment: exp.title,
    recoupment_period: exp.period,
    recoupment_sum: exp.value,
  }));

export const mergeAccrualsRecoupments = (accrualsExt, recoupmentsExt) => {
  const accruals = formatAccruals(accrualsExt);
  const recoupments = formatRecoupments(recoupmentsExt);

  const rowsCount = Math.max(accruals.length, recoupments.length);

  const rows = [];

  for (let i = 0; i < rowsCount; i += 1) {
    const accrual = typeof accruals[i] === 'undefined' ? null : accruals[i];
    const recoupment =
      typeof recoupments[i] === 'undefined' ? null : recoupments[i];
    const row = { ...accrual, ...recoupment };
    rows.push(row);
  }

  return rows;
};

export const formatPaySheetTable = paysheets =>
  paysheets.map((exp, index) => ({
    key: `${exp.employee_id}_${index}`,
    status: exp.status,
    name: exp.employee_full_name,
    internalId: exp.employee_internal_id,
    employeeId: exp.employee_id,
    fixedSalaryByn: exp.fixed_salary_byn, // TODO check third variant 'hourly-rate'
    fixedSalaryUsd: exp.fixed_salary_usd,
    salaryUsd: exp.salary_usd,
    salaryByn: exp.salary_byn,
    fullSalaryUsd: exp.full_salary_usd,
    fullExtraSalaryUsd: {
      amount: exp.full_extra_salary_usd,
      extras: exp.extra_payments,
    },
    paidSalaryUsd: exp.paid_salary_usd,
    paidSalaryByn: exp.paid_salary_byn,
    beforeTaxesSalary: exp.before_taxes_salary,
    calculatedAccrualsSum: exp.calculated_accruals_sum,
    nssfTax: exp.nssf_tax,
    pensionTax: exp.pension_tax,
    incomeTax: exp.income_tax,
    premium: exp.premium,
    accruals: exp.accruals,
    accruals_sum: exp.accruals.reduce(
      (accumulator, item) => accumulator + +item.value,
      0.0,
    ),
    recoupments: exp.recoupments,
    recoupments_sum: exp.recoupments.reduce(
      (accumulator, item) => accumulator + +item.value,
      0.0,
    ),
    taxCredits: exp.tax_credits,
    paysheetRows: mergeAccrualsRecoupments(exp.accruals, exp.recoupments),
    daysWorked: exp.days_worked,
    updatedAt: exp.updated_at,
    warnings: parseWarnings(exp.warnings),
  }));

export const getTableColumns = (params, searchFunc) => [
  {
    title: '1C',
    dataIndex: 'internalId',
    key: 'internalId',
    width: 60,
    ...searchFunc('internalId'),
  },
  {
    title: 'ФИО',
    sorter: stringSorter('name'),
    dataIndex: 'name',
    key: 'name',
    ...searchFunc('name'),
    render: (value, { employeeId }) => (
      <Link to={`/profiles/${employeeId}`}>{value}</Link>
    ),
  },
  {
    title: 'Дни',
    dataIndex: 'daysWorked',
    width: 50,
    key: 'daysWorked',
  },
  {
    title: 'USD',
    children: [
      {
        title: 'Выплачено',
        dataIndex: 'paidSalaryUsd',
        key: 'paidSalaryUsd',
        width: 100,
        render: value => (
          <div className={styles.moneyCell}>
            <Tooltip
              arrow={false}
              title={currencyFormatter(value, CURRENCY_CODES.USD)}
            >
              <span>{currencyFormatter(value, CURRENCY_CODES.USD)}</span>
            </Tooltip>
          </div>
        ),
      },
      {
        title: 'К выплате',
        dataIndex: 'fullSalaryUsd',
        key: 'fullSalaryUsd',
        width: 100,
        render: value => currencyFormatter(value, CURRENCY_CODES.USD),
      },
      {
        title: 'Зарплата',
        dataIndex: 'salaryUsd',
        key: 'salaryUsd',
        width: 100,
        render: value => currencyFormatter(value, CURRENCY_CODES.USD),
      },
      {
        title: 'Оклад',
        dataIndex: 'fixedSalaryUsd',
        key: 'fixedSalaryUsd',
        width: 100,
        render: value => (
          <div className={styles.moneyCell}>
            <Tooltip
              arrow={false}
              title={currencyFormatter(value, CURRENCY_CODES.USD)}
            >
              <span>{currencyFormatter(value, CURRENCY_CODES.USD)}</span>
            </Tooltip>
          </div>
        ),
      },

      {
        title: 'Прочие',
        width: 100,
        dataIndex: 'fullExtraSalaryUsd',
        key: 'fullExtraSalaryUsd',
        render: value => (
          <Popover
            content={
              <List
                size="small"
                bordered
                dataSource={value.extras}
                renderItem={item => (
                  <List.Item>
                    {item.title}: &nbsp;
                    {currencyFormatter(item.amount_byn, CURRENCY_CODES.BYR)}
                    &nbsp;
                    {currencyFormatter(item.amount_usd, CURRENCY_CODES.USD)}
                  </List.Item>
                )}
              />
            }
            title="Прочие начисления"
            placement="right"
          >
            {currencyFormatter(value.amount, CURRENCY_CODES.USD)}
          </Popover>
        ),
      },
    ],
  },
  {
    title: 'BYN',
    children: [
      {
        title: 'Выплачено',
        dataIndex: 'paidSalaryByn',
        key: 'paidSalaryByn',
        width: 100,
        render: value => (
          <div className={styles.moneyCell}>
            <Tooltip
              arrow={false}
              title={currencyFormatter(value, CURRENCY_CODES.BYR)}
            >
              <span>{currencyFormatter(value, CURRENCY_CODES.BYR)}</span>
            </Tooltip>
          </div>
        ),
      },

      {
        title: 'К выплате',
        dataIndex: 'salaryByn',
        key: 'salaryByn',
        width: 100,
        render: value => currencyFormatter(value, CURRENCY_CODES.BYR),
      },
      {
        title: 'Премия',
        dataIndex: 'premium',
        key: 'premium',
        width: 100,
        render: value => currencyFormatter(value, CURRENCY_CODES.BYR),
      },
      {
        title: 'Оклад',
        dataIndex: 'fixedSalaryByn',
        key: 'fixedSalaryByn',
        width: 100,
        render: value => (
          <div className={styles.moneyCell}>
            <Tooltip
              arrow={false}
              title={currencyFormatter(value, CURRENCY_CODES.BYR)}
            >
              <span>{currencyFormatter(value, CURRENCY_CODES.BYR)}</span>
            </Tooltip>
          </div>
        ),
      },
    ],
  },
  {
    title: 'Налоги',
    children: [
      {
        title: 'ФСЗН',
        dataIndex: 'nssfTax',
        width: 100,
        key: 'nssfTax',
        render: value => currencyFormatter(value, CURRENCY_CODES.BYR),
      },
      {
        title: 'Подоходный',
        dataIndex: 'incomeTax',
        width: 130,
        key: 'incomeTax',
        render: value => currencyFormatter(value, CURRENCY_CODES.BYR),
      },
      {
        title: 'Пенсионный',
        dataIndex: 'pensionTax',
        width: 150,
        key: 'pensionTax',
        render: value => currencyFormatter(value, CURRENCY_CODES.BYR),
      },
    ],
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    width: 130,
    key: 'status',
    render: (status, { warnings }) => {
      if (warnings && warnings.length > 0) {
        return (
          <Popover
            placement="bottom"
            title="Информация"
            content={renderWarnings(warnings)}
            trigger="hover"
          >
            {getStatusTag(status)}
          </Popover>
        );
      }

      return getStatusTag(status);
    },
    filters: Object.keys(PAYSHEET_STATUSES).map(status => ({
      text: PAYSHEET_STATUSES[status].title,
      value: status,
    })),
    onFilter: (value, record) => record.status === value,
  },
  {
    title: 'Изменен',
    sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
    width: 100,
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: creationDate => dayjs(creationDate).format('DD.MM.YYYY'),
  },
];

export const formatMoney = value =>
  (+value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getSummary = pageData => {
  const result = pageData.reduce(
    (total, item) => ({
      paidSalaryUsd: Number(total.paidSalaryUsd) + Number(item.paidSalaryUsd),
      fullSalaryUsd: Number(total.fullSalaryUsd) + Number(item.fullSalaryUsd),
      salaryUsd: Number(total.salaryUsd) + Number(item.salaryUsd),
      fixedSalaryUsd:
        Number(total.fixedSalaryUsd) + Number(item.fixedSalaryUsd),
      fullExtraSalaryUsd:
        Number(total.fullExtraSalaryUsd) +
        Number(item.fullExtraSalaryUsd.amount),
      paidSalaryByn: Number(total.paidSalaryByn) + Number(item.paidSalaryByn),
      salaryByn: Number(total.salaryByn) + Number(item.salaryByn),
      premium: Number(total.premium) + Number(item.premium),
      fixedSalaryByn:
        Number(total.fixedSalaryByn) + Number(item.fixedSalaryByn),
      nssfTax: Number(total.nssfTax) + Number(item.nssfTax),
      incomeTax: Number(total.incomeTax) + Number(item.incomeTax),
      pensionTax: Number(total.pensionTax) + Number(item.pensionTax),
    }),
    {
      paidSalaryUsd: 0,
      fullSalaryUsd: 0,
      salaryUsd: 0,
      fixedSalaryUsd: 0,
      fullExtraSalaryUsd: 0,
      paidSalaryByn: 0,
      salaryByn: 0,
      premium: 0,
      fixedSalaryByn: 0,
      nssfTax: 0,
      incomeTax: 0,
      pensionTax: 0,
    },
  );

  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1}>
          <TotalCell>{pageData.length}</TotalCell>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} />

        <Table.Summary.Cell index={3} />

        {Object.keys(result).map(item => (
          <Table.Summary.Cell index={4} key={item}>
            <TotalCell>
              <MoneyCell value={result[item]} />
            </TotalCell>
          </Table.Summary.Cell>
        ))}
      </Table.Summary.Row>
    </>
  );
};
