import { tooltipFormatter } from 'containers/DashboardSubcontractors/utils';
import { getMonthNameWithYear, getArrayOfMonth } from 'utils/helpers';

export const getRateData = (monthData, period) => {
  const arrayOfMonth = getArrayOfMonth(period);

  return (
    monthData &&
    arrayOfMonth.reduce((acc, month) => {
      const monthItemData = Object.entries(monthData).find(
        item => item[0] === month,
      );
      const invoicedRate = {
        type: 'Invoiced rate',
        value: monthItemData ? +monthItemData[1].invoiced_rate : 0,
        month,
      };
      const paidRate = {
        type: 'Paid rate',
        value: monthItemData ? +monthItemData[1].paid_rate : 0,
        month,
      };

      return [...acc, invoicedRate, paidRate];
    }, [])
  );
};

export const getRateConfig = data => ({
  data,
  xField: 'month',
  yField: 'value',
  seriesField: 'type',
  xAxis: {
    type: 'time',
    label: {
      formatter: getMonthNameWithYear,
    },
  },
  tooltip: {
    formatter: tooltipFormatter,
  },
  legend: {
    maxRow: 3,
    flipPage: false,
  },
});
