import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Common/Modal';
import Select, { Option } from 'components/Common/Select';

import { REQUEST_TYPES } from 'containers/SocialRequestsPage/constants';
import styles from './styles.scss';

const SocialRequestsApproveModal = ({
  data,
  onHide,
  approveProfile,
  setApproveProfile,
  onApprove,
}) => {
  const isSickDay = data?.request_type === REQUEST_TYPES.sick_day.key;

  return (
    <Modal
      open={!!data}
      title="Approve social request"
      onCancel={onHide}
      okButtonProps={{ disabled: !approveProfile && !isSickDay }}
      onOk={onApprove}
    >
      <div className={styles.approveContent}>
        <span>Are you sure you want to approve this social request?</span>
        {!isSickDay && (
          <>
            <span>
              Select the employee profile where the extra payment will be paid:
            </span>
            <Select onChange={setApproveProfile} value={approveProfile}>
              {data?.summary_employee.employees.map(employee => (
                <Option value={employee.id} key={employee.id}>
                  {employee.profile_name}
                </Option>
              ))}
            </Select>
          </>
        )}
      </div>
    </Modal>
  );
};

SocialRequestsApproveModal.propTypes = {
  data: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  approveProfile: PropTypes.number,
  setApproveProfile: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
};

export default SocialRequestsApproveModal;
