import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get, isNumber } from 'lodash';
import { Avatar, Badge, Popover } from 'antd';
import Icon from '@ant-design/icons';
import {
  EMPLOYEE_COLUMN_KEY,
  RM_PLAN_TYPES,
} from 'containers/ResourceManagementPage/constants';
import { LINKS } from 'config/routing';
import PopoverCell from '../PopoverCell';
import {
  projectIcon,
  calculateTotalTime,
  calculateCellWidth,
  timeView,
  calculateCellSeconds,
  colorBadge,
  findProjectsNames,
  getCell,
} from './utils';
import { cellColor } from '../PopoverCell/utils';

import styles from './styles.scss';

const TableRow = ({
  rows,
  indexRows,
  prepareRow,
  index,
  onCloseOpenTree,
  onClickCell,
  deletePlanById,
  deletePlanByType,
  plans,
  numberDaysOfMonth,
  calendar,
  startDate,
  endDate,
  projects,
  canUpdatePlans,
  canUpdateDeals,
}) => {
  const [isHover, setIsHover] = useState(false);

  const row = rows[index];
  prepareRow(row);
  const isOpenTree =
    indexRows.find(item => item === index) === 0 ||
    indexRows.find(item => item === index);

  const totalTime = calculateTotalTime({ row, calendar });

  return (
    <Fragment>
      <div className="tr">
        {row.cells.map(cell => {
          const key = `${cell.row.id}-${cell.column.Header}`;
          const width = calculateCellWidth(
            cell.column.Header === EMPLOYEE_COLUMN_KEY,
            numberDaysOfMonth,
          );
          const propsForTimeFields = {
            style: {
              display: 'flex',
              justifyContent: 'center',
              fontSize: '11px',
              position: 'relative',
              width,
            },
            onClick: onClickCell,
          };
          const propsForUsersFields = {
            style: {
              width,
            },
            onClick: () => onCloseOpenTree(index),

            onMouseOver: e => {
              setIsHover(true);
            },
            onMouseLeave: e => {
              setIsHover(false);
            },
          };
          const props =
            cell.column.Header === EMPLOYEE_COLUMN_KEY
              ? propsForUsersFields
              : propsForTimeFields;

          const nameOfClassWithManager = row.original.manager
            ? 'manager td'
            : 'td';
          const nameOfClass = !cell.column.isDayOff
            ? nameOfClassWithManager
            : `isdayoff ${nameOfClassWithManager}`;
          const icon = cell.row.original.avatarUrl
            ? { src: cell.row.original.avatarUrl['32x32'] }
            : { icon: 'user' };

          const seconds = calculateCellSeconds(cell.value);
          const cellProjectType =
            Array.isArray(cell.value) &&
            (getCell(cell.value, RM_PLAN_TYPES.SICKLEAVE) ||
              getCell(cell.value, RM_PLAN_TYPES.VACATION) ||
              getCell(cell.value, RM_PLAN_TYPES.PROJECT));
          const cellOtherType =
            Array.isArray(cell.value) &&
            getCell(cell.value, RM_PLAN_TYPES.BENCH);
          const cellTypeForContent = cellProjectType || cellOtherType;
          const cellTypeForStyles = cellProjectType || cellOtherType;
          const cellValue =
            cellTypeForContent &&
            timeView(cellTypeForContent.planItem.type, seconds);

          const formatedCellValue =
            isNumber(cellValue) && cellValue % 1
              ? cellValue.toFixed(1)
              : cellValue;

          const technologiesNames = cell.row.original.technologies
            ?.map(item => get(item, 'title'))
            .join(' ');

          const projectsNames = findProjectsNames(cell, projects);

          const projectsPopoverContent = (
            <ul className={styles.techList}>
              {projectsNames &&
                projectsNames.map(item => (
                  <li style={{ listStyleType: 'none' }} key={item.name}>
                    <Badge
                      className={styles.nameTech}
                      status={colorBadge(item.name)}
                      text={`${item.name} ${item.time}`}
                    />
                  </li>
                ))}
            </ul>
          );

          const techPopoverContent = (
            <ul className={styles.techList}>
              {cell.row.original.technologies?.map(item => (
                <li style={{ listStyleType: 'none' }} key={item.title}>
                  <Badge
                    className={styles.nameTech}
                    status="success"
                    text={item.title}
                  />
                </li>
              ))}
            </ul>
          );

          const colorStyle =
            cellTypeForStyles &&
            (!cell.column.isDayOff ||
              cellTypeForContent.includeNonWorkingDays) &&
            cellColor(cellTypeForStyles);
          const cellStyle = `${styles.cell} ${colorStyle}`;
          const viewCell = (
            <div
              className={cellStyle}
              key={key}
              row={cell.row.original.jiraId}
              column={cell.column.valueColum}
            >
              {cell.column.Header !== EMPLOYEE_COLUMN_KEY &&
                (!cell.column.isDayOff
                  ? formatedCellValue
                  : cellTypeForContent.includeNonWorkingDays &&
                    formatedCellValue)}
            </div>
          );

          return (
            <div {...props} className={nameOfClass} key={key}>
              {cell.column.Header === EMPLOYEE_COLUMN_KEY && (
                <Fragment>
                  <span className={styles.iconPlus}>
                    {isOpenTree && <Icon type="down" />}
                    {!isOpenTree && <Icon type="right" />}
                  </span>
                  <div className={styles.userTitle}>
                    <span style={{ margin: '8px' }}>
                      <Badge
                        style={{ backgroundColor: 'green' }}
                        dot={cell.row.original.isCurUser}
                      >
                        <Avatar
                          shape="square"
                          className={styles.avatar}
                          {...icon}
                        />
                      </Badge>
                    </span>
                    <span style={{ width: '100%' }}>
                      <span className={styles.nameUser}>{cell.value}</span>
                      <Popover
                        content={techPopoverContent}
                        placement="bottom"
                        trigger="hover"
                        className={styles.technologiesPopover}
                        mouseEnterDelay={0.5}
                      >
                        <span className={styles.positionTitle}>
                          {technologiesNames}
                        </span>
                      </Popover>
                    </span>
                  </div>

                  {isHover && canUpdatePlans ? (
                    <span className={styles.timeField}>
                      <Link to={LINKS.summaryEmployee(cell.row.original.id)}>
                        Profile
                      </Link>
                    </span>
                  ) : (
                    <span className={styles.timeField}>{totalTime}</span>
                  )}
                </Fragment>
              )}
              {formatedCellValue ? (
                <Popover
                  content={projectsPopoverContent}
                  placement="bottom"
                  trigger="hover"
                  mouseEnterDelay={0.5}
                >
                  {viewCell}
                </Popover>
              ) : (
                cell.column.Header !== EMPLOYEE_COLUMN_KEY && viewCell
              )}
            </div>
          );
        })}
      </div>
      {row.original.projects.map(
        item =>
          isOpenTree && (
            <div key={item.name} className="tr">
              {row.cells.map(cell => {
                const valueColum = cell.column.valueColum;
                const width = calculateCellWidth(
                  cell.column.Header === EMPLOYEE_COLUMN_KEY,
                  numberDaysOfMonth,
                );
                const subFieldsClassName = cell.column.isDayOff
                  ? 'td isdayoff'
                  : 'td';
                const key = `${cell.row.id}-${cell.column.Header}`;
                const commonProps = {
                  onClick: onClickCell,
                  projectid: item.key,
                  row: cell.row.original.jiraId,
                  column: valueColum,
                };
                const props = {
                  className: subFieldsClassName,
                  style: {
                    position: 'relative',
                    width,
                    fontSize:
                      cell.column.Header !== EMPLOYEE_COLUMN_KEY && '11px',
                  },
                  key,
                  ...commonProps,
                };
                const contentCell = cell.row.original[valueColum];
                const hoverCell =
                  contentCell &&
                  typeof contentCell === 'object' &&
                  contentCell.find(plan =>
                    plan.planItem.type === RM_PLAN_TYPES.PROJECT
                      ? plan.planItem.id === item.key
                      : plan.planItem.type === item.key,
                  );

                const content = (
                  <div {...props}>
                    {cell.row.original.projects.map(it => {
                      const view =
                        it[valueColum] && typeof it[valueColum] === 'object'
                          ? timeView(
                              it[valueColum].planItem.type,
                              it[valueColum].seconds,
                            )
                          : it[valueColum];
                      const viewConditions =
                        cell.column.Header === EMPLOYEE_COLUMN_KEY ||
                        item.key !== it.key ||
                        !view;
                      const iconCondition =
                        cell.column.Header === EMPLOYEE_COLUMN_KEY &&
                        view !== 'BENCH' &&
                        view !== 'SICKLEAVE' &&
                        view !== 'VACATION';

                      return viewConditions ? (
                        item.key === it.key && (
                          <span key={it.key} style={{ marginLeft: '20px' }}>
                            {iconCondition && (
                              <Avatar
                                style={{ marginRight: '10px' }}
                                shape="square"
                                size={20}
                                src={projectIcon}
                              />
                            )}
                            {view}
                          </span>
                        )
                      ) : (
                        <PopoverCell
                          key={it.key}
                          deletePlanById={deletePlanById}
                          deletePlanByType={deletePlanByType}
                          plans={plans}
                          hoverCell={hoverCell}
                          view={view % 1 ? view.toFixed(1) : view}
                          commonProps={{
                            ...commonProps,
                            planid: it[valueColum].id,
                            plantype: it[valueColum].planItem.type,
                          }}
                          condition={item.key === it.key}
                          cellContent={it[valueColum]}
                          endDate={endDate}
                          startDate={startDate}
                          canUpdatePlans={canUpdatePlans}
                          canUpdateDeals={canUpdateDeals}
                        />
                      );
                    })}
                  </div>
                );

                return content;
              })}
            </div>
          ),
      )}
    </Fragment>
  );
};

TableRow.propTypes = {
  rows: PropTypes.array,
  indexRows: PropTypes.array,
  plans: PropTypes.array,
  prepareRow: PropTypes.func.isRequired,
  index: PropTypes.number,
  onCloseOpenTree: PropTypes.func.isRequired,
  deletePlanById: PropTypes.func.isRequired,
  deletePlanByType: PropTypes.func.isRequired,
  onClickCell: PropTypes.func.isRequired,
  numberDaysOfMonth: PropTypes.number,
  calendar: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  canUpdatePlans: PropTypes.bool,
  canUpdateDeals: PropTypes.bool,
  projects: PropTypes.array,
};

export default TableRow;
