import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Checkbox, Radio, Form, Avatar } from 'antd';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import {
  RM_PLAN_TYPES,
  PLAN_TYPES_WITH_PROJECT,
  RM_PERMISSIONS,
} from 'containers/ResourceManagementPage/constants';
import RangePicker from 'components/Common/RangePicker';
import DatePicker from 'components/Common/DatePicker';
import { getPopupContainerHandler } from 'utils/helpers';
import { DATE_FORMAT } from 'utils/timeConstants';
import { getRmDealAllocations } from 'redux/Deals/actions';

import { hasRights } from 'utils/permissions';
import { ProjectSelect, AllocationSelect } from '../index';
import { EXP_REG } from './constants';
import styles from './styles.scss';

const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;

const PlanTimeForm = ({
  fieldValues,
  projects,
  jiraUsers,
  onFinish,
  dealAllocations,
  role,
  isUpdatePlan,
}) => {
  const [isVisibleEndDate, setVisible] = useState(true);
  const [planType, setPlanType] = useState(fieldValues.planType);
  const [project, setProject] = useState(fieldValues.project);
  const [dealAllocationId, setDealAllocationId] = useState(
    fieldValues.dealAllocationId,
  );
  const [date, setDate] = useState(fieldValues.data);
  const [period, setPeriod] = useState([fieldValues.data, fieldValues.endData]);
  const [timeType, setTimeType] = useState('Per day');

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const onChangePlanType = event => {
    const { value } = event.target;
    setPlanType(value);

    if (value === RM_PLAN_TYPES.SICKLEAVE || value === RM_PLAN_TYPES.VACATION) {
      form.setFieldsValue({ includeNonWorkingDays: true });
    } else {
      form.setFieldsValue({ includeNonWorkingDays: false });
    }
  };

  const onChangeVisibleEndDate = () => setVisible(prev => !prev);

  const filterOption = (input, option) =>
    option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.props.children[3].props.children[1]
      .toLowerCase()
      .indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    setProject(fieldValues.project);
    setDealAllocationId(fieldValues.dealAllocationId);
  }, [fieldValues]);

  useEffect(() => {
    if (planType === RM_PLAN_TYPES.DEALS && project) {
      dispatch(
        getRmDealAllocations({
          date: dayjs(isVisibleEndDate ? period[0] : date).format(DATE_FORMAT),
          projectId: project,
        }),
      );
    }
  }, [date, project, planType, period]);

  const onDateChange = value => {
    setDate(value);
  };

  const onPeriodChange = value => {
    setPeriod(value);
  };

  const onBlurPlannedTime = () => {
    const timeValue = form.getFieldValue('plannedTime');

    if (!Number.isNaN(Number(timeValue))) {
      form.setFieldValue('plannedTime', `${timeValue}h`);
    }
  };

  const onValuesChange = updatedValues => {
    if ('dealAllocationId' in updatedValues) {
      setDealAllocationId(updatedValues.dealAllocationId);
    }

    if ('project' in updatedValues) {
      setProject(updatedValues.project);
      form.setFieldValue('dealAllocationId', null);
      setDealAllocationId(null);
    }
  };

  const onChangeTimeType = value => setTimeType(value);

  const timeValidation = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('This field is required'));
    }

    if (!EXP_REG.test(value)) {
      return Promise.reject(new Error('Wrong time format'));
    }

    return Promise.resolve();
  };

  return (
    <Form
      layout="vertical"
      className={styles.form}
      onFinish={onFinish}
      id="planTime"
      fields={[
        { name: 'planType', value: planType },
        { name: 'project', value: project },
        { name: 'dealAllocationId', value: dealAllocationId },
        { name: 'dividedTime', value: timeType },
      ]}
      initialValues={{
        includeNonWorkingDays: false,
        rangeDate: [fieldValues.data, fieldValues.data],
        name: fieldValues.name,
        data: fieldValues.data,
        plannedTime: fieldValues.plannedTime,
        dealAllocationId: dealAllocationId,
      }}
      form={form}
      onValuesChange={onValuesChange}
    >
      <Item className={styles.formItem} label="Full name (eng)" name="name">
        <Select
          showSearch
          style={{ width: '100%' }}
          optionFilterProp="children"
          filterOption={filterOption}
          getPopupContainer={getPopupContainerHandler}
        >
          {jiraUsers.map(item => (
            <Option
              key={get(item, ['jira_user', 'id'], '')}
              value={get(item, ['jira_user', 'id'], '')}
            >
              <Avatar
                style={{ marginRight: '10px' }}
                shape="square"
                size={20}
                src={get(item, ['jira_user', 'avatarUrls', '32x32'], '')}
              />
              {item.name_en}{' '}
              <span style={{ fontWeight: 600 }}>
                ({item.jira_user && item.jira_user.id})
              </span>
            </Option>
          ))}
        </Select>
      </Item>
      <Item className={styles.formItem} label="Plan type" name="planType">
        <Radio.Group
          buttonStyle="solid"
          className={styles.radioGroup}
          onChange={onChangePlanType}
          disabled={isUpdatePlan}
        >
          <Radio.Button
            value={RM_PLAN_TYPES.DEALS}
            className={styles.fieldTypeButton}
            disabled={!hasRights(role, RM_PERMISSIONS.updateProjectPlan)}
          >
            {RM_PLAN_TYPES.DEALS}
          </Radio.Button>
          <Radio.Button
            value={RM_PLAN_TYPES.SICKLEAVE}
            className={styles.fieldTypeButton}
          >
            {RM_PLAN_TYPES.SICKLEAVE_TITLE}
          </Radio.Button>
          <Radio.Button
            value={RM_PLAN_TYPES.BENCH}
            className={styles.fieldTypeButton}
          >
            {RM_PLAN_TYPES.BENCH}
          </Radio.Button>
          <Radio.Button
            value={RM_PLAN_TYPES.VACATION}
            className={styles.fieldTypeButton}
          >
            {RM_PLAN_TYPES.VACATION}
          </Radio.Button>
        </Radio.Group>
      </Item>
      {PLAN_TYPES_WITH_PROJECT.includes(planType) && (
        <ProjectSelect data={projects} />
      )}
      {planType === RM_PLAN_TYPES.DEALS && (
        <AllocationSelect data={dealAllocations} />
      )}
      <Item className={styles.formItem} label="Description" name="description">
        <TextArea rows={3} />
      </Item>
      <Item className={styles.fieldPeriod} name="period">
        <Checkbox onChange={onChangeVisibleEndDate} checked={isVisibleEndDate}>
          Period
        </Checkbox>
      </Item>
      <Item name="includeNonWorkingDays" valuePropName="checked">
        <Checkbox>Include non-working days</Checkbox>
      </Item>
      {isVisibleEndDate && (
        <Item name="rangeDate" label="Date">
          <RangePicker
            picker="date"
            getPopupContainer={getPopupContainerHandler}
            onChange={onPeriodChange}
          />
        </Item>
      )}
      {!isVisibleEndDate && (
        <Item label="Date" className={styles.fieldStartData} name="data">
          <DatePicker
            className={styles.input}
            placeholder="Select date..."
            name="data"
            getPopupContainer={getPopupContainerHandler}
            onChange={onDateChange}
          />
        </Item>
      )}
      <div className={styles.fieldTime}>
        <Item
          label="Planned time"
          className={styles.fieldPlannedTime}
          name="plannedTime"
          rules={[
            {
              required: true,
              validator: timeValidation,
            },
          ]}
        >
          <Input placeholder="1h 10m" onBlur={onBlurPlannedTime} />
        </Item>
        {isVisibleEndDate && (
          <Item label="" className={styles.fieldDividedTime} name="dividedTime">
            <Select
              onChange={onChangeTimeType}
              getPopupContainer={getPopupContainerHandler}
            >
              <Option value="Per day">Per day</Option>
              <Option value="Total">Total</Option>
            </Select>
          </Item>
        )}
      </div>
    </Form>
  );
};

PlanTimeForm.propTypes = {
  fieldValues: PropTypes.object,
  projects: PropTypes.array,
  onFinish: PropTypes.func,
  jiraUsers: PropTypes.array,
  dealAllocations: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  isUpdatePlan: PropTypes.bool.isRequired,
};

export default PlanTimeForm;
