/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Input, Button, Dropdown } from 'antd';
import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';

import {
  LEAD_AUDIT_COLORS,
  LEAD_PERMISSIONS,
} from 'containers/LeadPage/constants';
import Tag from 'components/Common/Tag';
import { FULL_DATE_FORMAT } from 'utils/timeConstants';
import { hasRights } from 'utils/permissions';

import { ACTIONS } from './constants';
import styles from './styles.scss';

const NoteItem = ({ event, updateLeadNote, role, notesObject }) => {
  const {
    audited_changes: { text },
    auditable_id,
    action,
    created_at,
  } = event;
  const [isNoteClicked, setIsNoteClicked] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isInputValueChanged, setIsInputValueChanged] = useState(false);

  const onNoteClicked = () =>
    hasRights(role, LEAD_PERMISSIONS.updateNote) && setIsNoteClicked(true);

  const onCancelClicked = () => {
    setIsNoteClicked(false);
    setIsInputValueChanged(false);

    if (action === 'update') {
      setInputValue(text[1]);
    } else {
      setInputValue(text);
    }
  };

  const onUpdateNote = () => {
    setIsNoteClicked(false);
    updateLeadNote({ text: inputValue, noteId: auditable_id });
    setIsInputValueChanged(false);
  };

  const onChangeNote = ({ target: { value } }) => {
    setInputValue(value);
    setIsInputValueChanged(true);
  };

  useEffect(() => {
    if (action === 'update') {
      setInputValue(text[1]);
    } else {
      setInputValue(text);
    }
  }, [text]);

  const note = notesObject[auditable_id];

  const createdAt = dayjs(created_at).format(FULL_DATE_FORMAT);

  const dropdownItems = [
    {
      label: 'Edit',
      key: 'Edit',
      onClick: onNoteClicked,
      icon: <EditOutlined />,
    },
  ];

  return (
    <div>
      <div className={styles.noteContainer}>
        <div className={styles.noteInfo}>
          <div>
            Note <Tag color={LEAD_AUDIT_COLORS[action]}>{ACTIONS[action]}</Tag>
          </div>
          <Dropdown menu={{ items: dropdownItems }}>
            <EllipsisOutlined className={styles.noteMenu} />
          </Dropdown>
        </div>
        <div className={styles.content}>
          {isNoteClicked ? (
            <>
              <Input.TextArea
                value={inputValue}
                rows={3}
                onChange={onChangeNote}
              />
              <div className={styles.noteButtons}>
                <Button size="small" onClick={onCancelClicked}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  type="primary"
                  className={styles.saveButton}
                  onClick={onUpdateNote}
                  disabled={!isInputValueChanged}
                >
                  Save
                </Button>
              </div>
            </>
          ) : (
            <p>{action === 'update' ? text[1] : text}</p>
          )}
          <span className={styles.created}>{`Created by: ${
            note.owner_name
          } ${createdAt}`}</span>
        </div>
      </div>
    </div>
  );
};

NoteItem.propTypes = {
  event: PropTypes.object,
  notesObject: PropTypes.object,
  updateLeadNote: PropTypes.func,
  role: PropTypes.string,
};

export default NoteItem;
