import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { uniqueId } from 'lodash';

import { firebasePath } from 'containers/ProjectProfits/constants';
import { Loader, Table } from 'components/Common';
import PageHeader from 'components/Common/PageHeader';
import DatePicker from 'components/Common/DatePicker';
import { getFiltersWithOutNull } from 'utils/common';
import {
  DATE_FORMAT,
  DATE_MONTH_FORMAT,
  MONTH_FORMAT,
} from 'utils/timeConstants';
import { costRatesSelector } from 'redux/selectors';
import { getSubcontractors } from 'redux/CostRates/actions';

import { PERIOD_FORMAT } from './constants';
import { getColumns, getSummary } from './utils';
import firebase from '../../firebase';

let unsubscribe = null;

async function getFirebaseData(month, setCalculateProgress) {
  const db = firebase.firestore();

  const query = dayjs(month)
    .startOf('month')
    .format(DATE_FORMAT);

  unsubscribe = db
    .collection(firebasePath)
    .doc(query)
    .onSnapshot(doc => {
      setCalculateProgress(doc.data());
    });
}

const ReportSubcontractors = () => {
  const [period, setPeriod] = useState(dayjs(new Date()).format(PERIOD_FORMAT));
  const [filterInfo, setFilterInfo] = useState({});
  const [sortInfo, setSortInfo] = useState({
    order: 'ascend',
    field: 'project_name',
  });
  const [columnsSettings, setColumnsSettings] = useState([]);
  const [calculateProgress, setCalculateProgress] = useState(null);

  const dispatch = useDispatch();

  const {
    isCostRatesLoading,
    subcontractors,
    subcontractorsFilters,
  } = useSelector(costRatesSelector);

  const columns = useMemo(
    () => getColumns({ filterInfo, sortInfo, filters: subcontractorsFilters }),
    [subcontractors, period, filterInfo, sortInfo, subcontractorsFilters],
  );

  const onChangePeriod = period => {
    if (period) {
      setPeriod(dayjs(period).format(PERIOD_FORMAT));
    }
  };

  const onTableChange = (pagination, filters, sorter) => {
    const filtersWithOutNull = getFiltersWithOutNull(filters);
    setFilterInfo(filtersWithOutNull);
    setSortInfo(sorter);
    dispatch(
      getSubcontractors({
        period,
        filters: filtersWithOutNull,
        sort: `${sorter.field}:${sorter.order}`,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      getSubcontractors({
        period,
        filters: filterInfo,
        sort: `${sortInfo.field}:${sortInfo.order}`,
      }),
    );

    unsubscribe && unsubscribe();
    getFirebaseData(period, setCalculateProgress);

    return () => unsubscribe();
  }, [period]);

  useEffect(() => {
    const settings = JSON.parse(
      localStorage.getItem(`${window.location.pathname}-`),
    );

    const modifiedColumns = settings
      ? columns.map(column => ({
          ...column,
          isShowColumn: settings[column.dataIndex]?.isShowColumn,
          width: settings[column.dataIndex]?.width,
        }))
      : columns.map(column => ({
          ...column,
          isShowColumn: column.isShowColumn !== false,
        }));

    setColumnsSettings(modifiedColumns);
  }, []);

  const getSummaryRow = data => getSummary(data, columns, columnsSettings);

  return (
    <Fragment>
      <PageHeader
        title="Report: Subcontractors"
        subTitle={`Last updated: ${dayjs(
          calculateProgress?.updated_at.seconds * 1000,
        ).format(DATE_MONTH_FORMAT)}`}
        extra={
          <DatePicker
            picker="month"
            onChange={onChangePeriod}
            defaultValue={dayjs(period, PERIOD_FORMAT)}
            format={MONTH_FORMAT}
          />
        }
      />
      <Loader loading={isCostRatesLoading}>
        <Table
          bordered
          columns={columns}
          dataSource={subcontractors}
          pagination={false}
          onChange={onTableChange}
          size="small"
          summary={getSummaryRow}
          setColumnsSettings={setColumnsSettings}
          rowKey={uniqueId}
        />
      </Loader>
    </Fragment>
  );
};

export default ReportSubcontractors;
