import { ROLE_TYPES } from 'utils/permissions';

export const DATE_FORMAT = 'YYYY-MM-DD HH-mm';

export const STATUS_TAG_COLOR = {
  NEW: 'green',
  SQL: 'cyan',
  MQL: 'blue',
  OPPORTUNITY: 'purple',
  CLIENT: 'gold',
  UNQUALIFIED: 'orange',
};

export const STATUSES = [
  'NEW',
  'SQL',
  'MQL',
  'OPPORTUNITY',
  'CLIENT',
  'UNQUALIFIED',
];

export const LEAD_TABS = {
  all: 'All',
  notes: 'Notes',
};

export const LEAD_PERMISSIONS = {
  updateNote: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
};

export const LEAD_AUDIT_COLORS = {
  create: 'green',
  update: 'blue',
  destroy: 'red',
};
