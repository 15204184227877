import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from 'antd';

const { Item } = Form;

const ExchangeRatesForm = ({ onSubmit, form }) => (
  <Form layout="vertical" onFinish={onSubmit} form={form}>
    <Item
      label="USD"
      key="USD"
      name="value"
      rules={[
        {
          required: true,
          message: 'Required field',
        },
        {
          pattern: /^[0-9]+(\.[0-9]+)?$/gim,
          message: 'Invalid format',
        },
        {
          pattern: /^(-)?(\d)*(\.)?([0-9]{1,4})?$/,
          message: 'No more than four decimal places',
        },
      ]}
    >
      <Input />
    </Item>
  </Form>
);

ExchangeRatesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default ExchangeRatesForm;
