import { capitalize } from 'lodash';

export const SAGA_MESSAGES = {
  ERROR: 'Error',
  SUCCESS: 'Successfully',
  LOAD_DATA_ERROR: 'Failed to load data',
  INVALID_USERNAME_PASSWORD: 'Invalid username or password',
  ADD_BANK_SUCCESS: 'Banks added successfully',
  ADD_BANK_FAILED: 'Failed to add bank',

  UPDATE_BANK_SUCCESS: 'Banks updated successfully',
  UPDATE_BANK_FAILED: 'Failed to update bank',

  DELETE_BANK_SUCCESS: 'Banks deleted successfully',
  DELETE_BANK_FAILED: 'Failed to delete bank',

  ADD_NOTE_SUCCESS: 'Note added successfully',
  ADD_NOTE_FAILED: 'Failed to add note',
  UPDATE_NOTE_FAILED: 'Failed to update note',
  DELETE_NOTE_FAILED: 'Failed to delete note',
  UPDATE_CLIENT_SUCCESS: 'Client updated successfully',
  UPDATE_CLIENT_FAILED: 'Failed to update client',
  DELETE_CLIENT_SUCCESS: 'Client deleted successfuly',
  DELETE_CLIENT_FAILED: 'Failed to delete client',
  RESTORE_CLIENT_SUCCESS: 'Client restored successfuly',
  RESTORE_CLIENT_FAILED: 'Failed to restore client',
  ADD_ORGANIZATION_SUCCESS: 'Organization added successfully',
  ADD_ORGANIZATION_FAILED: 'Failed to add organization',
  UPDATE_CONTRACT_SUCCESS: 'Contract was updated successfully!',
  UPDATE_CONTRACT_FAILED: 'Failed to update contract',
  UPDATE_ALLOCATIONS_SUCCESS: 'Allocations updated successfully',
  CREATE_DEAL_SUCCESS: 'The deal was saved successfully',
  CREATE_DEAL_FAILED: 'Failed to create deal',
  UPDATE_DEAL_SUCCESS: 'Deal updated successfully',
  UPDATE_DEAL_FAILED: 'Failed to update deal',
  DELETE_DEAL_SUCCESS: 'The deal was deleted successfully',
  DELETE_DEAL_FAILED: 'Failed to delete deal',
  CREATE_INVOICE_SUCCESS: 'Invoice was created successfully',
  CREATE_INVOICE_FAILED: 'Failed to create invoice',
  DELETE_INVOICE_FAILED: 'Failed to delete invoice',
  CREATE_CONTRACT_SUCCESS: 'Contract was created successfully!',
  CREATE_CONTRACT_FAILED: 'Failed to create contract',
  DELETE_CONTRACT_SUCCESS: 'Contract was deleted successfully!',
  DELETE_CONTRACT_FAILED: 'Failed to delete contract',
  UPDATE_EMPLOYEE_SUCCESS: 'Employee updated successfully',
  UPDATE_EMPLOYEE_FAILED: 'Failed to update employee',
  UPDATE_INVOICE_SUCCESS: 'Invoice was updated',
  APPROVE_INVOICE: 'Invoice was aproved',
  DECLINE_INVOICE: 'Invoice was declined',
  LOAD_NOTIFICATIONS_FAILED: 'Failed to load notifications',
  UPDATING_NOTIFICATIONS: 'Updating notifications',
  CREATE_ORGANIZATIONS_SUCCESS: 'Organization has been created',
  CREATE_ORGANIZATIONS_FAILED: 'Failed to create organization',
  UPDATE_ORGANIZATIONS_SUCCESS: 'Organization was successful updated',
  UPDATE_ORGANIZATIONS_FAILED: 'Failed to update organization',
  DELETE_ORGANIZATIONS_SUCCESS: 'Organization was deleted',
  CREATE_ACCOUNT_SUCCESS: 'Account created',
  UPDATE_ACCOUNT_SUCCESS: 'Account updated',
  UPDATE_ACCOUNT_FAILED: 'Failed to update account',
  ADD_EXTRA_PAYMENT_SUCCESS: 'Extra payment was added',
  CALCULATE_PROJECTS_FAILES: 'Failed to calculate projects',
  CREATE_USER_SUCCESS: 'User created successfully',
  UPDATE_USER_SUCCESS: 'User updated successfully',
  DELETE_USER_SUCCESS: 'User deleted successfully',
  RESTORE_USER_SUCCESS: 'User restored successfully',
  IMPORT_EMPLOYEES_SUCCESS: 'Employees imported successfully',
  CREATE_EMPLOYEE_FAILED: 'Failed to create employee',
  IMPORT_EMPLOYEES_FAILED: 'Failed to import employees',
  DELETE_EMPLOYEE_ATTACHMENT_SUCCESS: 'Attachment deleted successfully',
  DELETE_EMPLOYEE_ATTACHMENT_FAILED: 'Failed to delete attachment',
  CREATE_ROLE_SUCCESS: 'Role created succeessfully',
  CREATE_ROLE_FAILED: 'Failed to create role',
  UPDATE_ROLE_SUCCESS: 'Role updated succeessfully',
  UPDATE_ROLE_FAILED: 'Failed to update role',
  DELETE_ROLE_SUCCESS: 'Role deleted succeessfully',
  DELETE_ROLE_FAILED: 'Failed to delete role',
  UPDATE_LEAD_SUCCESS: 'Lead updated successfully',
  UPDATE_LEAD_FAILED: 'Failed to update lead',
  CREATE_SALARY_SUCCESS: 'Salary created successfully',
  CREATE_SALARY_FAILED: 'Failed to create salary',
  UPDATE_SALARY_SUCCESS: 'Salary updated successfully',
  UPDATE_SALARY_FAILED: 'Failed to update salary',
  DELETE_SALARY_SUCCESS: 'Salary deleted successfully',
  DELETE_SALARY_FAILED: 'Failed to delete salary',
  UPDATE_EXTRA_PAYMENT_SUCCESS: 'Extra payment updated successfully',
  UPDATE_EXTRA_PAYMENT_FAILED: 'Failed to update extra payment',
  DELETE_EXTRA_PAYMENT_SUCCESS: 'Extra payment deleted successfully',
  DELETE_EXTRA_PAYMENT_FAILED: 'Failed to delete extra payment',
  CREATE_EXTRA_PAYMENT_SUCCESS: 'Extra payment created successfully',
  CREATE_EXTRA_PAYMENT_FAILED: 'Failed to create extra payment',
  UPDATE_TECHNOLOGY_SUCCESS: 'Technology updated successfully',
  UPDATE_TECHNOLOGY_FAILED: 'Failed to update technology',
  DELETE_TECHNOLOGY_SUCCESS: 'Technology deleted successfully',
  DELETE_TECHNOLOGY_FAILED: 'Failed to delete technology',
  CREATE_TECHNOLOGY_SUCCESS: 'Technology created successfully',
  CREATE_TECHNOLOGY_FAILED: 'Failed to create technology',
  CREATE_SOCIAL_REQUEST_SUCCESS: 'Social request created successfully',
  CREATE_SOCIAL_REQUEST_FAILED: 'Failed to create social request',
  UPDATE_SOCIAL_REQUEST_SUCCESS: 'Social request updated successfully',
  UPDATE_SOCIAL_REQUEST_FAILED: 'Failed to update social request',
  DELETE_SOCIAL_REQUEST_SUCCESS: 'Social request deleted successfully',
  DELETE_SOCIAL_REQUEST_FAILED: 'Failed to delete social request',
  APPROVE_SOCIAL_REQUEST_SUCCESS: 'Social request approved successfully',
  APPROVE_SOCIAL_REQUEST_FAILED: 'Failed to approve social request',
  DECLINE_SOCIAL_REQUEST_SUCCESS: 'Social request declined successfully',
  DECLINE_SOCIAL_REQUEST_FAILED: 'Failed to decline social request',
};

export const sagaMessageGenerator = {
  success: (entity, action) => `${capitalize(entity)} ${action}d successfully`,
  failed: (entity, action) => `Failed to ${action} ${capitalize(entity)}`,
};
