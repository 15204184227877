export const types = {
  GET_INVOICES: 'invoices/GET_INVOICES',
  GET_INVOICES_SUCCESS: 'invoices/GET_INVOICES_SUCCESS',
  GET_INVOICES_FAILED: 'invoices/GET_INVOICES_FAILED',

  GET_INVOICE: 'invoices/GET_INVOICE',
  GET_INVOICE_SUCCESS: 'invoices/GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILED: 'invoices/GET_INVOICE_FAILED',

  APPROVE_INVOICE: 'invoices/APPROVE_INVOICE',
  APPROVE_INVOICE_SUCCESS: 'invoices/APPROVE_INVOICE_SUCCESS',
  APPROVE_INVOICE_FAILED: 'invoices/APPROVE_INVOICE_FAILED',

  DECLINE_INVOICE: 'invoices/DECLINE_INVOICE',
  DECLINE_INVOICE_SUCCESS: 'invoices/DECLINE_INVOICE_SUCCESS',
  DECLINE_INVOICE_FAILED: 'invoices/DECLINE_INVOICE_FAILED',

  UPDATE_INVOICE: 'invoices/UPDATE_INVOICE',
  UPDATE_INVOICE_SUCCESS: 'invoices/UPDATE_INVOICE_SUCCESS',
  UPDATE_INVOICE_FAILED: 'invoices/UPDATE_INVOICE_FAILED',

  DELETE_INVOICE: 'invoices/DELETE_INVOICE',
  DELETE_INVOICE_SUCCESS: 'invoices/DELETE_INVOICE_SUCCESS',
  DELETE_INVOICE_FAILED: 'invoices/DELETE_INVOICE_FAILED',

  GET_INVOICE_STATUSES: 'invoices/GET_INVOICE_STATUSES',
  GET_INVOICE_STATUSES_SUCCESS: 'invoices/GET_INVOICE_STATUSES_SUCCESS',
  GET_INVOICE_STATUSES_FAILED: 'invoices/GET_INVOICE_STATUSES_FAILED',

  GET_INVOICE_TEMPLATES: 'invoices/GET_INVOICE_TEMPLATES',
  GET_INVOICE_TEMPLATES_SUCCESS: 'invoices/GET_INVOICE_TEMPLATES_SUCCESS',
  GET_INVOICE_TEMPLATES_FAILED: 'invoices/GET_INVOICE_TEMPLATES_FAILED',

  GET_INVOICE_AUDIT: 'invoices/GET_INVOICE_AUDIT',
  GET_INVOICE_AUDIT_SUCCESS: 'invoices/GET_INVOICE_AUDIT_SUCCESS',
  GET_INVOICE_AUDIT_FAILED: 'invoices/GET_INVOICE_AUDIT_FAILED',
};
