export const types = {
  GET_SOCIAL_REQUESTS: 'social-requests/GET_SOCIAL_REQUESTS',
  GET_SOCIAL_REQUESTS_SUCCESS: 'social-requests/GET_SOCIAL_REQUESTS_SUCCESS',
  GET_SOCIAL_REQUESTS_FAILED: 'social-requests/GET_SOCIAL_REQUESTS_FAILED',

  GET_SOCIAL_REQUESTS_INFO: 'social-requests/GET_SOCIAL_REQUESTS_INFO',
  GET_SOCIAL_REQUESTS_INFO_SUCCESS:
    'social-requests/GET_SOCIAL_REQUESTS_INFO_SUCCESS',
  GET_SOCIAL_REQUESTS_INFO_FAILED:
    'social-requests/GET_SOCIAL_REQUESTS_INFO_FAILED',

  GET_SOCIAL_REQUEST_TYPES: 'social-requests/GET_SOCIAL_REQUEST_TYPES',
  GET_SOCIAL_REQUEST_TYPES_SUCCESS:
    'social-requests/GET_SOCIAL_REQUEST_TYPES_SUCCESS',
  GET_SOCIAL_REQUEST_TYPES_FAILED:
    'social-requests/GET_SOCIAL_REQUEST_TYPES_FAILED',

  GET_SOCIAL_REQUEST_STATUSES: 'social-requests/GET_SOCIAL_REQUEST_STATUSES',
  GET_SOCIAL_REQUEST_STATUSES_SUCCESS:
    'social-requests/GET_SOCIAL_REQUEST_STATUSES_SUCCESS',
  GET_SOCIAL_REQUEST_STATUSES_FAILED:
    'social-requests/GET_SOCIAL_REQUEST_STATUSES_FAILED',

  CREATE_SOCIAL_REQUEST: 'social-requests/CREATE_SOCIAL_REQUEST',
  CREATE_SOCIAL_REQUEST_SUCCESS:
    'social-requests/CREATE_SOCIAL_REQUEST_SUCCESS',
  CREATE_SOCIAL_REQUEST_FAILED: 'social-requests/CREATE_SOCIAL_REQUEST_FAILED',

  UPDATE_SOCIAL_REQUEST: 'social-requests/UPDATE_SOCIAL_REQUEST',
  UPDATE_SOCIAL_REQUEST_SUCCESS:
    'social-requests/UPDATE_SOCIAL_REQUEST_SUCCESS',
  UPDATE_SOCIAL_REQUEST_FAILED: 'social-requests/UPDATE_SOCIAL_REQUEST_FAILED',

  DELETE_SOCIAL_REQUEST: 'social-requests/DELETE_SOCIAL_REQUEST',
  DELETE_SOCIAL_REQUEST_SUCCESS:
    'social-requests/DELETE_SOCIAL_REQUEST_SUCCESS',
  DELETE_SOCIAL_REQUEST_FAILED: 'social-requests/DELETE_SOCIAL_REQUEST_FAILED',

  APPROVE_SOCIAL_REQUEST: 'social-requests/APPROVE_SOCIAL_REQUEST',
  APPROVE_SOCIAL_REQUEST_SUCCESS:
    'social-requests/APPROVE_SOCIAL_REQUEST_SUCCESS',
  APPROVE_SOCIAL_REQUEST_FAILED:
    'social-requests/APPROVE_SOCIAL_REQUEST_FAILED',

  DECLINE_SOCIAL_REQUEST: 'social-requests/DECLINE_SOCIAL_REQUEST',
  DECLINE_SOCIAL_REQUEST_SUCCESS:
    'social-requests/DECLINE_SOCIAL_REQUEST_SUCCESS',
  DECLINE_SOCIAL_REQUEST_FAILED:
    'social-requests/DECLINE_SOCIAL_REQUEST_FAILED',

  UPLOAD_SOCIAL_REQUEST_ATTACHMENT:
    'social-requests/UPLOAD_SOCIAL_REQUEST_ATTACHMENT',
  UPLOAD_SOCIAL_REQUEST_ATTACHMENT_SUCCESS:
    'social-requests/UPLOAD_SOCIAL_REQUEST_ATTACHMENT_SUCCESS',
  UPLOAD_SOCIAL_REQUEST_ATTACHMENT_FAILED:
    'social-requests/UPLOAD_SOCIAL_REQUEST_ATTACHMENT_FAILED',
};
