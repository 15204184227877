exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".clientProjectItem-03ef5{position:relative;display:flex;justify-content:space-between;align-items:center;margin-bottom:12px;padding:16px 20px;border:1px solid #d9d9d9;border-radius:8px}.clientProjectItem-03ef5:hover .clientProjectIcons-a4f9b{visibility:visible}.clientProjectItem-03ef5:last-child{margin-bottom:0}.clientProjectName-b16b2,.clientProjectDescription-08a93{padding:0;margin:0}.clientProjectName-b16b2{margin-right:25px;font-weight:600}.clientProjectDescription-08a93{color:#ccc}.clientProjectIcons-a4f9b{position:absolute;right:8px;top:50%;transform:translateY(-50%);display:flex;flex-direction:column;visibility:hidden}", ""]);

// Exports
exports.locals = {
	"clientProjectItem": "clientProjectItem-03ef5",
	"clientProjectIcons": "clientProjectIcons-a4f9b",
	"clientProjectName": "clientProjectName-b16b2",
	"clientProjectDescription": "clientProjectDescription-08a93"
};