import { takeLatest, call, put, select } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';
import { banksSelector } from 'redux/selectors';

import { REDUX_DATA_HELPERS } from 'utils/helpers';
import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';
import { types } from './types';

function* getBanks() {
  try {
    const { data } = yield call(API.getBanks);

    yield put({ type: types.GET_BANKS_SUCCESS, payload: data });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_BANKS_FAILED);
  }
}

function* addBank({ payload }) {
  try {
    const { banks } = yield select(banksSelector);
    const { data } = yield call(API.addBank, payload);

    yield put({
      type: types.ADD_BANK_SUCCESS,
      payload: [...banks, data],
    });
    message.success(SAGA_MESSAGES.ADD_BANK_SUCCESS);
  } catch (err) {
    yield call(sagaErrorHandler, err, types.ADD_BANK_FAILED);
  }
}

function* updateBank({ payload }) {
  try {
    const { banks } = yield select(banksSelector);
    const { data } = yield call(API.updateBank, payload);

    const updatedInvoiceTypes = REDUX_DATA_HELPERS.updateData({
      data: banks,
      editedItem: data,
      id: payload.id,
    });

    yield put({
      type: types.UPDATE_BANK_SUCCESS,
      payload: updatedInvoiceTypes,
    });
    message.success(SAGA_MESSAGES.UPDATE_BANK_SUCCESS);
  } catch (err) {
    yield call(sagaErrorHandler, err, types.UPDATE_BANK_FAILED);
  }
}
function* deleteBank({ payload }) {
  try {
    const { banks } = yield select(banksSelector);

    yield call(API.deleteBank, payload);

    const updatedInvoiceTypes = REDUX_DATA_HELPERS.removeItem({
      data: banks,
      id: payload,
    });

    yield put({
      type: types.DELETE_BANK_SUCCESS,
      payload: updatedInvoiceTypes,
    });
    message.success(SAGA_MESSAGES.DELETE_BANK_SUCCESS);
  } catch (err) {
    yield call(sagaErrorHandler, err, types.DELETE_BANK_SUCCESS);
  }
}

export default function* saga() {
  yield takeLatest(types.GET_BANKS, getBanks);
  yield takeLatest(types.ADD_BANK, addBank);
  yield takeLatest(types.UPDATE_BANK, updateBank);
  yield takeLatest(types.DELETE_BANK, deleteBank);
}
