export const CREATION_ROW = {
  key: 'new_allocation',
  position: '',
  rate: '',
  hours_per_week: '',
};

export const BUTTON_ROW = {
  position: null,
  rate: null,
  hours_per_week: null,
  isAddButton: true,
  key: 'addButton',
};

export const CONDITION_OPTIONS = [
  {
    title:
      'Max - Billable hours are the largest number of hours from logged or expected hours',
    value: '<=',
    preview: 'Max',
  },
  {
    title:
      'Min - Billable hours are the smallest number of hours from logged or expected hours',
    value: '>=',
    preview: 'Min',
  },
  {
    title:
      'Allocation - Billable hours are the number of hours calculated from the deal conditions',
    value: '=',
    preview: 'Allocation',
  },
  {
    title: 'Logs - Billable hours are the number of logged hours',
    value: '<>',
    preview: 'Logs',
  },
];
