export const getModifiedLeatAudit = ({ leadAudit }) =>
  leadAudit.map(item => {
    const auditeKeys = Object.keys(item.audited_changes);
    const isOwnerChanged =
      auditeKeys.includes('owner_id') && auditeKeys.length === 1;
    const isStatusChanged =
      auditeKeys.includes('status') && auditeKeys.length === 1;
    const isScoreChagned =
      auditeKeys.includes('score') && auditeKeys.length === 1;

    switch (true) {
      case isOwnerChanged:
        return { ...item, auditable_type: 'LeadOwner' };
      case isStatusChanged:
        return { ...item, auditable_type: 'LeadStatus' };
      case isScoreChagned:
        return { ...item, auditable_type: 'LeadScore' };

      default:
        return item;
    }
  });
