exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container-54c30{max-width:1440px;width:100%;margin:auto}.tabs-5504c{padding:24px;border-radius:12px;background-color:#fff;box-shadow:0 4px 20px 0 rgba(112,112,128,.1)}.modal-40c7f>div>button>span{display:flex;justify-content:center;align-items:center}.name-359f4{margin:0 8px 0 4px}.header-02cc3{display:flex;align-items:center;gap:8px}", ""]);

// Exports
exports.locals = {
	"container": "container-54c30",
	"tabs": "tabs-5504c",
	"modal": "modal-40c7f",
	"name": "name-359f4",
	"header": "header-02cc3"
};