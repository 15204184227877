import { takeLatest, call, put } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';
import { sagaErrorHandler } from 'utils/common';
import { sagaMessageGenerator } from 'redux/sagaMessages';

import { CALENDAR_EXPAND } from 'containers/CalendarPage/constants';
import { types } from './types';

function* getCalendar({ payload }) {
  try {
    const { data } = yield call(API.getCalendar, payload);

    yield put({
      type: types.GET_CALENDAR_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_CALENDAR_FAILED);
  }
}

function* createHoliday({ payload: { from, to, ...params } }) {
  try {
    const { data } = yield call(API.createHoliday, params);

    yield put({
      type: types.CREATE_HOLIDAY_SUCCESS,
      payload: data,
    });

    yield call(getCalendar, { payload: { from, to, expand: CALENDAR_EXPAND } });

    message.success(sagaMessageGenerator.success('holiday', 'create'));
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_HOLIDAY_FAILED,
      sagaMessageGenerator.failed('holiday', 'create'),
    );
  }
}

function* updateHoliday({ payload: { from, to, ...params } }) {
  try {
    const { data } = yield call(API.updateHoliday, params);

    yield put({
      type: types.UPDATE_HOLIDAY_SUCCESS,
      payload: data,
    });

    yield call(getCalendar, { payload: { from, to, expand: CALENDAR_EXPAND } });

    message.success(sagaMessageGenerator.success('holiday', 'update'));
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_HOLIDAY_FAILED,
      sagaMessageGenerator.failed('holiday', 'update'),
    );
  }
}

function* deleteHoliday({ payload: { from, to, ...params } }) {
  try {
    const { data } = yield call(API.deleteHoliday, params);

    yield put({
      type: types.DELETE_HOLIDAY_SUCCESS,
      payload: data,
    });

    yield call(getCalendar, { payload: { from, to, expand: CALENDAR_EXPAND } });

    message.success(sagaMessageGenerator.success('holiday', 'delete'));
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_HOLIDAY_FAILED,
      sagaMessageGenerator.failed('holiday', 'delete'),
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_CALENDAR, getCalendar);
  yield takeLatest(types.CREATE_HOLIDAY, createHoliday);
  yield takeLatest(types.UPDATE_HOLIDAY, updateHoliday);
  yield takeLatest(types.DELETE_HOLIDAY, deleteHoliday);
}
