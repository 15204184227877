import React from 'react';
import { Popover } from 'antd';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { EXTRA_PAYMENTS_TABLE_CONTENT } from 'containers/ExtraPayments/constants';
import { PROFILES_PERMISSIONS } from 'containers/ProfilesPage/constants';
import TableActions from 'components/Common/TableActions';
import TableButton from 'components/Common/TableButton';
import Tag from 'components/Common/Tag';
import { hasRights } from 'utils/permissions';
import { DATE_MONTH_FORMAT, EXTRA_PAYMENT_FORMAT } from 'utils/timeConstants';

import { getAvatarAuthor } from '../ProfileMain/utils';
import { STATUS_INFO } from '../ProfileMain/constants';

import styles from './styles.scss';

const amountFormatter = ({ amount, postfix }) =>
  amount && (
    <span>{`${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
    }).format(amount)} ${postfix}`}</span>
  );

const getStatusTag = status => (
  <Tag color={get(STATUS_INFO, [status, 'color'], '')}>
    {get(STATUS_INFO, [status, 'title'], 'undefined')}
  </Tag>
);

export const getModifiedData = data => {
  const sortedData = data.sort((a, b) =>
    b.valid_from.localeCompare(a.valid_from),
  );

  const groupByPeriods = sortedData.reduce(function(acc, curr) {
    let estKey = curr['valid_from'];
    (acc[estKey] ? acc[estKey] : (acc[estKey] = null || [])).push(curr);

    return acc;
  }, {});

  const modifyData = [];
  const currentPeriod = {
    periodRowSpan: 1,
    period: '',
    firstRowId: '',
  };

  sortedData.forEach(elem => {
    if (get(currentPeriod, 'period') !== get(elem, 'valid_from')) {
      currentPeriod.period = get(elem, 'valid_from');
      currentPeriod.periodRowSpan = 1;
      currentPeriod.firstRowId = get(elem, 'id');
    } else {
      currentPeriod.periodRowSpan += 1;
    }

    modifyData.push({
      ...elem,
      rowSpan: get(groupByPeriods, get(elem, 'valid_from')).length,
      firstRowId: currentPeriod.firstRowId,
      currentPeriod: currentPeriod.period,
    });
  });

  return modifyData;
};

export const getColumns = ({
  users,
  onShowModal,
  onExtraPaymentsDelete,
  onDeclineExtraPayment,
  onApproveExtraPayment,
  employees,
  isWard,
  user,
  isSummary,
  role,
}) => {
  const canApprove = hasRights(role, PROFILES_PERMISSIONS.approveExtraPayment);
  const canDecline = hasRights(role, PROFILES_PERMISSIONS.declineExtraPayment);
  const canUpdate = hasRights(role, PROFILES_PERMISSIONS.updateExtraPayment);
  const canDelete = hasRights(role, PROFILES_PERMISSIONS.deleteExtraPayment);

  return [
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.period_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.valid_from,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.valid_from,
      render: (_, { firstRowId, rowSpan, id, currentPeriod }) => ({
        children: dayjs(currentPeriod).format(EXTRA_PAYMENT_FORMAT),
        props: {
          rowSpan: id === firstRowId ? rowSpan : 0,
        },
      }),
      width: 150,
      align: 'center',
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.amount_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.amount,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.amount,
      width: 160,
      align: 'center',
      render: (_, { amount_byn, amount_usd }) => (
        <div className={styles.amountCell}>
          {amountFormatter({ amount: amount_byn, postfix: 'р.' })}
          {amountFormatter({ amount: amount_usd, postfix: '' })}
        </div>
      ),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.employee_profile_name_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.employee_profile_name,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.employee_profile_name,
      align: 'center',
      width: 150,
      hidden: !isSummary,
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.description_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.title,
      width: 200,
      align: 'center',
      render: (value, record) => (
        <Popover
          placement="bottom"
          title="Description"
          content={
            <div className={styles.popoverDescription}>
              {get(record, 'description') || 'Description is missing'}
            </div>
          }
          trigger="hover"
        >
          {value}
        </Popover>
      ),
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.project_title,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.project_name,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.project_name,
      width: 180,
      align: 'center',
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.author_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.author_id,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.author_id,
      render: (value, record) =>
        getAvatarAuthor({ user: record.author, users, employees }),
      align: 'center',
      width: 100,
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.status_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.status,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.status,
      render: status => status && getStatusTag(status),
      width: 110,
      align: 'center',
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.created_at_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.created_at,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.created_at,
      render: value => dayjs(value).format(DATE_MONTH_FORMAT),
      width: 120,
      align: 'center',
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.reviewed_at_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.reviewed_at,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.reviewed_at,
      render: value => (value ? dayjs(value).format(DATE_MONTH_FORMAT) : ''),
      align: 'center',
      width: 100,
    },
    {
      title: EXTRA_PAYMENTS_TABLE_CONTENT.reviewer_title,
      key: EXTRA_PAYMENTS_TABLE_CONTENT.reviewer_id,
      dataIndex: EXTRA_PAYMENTS_TABLE_CONTENT.reviewer_id,
      render: (value, record) =>
        getAvatarAuthor({ user: record.reviewer, users, employees }),
      align: 'center',
      width: 100,
    },
    {
      title: 'Approve/Decline ',
      dataIndex: 'approve/decline',
      key: 'approve/decline',
      width: 140,
      align: 'center',
      hidden: isSummary || (!canApprove && !canDecline),
      render: (value, { status, id, employee_id }) => (
        <>
          {canApprove && (
            <TableButton
              type="primary"
              className={styles.paymentButton}
              onClick={() => onApproveExtraPayment({ id, employee_id })}
              icon={<CheckOutlined />}
              disabled={status === 'approved'}
            />
          )}
          {canDecline && (
            <TableButton
              danger
              onClick={() => onDeclineExtraPayment({ id, employee_id })}
              className={styles.paymentButton}
              icon={<CloseOutlined />}
              disabled={status === 'declined'}
            />
          )}
        </>
      ),
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      align: 'center',
      width: 50,
      hidden: isSummary,
      render: (_, { author_id, id }) => (
        <TableActions
          onEdit={onShowModal(id)}
          onDelete={onExtraPaymentsDelete(id)}
          canEdit={canUpdate || (isWard && author_id === user.id)}
          canDelete={canDelete}
        />
      ),
    },
  ].filter(column => !column.hidden);
};
