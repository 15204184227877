import React, { useMemo, useState, createRef, useEffect } from 'react';
import PageHeader from 'components/Common/PageHeader';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from 'components/Common';
import { SORT_DIRECTIONS } from 'utils/constants';
import { getFiltersWithOutNull } from 'utils/common';
import { getSummaryEmployees } from 'redux/SummaryEmployees/actions';
import { summaryEmployeesSelector } from 'redux/selectors';
import Table from 'components/Common/Table';

import { getRowKey } from 'utils/helpers';
import { SUMMARY_TABLE_COMMON, SUMMARY_WORKS } from './constants';
import { getColumns } from './utils';
import styles from './styles.scss';

const EmployeesSummary = () => {
  const [sortInfo, setSortInfo] = useState({
    field: SUMMARY_TABLE_COMMON.name_en,
    order: SORT_DIRECTIONS.ascend,
  });
  const [filterInfo, setFilterInfo] = useState({ status: [SUMMARY_WORKS] });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const searchInput = createRef();

  const dispatch = useDispatch();

  const {
    summaryEmployees,
    summaryEmployeesFilters,
    isSummaryEmployeesLoading,
    summaryEmployeesTotal,
  } = useSelector(summaryEmployeesSelector);

  const summaryEmployeesRequest = {
    expand: [
      SUMMARY_TABLE_COMMON.rm,
      SUMMARY_TABLE_COMMON.position,
      SUMMARY_TABLE_COMMON.buddy,
      SUMMARY_TABLE_COMMON.location,
    ],
    filters: filterInfo,
    sort: `${sortInfo.field}:${sortInfo.order}`,
    page: pagination.current,
    size: pagination.pageSize,
  };

  useEffect(() => {
    dispatch(getSummaryEmployees(summaryEmployeesRequest));
  }, []);

  useEffect(() => {
    setPagination(prev => ({ ...prev, total: summaryEmployeesTotal }));
  }, [summaryEmployeesTotal]);

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = clearFilters => {
    clearFilters();
    setFilterInfo({});
    dispatch(getSummaryEmployees({ ...summaryEmployeesRequest, filters: {} }));
  };

  const onTableChange = (pagination, filters, sort) => {
    const filterWithoutNull = getFiltersWithOutNull(filters);
    setFilterInfo(filterWithoutNull);

    if (SUMMARY_TABLE_COMMON.name_en in filterWithoutNull) {
      filterWithoutNull.name_en = filterWithoutNull.name_en[0];
    }

    if (SUMMARY_TABLE_COMMON.name_ru in filterWithoutNull) {
      filterWithoutNull.name_ru = filterWithoutNull.name_ru[0];
    }

    setSortInfo(sort);
    setPagination(pagination);

    dispatch(
      getSummaryEmployees({
        ...summaryEmployeesRequest,
        filters: filterWithoutNull,
        sort: `${sort.field}:${sort.order}`,
        page: pagination.current,
        size: pagination.pageSize,
      }),
    );
  };

  const columns = useMemo(
    () =>
      getColumns({
        filters: summaryEmployeesFilters,
        sortInfo,
        filterInfo,
        searchInput,
        handleSearch,
        handleReset,
      }),
    [sortInfo, filterInfo, summaryEmployees],
  );

  return (
    <div>
      <PageHeader title="Employees' summary" />
      <Loader loading={isSummaryEmployeesLoading}>
        <div className={styles.container}>
          <Table
            dataSource={summaryEmployees}
            columns={columns}
            className={styles.summaryEmployeesTable}
            onChange={onTableChange}
            bordered
            pagination={{
              ...pagination,
              showSizeChanger: true,
            }}
            rowKey={getRowKey}
          />
        </div>
      </Loader>
    </div>
  );
};

export default EmployeesSummary;
