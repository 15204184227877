import {
  takeLatest,
  call,
  put,
  all,
  select,
  take,
  race,
} from 'redux-saga/effects';
import { message } from 'antd';
import { push } from 'connected-react-router/immutable';

import API from 'utils/api';

import { EMPLOYEES_PAGE_SIZE } from 'containers/EmployeesProfiles/constants';
import { PROFILES_MESSAGES } from 'containers/ProfilesPage/constants';
import { REDUX_DATA_HELPERS } from 'utils/helpers';
import { employeesSelector } from 'redux/selectors';
import { types as technologiesTypes } from 'redux/Technologies/types';
import { sagaMessageGenerator, SAGA_MESSAGES } from 'redux/sagaMessages';
import { EMPLOYEE_OK_STATUSES } from 'utils/constants';
import { sagaErrorHandler } from 'utils/common';
import { EMPLOYEE_EXPAND, types } from './types';

function* loadEmployees({ payload }) {
  try {
    const { data } = yield call(API.loadEmployees, {
      ...payload,
      expand: ['with_summary_employee'],
    });

    yield put({ type: types.LOAD_EMPLOYEES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.LOAD_EMPLOYEES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getFullEmployees() {
  /// Get all employees for selects
  try {
    const { data } = yield call(API.loadEmployees, {
      size: EMPLOYEES_PAGE_SIZE,
      filters: { status: EMPLOYEE_OK_STATUSES },
      expand: ['with_summary_employee'],
    });
    yield put({ type: types.GET_FULL_EMPLOYEES_SUCCESS, payload: data.data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_FULL_EMPLOYEES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getEmployeesWithJira({ payload }) {
  /// Get all employees with jira_user
  try {
    const { data } = yield call(API.getEmployeesWithJira, payload);
    yield put({ type: types.GET_EMPLOYEES_WITH_JIRA_SUCCESS, payload: data });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_EMPLOYEES_WITH_JIRA_FAILED);
  }
}

function* createEmployee({ payload }) {
  try {
    const { data } = yield call(API.createEmployee, payload);

    yield put({ type: types.CREATE_EMPLOYEE_SUCCESS });
    yield put(push(`/employees/${data.id}`));
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_EMPLOYEE_FAILED,
      SAGA_MESSAGES.CREATE_EMPLOYEE_FAILED,
    );
  }
}

function* getEmployee({ payload }) {
  try {
    const { data: employee } = yield call(API.getEmployee, {
      employeeId: payload,
      expand: [
        'ibans',
        'technologies',
        'resource_manager',
        'buddies',
        'employee_position',
        'attachments',
        'with_summary_employee',
      ],
    });

    const formattedEmployee = {
      ...employee.summary_employee,
      ...employee,
    };

    yield put({
      type: types.GET_EMPLOYEE_SUCCESS,
      payload: formattedEmployee,
    });

    const ibans = {};
    employee.ibans.forEach(iban => {
      ibans[iban.iban_number] = {
        currency_type: iban.currency_type,
        isEditable: !iban.currency_type,
        iban_number: iban.iban_number,
      };
    });

    yield put({ type: types.SET_IBAN_CURRENCY, payload: ibans });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_EMPLOYEE_FAILED);
  }
}

function* getEmployeeProfileData({ payload }) {
  try {
    const {
      0: { data: salaries },
      1: { data: comments },
      2: { data: extraPayments },
    } = yield all([
      call(API.getEmployeeSalaries, payload),
      call(API.getEmployeeComments, payload),
      call(API.getEmployeeExtraPayments, payload),
    ]);

    yield put({
      type: types.GET_EMPLOYEE_PROFILE_DATA_SUCCESS,
      payload: {
        salaries,
        comments,
        extraPayments,
      },
    });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_EMPLOYEE_PROFILE_DATA_FAILED);
  }
}

function* getEmployeeRm({ payload }) {
  try {
    const {
      0: { data: employee },
    } = yield all([call(API.getEmployee, payload)]);
    yield put({
      type: types.GET_EMPLOYEE_SUCCESS,
      payload: {
        data: {
          ...employee,
        },
      },
    });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_EMPLOYEE_FAILED);
  }
}

function* getEmployeeComments({ payload }) {
  try {
    const { data } = yield call(API.getEmployeeComments, payload);
    yield put({ type: types.GET_EMPLOYEE_COMMENTS_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_EMPLOYEE_COMMENTS_FAILED,
      PROFILES_MESSAGES.COMMENT_UPLOAD_ERROR,
    );
  }
}

function* addEmployeeComment({ payload }) {
  try {
    const { data } = yield call(API.addEmployeeComment, payload);

    yield put({ type: types.ADD_EMPLOYEE_COMMENT_SUCCESS, payload: data });
    message.success(PROFILES_MESSAGES.COMMENT_ADD_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.ADD_EMPLOYEE_COMMENT_FAILED,
      PROFILES_MESSAGES.COMMENT_ADD_ERROR,
    );
  }
}
function* updateEmployeeComment({ payload }) {
  try {
    const { data } = yield call(API.updateEmployeeComment, payload);

    yield put({ type: types.UPDATE_EMPLOYEE_COMMENT_SUCCESS, payload: data });
    message.success(PROFILES_MESSAGES.COMMENT_UPDATE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_EMPLOYEE_COMMENT_FAILED,
      PROFILES_MESSAGES.COMMENT_UPDATE_ERROR,
    );
  }
}
function* deleteEmployeeComment({ payload }) {
  try {
    yield call(API.deleteEmployeeComment, payload);

    yield put({
      type: types.DELETE_EMPLOYEE_COMMENT_SUCCESS,
      payload: payload.comment_id,
    });
    message.success(PROFILES_MESSAGES.COMMENT_DELETE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_EMPLOYEE_COMMENT_FAILED,
      PROFILES_MESSAGES.COMMENT_DELETE_ERROR,
    );
  }
}

function* getEmployeeSalaries({ payload }) {
  try {
    const { data } = yield call(API.getEmployeeSalaries, payload);
    yield put({
      type: types.GET_EMPLOYEE_SALARIES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_EMPLOYEE_SALARIES_FAILED);
  }
}

function* updateEmployeeSalaries({ payload: { salaryId, ...payload } }) {
  try {
    const { employeeSalaries } = yield select(employeesSelector);
    const { data } = yield call(API.updateSalaries, salaryId, payload);
    const updatedSalaries = REDUX_DATA_HELPERS.updateData({
      data: employeeSalaries,
      editedItem: data,
      id: salaryId,
    });
    yield put({
      type: types.UPDATE_EMPLOYEE_SALARIES_SUCCESS,
      payload: updatedSalaries,
    });
    message.success(SAGA_MESSAGES.UPDATE_SALARY_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_EMPLOYEE_SALARIES_FAILED,
      SAGA_MESSAGES.UPDATE_SALARY_FAILED,
    );
  }
}

function* deleteEmployeeSalaries({ payload: { id, employeeId } }) {
  try {
    const { employeeSalaries } = yield select(employeesSelector);
    yield call(API.deleteSalaries, { id, employeeId });

    const updatedSalaries = REDUX_DATA_HELPERS.removeItem({
      id,
      data: employeeSalaries,
    });
    yield put({
      type: types.DELETE_EMPLOYEE_SALARIES_SUCCESS,
      payload: updatedSalaries,
    });
    message.success(SAGA_MESSAGES.DELETE_SALARY_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_EMPLOYEE_SALARIES_FAILED,
      SAGA_MESSAGES.DELETE_SALARY_FAILED,
    );
  }
}

function* getEmployeeExtraPayments({ payload }) {
  try {
    const { data } = yield call(API.getEmployeeExtraPayments, payload);
    yield put({
      type: types.GET_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_EMPLOYEE_EXTRA_PAYMENTS_FAILED);
  }
}

function* updateEmployeeExtraPayment({
  payload: { currentExtraPaymentId, currentEmployeeId, period, ...payload },
}) {
  try {
    const { data } = yield call(API.updateEmployeeExtraPayment, {
      id: currentExtraPaymentId,
      employeeId: currentEmployeeId,
      data: payload,
    });

    const { employeeExtraPayments } = yield select(employeesSelector);

    const updatedData = REDUX_DATA_HELPERS.updateData({
      data: employeeExtraPayments,
      editedItem: data,
      id: currentExtraPaymentId,
    });

    yield put({
      type: types.UPDATE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS,
      payload: updatedData,
    });
    message.success(SAGA_MESSAGES.UPDATE_EXTRA_PAYMENT_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_EMPLOYEE_EXTRA_PAYMENTS_FAILED,
      SAGA_MESSAGES.UPDATE_EXTRA_PAYMENT_FAILED,
    );
  }
}

function* deleteEmployeeExtraPayments({ payload: { id } }) {
  try {
    yield call(API.deleteExtraPayments, id);
    const { employeeExtraPayments } = yield select(employeesSelector);

    const updatedData = REDUX_DATA_HELPERS.removeItem({
      data: employeeExtraPayments,
      id,
    });
    yield put({
      type: types.DELETE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS,
      payload: updatedData,
    });
    message.success(SAGA_MESSAGES.DELETE_EXTRA_PAYMENT_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_EMPLOYEE_EXTRA_PAYMENTS_FAILED,
      SAGA_MESSAGES.DELETE_EXTRA_PAYMENT_SUCCESS,
    );
  }
}

function* approveEmployeeExtraPayment({ payload: { id } }) {
  try {
    const { data } = yield call(API.approveExtraPayment, id);

    const { employeeExtraPayments } = yield select(employeesSelector);

    const updatedData = REDUX_DATA_HELPERS.updateData({
      data: employeeExtraPayments,
      editedItem: data,
      id,
    });
    yield put({
      type: types.APPROVE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS,
      payload: updatedData,
    });
    message.success(sagaMessageGenerator.success('extra payment', 'approve'));
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.APPROVE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS,
      message.success(sagaMessageGenerator.failed('extra payment', 'approve')),
    );
  }
}

function* declineEmployeeExtraPayment({ payload: { id } }) {
  try {
    const { data } = yield call(API.declineExtraPayment, id);

    const { employeeExtraPayments } = yield select(employeesSelector);

    const updatedData = REDUX_DATA_HELPERS.updateData({
      data: employeeExtraPayments,
      editedItem: data,
      id,
    });
    yield put({
      type: types.DECLINE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS,
      payload: updatedData,
    });
    message.success(sagaMessageGenerator.success('extra payment', 'decline'));
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DECLINE_EMPLOYEE_EXTRA_PAYMENTS_FAILED,
      message.success(sagaMessageGenerator.failed('extra payment', 'decline')),
    );
  }
}

function* getEmployeePaysheets({ payload }) {
  try {
    const { data } = yield call(API.getEmployeePaysheets, payload);
    yield put({
      type: types.GET_EMPLOYEE_PAYSHEETS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_EMPLOYEE_PAYSHEETS_FAILED);
  }
}

function* updateEmployee({ payload: { id, ibans, ...payload } }) {
  try {
    const jira = payload.jira_id
      ? yield call(API.updateJiraKey, id, payload.jira_id)
      : {};
    yield call(API.updateEmployeeIbans, { id, ibans });
    const employeePayload = jira?.data
      ? { ...payload, name_en: jira.data.displayName }
      : payload;
    const {
      0: { data: employee },
      1: { data: salaries },
      2: { data: extraPayments },
      3: { data: paysheets },
      4: { data: technologies },
    } = yield all([
      call(API.updateEmployee, id, {
        ...employeePayload,
        expand: EMPLOYEE_EXPAND,
      }),
      call(API.getEmployeeSalaries, id),
      call(API.getEmployeeExtraPayments, id),
      call(API.getEmployeePaysheets, id),
      call(API.getEmployeeTechnologies, id),
    ]);

    const fullEmpoyee = {
      ...employee,
      jira_user: (jira && jira.data) || employee.jira_user,
      salaries,
      extraPayments,
      paysheets,
      technologies,
      ...employee.summary_employee,
      status: employee.status,
    };

    message.success(SAGA_MESSAGES.UPDATE_EMPLOYEE_SUCCESS);
    yield put({
      type: types.UPDATE_EMPLOYEE_SUCCESS,
      payload: fullEmpoyee,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_EMPLOYEE_FAILED,
      SAGA_MESSAGES.UPDATE_EMPLOYEE_FAILED,
    );
  }
}

function* updateEmployeeRm({ payload: { id, resource_manager_id } }) {
  try {
    const { data } = yield call(API.updateEmployee, id, {
      resource_manager_id,
      expand: EMPLOYEE_EXPAND,
    });

    yield put({
      type: types.UPDATE_EMPLOYEE_SUCCESS,
      payload: {
        ...data,
        ...data.summary_employee,
      },
    });
    message.success(SAGA_MESSAGES.UPDATE_EMPLOYEE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_EMPLOYEE_FAILED,
      SAGA_MESSAGES.UPDATE_EMPLOYEE_FAILED,
    );
  }
}

function* addEmployeeSalaries({ payload: { employee_id, ...payload } }) {
  try {
    const { employeeSalaries } = yield select(employeesSelector);
    const { data } = yield call(API.addEmployeeSalaries, employee_id, payload);
    yield put({
      type: types.ADD_EMPLOYEE_SALARIES_SUCCESS,
      payload: [data, ...employeeSalaries],
    });
    message.success(SAGA_MESSAGES.CREATE_SALARY_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.ADD_EMPLOYEE_SALARIES_FAILED,
      SAGA_MESSAGES.CREATE_SALARY_FAILED,
    );
  }
}

function* uploadEmployees({ payload: { file, ...payload } }) {
  try {
    yield call(API.uploadEmployees, file);
    yield call(loadEmployees, { payload });
    message.success(SAGA_MESSAGES.IMPORT_EMPLOYEES_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.LOAD_EMPLOYEES_FAILED,
      SAGA_MESSAGES.IMPORT_EMPLOYEES_FAILED,
    );
  }
}

function* addEmployeeExtraPayments({ payload: { employee_id, ...payload } }) {
  try {
    const { data } = yield call(
      API.addEmployeeExtraPayments,
      employee_id,
      payload,
    );

    const { employeeExtraPayments } = yield select(employeesSelector);
    yield put({
      type: types.ADD_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS,
      payload: [...employeeExtraPayments, data],
    });
    message.success(sagaMessageGenerator.success('extra payment', 'create'));
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.ADD_EMPLOYEE_EXTRA_PAYMENTS_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getEmployeeTechnologies({ payload }) {
  try {
    const { data } = yield call(API.getEmployeeTechnologies, payload);
    yield put({ type: types.GET_EMPLOYEE_TECHNOLOGIES_SUCCESS, payload: data });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_EMPLOYEE_TECHNOLOGIES_FAILED);
  }
}

function* addAndUpdateUserTechnologies({
  payload: { newTechnology, technology_ids, currentEmpoyeeId },
}) {
  /// Create new technology and add it to employee technologies
  try {
    const { data } = yield call(API.addTechnology, {
      title: newTechnology,
      key: newTechnology,
    });

    yield put({
      type: technologiesTypes.ADD_TECHNOLOGY_SUCCESS,
      payload: data,
    });

    yield call(updateEmployeeTechnologies, {
      payload: {
        technology_ids: [...technology_ids, data.id],
        currentEmpoyeeId,
      },
    });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.ADD_AND_UPDATE_TECHNOLOGIES_FAILED);
  }
}

function* updateEmployeeTechnologies({
  payload: { currentEmpoyeeId, technology_ids },
}) {
  try {
    const { data } = yield call(
      API.updateEmployeeTechnologies,
      currentEmpoyeeId,
      {
        technology_ids,
        expand: EMPLOYEE_EXPAND,
      },
    );
    message.success(SAGA_MESSAGES.UPDATE_EMPLOYEE_SUCCESS);
    yield put({
      type: types.UPDATE_EMPLOYEE_TECHNOLOGIES_SUCCESS,
      payload: data,
    });
    message.success(sagaMessageGenerator.success('technology', 'update'));
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_EMPLOYEE_TECHNOLOGIES_FAILED,
      message.success(sagaMessageGenerator.failed('technology', 'update')),
    );
  }
}

function* getEmployeeAttachments({ payload }) {
  try {
    const { data } = yield call(API.getEmployeeAttachments, payload);
    yield put({ type: types.GET_EMPLOYEE_ATTACHMENTS_SUCCESS, payload: data });
  } catch (err) {
    yield call(sagaErrorHandler, err, types.GET_EMPLOYEE_ATTACHMENTS_FAILED);
  }
}

function* uploadEmployeeAttachment({
  payload: {
    employeeId,
    fileName,
    file,
    onSuccessAttachmentUpload,
    onFailedAttachmentUpload,
  },
}) {
  try {
    const {
      data: { signed_url },
    } = yield call(API.uploadEmployeeAttachmentUrl, employeeId, fileName);
    yield call(API.uploadAttachmentToAWS, signed_url, file);
    const { data } = yield call(API.addEmployeeAttachment, employeeId, {
      file_name: fileName,
      url: signed_url,
      employee_id: employeeId,
    });

    const { employeeAttachments } = yield select(employeesSelector);
    yield put({
      type: types.ADD_EMPLOYEE_ATTACHMENT_SUCCESS,
      payload: [...employeeAttachments, data],
    });
    yield call(onSuccessAttachmentUpload, fileName);
  } catch (err) {
    yield put({ type: types.ADD_EMPLOYEE_ATTACHMENT_FAILED });
    yield call(onFailedAttachmentUpload, fileName);
  }
}

function* cancelAttachmentUpload() {
  yield put({ type: types.ADD_EMPLOYEE_ATTACHMENT_SUCCESS });
}

function* deleteEmployeeAttachment({ payload }) {
  try {
    yield call(API.deleteEmployeeAttachment, payload);

    const { employeeAttachments } = yield select(employeesSelector);

    const updatedData = REDUX_DATA_HELPERS.removeCustomItem({
      data: employeeAttachments,
      id: payload.url,
      field: 'url',
    });

    yield put({
      type: types.DELETE_EMPLOYEE_ATTACHMENT_SUCCESS,
      payload: updatedData,
    });
    message.success(SAGA_MESSAGES.DELETE_EMPLOYEE_ATTACHMENT_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_EMPLOYEE_ATTACHMENT_FAILED,
      SAGA_MESSAGES.DELETE_EMPLOYEE_ATTACHMENT_FAILED,
    );
  }
}

function* updateBuddies({ payload: { employee_id, buddy_ids } }) {
  try {
    const { data } = yield call(API.updateEmployeeBuddies, {
      buddy_ids,
      employee_id,
    });

    yield put({
      type: types.UPDATE_BUDDIES_SUCCESS,
      payload: data.summary_employee.buddy_ids,
    });
    message.success(PROFILES_MESSAGES.BUDDIES_UPDATE_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_BUDDIES_FAILED,
      PROFILES_MESSAGES.ERROR,
    );
  }
}

function* getIbanCurrency({ payload }) {
  try {
    const {
      data: { currency_type },
    } = yield call(API.getIbanCurrency, payload);
    yield put({
      type: types.GET_IBAN_CURRENCY_SUCCESS,
      payload: {
        iban_number: payload,
        currency_type,
        isEditable: !currency_type,
      },
    });
  } catch (error) {
    yield put({
      type: types.GET_IBAN_CURRENCY_SUCCESS,
      payload: {
        iban_number: payload,
        currency_type: null,
        isEditable: true,
      },
    });
  }
}

function* updateResourceManager({ payload: { resource_manager_id, id } }) {
  try {
    const { data } = yield call(API.updateResourceManager, {
      id,
      resource_manager_id,
      expand: ['resource_manager'],
    });

    yield put({
      type: types.UPDATE_RESOURCE_MANAGER_SUCCESS,
      payload: data.summary_employee.resource_manager,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_RESOURCE_MANAGER_FAILED,
      PROFILES_MESSAGES.ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.LOAD_EMPLOYEES, loadEmployees);
  yield takeLatest(types.GET_FULL_EMPLOYEES, getFullEmployees);
  yield takeLatest(types.GET_EMPLOYEES_WITH_JIRA, getEmployeesWithJira);
  yield takeLatest(types.CREATE_EMPLOYEE, createEmployee);
  yield takeLatest(types.UPLOAD_EMPLOYEES, uploadEmployees);
  yield takeLatest(types.GET_EMPLOYEE, getEmployee);
  yield takeLatest(types.GET_EMPLOYEE_PROFILE_DATA, getEmployeeProfileData);
  yield takeLatest(types.GET_EMPLOYEE_RM, getEmployeeRm);
  yield takeLatest(types.UPDATE_EMPLOYEE, updateEmployee);
  yield takeLatest(types.UPDATE_EMPLOYEE_RM, updateEmployeeRm);
  yield takeLatest(types.ADD_EMPLOYEE_COMMENT, addEmployeeComment);
  yield takeLatest(types.UPDATE_EMPLOYEE_COMMENT, updateEmployeeComment);
  yield takeLatest(types.DELETE_EMPLOYEE_COMMENT, deleteEmployeeComment);
  yield takeLatest(types.GET_EMPLOYEE_COMMENTS, getEmployeeComments);
  yield takeLatest(types.GET_EMPLOYEE_SALARIES, getEmployeeSalaries);
  yield takeLatest(types.ADD_EMPLOYEE_SALARIES, addEmployeeSalaries);
  yield takeLatest(types.UPDATE_EMPLOYEE_SALARIES, updateEmployeeSalaries);
  yield takeLatest(types.DELETE_EMPLOYEE_SALARIES, deleteEmployeeSalaries);
  yield takeLatest(types.ADD_EMPLOYEE_EXTRA_PAYMENTS, addEmployeeExtraPayments);
  yield takeLatest(types.GET_EMPLOYEE_TECHNOLOGIES, getEmployeeTechnologies);
  yield takeLatest(types.UPDATE_RESOURCE_MANAGER, updateResourceManager);
  yield takeLatest(
    types.ADD_AND_UPDATE_TECHNOLOGIES,
    addAndUpdateUserTechnologies,
  );
  yield takeLatest(
    types.UPDATE_EMPLOYEE_TECHNOLOGIES,
    updateEmployeeTechnologies,
  );
  yield takeLatest(types.GET_EMPLOYEE_EXTRA_PAYMENTS, getEmployeeExtraPayments);
  yield takeLatest(
    types.UPDATE_EMPLOYEE_EXTRA_PAYMENTS,
    updateEmployeeExtraPayment,
  );
  yield takeLatest(
    types.DELETE_EMPLOYEE_EXTRA_PAYMENTS,
    deleteEmployeeExtraPayments,
  );
  yield takeLatest(
    types.APPROVE_EMPLOYEE_EXTRA_PAYMENTS,
    approveEmployeeExtraPayment,
  );
  yield takeLatest(
    types.DECLINE_EMPLOYEE_EXTRA_PAYMENTS,
    declineEmployeeExtraPayment,
  );
  yield takeLatest(types.GET_EMPLOYEE_PAYSHEETS, getEmployeePaysheets);
  yield takeLatest(types.GET_EMPLOYEE_ATTACHMENTS, getEmployeeAttachments);
  yield takeLatest(types.DELETE_EMPLOYEE_ATTACHMENT, deleteEmployeeAttachment);
  yield takeLatest(types.CANCEL_ATTACHMENT_UPLOAD, cancelAttachmentUpload);
  yield takeLatest(types.UPDATE_BUDDIES, updateBuddies);
  yield takeLatest(types.GET_IBAN_CURRENCY, getIbanCurrency);
  yield takeLatest(types.ADD_EMPLOYEE_ATTACHMENT, function*(...args) {
    yield race({
      task: call(uploadEmployeeAttachment, ...args),
      cancel: take(types.CANCEL_ATTACHMENT_UPLOAD),
    });
  });
}
