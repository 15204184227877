import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Loader } from 'components/Common';

import ClientInvoiceNotificationsItem from './ClientInvoiceNotificationsItem';
import { getAlerts } from './utils';

import styles from './styles.scss';

const ClientInvoiceNotification = ({
  invoiceAllocations,
  loggedAllocations,
  jiraRolesGroupedById,
  projectGroupedById,
  worklogs,
  worklogsGroupedByProject,
  calendar,
  current,
  isClientsLoading,
  addWorklogFromAlert,
  setInvoiceAlertsCount,
}) => {

  // Формирует массив уведомлений о несовпадении затреканных и ожидаемых часов по аллокейшенам
  const alerts = useMemo(
    () =>
      getAlerts({
        invoiceAllocations,
        loggedAllocations,
        jiraRolesGroupedById,
        projectGroupedById,
        worklogs: current === 1 ? worklogs : worklogsGroupedByProject,
        calendar,
        current,
      }),
    [
      invoiceAllocations,
      loggedAllocations,
      jiraRolesGroupedById,
      projectGroupedById,
      worklogs,
      calendar,
      current,
      worklogsGroupedByProject,
    ],
  );

  useEffect(() => {
    setInvoiceAlertsCount(alerts.length);
  }, [alerts]);

  return (
    <div className={styles.clientInvoiceNotification}>
      <Loader loading={isClientsLoading}>
        {alerts.map(alert => (
          <ClientInvoiceNotificationsItem
            alert={alert}
            key={alert.allocationId}
            current={current}
            addWorklogFromAlert={addWorklogFromAlert}
          />
        ))}
      </Loader>
    </div>
  );
};

ClientInvoiceNotification.propTypes = {
  invoiceAllocations: PropTypes.object.isRequired,
  loggedAllocations: PropTypes.array.isRequired,
  jiraRolesGroupedById: PropTypes.object.isRequired,
  projectGroupedById: PropTypes.object.isRequired,
  worklogs: PropTypes.object.isRequired,
  worklogsGroupedByProject: PropTypes.object.isRequired,
  calendar: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired,
  isClientsLoading: PropTypes.bool.isRequired,
  addWorklogFromAlert: PropTypes.func.isRequired,
  setInvoiceAlertsCount: PropTypes.func.isRequired,
};

export default ClientInvoiceNotification;
