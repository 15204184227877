import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button, Typography } from 'antd';
import { FULL_DATE_FORMAT } from 'utils/timeConstants';

import styles from './styles.scss';

const { Text } = Typography;

const ClientInvoiceDraftAlert = ({ removeWorklogsDraft, draftSavedAt }) => (
  <div className={styles.draftAlert}>
    <Text type="warning">{`Loaded worklogs draft from ${dayjs(
      draftSavedAt,
    ).format(FULL_DATE_FORMAT)}`}</Text>
    <Button
      type="default"
      size="small"
      onClick={removeWorklogsDraft}
      className={styles.resetButton}
    >
      Reset to initial worklogs
    </Button>
  </div>
);

ClientInvoiceDraftAlert.propTypes = {
  removeWorklogsDraft: PropTypes.func.isRequired,
  draftSavedAt: PropTypes.object.isRequired,
};

export default ClientInvoiceDraftAlert;
