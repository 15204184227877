import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Transfer } from 'antd';

import { ROLES_COMMON } from 'containers/Roles/constants';
import Modal from 'components/Common/Modal';
import Input from 'components/Common/Input';
import Select, { Option } from 'components/Common/Select';

const { Item } = Form;

const RolesModal = ({
  isVisible,
  currentRole,
  toggleModal,
  onUpdate,
  onCreate,
  permissions,
  roleTypes,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentRole) {
      form.setFieldsValue(currentRole);
    }

    return () => form.resetFields();
  }, [currentRole]);

  const okText = currentRole ? 'Update' : 'Add';

  const titleText = currentRole ? 'Update role' : 'Add role';

  const onSubmit = values => {
    if (currentRole) {
      onUpdate({ ...values, id: currentRole.id });
      form.resetFields();

      return;
    }

    onCreate(values);
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title={titleText}
      okText={okText}
      onCancel={toggleModal(false)}
      onOk={form.submit}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Item
          name={ROLES_COMMON.name}
          label={ROLES_COMMON.name_title}
          rules={[
            {
              required: true,
              message: 'Please enter role name',
            },
          ]}
        >
          <Input />
        </Item>
        <Item
          name={ROLES_COMMON.role_type}
          label={ROLES_COMMON.role_type_title}
          rules={[
            {
              required: true,
              message: 'Please select role type',
            },
          ]}
        >
          <Select>
            {roleTypes.map(type => (
              <Option value={type} key={type}>
                {type}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          label={ROLES_COMMON.permission_ids_title}
          name={ROLES_COMMON.permission_ids}
          valuePropName="targetKeys"
          rules={[
            {
              required: true,
              message: 'Please select at least one element',
            },
          ]}
        >
          <Transfer
            listStyle={{ width: '45%' }}
            dataSource={permissions}
            render={item => item.title}
          />
        </Item>
      </Form>
    </Modal>
  );
};

RolesModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  currentRole: PropTypes.object,
  toggleModal: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  permissions: PropTypes.array,
  roleTypes: PropTypes.array,
};

export default RolesModal;
