import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Timeline, Tag, Spin } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'utils/timeConstants';
import {
  GREEN_STATUSES,
  RED_STATUSES,
  GRAY_STATUSES,
  YELLOW_STATUSES,
} from 'components/ClientCommon/ClientTimeline/TimelineItems/InvoiceItem/constants';
import DatePicker from 'components/Common/DatePicker';
import { INVOICE_STATUSES } from 'containers/InvoicesPage/constants';
import { hasRights } from 'utils/permissions';
import Select, { Option } from 'components/Common/Select';
import TimelineItemLabel from './TimelineItemLabel';
import {
  getFilteredInvoiceAudit,
  getTimelineColors,
  getTimelineIconColor,
} from './utils';
import { WORKFLOW_TIMELINE } from './constants';
import styles from './styles.scss';

const WorkflowCommon = ({
  currentInvoice,
  role,
  invoiceStatus,
  setInvoiceStatus,
  sentAt,
  setSentAt,
  isInvoiceAuditLoading,
  invoiceAudit,
  usersObject,
}) => {
  const { status = '' } = currentInvoice;

  useEffect(() => {
    setInvoiceStatus(status);
  }, [status]);

  const onChangePeriod = value => setSentAt(dayjs(value));
  const onChangeStatus = value => setInvoiceStatus(value);

  const filteredInvoiceAudit = useMemo(
    () => getFilteredInvoiceAudit(invoiceAudit),
    [invoiceAudit],
  );

  const invoiceStatusIndex = WORKFLOW_TIMELINE.findIndex(
    elem => elem.status === invoiceStatus,
  );

  return (
    <Spin spinning={isInvoiceAuditLoading}>
      <Timeline mode="left" style={{ marginRight: '200px' }}>
        {WORKFLOW_TIMELINE.map((item, index) => (
          <Timeline.Item
            label={
              <TimelineItemLabel
                filteredInvoiceAudit={filteredInvoiceAudit}
                usersObject={usersObject}
                status={item.status}
              />
            }
            color={getTimelineColors({
              status: item.status,
              index,
              invoiceStatusIndex,
            })}
            dot={
              status === item.status && (
                <ClockCircleOutlined
                  style={{
                    color: getTimelineIconColor({
                      status: item.status,
                    }),
                  }}
                />
              )
            }
          >
            {item.status === invoiceStatus ? (
              <div>
                <div style={{ display: 'flex' }}>
                  <Select
                    size="small"
                    onChange={onChangeStatus}
                    value={invoiceStatus}
                    className={
                      (invoiceStatus === status &&
                        ((RED_STATUSES.includes(invoiceStatus) &&
                          styles.redStatus) ||
                          (GRAY_STATUSES.includes(invoiceStatus) &&
                            styles.grayStatus) ||
                          (GREEN_STATUSES.includes(invoiceStatus) &&
                            styles.greenStatus) ||
                          (YELLOW_STATUSES.includes(invoiceStatus) &&
                            styles.yellowStatus))) ||
                      styles.statusSelector
                    }
                  >
                    {Object.keys(INVOICE_STATUSES)
                      .filter(item =>
                        hasRights(role, INVOICE_STATUSES[item].scopes),
                      )
                      .map(item => (
                        <Option value={item}>{item.replace(/_/g, ' ')}</Option>
                      ))}
                  </Select>
                  {invoiceStatus === 'SENT' && (
                    <DatePicker
                      style={{ marginLeft: '30px' }}
                      value={sentAt}
                      format={DATE_FORMAT}
                      onChange={onChangePeriod}
                      size="small"
                      placeholder=""
                    />
                  )}
                </div>
                <div style={{ fontSize: '0.75rem' }}>{item.text}</div>
              </div>
            ) : (
              <div>
                <Tag
                  color={
                    (RED_STATUSES.includes(item.status) && 'red') ||
                    (GRAY_STATUSES.includes(item.status) && 'default') ||
                    (GREEN_STATUSES.includes(item.status) && 'green') ||
                    (YELLOW_STATUSES.includes(item.status) && 'gold')
                  }
                  style={{
                    opacity: invoiceStatusIndex < index ? 1 : 0.5,
                  }}
                >
                  {item.status.replace(/_/g, ' ')}
                </Tag>
                <div style={{ fontSize: '0.75rem' }}>{item.text}</div>
              </div>
            )}
          </Timeline.Item>
        ))}
      </Timeline>
    </Spin>
  );
};

WorkflowCommon.propTypes = {
  currentInvoice: PropTypes.object,
  usersObject: PropTypes.object,
  setInvoiceStatus: PropTypes.func,
  setSentAt: PropTypes.func,
  role: PropTypes.string,
  invoiceAudit: PropTypes.array,
  invoiceStatus: PropTypes.string,
  sentAt: PropTypes.string,
  isInvoiceAuditLoading: PropTypes.bool,
};

export default WorkflowCommon;
