import { message } from 'antd';
import { get } from 'lodash';
import { put } from 'redux-saga/effects';
import { SAGA_MESSAGES } from 'redux/sagaMessages';

export function mapArrayToEntities(data = [], entities = {}, key = 'id') {
  return data.reduce(
    (memo, entity) => {
      const { order, entities: entitesBuf } = memo;

      order.push(entity[key]);
      entitesBuf[entity[key]] = entity;

      return {
        order,
        entities,
      };
    },
    {
      order: [],
      entities,
    },
  );
}

export const formatMoney = value =>
  (+value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getFiltersWithOutNull = filtersInfo =>
  Object.keys(filtersInfo).reduce(
    (acc, item) =>
      filtersInfo[item] ? { ...acc, [item]: filtersInfo[item] } : acc,
    {},
  );

export const notifyError = error => {
  message.error(get(error, 'response.data.message', error.message));
};

export function* sagaErrorHandler(error, actionType, customErrorMessage) {
  const { response } = error;
  yield put({ type: actionType, payload: error });

  if (!response) {
    message.error(SAGA_MESSAGES.ERROR);

    return;
  }

  if (response.status >= 400 && response.status < 500) {
    const responseMessage =
      typeof response.data.message === 'string'
        ? response.data.message
        : response.data.message.error;
    message.error(`${response.statusText}: ${responseMessage}`);
  } else if (customErrorMessage) {
    message.error(customErrorMessage);
  }
}
