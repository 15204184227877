import React from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';

import Modal from 'components/Common/Modal';
import OrganizationForm from '../OrganizationForm';
import OrganizationAccounts from '../OrganizationAccounts';

const OrganizationModal = ({
  currentOrganization,
  isVisible,
  onHideModal,
  isLoading = false,
  onEdit = () => {},
  onCreate = () => {},
  role,
  form,
  accounts,
  deleteAccount = () => {},
  createAccount = () => {},
  editAccount = () => {},
  accountsData,
  setAccountsData,
}) => {
  const tabItems = [
    {
      label: 'Organization',
      key: 'organization',
      children: (
        <OrganizationForm
          onFinish={currentOrganization ? onEdit : onCreate}
          form={form}
        />
      ),
    },
    {
      label: 'Accounts',
      key: 'accounts',
      children: (
        <OrganizationAccounts
          organizationId={currentOrganization?.id}
          accounts={accounts}
          isLoading={isLoading}
          onDelete={deleteAccount}
          createAccount={createAccount}
          onEdit={editAccount}
          role={role}
          accountsData={accountsData}
          setAccountsData={setAccountsData}
        />
      ),
    },
  ];

  return (
    <Modal
      title={currentOrganization ? 'Edit organization' : 'Create organization'}
      width="70%"
      open={isVisible}
      onCancel={onHideModal}
      onOk={form.submit}
      okText={currentOrganization ? 'Edit' : 'Add'}
      cancelText="Cancel"
      maskClosable
      confirmLoading={isLoading}
      destroyOnClose
    >
      <Tabs tabPosition="left" items={tabItems} />
    </Modal>
  );
};

OrganizationModal.propTypes = {
  role: PropTypes.string,
  form: PropTypes.object,
  currentOrganization: PropTypes.object,
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  onHideModal: PropTypes.func,
  onEdit: PropTypes.func,
  deleteAccount: PropTypes.func,
  createAccount: PropTypes.func,
  editAccount: PropTypes.func,
  onCreate: PropTypes.func,
  accounts: PropTypes.array,
  accountsData: PropTypes.array,
  setAccountsData: PropTypes.func,
};

export default OrganizationModal;
