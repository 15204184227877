import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import { INVOICE_TYPES_COMMON } from 'containers/InvoiceTypesPage/constants';
import Input from 'components/Common/Input';
import Modal from 'components/Common/Modal';

const { Item } = Form;

const InvoiceTypesModal = ({
  isVisible,
  currentType,
  toggleModal,
  onUpdateInvoiceType,
  onCreateInvoiceType,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentType) {
      form.setFieldsValue(currentType);
    }

    return () => form.resetFields();
  }, [currentType]);

  const okText = currentType ? 'Update' : 'Add';

  const titleText = currentType
    ? 'Update incoming invoice type'
    : 'Create incoming invoice type';

  const onSubmit = () => {
    const name = form.getFieldValue(INVOICE_TYPES_COMMON.name);

    if (currentType) {
      onUpdateInvoiceType({ name, id: currentType.id });
      form.resetFields();

      return;
    }

    onCreateInvoiceType(name);
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title={titleText}
      okText={okText}
      onCancel={toggleModal(false)}
      onOk={form.submit}
    >
      <Form form={form} onFinish={onSubmit}>
        <Item
          name={INVOICE_TYPES_COMMON.name}
          label={INVOICE_TYPES_COMMON.name_title}
          rules={[
            {
              required: true,
              message: 'Please enter type name',
            },
          ]}
        >
          <Input />
        </Item>
      </Form>
    </Modal>
  );
};

InvoiceTypesModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  currentType: PropTypes.object,
  toggleModal: PropTypes.func.isRequired,
  onUpdateInvoiceType: PropTypes.func.isRequired,
  onCreateInvoiceType: PropTypes.func.isRequired,
};

export default InvoiceTypesModal;
