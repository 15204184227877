import React, { useMemo, useEffect, useState, createRef } from 'react';
import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, Table } from 'components/Common';
import RangePicker from 'components/Common/RangePicker';
import { USERS_DEFAULT_SIZE } from 'containers/Users/constants';
import {
  auditsSelector,
  employeesSelector,
  usersSelector,
} from 'redux/selectors';
import { getAudits } from 'redux/Audits/actions';
import { loadUsers } from 'redux/Users/actions';
import { loadEmployees } from 'redux/Employees/actions';

import { getRowKey } from 'utils/helpers';
import PageHeader from 'components/Common/PageHeader';
import FilterDropdown from 'components/Common/FilterDropdown';
import SearchTableIcon from 'components/Icons/SearchIcon';
import { getColumns } from './utils';
import { DEFAULT_SORTER, loadEmployeeParams } from './constants';
import ExpandedTable from './ExpandedTable';

const AuditPage = () => {
  const [auditData, setAuditData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
  const [sortInfo, setSortInfo] = useState({
    order: 'descend',
    columnKey: 'created_at',
  });
  const [filtersInfo, setFiltersInfo] = useState([]);

  const searchInput = createRef();

  const dispatch = useDispatch();

  const { audits, auditFilters, totalAudits, isAuditsLoading } = useSelector(
    auditsSelector,
  );
  const { users } = useSelector(usersSelector);
  const { employees } = useSelector(employeesSelector);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <>
        {dataIndex !== 'created_at' ? (
          <FilterDropdown
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            dataIndex={dataIndex}
            handleReset={handleReset}
          />
        ) : (
          <div style={{ padding: 20 }}>
            <RangePicker onChange={onChangeDate} picker="date" />
          </div>
        )}
      </>
    ),
    filterIcon: filtered => <SearchTableIcon isFiltered={filtered} />,
    onFilterDropdownOpenChange: open => {
      if (open && dataIndex !== 'created_at') {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  const onTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setSortInfo(sorter);
    setFiltersInfo(filters);

    dispatch(
      getAudits({
        pagination,
        sort: `${sorter.columnKey}:${sorter.order}`,
        filters,
      }),
    );
  };

  const onChangeDate = (dates, formattedValue) => {
    dispatch(
      getAudits({
        pagination,
        sort: `${sortInfo.columnKey}:${sortInfo.order}`,
        filters: { start_date: formattedValue[0], end_date: formattedValue[1] },
      }),
    );
  };

  const handleReset = clearFilters => {
    dispatch(
      getAudits({
        pagination,
        sort: `${sortInfo.columnKey}:${sortInfo.order}`,
      }),
    );
    clearFilters();
    setSearchText('');
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const columns = useMemo(
    () =>
      getColumns({
        auditFilters,
        users,
        sorter: sortInfo,
        getColumnSearchProps,
        filtersInfo,
      }),
    [auditFilters, sortInfo, filtersInfo, users],
  );

  useEffect(() => {
    dispatch(getAudits({ pagination, sort: DEFAULT_SORTER }));
    dispatch(loadUsers({ size: USERS_DEFAULT_SIZE }));
    dispatch(loadEmployees(loadEmployeeParams));
  }, []);

  useEffect(() => {
    setAuditData(audits);
    setIsLoading(isAuditsLoading);
    setPagination(prev => ({ ...prev, total: Number(totalAudits) }));
  }, [audits, isAuditsLoading]);

  return (
    <Loader loading={isLoading}>
      <PageHeader title="Audit" />
      <Table
        rowKey={getRowKey}
        columns={columns}
        dataSource={auditData}
        pagination={pagination}
        onChange={onTableChange}
        expandedRowRender={record => (
          <ExpandedTable data={record} users={users} employees={employees} />
        )}
      />
    </Loader>
  );
};

export default AuditPage;
