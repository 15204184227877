export const SUMMARY_EXPAND_PARAMS = [
  'employee_position',
  'technologies',
  'resource_manager',
  'buddies',
  'salaries',
  'extra_payments',
  'attachments',
  'comments',
  'with_employees',
];

export const WITH_EMPLOYEES = 'with_employees';

export const SUMMARY_TAB = 'Summary';
