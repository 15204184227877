import { SUMMARY_TAB } from './constants';

export const getProfilesInfo = ({
  summaryProfile,
  summarySalaries,
  summaryComments,
  summaryExtraPayments,
}) => {
  if (!summaryProfile) return [];

  const employeeProfiles = summaryProfile.employees
    .map(employee => ({
      ...employee,
      tabKey: employee.id,
      tabTitle: employee.profile_name,
      isDataLoaded: false,
    }))
    .sort((a, b) =>
      a.deleted_at ? true : b.created_at.localeCompare(a.created_at),
    );

  return [
    {
      ...summaryProfile,
      salaries: summarySalaries,
      comments: summaryComments,
      extraPayments: summaryExtraPayments,
      tabKey: SUMMARY_TAB,
      tabTitle: SUMMARY_TAB,
    },
    ...employeeProfiles,
  ];
};

export const updateProfilesInfo = ({
  profilesInfo,
  comments,
  salaries,
  extraPayments,
  activeProfileId,
}) => {
  const profile = profilesInfo.find(prof => prof.tabKey == activeProfileId);

  if (profile && !profile.isDataLoaded) {
    const updatedProfilesInfo = profilesInfo.map(prof => {
      if (prof.tabKey == activeProfileId) {
        return {
          ...prof,
          salaries,
          extraPayments,
          comments,
          isDataLoaded: true,
        };
      }

      return prof;
    });

    return updatedProfilesInfo;
  }

  return profilesInfo;
};

export const getInactiveProfiles = profiles =>
  profiles?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: !!curr.deleted_at,
    }),
    {},
  ) || {};
