import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Modal as AntdModal, Spin, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  MoreOutlined,
  DeleteOutlined,
  EditOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import Input from 'components/Common/Input';
import Modal from 'components/Common/Modal';
import { Button } from 'components/Common';
import Select, { OptGroup, Option } from 'components/Common/Select';
import { employeesSelector, technologiesSelector } from 'redux/selectors';
import {
  getTechnologies,
  editTechnology,
  deleteTechnology,
} from 'redux/Technologies/actions';
import {
  updateEmployeeTechnologies,
  addAndUpdateTechnology,
} from 'redux/Employees/actions';

import styles from './styles.scss';

const TechnologiesSelect = ({ currentEmpoyeeId, isProfilePage }) => {
  const [selectedUserTechnologies, setSelectedUserTechnologies] = useState([]);
  const [isVisibleEditModal, setIsVisibleEditModal] = useState(false);
  const [technologyId, setTechnologyId] = useState('');

  const { employeeTechnologies, isEmployeeTechnologiesLoading } = useSelector(
    employeesSelector,
  );

  const { technologies } = useSelector(technologiesSelector);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    if (employeeTechnologies) {
      setSelectedUserTechnologies([...employeeTechnologies]);
    }
  }, [employeeTechnologies]);

  const onSelectChange = value => {
    const existedTechnology = technologies.find(
      item =>
        item.id.toString() === value.toString() ||
        item.title.toLowerCase() === value,
    );

    if (existedTechnology) {
      dispatch(
        updateEmployeeTechnologies({
          technology_ids: [
            ...selectedUserTechnologies.map(item => item.id),
            existedTechnology.id,
          ],
          currentEmpoyeeId,
        }),
      );
    } else {
      dispatch(
        addAndUpdateTechnology({
          newTechnology: value,
          technology_ids: selectedUserTechnologies.map(item => item.id),
          currentEmpoyeeId,
        }),
      );
    }

    setSelectedUserTechnologies(prev => [...prev, value]);
  };

  const onDeselect = value => {
    const currentIds = selectedUserTechnologies
      .filter(item => item.key.toString() !== value.toString())
      .map(item => item.id);

    dispatch(
      updateEmployeeTechnologies({
        technology_ids: currentIds,
        currentEmpoyeeId,
      }),
    );
  };

  const onShowTagMenu = e => {
    e.stopPropagation();
  };

  const onDeleteTechnologies = (event, id) => {
    event.domEvent.stopPropagation();
    AntdModal.confirm({
      content: 'Are you sure ?',
      onOk: () => {
        dispatch(deleteTechnology({ id }));
      },
      onCancel: AntdModal.destroyAll,
    });
  };

  const onEditTechnologies = async () => {
    const isValidate = await form.validateFields();

    if (isValidate) {
      const values = form.getFieldsValue();
      dispatch(
        editTechnology({
          id: technologyId,
          title: values.title,
          key: values.title,
        }),
      );
    }

    onHideModal();
  };

  const onShowModal = (event, technology) => {
    event.domEvent.stopPropagation();
    setIsVisibleEditModal(true);
    setTechnologyId(technology.id);
    form.setFieldsValue(technology);
  };

  const onHideModal = () => {
    setIsVisibleEditModal(false);
    setTechnologyId('');
    form.resetFields();
  };

  useEffect(() => {
    dispatch(getTechnologies());
  }, []);

  return (
    <div className={styles.technologiesSelectorContainer}>
      <Modal
        open={isVisibleEditModal}
        onCancel={onHideModal}
        title="Edit technology title"
        footer={[
          <Button key="cancel">Cancel</Button>,
          <Button
            type="primary"
            htmlType="submit"
            form="editTechnologiesForm"
            key="submit"
          >
            Save
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="editTechnologiesForm"
          onFinish={onEditTechnologies}
        >
          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: 'Plase input technology',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {!isProfilePage && 'Select or create technologies'}
      <Spin spinning={isEmployeeTechnologiesLoading}>
        <Select
          mode="tags"
          className={styles.technologiesSelector}
          onSelect={onSelectChange}
          tokenSeparators={[',']}
          value={selectedUserTechnologies}
          loading={isEmployeeTechnologiesLoading}
          optionLabelProp="label"
          onDeselect={onDeselect}
          filterOption={(input, option) =>
            `${option.key}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          removeIcon={
            <span className={styles.removeIcon}>
              <CloseOutlined />
            </span>
          }
        >
          <OptGroup label="Select a technology or type and press Enter to create a new one">
            {technologies.map(item => (
              <Option key={item.title} value={item.id} label={item.title}>
                <div className={styles.selectOption}>
                  <span>{item.title}</span>
                  <Dropdown
                    placement="bottomLeft"
                    menu={{
                      items: [
                        {
                          label: 'Edit',
                          key: 'Edit',
                          onClick: event => onShowModal(event, item),
                          icon: <EditOutlined />,
                        },
                        {
                          label: 'Remove',
                          key: 'Remove',
                          onClick: event =>
                            onDeleteTechnologies(event, item.id),
                          icon: <DeleteOutlined />,
                        },
                      ],
                    }}
                  >
                    <MoreOutlined onClick={onShowTagMenu} />
                  </Dropdown>
                </div>
              </Option>
            ))}
          </OptGroup>
        </Select>
      </Spin>
    </div>
  );
};

TechnologiesSelect.propTypes = {
  currentEmpoyeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isProfilePage: PropTypes.bool,
};

export default memo(TechnologiesSelect);
