exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".userSkills-ee761{width:95%}.userSkills-ee761 .title-fc534{margin-bottom:12px;font-size:20px;font-weight:600;line-height:28px;color:#262626;margin-bottom:0}.userSkills-ee761 .skillsTag-6f920{margin:0px 4px 9px 0px}.userSkills-ee761 .header-baa94{position:relative;display:flex;justify-content:space-between;align-items:center;margin-bottom:12px}.userSkills-ee761 .header-baa94 .editIcon-15af0{position:absolute;top:10%;right:0;visibility:hidden;font-size:18px;color:#1677ff;cursor:pointer}.userSkills-ee761 .header-baa94:hover .editIcon-15af0{visibility:initial}.userSkills-ee761 .button-87221{margin-left:6px}", ""]);

// Exports
exports.locals = {
	"userSkills": "userSkills-ee761",
	"title": "title-fc534",
	"skillsTag": "skillsTag-6f920",
	"header": "header-baa94",
	"editIcon": "editIcon-15af0",
	"button": "button-87221"
};