import React from 'react';
import PropTypes from 'prop-types';

import { HOLIDAY_UPDATE_TYPES } from 'containers/CalendarPage/constants';
import Modal from 'components/Common/Modal';
import Select, { Option } from 'components/Common/Select';
import { Button } from 'components/Common';

import styles from './styles.scss';

const HolidayDeleteModal = ({
  holiday,
  onDelete,
  onCancel,
  deleteType,
  setDeleteType,
}) => {
  const onDeleteHandler = () => {
    onDelete(holiday);
  };

  return (
    <Modal
      open={!!holiday}
      title="Delete holiday"
      onCancel={onCancel}
      footer={
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            type="primary"
            onClick={onDeleteHandler}
            disabled={holiday?.repeat_type && !deleteType}
          >
            Delete
          </Button>
        </>
      }
    >
      {holiday?.repeat_type ? (
        <>
          <span className={styles.label}>Select holidays to delete</span>
          <Select
            value={deleteType}
            onChange={setDeleteType}
            className={styles.deleteType}
          >
            {Object.values(HOLIDAY_UPDATE_TYPES).map(({ key, title }) => (
              <Option value={key} key={key}>
                {title}
              </Option>
            ))}
          </Select>
        </>
      ) : (
        <span className={styles.label}>
          Are you sure you want to delete this holiday?
        </span>
      )}
    </Modal>
  );
};

HolidayDeleteModal.propTypes = {
  holiday: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  deleteType: PropTypes.string,
  setDeleteType: PropTypes.func.isRequired,
};

export default HolidayDeleteModal;
