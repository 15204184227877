import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isLoadingContracts: false,
  contracts: [],
  contractsFilters: {},
  totalContracts: 0,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_CONTRACTS:
    case types.CREATE_CONTRACT:
    case types.DELETE_CONTRACT:
    case types.UPDATE_CONTRACT:
      return state.set('isLoadingContracts', true).set('error', null);

    case types.CREATE_CONTRACT_SUCCESS:
    case types.UPDATE_CONTRACT_SUCCESS:
      return state.set('isLoadingContracts', false).set('error', null);
    case types.DELETE_CONTRACT_SUCCESS:
      return state
        .set('contracts', payload)
        .set('isLoadingContracts', false)
        .set('error', null);
    case types.GET_CONTRACTS_SUCCESS:
      return state
        .set('contracts', payload.data.data)
        .set('contractsFilters', payload.data.data_for_filters)
        .set('isLoadingContracts', false)
        .set('totalContracts', payload.headers['x-total-count']);

    case types.GET_CONTRACTS_FAILED:
    case types.CREATE_CONTRACT_FAILED:
    case types.DELETE_CONTRACT_FAILED:
    case types.UPDATE_CONTRACT_FAILED:
      return state.set('isLoadingContracts', false).set('error', payload.error);
    default:
      return state;
  }
}
