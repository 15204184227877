export const types = {
  LOGIN: 'auth/LOGIN',
  LOGIN_FAILED: 'auth/LOGIN_FAILED',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',

  LOGOUT: 'auth/LOGOUT',

  GET_USER: 'auth/GET_USER',
  GET_USER_SUCCESS: 'auth/GET_USER_SUCCESS',
  GET_USER_FAILED: 'auth/GET_USER_FAILED',
};
