import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { Line } from '@ant-design/charts';
import {
  getDashboardData,
  getDashboardChartConfig,
} from 'containers/DashboardEffectivenessPage/utils';
import { getCOMDataByRoles, comTotalLineStyle } from './utils';
import { COM_LABELS } from './constants';

import styles from './styles.scss';

const { Title } = Typography;

const DashboardCOM = ({ dashboardData: { months_com, average_com } }) => {
  const comDataByRoles = useMemo(() => getCOMDataByRoles(months_com), [
    months_com,
  ]);

  const comDataTotal = useMemo(
    () =>
      getDashboardData({
        data: months_com,
        average: average_com,
        currentLabel: COM_LABELS.CURRENT,
        averageLabel: COM_LABELS.AVG,
        value: 'com',
      }),
    [],
  );

  const comRolesConfig = getDashboardChartConfig({
    data: comDataByRoles,
    seriesField: 'role',
    lineStyle: comTotalLineStyle,
  });

  const comTotalConfig = getDashboardChartConfig({
    data: comDataTotal,
    seriesField: 'type',
  });

  return (
    <div className={styles.dashboardCOM}>
      <Title level={5}>COM%</Title>
      <div className={styles.container}>
        {comDataTotal && <Line {...comTotalConfig} />}
        {comDataByRoles && <Line {...comRolesConfig} />}
      </div>
    </div>
  );
};

DashboardCOM.propTypes = {
  dashboardData: PropTypes.shape({
    months_com: PropTypes.array,
    average_com: PropTypes.number,
  }),
};

export default memo(DashboardCOM);
