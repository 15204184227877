import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router/immutable';
import {
  getExchangeRates,
  editExchangeRates,
  createExchangeRates,
} from 'redux/ExchangeRates/actions';
import Component from './ExchangeRatesPage';

const withConnect = connect(
  state => ({ data: state.getIn(['exchangeRates']).toJS() }),
  { getExchangeRates, editExchangeRates, createExchangeRates, push },
);

export default compose(
  withConnect,
  withRouter,
)(Component);
