import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import classNames from 'classnames';

import styles from './styles.scss';

const TableButton = ({ ...props }) => {
  const buttonColor = props.danger ? '#f00' : '#08c';

  return (
    <Button
      {...props}
      ghost
      size="small"
      style={{ color: props.disabled ? '#d9d9d9' : buttonColor }}
      className={classNames([styles.tableButton, props.className])}
    />
  );
};

TableButton.defaultProps = {
  danger: false,
};

TableButton.propTypes = {
  className: PropTypes.string,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TableButton;
