exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dashboardOverlay-7c1f6{position:relative;max-height:250px;overflow:auto;box-shadow:0 4px 20px 0 rgba(112,112,128,.1);border-radius:8px}.dashboardOverlay-7c1f6 .menu-5555d{border-radius:0}.dashboardOverlay-7c1f6 .menu-5555d .checkbox-f96f6{margin-right:8px}.dashboardOverlay-7c1f6 .menu-5555d .menuItem-33443{padding:12px}.dashboardOverlay-7c1f6 .footer-3c535{position:sticky;bottom:0;display:flex;justify-content:space-between;padding:12px 16px;background-color:#fff}.dashboardOverlay-7c1f6 .footer-3c535 .applyButton-269f3{padding:5px 12px}.dashboardOverlay-7c1f6 .footer-3c535 .resetButton-98ecf{padding:0}", ""]);

// Exports
exports.locals = {
	"dashboardOverlay": "dashboardOverlay-7c1f6",
	"menu": "menu-5555d",
	"checkbox": "checkbox-f96f6",
	"menuItem": "menuItem-33443",
	"footer": "footer-3c535",
	"applyButton": "applyButton-269f3",
	"resetButton": "resetButton-98ecf"
};