import { takeLatest, call, put } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';
import { SAGA_MESSAGES } from 'redux/sagaMessages';

import { sagaErrorHandler } from 'utils/common';
import { types } from './types';

function* getExchangeRateForDate({ payload }) {
  try {
    const response = yield call(API.getExchangeRateForDate, payload);

    if (!response.data) {
      response.data = {
        date: payload,
        value: 0,
      };
    }

    yield put({
      type: types.GET_EXCHANGE_RATE_FOR_DATE_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_EXCHANGE_RATE_FOR_DATE_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* getExchangeRateForMonth({ payload: { period } }) {
  try {
    const { data } = yield call(API.getExchangeRateForMonth, period);
    yield put({ type: types.GET_EXCHANGE_RATES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_EXCHANGE_RATES_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* editExchangeRateForMonth({
  payload: { currentDate, date, ...payload },
}) {
  try {
    yield call(API.editExchangeRateForMonth, date, payload);
    yield call(getExchangeRateForMonth, { payload: { period: currentDate } });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_EXCHANGE_RATES_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

function* createExchangeRate({ payload: { currentDate, ...payload } }) {
  try {
    yield call(API.createExchangeRate, payload);
    yield call(getExchangeRateForMonth, { payload: { period: currentDate } });
    message.success(SAGA_MESSAGES.SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_EXCHANGE_RATES_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_EXCHANGE_RATES, getExchangeRateForMonth);
  yield takeLatest(types.UPDATE_EXCHANGE_RATES, editExchangeRateForMonth);
  yield takeLatest(types.CREATE_EXCHANGE_RATES, createExchangeRate);
  yield takeLatest(types.GET_EXCHANGE_RATE_FOR_DATE, getExchangeRateForDate);
}
