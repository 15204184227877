import React, { useState, useEffect, useMemo } from 'react';
import { Form, Calendar } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import DatePicker from 'components/Common/DatePicker';
import HolidayRepeatModal from 'components/CalendarCommon/HolidayRepeatModal';
import PageLoader from 'components/Common/PageLoader';
import PageHeader from 'components/Common/PageHeader';
import { Loader } from 'components/Common';
import { HolidayModal, CalendarDataCell } from 'components/CalendarCommon';
import HolidayDeleteModal from 'components/CalendarCommon/HolidayDeleteModal';
import {
  DATE_FORMAT,
  HH_mm,
  HH_mm_zz,
  MONTH_FORMAT,
} from 'utils/timeConstants';
import { hasRights } from 'utils/permissions';
import { useRoleContext } from 'utils/hooks';
import { calendarSelector } from 'redux/selectors';
import {
  createHoliday,
  deleteHoliday,
  getCalendar,
  updateHoliday,
} from 'redux/Calendar/actions';

import { calculateDate, groupCalendar } from './utils';
import {
  CALENDAR_EXPAND,
  CALENDAR_PERMISSIONS,
  HOLIDAY_COMMON,
  HOLIDAY_UPDATE_TYPES,
} from './constants';
import styles from './styles.scss';

const { useForm } = Form;

const CalendarPage = () => {
  const [month, setMonth] = useState(dayjs());
  const [holidayDate, setHolidayDate] = useState(null);
  const [holidayData, setHolidayData] = useState(null);
  const [isHolidayRepeatModal, setIsHolidayRepeatModal] = useState(null);
  const [deletingHoliday, setDeletingHoliday] = useState(null);
  const [deleteType, setDeleteType] = useState(null);
  const [step, setStep] = useState(0);

  const role = useRoleContext();

  const [form] = useForm();

  const dispatch = useDispatch();

  const { calendar, isCalendarLoading } = useSelector(calendarSelector);

  const calendarObject = useMemo(() => groupCalendar(calendar), [calendar]);

  useEffect(() => {
    const { from, to } = calculateDate(month);

    dispatch(
      getCalendar({
        from,
        to,
        expand: CALENDAR_EXPAND,
      }),
    );
  }, [month]);

  const onAddHoliday = date => {
    setHolidayDate(date);
    form.setFieldsValue({
      [HOLIDAY_COMMON.holiday_date]: date,
      [HOLIDAY_COMMON.is_all_day]: true,
      [HOLIDAY_COMMON.repeat_type]: null,
    });
  };

  const onEditHoliday = holiday => {
    if (holiday.repeat_type) {
      setIsHolidayRepeatModal(true);
    }

    setHolidayData(holiday);
    setHolidayDate(holiday.holiday_date);
    form.setFieldsValue({
      ...holiday,
      [HOLIDAY_COMMON.holiday_date]: dayjs(holiday.holiday_date),
      [HOLIDAY_COMMON.holiday_time]:
        holiday.holiday_time && dayjs(holiday.holiday_time, HH_mm),
      [HOLIDAY_COMMON.repeat_until_date]:
        holiday.repeat_until_date && dayjs(holiday.repeat_until_date),
    });
  };

  const onHideModal = () => {
    setHolidayDate(false);
    setHolidayData(null);
    form.resetFields();
    setIsHolidayRepeatModal(false);
    setStep(0);
  };

  const onOpenDeleteModal = holiday => {
    setDeletingHoliday(holiday);
  };

  const onHideDeleteModal = () => {
    setDeleteType(null);
    setDeletingHoliday(null);
  };

  const onDeleteHoliday = holiday => {
    const { from, to } = calculateDate(month);

    dispatch(
      deleteHoliday({
        from,
        to,
        id: holiday.id,
        [HOLIDAY_COMMON.delete_type]:
          deleteType || HOLIDAY_UPDATE_TYPES.current.key,
      }),
    );

    onHideDeleteModal();
  };

  const onSubmit = values => {
    const { from, to } = calculateDate(month);

    const formattedValues = {
      ...values,
      [HOLIDAY_COMMON.holiday_date]: dayjs(
        values[HOLIDAY_COMMON.holiday_date],
      ).format(DATE_FORMAT),
      from,
      to,
    };

    if (HOLIDAY_COMMON.holiday_time in values) {
      formattedValues[HOLIDAY_COMMON.holiday_time] = dayjs(
        values[HOLIDAY_COMMON.holiday_time],
      ).format(HH_mm_zz);
    }

    if (HOLIDAY_COMMON.repeat_until_date in values) {
      formattedValues[HOLIDAY_COMMON.repeat_until_date] = dayjs(
        values[HOLIDAY_COMMON.repeat_until_date],
      ).format(DATE_FORMAT);
    }

    if (holidayData) {
      dispatch(
        updateHoliday({
          ...formattedValues,
          id: holidayData.id,
          update_type: values.update_type || HOLIDAY_UPDATE_TYPES.current.key,
        }),
      );
    } else {
      dispatch(createHoliday(formattedValues));
    }

    onHideModal();
  };

  const onMonthPickerChange = currentDate => {
    setMonth(currentDate);
  };

  const canUpdate = hasRights(role, CALENDAR_PERMISSIONS.updateHoliday);

  const disabledDate = currentDate => !currentDate.isSame(month, 'month');

  if (isEmpty(calendarObject) || isCalendarLoading) return <PageLoader />;

  return (
    <Loader loading={isCalendarLoading}>
      <HolidayModal
        holidayDate={holidayDate}
        holidayData={holidayData}
        onSubmit={onSubmit}
        form={form}
        onCancel={onHideModal}
        isHolidayRepeatModal={isHolidayRepeatModal}
      />
      <HolidayRepeatModal
        holidayDate={holidayDate}
        holidayData={holidayData}
        onSubmit={onSubmit}
        form={form}
        onCancel={onHideModal}
        isHolidayRepeatModal={isHolidayRepeatModal}
        step={step}
        setStep={setStep}
      />
      <HolidayDeleteModal
        holiday={deletingHoliday}
        onDelete={onDeleteHoliday}
        onCancel={onHideDeleteModal}
        deleteType={deleteType}
        setDeleteType={setDeleteType}
      />
      <PageHeader
        title="Calendar"
        extra={
          <>
            <DatePicker
              picker="month"
              allowClear={false}
              onChange={onMonthPickerChange}
              defaultValue={month}
              format={MONTH_FORMAT}
            />
          </>
        }
      />
      <Calendar
        dateCellRender={CalendarDataCell({
          calendarObject,
          onAddHoliday,
          onEditHoliday,
          onDeleteHoliday: onOpenDeleteModal,
          canUpdate,
        })}
        value={month}
        headerRender={() => <></>}
        className={styles.calendar}
        disabledDate={disabledDate}
      />
    </Loader>
  );
};

export default CalendarPage;
