import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { Line } from '@ant-design/charts';
import { getGpmData, getGpmConfig } from './utils';

import styles from './styles.scss';

const { Title } = Typography;

const DashboardSubcontractorsGPM = ({ monthData, period }) => {
  const gpmData = useMemo(() => getGpmData(monthData, period), [
    monthData,
    period,
  ]);

  const gpmConfig = useMemo(() => getGpmConfig(gpmData), [gpmData]);

  return (
    <div className={styles.dashboardSubcontractorsGPM}>
      <Title level={5}>Margin</Title>
      <Line {...gpmConfig} />
    </div>
  );
};

DashboardSubcontractorsGPM.propTypes = {
  monthData: PropTypes.object.isRequired,
  period: PropTypes.array.isRequired,
};

export default DashboardSubcontractorsGPM;
