import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntdSelect } from 'antd';
import classNames from 'classnames';

import styles from './styles.scss';

const { Option: AntdOption, OptGroup: AntdOptGroup } = AntdSelect;

const Option = props => <AntdOption {...props} />;

const OptGroup = props => <AntdOptGroup {...props} />;

const Select = ({ ...props }) => (
  <AntdSelect
    {...props}
    size="large"
    className={classNames([styles.select, props.className])}
  />
);

Select.propTypes = {
  className: PropTypes.string,
};

export { Option, OptGroup };

export default Select;
