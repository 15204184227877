import { actionFactory } from 'redux/factories';
import { types } from './types';

export const updatePlanById = actionFactory(types.UPDATE_PLAN_BY_ID);
export const deletePlanById = actionFactory(types.DELETE_PLAN_BY_ID);
export const getData = actionFactory(types.GET_DATA);
export const createPlan = actionFactory(types.CREATE_PLAN);
export const createPlanByType = actionFactory(types.CREATE_PLAN_BY_TYPE);
export const updatePlanByType = actionFactory(types.UPDATE_PLAN_BY_TYPE);
export const deletePlanByType = actionFactory(types.DELETE_PLAN_BY_TYPE);
export const setTableData = actionFactory(types.SET_TABLE_DATA);
export const getRmEmployees = actionFactory(types.GET_RM_EMPLOYEES);
export const getRmProjects = actionFactory(types.GET_RM_PROJECTS);
