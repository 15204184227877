import React, { useMemo } from 'react';
import { Avatar, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import {
  getStatusTag,
  getTeamName,
} from 'components/ProfilesCommon/ProfileDetails/utils';
import Tag from 'components/Common/Tag';
import { PROFILES_PERMISSIONS } from 'containers/ProfilesPage/constants';
import { hasRights } from 'utils/permissions';

import styles from './styles.scss';

const { Title } = Typography;

const UserInfo = ({
  nameRu,
  nameEn,
  onEditClick,
  status,
  team_id,
  teams,
  jiraUser,
  isSummary,
  role,
}) => {
  const statusTag = useMemo(() => getStatusTag(status, isSummary), [
    status,
    isSummary,
  ]);
  const teamName = useMemo(() => getTeamName(team_id, teams), [team_id, teams]);

  return (
    <div className={styles.userInfo}>
      <Avatar
        src={get(
          jiraUser,
          ['avatarUrls', '48x48'],
          'https://www.gravatar.com/avatar/e5ffa8aecb9a36a76676407d8bfd1d36?d=mm&s=48',
        )}
        size={120}
        className={styles.avatar}
      />
      {!isSummary &&
        hasRights(role, PROFILES_PERMISSIONS.updateProfilePage) && (
          <EditOutlined className={styles.editIcon} onClick={onEditClick} />
        )}
      <div className={styles.userName}>
        <Title className={styles.englishName}>{nameEn}</Title>
        <span className={styles.russianName}>{nameRu}</span>
      </div>
      <div>
        {statusTag}
        {teamName && <Tag color="gold">{teamName}</Tag>}
      </div>
    </div>
  );
};

UserInfo.propTypes = {
  nameRu: PropTypes.string,
  team_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nameEn: PropTypes.string,
  onEditClick: PropTypes.func,
  status: PropTypes.string,
  teams: PropTypes.array,
  jiraUser: PropTypes.object,
  isSummary: PropTypes.bool,
  role: PropTypes.string,
};

export default UserInfo;
