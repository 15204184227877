import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import API from 'utils/api';
import { sagaErrorHandler } from 'utils/common';
import { SAGA_MESSAGES } from 'redux/sagaMessages';

import { summaryEmployeesSelector } from 'redux/selectors';
import { message } from 'antd';
import { REDUX_DATA_HELPERS } from 'utils/helpers';
import { types } from './types';

function* getSummaryEmployees({ payload }) {
  try {
    const { data } = yield call(API.getSummaryEmployees, payload);

    yield put({ type: types.GET_SUMMARY_EMPLOYEES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_SUMMARY_EMPLOYEES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getSummaryEmployee({ payload }) {
  try {
    const { data } = yield call(API.getSummaryEmployee, {
      summaryEmployeeId: payload.summaryId,
      params: payload.params,
    });

    yield put({ type: types.GET_SUMMARY_EMPLOYEE_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_SUMMARY_EMPLOYEE_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getSummaryEmployeeSalaries({ payload }) {
  try {
    const { data } = yield call(API.getSummaryEmployeeSalaries, payload);

    yield put({
      type: types.GET_SUMMARY_EMPLOYEE_SALARIES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_SUMMARY_EMPLOYEE_SALARIES_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getSummaryEmployeeComments({ payload }) {
  try {
    const { data } = yield call(API.getSummaryEmployeeComments, payload);

    yield put({
      type: types.GET_SUMMARY_EMPLOYEE_COMMENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_SUMMARY_EMPLOYEE_COMMENTS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getSummaryEmployeeExtraPayments({ payload }) {
  try {
    const { data } = yield call(API.getSummaryEmployeeExtraPayments, payload);

    yield put({
      type: types.GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getSummarySocialRequests({ payload }) {
  try {
    const { data } = yield call(API.getSummarySocialRequests, payload);

    yield put({
      type: types.GET_SUMMARY_SOCIAL_REQUESTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_SUMMARY_SOCIAL_REQUESTS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* getSummarySocialRequestsInfo({ payload }) {
  try {
    const { data } = yield call(API.getSummarySocialRequestsInfo, payload);

    yield put({
      type: types.GET_SUMMARY_SOCIAL_REQUESTS_INFO_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_SUMMARY_SOCIAL_REQUESTS_INFO_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* createSummarySocialRequest({
  payload: { attachments, sort, summary_employee_id, size, ...payload },
}) {
  try {
    const { data } = yield call(API.createSummarySocialRequest, {
      ...payload,
      summary_employee_id,
    });

    if (attachments) {
      const attachmentUrls = yield all(
        attachments.map(attachment =>
          call(API.uploadSocialRequestAttachmentUrl, {
            id: data.id,
            file_name: attachment.name,
          }),
        ),
      );

      yield all(
        attachmentUrls.map((url, index) =>
          call(
            API.uploadAttachmentToAWS,
            url.data.signed_url,
            attachments[index],
          ),
        ),
      );

      const formattedAttachments = attachments.map((attachment, index) => ({
        file_name: attachment.name,
        url: attachmentUrls[index].data.signed_url,
      }));

      yield all(
        formattedAttachments.map(({ file_name, url }) =>
          call(API.uploadSocialRequestAttachment, {
            id: data.id,
            payload: {
              file_name,
              url,
              social_request_id: data.id,
            },
          }),
        ),
      );
    }

    yield call(getSummarySocialRequests, {
      payload: { sort, summary_employee_id, size },
    });
    yield call(getSummarySocialRequestsInfo, {
      payload: { summary_employee_id, ...payload },
    });

    message.success(SAGA_MESSAGES.CREATE_SOCIAL_REQUEST_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_SUMMARY_SOCIAL_REQUEST_FAILED,
      SAGA_MESSAGES.CREATE_SOCIAL_REQUEST_FAILED,
    );
  }
}

function* updateSummarySocialRequest({ payload }) {
  try {
    const { data } = yield call(API.updateSummarySocialRequest, payload);

    const { summaryEmployeeSocialRequests } = yield select(
      summaryEmployeesSelector,
    );

    const updatedData = REDUX_DATA_HELPERS.updateData({
      data: summaryEmployeeSocialRequests,
      editedItem: data,
      id: payload.social_request_id,
    });

    yield put({
      type: types.UPDATE_SUMMARY_SOCIAL_REQUEST_SUCCESS,
      payload: updatedData,
    });

    message.success(SAGA_MESSAGES.UPDATE_SOCIAL_REQUEST_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_SUMMARY_SOCIAL_REQUEST_FAILED,
      SAGA_MESSAGES.UPDATE_SOCIAL_REQUEST_FAILED,
    );
  }
}

function* deleteSummarySocialRequest({ payload }) {
  try {
    yield call(API.deleteSummarySocialRequest, payload);

    const { summaryEmployeeSocialRequests } = yield select(
      summaryEmployeesSelector,
    );

    const updatedData = REDUX_DATA_HELPERS.removeItem({
      data: summaryEmployeeSocialRequests,
      id: payload,
    });

    yield put({
      type: types.DELETE_SUMMARY_SOCIAL_REQUEST_SUCCESS,
      payload: updatedData,
    });

    message.success(SAGA_MESSAGES.DELETE_SOCIAL_REQUEST_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_SUMMARY_SOCIAL_REQUEST_FAILED,
      SAGA_MESSAGES.DELETE_SOCIAL_REQUEST_FAILED,
    );
  }
}

function* getSummaryEmployeeProfiles({ payload }) {
  try {
    const { data } = yield call(API.getSummaryEmployeeProfiles, payload);

    yield put({ type: types.GET_SUMMARY_PROFILES_SUCCESS, payload: data });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_SUMMARY_PROFILES_FAILED,
      SAGA_MESSAGES.ERROR,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_SUMMARY_EMPLOYEES, getSummaryEmployees);
  yield takeLatest(types.GET_SUMMARY_EMPLOYEE, getSummaryEmployee);
  yield takeLatest(
    types.GET_SUMMARY_EMPLOYEE_SALARIES,
    getSummaryEmployeeSalaries,
  );
  yield takeLatest(
    types.GET_SUMMARY_EMPLOYEE_COMMENTS,
    getSummaryEmployeeComments,
  );
  yield takeLatest(
    types.GET_SUMMARY_EMPLOYEE_EXTRA_PAYMENTS,
    getSummaryEmployeeExtraPayments,
  );
  yield takeLatest(types.GET_SUMMARY_SOCIAL_REQUESTS, getSummarySocialRequests);
  yield takeLatest(
    types.GET_SUMMARY_SOCIAL_REQUESTS_INFO,
    getSummarySocialRequestsInfo,
  );
  yield takeLatest(
    types.CREATE_SUMMARY_SOCIAL_REQUEST,
    createSummarySocialRequest,
  );
  yield takeLatest(
    types.UPDATE_SUMMARY_SOCIAL_REQUEST,
    updateSummarySocialRequest,
  );
  yield takeLatest(
    types.DELETE_SUMMARY_SOCIAL_REQUEST,
    deleteSummarySocialRequest,
  );
  yield takeLatest(types.GET_SUMMARY_PROFILES, getSummaryEmployeeProfiles);
}
