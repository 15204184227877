import React from 'react';
import { Button, notification } from 'antd';
import get from 'lodash/get';

import { getType } from 'components/Notification/utils';

import { menuItems } from './constants';

import styles from './styles.scss';

export function getSelectedKeys(items, pathname, key = 1) {
  let defaultKey = key;
  const pathnameFirstPart = `/${pathname.split('/')[1]}`;

  items.forEach(item => {
    if (item.items) {
      defaultKey = getSelectedKeys(item.items, pathname, defaultKey);
    } else if (
      pathname === item.route.path ||
      pathnameFirstPart === item.route.path
    ) {
      defaultKey = item.key;
    }
  });

  return defaultKey;
}

export function getItems(role, user) {
  const formatter = items => {
    const result = items.reduce((memo, item) => {
      const buf = memo;
      const currentItem = { ...item };

      if (currentItem.route && currentItem.route.scopes(role, user)) {
        buf.push(currentItem);
      } else if (currentItem.items) {
        const bufItems = formatter(currentItem.items);

        if (bufItems.length !== 0) {
          currentItem.items = bufItems;
          buf.push(currentItem);
        }
      }

      return buf;
    }, []);

    return result;
  };

  return formatter(menuItems);
}

export const showNotification = notificationMessage => {
  const issues = get(notificationMessage, 'issues', []);

  return notification[getType(issues) || 'info']({
    message: get(notificationMessage, 'title', ''),
    description: (
      <div>
        <p>{get(notificationMessage, 'subtitle', '')}</p>
        <ul className={styles.notificationContent}>
          {issues.map(issue => (
            <a key={get(issue, 'title', '')} href={get(issue, 'path', '')}>
              <li className={styles.messageTitle}>{get(issue, 'title', '')}</li>
            </a>
          ))}
        </ul>
        <div className={styles.notificationFooter}>
          <Button onClick={() => notification.destroy()}>Close all</Button>
        </div>
      </div>
    ),
  });
};
