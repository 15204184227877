import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { isEmpty } from 'lodash';
import { EditOutlined } from '@ant-design/icons';

import OrganizationsList from 'containers/Organizations/OrganizationsList';

import DetailsContainer from '../../../Common/DetailsContainer';
import OrganizationItem from './OrganizationItem';
import styles from './styles.scss';

const ClientOrganizations = ({
  organizations = [],
  editOrganization,
  addOrganization,
  deleteAccount,
  editAccount,
  createAccount,
  isLoading,
  clientId,
  role,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [chosenOrganizationId, setChosenOrganizationId] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onShowModal = () => setIsVisible(true);
  const onHideModal = () => {
    setIsVisible(false);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setChosenOrganizationId(selectedRows.map(item => item.id));
    },
  };

  const onAddOrganization = () => {
    addOrganization(chosenOrganizationId);
    onHideModal();
  };

  useEffect(() => {
    setSelectedRowKeys(organizations.map(item => item.id));
    setChosenOrganizationId(organizations.map(item => item.id));
  }, [organizations]);

  return (
    <>
      <DetailsContainer
        name="Organizations"
        extra={
          <Button
            onClick={onShowModal}
            size="small"
            icon={<EditOutlined />}
            type="link"
          />
        }
      >
        {isEmpty(organizations) ? (
          <div className={styles.addOrganizationButtonContainer}>
            <Button type="dashed" onClick={onShowModal}>
              Add organization
            </Button>
          </div>
        ) : (
          organizations.map(({ ...organization }) => (
            <OrganizationItem
              key={`${organization.id}`}
              organization={organization}
              editOrganization={editOrganization}
              deleteAccount={deleteAccount}
              createAccount={createAccount}
              editAccount={editAccount}
              isLoading={isLoading}
              role={role}
            />
          ))
        )}
      </DetailsContainer>
      <Modal
        open={isVisible}
        onOk={onShowModal}
        onCancel={onHideModal}
        destroyOnClose
        width="70%"
        footer={[
          <Button onClick={onHideModal}>Close</Button>,
          <Button type="primary" onClick={onAddOrganization}>
            {isEmpty(selectedRowKeys) ? 'Clear organizations' : 'Update'}
          </Button>,
        ]}
      >
        <OrganizationsList
          isOrganizationsListInModal
          clientId={clientId}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
        />
      </Modal>
    </>
  );
};

ClientOrganizations.propTypes = {
  organizations: PropTypes.array,
  addOrganization: PropTypes.func,
  deleteAccount: PropTypes.func,
  editOrganization: PropTypes.func,
  createAccount: PropTypes.func,
  editAccount: PropTypes.func,
  isLoading: PropTypes.bool,
  clientId: PropTypes.string,
  role: PropTypes.string,
};

export default ClientOrganizations;
