exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".tooltipContent-4d5fe{box-shadow:0 4px 20px 0 rgba(112,112,128,.1);padding:20px;border:1px solid #d9d9d9;border-radius:8px;background-color:#fff}.tooltipContent-4d5fe .label-2f28b{display:inline-block;margin-bottom:16px;font-size:14px;color:#262626}.tooltipContent-4d5fe .items-cac1a .item-86c26{display:flex;align-items:center;justify-content:space-between;min-width:150px;margin-bottom:12px;font-size:12px;color:#595959}.tooltipContent-4d5fe .items-cac1a .left-59341{display:flex;align-items:center;margin-right:10px}.tooltipContent-4d5fe .items-cac1a .icon-975e8{width:12px;height:12px;margin-right:6px;border-radius:4px}", ""]);

// Exports
exports.locals = {
	"tooltipContent": "tooltipContent-4d5fe",
	"label": "label-2f28b",
	"items": "items-cac1a",
	"item": "item-86c26",
	"left": "left-59341",
	"icon": "icon-975e8"
};