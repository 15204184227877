exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".profitAndLossTotal-40b9f{width:34%}.profitAndLossTotal-40b9f .header-bbfc8{display:flex;justify-content:flex-end;margin-bottom:5px}.profitAndLossTotal-40b9f .header-bbfc8 span{width:30%;text-align:center;font-weight:500}.profitAndLossTotal-40b9f .item-0a256{display:flex;justify-content:space-between;align-items:center;margin-bottom:6px;padding:10px 6px;text-align:center;border:1px solid #d9d9d9}.profitAndLossTotal-40b9f .item-0a256 div{width:30%}.profitAndLossTotal-40b9f .item-0a256 .icon-fa56c{width:10%}.profitAndLossTotal-40b9f .item-0a256 .firstPeriod-2c6ed,.profitAndLossTotal-40b9f .item-0a256 .secondPeriod-4dd64{width:30%}.profitAndLossTotal-40b9f .item-0a256 .title-cce47{position:relative;width:25%;text-align:left;font-weight:500}.profitAndLossTotal-40b9f .label-afc6d{display:inline-block;margin-bottom:4px;font-size:14px;opacity:.8}.profitAndLossTotal-40b9f .info-8b8da{position:absolute;align-self:flex-start;margin-left:3px}.profitAndLossTotal-40b9f .info-8b8da svg{width:8px}", ""]);

// Exports
exports.locals = {
	"profitAndLossTotal": "profitAndLossTotal-40b9f",
	"header": "header-bbfc8",
	"item": "item-0a256",
	"icon": "icon-fa56c",
	"firstPeriod": "firstPeriod-2c6ed",
	"secondPeriod": "secondPeriod-4dd64",
	"title": "title-cce47",
	"label": "label-afc6d",
	"info": "info-8b8da"
};