import { actionFactory } from 'redux/factories';
import { types } from './types';

export const getIncomingInvoices = actionFactory(types.GET_INCOMING_INVOICES);
export const createIncomingInvoice = actionFactory(
  types.CREATE_INCOMING_INVOICE,
);
export const updateIncomingInvoice = actionFactory(
  types.UPDATE_INCOMING_INVOICE,
);
export const deleteIncomingInvoice = actionFactory(
  types.DELETE_INCOMING_INVOICE,
);
export const approveIncomingInvoiceByFinancier = actionFactory(
  types.APPROVE_INCOMING_INVOICE_FINANCIER,
);
export const declineIncomingInvoiceByFinancier = actionFactory(
  types.DECLINE_INCOMING_INVOICE_FINANCIER,
);
export const approveIncomingInvoiceByApprover = actionFactory(
  types.APPROVE_INCOMING_INVOICE_APPROVER,
);
export const declineIncomingInvoiceByApprover = actionFactory(
  types.DECLINE_INCOMING_INVOICE_APPROVER,
);
