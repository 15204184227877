import { actionFactory } from 'redux/factories';
import { types } from './types';

export const getInvoices = actionFactory(types.GET_INVOICES);
export const getInvoice = actionFactory(types.GET_INVOICE);
export const approveInvoice = actionFactory(types.APPROVE_INVOICE);
export const declineInvoice = actionFactory(types.DECLINE_INVOICE);
export const updateInvoice = actionFactory(types.UPDATE_INVOICE);
export const deleteInvoice = actionFactory(types.DELETE_INVOICE);
export const getInvoiceStatuses = actionFactory(types.GET_INVOICE_STATUSES);
export const getInvoiceTemplates = actionFactory(types.GET_INVOICE_TEMPLATES);
export const getInvoiceAudit = actionFactory(types.GET_INVOICE_AUDIT);
