import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { Line } from '@ant-design/charts';
import { getRateData, getRateConfig } from './utils';

import styles from './styles.scss';

const { Title } = Typography;

const DashboardSubcontractorsRate = ({ monthData, period }) => {
  const rateData = useMemo(() => getRateData(monthData, period), [
    monthData,
    period,
  ]);

  const rateConfig = useMemo(() => getRateConfig(rateData), [rateData]);

  return (
    <div className={styles.dashboardSubcontractorsRate}>
      <Title level={5}>Rates comparing</Title>
      <Line {...rateConfig} />
    </div>
  );
};

DashboardSubcontractorsRate.propTypes = {
  monthData: PropTypes.object.isRequired,
  period: PropTypes.array.isRequired,
};

export default DashboardSubcontractorsRate;
