import dayjs from 'dayjs';

export const formatProjectInfo = ({ infoAboutCurrentProject }) =>
  infoAboutCurrentProject.reduce((acc, cur) => {
    const tempArr = [];
    for (const [date, value] of Object.entries(cur)) {
      if (date === 'title') continue;

      tempArr.push({
        date,
        value: +value,
        title: cur.title,
      });
    }
    const sortedDatesArr = tempArr.sort((a, b) => dayjs(a.date).diff(b.date));

    return [...acc, ...sortedDatesArr];
  }, []);
