import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  teams: [],
  isTeamsLoading: false,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_TEAMS:
      return state.set('isTeamsLoading', true);

    case types.GET_TEAMS_SUCCESS:
      return state.set('isTeamsLoading', false).set('teams', payload);

    case types.GET_TEAMS_FAILED:
      return state.set('isTeamsLoading', false);
    default:
      return state;
  }
}
