import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DatePicker as AntdDatePicker } from 'antd';

import styles from './styles.scss';

const DatePicker = ({ ...props }) => (
  <AntdDatePicker
    {...props}
    className={classNames([props.className, styles.datePicker])}
  />
);

DatePicker.propTypes = {
  className: PropTypes.string,
};

export default DatePicker;
