import React from 'react';
import { Upload } from 'antd';

export const getColumns = () => [
  {
    title: 'File',
    dataIndex: 'url',
    width: '200px',
    render: (value, record) => (
      <Upload
        showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
        fileList={[
          // @ts-ignore
          {
            name: record.title,
            status: 'done',
            url: value,
            uid: `fileTable__${record.title}`,
          },
        ]}
      />
    ),
  },
];
