import { takeLatest, call, put, select } from 'redux-saga/effects';
import { message } from 'antd';

import API from 'utils/api';

import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { sagaErrorHandler } from 'utils/common';
import { contractsSelector } from 'redux/selectors';
import { REDUX_DATA_HELPERS } from 'utils/helpers';
import { types } from './types';

function* getContracts({ payload }) {
  try {
    const { data, headers } = yield call(API.getClientsContracts, {
      ...payload,
    });
    yield put({
      type: types.GET_CONTRACTS_SUCCESS,
      payload: { data, headers },
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.GET_CONTRACTS_FAILED,
      SAGA_MESSAGES.LOAD_DATA_ERROR,
    );
  }
}

function* createContract({ payload: { values, page, size, sort } }) {
  try {
    const { data } = yield call(API.createContract, values);
    yield call(getContracts, { payload: { page, size, sort } });
    yield put({
      type: types.CREATE_CONTRACT_SUCCESS,
      payload: { data },
    });
    message.success(SAGA_MESSAGES.CREATE_CONTRACT_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.CREATE_CONTRACT_FAILED,
      SAGA_MESSAGES.CREATE_CONTRACT_FAILED,
    );
  }
}

function* deleteContract({ payload: { id } }) {
  try {
    yield call(API.deleteContract, id);

    const { contracts } = yield select(contractsSelector);

    const updatedData = REDUX_DATA_HELPERS.removeItem({ id, data: contracts });

    yield put({
      type: types.DELETE_CONTRACT_SUCCESS,
      payload: updatedData,
    });
    message.success(SAGA_MESSAGES.DELETE_CONTRACT_SUCCESS);
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.DELETE_CONTRACT_FAILED,
      SAGA_MESSAGES.DELETE_CONTRACT_FAILED,
    );
  }
}

function* updateContract({ payload: { values, page, size, id, sort } }) {
  try {
    yield call(API.updateContract, { values, id });
    message.success(SAGA_MESSAGES.UPDATE_CONTRACT_SUCCESS);

    yield call(getContracts, { payload: { page, size, sort } });

    yield put({
      type: types.UPDATE_CONTRACT_SUCCESS,
    });
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.UPDATE_CONTRACT_FAILED,
      SAGA_MESSAGES.UPDATE_CONTRACT_FAILED,
    );
  }
}

export default function* saga() {
  yield takeLatest(types.GET_CONTRACTS, getContracts);
  yield takeLatest(types.CREATE_CONTRACT, createContract);
  yield takeLatest(types.DELETE_CONTRACT, deleteContract);
  yield takeLatest(types.UPDATE_CONTRACT, updateContract);
}
