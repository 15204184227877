exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".button-4e85e{padding:0}.text_expanded-3dca6{max-height:none}.text_expanded-3dca6:after{content:\"\";display:none}.noteInfo-950a5{display:flex;justify-content:space-between;align-items:center;margin-bottom:12px}.noteMenu-7b423{cursor:pointer}.noteContainer-30e44{display:flex;flex-direction:column}.noteContainer-30e44 p{width:80%}.noteContainer-30e44 .content-f82cc{padding:12px 20px;border:1px solid #d9d9d9;border-radius:8px}.noteContainer-30e44 .created-a6720{font-size:14px;color:#8c8c8c}.tools-7fe17 svg{cursor:pointer;margin-left:10px;width:20px;height:20px}.noteButtons-ae74b{margin-top:5px}.saveButton-4ef09{margin-left:5px}", ""]);

// Exports
exports.locals = {
	"button": "button-4e85e",
	"text_expanded": "text_expanded-3dca6",
	"noteInfo": "noteInfo-950a5",
	"noteMenu": "noteMenu-7b423",
	"noteContainer": "noteContainer-30e44",
	"content": "content-f82cc",
	"created": "created-a6720",
	"tools": "tools-7fe17",
	"noteButtons": "noteButtons-ae74b",
	"saveButton": "saveButton-4ef09"
};