import { all } from 'redux-saga/effects';

import auditsSaga from 'redux/Audits/saga';
import authSaga from 'redux/Auth/saga';
import averageSalariesSaga from 'redux/AverageSalaries/saga';
import banksSaga from 'redux/Banks/saga';
import calendarSaga from 'redux/Calendar/saga';
import clientsSaga from 'redux/Clients/saga';
import contractsSaga from 'redux/Contracts/saga';
import costRatesSaga from 'redux/CostRates/saga';
import currenciesSaga from 'redux/Currencies/saga';
import dashboardSaga from 'redux/Dashboard/saga';
import dealsSaga from 'redux/Deals/saga';
import employeePositionsSaga from 'redux/EmployeePositions/saga';
import employeesSaga from 'redux/Employees/saga';
import exchangeRatesSaga from 'redux/ExchangeRates/saga';
import extraPaymentsSaga from 'redux/ExtraPayments/saga';
import incomingInvoicesSaga from 'redux/IncomingInvoices/saga';
import invoicesSaga from 'redux/Invoices/saga';
import invoiceTypesSaga from 'redux/InvoiceTypes/saga';
import jiraSaga from 'redux/Jira/saga';
import leadsSaga from 'redux/Leads/saga';
import notificationsSaga from 'redux/Notifications/saga';
import organizationsSaga from 'redux/Organizations/saga';
import paysheetsSaga from 'redux/Paysheets/saga';
import projectProfitsSaga from 'redux/ProjectProfits/saga';
import resourceManagementSaga from 'redux/ResourceManagement/saga';
import teamsSaga from 'redux/Teams/saga';
import technologiesSaga from 'redux/Technologies/saga';
import usersSaga from 'redux/Users/saga';
import summaryEmployeesSaga from 'redux/SummaryEmployees/saga';
import rolesSaga from 'redux/Roles/saga';
import socialRequestsSaga from 'redux/SocialRequests/saga';

export default function* rootSaga() {
  yield all([
    auditsSaga(),
    authSaga(),
    averageSalariesSaga(),
    banksSaga(),
    calendarSaga(),
    clientsSaga(),
    contractsSaga(),
    costRatesSaga(),
    currenciesSaga(),
    dashboardSaga(),
    dealsSaga(),
    employeePositionsSaga(),
    employeesSaga(),
    exchangeRatesSaga(),
    extraPaymentsSaga(),
    incomingInvoicesSaga(),
    invoicesSaga(),
    invoiceTypesSaga(),
    jiraSaga(),
    leadsSaga(),
    notificationsSaga(),
    organizationsSaga(),
    paysheetsSaga(),
    projectProfitsSaga(),
    resourceManagementSaga(),
    teamsSaga(),
    technologiesSaga(),
    usersSaga(),
    summaryEmployeesSaga(),
    rolesSaga(),
    socialRequestsSaga(),
  ]);
}
