import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../styles.scss';
import { formatBalanceValue } from '../../utils';

const RequestsInfoItem = ({ title, value, type }) => (
  <div className={styles.balanceItem}>
    <span className={styles.title}>{title}</span>
    <span
      className={classNames([
        styles.value,
        { [styles.valueZero]: !Number(value) },
      ])}
    >
      {formatBalanceValue({ value, type })}
    </span>
  </div>
);

RequestsInfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RequestsInfoItem;
