import React from 'react';

import TableActions from 'components/Common/TableActions';

import { CURRENCIES_COMMON } from './constants';

export const getColumns = ({ onEdit, onDeleteCurrency }) => [
  {
    title: CURRENCIES_COMMON.id_title,
    dataIndex: CURRENCIES_COMMON.id,
  },
  {
    title: CURRENCIES_COMMON.name_title,
    dataIndex: CURRENCIES_COMMON.name,
  },
  {
    title: ' ',
    dataIndex: CURRENCIES_COMMON.actions,
    width: 50,
    align: 'center',
    render: (_, record) => (
      <TableActions
        onEdit={onEdit(record)}
        onDelete={onDeleteCurrency(record.id)}
      />
    ),
  },
];
