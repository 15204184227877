import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';
import { formatMoney } from '../utils';

const MoneyCell = ({ value }) => (
  <div className={styles.moneyCellWrapper}>{`${formatMoney(value)}`}</div>
);

MoneyCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MoneyCell;
