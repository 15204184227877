import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Text } = Typography;

const LeadScore = ({ event }) => {
  const { audited_changes } = event;
  const isPreviosScoreExisted = Boolean(audited_changes.score[0]);
  const [previousScore, currentScore] = audited_changes.score;

  return (
    <div>
      <Text strong>Score updated</Text>
      {isPreviosScoreExisted ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {previousScore}
          <ArrowRightOutlined style={{ margin: '0px 5px' }} />
          {currentScore}
        </div>
      ) : (
        <div>{currentScore}</div>
      )}
    </div>
  );
};

LeadScore.propTypes = {
  event: PropTypes.object,
};

export default LeadScore;
