export const USERS_TABLE_COMMON = {
  id_title: 'ID',
  id: 'id',
  name_title: 'Name',
  name: 'name',
  email_title: 'Email',
  email: 'email',
  created_at_title: 'Created at',
  created_at: 'created_at',
  updated_at_title: 'Edited at',
  updated_at: 'updated_at',
  role_id_title: 'Role',
  role_id: 'role_id',
};

export const USERS_EXPAND = ['permissions'];

export const USERS_DEFAULT_SIZE = 9999;
