import React, { useState, useEffect } from 'react';
import {
  EnvironmentOutlined,
  InfoCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Form } from 'antd';
import PropTypes from 'prop-types';

import { OrganizationModal } from 'components/OrganizationsCommon';
import { COUNTRY_OBJECT } from 'components/OrganizationsCommon/OrganizationForm/constants';
import styles from '../styles.scss';

const OrganizationItem = ({
  organization,
  editOrganization,
  deleteAccount,
  editAccount,
  createAccount,
  isLoading,
  role,
}) => {
  /// ↓↓↓↓ NEEDS REFACTORING ↓↓↓↓
  if (!organization) {
    return null;
  }

  const [isVisible, setIsVisible] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [form] = Form.useForm();
  const [accountsData, setAccountsData] = useState([]);

  const onHideModal = () => setIsVisible(false);

  const onShowModal = () => setIsVisible(true);

  useEffect(() => {
    setCurrentOrganization(organization);
    form.setFieldsValue(organization);
  }, [organization]);

  const onEdit = values => {
    if (currentOrganization) {
      editOrganization({
        organizationId: currentOrganization.id,
        data: values,
      });
    }

    onHideModal();
  };

  const { title = 'title', details, country_code = 'USA' } = organization;

  return (
    <div className={styles.organizationItem}>
      <OrganizationModal
        isShowAccountTable={false}
        currentOrganization={currentOrganization}
        isVisible={isVisible}
        onHideModal={onHideModal}
        form={form}
        onEdit={onEdit}
        accounts={organization.accounts}
        deleteAccount={deleteAccount}
        createAccount={createAccount}
        editAccount={editAccount}
        isLoading={isLoading}
        accountsData={accountsData}
        setAccountsData={setAccountsData}
        role={role}
      />
      <Button
        onClick={onShowModal}
        size="small"
        type="link"
        icon={<EditOutlined />}
        className={styles.organizationIcon}
      />
      <p className={styles.organizationTitle}>{title}</p>
      <p className={styles.organizationLocation}>
        <EnvironmentOutlined />
        <span className={styles.organizationValue}>
          {COUNTRY_OBJECT[country_code]}
        </span>
      </p>
      {details && (
        <div className={styles.organizationInfo}>
          <InfoCircleOutlined style={{ marginTop: '4px' }} />
          <span className={styles.organizationValue}>
            {details.split('\n').map((key, i) => (
              <div key={`${key}_${i}`}>{key}</div>
            ))}
          </span>
        </div>
      )}
    </div>
  );
};

OrganizationItem.propTypes = {
  organization: PropTypes.object,
};

export default OrganizationItem;
