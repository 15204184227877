exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".invoiceDeatailsContainer-51c70{display:flex;justify-content:space-evenly;min-height:250px;position:relative}.invoiceDetailsSelectContainer-cfc8b{width:450px}.invoiceRequiredField-174de{color:red;margin-left:5px}.templatesSelect-6f7c7{display:flex;flex-direction:column}.invoiceIsFullScreen-f42ec{margin-bottom:0;flex-grow:1}.invoiceIsNotFullScreen-91bf4{margin-bottom:30px;flex-grow:1}.invoiceDetailsWrapper-2300c{display:flex;justify-content:space-between}", ""]);

// Exports
exports.locals = {
	"invoiceDeatailsContainer": "invoiceDeatailsContainer-51c70",
	"invoiceDetailsSelectContainer": "invoiceDetailsSelectContainer-cfc8b",
	"invoiceRequiredField": "invoiceRequiredField-174de",
	"templatesSelect": "templatesSelect-6f7c7",
	"invoiceIsFullScreen": "invoiceIsFullScreen-f42ec",
	"invoiceIsNotFullScreen": "invoiceIsNotFullScreen-91bf4",
	"invoiceDetailsWrapper": "invoiceDetailsWrapper-2300c"
};