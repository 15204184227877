import { getMonthNameWithYear } from 'utils/helpers';

const getDashboardDataObject = ({ type, value, month }) => ({
  type,
  value,
  month,
});

export const getDashboardData = ({
  data,
  average,
  currentLabel,
  averageLabel,
  value,
}) =>
  data &&
  data.reduce((acc, curr) => {
    const currentObj = getDashboardDataObject({
      type: currentLabel,
      value: +curr[value],
      month: curr.month,
    });

    const averageObj = getDashboardDataObject({
      type: averageLabel,
      value: +average,
      month: curr.month,
    });

    return [...acc, currentObj, averageObj];
  }, []);

export const getDashboardChartConfig = ({
  data,
  seriesField,
  lineStyle = {},
}) => ({
  data,
  xField: 'month',
  yField: 'value',
  seriesField,
  xAxis: {
    type: 'time',
    label: {
      formatter: getMonthNameWithYear,
    },
  },
  yAxis: {
    label: {
      formatter: value => `${value}%`,
    },
  },
  tooltip: {
    formatter: datum => ({
      name: datum[seriesField],
      value: `${datum.value.toFixed(2)}%`,
    }),
  },
  legend: {
    maxRow: 3,
    flipPage: false,
  },
  ...lineStyle,
});
