import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import Modal from 'components/Common/Modal';
import Select, { Option } from 'components/Common/Select';
import { STATUSES } from 'containers/LeadPage/constants';

import { SCORES_ARRAY } from '../LeadScoreSelector/constants';

const { Item } = Form;

const LeadEditModal = ({ form, users, onSubmit, onCancel, open }) => (
  <Modal
    title="Edit lead"
    okText="Save"
    onOk={form.submit}
    onCancel={onCancel}
    open={open}
  >
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Item label="Owner" name="owner_id">
        <Select>
          {users.map(user => (
            <Option key={user.id} value={user.id}>
              {user.name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item label="Score" name="score">
        <Select>
          {SCORES_ARRAY.map(score => (
            <Option key={score} value={score}>
              {score}
            </Option>
          ))}
        </Select>
      </Item>
      <Item label="Status" name="status">
        <Select>
          {STATUSES.map(status => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
      </Item>
    </Form>
  </Modal>
);

LeadEditModal.propTypes = {
  form: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  users: PropTypes.array,
  open: PropTypes.bool,
};

export default LeadEditModal;
