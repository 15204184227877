import React from 'react';

import PropTypes from 'prop-types';
import { Layout, Result } from 'antd';
import ErrorBoundary from 'containers/ErrorBoundary';
import { hasRights } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { authSelector } from 'redux/selectors';
import HeaderContent from './Header/Header';
import styles from './styles.scss';

const { Content } = Layout;

export const ScopeContext = React.createContext({});

const AuthLayout = ({ children, scopes }) => {
  const { user } = useSelector(authSelector);

  const role = user.role_type;

  const hasScope = scopes(role, user);

  return (
    <Layout className={styles.contentLayout}>
      <HeaderContent role={role} />
      <ErrorBoundary>
        <Content className={styles.content}>
          {hasScope ? (
            <ScopeContext.Provider value={{ role }}>
              {children}
            </ScopeContext.Provider>
          ) : (
            <Result
              status="403"
              title="403"
              subTitle="You don't have access to this resource"
            />
          )}
        </Content>
      </ErrorBoundary>
    </Layout>
  );
};

AuthLayout.propTypes = {
  scopes: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default AuthLayout;
