exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".salary-a54f5{font-weight:600}.hoverButton-8c887{display:none}.field-4f787:hover .hoverButton-8c887{display:inline-block}.field-4f787{width:fit-content}.actions-9bd1c{margin-bottom:15px}.actionButton-9f211{margin-left:5px;margin-bottom:5px}.editIcon-1cdba{margin-left:10px;color:#1677ff}.deleteIcon-10664{margin-left:10px;color:tomato}", ""]);

// Exports
exports.locals = {
	"salary": "salary-a54f5",
	"hoverButton": "hoverButton-8c887",
	"field": "field-4f787",
	"actions": "actions-9bd1c",
	"actionButton": "actionButton-9f211",
	"editIcon": "editIcon-1cdba",
	"deleteIcon": "deleteIcon-10664"
};