import React, { useState, useEffect, useMemo, createRef } from 'react';
import { Row, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import PageHeader from 'components/Common/PageHeader';
import EmployeeAddForm from 'components/EmployeeCommon/EmployeeAddForm';
import TableWithSettings from 'components/Common/TableWithSettings';
import { Button } from 'components/Common';
import { EMPLOYEE_OK_STATUSES, SORT_DIRECTIONS } from 'utils/constants';
import {
  employeePositionsSelector,
  employeesSelector,
  organizationsSelector,
  summaryEmployeesSelector,
  teamsSelector,
} from 'redux/selectors';
import {
  loadEmployees,
  createEmployee,
  uploadEmployees,
} from 'redux/Employees/actions';
import { getOrganizations } from 'redux/Organizations/actions';
import { getTeams } from 'redux/Teams/actions';
import { getPositions } from 'redux/EmployeePositions/actions';
import { getSummaryEmployees } from 'redux/SummaryEmployees/actions';
import { useRoleContext } from 'utils/hooks';
import { hasRights } from 'utils/permissions';

import { getTableColumns, formatEmployeeFilters } from './utils';
import { EMPLOYEE_PROFILES_PERMISSIONS } from './constants';
import styles from './styles.scss';

const EmployeesProfiles = () => {
  const {
    isEmployeesLoading,
    employees,
    employeesFilters,
    employeesTotal,
  } = useSelector(employeesSelector);

  const { summaryEmployees } = useSelector(summaryEmployeesSelector);

  const { organizationsFull } = useSelector(organizationsSelector);

  const { teams } = useSelector(teamsSelector);

  const { positions } = useSelector(employeePositionsSelector);

  const role = useRoleContext();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filterInfo, setFilterInfo] = useState({
    status: EMPLOYEE_OK_STATUSES,
  });
  const [sortInfo, setSortInfo] = useState({
    columnKey: 'id',
    order: SORT_DIRECTIONS.ascend,
  });
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    pageSize: 25,
    total: employeesTotal,
  });

  const dispatch = useDispatch();

  const searchInput = createRef();

  useEffect(() => {
    dispatch(getOrganizations());
    dispatch(getSummaryEmployees({ size: 9999 }));
    dispatch(getTeams());
    dispatch(getPositions());
    dispatch(
      loadEmployees({
        size: paginationInfo.pageSize,
        page: paginationInfo.current,
        sort: `${sortInfo.columnKey}:${sortInfo.order}`,
        filters: filterInfo,
      }),
    );
  }, []);

  useEffect(() => {
    setPaginationInfo(prev => ({ ...prev, total: employeesTotal }));
  }, [employeesTotal]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const handleSearch = (_, confirm) => {
    confirm();
  };

  const handleReset = clearFilters => {
    clearFilters();
  };

  const onUserCreate = values => {
    dispatch(createEmployee(values));
  };

  const onTableChange = (pagination, filters, { columnKey, order }) => {
    const formattedFilters = formatEmployeeFilters(filters);
    setFilterInfo(formattedFilters);
    setSortInfo({ columnKey, order });
    setPaginationInfo(prev => ({ ...prev, ...pagination }));

    dispatch(
      loadEmployees({
        size: pagination.pageSize,
        page: pagination.current,
        sort: `${columnKey}:${order}`,
        filters: formattedFilters,
      }),
    );
  };

  const columns = useMemo(
    () =>
      getTableColumns({
        organizations: organizationsFull,
        filterInfo,
        teams,
        filters: employeesFilters,
        sortInfo,
        handleSearch,
        handleReset,
        searchInput,
        role,
      }),
    [organizationsFull, filterInfo, teams, employeesFilters, sortInfo],
  );

  const uploadProps = {
    name: 'file',
    beforeUpload: () => false,
    showUploadList: false,
    onChange({ file }) {
      if (file.status !== 'uploading' && file.status !== 'error') {
        dispatch(
          uploadEmployees({
            file,
            size: paginationInfo.pageSize,
            page: paginationInfo.current,
            sort: `${sortInfo.columnKey}:${sortInfo.order}`,
            filters: filterInfo,
          }),
        );
      }

      if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <EmployeeAddForm
        teams={teams}
        organizations={organizationsFull}
        positions={positions}
        summaryEmployees={summaryEmployees}
        onSubmit={onUserCreate}
        isVisible={isModalVisible}
        hideModal={hideModal}
        isLoading={isEmployeesLoading}
      />

      <PageHeader
        title="Employees' profiles"
        extra={
          <Row type="flex">
            <>
              {hasRights(
                role,
                EMPLOYEE_PROFILES_PERMISSIONS.importProfiles,
              ) && (
                <Upload {...uploadProps}>
                  <Button>
                    <UploadOutlined />
                    Import from file
                  </Button>
                </Upload>
              )}
              {hasRights(role, EMPLOYEE_PROFILES_PERMISSIONS.addProfile) && (
                <Button
                  onClick={showModal}
                  type="primary"
                  style={{ marginLeft: 16 }}
                >
                  Add employee
                </Button>
              )}
            </>
          </Row>
        }
      />
      <TableWithSettings
        pagination={paginationInfo}
        dataSource={employees}
        rowKey="id"
        columns={columns}
        scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
        loading={isEmployeesLoading}
        onChange={onTableChange}
        style={{ margin: 0 }}
        className={styles.employeeTable}
      />
    </>
  );
};

export default EmployeesProfiles;
