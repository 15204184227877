import React from 'react';
import { Button, Upload, Row, message } from 'antd';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';

import PageHeader from 'components/Common/PageHeader';

import styles from './styles.scss';

const Header = ({ title, handleAdd, onLoadFile }) => {
  const props = {
    name: 'file',
    beforeUpload: () => false,
    showUploadList: false,
    onChange({ file }) {
      if (file.status !== 'uploading' && file.status !== 'error') {
        console.log(file);
        onLoadFile({ file });
      }

      if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
    },
  };

  return (
    <PageHeader
      title={<span className={styles.title}>{title}</span>}
      extra={
        <Row type="flex" className={styles.extra}>
          <Upload {...props}>
            <Button>
              <Icon type="upload" /> Загрузить из файла
            </Button>
          </Upload>
          <Button onClick={handleAdd} type="primary" style={{ marginLeft: 16 }}>
            Добавить
          </Button>
        </Row>
      }
    />
  );
};

Header.propTypes = {
  extra: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
  onLoadFile: PropTypes.func,
};

export default Header;
