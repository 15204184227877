export const types = {
  GET_PAYSHEETS: 'paysheets/GET_PAYSHEETS',
  GET_PAYSHEETS_SUCCESS: 'paysheets/GET_PAYSHEETS_SUCCESS',
  GET_PAYSHEETS_FAILED: 'paysheets/GET_PAYSHEETS_FAILED',

  UPDATE_PAYSHEETS_VALUES: 'paysheets/UPDATE_PAYSHEETS_VALUES',
  UPDATE_PAYSHEETS_VALUES_SUCCESS: 'paysheets/UPDATE_PAYSHEETS_VALUES_SUCCESS',
  UPDATE_PAYSHEETS_VALUES_FAILED: 'paysheets/UPDATE_PAYSHEETS_VALUES_FAILED',

  DOWNLOAD_PAYSHEETS: 'paysheets/DOWNLOAD_PAYSHEETS',
  DOWNLOAD_PAYSHEETS_SUCCESS: 'paysheets/DOWNLOAD_PAYSHEETS_SUCCESS',
  DOWNLOAD_PAYSHEETS_FAILED: 'paysheets/DOWNLOAD_PAYSHEETS_FAILED',

  GET_AVERAGE_SALARY: 'paysheets/GET_AVERAGE_SALARY',
  GET_AVERAGE_SALARY_SUCCESS: 'paysheets/GET_AVERAGE_SALARY_SUCCESS',
  GET_AVERAGE_SALARY_FAILED: 'paysheets/GET_AVERAGE_SALARY_FAILED',

  GET_AVERAGE_SALARY_PERIOD: 'paysheets/GET_AVERAGE_SALARY_PERIOD',
  GET_AVERAGE_SALARY_PERIOD_SUCCESS:
    'paysheets/GET_AVERAGE_SALARY_PERIOD_SUCCESS',
  GET_AVERAGE_SALARY_PERIOD_FAILED:
    'paysheets/GET_AVERAGE_SALARY_PERIOD_FAILED',
};
