import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isExchangeRatesLoading: false,
  exchangeRates: [],
  exhangeRateForDate: null,
  exchangeDate: null,
  exchangeRate: null,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_EXCHANGE_RATES:
    case types.CREATE_EXCHANGE_RATES:
    case types.UPDATE_EXCHANGE_RATES:
    case types.GET_EXCHANGE_RATE_FOR_DATE:
      return state.set('isExchangeRatesLoading', true);

    case types.GET_EXCHANGE_RATES_SUCCESS:
    case types.CREATE_EXCHANGE_RATES_SUCCESS:
    case types.UPDATE_EXCHANGE_RATES_SUCCESS:
      return state
        .set('exchangeRates', payload)
        .set('isExchangeRatesLoading', false);
    case types.GET_EXCHANGE_RATE_FOR_DATE_SUCCESS:
      return state
        .set('exhangeRateForDate', payload)
        .set('exchangeDate', payload.date)
        .set('exchangeRate', payload.value)
        .set('isExchangeRatesLoading', false);

    case types.CREATE_EXCHANGE_RATES_FAILED:
    case types.GET_EXCHANGE_RATES_FAILED:
    case types.UPDATE_EXCHANGE_RATES_FAILED:
    case types.GET_EXCHANGE_RATE_FOR_DATE_FAILED:
      return state.set('isExchangeRatesLoading', false);
    default:
      return state;
  }
}
