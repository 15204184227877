import {
  findMonthItemData,
  tooltipFormatter,
} from 'containers/DashboardSubcontractors/utils';
import { getMonthNameWithYear, getArrayOfMonth } from 'utils/helpers';

export const getHoursData = (monthData, period) => {
  const arrayOfMonth = getArrayOfMonth(period);

  return (
    monthData &&
    arrayOfMonth.map(month => {
      const monthItemData = findMonthItemData(monthData, month);

      return {
        month,
        type: 'Hours',
        value: monthItemData ? +monthItemData[1].spent_hours : 0,
      };
    })
  );
};

export const getHoursConfig = data => ({
  data,
  xField: 'month',
  yField: 'value',
  xAxis: {
    label: {
      formatter: getMonthNameWithYear,
      autoHide: true,
      autoRotate: false,
    },
  },
  yAxis: {
    label: {
      formatter: value => `${value} h.`,
    },
  },
  tooltip: {
    formatter: tooltipFormatter,
  },
});
