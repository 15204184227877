import { actionFactory } from 'redux/factories';
import { types } from './types';

export const loadUsers = actionFactory(types.GET_USERS);
export const updateUser = actionFactory(types.UPDATE_USER);
export const deleteUser = actionFactory(types.DELETE_USER);
export const createUser = actionFactory(types.CREATE_USER);
export const setCurrentUser = actionFactory(types.SET_CURRENT_USER);
export const restoreUser = actionFactory(types.RESTORE_USER);
export const getUserPermissions = actionFactory(types.GET_USER_PERMISSIONS);
