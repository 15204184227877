import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader } from 'components/Common/';
import {
  LeadTimeline,
  LeadsPageHeader,
  AnalyticsCommon,
  DetailsCommon,
  LeadFiltersCommon,
  LeadModal,
} from 'components/LeadCommon/';
import { groupById } from 'components/ClientCommon/ClientModal/ClientInvoice/ClientInvoiceWorklogsStep/utils';
import { leadsSelector, usersSelector } from 'redux/selectors';
import {
  getLeadData,
  getLeadActivities,
  getLeadAttachments,
  updateLeadActivities,
  getLeadAudit,
  saveLeadNote,
  updateLeadNote,
  getLeadGeo,
  getLeadNotes,
  updateLead,
} from 'redux/Leads/actions';
import { loadUsers } from 'redux/Users/actions';
import { useRoleContext } from 'utils/hooks';

import { getModifiedLeatAudit } from './utils';
import styles from './styles.scss';

const Lead = () => {
  const [tab, setTab] = useState('All');
  const [showModal, setShowModal] = useState(false);

  const { id } = useParams();

  const role = useRoleContext();

  const dispatch = useDispatch();

  const {
    leadData,
    leadActivities,
    leadAttachments,
    leadAudit,
    leadNotes,
    leadGeo,
    isLeadsLoading,
  } = useSelector(leadsSelector);

  const { users } = useSelector(usersSelector);

  useEffect(() => {
    dispatch(getLeadData({ id }));
    dispatch(getLeadActivities({ id }));
    dispatch(getLeadAttachments({ id }));
    dispatch(getLeadAudit({ id }));
    dispatch(getLeadNotes({ id }));
    dispatch(getLeadGeo({ id }));
    dispatch(loadUsers());
  }, []);

  const notesObject = useMemo(() => groupById(leadNotes), [leadNotes]);

  const onChangeTab = tab => {
    setTab(tab);
  };

  const onUpdateLeadActivities = () => {
    dispatch(updateLeadActivities({ id }));
  };

  const onShowModal = () => setShowModal(true);

  const onHideLeadModal = () => {
    setShowModal(false);
  };

  const onSaveNote = values => {
    if (values.text) {
      dispatch(saveLeadNote({ id, text: values.text, leadId: id }));
    }

    onHideLeadModal();
  };

  const onUpdateLeadNote = ({ text, noteId }) => {
    dispatch(updateLeadNote({ text, noteId, id }));
  };

  const modifiedLeadAudit = useMemo(() => getModifiedLeatAudit({ leadAudit }), [
    leadAudit,
  ]);

  const ownerName = useMemo(
    () => users.find(user => user.id === leadData.owner_id)?.name || 'No owner',
    [users, leadData],
  );

  const onLeadUpdate = values => dispatch(updateLead({ ...values, id }));

  return (
    <div>
      <LeadModal
        showModal={showModal}
        onSaveNote={onSaveNote}
        onHideLeadModal={onHideLeadModal}
      />
      <Row gutter={24}>
        <Col md={{ span: 6 }}>
          <LeadsPageHeader leadData={leadData} ownerName={ownerName} />
          <DetailsCommon
            leadData={leadData}
            users={users}
            ownerName={ownerName}
            onLeadUpdate={onLeadUpdate}
          />
          <AnalyticsCommon
            onUpdateLeadActivities={onUpdateLeadActivities}
            leadData={leadData}
            leadActivities={leadActivities}
            leadGeo={leadGeo}
          />
        </Col>
        <Col md={{ span: 18 }}>
          <div className={styles.leadsContent}>
            <div className={styles.clientTimeLine}>
              <LeadFiltersCommon
                tab={tab}
                onChangeTab={onChangeTab}
                onShowModal={onShowModal}
              />
              <Loader loading={isLeadsLoading}>
                <LeadTimeline
                  leadData={leadData}
                  leadAudit={modifiedLeadAudit}
                  tab={tab}
                  updateLeadNote={onUpdateLeadNote}
                  onShowModal={onShowModal}
                  users={users}
                  leadAttachments={leadAttachments}
                  role={role}
                  notesObject={notesObject}
                />
              </Loader>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Lead;
