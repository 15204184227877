import React from 'react';
import PropTypes from 'prop-types';
import { Button as AntdButton } from 'antd';
import classNames from 'classnames';

import styles from './styles.scss';

const Button = ({ children, ...props }) => (
  <AntdButton
    {...props}
    className={classNames({
      [styles.button]: true,
      [props.className]: !!props.className,
    })}
  >
    {children}
  </AntdButton>
);

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default Button;
