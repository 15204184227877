exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".projectCell-99b3a{font-weight:700}.actorAvatar-9d121{margin-right:7px}.allocationCheckbox-e5c23{margin-right:12px}.secondStepIsFullScreen-501d7{margin-bottom:0px}.secondStepIsNotFullScreen-c19c8{margin-bottom:30px}.secondStepIsShowDealDescription-19746{display:flex;width:calc(100% - 315px)}.allocationSelect-ddc7b{min-width:70%}.allocationsTable-4bcbb{width:100%}", ""]);

// Exports
exports.locals = {
	"projectCell": "projectCell-99b3a",
	"actorAvatar": "actorAvatar-9d121",
	"allocationCheckbox": "allocationCheckbox-e5c23",
	"secondStepIsFullScreen": "secondStepIsFullScreen-501d7",
	"secondStepIsNotFullScreen": "secondStepIsNotFullScreen-c19c8",
	"secondStepIsShowDealDescription": "secondStepIsShowDealDescription-19746",
	"allocationSelect": "allocationSelect-ddc7b",
	"allocationsTable": "allocationsTable-4bcbb"
};