import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import { getPopupContainerHandler } from 'utils/helpers';

import styles from './styles.scss';

const { Option } = Select;
const { Item } = Form;

const ProjectSelect = ({ data = [] }) => {
  const filterOption = (input, option) =>
    option.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Item
      className={styles.formItem}
      label="Project"
      name="project"
      rules={[{ required: true, message: 'Select a project' }]}
    >
      <Select
        showSearch
        style={{ width: '100%' }}
        optionFilterProp="children"
        filterOption={filterOption}
        getPopupContainer={getPopupContainerHandler}
      >
        {data.map(item => (
          <Option key={item.id} value={item.id}>
            {item.key} - {item.name}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

ProjectSelect.propTypes = {
  data: PropTypes.array,
};

export default ProjectSelect;
