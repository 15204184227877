import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import PageHeader from 'components/Common/PageHeader';
import { Button, Loader } from 'components/Common';
import Table from 'components/Common/Table';
import BanksModal from 'components/BanksCommon/BanksModal';
import { getRowKey } from 'utils/helpers';
import { getBanks, updateBank, addBank, deleteBank } from 'redux/Banks/actions';
import { banksSelector } from 'redux/selectors';

import { getColumns } from './utils';

const BanksPage = () => {
  const [currentBank, setCurrentBank] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch = useDispatch();

  const { banks, isBanksLoading } = useSelector(banksSelector);

  useEffect(() => {
    dispatch(getBanks());
  }, []);

  const toggleModal = isVisible => () => {
    setIsModalVisible(isVisible);

    if (!isVisible) {
      setCurrentBank(null);
    }
  };

  const onEdit = bank => () => {
    setCurrentBank(bank);
    toggleModal(true)();
  };

  const onUpdateBank = bank => {
    dispatch(updateBank(bank));
    toggleModal(false)();
  };

  const onCreateBank = bank => {
    dispatch(addBank(bank));
    toggleModal(false)();
  };

  const onDeleteBank = id => () => {
    Modal.confirm({
      title: 'Delete bank',
      content: 'Are you sure you want to delete bank?',
      onOk: () => {
        dispatch(deleteBank(id));
        Modal.destroyAll();
      },
    });
  };

  const columns = useMemo(() => getColumns({ onEdit, onDeleteBank }), [banks]);

  return (
    <div>
      <PageHeader
        title="Banks"
        extra={
          <Button type="primary" onClick={toggleModal(true)}>
            Add bank
          </Button>
        }
      />
      <BanksModal
        currentBank={currentBank}
        isVisible={isModalVisible}
        onCreateBank={onCreateBank}
        onUpdateBank={onUpdateBank}
        toggleModal={toggleModal}
      />
      <Loader loading={isBanksLoading}>
        <Table
          columns={columns}
          dataSource={banks}
          pagination={false}
          rowKey={getRowKey}
        />
      </Loader>
    </div>
  );
};

export default BanksPage;
