exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".actionType-ed198{text-transform:lowercase;margin-left:5px}.actionContainer-8d248{display:flex;justify-content:space-between;margin-bottom:12px;font-size:16px;font-weight:600}.actionContainer-8d248 .tag-c1062{font-weight:400}.actionInfo-dc08a{display:flex}.dealButtons-15e1d{visibility:hidden}.dealButton-2053c{margin-right:15px}.endDealPickerContainer-7fdae{display:flex;justify-content:space-around;align-items:center;margin-top:30px}.dealMargins-58e35{margin:0 12px 0 0}.ClientOrganization-c8ec3{max-width:100px}.dealStarts-1e26e,.dealEnds-ba3fa{font-size:14px;font-weight:400;color:#8c8c8c}.dealInfo-1deb9:hover .dealButtons-15e1d{visibility:visible}.dealMenu-ba4a8{cursor:pointer}.dealPeriod-083c7{margin-right:12px;padding-right:12px;border-right:1px solid #d9d9d9}.summaryItem-48711{font-weight:600}.tableItem-42ac5{font-size:14px}.tableItem-42ac5>span{font-size:14px}", ""]);

// Exports
exports.locals = {
	"actionType": "actionType-ed198",
	"actionContainer": "actionContainer-8d248",
	"tag": "tag-c1062",
	"actionInfo": "actionInfo-dc08a",
	"dealButtons": "dealButtons-15e1d",
	"dealButton": "dealButton-2053c",
	"endDealPickerContainer": "endDealPickerContainer-7fdae",
	"dealMargins": "dealMargins-58e35",
	"ClientOrganization": "ClientOrganization-c8ec3",
	"dealStarts": "dealStarts-1e26e",
	"dealEnds": "dealEnds-ba3fa",
	"dealInfo": "dealInfo-1deb9",
	"dealMenu": "dealMenu-ba4a8",
	"dealPeriod": "dealPeriod-083c7",
	"summaryItem": "summaryItem-48711",
	"tableItem": "tableItem-42ac5"
};