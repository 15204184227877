exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dealsContainer-c4739{width:300px;display:flex;flex-direction:column;overflow-y:scroll;margin-bottom:50px;margin-left:15px;max-height:calc(90vh - 170px)}.dealsDescription-67ab8{margin-bottom:30px}.isFullScrenDealsDescription-05597{max-height:70vh}.isNotFullScreenDealDescription-091dc{max-height:45vh}.stepsContentContainerThirdOrFifthStep-a4bbd{display:flex;justify-content:center}.stepsContentCotainerOtherSteps-869d0{display:flex;justify-content:space-between}.stepsActionsContainer-dba93{margin-top:15px;display:flex;justify-content:space-between;bottom:8px;position:absolute;width:97%}.prevButton-bdb1d{margin:0 8px}.steps-9c78b{margin-bottom:15px}.organizationsAlert-6a38c{display:flex;justify-content:space-between}.downloadWorklogs-c72d4{color:#fff}", ""]);

// Exports
exports.locals = {
	"dealsContainer": "dealsContainer-c4739",
	"dealsDescription": "dealsDescription-67ab8",
	"isFullScrenDealsDescription": "isFullScrenDealsDescription-05597",
	"isNotFullScreenDealDescription": "isNotFullScreenDealDescription-091dc",
	"stepsContentContainerThirdOrFifthStep": "stepsContentContainerThirdOrFifthStep-a4bbd",
	"stepsContentCotainerOtherSteps": "stepsContentCotainerOtherSteps-869d0",
	"stepsActionsContainer": "stepsActionsContainer-dba93",
	"prevButton": "prevButton-bdb1d",
	"steps": "steps-9c78b",
	"organizationsAlert": "organizationsAlert-6a38c",
	"downloadWorklogs": "downloadWorklogs-c72d4"
};