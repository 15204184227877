import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Steps, Button, Modal, Alert, Form } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { isEmpty, get } from 'lodash';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useIndexedDBStore } from 'use-indexeddb';

import {
  INVOICE_UPDATE_ACIONS,
  DEFAULT_EDIT_MODAL_STATE_TOUCHED,
  INVOICE_UPDATE_MESSAGES,
  CLIENT_PERMISSIONS,
} from 'containers/ClientPage/constants';
import { BEGIN_MONTH_FORMAT, DATE_FORMAT } from 'utils/timeConstants';
import {
  calendarSelector,
  clientsSelector,
  jiraSelector,
} from 'redux/selectors';
import { getCalendar } from 'redux/Calendar/actions';
import {
  addClientInvoice,
  getAllocationsStepData,
  updateClientInvoice,
} from 'redux/Clients/actions';
import { DB_STORES } from 'config/indexedDB';
import { hasRights } from 'utils/permissions';

import { ClientInvoiceNotifications } from '..';
import {
  checkWorklogs,
  formatAllocations,
  formatLoggedAllocations,
  getProjectsFromWorklogsAndAllocations,
  getInvoiceStartEndDates,
  getInvoiceSteps,
} from './utils';
import {
  formatDeals,
  getExistingInvoiceAllocations,
} from '../ClientInvoiceAllocationsStep/utils';
import { groupById } from '../ClientInvoiceWorklogsStep/utils';

import styles from './styles.scss';

const { Step } = Steps;

const ClientInvoiceSteps = ({
  clientOrganizations,
  isFullScreenClientModal,
  onDeleteInvoice,
  clearInvoiceData,
  invoiceEditId,
  isInvoiceEdit,
  isInvoiceValuesTouched,
  setIsInvoiceValuesTouched,
  current,
  setCurrent,
  setInvoiceAlertsCount,
  invoiceAlertsCount,
  role,
  setIsInvoiceEditModalVisible,
}) => {
  const [isDisableNext, setIsDisableNext] = useState(false);
  const [isNextStep, setIsNextStep] = useState(false);
  const [invoiceAttachmentLink, setInvoiceAttachmentLink] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState([]);

  const [invoiceDate, setInvoiceDate] = useState(dayjs());
  const [invoiceProjects, setInvoiceProjects] = useState([]);
  const [isCreateCustomInvoice, setIsCreateCustomInvoice] = useState(false);

  const [allocationsDataSource, setAllocationsDataSource] = useState([]);
  const [allocationsDataSourceMap, setAllocationsDataSourceMap] = useState({});

  // Все allocations, доступные для выбранных проектов в выбранный период
  const [invoiceAllocations, setInvoiceAllocations] = useState({});

  // Allocations которые были присвоены какому-либо сотруднику
  const [loggedAllocations, setLoggedAllocations] = useState([]);

  const [isAddWorklogs, setIsAddWorklog] = useState(false);
  const [worklogsGroupedByProject, setWorklogsGroupedByProject] = useState({});
  const [draftSavedAt, setDraftSavedAt] = useState(null);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const [addWorklogForm] = Form.useForm();
  const [detailsForm] = Form.useForm();

  const dispatch = useDispatch();

  const { deleteByID } = useIndexedDBStore(DB_STORES.worklogs);

  const {
    client: { id },
    isClientsLoading,
    invoiceData,
    invoiceStatus,
    isAllocationsStepLoading,
    dealAllocations,
    worklogs,
    clientProjects,
    clientAllocations,
  } = useSelector(clientsSelector);

  const { calendar } = useSelector(calendarSelector);

  const { jiraRoles } = useSelector(jiraSelector);

  const customInvoiceHandler = e => {
    setIsCreateCustomInvoice(e.target.checked);

    if (e.target.checked) {
      setInvoiceProjects([]);
    }
  };

  const jiraRolesGroupedById = useMemo(() => groupById(jiraRoles), [jiraRoles]);

  const projectGroupedById = useMemo(() => groupById(clientProjects), [
    clientProjects,
  ]);

  const steps = useMemo(
    () =>
      getInvoiceSteps({
        projects: clientProjects,
        clientId: id,
        loggedAllocations,
        invoiceData,
        addWorklogForm,
        invoiceAllocations,
        worklogsGroupedByProject,
        detailsForm,
        invoiceItems,
        invoiceDate,
        invoiceProjects,
        jiraRolesGroupedById,
        projectGroupedById,
        allocationsDataSource,
        allocationsDataSourceMap,
        draftSavedAt,

        /// Boolean
        isClientsLoading,
        isFullScreenClientModal,
        isAddWorklogs,
        isCreateCustomInvoice,
        isInvoiceEdit,

        /// Functions
        setInvoiceAllocations,
        setLoggedAllocations,
        setIsInvoiceValuesTouched,
        setIsAddWorklog,
        setWorklogsGroupedByProject,
        setInvoiceItems,
        customInvoiceHandler,
        setInvoiceDate,
        setInvoiceProjects,
        setAllocationsDataSource,
        setAllocationsDataSourceMap,
        setDraftSavedAt,
      }),
    [
      clientProjects,
      isClientsLoading,
      isFullScreenClientModal,
      invoiceData,
      loggedAllocations,
      id,
      isAddWorklogs,
      invoiceAllocations,
      worklogsGroupedByProject,
      detailsForm?.getFieldValue('invoice_template_id'),
      invoiceItems,
      isCreateCustomInvoice,
      invoiceDate,
      invoiceProjects,
      isInvoiceEdit,
      jiraRolesGroupedById,
      projectGroupedById,
      allocationsDataSource,
      allocationsDataSourceMap,
      draftSavedAt,
    ],
  );

  const isWorklogsEmpty = useMemo(
    () => checkWorklogs(worklogsGroupedByProject),
    [worklogsGroupedByProject],
  );

  // Открывает модальное окно добавления ворклога с предзаполненными данными.
  // Используется в ClientInvoiceNotifications
  const addWorklogFromAlert = values => {
    setIsAddWorklog(true);
    addWorklogForm.setFieldsValue(values);
  };

  const next = () => {
    const keys = Object.keys(isInvoiceValuesTouched);
    const isFieldTouched = keys.some(item => isInvoiceValuesTouched[item]);

    if (isFieldTouched && current !== 0) {
      Modal.confirm({
        title: 'Are you sure ?',
        icon: <WarningOutlined />,
        content: 'You have unsaved data',
        okText: 'Ok',
        cancelText: 'Cancel',
        onOk: () => {
          setCurrent(current + 1);
          setIsInvoiceValuesTouched(DEFAULT_EDIT_MODAL_STATE_TOUCHED);
        },
      });
    } else {
      setCurrent(prev => prev + 1);
      setIsInvoiceValuesTouched(DEFAULT_EDIT_MODAL_STATE_TOUCHED);
    }
  };

  const clearInvoiceState = () => {
    setAllocationsDataSource([]);
    setAllocationsDataSourceMap({});
    setLoggedAllocations([]);
    setInvoiceAllocations([]);
    setInvoiceItems([]);
    setWorklogsGroupedByProject({});
  };

  const prev = () => {
    // Если инвойс уже создан и находимся на шаге Allocations - при нажатии Prev он полностью удаляется
    if (
      (current === 1 && !!invoiceData) ||
      (current === 3 && invoiceData && invoiceData.is_freeform)
    ) {
      Modal.confirm({
        title: 'Are you sure ?',
        icon: <WarningOutlined />,
        content: 'Created invoice will be deleted',
        okText: 'Ok',
        cancelText: 'Cancel',
        onOk: () => {
          setCurrent(0);
          onDeleteInvoice(invoiceData.id);
          setIsInvoiceValuesTouched(DEFAULT_EDIT_MODAL_STATE_TOUCHED);
          clearInvoiceData();
          clearInvoiceState();
          setIsInvoiceEditModalVisible(false);
        },
      });

      return;
    }

    // Clears state if invoice is not created
    if (current === 1 && !invoiceData) {
      setCurrent(0);
      clearInvoiceData();
      clearInvoiceState();
      setIsInvoiceValuesTouched(DEFAULT_EDIT_MODAL_STATE_TOUCHED);

      return;
    }

    const keys = Object.keys(isInvoiceValuesTouched);
    const isFieldTouched = keys.some(item => isInvoiceValuesTouched[item]);

    if (isFieldTouched) {
      Modal.confirm({
        title: 'Are you sure ?',
        icon: <WarningOutlined />,
        content: 'You have unsaved data',
        okText: 'Ok',
        cancelText: 'Cancel',
        onOk: () => {
          setCurrent(current - 1);
          setIsInvoiceValuesTouched(DEFAULT_EDIT_MODAL_STATE_TOUCHED);
          setIsNextStep(false);
        },
      });
    } else {
      setCurrent(current - 1);
      setIsInvoiceValuesTouched(DEFAULT_EDIT_MODAL_STATE_TOUCHED);
      setIsNextStep(false);
    }
  };

  useEffect(() => {
    if (invoiceData && isInvoiceEdit && isFirstRender) {
      const period = dayjs(invoiceData.period_from).format(BEGIN_MONTH_FORMAT);

      // Это можно рефакторить, теперь в инвойсе сразу приходит поле project_ids
      const invoiceProjects = getProjectsFromWorklogsAndAllocations(
        invoiceData.original_worklogs,
        invoiceData.allocations,
      );
      const { date_from, date_to } = getInvoiceStartEndDates(period);
      // Получаем все данные для шага Allocations
      dispatch(
        getAllocationsStepData({
          client_id: id,
          date_from,
          date_to,
          project_ids: invoiceProjects,
        }),
      );
      dispatch(getCalendar({ from: date_from, to: date_to }));
      setInvoiceProjects(invoiceProjects.map(project => project.toString()));

      // Устанавливает дефолтный шаг в зависимости от наличия подтвержденных данных.
      switch (true) {
        case Boolean(invoiceData.items):
          setCurrent(5);
          setIsFirstRender(false);

          break;

        case Boolean(invoiceData.original_items):
          setCurrent(4);
          setIsFirstRender(false);

          break;

        case Boolean(invoiceData.worklogs):
          setCurrent(3);
          setIsFirstRender(false);

          break;
        case Boolean(invoiceData.original_worklogs):
          setCurrent(2);
          setIsFirstRender(false);

          break;
      }
    }
  }, [invoiceData, isInvoiceEdit]);

  useEffect(() => {
    // Execute only for invoice editing. Setting up selected allocations once
    if (isInvoiceEdit && invoiceData && !loggedAllocations.length) {
      const period = dayjs(invoiceData.period_from).format(BEGIN_MONTH_FORMAT);

      const formattedDeals = formatDeals({
        deals: dealAllocations,
        period,
      });

      const invoiceAllocations = getExistingInvoiceAllocations({
        deals: formattedDeals,
        clientAllocations: clientAllocations,
        invoiceDataAllocations: invoiceData.allocations,
      });
      const formattedAllocations = formatLoggedAllocations({
        invoiceAllocations,
        loggedAllocations: invoiceData.allocations,
      });
      setLoggedAllocations(formattedAllocations);
      setInvoiceAllocations(invoiceAllocations);
      setInvoiceDate(dayjs(invoiceData.period_from));
    }
  }, [isAllocationsStepLoading, isInvoiceEdit, invoiceData]);

  useEffect(() => {
    if (
      invoiceStatus >= 200 &&
      invoiceStatus < 300 &&
      isNextStep &&
      current !== 2
    ) {
      if (isCreateCustomInvoice) {
        setCurrent(3);
        setIsCreateCustomInvoice(false);
      } else {
        next();
      }

      setIsNextStep(false);
    }
  }, [invoiceStatus]);

  // Вычисляет текущее состояние кнопки Next
  useEffect(() => {
    const hasOriginalItems = !isEmpty(get(invoiceData, 'original_items', []));

    switch (true) {
      case current === 0 && isCreateCustomInvoice:
      case current === 0 &&
        !isCreateCustomInvoice &&
        invoiceProjects.length &&
        Boolean(invoiceDate):
        setIsDisableNext(false);

        break;
      case current === 1 &&
        get(invoiceData, 'original_worklogs') &&
        !isInvoiceValuesTouched.allocations:
        setIsDisableNext(false);

        break;

      case current === 2 &&
        !isEmpty(get(invoiceData, 'worklogs', [])) &&
        !isInvoiceValuesTouched.worklogs:
        setIsDisableNext(false);

        break;

      case current === 3 && hasOriginalItems && !isInvoiceValuesTouched.details:
        setIsDisableNext(false);

        break;

      case current === 4 &&
        !isEmpty(get(invoiceData, 'items', [])) &&
        !isInvoiceValuesTouched.items:
        setIsDisableNext(false);

        break;

      default:
        setIsDisableNext(true);
        setIsNextStep(true);

        break;
    }
  }, [
    invoiceData,
    current,
    isInvoiceValuesTouched,
    invoiceProjects,
    isCreateCustomInvoice,
    invoiceDate,
  ]);

  useEffect(() => {
    if (invoiceData) {
      const { attachments } = invoiceData;

      if (Array.isArray(attachments) && attachments.length) {
        const worklogAttachment = attachments.find(elem =>
          elem.title.includes('worklogs'),
        );

        setInvoiceAttachmentLink(worklogAttachment?.url);
      }
    }
  }, [invoiceData]);

  const onGenerateCustomInvoice = () => {
    const period_from = dayjs(invoiceDate)
      .clone()
      .startOf('month')
      .format(DATE_FORMAT);
    const period_to = dayjs(invoiceDate)
      .clone()
      .endOf('month')
      .format(DATE_FORMAT);
    dispatch(
      addClientInvoice({
        client_id: id,
        allocations: [],
        invoiceDate,
        period_from,
        period_to,
        is_freeform: true,
      }),
    );
  };

  const onGenerateInvoice = () => {
    const formattedAllocations = formatAllocations(loggedAllocations);

    const period_from = dayjs(invoiceDate)
      .clone()
      .startOf('month')
      .format(DATE_FORMAT);
    const period_to = dayjs(invoiceDate)
      .clone()
      .endOf('month')
      .format(DATE_FORMAT);
    dispatch(
      addClientInvoice({
        client_id: id,
        allocations: formattedAllocations,
        invoiceDate,
        is_freeform: false,
        period_from,
        period_to,
        project_ids: invoiceProjects,
      }),
    );
  };

  const onConfirmAllocations = invoiceId => {
    const formattedAllocations = formatAllocations(loggedAllocations);
    deleteByID(invoiceData.id);
    setDraftSavedAt(null);
    dispatch(
      updateClientInvoice({
        invoice_id: invoiceId,
        client_id: id,
        allocations: formattedAllocations,
        messageText: INVOICE_UPDATE_MESSAGES.ALLOCATIONS,
      }),
    );
  };

  const onConfirmWorklogs = invoiceId => {
    deleteByID(invoiceData.id);
    setDraftSavedAt(null);
    const worklogs = invoiceProjects.reduce(
      (acc, project) => [...acc, ...worklogsGroupedByProject[project]],
      [],
    );

    const worklogsWithoutDeleted = worklogs.filter(item => !item.deleted);

    dispatch(
      updateClientInvoice({
        invoice_id: invoiceId,
        worklogs: worklogsWithoutDeleted,
        client_id: id,
        messageText: INVOICE_UPDATE_MESSAGES.WORKLOGS,
        setIsInvoiceValuesTouched,
        updatedField: 'worklogs',
      }),
    );
  };

  const onConfirmDetails = async () => {
    const values = await detailsForm.validateFields();
    dispatch(
      updateClientInvoice({
        ...values,
        issue_date:
          values.issue_date && dayjs(values.issue_date).format(DATE_FORMAT),
        due_date: values.due_date && dayjs(values.due_date).format(DATE_FORMAT),
        invoice_id: invoiceData.id,
        client_id: id,
        messageText: INVOICE_UPDATE_MESSAGES.DETAILS,
      }),
    );
    setIsInvoiceValuesTouched(prev => ({
      ...prev,
      details: false,
    }));
  };

  const onConfirmItems = invoiceId => {
    const invoiceItemsWithoutDeleted = invoiceItems.filter(
      item => !item.deleted,
    );

    dispatch(
      updateClientInvoice({
        invoice_id: invoiceId,
        items: invoiceItemsWithoutDeleted,
        client_id: id,
        messageText: INVOICE_UPDATE_MESSAGES.FILE,
      }),
    );
    setIsInvoiceValuesTouched(prev => ({
      ...prev,
      items: false,
    }));
  };

  // Вызывает фукнцию обновления инвойса в зависимости от текущего шага.
  const onUpdateInvoice = async (action = '') => {
    const invoiceId = get(invoiceData, 'id', invoiceEditId);

    if (invoiceId) {
      switch (action) {
        case INVOICE_UPDATE_ACIONS.allocations:
          onConfirmAllocations(invoiceId);

          break;

        case INVOICE_UPDATE_ACIONS.overview:
          onConfirmWorklogs(invoiceId);

          break;

        case INVOICE_UPDATE_ACIONS.details:
          onConfirmDetails();

          break;

        case INVOICE_UPDATE_ACIONS.items:
          onConfirmItems(invoiceId);

          break;

        default:
          break;
      }
    }
  };

  const onGenerateOrUpdateInvoice = () => {
    if (invoiceData) {
      onUpdateInvoice(INVOICE_UPDATE_ACIONS.allocations);
    } else {
      onGenerateInvoice();
    }

    setIsInvoiceValuesTouched(DEFAULT_EDIT_MODAL_STATE_TOUCHED);
  };

  const showAlertsWarning = action => {
    if (invoiceAlertsCount) {
      Modal.confirm({
        title: 'Warning',
        content:
          'Are you sure the information you entered is correct? There are several unresolved warnings during this period.',
        cancelText: 'No',
        okText: 'Yes',
        closable: true,
        onOk() {
          onUpdateInvoice(action);
        },
      });

      return;
    }

    onUpdateInvoice(action);
  };

  return (
    <div>
      <Steps current={current} className={styles.steps} size="small">
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className={styles.organizationsAlert}>
        {isEmpty(clientOrganizations) && (
          <Alert message="Client has no organization assigned" type="error" />
        )}
      </div>
      <div
        className={classNames(
          'steps-content',
          current === 3 || current === 5
            ? styles.stepsContentContainerThirdOrFifthStep
            : styles.stepsContentCotainerOtherSteps,
        )}
      >
        {steps[current] && steps[current].content}
        <div className={styles.dealsContainer}>
          {isEmpty(invoiceAllocations) &&
            current === 1 &&
            !isAllocationsStepLoading && (
              <div className={styles.secondStepAlertContainer}>
                <Alert message="Can’t find deals for a period" type="info" />
              </div>
            )}
          <ClientInvoiceNotifications
            invoiceAllocations={invoiceAllocations}
            loggedAllocations={loggedAllocations}
            jiraRolesGroupedById={jiraRolesGroupedById}
            projectGroupedById={projectGroupedById}
            worklogs={worklogs}
            worklogsGroupedByProject={worklogsGroupedByProject}
            calendar={calendar}
            current={current}
            isClientsLoading={isClientsLoading}
            addWorklogFromAlert={addWorklogFromAlert}
            setInvoiceAlertsCount={setInvoiceAlertsCount}
          />
        </div>
      </div>
      <div className={classNames('steps-action', styles.stepsActionsContainer)}>
        {current === 0 && !isCreateCustomInvoice && (
          <Button
            type="primary"
            onClick={next}
            disabled={isDisableNext || isClientsLoading}
          >
            Next
          </Button>
        )}
        {current === 0 && isCreateCustomInvoice && (
          <Button
            type="primary"
            onClick={onGenerateCustomInvoice}
            disabled={isClientsLoading || isDisableNext}
          >
            Save custom invoice
          </Button>
        )}
        {current >= 1 && (
          <div>
            <Button
              className={styles.prevButton}
              onClick={prev}
              disabled={isClientsLoading}
            >
              Previous
            </Button>
            <Button
              type="primary"
              onClick={next}
              disabled={isDisableNext || isClientsLoading}
            >
              Next
            </Button>
          </div>
        )}
        {current === 1 && (
          <Button
            type="primary"
            onClick={onGenerateOrUpdateInvoice}
            disabled={
              !isDisableNext ||
              isEmpty(loggedAllocations) ||
              !hasRights(role, CLIENT_PERMISSIONS.confirmAllocations)
            }
            loading={isClientsLoading}
          >
            Confirm Allocations
          </Button>
        )}

        {current === 2 && (
          <div>
            <Button
              style={{ marginRight: '15px' }}
              type="primary"
              disabled={!invoiceAttachmentLink}
            >
              <a
                href={invoiceAttachmentLink}
                target="_blank"
                className={styles.downloadWorklogs}
              >
                Download worklogs
              </a>
            </Button>
            <Button
              type="primary"
              loading={isClientsLoading}
              onClick={() => showAlertsWarning(INVOICE_UPDATE_ACIONS.overview)}
              disabled={
                !isDisableNext ||
                isWorklogsEmpty ||
                !hasRights(role, CLIENT_PERMISSIONS.confirmWorklogs)
              }
            >
              Confirm Worklogs
            </Button>
          </div>
        )}

        {current === 3 && (
          <Button
            type="primary"
            onClick={() => onUpdateInvoice(INVOICE_UPDATE_ACIONS.details)}
            loading={isClientsLoading}
            disabled={
              !isDisableNext ||
              !hasRights(role, CLIENT_PERMISSIONS.confirmDetails)
            }
          >
            Confirm Details
          </Button>
        )}
        {current === 4 && (
          <Button
            type="primary"
            onClick={() => onUpdateInvoice(INVOICE_UPDATE_ACIONS.items)}
            loading={isClientsLoading}
            disabled={
              !isDisableNext ||
              !hasRights(role, CLIENT_PERMISSIONS.confirmItems)
            }
          >
            Generate Files
          </Button>
        )}
      </div>
    </div>
  );
};

ClientInvoiceSteps.propTypes = {
  clientOrganizations: PropTypes.array.isRequired,
  isFullScreenClientModal: PropTypes.bool.isRequired,
  onDeleteInvoice: PropTypes.func.isRequired,
  clearInvoiceData: PropTypes.func.isRequired,
  invoiceEditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isInvoiceEdit: PropTypes.bool.isRequired,
  isInvoiceValuesTouched: PropTypes.object.isRequired,
  setIsInvoiceValuesTouched: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired,
  invoiceAlertsCount: PropTypes.number.isRequired,
  setInvoiceAlertsCount: PropTypes.func.isRequired,
  setIsInvoiceEditModalVisible: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
};

export default ClientInvoiceSteps;
