export const LOCAL_STORAGE_ITEMS = {
  tablePageSetting: `${window.location.pathname}-`,
  invoiceOverviewSummarySettings: 'invoiceOverviewSummarySettings',
  invoiceOverviewProjectSettings: 'invoiceOverviewProjectSettings',
  invoiceItemsSettings: 'invoiceItems',
  accessToken: 'accessToken',
  lastPageTime: 'lastPageTime',
  lastPage: 'lastPage',
  selectedPaysheeetsPeriod: 'selectedPeriod',
};
