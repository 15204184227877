import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber, Modal, Select, Typography } from 'antd';
import dayjs from 'dayjs';

import DatePicker from 'components/Common/DatePicker';
import { getConditionPreview } from 'components/ClientCommon/ClientModal/ClientAllocationTable/utils';
import { getPopupContainerHandler } from 'utils/helpers';
import { DATE_FORMAT } from 'utils/timeConstants';

import { ISSUE_TYPES } from '../constants';

const { Text } = Typography;
const { Item } = Form;
const { Option } = Select;

const ClientInvoiceWorklogsModal = ({
  isVisible,
  form,
  onClose,
  onAdd,
  onSearchJiraUsers,
  isJiraLoading,
  searchedJiraUsers,
  invoiceAllocations,
  currentProjectId,
  jiraRolesGroupedById,
  invoiceDate,
}) => {
  const [formMessage, setFormMessage] = useState('');
  const [displayedAllocations, setDisplayedAllocations] = useState(
    invoiceAllocations,
  );

  const onValuesChange = changedValues => {
    setFormMessage('');

    if (changedValues.hasOwnProperty('date')) {
      const { date } = changedValues;
      const filteredAllocations = invoiceAllocations[currentProjectId].filter(
        allocation =>
          !dayjs(date, DATE_FORMAT).isBefore(allocation.period_from, 'day') &&
          !dayjs(date, DATE_FORMAT).isAfter(allocation.period_to, 'day'),
      );
      setDisplayedAllocations({
        ...invoiceAllocations,
        [currentProjectId]: filteredAllocations,
      });
    }
  };

  const onSubmit = values => {
    setFormMessage('');

    const { date, allocation_id } = values;

    const project_id = form.getFieldValue('project_id');

    const allocation = invoiceAllocations[project_id || currentProjectId].find(
      allocation => allocation.id === allocation_id,
    );

    if (
      allocation &&
      (dayjs(date).isBefore(allocation.period_from) ||
        dayjs(date).isAfter(allocation.period_to))
    ) {
      setFormMessage('Invalid allocation for this date');

      return;
    }

    onAdd({ ...values, project_id });
  };

  return (
    <Modal
      open={isVisible}
      title="Add row"
      destroyOnClose
      onCancel={onClose}
      onOk={form.submit}
      cancelText="Cancel"
      okText="Add"
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onFinish={onSubmit}
        onValuesChange={onValuesChange}
      >
        <Item
          name="issue_type"
          rules={[{ required: true, message: 'Please input issue type!' }]}
          label="Issue type"
        >
          <Select getPopupContainer={getPopupContainerHandler}>
            {ISSUE_TYPES.map(type => (
              <Option value={type} key={type}>
                {type}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="issue_key"
          rules={[{ required: true, message: 'Please input issue key!' }]}
          label="Issue key"
        >
          <Input placeholder="Issue key" />
        </Item>
        <Item
          name="issue_summary"
          rules={[{ required: true, message: 'Please input issue summary!' }]}
          label="Issue summary"
        >
          <Input placeholder="Issue summary" />
        </Item>
        <Item
          name="date"
          label="Date"
          rules={[
            { required: true, message: 'Please choose date!' },
            ({ getFieldValue }) => ({
              validator() {
                const rolesLength =
                  displayedAllocations[
                    getFieldValue('project_id') || currentProjectId
                  ]?.length;

                if (rolesLength) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error('No allocations for this date'),
                );
              },
            }),
          ]}
        >
          <DatePicker
            getPopupContainer={getPopupContainerHandler}
            defaultPickerValue={dayjs(invoiceDate).startOf('M')}
          />
        </Item>
        <Item
          name="worker"
          label="Worker"
          shouldUpdate
          rules={[{ required: true, message: 'Please choose worker!' }]}
        >
          <Select
            onSearch={onSearchJiraUsers}
            showSearch
            loading={isJiraLoading}
            filterOption={false}
            getPopupContainer={getPopupContainerHandler}
          >
            {searchedJiraUsers.map(jiraUser => (
              <Option value={jiraUser.key} key={jiraUser.key}>
                {jiraUser.displayName}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="allocation_id"
          label="Role"
          rules={[{ required: true, message: 'Please choose allocation!' }]}
        >
          <Select getPopupContainer={getPopupContainerHandler}>
            {displayedAllocations[
              form.getFieldValue('project_id') || currentProjectId
            ]?.map(
              ({
                jira_role_id,
                id,
                currency_code,
                rate,
                hours_per_week,
                condition,
              }) => (
                <Option value={id} key={id}>
                  {jiraRolesGroupedById[jira_role_id].name}{' '}
                  {`${currency_code} ${rate} ${getConditionPreview(
                    condition,
                  )} ${hours_per_week} h/week`}
                </Option>
              ),
            )}
          </Select>
        </Item>
        <Item
          name="spent_hours"
          label="Spent hours"
          rules={[{ required: true, message: 'Please input spent hours!' }]}
        >
          <InputNumber min={0} />
        </Item>
        <Item name="comment" label="Comment">
          <Input.TextArea />
        </Item>
        <Text type="danger">{formMessage}</Text>
      </Form>
    </Modal>
  );
};

ClientInvoiceWorklogsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSearchJiraUsers: PropTypes.func.isRequired,
  isJiraLoading: PropTypes.bool.isRequired,
  searchedJiraUsers: PropTypes.array.isRequired,
  invoiceAllocations: PropTypes.object.isRequired,
  currentProjectId: PropTypes.string.isRequired,
  jiraRolesGroupedById: PropTypes.object.isRequired,
  invoiceDate: PropTypes.object.isRequired,
};

export default ClientInvoiceWorklogsModal;
