import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge, Modal, Tabs } from 'antd';
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  CloseOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';

import {
  CLIENT_PERMISSIONS,
  DEFAULT_EDIT_MODAL_STATE_TOUCHED,
} from 'containers/ClientPage/constants';
import { ClientDealSteps } from 'components/ClientCommon/ClientDeal';
import ClientInvoiceSteps from 'components/ClientCommon/ClientModal/ClientInvoice/ClientInvoiceSteps';
import ClientNoteForm from 'components/ClientCommon/ClientForms/ClientNoteForm';
import { hasRights } from 'utils/permissions';

import styles from './styles.scss';

const ClientModal = ({
  isModalVisible,
  onHideClientModal,
  status,
  onSaveNote,
  tabKey,
  isDisableNextButton,
  setIsDisableNextButton,
  clientOrganizations,
  onDeleteInvoice,
  clearInvoiceData,
  invoiceEditId,
  isInvoiceEditModalVisible,
  chosenTab,
  setChosenTab,
  filteredDeals,
  allocationsGroupByDeal,
  setDealValues,
  dealValues,
  allocationsData,
  setAllocationsData,
  editingDealId,
  role,
  setIsInvoiceEditModalVisible,
}) => {
  const [currentInvoiceStep, setCurrentInvoiceStep] = useState(0);
  const [isFullScreenClientModal, setIsFullScreenClientModal] = useState(true);

  // Флаг отвечает за текущее состояние данных в процессе инвойсинга.
  // При редактировании значение переходит в true, при сохранении в false.
  // Влияет на состояние кнопок Next/Confirm.
  const [isInvoiceValuesTouched, setIsInvoiceValuesTouched] = useState(
    DEFAULT_EDIT_MODAL_STATE_TOUCHED,
  );

  const [invoiceAlertsCount, setInvoiceAlertsCount] = useState(0);

  useEffect(() => {
    setChosenTab(tabKey === 'All' ? 'Deal' : tabKey);
  }, [tabKey]);

  const showConfirm = () => {
    const keys = Object.keys(isInvoiceValuesTouched);
    const isFieldTouched = keys.some(item => isInvoiceValuesTouched[item]);

    if (isFieldTouched || currentInvoiceStep > 1) {
      Modal.confirm({
        title: 'Are you sure?',
        onOk() {
          onHideClientModal();
          setCurrentInvoiceStep(0);
        },
      });
    } else {
      onHideClientModal();
      setCurrentInvoiceStep(0);
    }
  };

  const isInvoiceEdit = isInvoiceEditModalVisible;

  const tabItems = [
    {
      label: 'Deal',
      key: 'Deal',
      disabled:
        currentInvoiceStep > 1 ||
        isInvoiceEdit ||
        !hasRights(role, CLIENT_PERMISSIONS.createDeal),
      children: (
        <ClientDealSteps
          status={status}
          isDisableNextButton={isDisableNextButton}
          setIsDisableNextButton={setIsDisableNextButton}
          onHideClientModal={onHideClientModal}
          filteredDeals={filteredDeals}
          allocationsGroupByDeal={allocationsGroupByDeal}
          setDealValues={setDealValues}
          dealValues={dealValues}
          allocationsData={allocationsData}
          setAllocationsData={setAllocationsData}
          editingDealId={editingDealId}
        />
      ),
    },
    {
      label: 'Note',
      key: 'ClientNote',
      disabled: currentInvoiceStep > 1 || isInvoiceEdit,
      children: <ClientNoteForm onFinish={onSaveNote} />,
    },
    {
      label: 'Invoice',
      key: 'Invoice',
      disabled: !hasRights(role, CLIENT_PERMISSIONS.createInvoice),
      children: (
        <ClientInvoiceSteps
          clientOrganizations={clientOrganizations}
          isFullScreenClientModal={isFullScreenClientModal}
          onDeleteInvoice={onDeleteInvoice}
          clearInvoiceData={clearInvoiceData}
          invoiceEditId={invoiceEditId}
          isInvoiceEdit={isInvoiceEdit}
          isInvoiceValuesTouched={isInvoiceValuesTouched}
          setIsInvoiceValuesTouched={setIsInvoiceValuesTouched}
          current={currentInvoiceStep}
          setCurrent={setCurrentInvoiceStep}
          invoiceAlertsCount={invoiceAlertsCount}
          setInvoiceAlertsCount={setInvoiceAlertsCount}
          role={role}
          setIsInvoiceEditModalVisible={setIsInvoiceEditModalVisible}
        />
      ),
    },
  ];

  return (
    <Modal
      open={isModalVisible || isInvoiceEditModalVisible}
      style={{ top: isFullScreenClientModal ? 0 : null }}
      footer={null}
      width={isFullScreenClientModal ? '100vw' : '1300px'}
      bodyStyle={{ height: isFullScreenClientModal ? '90vh' : null }}
      onCancel={showConfirm}
      closeIcon={<CloseOutlined />}
      destroyOnClose
      title={
        <div className={styles.modalTitle}>
          <span>{chosenTab}</span>
          <span>
            {chosenTab === 'Invoice' && (
              <span className={styles.invoiceMarginRight}>
                <Badge count={invoiceAlertsCount} size="small">
                  <MenuFoldOutlined style={{ cursor: 'pointer' }} />
                </Badge>
              </span>
            )}
            <span className={styles.invoiceMarginRight}>
              {isFullScreenClientModal ? (
                <FullscreenExitOutlined
                  onClick={() => setIsFullScreenClientModal(prev => !prev)}
                />
              ) : (
                <FullscreenOutlined
                  onClick={() => setIsFullScreenClientModal(prev => !prev)}
                />
              )}
            </span>
          </span>
        </div>
      }
    >
      <Tabs
        defaultActiveKey={tabKey}
        activeKey={chosenTab}
        onChange={setChosenTab}
        className={styles.clientTabs}
        items={tabItems}
      />
    </Modal>
  );
};

ClientModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onHideClientModal: PropTypes.func.isRequired,
  status: PropTypes.number,
  onSaveNote: PropTypes.func.isRequired,
  tabKey: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  isDisableNextButton: PropTypes.bool.isRequired,
  setIsDisableNextButton: PropTypes.func.isRequired,
  clientOrganizations: PropTypes.array.isRequired,
  onDeleteInvoice: PropTypes.func.isRequired,
  clearInvoiceData: PropTypes.func.isRequired,
  invoiceEditId: PropTypes.string.isRequired,
  isInvoiceEditModalVisible: PropTypes.bool.isRequired,
  chosenTab: PropTypes.string.isRequired,
  setChosenTab: PropTypes.func.isRequired,
  filteredDeals: PropTypes.array.isRequired,
  allocationsGroupByDeal: PropTypes.object.isRequired,
  setDealValues: PropTypes.func.isRequired,
  dealValues: PropTypes.object,
  allocationsData: PropTypes.array.isRequired,
  setAllocationsData: PropTypes.func.isRequired,
  setIsInvoiceEditModalVisible: PropTypes.func.isRequired,
  editingDealId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ClientModal;
