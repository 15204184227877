import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';

const SearchTableIcon = ({ isFiltered = false }) => (
  <SearchOutlined style={{ color: isFiltered ? '#1890ff' : '#656565' }} />
);

SearchTableIcon.propTypes = {
  isFiltered: PropTypes.bool,
};

export default memo(SearchTableIcon);
