import { ROLE_TYPES } from 'utils/permissions';

export const STATUS_INFO = {
  approved: { title: 'Approved', color: 'green' },
  declined: { title: 'Declined', color: 'red' },
  pending: { title: 'Pending', color: 'orange' },
};

export const LOAD_EMPLOYEE_PARAMS = {
  page: 1,
  size: 1000,
  sort: 'full_name:ascend',
  filters: { status: [] },
};

export const DEFAULT_AVATAR =
  'https://www.gravatar.com/avatar/e5ffa8aecb9a36a76676407d8bfd1d36?d=mm&s=32';

export const EXTRA_PAYMENTS_TABLE_CONTENT = {
  id_title: 'ID',
  id: 'id',
  internal_id_title: '1C ID',
  internal_id: 'internal_id',
  employee_internal_id: 'employee_internal_id',
  employee_title: 'Employee',
  employee_id: 'employee_id',
  name: 'name',
  employee_full_name: 'employee_full_name',
  period_title: 'Period',
  valid_from: 'valid_from',
  amount_title: 'Amount',
  amount: 'amount',
  amount_usd: 'amount_usd',
  title: 'title',
  description_title: 'Title',
  project_title: 'Project',
  project_id: 'project_id',
  status_title: 'Status',
  status: 'status',
  author_title: 'Author',
  author: 'author',
  author_id: 'author_id',
  created_at_title: 'Created at',
  created_at: 'created_at',
  reviewed_at_title: 'Reviewed at',
  reviewed_at: 'reviewed_at',
  reviewer_title: 'Reviewer',
  reviewer: 'reviewer',
  reviewer_id: 'reviewer_id',
  project_name: 'project_name',
  is_social_request: 'is_social_request',
  employee_profile_name_title: 'Profile',
  employee_profile_name: 'employee_profile_name',
};

export const EXTRA_PAYMENTS_DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 50,
};

export const EXTRA_PAYMENT_AMOUNT = {
  USD: 'amount_usd',
  BYN: 'amount_byn',
};

export const EXTRA_PAYMENT_STATUS = {
  APPROVED: 'approved',
  DECLINED: 'declined',
};

export const EXTRA_PAYMENTS_MODAL = {
  rejectTitle: 'Reject extra payment',
  approveTitle: 'Approve extra payment',
  deleteTitle: 'Remove extra payment',
  ok: 'OK',
  cancel: 'Cancel',
  approve: 'Are you sure you want to approve this extra payment?',
  decline: 'Are you sure you want to reject this extra payment?',
  delete: 'Are you sure you want to remove this extra payment?',
};

export const EP_PERMISSIONS = {
  updatePayment: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  updateOwnPayment: [ROLE_TYPES.hr_lead, ROLE_TYPES.dm, ROLE_TYPES.pm],
  deletePayment: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
  approveDeclinePayment: [ROLE_TYPES.ceo, ROLE_TYPES.finance_specialist],
};
