export const TYPES = {
  ERROR: {
    key: 0,
    type: 'error',
  },
  WARNING: {
    key: 1,
    type: 'warning',
  },
  INFO: {
    key: 2,
    type: 'info',
  },
  SUCCESS: {
    key: 3,
    type: 'success',
  },
};

export function getType(issues) {
  if (!issues || !issues.length) {
    return null;
  }

  let index = 0;
  let result = { key: 3, type: 'success' };

  while (index < issues.length && result.key > 0) {
    const { type } = issues[index];

    if (TYPES[type].key < result.key) {
      result = TYPES[type];
    }

    index += 1;
  }

  return result.type;
}
