import React from 'react';
import PropTypes from 'prop-types';

import { formatNumberWithCommas } from 'utils/helpers';

import styles from './styles.scss';

const TooltipContentPie = ({ payload }) => (
  <div className={styles.tooltipContentPie}>
    <div className={styles.items}>
      {payload.map(item => (
        <div className={styles.item} key={item.name}>
          <div className={styles.left}>
            <span
              className={styles.icon}
              style={{ backgroundColor: item.payload.fill }}
            />
            <span className={styles.name}>{item.name}</span>
          </div>
          <span className={styles.value}>
            {formatNumberWithCommas(item.value)}
          </span>
        </div>
      ))}
    </div>
  </div>
);

TooltipContentPie.propTypes = {
  payload: PropTypes.array,
};

export default TooltipContentPie;
