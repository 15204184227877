import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select, Alert } from 'antd';

import { getPopupContainerHandler } from 'utils/helpers';
import { filterProjects, findDeal } from './utils';
import styles from './styles.scss';

const ClientProject = ({ projects, setProject, project, filteredDeals }) => {
  const onSelectProject = value => {
    setProject(value);
  };

  const chosenProject = useMemo(() => findDeal({ filteredDeals, project }), [
    filteredDeals,
    project,
  ]);

  return (
    <div className={styles.projectContainer}>
      <div>
        <span className={styles.projectSelect}>Select project</span>
        <Select
          showSearch
          filterOption={filterProjects}
          style={{ width: 300 }}
          onChange={onSelectProject}
          defaultValue={project}
          getPopupContainer={getPopupContainerHandler}
        >
          {projects.map(item => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </div>

      {chosenProject && (
        <Alert
          message="There is already a deal for the selected project, the data will be pre-filled from the last deal."
          type="info"
          showIcon
          style={{ width: '350px', marginLeft: '15px' }}
        />
      )}
    </div>
  );
};

ClientProject.propTypes = {
  projects: PropTypes.array,
  setProject: PropTypes.func,
  project: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filteredDeals: PropTypes.array,
};

export default ClientProject;
