exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".contractsModalContainer-6849d{display:flex;justify-content:center}.clientContractItem-75d5c{margin-bottom:12px;padding:16px 20px;border:1px solid #d9d9d9;border-radius:8px}.clientContractItem-75d5c .infoItem-42958{font-weight:600}.clientContractItem-75d5c .field-ceca5{font-weight:400;color:#595959}.clientContractNumber-1ad7c{cursor:pointer;color:#1890ff}", ""]);

// Exports
exports.locals = {
	"contractsModalContainer": "contractsModalContainer-6849d",
	"clientContractItem": "clientContractItem-75d5c",
	"infoItem": "infoItem-42958",
	"field": "field-ceca5",
	"clientContractNumber": "clientContractNumber-1ad7c"
};