export const types = {
  LOAD_EMPLOYEES: 'employees/LOAD_EMPLOYEES',
  LOAD_EMPLOYEES_SUCCESS: 'employees/LOAD_EMPLOYEES_SUCCESS',
  LOAD_EMPLOYEES_FAILED: 'employees/LOAD_EMPLOYEES_FAILED',

  GET_EMPLOYEES_WITH_JIRA: 'employees/GET_EMPLOYEES_WITH_JIRA',
  GET_EMPLOYEES_WITH_JIRA_SUCCESS: 'employees/GET_EMPLOYEES_WITH_JIRA_SUCCESS',
  GET_EMPLOYEES_WITH_JIRA_FAILED: 'employees/GET_EMPLOYEES_WITH_JIRA_FAILED',

  GET_FULL_EMPLOYEES: 'employees/GET_FULL_EMPLOYEES',
  GET_FULL_EMPLOYEES_SUCCESS: 'employees/GET_FULL_EMPLOYEES_SUCCESS',
  GET_FULL_EMPLOYEES_FAILED: 'employees/GET_FULL_EMPLOYEES_FAILED',

  UPLOAD_EMPLOYEES: 'employees/UPLOAD_EMPLOYEES',
  UPLOAD_EMPLOYEES_SUCCESS: 'employees/UPLOAD_EMPLOYEES_SUCCESS',
  UPLOAD_EMPLOYEES_FAILED: 'employees/UPLOAD_EMPLOYEES_FAILED',

  GET_EMPLOYEE: 'employees/GET_EMPLOYEE',
  GET_EMPLOYEE_SUCCESS: 'employees/GET_EMPLOYEE_SUCCESS',
  GET_EMPLOYEE_FAILED: 'employees/GET_EMPLOYEE_FAILED',

  GET_EMPLOYEE_RM: 'employees/GET_EMPLOYEE_RM',
  GET_EMPLOYEE_RM_SUCCESS: 'employees/GET_EMPLOYEE_RM_SUCCESS',
  GET_EMPLOYEE_RM_FAILED: 'employees/GET_EMPLOYEE_RM_FAILED',

  CREATE_EMPLOYEE: 'employees/CREATE_EMPLOYEE',
  CREATE_EMPLOYEE_SUCCESS: 'employees/CREATE_EMPLOYEE_SUCCESS',
  CREATE_EMPLOYEE_FAILED: 'employees/CREATE_EMPLOYEE_FAILED',

  GET_EMPLOYEE_PROFILE_DATA: 'employees/GET_EMPLOYEE_PROFILE_DATA',
  GET_EMPLOYEE_PROFILE_DATA_SUCCESS:
    'employees/GET_EMPLOYEE_PROFILE_DATA_SUCCESS',
  GET_EMPLOYEE_PROFILE_DATA_FAILED:
    'employees/GET_EMPLOYEE_PROFILE_DATA_FAILED',

  UPDATE_EMPLOYEE: 'employees/UPDATE_EMPLOYEE',
  UPDATE_EMPLOYEE_SUCCESS: 'employees/UPDATE_EMPLOYEE_SUCCESS',
  UPDATE_EMPLOYEE_FAILED: 'employees/UPDATE_EMPLOYEE_FAILED',

  UPDATE_EMPLOYEE_RM: 'employees/UPDATE_EMPLOYEE_RM',
  UPDATE_EMPLOYEE_RM_SUCCESS: 'employees/UPDATE_EMPLOYEE_RM_SUCCESS',
  UPDATE_EMPLOYEE_RM_FAILED: 'employees/UPDATE_EMPLOYEE_RM_FAILED',

  GET_EMPLOYEE_SALARIES: 'employees/GET_EMPLOYEE_SALARIES',
  GET_EMPLOYEE_SALARIES_SUCCESS: 'employees/GET_EMPLOYEE_SALARIES_SUCCESS',
  GET_EMPLOYEE_SALARIES_FAILED: 'employees/GET_EMPLOYEE_SALARIES_FAILED',

  ADD_EMPLOYEE_SALARIES: 'employees/ADD_EMPLOYEE_SALARIES',
  ADD_EMPLOYEE_SALARIES_SUCCESS: 'employees/ADD_EMPLOYEE_SALARIES_SUCCESS',
  ADD_EMPLOYEE_SALARIES_FAILED: 'employees/ADD_EMPLOYEE_SALARIES_FAILED',

  UPDATE_EMPLOYEE_SALARIES: 'employees/UPDATE_EMPLOYEE_SALARIES',
  UPDATE_EMPLOYEE_SALARIES_FAILED: 'employees/UPDATE_EMPLOYEE_SALARIES_FAILED',
  UPDATE_EMPLOYEE_SALARIES_SUCCESS:
    'employees/UPDATE_EMPLOYEE_SALARIES_SUCCESS',

  DELETE_EMPLOYEE_SALARIES: 'employees/DELETE_EMPLOYEE_SALARIES',
  DELETE_EMPLOYEE_SALARIES_SUCCESS:
    'employees/DELETE_EMPLOYEE_SALARIES_SUCCESS',
  DELETE_EMPLOYEE_SALARIES_FAILED: 'employees/DELETE_EMPLOYEE_SALARIES_FAILED',

  GET_EMPLOYEE_EXTRA_PAYMENTS: 'employees/GET_EMPLOYEE_EXTRA_PAYMENTS',
  GET_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
    'employees/GET_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS',
  GET_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    'employees/GET_EMPLOYEE_EXTRA_PAYMENTS_FAILED',

  ADD_EMPLOYEE_EXTRA_PAYMENTS: 'employees/ADD_EMPLOYEE_EXTRA_PAYMENTS',
  ADD_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
    'employees/ADD_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS',
  ADD_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    'employees/ADD_EMPLOYEE_EXTRA_PAYMENTS_FAILED',

  UPDATE_EMPLOYEE_EXTRA_PAYMENTS: 'employees/UPDATE_EMPLOYEE_EXTRA_PAYMENTS',
  UPDATE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
    'employees/UPDATE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS',
  UPDATE_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    'employees/UPDATE_EMPLOYEE_EXTRA_PAYMENTS_FAILED',

  DELETE_EMPLOYEE_EXTRA_PAYMENTS: 'employees/DELETE_EMPLOYEE_EXTRA_PAYMENTS',
  DELETE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
    'employees/DELETE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS',
  DELETE_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    'employees/DELETE_EMPLOYEE_EXTRA_PAYMENTS_FAILED',

  APPROVE_EMPLOYEE_EXTRA_PAYMENTS: 'employees/APPROVE_EMPLOYEE_EXTRA_PAYMENTS',
  APPROVE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
    'employees/APPROVE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS',
  APPROVE_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    'employees/APPROVE_EMPLOYEE_EXTRA_PAYMENTS_FAILED',

  DECLINE_EMPLOYEE_EXTRA_PAYMENTS: 'employees/DECLINE_EMPLOYEE_EXTRA_PAYMENTS',
  DECLINE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS:
    'employees/DECLINE_EMPLOYEE_EXTRA_PAYMENTS_SUCCESS',
  DECLINE_EMPLOYEE_EXTRA_PAYMENTS_FAILED:
    'employees/DECLINE_EMPLOYEE_EXTRA_PAYMENTS_FAILED',

  UPDATE_EMPLOYEE_TECHNOLOGIES: 'employees/UPDATE_EMPLOYEE_TECHNOLOGIES',
  UPDATE_EMPLOYEE_TECHNOLOGIES_SUCCESS:
    'employees/UPDATE_EMPLOYEE_TECHNOLOGIES_SUCCESS',
  UPDATE_EMPLOYEE_TECHNOLOGIES_FAILED:
    'employees/UPDATE_EMPLOYEE_TECHNOLOGIES_FAILED',

  GET_EMPLOYEE_TECHNOLOGIES: 'employees/GET_EMPLOYEE_TECHNOLOGIES',
  GET_EMPLOYEE_TECHNOLOGIES_SUCCESS:
    'employees/GET_EMPLOYEE_TECHNOLOGIES_SUCCESS',
  GET_EMPLOYEE_TECHNOLOGIES_FAILED:
    'employees/GET_EMPLOYEE_TECHNOLOGIES_FAILED',

  ADD_AND_UPDATE_TECHNOLOGIES: 'employees/ADD_AND_UPDATE_TECHNOLOGIES',
  ADD_AND_UPDATE_TECHNOLOGIES_SUCCESS:
    'employees/ADD_AND_UPDATE_TECHNOLOGIES_SUCCESS',
  ADD_AND_UPDATE_TECHNOLOGIES_FAILED:
    'employees/ADD_AND_UPDATE_TECHNOLOGIES_FAILED',

  GET_EMPLOYEE_PAYSHEETS: 'employees/GET_EMPLOYEE_PAYSHEETS',
  GET_EMPLOYEE_PAYSHEETS_SUCCESS: 'employees/GET_EMPLOYEE_PAYSHEETS_SUCCESS',
  GET_EMPLOYEE_PAYSHEETS_FAILED: 'employees/GET_EMPLOYEE_PAYSHEETS_FAILED',

  GET_EMPLOYEE_COMMENTS: 'employees/GET_EMPLOYEE_COMMENTS',
  GET_EMPLOYEE_COMMENTS_SUCCESS: 'employees/GET_EMPLOYEE_COMMENTS_SUCCESS',
  GET_EMPLOYEE_COMMENTS_FAILED: 'employees/GET_EMPLOYEE_COMMENTS_FAILED',

  ADD_EMPLOYEE_COMMENT: 'employees/ADD_EMPLOYEE_COMMENTS',
  ADD_EMPLOYEE_COMMENT_SUCCESS: 'employees/ADD_EMPLOYEE_COMMENTS_SUCCESS',
  ADD_EMPLOYEE_COMMENT_FAILED: 'employees/ADD_EMPLOYEE_COMMENTS_FAILED',

  DELETE_EMPLOYEE_COMMENT: 'employees/DELETE_EMPLOYEE_COMMENT',
  DELETE_EMPLOYEE_COMMENT_SUCCESS: 'employees/DELETE_EMPLOYEE_COMMENT_SUCCESS',
  DELETE_EMPLOYEE_COMMENT_FAILED: 'employees/DELETE_EMPLOYEE_COMMENT_FAILED',

  UPDATE_EMPLOYEE_COMMENT: 'employees/UPDATE_EMPLOYEE_COMMENT',
  UPDATE_EMPLOYEE_COMMENT_SUCCESS: 'employees/UPDATE_EMPLOYEE_COMMENT_SUCCESS',
  UPDATE_EMPLOYEE_COMMENT_FAILED: 'employees/UPDATE_EMPLOYEE_COMMENT_FAILED',

  GET_EMPLOYEE_ATTACHMENTS: 'employees/GET_EMPLOYEE_ATTACHMENTS',
  GET_EMPLOYEE_ATTACHMENTS_FAILED: 'employees/GET_EMPLOYEE_ATTACHMENTS_FAILED',
  GET_EMPLOYEE_ATTACHMENTS_SUCCESS:
    'employees/GET_EMPLOYEE_ATTACHMENTS_SUCCESS',

  ADD_EMPLOYEE_ATTACHMENT: 'employees/ADD_EMPLOYEE_ATTACHMENT',
  ADD_EMPLOYEE_ATTACHMENT_SUCCESS: 'employees/ADD_EMPLOYEE_ATTACHMENT_SUCCESS',
  ADD_EMPLOYEE_ATTACHMENT_FAILED: 'employees/ADD_EMPLOYEE_ATTACHMENT_FAIL',

  UPDATE_RESOURCE_MANAGER: 'employees/UPDATE_RESOURCE_MANAGER',
  UPDATE_RESOURCE_MANAGER_SUCCESS: 'employees/UPDATE_RESOURCE_MANAGER_SUCCESS',
  UPDATE_RESOURCE_MANAGER_FAILED: 'employees/UPDATE_RESOURCE_MANAGER_FAILED',

  DELETE_EMPLOYEE_ATTACHMENT: 'employee/DELETE_EMPLOYEE_ATTACHMENT',
  DELETE_EMPLOYEE_ATTACHMENT_SUCCESS:
    'employee/DELETE_EMPLOYEE_ATTACHMENT_SUCCESS',
  DELETE_EMPLOYEE_ATTACHMENT_FAILED:
    'employee/DELETE_EMPLOYEE_ATTACHMENT_FAILED',

  UPDATE_BUDDIES: 'employees/UPDATE_BUDDIES',
  UPDATE_BUDDIES_SUCCESS: 'employees/UPDATE_BUDDIES_SUCCESS',
  UPDATE_BUDDIES_FAILED: 'employees/UPDATE_BUDDIES_FAILED',

  CANCEL_ATTACHMENT_UPLOAD: 'employees/CANCEL_ATTACHMENT_UPLOAD',

  GET_IBAN_CURRENCY: 'employees/GET_IBAN_CURRENCY',
  GET_IBAN_CURRENCY_SUCCESS: 'employees/GET_IBAN_CURRENCY_SUCCESS',
  GET_IBAN_CURRENCY_FAILED: 'employees/GET_IBAN_CURRENCY_FAILED',

  SET_IBAN_CURRENCY: 'employees/SET_IBAN_CURRENCY',

  SET_IBAN_SELECTED_CURRENCY: 'employees/SET_IBAN_SELECTED_CURRENCY',

  CLEAR_EMPLOYEE_STORE: 'employees/CLEAR_EMPLOYEE_STORE',
};

export const EMPLOYEE_EXPAND = [
  'buddies',
  'ibans',
  'with_summary_employee',
  'resource_manager',
  'technologies',
];
