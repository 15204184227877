import React from 'react';
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { checkIsMobile } from 'utils/helpers';

const isMobile = checkIsMobile();

const HeaderMenu = ({ items, currentItem }) => (
  <Menu
    theme="light"
    mode="horizontal"
    selectedKeys={[currentItem.toString()]}
    defaultSelectedKeys={['1']}
    disabledOverflow
    triggerSubMenuAction={isMobile ? 'click' : 'hover'}
  >
    {items.map(item =>
      item.items ? (
        <Menu.SubMenu key={item.key} title={item.title} popupOffset={[-20, 0]}>
          {item.items.map(subItem => (
            <Menu.Item key={subItem.key}>
              <Link to={subItem.route.path}>{subItem.title}</Link>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      ) : (
        <Menu.Item key={item.key}>
          <Link to={item.route.path}>{item.title}</Link>
        </Menu.Item>
      ),
    )}
  </Menu>
);

HeaderMenu.propTypes = {
  items: PropTypes.array,
  currentItem: PropTypes.string,
};

export default HeaderMenu;
