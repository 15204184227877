export const types = {
  GET_JIRA_ROLES: 'jira/GET_JIRA_ROLES',
  GET_JIRA_ROLES_SUCCESS: 'jira/GET_JIRA_ROLES_SUCCESS',
  GET_JIRA_ROLES_FAILED: 'jira/GET_JIRA_ROLES_FAILED',

  GET_JIRA_PROJECTS: 'jira/GET_JIRA_PROJECTS',
  GET_JIRA_PROJECTS_SUCCESS: 'jira/GET_JIRA_PROJECTS_SUCCESS',
  GET_JIRA_PROJECTS_FAILED: 'jira/GET_JIRA_PROJECTS_FAILED',

  SEARCH_JIRA_USERS: 'jira/SEARCH_JIRA_USERS',
  SEARCH_JIRA_USERS_SUCCESS: 'jira/SEARCH_JIRA_USERS_SUCCESS',
  SEARCH_JIRA_USERS_FAILED: 'jira/SEARCH_JIRA_USERS_FAILED',

  UPDATE_JIRA_PROJECTS: 'jira/UPDATE_JIRA_PROJECTS',
  UPDATE_JIRA_PROJECTS_SUCCESS: 'jira/UPDATE_JIRA_PROJECTS_SUCCESS',
  UPDATE_JIRA_PROJECTS_FAILED: 'jira/UPDATE_JIRA_PROJECTS_FAILED',
};
