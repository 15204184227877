import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Popover, Modal } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';

import { RM_PLAN_TYPES } from 'containers/ResourceManagementPage/constants';
import {
  cellColor,
  closeIconColor,
  arrowRightColor,
  arrowLeftColor,
} from './utils';
import styles from './styles.scss';

const PopoverCell = ({
  deletePlanById,
  deletePlanByType,
  plans,
  hoverCell,
  view,
  commonProps,
  condition,
  cellContent,
  endDate,
  startDate,
  canUpdatePlans,
  canUpdateDeals,
}) => {
  const onDeletePlan = (id, type) => event => {
    const selectedPlan = plans.find(
      item => item.id === id && item.planItem.type === type,
    );
    const hours = dayjs.duration(selectedPlan.seconds, 'seconds').hours();
    const minutes = dayjs.duration(selectedPlan.seconds, 'seconds').minutes();
    const time = `${hours} h ${minutes} m`;
    const content = (
      <Fragment>
        <p>Are you sure you want to remove this plan?</p>
        <p>You remove {time}</p>
        <p>Start date: {selectedPlan.start}</p>
      </Fragment>
    );
    Modal.confirm({
      title: 'Remove plan',
      content,
      onOk: () => {
        if (selectedPlan.planItem.type === RM_PLAN_TYPES.PROJECT) {
          deletePlanById({
            plans,
            id,
            type,
          });
        } else {
          deletePlanByType({
            plans,
            id,
            type,
          });
        }

        Modal.destroyAll();
      },
      onCancel: Modal.destroyAll,
    });
    event.stopPropagation();
  };
  const hours = hoverCell && Math.trunc(hoverCell.seconds / 3600);
  const minutes =
    hoverCell && dayjs.duration(hoverCell.seconds, 'seconds').minutes();
  const time = `${hours} h ${minutes} m`;
  const popoverContent = (
    <div>
      {hoverCell && hoverCell.description && (
        <p>Description: {hoverCell.description}</p>
      )}
      {hoverCell && hoverCell.seconds && <p>Planned time N h: {time}</p>}
    </div>
  );
  const endDatePlan =
    cellContent &&
    (dayjs(cellContent.end).isAfter(dayjs(endDate))
      ? endDate
      : cellContent.end);

  const startDatePlan =
    cellContent &&
    (dayjs(cellContent.start).isBefore(dayjs(startDate))
      ? startDate
      : cellContent.start);
  const numberDays =
    cellContent && dayjs(endDatePlan).diff(startDatePlan, 'days') + 1;
  const cellWidth =
    numberDays && `calc(100% * ${numberDays} + ${numberDays}px)`;

  const arrowRightStyle =
    hoverCell && dayjs(cellContent.end).isAfter(endDate)
      ? arrowRightColor(hoverCell.planItem.type)
      : '';
  const arrowLeftStyle =
    hoverCell && dayjs(cellContent.start).isBefore(startDate)
      ? arrowLeftColor(hoverCell.planItem.type)
      : '';

  const colorStyle = hoverCell && cellColor(hoverCell);
  const closeIconColorStyle = hoverCell && closeIconColor(hoverCell);

  const isProjectCell = commonProps.plantype === RM_PLAN_TYPES.PROJECT;

  const canDelete =
    (canUpdatePlans && !isProjectCell) || (canUpdateDeals && isProjectCell);

  return (
    <Popover content={popoverContent} placement="bottom" trigger="hover">
      <div
        className={classNames(
          styles.cell,
          arrowRightStyle,
          arrowLeftStyle,
          colorStyle,
        )}
        style={{ width: cellWidth }}
        {...commonProps}
      >
        {condition && view && (
          <Fragment>
            {view}
            {canDelete && (
              <CloseOutlined
                onClick={onDeletePlan(
                  cellContent.id,
                  cellContent.planItem.type,
                )}
                className={classNames(styles.closeIcon, closeIconColorStyle)}
              />
            )}
          </Fragment>
        )}
      </div>
    </Popover>
  );
};

PopoverCell.propTypes = {
  deletePlanById: PropTypes.func.isRequired,
  deletePlanByType: PropTypes.func.isRequired,
  plans: PropTypes.array,
  hoverCell: PropTypes.object,
  commonProps: PropTypes.object,
  view: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  condition: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  canUpdatePlans: PropTypes.bool,
  canUpdateDeals: PropTypes.bool,
  cellContent: PropTypes.object,
};
export default PopoverCell;
