import { COM_LABELS } from './constants';

export const getCOMDataByRoles = data =>
  data &&
  data.reduce((acc, { month, com_by_roles }) => {
    const monthItem = Object.entries(com_by_roles).map(([role, value]) => ({
      role,
      value,
      month,
    }));

    return [...acc, ...monthItem];
  }, []);

export const getCOMDataTotal = ({ data, average_com }) =>
  data &&
  data.reduce((acc, { month, com }) => {
    const comObj = {
      type: COM_LABELS.CURRENT,
      value: com,
      month,
    };

    const averageComObj = {
      type: COM_LABELS.AVG,
      value: average_com,
      month,
    };

    return [...acc, comObj, averageComObj];
  }, []);

export const comTotalLineStyle = ({ type }) =>
  type === COM_LABELS.AVG ? { lineDash: [5, 5] } : {};
