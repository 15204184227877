import { actionFactory } from 'redux/factories';
import { types } from './types';

export const getTechnologies = actionFactory(types.GET_TECHNOLOGIES);
export const editTechnology = actionFactory(types.UPDATE_TECHNOLOGY);
export const deleteTechnology = actionFactory(types.DELETE_TECHNOLOGY);
export const addTechnology = actionFactory(types.ADD_TECHNOLOGY);
export const deleteTechnologyErrors = actionFactory(
  types.DELETE_TECHNOLOGY_ERRORS,
);
