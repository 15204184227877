exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".submit-d45f7{text-align:center}.warning-f0327{margin-bottom:10px}div.checkboxItem-0c7d2{display:flex;align-items:center;margin-left:30px}div.checkboxItem-0c7d2>div{width:auto}.fieldSwitch-d53c5{height:32px;width:240px;margin-left:10px;display:flex;align-items:center}.switch-38f8b{margin:0 10px;display:flex}.contract-starts-at-item-5f00c{display:flex}.contractStartsAt-ab90a{position:relative}.contractStartsAt-ab90a .unlimitedCheckbox-4aef1{position:absolute;left:400px;top:5px;width:260px}.select-5b960{display:flex}.checkboxSelect-2b67c .ant-form-item-control-input-content{display:grid;grid-template-columns:70% 30%}.selectCheckbox-8f2b2 div:nth-child(2)>div>div{display:flex}.ibanItem-9a971>div>div:last-child>div>div{display:grid;grid-template-columns:75% 12% 8% 5%;align-items:center}.ibanItem-9a971>div>div:last-child>div>div>div{margin-bottom:0}.mainIban-5ea2e{justify-self:center}.addIbanBtn-50157{width:60%}.isMainLabel-54a17{margin-left:5px}.ibanInput-b5714{padding:0}.ibanInput-b5714 input{padding:8px 12px}", ""]);

// Exports
exports.locals = {
	"submit": "submit-d45f7",
	"warning": "warning-f0327",
	"checkboxItem": "checkboxItem-0c7d2",
	"fieldSwitch": "fieldSwitch-d53c5",
	"switch": "switch-38f8b",
	"contract-starts-at-item": "contract-starts-at-item-5f00c",
	"contractStartsAt": "contractStartsAt-ab90a",
	"unlimitedCheckbox": "unlimitedCheckbox-4aef1",
	"select": "select-5b960",
	"checkboxSelect": "checkboxSelect-2b67c",
	"selectCheckbox": "selectCheckbox-8f2b2",
	"ibanItem": "ibanItem-9a971",
	"mainIban": "mainIban-5ea2e",
	"addIbanBtn": "addIbanBtn-50157",
	"isMainLabel": "isMainLabel-54a17",
	"ibanInput": "ibanInput-b5714"
};