import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isCalendarLoading: false,
  calendar: [],
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_CALENDAR:
    case types.CREATE_HOLIDAY:
    case types.UPDATE_HOLIDAY:
    case types.DELETE_HOLIDAY:
      return state.set('isCalendarLoading', true);

    case types.GET_CALENDAR_SUCCESS:
      return state.set('isCalendarLoading', false).set('calendar', payload);

    case types.GET_CALENDAR_FAILED:
    case types.CREATE_HOLIDAY_FAILED:
    case types.UPDATE_HOLIDAY_FAILED:
    case types.DELETE_HOLIDAY_FAILED:
      return state.set('isCalendarLoading', false);

    default:
      return state;
  }
}
