import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import styles from './styles.scss';

const Loader = ({ loading, global = false, children, isDynamicPosition }) => {
  const initialTopHeight = isDynamicPosition
    ? window.innerHeight / 2 + window.pageYOffset - 160
    : 160;
  const [topHeight, setTopHeight] = useState(initialTopHeight);

  useEffect(() => {
    if (isDynamicPosition) {
      const handleLoaderPosition = () => {
        setTopHeight(window.innerHeight / 2 + window.pageYOffset - 160);
      };

      document.addEventListener('scroll', handleLoaderPosition);

      return () => document.removeEventListener('scroll', handleLoaderPosition);
    }
  }, []);

  if (!loading) {
    return children;
  }

  return global ? (
    <div className={styles.globalLoader}>
      <Spin size="large" />
    </div>
  ) : (
    <div className={styles.loaderContainer}>
      <div className={styles.loadingChildren}>{children}</div>
      <div className={isDynamicPosition ? styles.loaderTable : styles.loader}>
        <Spin size="large" className={styles.spin} style={{ top: topHeight }} />
      </div>
    </div>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  global: PropTypes.bool,
  children: PropTypes.any,
  isDynamicPosition: PropTypes.bool,
};

export default Loader;
