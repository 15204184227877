import React from 'react';
import PropTypes from 'prop-types';

import DetailsContainer from '../../../Common/DetailsContainer';
import ProjectItem from './ProjectItem';

const ClientProjects = ({ projects = [] }) => {
  if (!projects.length) {
    return null;
  }

  return (
    <DetailsContainer name="Projects">
      {projects.map(({ id, ...project }) => (
        <ProjectItem key={id} project={project} />
      ))}
    </DetailsContainer>
  );
};

ClientProjects.propTypes = {
  projects: PropTypes.array,
};

export default ClientProjects;
