import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import { getColumns } from './utils';
import styles from './styles.scss';

const ClientInvoiceFilesStep = ({ invoiceData }) => {
  const columns = getColumns();

  const getRowKey = record => record.title;

  return (
    <div className={styles.invoiceThirdStep}>
      {invoiceData && invoiceData.attachments && (
        <Table
          dataSource={invoiceData.attachments}
          columns={columns}
          size="small"
          pagination={false}
          rowKey={getRowKey}
        />
      )}
    </div>
  );
};

ClientInvoiceFilesStep.propTypes = {
  invoiceData: PropTypes.object,
};

export default ClientInvoiceFilesStep;
