import uniqBy from 'lodash/uniqBy';
import {
  getMonthNameWithYear,
  formatNumberWithCommas,
  numberSorter,
} from 'utils/helpers';
import { stringSorter } from 'utils/stringSorter';
import {
  DASHBOARD_SUBCONTRACTORS_COMMON,
  MARGIN_NEGATIVE_VALUE,
} from './constants';
import styles from './styles.scss';

export const getPeriodFilters = data =>
  uniqBy(data, 'period').map(item => ({
    text: getMonthNameWithYear(item.period),
    value: item.period,
  }));

const getFilters = filters =>
  filters?.map(({ name }) => ({ text: name, value: name })) || [];

const filteredValue = (valueType, filterInfo) =>
  (filterInfo && filterInfo[valueType]) || null;

export const getColumns = ({ periodFilters, filters, filterInfo }) => [
  {
    title: DASHBOARD_SUBCONTRACTORS_COMMON.period_title,
    dataIndex: DASHBOARD_SUBCONTRACTORS_COMMON.period,
    filters: periodFilters,
    filteredValue: filteredValue(
      DASHBOARD_SUBCONTRACTORS_COMMON.period,
      filterInfo,
    ),
    render: value => getMonthNameWithYear(value),
  },
  {
    title: DASHBOARD_SUBCONTRACTORS_COMMON.project_title,
    dataIndex: DASHBOARD_SUBCONTRACTORS_COMMON.project,
    sorter: stringSorter(DASHBOARD_SUBCONTRACTORS_COMMON.project),
    filters: getFilters(filters.projects),
    filteredValue: filteredValue(
      DASHBOARD_SUBCONTRACTORS_COMMON.project,
      filterInfo,
    ),
  },
  {
    title: DASHBOARD_SUBCONTRACTORS_COMMON.employer_title,
    dataIndex: DASHBOARD_SUBCONTRACTORS_COMMON.employer,
    sorter: stringSorter(DASHBOARD_SUBCONTRACTORS_COMMON.employer),
    filters: getFilters(filters.employees),
    filteredValue: filteredValue(
      DASHBOARD_SUBCONTRACTORS_COMMON.employer,
      filterInfo,
    ),
  },
  {
    title: DASHBOARD_SUBCONTRACTORS_COMMON.name_title,
    dataIndex: DASHBOARD_SUBCONTRACTORS_COMMON.name,
    sorter: stringSorter(DASHBOARD_SUBCONTRACTORS_COMMON.name),
    filters: getFilters(filters.names),
    filteredValue: filteredValue(
      DASHBOARD_SUBCONTRACTORS_COMMON.name,
      filterInfo,
    ),
  },
  {
    title: DASHBOARD_SUBCONTRACTORS_COMMON.paid_rate_title,
    dataIndex: DASHBOARD_SUBCONTRACTORS_COMMON.paid_rate,
    sorter: numberSorter(DASHBOARD_SUBCONTRACTORS_COMMON.paid_rate),
    render: formatNumberWithCommas,
  },
  {
    title: DASHBOARD_SUBCONTRACTORS_COMMON.invoiced_rate_title,
    dataIndex: DASHBOARD_SUBCONTRACTORS_COMMON.invoiced_rate,
    sorter: numberSorter(DASHBOARD_SUBCONTRACTORS_COMMON.invoiced_rate),
    render: formatNumberWithCommas,
  },
  {
    title: DASHBOARD_SUBCONTRACTORS_COMMON.margin_title,
    dataIndex: DASHBOARD_SUBCONTRACTORS_COMMON.margin,
    sorter: numberSorter(DASHBOARD_SUBCONTRACTORS_COMMON.margin),
    render: value => ({
      props: {
        className: value < MARGIN_NEGATIVE_VALUE && styles.redCell,
      },
      children: formatNumberWithCommas(value),
    }),
  },
];
