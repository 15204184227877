import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ClientContractsModal } from 'components/ClientCommon';
import PageHeader from 'components/Common/PageHeader';
import { Button, Loader } from 'components/Common';
import Table from 'components/Common/Table';
import { getFiltersWithOutNull } from 'utils/common';
import { SORT_DIRECTIONS } from 'utils/constants';
import { DATE_FORMAT } from 'utils/timeConstants';
import { useRoleContext } from 'utils/hooks';
import { getPopupContainerHandler, getRowKey } from 'utils/helpers';
import { contractsSelector, organizationsSelector } from 'redux/selectors';
import {
  getContracts,
  createContract,
  updateContract,
  deleteContract,
} from 'redux/Contracts/actions';
import { getOrganizations } from 'redux/Organizations/actions';

import { getColumns } from './utils';
import { CONTRACTS_TABLE_COMMON } from './constants';
import styles from './styles.scss';

const CientContractsPage = ({ isClientPage }) => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
  const [sortInfo, setSortInfo] = useState({
    order: SORT_DIRECTIONS.ascend,
    field: CONTRACTS_TABLE_COMMON.customer_title,
  });
  const [filtersInfo, setFiltersInfo] = useState({});
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isEditRecord, setIsEditRecord] = useState(false);
  const [editRecordId, setEditRecordId] = useState('');

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const role = useRoleContext();

  const {
    contracts,
    isLoadingContracts,
    contractsFilters,
    totalContracts,
  } = useSelector(contractsSelector);

  const { organizationsFull } = useSelector(organizationsSelector);

  const onShowModal = () => setIsVisibleModal(true);

  const onHideModal = () => {
    setIsVisibleModal(false);
    setIsEditRecord(false);
    form.resetFields();
    setEditRecordId('');
  };

  const onEditRecord = record => {
    setIsEditRecord(true);
    setIsVisibleModal(true);
    const formValues = Object.keys(record).reduce((acc, curr) => {
      if (curr === 'end_date' || curr === 'start_date' || curr === 'dated') {
        return record[curr]
          ? { ...acc, [curr]: dayjs(record[curr]) }
          : { ...acc, [curr]: record[curr] };
      }

      return { ...acc, [curr]: record[curr] };
    }, {});
    setEditRecordId(record.id);
    form.setFieldsValue(formValues);
  };

  const dispatchDeleteContract = ({ id }) => {
    Modal.confirm({
      title: 'Delete contract',
      content: 'Are you sure you want to delete client contract?',
      onOk: () => {
        dispatch(deleteContract({ id }));
      },
    });
  };

  const columns = useMemo(
    () =>
      getColumns({
        filtersInfo,
        sortInfo,
        role,
        deleteContract: dispatchDeleteContract,
        onEditRecord,
        filtersData: contractsFilters,
      }),
    [filtersInfo, sortInfo, contracts],
  );

  const contractsPaginationParams = {
    page: pagination.current,
    size: pagination.pageSize,
  };

  const onTableChange = (pagination, filters, sorter) => {
    const filtersWithOutNull = getFiltersWithOutNull(filters);
    setPagination(pagination);
    setSortInfo(sorter);
    setFiltersInfo(filtersWithOutNull);
    dispatch(
      getContracts({
        ...pagination,
        filters: {
          ...filtersWithOutNull,
        },
        sort: `${sorter.field}:${sorter.order}`,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      getContracts({
        ...contractsPaginationParams,
        sort: `${sortInfo.field}:${sortInfo.order}`,
      }),
    );
    dispatch(getOrganizations());
  }, []);

  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      total: Number(totalContracts),
      showTotal: total => `Found ${total} records`,
    }));
  }, [totalContracts]);

  const onAddContract = async () => {
    const isValidate = await form.validateFields();

    if (isValidate) {
      const values = form.getFieldsValue();
      const modifiedValues = Object.keys(values).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: dayjs.isDayjs(values[curr])
            ? dayjs(values[curr]).format(DATE_FORMAT)
            : values[curr],
        }),
        {},
      );

      const valuesWithoutNull = Object.keys(modifiedValues).reduce(
        (acc, cur) =>
          modifiedValues[cur] ? { ...acc, [cur]: modifiedValues[cur] } : acc,
        {},
      );

      if (isEditRecord) {
        dispatch(
          updateContract({
            values: valuesWithoutNull,
            page: pagination.current,
            size: pagination.pageSize,
            id: editRecordId,
            sort: `${sortInfo.field}:${sortInfo.order}`,
          }),
        );
      } else {
        dispatch(
          createContract({
            values: valuesWithoutNull,
            page: pagination.current,
            size: pagination.pageSize,
            sort: `${sortInfo.field}:${sortInfo.order}`,
          }),
        );
      }

      onHideModal();
    }
  };

  return (
    <React.Fragment>
      <PageHeader
        title="Clients contracts"
        extra={
          <Button
            type="primary"
            onClick={onShowModal}
            className={classNames({
              [styles.buttonMargin]: isClientPage,
            })}
          >
            Add contract
          </Button>
        }
      />
      <Loader loading={isLoadingContracts}>
        <Table
          rowKey={getRowKey}
          bordered
          pagination={pagination}
          columns={columns}
          onChange={onTableChange}
          dataSource={contracts}
          getPopupContainer={getPopupContainerHandler}
        />
      </Loader>
      <ClientContractsModal
        isVisibleModal={isVisibleModal}
        onHideModal={onHideModal}
        organizationsData={organizationsFull}
        form={form}
        onFinish={onAddContract}
        isEditRecord={isEditRecord}
      />
    </React.Fragment>
  );
};

CientContractsPage.propTypes = {
  isClientPage: PropTypes.bool,
};

export default CientContractsPage;
