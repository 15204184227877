import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { formatNumberWithCommas } from 'utils/helpers';
import { getString } from './utils';

const AmountCell = ({ amount, code }) => {
  const value = formatNumberWithCommas(amount);

  return <span>{getString({ value, code })}</span>;
};

AmountCell.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  code: PropTypes.string.isRequired,
};

export default memo(AmountCell);
