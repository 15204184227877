exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".actions-d4304{margin-bottom:15px}.actionButton-0c196{margin-left:5px;margin-bottom:5px}.modal-8a12e>div>button>span{display:flex;justify-content:center;align-items:center}.hoverButton-d1ccc{display:none}.field-41dab:hover .hoverButton-d1ccc{display:inline-block}.field-41dab{width:fit-content}.salaryAlert-7e7e8{margin-bottom:10px}", ""]);

// Exports
exports.locals = {
	"actions": "actions-d4304",
	"actionButton": "actionButton-0c196",
	"modal": "modal-8a12e",
	"hoverButton": "hoverButton-d1ccc",
	"field": "field-41dab",
	"salaryAlert": "salaryAlert-7e7e8"
};