import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from 'antd';

import styles from './styles.scss';

const { Item } = Form;

const AverageSalaryForm = ({ form, onSubmit }) => (
  <Form
    layout="vertical"
    className={styles.form}
    form={form}
    onFinish={onSubmit}
  >
    <Item name="period">
      <Input type="hidden" />
    </Item>
    <Item
      label="Amount"
      name="value"
      rules={[
        {
          required: true,
          message: 'Required field',
        },
        {
          pattern: /^[0-9]+(\.[0-9]+)?$/gim,
          message: 'Invalid format',
        },
        {
          pattern: /^(-)?(\d)*(\.)?([0-9]{1,2})?$/,
          message: 'No more than two decimal places',
        },
      ]}
    >
      <Input />
    </Item>
  </Form>
);

AverageSalaryForm.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AverageSalaryForm;
