export const WORKFLOW_TIMELINE = [
  {
    status: 'ON_ALLOCATIONS',
    text: 'This invoice is being actively worked on at the moment.',
  },
  {
    status: 'ON_WORKLOGS',
    text: 'This invoice is being actively worked on at the moment.',
  },
  {
    status: 'WORKLOGS_READY',
    text:
      'Worklogs were confirmed by the client or PM. Responsible worker (accountant, etc.) can continue with that data.',
  },
  {
    status: 'INVOICE_READY',
    text: 'Invoice was prepared and waiting for review.',
  },
  {
    status: 'APPROVED',
    text:
      'Invoice was approved by reviewer. That means there is no issues with the invoice. Invoice can be sent to the client.',
  },
  {
    status: 'DECLINED',
    text:
      'Invoice was declined by reviewer. That means it should be edited and fixed. Request the reviewer to provide more details.',
  },
  {
    status: 'SENT',
    text: 'Invoice was sent to client. Use this status after invoice was sent.',
  },
  {
    status: 'CLIENT_REJECTED',
    text:
      'Invoice was rejected by client. Use this status when client rejected the invoice by some reasons.',
  },
  {
    status: 'PAID',
    text:
      'Invoice was paid by client. This status will be set when payments will be collected.',
  },
];

export const TIMELINE_COLORS = {
  red: '#cf1322',
  green: '#389e0d',
  yellow: '#d48806',
  gray: '#d9d9d9',
};
