import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

// @ts-ignore
import styles from './styles.scss';

const FilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataIndex,
  handleReset,
}) => (
  <div className={styles.filterDropdown}>
    <Input
      placeholder={`Search ${dataIndex}`}
      value={selectedKeys[0]}
      onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={confirm}
      className={styles.filterDropdownInput}
      ref={input => input && input.focus()}
    />
    <Button
      type="primary"
      onClick={confirm}
      icon={<SearchOutlined />}
      size="small"
      className={styles.filterDropdownSearchButton}
    >
      Search
    </Button>
    <Button
      onClick={() => handleReset ? handleReset(clearFilters) : clearFilters()}
      size="small"
      className={styles.filterDropdownResetButton}
    >
      Reset
    </Button>
  </div>
);

FilterDropdown.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array,
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  dataIndex: PropTypes.string.isRequired,
};

export default FilterDropdown;
