import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import { MONTH_FORMAT_DAY_DIGIT } from 'containers/InvoicesPage/constants';
import PageHeader from 'components/Common/PageHeader';
import DashboardSubcontractorsOverall from 'components/DashboardCommon/DashboardSubcontractorsOverall';
import DashboardSubcontractorsGPM from 'components/DashboardCommon/DashboardSubcontractorsGPM';
import DashboardSubcontractorsHours from 'components/DashboardCommon/DashboardSubcontractorsHours';
import DashboardSubcontractorsRate from 'components/DashboardCommon/DashboardSubcontractorsRate';
import DashboardSubcontractorsTable from 'components/DashboardCommon/DashboardSubcontractorsTable';
import { Loader } from 'components/Common';
import RangePicker from 'components/Common/RangePicker';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getMonthsRange,
} from 'utils/helpers';
import { getFiltersWithOutNull } from 'utils/common';
import { dashboardSelector } from 'redux/selectors';
import {
  getDashboardSubcontractors,
  getDashboardSubcontractorsTable,
} from 'redux/Dashboard/actions';

import styles from './styles.scss';

const DashboardSubcontractorsPage = () => {
  const [period, setPeriod] = useState(getMonthsRange(new Date(), 2));
  const [filterInfo, setFilterInfo] = useState({});

  const dispatch = useDispatch();

  const {
    dashboardSubcontractorsOverall,
    dashboardSubcontractorsTable,
    dashboardSubcontractorsFilters,
    isDashboardLoading,
  } = useSelector(dashboardSelector);

  const dashboardDefaultRequest = {
    from: dayjs(period[0]).format(MONTH_FORMAT_DAY_DIGIT),
    to: dayjs(period[1]).format(MONTH_FORMAT_DAY_DIGIT),
  };

  useEffect(() => {
    dispatch(getDashboardSubcontractors(dashboardDefaultRequest));
  }, [period]);

  const onPeriodChange = period => {
    setPeriod([getFirstDayOfMonth(period[0]), getLastDayOfMonth(period[1])]);
  };

  const onTableChange = (pagination, filters, sorter, { action }) => {
    if (action !== 'filter') return;

    const filtersWithoutNull = getFiltersWithOutNull(filters);
    dispatch(
      getDashboardSubcontractorsTable({
        ...dashboardDefaultRequest,
        filters: filtersWithoutNull,
      }),
    );
    setFilterInfo(filtersWithoutNull);
  };

  return (
    <div>
      <PageHeader
        title="Dashboard subcontractors"
        extra={
          <div className={styles.dashboardHeader}>
            <RangePicker
              picker="month"
              value={period}
              format="YYYY/MM"
              onChange={onPeriodChange}
              className={styles.rangePicker}
            />
          </div>
        }
      />
      <Loader loading={isDashboardLoading} isDynamicPosition>
        <DashboardSubcontractorsOverall
          monthData={dashboardSubcontractorsOverall}
          period={period}
        />
        <DashboardSubcontractorsRate
          monthData={dashboardSubcontractorsOverall}
          period={period}
        />
        <DashboardSubcontractorsGPM
          monthData={dashboardSubcontractorsOverall}
          period={period}
        />
        <DashboardSubcontractorsHours
          monthData={dashboardSubcontractorsOverall}
          period={period}
        />
      </Loader>
      <Loader loading={isDashboardLoading}>
        <DashboardSubcontractorsTable
          tableData={dashboardSubcontractorsTable}
          filters={dashboardSubcontractorsFilters}
          filterInfo={filterInfo}
          onTableChange={onTableChange}
        />
      </Loader>
    </div>
  );
};

export default DashboardSubcontractorsPage;
