export const PERIOD_FORMAT = 'YYYY-MM-01';

export const SUBCONTRACTS_TABLE_INDEX = {
  project_key: 'project_key',
  project_name: 'project_name',
  project_id: 'project_id',
  employer: 'employer',
  employee_name: 'employee_name',
  employee_id: 'employee_id',
  spent_hours: 'spent_hours',
  cost: 'cost',
};

export const SUBCONTRACTORS_UPDATED_FORMAT = 'MMMM Do YYYY, h:mm:ss a';
