exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dashboardAERTotalItem-6281d{display:flex;justify-content:space-between;align-items:center;margin-bottom:6px;padding:10px 6px;border:1px solid #d9d9d9;text-align:center}.dashboardAERTotalItem-6281d div{width:30%}.dashboardAERTotalItem-6281d .icon-1fcf0{width:10%}.dashboardAERTotalItem-6281d .firstPeriod-5c530,.dashboardAERTotalItem-6281d .secondPeriod-a96b6{width:30%}.dashboardAERTotalItem-6281d .title-82a50{width:25%;text-align:left;font-weight:500}", ""]);

// Exports
exports.locals = {
	"dashboardAERTotalItem": "dashboardAERTotalItem-6281d",
	"icon": "icon-1fcf0",
	"firstPeriod": "firstPeriod-5c530",
	"secondPeriod": "secondPeriod-a96b6",
	"title": "title-82a50"
};