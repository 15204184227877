import React, { useState, useEffect, useMemo, createRef } from 'react';
import { Modal, Form } from 'antd';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import PageHeader from 'components/Common/PageHeader';
import { Loader, Table } from 'components/Common';
import RangePicker from 'components/Common/RangePicker';
import { InvoicesModal } from 'components/InvoicesCommon';
import { mapArrayToEntities, getFiltersWithOutNull } from 'utils/common';
import { DATE_FORMAT, MONTH_FORMAT, Y_M_D } from 'utils/timeConstants';
import { getRowKey } from 'utils/helpers';
import { useRoleContext } from 'utils/hooks';
import {
  clientsSelector,
  employeesSelector,
  invoicesSelector,
  organizationsSelector,
  usersSelector,
} from 'redux/selectors';
import {
  getInvoices,
  approveInvoice,
  declineInvoice,
  updateInvoice,
  getInvoiceStatuses,
} from 'redux/Invoices/actions';
import { loadUsers } from 'redux/Users/actions';
import { loadEmployees } from 'redux/Employees/actions';
import { getOrganizations } from 'redux/Organizations/actions';
import { loadClients } from 'redux/Clients/actions';

import {
  LOAD_CLIENTS_PARAMS,
  TIME_FIELDS,
  DEFAULT_INVOICE_PAGINATION,
  INVOICE_CONFIRM_ACTIONS,
  INVOICES_INDEX,
} from './constants';
import { getColumns, getStartAndEndOfMonth, getEndOfMonth } from './utils';
import styles from './styles.scss';

const {
  CONFIRM_APPROVE_TITLE,
  CONFIRM_REJECT_TITLE,
  CONFIRM_DECLINE,
  CONFIRM_APPROVE,
  OK,
  CANCEL,
} = INVOICE_CONFIRM_ACTIONS;

const InvoicesPage = () => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const [period, setPeriod] = useState(getStartAndEndOfMonth(new Date()));
  const [searchText, setSearchText] = useState('');
  const [filtersInfo, setFiltersInfo] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [editedInvoiceId, setEditedInvoiceId] = useState(null);
  const [sortInfo, setSortInfo] = useState({
    order: 'descend',
    columnKey: 'number',
  });
  const [form] = Form.useForm();
  const searchInput = createRef();

  const dispatch = useDispatch();

  const role = useRoleContext();

  const {
    isInvoicesLoading,
    invoices,
    invoiceFilters,
    invoiceStatuses,
    invoicesTotal,
  } = useSelector(invoicesSelector);

  const { users } = useSelector(usersSelector);

  const { organizationsFull } = useSelector(organizationsSelector);

  const { employees } = useSelector(employeesSelector);

  const { clients } = useSelector(clientsSelector);

  const onChangePeriod = period => {
    if (period) {
      const endDate = dayjs(getEndOfMonth(period[1]));
      const { size } = DEFAULT_INVOICE_PAGINATION;
      setPeriod([period[0], endDate]);
      setPagination({ current: 1, pageSize: size });

      dispatch(
        getInvoices({
          filters: {
            ...filtersInfo,
            period_from: dayjs(period[0]).format(DATE_FORMAT),
            period_to: dayjs(endDate).format(DATE_FORMAT),
          },
          sort: `${sortInfo.columnKey}:${sortInfo.order}`,
          ...DEFAULT_INVOICE_PAGINATION,
        }),
      );
    }
  };

  const filtersData = {
    period_from: dayjs(period[0]).format(DATE_FORMAT),
    period_to: dayjs(period[1]).format(DATE_FORMAT),
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const onTableChange = (pagination, filters, sorter) => {
    if (filters.hasOwnProperty(INVOICES_INDEX.client_title)) {
      filters.client_id = filters.client_title;
      filters.client_title = null;
    }

    if (filters.hasOwnProperty(INVOICES_INDEX.contractor_name)) {
      filters.contractor_id = filters.contractor_name;
      filters.contractor_name = null;
    }

    if (filters.hasOwnProperty(INVOICES_INDEX.author_name)) {
      filters.owner_id = filters.author_name;
      filters.author_name = null;
    }

    if (filters.hasOwnProperty(INVOICES_INDEX.reviewer_name)) {
      filters.reviewer_id = filters.reviewer_name;
      filters.reviewer_name = null;
    }

    const filtersWithOutNull = getFiltersWithOutNull(filters);
    setPagination(pagination);

    const sortOrder = sorter.order || 'ascend';
    setSortInfo({ ...sorter, order: sortOrder });

    if (Array.isArray(filtersWithOutNull?.number)) {
      filtersWithOutNull.number = filtersWithOutNull?.number[0];
    }

    setFiltersInfo(filtersWithOutNull);

    dispatch(
      getInvoices({
        filters: {
          ...filtersData,
          ...filtersWithOutNull,
        },
        page: pagination.current,
        size: pagination.pageSize,
        sort: `${sorter.columnKey}:${sortOrder}`,
      }),
    );
  };

  const onApproveInvoice = ({ id }) => {
    if (id) {
      Modal.confirm({
        title: CONFIRM_APPROVE_TITLE,
        content: CONFIRM_APPROVE,
        okText: OK,
        cancelText: CANCEL,
        onOk: () => {
          dispatch(
            approveInvoice({
              id,
              filters: {
                ...filtersInfo,
                ...filtersData,
              },
              page: pagination.current,
              size: pagination.pageSize,
              sort: `${sortInfo.columnKey}:${sortInfo.order}`,
            }),
          );

          Modal.destroyAll();
        },
        onCancel: Modal.destroyAll,
      });
    }
  };

  const onDeclineInvoice = ({ id }) => {
    if (id) {
      Modal.confirm({
        title: CONFIRM_REJECT_TITLE,
        content: CONFIRM_DECLINE,
        okText: OK,
        cancelText: CANCEL,
        onOk: () => {
          dispatch(
            declineInvoice({
              id,
              filters: {
                ...filtersInfo,
                ...filtersData,
              },
              page: pagination.current,
              size: pagination.pageSize,
              sort: `${sortInfo.columnKey}:${sortInfo.order}`,
            }),
          );

          Modal.destroyAll();
        },
        onCancel: Modal.destroyAll,
      });
    }
  };

  const groupUsersById = useMemo(() => mapArrayToEntities(users).entities, [
    users,
  ]);

  const groupClientsById = useMemo(() => mapArrayToEntities(clients).entities, [
    clients,
  ]);

  const organizationdsGroupById = useMemo(
    () => mapArrayToEntities(organizationsFull).entities,
    [organizationsFull],
  );

  const onVisibleEditModal = record => {
    const formValues = Object.keys(record).reduce((acc, curr) => {
      if (TIME_FIELDS.includes(curr)) {
        return record[curr]
          ? { ...acc, [curr]: dayjs(record[curr]) }
          : { ...acc, [curr]: record[curr] };
      }

      if (curr === 'client_id') {
        const clientId = record[curr];
        const client = groupClientsById[clientId];

        return { ...acc, [curr]: client.title };
      }

      if (curr === 'contractor_id') {
        const contractorId = record[curr];
        const contractor = organizationdsGroupById[contractorId];

        return { ...acc, [curr]: contractor ? contractor.friendly_name : '' };
      }

      return { ...acc, [curr]: record[curr] };
    }, {});
    setEditedInvoiceId(record.id);
    form.setFieldsValue(formValues);
    setIsVisible(true);
  };

  const onHideModal = () => {
    setIsVisible(false);
    setEditedInvoiceId(null);
    form.resetFields();
  };

  const handleReset = clearFilters => {
    clearFilters();
    setFiltersInfo({});
    dispatch(
      getInvoices({
        filters: {
          ...filtersData,
        },
        page: pagination.current,
        size: pagination.pageSize,
      }),
    );
  };

  const columns = useMemo(
    () =>
      getColumns({
        groupUsersById,
        groupClientsById,
        filtersInfo,
        role,
        onApproveInvoice,
        onDeclineInvoice,
        organizationdsGroupById,
        onVisibleEditModal,
        invoiceStatuses,
        sortInfo,
        searchInput,
        handleReset,
        employees,
        filters: invoiceFilters,
      }),
    [
      groupUsersById,
      groupClientsById,
      employees,
      filtersInfo,
      organizationdsGroupById,
      invoiceStatuses,
      sortInfo,
      searchText,
      filtersData,
      invoiceFilters,
    ],
  );

  useEffect(() => {
    dispatch(
      getInvoices({
        page: pagination.current,
        size: pagination.pageSize,
        sort: `${sortInfo.columnKey}:${sortInfo.order}`,
        filters: {
          ...filtersData,
        },
      }),
    );
    dispatch(loadUsers());
    dispatch(loadClients(LOAD_CLIENTS_PARAMS));
    dispatch(getOrganizations());
    dispatch(loadEmployees());
    dispatch(getInvoiceStatuses());
  }, []);

  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      total: Number(invoicesTotal),
      showTotal: total => `Found ${total} records`,
    }));
  }, [invoicesTotal]);

  const onUpdateInvoice = () => {
    const values = form.getFieldsValue();

    dispatch(
      updateInvoice({
        values: {
          sent_at: values.sent_at
            ? dayjs(values.sent_at).format(Y_M_D)
            : values.sent_at,
          status: values.status,
        },
        id: editedInvoiceId,
        page: pagination.current,
        size: pagination.pageSize,
        filters: {
          ...filtersData,
          ...filtersInfo,
        },
        sort: `${sortInfo.columnKey}:${sortInfo.order}`,
      }),
    );

    onHideModal();
  };

  return (
    <React.Fragment>
      <PageHeader
        title="Invoices"
        extra={
          <>
            <span className={styles.invoicePeriod}>Invoicing period</span>
            <RangePicker
              picker="month"
              defaultValue={period}
              format={MONTH_FORMAT}
              onChange={onChangePeriod}
              className={styles.rangePicker}
            />
          </>
        }
      />
      <Loader loading={isInvoicesLoading} isDynamicPosition>
        <Table
          className={styles.invoiceTable}
          rowKey={getRowKey}
          bordered
          pagination={pagination}
          onChange={onTableChange}
          columns={columns}
          dataSource={invoices}
        />
      </Loader>
      <InvoicesModal
        visible={isVisible}
        onHideModal={onHideModal}
        form={form}
        onFinish={onUpdateInvoice}
        currentInvoice={{ id: editedInvoiceId }}
        role={role}
      />
    </React.Fragment>
  );
};

export default InvoicesPage;
