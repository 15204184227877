exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "header.header-30135{height:60px;padding:0 40px !important;line-height:60px;background:#fff !important;box-shadow:0px 4px 20px 0px rgba(112,112,128,.1)}header.header-30135 .headerContainer-ca5fc{display:flex;align-items:center;justify-content:space-between}header.header-30135 .headerContainer-ca5fc .logout-420b6{display:flex;align-items:center;margin-left:25px;box-sizing:border-box;cursor:pointer}header.header-30135 .headerContainer-ca5fc span.avatar-56a8a{margin-right:5px;display:flex;justify-content:center;align-items:center}header.header-30135 .headerContainer-ca5fc .button-b52fd{margin:0 15px}header.header-30135 .headerContainer-ca5fc .headerLeft-62b56{display:flex;align-items:center}header.header-30135 .headerContainer-ca5fc .headerRight-7581f{display:flex;align-items:center}header.header-30135 .headerContainer-ca5fc .notificationTitle-19522{display:flex;justify-content:space-between;align-items:center}header.header-30135 .headerContainer-ca5fc .notificationContent-eeca8{margin:0;padding:0}header.header-30135 .headerContainer-ca5fc .notificationContent-eeca8 .messageTitle-09041{list-style:none}header.header-30135 .headerContainer-ca5fc .notificationContent-eeca8 .notificationFooter-69535{display:flex;justify-content:flex-end}header.header-30135 .headerContainer-ca5fc .bell-c2041{color:#ccc;font-size:22px;margin-left:15px}header.header-30135 .badge-0a33c .ant-badge-count{height:initial;padding:2px 4px;border-radius:4px;font-size:14px;line-height:12px}", ""]);

// Exports
exports.locals = {
	"header": "header-30135",
	"headerContainer": "headerContainer-ca5fc",
	"logout": "logout-420b6",
	"avatar": "avatar-56a8a",
	"button": "button-b52fd",
	"headerLeft": "headerLeft-62b56",
	"headerRight": "headerRight-7581f",
	"notificationTitle": "notificationTitle-19522",
	"notificationContent": "notificationContent-eeca8",
	"messageTitle": "messageTitle-09041",
	"notificationFooter": "notificationFooter-69535",
	"bell": "bell-c2041",
	"badge": "badge-0a33c"
};