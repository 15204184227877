import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const DashboardAERTotalItem = ({
  title,
  penultimateMonth,
  lastMonth,
  icon,
}) => (
  <div className={styles.dashboardAERTotalItem}>
    <div className={styles.title}>{title}</div>
    <div className={styles.icon}>{icon}</div>
    <div className={styles.firstPeriod}>{penultimateMonth}</div>
    <div className={styles.secondPeriod}>{lastMonth}</div>
  </div>
);

DashboardAERTotalItem.propTypes = {
  title: PropTypes.string.isRequired,
  penultimateMonth: PropTypes.string,
  lastMonth: PropTypes.string,
  icon: PropTypes.element,
};

export default DashboardAERTotalItem;
