import dayjs from 'dayjs';
import { getConditionPreview } from '../../ClientAllocationTable/utils';
import { ALERT_TYPES } from './constants';

const compareLoggedExpected = ({
  logged,
  expected,
  condition,
  hours_per_week,
}) => {
  if (!logged)
    return { message: 'No worklogs found', type: ALERT_TYPES.WARNING };

  let message = '';

  switch (condition) {
    case '<=':
      message =
        logged > expected ? `Worklog exceded ${hours_per_week} h/week` : '';

      break;
    case '>=':
      message =
        logged < expected ? `Logged less than ${hours_per_week} h/week` : '';

      break;
    case '=':
      if (logged === expected) {
        return null;
      }

      message =
        logged < expected
          ? `Logged less than ${hours_per_week} h/week`
          : `Worklog exceded ${hours_per_week} h/week`;

      break;
    default:
      return null;
  }

  return {
    message,
    type: ALERT_TYPES.ERROR,
  };
};

const getWorkDays = ({ calendar, period_from, period_to }) =>
  calendar.reduce(
    (acc, curr) =>
      !curr.is_day_off &&
      !dayjs(curr.date).isBefore(period_from) &&
      !dayjs(curr.date).isAfter(period_to)
        ? acc + 1
        : acc,
    0,
  );

const getRoleName = ({ jiraRolesGroupedById, allocation }) => {
  const {
    rate,
    jira_role_id,
    currency_code,
    hours_per_week,
    condition,
  } = allocation;

  return `${
    jiraRolesGroupedById[jira_role_id].name
  } / ${currency_code} ${rate} ${getConditionPreview(
    condition,
  )} ${hours_per_week} h/week`;
};

const getAllocationsStepLogged = ({
  loggedAllocations,
  projectAllocation,
  worklogs,
  projectId,
}) => {
  const { period_from, period_to, id } = projectAllocation;
  const workers = loggedAllocations.reduce((workers, loggedAllocation) => {
    if (loggedAllocation.id === id) {
      return [...workers, loggedAllocation.worker];
    }

    return workers;
  }, []);

  const loggedSeconds = worklogs[projectId]?.reduce((logged, worklog) => {
    if (
      workers.includes(worklog.worker) &&
      !dayjs(worklog.started).isBefore(period_from) &&
      !dayjs(worklog.started).isAfter(period_to)
    ) {
      return logged + worklog.timeSpentSeconds;
    }

    return logged;
  }, 0);

  return loggedSeconds / 3600;
};

const getWorklogsLogged = ({ projectAllocation, worklogs, projectId }) => {
  const loggedSeconds = worklogs[projectId]?.reduce((logged, worklog) => {
    if (worklog.allocation_id === projectAllocation.id && !worklog.deleted) {
      return logged + worklog.spent_seconds;
    }

    return logged;
  }, 0);

  return loggedSeconds / 3600;
};

export const getAlerts = ({
  invoiceAllocations,
  loggedAllocations,
  jiraRolesGroupedById,
  projectGroupedById,
  worklogs,
  calendar,
  current,
}) => {
  if (current < 1 || current > 2) return [];

  let alertsArr = [];

  for (let project in invoiceAllocations) {
    const projectAlerts = invoiceAllocations[project].reduce(
      (alerts, projectAllocation) => {
        const {
          period_from,
          period_to,
          hours_per_week,
          condition,
          id,
        } = projectAllocation;

        // Вычисляет все рабочие дни в периоде аллокейшена, в зависимости от календаря (api/v2/holidays)
        const workDays = getWorkDays({
          calendar,
          period_from: period_from,
          period_to: period_to,
        });
        const expected = (workDays / 5) * hours_per_week;

        // Вычисляет затреканное время в зависимости от текущего шага.
        const logged =
          current === 1
            ? getAllocationsStepLogged({
                loggedAllocations,
                projectAllocation,
                worklogs,
                projectId: project,
              })
            : getWorklogsLogged({
                projectAllocation,
                worklogs,
                projectId: project,
              });

        const roleName = getRoleName({
          jiraRolesGroupedById,
          allocation: projectAllocation,
        });

        // Сравнивает затреканное и ожидаемое время с учетом флага condition.
        const alertInfo = compareLoggedExpected({
          logged,
          expected,
          condition,
          hours_per_week,
        });

        if (!alertInfo) {
          return alerts;
        }

        const alert = {
          logged,
          expected,
          message: alertInfo.message,
          type: alertInfo.type,
          roleName,
          periodFrom: period_from,
          periodTo: period_to,
          projectName: projectGroupedById[project].name,
          projectId: project,
          allocationId: id,
        };

        return [...alerts, alert];
      },
      [],
    );

    alertsArr = [...alertsArr, ...projectAlerts];
  }

  return alertsArr;
};
