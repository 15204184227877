import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import EmployeeEditForm from 'components/EmployeeCommon/EmployeeEditForm';

const UserProfile = ({
  employee,
  onUpdate,
  summaryEmployees,
  jiraUsers,
  employeePositions,
  teams,
  organizations,
  onJiraSearch,
  form,
  isExternal,
  isEditable,
  setIsEditable,
  setIsExternal,
  ibanCurrencies,
  currencies,
}) => (
  <React.Fragment>
    <Row gutter={16}>
      <Col md={{ span: 14 }}>
        <EmployeeEditForm
          form={form}
          employee={employee}
          onSubmit={onUpdate}
          employees={summaryEmployees}
          jiraUsers={jiraUsers}
          employeePositions={employeePositions}
          teams={teams}
          organizations={organizations}
          onJiraSearch={onJiraSearch}
          isExternal={isExternal}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          setIsExternal={setIsExternal}
          ibanCurrencies={ibanCurrencies}
          currencies={currencies}
        />
      </Col>
    </Row>
  </React.Fragment>
);

UserProfile.propTypes = {
  employee: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  summaryEmployees: PropTypes.array.isRequired,
  jiraUsers: PropTypes.array.isRequired,
  employeePositions: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  organizations: PropTypes.array.isRequired,
  onJiraSearch: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  isExternal: PropTypes.bool.isRequired,
  isEditable: PropTypes.string.isRequired,
  setIsEditable: PropTypes.func.isRequired,
  setIsExternal: PropTypes.func.isRequired,
  ibanCurrencies: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
};

export default UserProfile;
