export const types = {
  LOAD_CLIENTS: 'clients/LOAD_CLIENTS',
  LOAD_CLIENTS_SUCCESS: 'clients/LOAD_CLIENTS_SUCCESS',
  LOAD_CLIENTS_FAILED: 'clients/LOAD_CLIENTS_FAILED',

  CREATE_CLIENT: 'clients/CREATE_CLIENT',
  CREATE_CLIENT_SUCCESS: 'clients/CREATE_CLIENT_SUCCESS',
  CREATE_CLIENT_FAILED: 'clients/CREATE_CLIENT_FAILED',

  DELETE_CLIENT: 'clients/DELETE_CLIENT',
  DELETE_CLIENT_FAILED: 'clients/DELETE_CLIENT_FAILED',
  DELETE_CLIENT_SUCCESS: 'clients/DELETE_CLIENT_SUCCESS',

  RESTORE_CLIENT: 'clients/RESTORE_CLIENT',
  RESTORE_CLIENT_FAILED: 'clients/RESTORE_CLIENT_FAILED',
  RESTORE_CLIENT_SUCCESS: 'clients/RESTORE_CLIENT_SUCCESS',

  UPDATE_CLIENT: 'clients/UPDATE_CLIENT',
  UPDATE_CLIENT_SUCCESS: 'clients/UPDATE_CLIENT_SUCCESS',
  UPDATE_CLIENT_FAILED: 'clients/UPDATE_CLIENT_FAILED',

  GET_CLIENTS_FILTERS: 'clients/GET_CLIENTS_FILTERS',
  GET_CLIENTS_FILTERS_FAILED: 'clients/GET_CLIENTS_FILTERS_FAILED',
  GET_CLIENTS_FILTERS_SUCCESS: 'clients/GET_CLIENTS_FILTERS_SUCCESS',

  GET_CLIENT: 'clients/GET_CLIENT',
  GET_CLIENT_SUCCESS: 'clients/GET_CLIENT_SUCCESS',
  GET_CLIENT_FAILED: 'clients/GET_CLIENT_FAILED',

  GET_CLIENT_PROJECTS: 'clients/GET_CLIENT_PROJECTS',
  GET_CLIENT_PROJECTS_SUCCESS: 'clients/GET_CLIENT_PROJECTS_SUCCESS',
  GET_CLIENT_PROJECTS_FAILED: 'clients/GET_CLIENT_PROJECTS_FAILED',

  GET_CLIENT_ORGANIZATIONS: 'clients/GET_CLIENT_ORGANIZATIONS',
  GET_CLIENT_ORGANIZATIONS_FAILED: 'clients/GET_CLIENT_ORGANIZATIONS_FAILED',
  GET_CLIENT_ORGANIZATIONS_SUCCESS: 'clients/GET_CLIENT_ORGANIZATIONS_SUCCESS',

  ADD_CLIENT_ORGANIZATION: 'clients/ADD_CLIENT_ORGANIZATION',
  ADD_CLIENT_ORGANIZATION_SUCCESS: 'clients/ADD_CLIENT_ORGANIZATION_SUCCESS',
  ADD_CLIENT_ORGANIZATION_FAILED: 'clients/ADD_CLIENT_ORGANIZATION_FAILED',

  SAVE_CLIENT_NOTE: 'clients/SAVE_NOTE',
  SAVE_CLIENT_NOTE_FAILED: 'clients/SAVE_NOTE_FAILED',
  SAVE_CLIENT_NOTE_SUCCESS: 'clients/SAVE_NOTE_SUCCESS',

  UPDATE_CLIENT_NOTE: 'clients/UPDATE_NOTE',
  UPDATE_CLIENT_NOTE_FAILED: 'clients/UPDATE_NOTE_FAILED',
  UPDATE_CLIENT_NOTE_SUCCESS: 'clients/UPDATE_NOTE_SUCCESS',

  DELETE_CLIENT_NOTE: 'clients/DELETE_NOTE',
  DELETE_CLIENT_NOTE_SUCCESS: 'clients/DELETE_NOTE_SUCCESS',
  DELETE_CLIENT_NOTE_FAILED: 'clients/DELETE_NOTE_FAILED',

  GET_CLIENT_INVOICES: 'clients/GET_CLIENT_INVOICES',
  GET_CLIENT_INVOICES_SUCCESS: 'clients/GET_CLIENT_INVOICES_SUCCESS',
  GET_CLIENT_INVOICES_FAILED: 'clients/GET_CLIENT_INVOICES_FAILED',

  GET_CLIENT_INVOICE: 'clients/GET_CLIENT_INVOICE',
  GET_CLIENT_INVOICE_SUCCESS: 'clients/GET_CLIENT_INVOICE_SUCCESS',
  GET_CLIENT_INVOICE_FAILED: 'clients/GET_CLIENT_INVOICE_FAILED',

  ADD_CLIENT_INVOICE: 'clients/ADD_CLIENT_INVOICE',
  ADD_CLIENT_INVOICE_SUCCESS: 'clients/ADD_CLIENT_INVOICE_SUCCESS',
  ADD_CLIENT_INVOICE_FAILED: 'clients/ADD_CLIENT_INVOICE_FAILED',

  UPDATE_CLIENT_INVOICE: 'client/UPDATE_CLIENT_INVOICE',
  UPDATE_CLIENT_INVOICE_SUCCESS: 'client/UPDATE_CLIENT_INVOICE_SUCCESS',
  UPDATE_CLIENT_INVOICE_FAILED: 'client/UPDATE_CLIENT_INVOICE_FAILED',

  DELETE_CLIENT_INVOICE: 'client/DELETE_CLIENT_INVOICE',
  DELETE_CLIENT_INVOICE_SUCCESS: 'client/DELETE_CLIENT_INVOICE_SUCCESS',
  DELETE_CLIENT_INVOICE_FAILED: 'client/DELETE_CLIENT_INVOICE_FAILED',

  GET_CLIENT_CONTRACTS: 'clients/GET_CLIENTS_CONTRACTS',
  GET_CLIENT_CONTRACTS_SUCCESS: 'clients/GET_CLIENTS_CONTRACTS_SUCCESS',
  GET_CLIENT_CONTRACTS_FAILED: 'clients/GET_CLIENTS_CONTRACTS_FAILED',

  UPDATE_CLIENT_CONTRACT: 'clients/UPDATE_CLIENTS_CONTRACT',
  UPDATE_CLIENT_CONTRACT_SUCCESS: 'clients/UPDATE_CLIENTS_CONTRACT_SUCCESS',
  UPDATE_CLIENT_CONTRACT_FAILED: 'clients/UPDATE_CLIENTS_CONTRACT_FAILED',

  GET_CLIENT_AUDIT: 'clients/GET_CLIENT_AUDIT',
  GET_CLIENT_AUDIT_SUCCESS: 'clients/GET_CLIENT_AUDIT_SUCCESS',
  GET_CLIENT_AUDIT_FAILED: 'clients/GET_CLIENT_AUDIT_FAILED',

  GET_CLIENT_ALLOCATIONS: 'clients/GET_CLIENT_ALLOCATIONS',
  GET_CLIENT_ALLOCATIONS_FAILED: 'clients/GET_CLIENT_ALLOCATIONS_FAILED',
  GET_CLIENT_ALLOCATIONS_SUCCESS: 'clients/GET_CLIENT_ALLOCATIONS_SUCCESS',

  GET_CLIENT_NOTES: 'clients/GET_CLIENT_NOTES',
  GET_CLIENT_NOTES_SUCCESS: 'clients/GET_CLIENT_NOTES_SUCCESS',
  GET_CLIENT_NOTES_FAILED: 'clients/GET_CLIENT_NOTES_FAILED',

  GET_CLIENT_DEALS: 'clients/GET_CLIENT_DEALS',
  GET_CLIENT_DEALS_SUCCESS: 'clients/GET_CLIENT_DEALS_SUCCESS',
  GET_CLIENT_DEALS_FAILED: 'clients/GET_CLIENT_DEALS_FAILED',

  CREATE_CLIENT_DEAL: 'clients/CREATE_CLIENT_DEAL',
  CREATE_CLIENT_DEAL_SUCCESS: 'clients/CREATE_CLIENT_DEAL_SUCCESS',
  CREATE_CLIENT_DEAL_FAILED: 'clients/CREATE_CLIENT_DEAL_FAILED',

  UPDATE_CLIENT_DEAL: 'clients/UPDATE_CLIENT_DEAL',
  UPDATE_CLIENT_DEAL_SUCCESS: 'clients/UPDATE_CLIENT_DEAL_SUCCESS',
  UPDATE_CLIENT_DEAL_FAILED: 'clients/UPDATE_CLIENT_DEAL_FAILED',

  DELETE_CLIENT_DEAL: 'clients/DELETE_CLIENT_DEAL',
  DELETE_CLIENT_DEAL_SUCCESS: 'clients/DELETE_CLIENT_DEAL_SUCCESS',
  DELETE_CLIENT_DEAL_FAILED: 'clients/DELETE_CLIENT_DEAL_FAILED',

  CLEAR_RESPONSE: 'clients/CLEAR_RESPONSE',

  GET_ALLOCATIONS_STEP_DATA: 'clients/GET_ALLOCATIONS_STEP_DATA',
  GET_ALLOCATIONS_STEP_DATA_SUCCESS:
    'clients/GET_ALLOCATIONS_STEP_DATA_SUCCESS',
  GET_ALLOCATIONS_STEP_DATA_FAILED: 'clients/GET_ALLOCATIONS_STEP_DATA_FAILED',
};
