import { takeLatest, call, put, takeEvery } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { push } from 'connected-react-router/immutable';

import API from 'utils/api';
import { SAGA_MESSAGES } from 'redux/sagaMessages';
import { LOCAL_STORAGE_ITEMS } from 'utils/localStorage';
import { Y_M_D_H_M } from 'utils/timeConstants';

import { sagaErrorHandler } from 'utils/common';
import { types } from './types';

function* login({ payload: { username, password } }) {
  try {
    const {
      data: { access_token: accessToken },
    } = yield call(API.login, username, password);
    yield Cookies.set(LOCAL_STORAGE_ITEMS.accessToken, accessToken);
    const { data: user } = yield call(API.getProfile);
    yield put({
      type: types.LOGIN_SUCCESS,
      payload: user,
    });
    const timeDiff = dayjs(
      localStorage.getItem(LOCAL_STORAGE_ITEMS.lastPageTime),
    ).diff(dayjs(new Date()).format(Y_M_D_H_M), 'hours');
    const pushUrl =
      timeDiff < 11
        ? localStorage.getItem(LOCAL_STORAGE_ITEMS.lastPage)
        : '/resource-management';

    yield put(push(pushUrl));
  } catch (err) {
    yield call(
      sagaErrorHandler,
      err,
      types.LOGIN_FAILED,
      SAGA_MESSAGES.INVALID_USERNAME_PASSWORD,
    );
  }
}

function* getUser() {
  try {
    const { data } = yield call(API.getProfile);
    yield put({
      type: types.GET_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield call(sagaErrorHandler, error, types.GET_USER_FAILED);
  }
}

function* logout() {
  yield Cookies.remove(LOCAL_STORAGE_ITEMS.accessToken);
}

export default function* saga() {
  yield takeEvery(types.GET_USER, getUser);
  yield takeEvery(types.LOGIN, login);
  yield takeLatest(types.LOGOUT, logout);
}
