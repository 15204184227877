/* eslint-disable indent */
import React, { useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Button, Checkbox, Input } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import styles from './styles.scss';
import Table from '../Table';

const TableWithSettings = ({
  storageKey = '',
  isPageWithId = false,
  columns,
  ...restProps
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [editedColumns, setEditedColumns] = useState([]);
  const [tempColumnsSettings, setTempColumnsSettings] = useState([]);

  const { setColumnsSettings } = restProps;

  const onToggleVisible = useCallback(
    value => event => {
      event.stopPropagation();
      setIsVisible(value);
    },
    [],
  );

  const onSetColumnsSettings = settings => {
    if (setColumnsSettings && settings) {
      setColumnsSettings(settings);
    }
  };

  const onSaveSettings = () => {
    const showedColumns = tempColumnsSettings.filter(
      ({ isShowColumn }) => isShowColumn,
    );

    const settings = tempColumnsSettings.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.dataIndex]: {
          isShowColumn: curr.isShowColumn,
          width: curr.width,
        },
      }),
      {},
    );

    if (isPageWithId) {
      localStorage.setItem(`${storageKey}`, JSON.stringify(settings));
    } else {
      localStorage.setItem(
        `${window.location.pathname}-${storageKey}`,
        JSON.stringify(settings),
      );
    }

    setEditedColumns(showedColumns);
    setIsVisible(false);
    onSetColumnsSettings(tempColumnsSettings);
  };

  useEffect(() => {
    let settings;

    if (isPageWithId) {
      settings = JSON.parse(localStorage.getItem(`${storageKey}`));
    } else {
      settings = JSON.parse(
        localStorage.getItem(`${window.location.pathname}-${storageKey}`),
      );
    }

    const modifiedColumns = settings
      ? columns.map(column => ({
          ...column,
          isShowColumn: settings[column.dataIndex]?.isShowColumn,
          width: settings[column.dataIndex]?.width,
        }))
      : columns.map(column => ({
          ...column,
          isShowColumn: column.isShowColumn !== false,
        }));

    const showedColumns = modifiedColumns.filter(
      ({ isShowColumn, hidden }) => isShowColumn && !hidden,
    );

    const lastColumn = showedColumns.pop();

    if (lastColumn) {
      lastColumn.title = (
        <div className={styles.tableLastColumnTitle}>
          {lastColumn?.title}
          <SettingOutlined onClick={onToggleVisible(true)} />
        </div>
      );

      setEditedColumns([...showedColumns, lastColumn]);
      setTempColumnsSettings(modifiedColumns);
    }
  }, [columns]);

  const onChangeCheckbox = ({ column, event }) => {
    const checked = event.target.checked;

    const newTempColumnsSettings = tempColumnsSettings.map(item => {
      if (column.dataIndex === item.dataIndex) {
        return { ...item, isShowColumn: checked };
      }

      return item;
    });

    setTempColumnsSettings(newTempColumnsSettings);
  };

  const onChangeInput = ({ column, event }) => {
    const value = event.target.value;
    const newTempColumnsSettings = tempColumnsSettings.map(item => {
      if (column.dataIndex === item.dataIndex) {
        return { ...item, width: Number(value) };
      }

      return item;
    });

    setTempColumnsSettings(newTempColumnsSettings);
  };

  const onResetSettings = () => {
    const settings = columns.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.dataIndex]: {
          isShowColumn: true,
          width: Number(curr.width),
        },
      }),
      {},
    );

    if (isPageWithId) {
      localStorage.setItem(`${storageKey}`, JSON.stringify(settings));
    } else {
      localStorage.setItem(
        `${window.location.pathname}-${storageKey}`,
        JSON.stringify(settings),
      );
    }

    const modifiedColumns = columns.map(column => ({
      ...column,
      isShowColumn: true,
    }));

    const lastColumn = modifiedColumns.pop();

    lastColumn.title = (
      <div className={styles.tableLastColumnTitle}>
        {lastColumn.title}
        <SettingOutlined onClick={onToggleVisible(true)} />
      </div>
    );

    setEditedColumns([...modifiedColumns, lastColumn]);
    setTempColumnsSettings([...modifiedColumns, lastColumn]);
    setIsVisible(false);
    onSetColumnsSettings(modifiedColumns);
  };

  return (
    <>
      <Table {...restProps} columns={editedColumns} />
      <Drawer
        className={styles.tableDrawerWrapper}
        width={450}
        title={
          <div className={styles.tableLastColumnTitle}>
            Table settings
            <Button onClick={onResetSettings}>Reset settings</Button>
          </div>
        }
        style={{ visibility: isVisible ? 'visible' : 'hidden' }}
        placement="right"
        closable={false}
        onClose={onToggleVisible(false)}
        open={isVisible}
        getContainer={false}
        forceRender
      >
        {Array.isArray(tempColumnsSettings) &&
          tempColumnsSettings.slice(0, -1).map((column, index) => (
            <div
              className={styles.columnSettings}
              key={`${column.dataIndex || column.title}_${index}`}
            >
              <Checkbox
                checked={column.isShowColumn}
                onChange={event => onChangeCheckbox({ event, column })}
              >
                {storageKey === 'clientsOrganizations' &&
                column.key === 'action'
                  ? 'Actions'
                  : column.title}
              </Checkbox>
              <Input
                size="small"
                prefix="Width:"
                style={{ width: '125px' }}
                value={column.width && column.width.toString().replace('%', '')}
                suffix={
                  column.width && column.width.toString().includes('%')
                    ? '%'
                    : 'px'
                }
                onChange={event => onChangeInput({ event, column })}
              />
            </div>
          ))}
        <div className={styles.tableSettingsButton}>
          <Button onClick={onToggleVisible(false)}>Cancel</Button>
          <Button type="primary" onClick={onSaveSettings}>
            Save settings
          </Button>
        </div>
      </Drawer>
    </>
  );
};

TableWithSettings.propTypes = {
  columns: PropTypes.array,
  storageKey: PropTypes.string,
  isPageWithId: PropTypes.bool,
};

export default memo(TableWithSettings);
