import dayjs from 'dayjs';
import { MONTH_FORMAT_DAY_DIGIT } from 'containers/InvoicesPage/constants';
import { formatNumberWithCommas } from 'utils/helpers';
import {
  DASHBOARD_EMPLOYEE_LABELS,
  DASHBOARD_EMPLOYEE_PROJECTS_INDEX,
} from './constants';

export const getColumns = () => [
  {
    title: DASHBOARD_EMPLOYEE_PROJECTS_INDEX.project_title,
    dataIndex: DASHBOARD_EMPLOYEE_PROJECTS_INDEX.project_name,
  },
  {
    title: DASHBOARD_EMPLOYEE_PROJECTS_INDEX.rate_title,
    dataIndex: DASHBOARD_EMPLOYEE_PROJECTS_INDEX.rate,
    render: value => formatNumberWithCommas(value),
  },
  {
    title: DASHBOARD_EMPLOYEE_PROJECTS_INDEX.hours_title,
    dataIndex: DASHBOARD_EMPLOYEE_PROJECTS_INDEX.hours,
    render: value => formatNumberWithCommas(value),
  },
];

export const getDataSource = data =>
  data &&
  Object.keys(data).map(projectId => ({
    ...data[projectId],
    id: projectId,
  }));

const getArrayOfMonth = period => {
  const [firstMonth, lastMonth] = period;
  const monthsAmount = dayjs(lastMonth).diff(firstMonth, 'month') + 1;
  const monthsArray = [];

  for (let i = 0; i < monthsAmount; i++) {
    const month = dayjs(lastMonth)
      .subtract(i, 'month')
      .format(MONTH_FORMAT_DAY_DIGIT);
    monthsArray.push(month);
  }

  return monthsArray.reverse();
};

const getYearRange = period => [
  dayjs(period[0]).subtract(11, 'month'),
  period[1],
];

const getEmployeePeriod = period => {
  let employeePeriod = period;

  if (dayjs(period[0]).isSame(period[1], 'month')) {
    employeePeriod = getYearRange(period);
  }

  return getArrayOfMonth(employeePeriod);
};

export const getEmployeeComData = (monthData, period) => {
  const arrayOfMonth = getEmployeePeriod(period);

  return (
    monthData &&
    arrayOfMonth.reduce((acc, month) => {
      const monthItemData = Object.entries(monthData).find(
        item => item[0] === month,
      );

      const comObject = {
        type: DASHBOARD_EMPLOYEE_LABELS.com,
        value: monthItemData ? monthItemData[1].com_prc : 0,
        month,
      };

      const utilizationObj = {
        type: DASHBOARD_EMPLOYEE_LABELS.utilization,
        value: monthItemData ? monthItemData[1].utilization : 0,
        month,
      };

      return [...acc, comObject, utilizationObj];
    }, [])
  );
};

export const getEmployeeRevenueData = (monthData, period) => {
  const arrayOfMonth = getEmployeePeriod(period);

  return (
    monthData &&
    arrayOfMonth.map(month => {
      const monthItemData = Object.entries(monthData).find(
        item => item[0] === month,
      );

      return {
        month,
        revenue: monthItemData ? +monthItemData[1].revenue : 0,
        cost: monthItemData ? +monthItemData[1].cost : 0,
      };
    })
  );
};
