import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import {
  getExpandedColumns,
  transformObjectToArray,
  changeIdToFullName,
} from './utils';

const ExpandedTable = ({ data, users, employees }) => {
  const dataSource = useMemo(() => transformObjectToArray(data), [data]);
  const columns = useMemo(() => getExpandedColumns(), [dataSource]);

  return (
    <div>
      <Table
        pagination={false}
        dataSource={changeIdToFullName({ users, dataSource, employees })}
        columns={columns}
        showHeader={false}
        rowKey={record => record.key}
        style={{
          width: data.auditable_type === 'Invoice' ? 1400 : 825,
          boxShadow: '0 0 3px #ccc',
        }}
        size="small"
      />
    </div>
  );
};

ExpandedTable.propTypes = {
  employees: PropTypes.array,
  users: PropTypes.array,
  data: PropTypes.object,
};

export default ExpandedTable;
