/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FILTER_TYPES } from 'components/ClientCommon/ClientTimeline/constants';
import InfoItem from 'components/ClientCommon/ClientTimeline/TimelineItems/CommonItem/InfoItem';
import { FULL_DATE_FORMAT } from 'utils/timeConstants';

import { getFilteredClientAudit } from './utils';
import { EVENT_ACTIONS, ORGANIZATIONS_ACTIONS } from './constants';

import styles from './styles.scss';

const TimelineItem = ({
  children,
  event,
  className,
  users = {},
  jiraRoles,
  deals,
  allocations,
  organizationsData,
  jiraProjects,
  onEndDeal,
  onDeleteDeal,
  clientAuditWithGroupDealAllocations,
  clientAudit,
  groupedDealsByProject,
  isActive,
  onEditDeal,
  role,
}) => {
  const {
    created_at,
    action,
    auditable_type,
    user_id,
    audited_changes,
    auditable_id,
    username,
  } = event;
  const formattedTimeStanp = dayjs(created_at).format(FULL_DATE_FORMAT);

  const onShowModal = () => {
    onEditDeal({
      dealValues: deals[auditable_id],
      allocationsData: allocations[auditable_id],
    });
  };

  const filteredClientAudit =
    auditable_type === FILTER_TYPES.deal.type &&
    useMemo(
      () =>
        getFilteredClientAudit({
          clientAuditWithGroupDealAllocations,
          dealId: event.auditable_id,
        }),
      [clientAuditWithGroupDealAllocations],
    );

  return (
    <div className={classnames(styles.item, className)}>
      {auditable_type === FILTER_TYPES.deal.type ? (
        <div className={styles.dealInfoContainer}>
          <InfoItem
            type={auditable_type}
            action={action}
            deal={deals[event.auditable_id]}
            deals={deals}
            actions={EVENT_ACTIONS}
            allocations={allocations[event.auditable_id]}
            jiraRoles={jiraRoles}
            onShowModalDeal={onShowModal}
            jiraProjects={jiraProjects}
            endDeal={onEndDeal}
            deleteDeal={onDeleteDeal}
            filteredClientAudit={filteredClientAudit}
            groupedDealsByProject={groupedDealsByProject}
            allocationsGroupsById={allocations}
            clientAudit={clientAudit}
            isActive={isActive}
            role={role}
          />
        </div>
      ) : (
        <InfoItem
          type={auditable_type}
          action={action}
          actions={ORGANIZATIONS_ACTIONS}
        />
      )}
      {auditable_type === FILTER_TYPES.clientOrganization.type && (
        <div className={styles.organizationTitle}>
          {organizationsData[audited_changes.organization_id] &&
            organizationsData[audited_changes.organization_id][0].friendly_name}
        </div>
      )}
      {children}
      <div className={styles.source}>
        <span>{`Created by: ${username} ${formattedTimeStanp}`}</span>
        {auditable_type === FILTER_TYPES.deal.type &&
          !dayjs(get(deals[auditable_id], 'updated_at')).isSame(created_at) && (
            <span className={styles.dealUpdated}>
              {`Updated: ${deals[auditable_id] &&
                dayjs(deals[auditable_id].updated_at).format(
                  FULL_DATE_FORMAT,
                )}`}
            </span>
          )}
      </div>
    </div>
  );
};

TimelineItem.propTypes = {
  event: PropTypes.object,
  className: PropTypes.string,
  role: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default TimelineItem;
