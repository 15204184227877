import dayjs from 'dayjs';
import { MONTH_FORMAT_DAY_DIGIT } from 'containers/InvoicesPage/constants';
import {
  MONTH_NAME_FORMAT,
  MONTH_SHORTEN_NAME_FORMAT,
} from 'utils/timeConstants';
import { CURRENCY_CODES, MOBILE_WIDTH } from './constants';

export const checkIsMobile = () => window.innerWidth < MOBILE_WIDTH;

export const nFormatter = value =>
  new Intl.NumberFormat('en-GB', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value);

export const getPopupContainerHandler = event => event.parentElement || event;

export const formatNumberWithCommas = value =>
  Number(value)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getFirstDayOfMonth = date => dayjs(date).startOf('month');
export const getLastDayOfMonth = date => dayjs(date).endOf('month');

export const getMonthNameWithYear = date =>
  dayjs(date).format(MONTH_NAME_FORMAT);

export const getMonthNameWithYearShorten = date =>
  dayjs(date).format(MONTH_SHORTEN_NAME_FORMAT);

export const getMonthFormat = date =>
  dayjs(date).format(MONTH_FORMAT_DAY_DIGIT);

export const getArrayOfMonth = period => {
  const [firstMonth, lastMonth] = period;
  const monthsAmount = dayjs(lastMonth).diff(firstMonth, 'month') + 1;
  const monthsArray = [];

  for (let i = 0; i < monthsAmount; i++) {
    const month = dayjs(lastMonth)
      .subtract(i, 'month')
      .format(MONTH_FORMAT_DAY_DIGIT);
    monthsArray.push(month);
  }

  return monthsArray.reverse();
};

export const numberSorter = field => (a, b) => a[field] - b[field];

export const REDUX_DATA_HELPERS = {
  updateData: ({ data, editedItem, id }) =>
    data.map(item => (item.id === id ? editedItem : item)),

  removeItem: ({ data, id }) => data.filter(item => item.id !== id),
  removeCustomItem: ({ data, id, field }) =>
    data.filter(item => item[field] !== id),
};

export const getFiltersOrder = ({ order, filters }) => {
  const filterKeys = Object.keys(filters);

  const newOrder = order.filter(filter => filterKeys.includes(filter));

  filterKeys.forEach(filter => {
    if (!newOrder.includes(filter)) {
      newOrder.push(filter);
    }
  });

  return newOrder;
};

export const getObjectDeepCopy = obj => JSON.parse(JSON.stringify(obj));

export const getRowKey = record => record.id;

export const getMonthsRange = (date, monthsQauntity) => {
  const lastMonthDay = getMonthFormat(dayjs(date).subtract(1, 'month'));

  const firstMonth = getMonthFormat(
    dayjs(lastMonthDay).subtract(monthsQauntity, 'M'),
  );

  return [dayjs(firstMonth), dayjs(lastMonthDay)];
};

export const getFilteredValue = ({ field, filterInfo }) =>
  (filterInfo && filterInfo[field]) || null;

export const getSortOrder = ({ field, sortInfo, fieldType }) =>
  sortInfo[fieldType] === field && sortInfo.order;

export const groupBy = ({ data, by, field }) =>
  data?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr[by]]: curr[field],
    }),
    {},
  ) || {};

export const currencyFormatter = (value, currency) => {
  const formattedValue = formatNumberWithCommas(value);

  switch (currency) {
    case CURRENCY_CODES.BYR:
      return `${formattedValue} р.`;
    case CURRENCY_CODES.USD:
      return `$${formattedValue}`;
    default:
      return value;
  }
};
