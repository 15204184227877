import dayjs from 'dayjs';

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
    md: { span: 18 },
    lg: { span: 18 },
  },
};

export function mapPropsToFields(propsKey, form) {
  return ({ [propsKey]: data = {} }) => {
    const result = Object.keys(data || {}).reduce((values, key) => {
      const memo = values;
      const field = {};

      field.value = data[key];

      memo[key] = form.createFormField(field);

      return memo;
    }, {});

    return result;
  };
}

export const onValuesChange = ({ onChange }, field) => onChange(field);

export const dateFormat = 'YYYY-MM-DDTHH:mm:ss.ssssssZ';
export const formatDate = (date, format) =>
  date &&
  dayjs(date)
    .format(format || dateFormat)
    .replace(/(\+|-)\d\d:\d\d/, 'Z');

export const formTrimValidator = (_, value) =>
  value.trim()
    ? Promise.resolve()
    : Promise.reject(new Error('This field is required.'));
