import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html,
    body {
      height: 100%;
      width: 100%;
      background-color: #f8f8f8;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-info::before {
      top: 0;
      left: 0;
    }

    .ant-btn {
      height: initial;
      padding: 8px 16px;
    }

    .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
      background: #161616;
    }

    .ant-btn > .anticon {
      line-height: 0;
    }

    .anticon {
      vertical-align: middle;
    }

    .ant-table-thead > tr > th {
      font-size: 14px;
    }

    .ant-menu-dark .ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-active,
    .ant-menu-dark .ant-menu-submenu-active,
    .ant-menu-dark .ant-menu-submenu-open,
    .ant-menu-dark .ant-menu-submenu-selected,
    .ant-menu-dark .ant-menu-submenu-title:hover {
      background-color: #1890ff;
      color: #ffffff;
    }

    .ant-page-header-title-view {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
    .ant-page-header-title-view-title {
      font-size: 24px;
      flex: 0 0 auto;
    }
    .ant-page-header-title-view-extra {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: static;
    }

    .ant-menu-horizontal > .ant-menu-item::after,
    .ant-menu-horizontal > .ant-menu-submenu::after {
      border-bottom: none !important;
      transition: none !important;
    }

    .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-title-content {
      color: #1677ff;
    }
`;

export default GlobalStyle;
