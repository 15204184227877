export const types = {
  GET_PROJECT_PROFITS: 'project-profits/GET_PROJECT_PROFITS',
  GET_PROJECT_PROFITS_SUCCESS: 'project-profits/GET_PROJECT_PROFITS_SUCCESS',
  GET_PROJECT_PROFITS_FAILED: 'project-profits/GET_PROJECT_PROFITS_FAILED',

  DELETE_REVENUE_FROM_PROJECT_PROFIT:
    'project-profits/DELETE_REVENUE_FROM_PROJECT_PROFIT',
  DELETE_REVENUE_FROM_PROJECT_PROFIT_SUCCESS:
    'project-profits/DELETE_REVENUE_FROM_PROJECT_PROFIT_SUCCESS',
  DELETE_REVENUE_FROM_PROJECT_PROFIT_FAILED:
    'project-profits/DELETE_REVENUE_FROM_PROJECT_PROFIT_FAILED',

  CALCULATE_PROJECT_PROFITS: 'project-profits/CALCULATE_PROJECT_PROFITS',
  CALCULATE_PROJECT_PROFITS_SUCCESS:
    'project-profits/CALCULATE_PROJECT_PROFITS_SUCCESS',
  CALCULATE_PROJECT_PROFITS_FAILED:
    'project-profits/CALCULATE_PROJECT_PROFITS_FAILED',

  UPDATE_PROJECT_PROFITS: 'project-profits/UPDATE_PROJECT_PROFITS',
  UPDATE_PROJECT_PROFITS_SUCCESS:
    'project-profits/UPDATE_PROJECT_PROFITS_SUCCESS',
  UPDATE_PROJECT_PROFITS_FAILED:
    'project-profits/UPDATE_PROJECT_PROFITS_FAILED',

  GET_PROJECT_PROFITS_BY_YEAR: 'project-profits/GET_PROJECT_PROFITS_BY_YEAR',
  GET_PROJECT_PROFITS_BY_YEAR_SUCCESS:
    'project-profits/GET_PROJECT_PROFITS_BY_YEAR_SUCCESS',
  GET_PROJECT_PROFITS_BY_YEAR_FAILED:
    'project-profits/GET_PROJECT_PROFITS_BY_YEAR_FAILED',

  ADD_PROJECT_EXTRA_PAYMENT: 'project-profits/ADD_PROJECT_EXTRA_PAYMENT',
  ADD_PROJECT_EXTRA_PAYMENT_SUCCESS:
    'project-profits/ADD_PROJECT_EXTRA_PAYMENT_SUCCESS',
  ADD_PROJECT_EXTRA_PAYMENT_FAILED:
    'project-profits/ADD_PROJECT_EXTRA_PAYMENT_FAILED',

  GET_MANAGER_PROFILES: 'project-profits/GET_MANAGER_PROFILES',
  GET_MANAGER_PROFILES_SUCCESS: 'project-profits/GET_MANAGER_PROFILES_SUCCESS',
  GET_MANAGER_PROFILES_FAILED: 'project-profits/GET_MANAGER_PROFILES_FAILED',
};
