/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import history from 'utils/history';
import languageProviderReducer from 'containers/LanguageProvider/reducer';

import auditsReducer from 'redux/Audits/reducer';
import authReducer from 'redux/Auth/reducer';
import averageSalariesReducer from 'redux/AverageSalaries/reducer';
import banksReducer from 'redux/Banks/reducer';
import calendarReducer from 'redux/Calendar/reducer';
import clientsReducer from 'redux/Clients/reducer';
import contractsReducer from 'redux/Contracts/reducer';
import costRatesReducer from 'redux/CostRates/reducer';
import currenciesReducer from 'redux/Currencies/reducer';
import dashboardReducer from 'redux/Dashboard/reducer';
import dealsReducer from 'redux/Deals/reducer';
import employeePositionsReducer from 'redux/EmployeePositions/reducer';
import employeesReducer from 'redux/Employees/reducer';
import exchangeRatesReducer from 'redux/ExchangeRates/reducer';
import extraPaymentsReducer from 'redux/ExtraPayments/reducer';
import incomingInvoicesReducer from 'redux/IncomingInvoices/reducer';
import invoicesReducer from 'redux/Invoices/reducer';
import invoiceTypesReducer from 'redux/InvoiceTypes/reducer';
import jiraReducer from 'redux/Jira/reducer';
import leadsReducer from 'redux/Leads/reducer';
import notificationsReducer from 'redux/Notifications/reducer';
import organizationsReducer from 'redux/Organizations/reducer';
import paysheetsReducer from 'redux/Paysheets/reducer';
import projectProfitsReducer from 'redux/ProjectProfits/reducer';
import resourceManagementReducer from 'redux/ResourceManagement/reducer';
import teamsReducer from 'redux/Teams/reducer';
import technologiesReducer from 'redux/Technologies/reducer';
import usersReducer from 'redux/Users/reducer';
import summaryEmployeesReducer from 'redux/SummaryEmployees/reducer';
import rolesReducer from 'redux/Roles/reducer';
import socialRequestsReducer from 'redux/SocialRequests/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    language: languageProviderReducer,
    router: connectRouter(history),
    audits: auditsReducer,
    auth: authReducer,
    averageSalaries: averageSalariesReducer,
    banks: banksReducer,
    calendar: calendarReducer,
    clients: clientsReducer,
    contracts: contractsReducer,
    costRates: costRatesReducer,
    currencies: currenciesReducer,
    dashboard: dashboardReducer,
    deals: dealsReducer,
    employeePositions: employeePositionsReducer,
    employees: employeesReducer,
    exchangeRates: exchangeRatesReducer,
    extraPayments: extraPaymentsReducer,
    incomingInvoices: incomingInvoicesReducer,
    invoices: invoicesReducer,
    invoiceTypes: invoiceTypesReducer,
    jira: jiraReducer,
    leads: leadsReducer,
    notifications: notificationsReducer,
    organizations: organizationsReducer,
    paysheets: paysheetsReducer,
    projectProfits: projectProfitsReducer,
    resourceManagement: resourceManagementReducer,
    teams: teamsReducer,
    technologies: technologiesReducer,
    users: usersReducer,
    summaryEmployees: summaryEmployeesReducer,
    roles: rolesReducer,
    socialRequests: socialRequestsReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
