import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { HOLIDAY_COMMON } from 'containers/CalendarPage/constants';
import Modal from 'components/Common/Modal';

import HolidayForm from '../HolidayForm';

const HolidayModal = ({
  holidayDate,
  holidayData,
  form,
  onSubmit,
  onCancel,
  isHolidayRepeatModal,
}) => {
  const [isAllDay, setIsAllDay] = useState(true);
  const [isRepeat, setIsRepeat] = useState(false);

  useEffect(() => {
    if (holidayData) {
      setIsAllDay(holidayData[HOLIDAY_COMMON.is_all_day]);
      setIsRepeat(!!holidayData[HOLIDAY_COMMON.repeat_type]);
    }
  }, [holidayData]);

  const onCancelHandler = () => {
    setIsAllDay(true);
    setIsRepeat(false);
    onCancel();
  };

  const onValuesChange = updatedValues => {
    if (HOLIDAY_COMMON.is_all_day in updatedValues) {
      setIsAllDay(updatedValues[HOLIDAY_COMMON.is_all_day]);
    }

    if (HOLIDAY_COMMON.repeat_type in updatedValues) {
      setIsRepeat(!!updatedValues[HOLIDAY_COMMON.repeat_type]);
    }
  };

  const title = holidayData ? 'Edit holiday' : 'Add a holiday';

  return (
    <Modal
      onOk={form.submit}
      onCancel={onCancelHandler}
      open={!!holidayDate && !isHolidayRepeatModal}
      title={title}
      okText="Save"
    >
      <HolidayForm
        form={form}
        isAllDay={isAllDay}
        isRepeat={isRepeat}
        onValuesChange={onValuesChange}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

HolidayModal.propTypes = {
  holidayDate: PropTypes.object,
  holidayData: PropTypes.object,
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isHolidayRepeatModal: PropTypes.bool.isRequired,
};

export default HolidayModal;
