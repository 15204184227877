import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isIncomingInvoicesLoading: false,
  incomingInvoices: [],
  incomingInvoicesFilters: {},
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_INCOMING_INVOICES:
      return state.set('isIncomingInvoicesLoading', true);
    case types.GET_INCOMING_INVOICES_SUCCESS:
      return state
        .set('isIncomingInvoicesLoading', false)
        .set('incomingInvoices', payload.data)
        .set('incomingInvoicesFilters', payload.data_for_filters);

    case types.CREATE_INCOMING_INVOICE_SUCCESS:
    case types.UPDATE_INCOMING_INVOICE_SUCCESS:
    case types.DELETE_INCOMING_INVOICE_SUCCESS:
    case types.APPROVE_INCOMING_INVOICE_APPROVER_SUCCESS:
    case types.APPROVE_INCOMING_INVOICE_FINANCIER_SUCCESS:
    case types.DECLINE_INCOMING_INVOICE_APPROVER_SUCCESS:
    case types.DECLINE_INCOMING_INVOICE_FINANCIER_SUCCESS:
      return state.set('incomingInvoices', payload);

    case types.GET_INCOMING_INVOICES_FAILED:
      return state.set('isIncomingInvoicesLoading', false);
    default:
      return state;
  }
}
