import { fromJS } from 'immutable';
import { types, changeType } from './types';

const initialState = fromJS({
  isNotificationsLoading: false,
  notifications: [],
  error: null,
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.CLEAR_NOTIFICATIONS:
      return state.set('notifications', []);
    case types.SYNC_NOTIFICATIONS:
    case types.REFRESH_SYNC_NOTIFICATIONS:
      return state.set('isNotificationsLoading', true).set('error', null);
    case types.SYNC_NOTIFICATIONS_FAILED:
    case types.REFRESH_NOTIFICATIONS_FAILED:
    case types.REFRESH_SYNC_NOTIFICATIONS_SUCCESS:
      return state.set('isNotificationsLoading', false).set('error', payload);
    case types.SYNC_NOTIFICATIONS_SUCCESS: {
      let changedData = Array.isArray(state.get('notifications'))
        ? state.get('notifications')
        : state.get('notifications').toArray();
      payload.data.docChanges().forEach(change => {
        const notification = {
          docId: change.doc.id,
          ...change.doc.data(),
        };

        if (
          notification.user_id === 0 ||
          notification.user_id === payload.userId
        ) {
          switch (change.type) {
            case changeType.ADDED:
              changedData = [...changedData, notification];

              break;
            case changeType.MODIFIED:
              changedData = changedData.map(item =>
                item.docId === change.doc.id ? notification : item,
              );

              break;
            case changeType.REMOVED:
              changedData = changedData.filter(
                item => item.docId !== change.doc.id,
              );

              break;
          }
        }
      });

      return state
        .set('isNotificationsLoading', false)
        .set('notifications', changedData);
    }
    default:
      return state;
  }
}
