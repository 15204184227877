/* eslint-disable react/prop-types */
/* eslint-disable indent */
import React from 'react';
import { Form, Button, Popconfirm, Typography } from 'antd';
import {
  CheckOutlined,
  DeleteOutlined,
  CloseOutlined,
  EditOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { get } from 'lodash';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'utils/timeConstants';
import DatePicker from 'components/Common/DatePicker';
import { CELL_COLORS } from 'components/ClientCommon/ClientTimeline/TimelineItems/CommonItem/constants';
import Select, { Option } from 'components/Common/Select';

import Input from 'components/Common/Input';
import TableButton from 'components/Common/TableButton';
import InputNumber from 'components/Common/InputNumber';
import { formatNumberWithCommas } from 'utils/helpers';
import {
  GROUP_TITLE,
  NEW_ALLOCATION,
  CUSTOM,
  TITLE,
  EXPECTED_QUANTITY,
  ORIGINAL_QUANTITY,
  UNIT_PRICE,
  DISCOUNT,
  TAX_RATE,
  UNIT,
  QUANTITY,
  PERIOD_FROM,
  PERIOD_TO,
  CURRENCY_CODE,
} from './constants';
import styles from './styles.scss';

const { Text } = Typography;

const discountFormatter = value => `${value}%`;

const discountParser = value => value?.replace('%', '');

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  currencies,
  onChangePeriods,
  editingKey,
  uniqGroupTitle,
  form,
  setGroupTitleSelectValue,
  groupTitleSelectValue,
  isAddInvoiceItem,
  ...restProps
}) => {
  const checkQuantity = (_, value) => {
    if (value !== '') {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Field can't be empty!"));
  };

  const checkStringFields = (_, value) => {
    if (`${value}`.trim() !== '') {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Field can't be empty!"));
  };

  const checkFields = (_, value) => {
    if (value >= 0 && value !== '') {
      return Promise.resolve();
    }

    return Promise.reject(new Error('Value must be greater than zero!'));
  };

  const checkUnit = (_, value) => {
    if (value !== '') {
      return Promise.resolve();
    }

    return Promise.reject(new Error('Value must be greater than zero!'));
  };

  if (inputType === GROUP_TITLE && editingKey === NEW_ALLOCATION) {
    const onChangeSelect = value => {
      setGroupTitleSelectValue(value);
    };

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                message: 'This field is  required.',
                required: true,
                validator: checkStringFields,
              },
            ]}
          >
            {groupTitleSelectValue === CUSTOM ? (
              <Input size="small" />
            ) : (
              <Select onChange={onChangeSelect} size="small">
                {uniqGroupTitle.map(item => (
                  <Option value={item.group_title}>{item.group_title}</Option>
                ))}
                <Option value={CUSTOM}>Custom</Option>
              </Select>
            )}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  if (inputType === GROUP_TITLE || inputType === TITLE) {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                message: 'This field is  required.',
                required: true,
                validator: checkStringFields,
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  if (inputType === EXPECTED_QUANTITY || inputType === ORIGINAL_QUANTITY) {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                pattern: /^(-)?(\d)*(\.)?([0-9]{1,2})?$/,
                message: 'No more than two decimal places',
              },
            ]}
          >
            <InputNumber size="small" min={0} />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  if (inputType === UNIT_PRICE || inputType === TAX_RATE) {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            initialValue={record[dataIndex]}
            name={dataIndex}
            rules={[
              {
                message: 'This field is  required.',
                required: true,
                validator: checkFields,
              },
              {
                pattern: /^(-)?(\d)*(\.)?([0-9]{1,2})?$/,
                message: 'No more than two decimal places',
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  if (inputType === DISCOUNT) {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            initialValue={record[dataIndex]}
            name={dataIndex}
            rules={[
              {
                message: 'This field is  required.',
                required: true,
                validator: checkFields,
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              min={0}
              max={100}
              formatter={discountFormatter}
              parser={discountParser}
            />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  if (inputType === UNIT) {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            initialValue={record[dataIndex]}
            name={dataIndex}
            rules={[
              {
                message: 'This field is  required.',
                required: true,
                validator: checkUnit,
              },
            ]}
          >
            <Input size="small" placeholder="h, pc, etc." />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  if (inputType === QUANTITY) {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                message: 'This field is  required.',
                required: true,
                validator: checkQuantity,
              },
              {
                pattern: /^(-)?(\d)*(\.)?([0-9]{1,2})?$/,
                message: 'No more than two decimal places',
              },
            ]}
          >
            <InputNumber
              size="small"
              min={isAddInvoiceItem ? Number.MIN_SAFE_INTEGER : 1}
            />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  if (inputType === PERIOD_FROM || inputType === PERIOD_TO) {
    return (
      <td {...restProps}>
        {editing ? (
          <DatePicker
            size="small"
            defaultValue={dayjs(record[dataIndex])}
            onChange={value => onChangePeriods({ value, dataIndex })}
          />
        ) : (
          children
        )}
      </td>
    );
  }

  if (inputType === CURRENCY_CODE) {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
          >
            <Select showSearch size="small">
              {currencies.map(item => (
                <Option key={item.id} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  return <td {...restProps}>{children}</td>;
};

const stringSorter = field => (a, b) =>
  typeof a[field] === 'string' && typeof b[field] === 'string'
    ? a[field].localeCompare(b[field])
    : a[field];

const numberSorter = field => (a, b) => a[field] - b[field];

export const getColumns = ({
  isEditing,
  onSave,
  onCancelEditOrCreate,
  onEditRow,
  editingKey,
  onDeleteInvoice,
  setIsAddInvoiceItem,
  originalItemsGroupById,
  onRestoreInvoice,
}) => [
  {
    title: 'Group title',
    dataIndex: 'group_title',
    key: 'group_title',
    width: 75,
    editable: true,
    sorter: stringSorter('group_title'),
    render: (value, record) => {
      if (get(record, 'isAddButton')) {
        return (
          <Button
            type="dashed"
            onClick={() => setIsAddInvoiceItem(true)}
            size="small"
          >
            Add row
          </Button>
        );
      }

      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
          },
        },
        children: <span>{value}</span>,
      };
    },
  },
  {
    title: 'Item',
    dataIndex: 'title',
    key: 'title',
    width: 100,
    editable: true,
    sorter: stringSorter('title'),
    render: (value, record) => {
      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
          },
        },
        children: <span>{value}</span>,
      };
    },
  },
  {
    title: 'Period from',
    dataIndex: 'period_from',
    key: 'period_from',
    align: 'center',
    width: 70,
    editable: true,
    sorter: numberSorter('rate'),
    render: (value, record) => {
      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
          },
        },
        children: <span>{value ? dayjs(value).format(DATE_FORMAT) : ''}</span>,
      };
    },
  },
  {
    title: 'Period to',
    dataIndex: 'period_to',
    key: 'period_to',
    align: 'center',
    width: 70,
    editable: true,
    sorter: numberSorter('rate'),
    render: (value, record) => {
      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
          },
        },
        children: <span>{value ? dayjs(value).format('YYYY-MM-DD') : ''}</span>,
      };
    },
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 70,
    editable: true,
    render: (value, record) => {
      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      const color = record.expected_quantity > value ? '#a8071a' : '#0050b3';

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
          },
        },
        children: (
          <Text
            strong
            style={{
              color:
                Number(record.expected_quantity) !== Number(value) && color,
            }}
          >
            <span>{value ? formatNumberWithCommas(value) : value}</span>
          </Text>
        ),
      };
    },
  },
  {
    title: 'Expected quantity',
    dataIndex: 'expected_quantity',
    key: 'expected_quantity',
    width: 70,
    editable: true,
    render: (value, record) => {
      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
            color: '#8c8c8c',
          },
        },
        children: <span>{value ? formatNumberWithCommas(value) : value}</span>,
      };
    },
  },
  {
    title: 'Original quantity',
    dataIndex: 'original_quantity',
    key: 'original_quantity',
    width: 70,
    editable: true,
    render: (value, record) => {
      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
            color: '#8c8c8c',
          },
        },
        children: <span>{value ? formatNumberWithCommas(value) : value}</span>,
      };
    },
  },
  {
    title: 'Unit price',
    dataIndex: 'unit_price',
    key: 'unit_price',
    width: 50,
    editable: true,
    render: (value, record) => {
      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
          },
        },
        children: <span>{value}</span>,
      };
    },
  },
  {
    title: 'Currency code',
    dataIndex: 'currency_code',
    key: 'currency_code',
    align: 'center',
    width: 75,
    editable: true,
    sorter: numberSorter('rate'),
    render: (value, record) => {
      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
          },
        },
        children: <span>{value}</span>,
      };
    },
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: 40,
    editable: true,
    render: (value, record) => {
      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
          },
        },
        children: <span>{value}</span>,
      };
    },
  },
  {
    title: 'Discount, %',
    dataIndex: 'discount',
    key: 'discount',
    width: 55,
    editable: true,
    sorter: stringSorter('role'),
    render: (value, record) => {
      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
          },
        },
        children: <span>{value ? `${value}%` : value}</span>,
      };
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: 55,
    align: 'center',
    sorter: numberSorter('amount'),
    render: (value, record) => {
      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
          },
        },
        children: <span>{value ? Number(value).toFixed(2) : value}</span>,
      };
    },
  },
  {
    title: 'Tax rate',
    dataIndex: 'tax_rate',
    key: 'tax_rate',
    align: 'center',
    width: 75,
    editable: true,
    sorter: numberSorter('rate'),
    render: (value, record) => {
      const isNewField =
        !originalItemsGroupById[record.id] && !record.isAddButton;

      return {
        props: {
          style: {
            background:
              (record.deleted && CELL_COLORS.red) ||
              (record.edited && CELL_COLORS.yellow) ||
              (isNewField && CELL_COLORS.green),
          },
        },
        children: <span>{formatNumberWithCommas(value)}</span>,
      };
    },
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: 65,
    align: 'center',
    fixed: 'right',
    render: (_, record) => {
      if (get(record, 'isAddButton')) return null;

      const editable = isEditing(record);

      return editable ? (
        <div>
          <TableButton
            ghost
            type="primary"
            style={{
              marginRight: 5,
              color: '#08c',
            }}
            icon={<CheckOutlined />}
            onClick={() => onSave(record)}
            size="small"
          />
          <Popconfirm title="Sure to cancel?" onConfirm={onCancelEditOrCreate}>
            <TableButton
              ghost
              style={{ color: '#f00' }}
              danger
              icon={<CloseOutlined />}
              size="small"
            />
          </Popconfirm>
        </div>
      ) : (
        <>
          <TableButton
            icon={<EditOutlined />}
            onClick={() => onEditRow(record)}
            type="primary"
            ghost
            disabled={editingKey !== '' || record.deleted}
            size="small"
          />
          {record.deleted ? (
            <Popconfirm
              placement="topRight"
              title="Вы действительно хотите восстановить запись?"
              onConfirm={() => onRestoreInvoice(record)}
            >
              <TableButton
                className={styles.button}
                icon={<UndoOutlined />}
                style={{ color: '#237804', borderColor: '#237804' }}
                size="small"
              />
            </Popconfirm>
          ) : (
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to remove this role?"
              onConfirm={() => onDeleteInvoice(record)}
            >
              <TableButton
                className={styles.button}
                icon={<DeleteOutlined />}
                danger
                ghost
                size="small"
              />
            </Popconfirm>
          )}
        </>
      );
    },
  },
];

export const components = {
  body: {
    cell: EditableCell,
  },
};

export const getStartAndEndPeriods = data =>
  data
    ? data.reduce(
        (acc, curr) => {
          if (!acc.period_from || !acc.period_to) {
            acc.period_from = curr.period_from;
            acc.period_to = curr.period_to;
          }

          return {
            period_from: dayjs(curr.period_from).isBefore(acc.period_from)
              ? curr.period_from
              : acc.period_from,
            period_to: dayjs(curr.period_to).isAfter(acc.period_to)
              ? curr.period_to
              : acc.period_to,
          };
        },
        { period_from: null, period_to: null },
      )
    : { period_from: null, period_to: null };

export const getDeletedInvoiceItems = ({ itemsGroupById, originalItems }) =>
  originalItems?.reduce((acc, curr) => {
    if (!itemsGroupById[curr.id]) {
      return [...acc, { ...curr, deleted: true }];
    }

    return acc;
  }, []);
