import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Upload, Dropdown } from 'antd';
import { isEmpty } from 'lodash';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  DownOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';

import {
  CLIENT_PERMISSIONS,
  INVOICES_MODAL_TAB,
} from 'containers/ClientPage/constants';
import Tag from 'components/Common/Tag';
import { FULL_DATE_FORMAT } from 'utils/timeConstants';
import { hasRights } from 'utils/permissions';
import { formatNumberWithCommas } from 'utils/helpers';

import {
  GREEN_STATUSES,
  RED_STATUSES,
  GRAY_STATUSES,
  YELLOW_STATUSES,
} from './constants';
import { getFileList } from './utils';
import styles from './styles.scss';

const { confirm } = Modal;

const InvoiceItem = ({
  event,
  invoicesGroupById,
  deleteInvoice,
  onVisibleInvoiceModal,
  onEditInvoice,
  role,
}) => {
  const { auditable_id } = event;

  const invoiceData = invoicesGroupById[auditable_id];

  const {
    amount,
    status,
    number,
    currency_code,
    attachments,
    created_at,
    owner_name,
    updated_at,
  } = invoiceData;

  const onDeleteInvoice = () => {
    confirm({
      title: 'Delete invoice',
      content: 'Are you sure you want to delete this invoice?',
      onOk() {
        deleteInvoice(auditable_id);
      },
    });
  };

  const formattedTimeStanp = dayjs(created_at).format(FULL_DATE_FORMAT);
  const fileList = getFileList(attachments);

  const dropdownItem = [
    {
      label: 'Edit invoice',
      key: 'Edit',
      onClick: () => onEditInvoice(auditable_id),
      icon: <EditOutlined />,
    },
    {
      label: 'Edit invoice info',
      key: 'End',
      onClick: () => onVisibleInvoiceModal(invoiceData),
      icon: <EllipsisOutlined />,
    },
    {
      label: 'Remove',
      key: 'Remove',
      disabled: !hasRights(role, CLIENT_PERMISSIONS.deleteInvoice),
      onClick: () => onDeleteInvoice(),
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <div className={styles.invoiceTimelineContainer}>
      <div className={styles.invoiceInfo}>
        <div className={styles.infoLeft}>
          <span className={styles.number}>#{number}</span>
          <Tag
            color={
              (RED_STATUSES.includes(status) && 'red') ||
              (GRAY_STATUSES.includes(status) && 'default') ||
              (GREEN_STATUSES.includes(status) && 'green') ||
              (YELLOW_STATUSES.includes(status) && 'gold')
            }
          >
            {status.replace(/_/g, ' ')}
          </Tag>
          <DownOutlined
            className={styles.statusEdit}
            onClick={() =>
              onVisibleInvoiceModal(invoiceData, INVOICES_MODAL_TAB.workflow)
            }
          />
        </div>
        <div className={styles.infoRight}>
          <Dropdown menu={{ items: dropdownItem }}>
            <EllipsisOutlined className={styles.invoiceMenu} />
          </Dropdown>
        </div>
      </div>
      <div className={styles.content}>
        <span className={styles.amount}>
          {currency_code} {formatNumberWithCommas(amount)}
        </span>
        <div className={styles.source}>
          <span
            className={styles.created}
          >{`Created by: ${owner_name} ${formattedTimeStanp}`}</span>
          {!dayjs(updated_at).isSame(created_at) && (
            <span className={styles.invoiceUpdateTime}>
              Updated: {dayjs(updated_at).format(FULL_DATE_FORMAT)}
            </span>
          )}
        </div>
        {attachments && !isEmpty(attachments) && (
          <div className={styles.attachments}>
            <Upload
              fileList={fileList}
              showUploadList={{
                showDownloadIcon: false,
                showRemoveIcon: false,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

InvoiceItem.propTypes = {
  event: PropTypes.object.isRequired,
  invoicesGroupById: PropTypes.object.isRequired,
  deleteInvoice: PropTypes.func.isRequired,
  onVisibleInvoiceModal: PropTypes.func.isRequired,
  onEditInvoice: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
};

export default InvoiceItem;
