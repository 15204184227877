import React from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import classNames from 'classnames';

import styles from './styles.scss';

const { Item: AntdItem } = Timeline;

const TimelineItem = ({ children, ...props }) => (
  <AntdItem
    {...props}
    className={classNames([props.className, styles.timelineItem])}
  >
    {children}
  </AntdItem>
);

TimelineItem.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
};

export default TimelineItem;
