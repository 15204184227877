import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { Line } from '@ant-design/charts';
import { getOverallData, getOverallConfig } from './utils';

import styles from './styles.scss';

const { Title } = Typography;

const DashboardSubcontractorsOverall = ({ monthData, period }) => {
  const overallData = useMemo(() => getOverallData(monthData, period), [
    monthData,
    period,
  ]);

  const overallconfig = useMemo(() => getOverallConfig(overallData), [
    overallData,
  ]);

  return (
    <div className={styles.dashboardSubcontractorsOverall}>
      <Title level={5}>Overall</Title>
      <Line {...overallconfig} />
    </div>
  );
};

DashboardSubcontractorsOverall.propTypes = {
  monthData: PropTypes.object.isRequired,
  period: PropTypes.array.isRequired,
};

export default DashboardSubcontractorsOverall;
