exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".detailsContainer-2e608{padding:24px;border-radius:12px;background-color:#fff;box-shadow:0 4px 20px 0 rgba(112,112,128,.1);overflow:hidden;margin-bottom:15px}.detailsContainer-2e608 .extra-f11bd{display:none}.detailsContainer-2e608:hover .extra-f11bd{display:block}.detailsHeader-537b4{display:flex;flex-flow:row nowrap;align-items:center;justify-content:space-between;margin-bottom:12px}.detailsTitle-f4a2e{font-weight:600;font-size:20px;line-height:28px}.detailsContent-92d4d{box-sizing:border-box;overflow:hidden auto}", ""]);

// Exports
exports.locals = {
	"detailsContainer": "detailsContainer-2e608",
	"extra": "extra-f11bd",
	"detailsHeader": "detailsHeader-537b4",
	"detailsTitle": "detailsTitle-f4a2e",
	"detailsContent": "detailsContent-92d4d"
};