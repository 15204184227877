import { fromJS } from 'immutable';
import { types } from './types';

const initialState = fromJS({
  isDashboardLoading: false,
  dashboardEffectiveness: {},

  dashboardEmployees: [],
  averageAer: 0,
  totalDelivery: 0,
  totalNotDelivery: 0,
  employeeData: {},
  employeesFilters: {},
  isEmployeeLoading: false,

  dashboardOverview: {},

  dashboardSalaryChart: {},
  dashboardSalaryTable: [],
  dashboardSalaryTableFilters: {},
  dashboardSalaryTableTotal: 0,
  dashboardSalaryTableSummary: {},

  dashboardSubcontractorsOverall: {},
  dashboardSubcontractorsTable: [],
  dashboardSubcontractorsFilters: {},
});

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_DASHBOARD_EFFECTIVENESS:
    case types.GET_DASHBOARD_EMPLOYEES:
    case types.GET_DASHBOARD_OVERVIEW:
    case types.GET_DASHBOARD_SALARY_CHART:
    case types.GET_DASHBOARD_SALARY_TABLE:
    case types.GET_DASHBOARD_SUBCONTRACTORS_TABLE:
    case types.GET_DASHBOARD_SUBCONTRACTORS:
      return state.set('isDashboardLoading', true);
    case types.GET_DASHBOARD_EMPLOYEE_DATA:
      return state.set('isEmployeeLoading', true).set('employeeData', {});

    case types.GET_DASHBOARD_EFFECTIVENESS_SUCCESS:
      return state
        .set('isDashboardLoading', false)
        .set('dashboardEffectiveness', payload);
    case types.GET_DASHBOARD_EMPLOYEES_SUCCESS:
      return state
        .set('isDashboardLoading', false)
        .set('dashboardEmployees', payload.data)
        .set('averageAer', payload.average_aer)
        .set('totalDelivery', payload.total_delivery)
        .set('totalNotDelivery', payload.total_not_delivery)
        .set('employeesFilters', payload.data_for_filters);
    case types.GET_DASHBOARD_EMPLOYEE_DATA_SUCCESS:
      return state.set('employeeData', payload).set('isEmployeeLoading', false);
    case types.GET_DASHBOARD_OVERVIEW_SUCCESS:
      return state
        .set('isDashboardLoading', false)
        .set('dashboardOverview', payload);
    /////
    case types.GET_DASHBOARD_SALARY_CHART_SUCCESS:
      return state
        .set('isDashboardLoading', false)
        .set('dashboardSalaryChart', payload);
    case types.GET_DASHBOARD_SALARY_TABLE_SUCCESS:
      return state
        .set('isDashboardLoading', false)
        .set('dashboardSalaryTable', payload.data)
        .set('dashboardSalaryTableFilters', payload.data_for_filters)
        .set('dashboardSalaryTableSummary', payload.summary)
        .set('dashboardSalaryTableTotal', payload.total_data);
    ///
    case types.GET_DASHBOARD_SUBCONTRACTORS_SUCCESS:
      return state
        .set('dashboardSubcontractorsOverall', payload.overallData.data)
        .set('dashboardSubcontractorsTable', payload.tableData.data)
        .set(
          'dashboardSubcontractorsFilters',
          payload.tableData.data_for_filters,
        )
        .set('isDashboardLoading', false);

    case types.GET_DASHBOARD_SUBCONTRACTORS_TABLE_SUCCESS:
      return state
        .set('dashboardSubcontractorsTable', payload.data)
        .set('dashboardSubcontractorsFilters', payload.data_for_filters)
        .set('isDashboardLoading', false);

    case types.GET_DASHBOARD_EFFECTIVENESS_FAILED:
    case types.GET_DASHBOARD_EMPLOYEES_FAILED:
    case types.GET_DASHBOARD_SALARY_CHART_FAILED:
    case types.GET_DASHBOARD_SALARY_TABLE_FAILED:
    case types.GET_DASHBOARD_OVERVIEW_FAILED:
    case types.GET_DASHBOARD_SUBCONTRACTORS_FAILED:
    case types.GET_DASHBOARD_SUBCONTRACTORS_TABLE_FAILED:
      return state.set('isDashboardLoading', false);
    case types.GET_DASHBOARD_EMPLOYEE_DATA_FAILED:
      return state.set('isEmployeeLoading', false);

    default:
      return state;
  }
}
