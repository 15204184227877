export const FIELD_TITLE = {
  client_id: 'Client',
  number: 'Number',
  contractor_id: 'Contractor',
  owner_name: 'Author',
  amount: 'Amount',
  paid_amount: 'Paid',
  pending_amount: 'Pending',
  status: 'Status',
  sent_at: 'Sent at',
  issue_date: 'Issue date',
  period_from: 'Period from',
  period_to: 'Period to',
  reviewer_name: 'Reviewer',
  reviewed_at: 'Reviewed at',
};

export const INVOICE_SENT = 'SENT';
