/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Popover, Menu, Checkbox, Tooltip } from 'antd';
import { get, flattenDeep } from 'lodash';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';

import {
  EMPLOYEE_STATUSES,
  TEAM_ID,
  OK_STATUS,
  FIRED_STATUS,
  EMPLOYEE_OK_STATUSES,
} from 'utils/constants';
import { formatDate } from 'utils/formHelper';
import { DATE_MONTH_FORMAT } from 'utils/timeConstants';
import { LINKS } from 'config/routing';
import { getFiltersWithOutNull } from 'utils/common';
import getColumnSearchProps from 'components/TableSearchCommon/TableSearchProps';
import Tag from 'components/Common/Tag';
import { Button } from 'components/Common';
import TableButton from 'components/Common/TableButton';
import { hasRights } from 'utils/permissions';
import { SUBCONTRACTORS_MISSING_IBAN_STATUS } from '../User/UserPage/constants';
import {
  EMPLOYEES_TABLE_COMMON,
  EMPLOYEE_PROFILES_PERMISSIONS,
} from './constants';

import styles from './styles.scss';

export function getStatusTag(status, isExternal) {
  const statusValue = EMPLOYEE_STATUSES[status];

  if (isExternal && status === SUBCONTRACTORS_MISSING_IBAN_STATUS) return null;

  if (!statusValue) {
    return null;
  }

  return (
    <Tag color={get(statusValue, ['color'], 'blue')} key={status}>
      {get(statusValue, ['title'], 'Не указан')}
    </Tag>
  );
}

export function parseEmployeeWarnings(warnings) {
  return flattenDeep(Object.values(warnings));
}

export function formatEmployee(values) {
  return {
    ...values,
    contract_ends_at: formatDate(values.contract_ends_at),
    contract_starts_at: formatDate(values.contract_starts_at),
    fired_at: formatDate(values.fired_at),
    birthday: formatDate(values.birthday),
  };
}

export const formatIbans = ({ ibans, mainIban }) =>
  ibans?.reduce(
    (acc, curr, i) =>
      curr
        ? [
            ...acc,
            {
              iban_number: curr,
              is_main: mainIban === i,
            },
          ]
        : acc,
    [],
  ) || [];

export const getDataIsSubcontractor = data =>
  data.map(item => {
    if (
      item.team_id === TEAM_ID.SUBCONTRACTORS &&
      item.status === SUBCONTRACTORS_MISSING_IBAN_STATUS
    ) {
      return { ...item, status: OK_STATUS };
    }

    return item;
  });

export const formatEmployeeTable = employees =>
  employees.map(
    ({
      name_ru,
      jira_id,
      internal_id,
      updated_at,
      contract_ends_at,
      fired_at,
      before_taxes_salary,
      warnings,
      ...exp
    }) => ({
      name: name_ru,
      jira_id,
      internalId: internal_id,
      updatedAt: updated_at,
      contractEndsAt: contract_ends_at,
      firedAt: fired_at,
      beforeTaxesSalaryByn: before_taxes_salary,
      warnings: parseEmployeeWarnings(warnings),
      ...exp,
    }),
  );

export function formatWarningsKeys(warnings) {
  const buf = Object.keys(warnings).reduce(
    (result, key) => {
      const memo = result;

      if (/(^\[".*"\]$)/gim.test(key)) {
        const forametKey = key.replace(/(^\["|"\]$)/gim, '');
        memo.formWarnings[forametKey] = warnings[key];

        return memo;
      }

      memo.commonWarnings[key] = warnings[key];

      return memo;
    },
    { formWarnings: {}, commonWarnings: {} },
  );

  buf.commonWarnings = parseEmployeeWarnings(buf.commonWarnings);

  return buf;
}

export function renderEployeeWarnings(warnings) {
  const message = warnings.map(warn => <p key={warn}>{warn}</p>);

  return <Alert message={message} type="warning" showIcon />;
}

export const getDefaultHiddenColumns = key => {
  switch (key) {
    case 'contractEndsAt':
    case 'firedAt':
    case 'updatedAt':
      return false;
    default:
      return true;
  }
};

const filteredValue = (valueType, filterInfo) =>
  (filterInfo && filterInfo[valueType]) || null;

const renderCellAsLink = (value, record) => (
  <Tooltip getPopupContainer={e => e.parentElement || e} title={value}>
    <Link to={LINKS.profile(record.id)} className={styles.link}>
      {value}
    </Link>
  </Tooltip>
);

const renderSummaryCellAsLink = field => (value, record) => (
  <Link to={LINKS.profile(record.id)}>{value[field]}</Link>
);

const statusDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  filters,
}) => {
  const onSelect = value => ({ target }) => {
    if (target.checked) {
      if (value === OK_STATUS) {
        setSelectedKeys(EMPLOYEE_OK_STATUSES);

        return;
      }

      setSelectedKeys([...selectedKeys, value]);

      return;
    }

    if (value === OK_STATUS) {
      setSelectedKeys(
        selectedKeys.includes(FIRED_STATUS) ? [FIRED_STATUS] : [],
      );

      return;
    }

    setSelectedKeys(selectedKeys.filter(key => key !== value));
  };

  return (
    <Menu selectedKeys={selectedKeys}>
      {filters.map(({ text, value }) => (
        <Menu.Item className={styles.statusMenuItem} key={value}>
          <Checkbox
            checked={selectedKeys.includes(value)}
            onChange={onSelect(value)}
          >
            {text}
          </Checkbox>
        </Menu.Item>
      ))}
      <Menu.Divider />
      <div className={styles.statusDropdownBtns}>
        <Button
          size="small"
          type="link"
          disabled={!selectedKeys.length}
          onClick={clearFilters}
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => confirm({ closeDropdown: true })}
        >
          OK
        </Button>
      </div>
    </Menu>
  );
};

statusDropdown.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  filters: PropTypes.array,
};

const getSortOrder = (condition, sortInfo) =>
  sortInfo.columnKey === condition && sortInfo.order;

const getFilters = filters =>
  filters?.map(({ name, id }) => ({ text: name, value: id })) || [];

const getStatusFilters = filters =>
  filters?.map(status => ({
    text: EMPLOYEE_STATUSES[status].title || status,
    value: status,
  })) || [];

export const getTableColumns = ({
  organizations,
  filterInfo,
  teams,
  filters,
  sortInfo,
  handleReset,
  role,
}) => [
  {
    title: EMPLOYEES_TABLE_COMMON.id_title,
    dataIndex: EMPLOYEES_TABLE_COMMON.id,
    key: EMPLOYEES_TABLE_COMMON.id,
    sorter: true,
    sortOrder: getSortOrder(EMPLOYEES_TABLE_COMMON.id, sortInfo),
    ...getColumnSearchProps({
      dataIndex: EMPLOYEES_TABLE_COMMON.id,
      handleReset,
    }),
    width: '6%',
    filteredValue: filteredValue(EMPLOYEES_TABLE_COMMON.id, filterInfo),
    render: renderCellAsLink,
  },
  {
    title: EMPLOYEES_TABLE_COMMON.internal_id_title,
    sorter: true,
    dataIndex: EMPLOYEES_TABLE_COMMON.internal_id,
    key: EMPLOYEES_TABLE_COMMON.internal_id,
    sortOrder: getSortOrder(EMPLOYEES_TABLE_COMMON.internal_id, sortInfo),
    ...getColumnSearchProps({
      dataIndex: EMPLOYEES_TABLE_COMMON.internal_id,
      handleReset,
    }),
    filteredValue: filteredValue(
      EMPLOYEES_TABLE_COMMON.internal_id,
      filterInfo,
    ),
    width: '9%',
    render: renderCellAsLink,
  },
  {
    title: EMPLOYEES_TABLE_COMMON.name_title,
    sorter: true,
    dataIndex: EMPLOYEES_TABLE_COMMON.summary_employee,
    key: EMPLOYEES_TABLE_COMMON.full_name,
    sortOrder: getSortOrder(EMPLOYEES_TABLE_COMMON.full_name, sortInfo),
    ...getColumnSearchProps({
      dataIndex: EMPLOYEES_TABLE_COMMON.full_name,
      handleReset,
    }),
    filteredValue: filteredValue(EMPLOYEES_TABLE_COMMON.full_name, filterInfo),
    width: '18%',
    render: renderSummaryCellAsLink(EMPLOYEES_TABLE_COMMON.full_name),
  },
  {
    title: EMPLOYEES_TABLE_COMMON.organization_title,
    dataIndex: EMPLOYEES_TABLE_COMMON.organization_id,
    key: EMPLOYEES_TABLE_COMMON.organization_id,
    width: '10%',
    filters: getFilters(filters?.organizations),
    filteredValue: filteredValue(
      EMPLOYEES_TABLE_COMMON.organization_id,
      filterInfo,
    ),
    render: (value, record) => {
      if (value) {
        const currentOrganizations = organizations.find(
          organization => organization.id === value,
        );

        const renderValue = currentOrganizations
          ? currentOrganizations.friendly_name
          : '';

        return <Link to={LINKS.profile(record.id)}>{renderValue}</Link>;
      }
    },
  },
  {
    title: EMPLOYEES_TABLE_COMMON.contract_title,
    sorter: true,
    dataIndex: EMPLOYEES_TABLE_COMMON.contract_ends_at,
    key: EMPLOYEES_TABLE_COMMON.contract_ends_at,
    sortOrder: getSortOrder(EMPLOYEES_TABLE_COMMON.contract_ends_at, sortInfo),
    render: (date, record) => {
      const renderValue = date && dayjs(date).format(DATE_MONTH_FORMAT);

      return <Link to={LINKS.profile(record.id)}>{renderValue || ''}</Link>;
    },
    width: '12%',
  },
  {
    title: EMPLOYEES_TABLE_COMMON.fired_at_title,
    sorter: true,
    dataIndex: EMPLOYEES_TABLE_COMMON.fired_at,
    key: EMPLOYEES_TABLE_COMMON.fired_at,
    sortOrder: getSortOrder(EMPLOYEES_TABLE_COMMON.fired_at, sortInfo),
    render: (date, record) => {
      const renderValue = date && dayjs(date).format(DATE_MONTH_FORMAT);

      return <Link to={LINKS.profile(record.id)}>{renderValue || ''}</Link>;
    },
    width: '8%',
  },
  {
    title: EMPLOYEES_TABLE_COMMON.team_id_title,
    dataIndex: EMPLOYEES_TABLE_COMMON.summary_employee,
    key: EMPLOYEES_TABLE_COMMON.team_id,
    width: '10%',
    sorter: true,
    sortOrder: getSortOrder(EMPLOYEES_TABLE_COMMON.team_id, sortInfo),
    filters: getFilters(filters?.teams),
    filteredValue: filteredValue(EMPLOYEES_TABLE_COMMON.team_id, filterInfo),
    render: (value, record) => {
      const teamId = value[EMPLOYEES_TABLE_COMMON.team_id];
      const renderValue = teams.find(team => team.id === teamId)?.title || '';

      return <Link to={LINKS.profile(record.id)}>{renderValue}</Link>;
    },
  },
  {
    title: EMPLOYEES_TABLE_COMMON.location_title,
    sorter: true,
    dataIndex: EMPLOYEES_TABLE_COMMON.summary_employee,
    key: EMPLOYEES_TABLE_COMMON.location,
    sortOrder: getSortOrder(EMPLOYEES_TABLE_COMMON.location, sortInfo),
    width: '10%',
    render: renderSummaryCellAsLink(EMPLOYEES_TABLE_COMMON.location),
  },
  {
    title: EMPLOYEES_TABLE_COMMON.status_title,
    dataIndex: EMPLOYEES_TABLE_COMMON.status,
    key: EMPLOYEES_TABLE_COMMON.status,
    width: '12%',
    render: (status, { warnings, id }) => {
      if (warnings && warnings.length > 0) {
        return (
          <Popover
            placement="bottom"
            title="Информация"
            content={renderEployeeWarnings(warnings)}
            trigger="hover"
          >
            <Link to={LINKS.profile(id)}>{getStatusTag(status)}</Link>
          </Popover>
        );
      }

      return <Link to={LINKS.profile(id)}>{getStatusTag(status)}</Link>;
    },
    filters: getStatusFilters(filters?.statuses),
    filteredValue: filteredValue(EMPLOYEES_TABLE_COMMON.status, filterInfo),
    filterDropdown: statusDropdown,
  },
  {
    title: '',
    key: 'employee',
    align: 'center',
    hidden: !hasRights(role, EMPLOYEE_PROFILES_PERMISSIONS.updateProfile),
    render: value => (
      <Link to={`employees/${value.id}`} onClick={e => e.stopPropagation()}>
        <TableButton type="primary" icon={<EditOutlined />} />
      </Link>
    ),
    width: '5%',
  },
];

const formatSearchFilters = (filters, fieldsArr) => {
  fieldsArr.forEach(field => {
    if (filters[field] && Array.isArray(filters[field])) {
      filters[field] = filters[field][0];
    }
  });
};

export const formatEmployeeFilters = filters => {
  const filtersWithoutNull = getFiltersWithOutNull(filters);

  formatSearchFilters(filtersWithoutNull, ['id', 'internal_id', 'full_name']);

  return filtersWithoutNull;
};
