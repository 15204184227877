exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".comment-e2223{position:relative;padding-bottom:20px;margin-bottom:20px;border-bottom:1px solid #d9d9d9}.comment-e2223 .ant-comment-inner{padding:0 !important}.comment-e2223 .ant-comment-actions{height:0;margin:0}.comment-e2223 .author-aa9ea{font-size:16px;font-weight:600;color:#262626}.comment-e2223 .time-50dbf{font-size:14px}.comment-e2223 .content-8d01f{font-size:16px;color:#595959}.comment-e2223 .dropdown-2dac0{position:absolute;right:0;top:0}.comment-e2223 .buttons-1224f{position:absolute;right:35px;top:-10px}.comment-e2223 .more-e444e{color:#000;font-size:16px}", ""]);

// Exports
exports.locals = {
	"comment": "comment-e2223",
	"author": "author-aa9ea",
	"time": "time-50dbf",
	"content": "content-8d01f",
	"dropdown": "dropdown-2dac0",
	"buttons": "buttons-1224f",
	"more": "more-e444e"
};