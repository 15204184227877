exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".detailsContainer-763c2{box-sizing:border-box;background-color:#fff;border-radius:5px;overflow:hidden;box-shadow:0 0 5px 0 #ccc;margin-bottom:15px}.detailsHeader-142fe{box-sizing:border-box;padding:15px;display:flex;flex-flow:row nowrap;align-items:center;justify-content:space-between;border-bottom:1px solid #ccc}.detailsTitle-1a4c2{font-weight:600;font-size:18px}.detailsContent-22109{box-sizing:border-box;padding:15px;overflow:hidden auto}", ""]);

// Exports
exports.locals = {
	"detailsContainer": "detailsContainer-763c2",
	"detailsHeader": "detailsHeader-142fe",
	"detailsTitle": "detailsTitle-1a4c2",
	"detailsContent": "detailsContent-22109"
};