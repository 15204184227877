import { actionFactory } from 'redux/factories';
import { types } from './types';

export const getSocialRequests = actionFactory(types.GET_SOCIAL_REQUESTS);
export const getSocialRequestsInfo = actionFactory(
  types.GET_SOCIAL_REQUESTS_INFO,
);
export const getSocialRequestTypes = actionFactory(
  types.GET_SOCIAL_REQUEST_TYPES,
);
export const getSocialRequestStatuses = actionFactory(
  types.GET_SOCIAL_REQUEST_STATUSES,
);
export const createSocialRequest = actionFactory(types.CREATE_SOCIAL_REQUEST);
export const updateSocialRequest = actionFactory(types.UPDATE_SOCIAL_REQUEST);
export const deleteSocialRequest = actionFactory(types.DELETE_SOCIAL_REQUEST);
export const approveSocialRequest = actionFactory(types.APPROVE_SOCIAL_REQUEST);
export const declineSocialRequest = actionFactory(types.DECLINE_SOCIAL_REQUEST);
export const uploadSocialRequestAttachment = actionFactory(
  types.UPLOAD_SOCIAL_REQUEST_ATTACHMENT,
);
